import React from 'react'
import { SnowInputBase } from '../../../snowComponents'
import { ScFieldLabel } from '../../../LayoutComponents'
import styled from 'styled-components'

const BaseTextFieldView = styled(SnowInputBase)`
  &.MuiInputBase-root {
    padding: 0;
    min-width: 10%;
    width: 100%;
    max-width: 100%;
    flex: 1 1 20%;
  }
  .MuiInputBase-input {
    width: 100%;
    padding: 9px 10px;
    min-height: 40px;
    box-sizing: border-box;
    color: inherit;
    transition: box-shadow ${({theme})=>theme.transition.main};
    :focus {
      box-shadow: inset 0 -2px ${({ theme, iserror }) => (iserror ? theme.mainPalette.warning.secondary : theme.mainPalette.grey[200])};
    }
  }
`

function ScBaseTextField({ ...props }) {
  const { inputProps, rest } = props
  console.log('SnowTextField props', { props, inputProps })
  return (
    <ScFieldLabel {...rest}>
      <BaseTextFieldView {...inputProps} />
    </ScFieldLabel>
  )
}
export default ScBaseTextField
