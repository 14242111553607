import React from 'react'
import { useGoogleLogout } from 'react-google-login'
import { useRecoilValue } from 'recoil'
import { clearProviderAuth, clearSaUser } from '../data/authInfra'
import fetchClientId from '../data/fetchClientId'

function useLogoutHook() {
  const { clientId: googleId } = useRecoilValue(
    fetchClientId({ provider: 'google' })
  )

  const onLogoutSuccess = (res) => {
    console.log('Logged out Success')
    clearProviderAuth()
    clearSaUser()
    // alert('Logged out Successfully ✌')
  }

  const onFailure = () => {
    console.log('Handle failure cases')
  }

  const { signOut } = useGoogleLogout({
    clientId: googleId,
    onLogoutSuccess,
    onFailure
  })

  return { signOut }
}

export default useLogoutHook
