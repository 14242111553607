import { selectedBulkItem } from '../data/selectedBulkItemsState'
import { useRecoilState } from 'recoil'
import React, { useEffect, useState } from 'react'

export const useBulkGridItem = ({
  item,
  preselected,
  uidFieldName = 'uid'
}) => {
  const [isSelected, setIsSelected] = useRecoilState(
    selectedBulkItem(item[uidFieldName])
  )
  console.log('useBulkGridItem', item[uidFieldName], isSelected, preselected)
  const [internalSelected, setInternalSelected] = useState(
    preselected || isSelected
  )
  const toggleSelected = () => setIsSelected((item) => !item)
  useEffect(() => {
    setInternalSelected(isSelected)
  }, [isSelected])

  useEffect(() => {
    console.log('selected update', preselected)
    typeof preselected !== 'undefined' && setIsSelected(preselected)
  }, [preselected])

  return { internalSelected, toggleSelected }
}
