import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { currencySelector } from '../data/currencies'

const useCurrency = () => {
  const loadedCurrencies = useRecoilValue(currencySelector)
  useEffect(() => {
    // setPlatforms(loadedPlatforms)
    console.log('useCurrency', loadedCurrencies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedCurrencies])
  return loadedCurrencies
}

export default useCurrency
