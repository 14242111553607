import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import {
  SnowGrid,
  SnowFormControlLabel,
  SnowTypography
} from '../../../../shared/components/snowComponents'
import { ScCrudButton } from '../../../../shared/components/buttons'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import CastedView from '../../../../shared/components/SnowEditableField/CastedView'
import {
  currentIntegrationUpdatedFieldsList,
  currentIntegrationActualFieldsState
} from '../../data/integrationModel'
import { useVendorChannels } from '../vendors/VendorIntegration/VendorIntegration'
import {
  integrationCqrsRequestModel,
  integrationEntityType
} from '../../data/integrationDeterminant'
import {
  updatedScFieldsErrorsArray,
  useScFieldState
} from '../../../../shared/components/SnowEditableField/data/updatedFieldData'
import { useSaveConfigurationCmd } from '../../infrastructure/v2/apiConfigs'
import { vendorWithId } from '../../hooks/useVendors'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components'

const ConfigSaverView = styled(SnowGrid)`
  width: 100%;
  justify-content: center;
  .MuiFormControlLabel-root {
    margin: 0;
  }
  span.MuiFormControlLabel-label {
    color: ${({ theme }) => theme.mainPalette.color.contrast} !important;
  }
`

const MessageTextView = styled.div`
  padding: 2px;
  border-bottom: ${({ theme }) => theme.border.main};
`

const UpdatedFieldInfo = ({ id, value = '' }) => {
  const [field] = useScFieldState(id)
  const castedProps = { ...field, value }
  console.log('UpdatedFieldInfo', { field, value, castedProps })
  return <CastedView {...castedProps} />
}

const hashToStructure = (obj) => {
  const [field_id, value] = Object.entries(obj)[0]
  return { field_id, value }
}
const ConfigSaver = () => {
  const [showAlert, setShowAlert] = useState(false)
  const [updateInfo, setUpdateInfo] = useState()
  const integrationEntityTypeName = useRecoilValue(integrationEntityType)
  const integrationParams = useRecoilValue(integrationCqrsRequestModel)
  const updatedFields = useRecoilValue(currentIntegrationUpdatedFieldsList)
  const actualFields = useRecoilValue(currentIntegrationActualFieldsState)
  const errorsInUpdate = useRecoilValue(updatedScFieldsErrorsArray)
  const { domainId: vendorId } = useParams()
  const loadedVendor = useRecoilValue(vendorWithId(vendorId))

  const hasChanges = updatedFields.length
  const { vendorChannels: channelList, updateChannels } =
    useVendorChannels(vendorId)
  const label = hasChanges ? 'Save Changes' : 'No Changes'
  const updatedMap =
    updateInfo?.changes
      ?.filter((item) => item)
      .reduce((item, acc) => {
        return { ...acc, ...item }
      }, {}) ?? {}

  const saveConfigurationCmd = useSaveConfigurationCmd(
    {
      config_entity_type: integrationEntityTypeName,
      config_entity_id: integrationParams[integrationEntityTypeName],
      new_values: updatedFields.map((field) => hashToStructure(field)),
      marketplace_id: integrationParams['channel'] || 0,
      shop_id: integrationParams['project'] || 0
    },
    (r) => {
      // console.log('successfully saved', r)
      window.location.reload()
    },
    (r) => {
      // console.log('saved with errors', r)
    }
  )
  const [status, setStatus] = useState('')

  const saveChanges = async () => {
    saveConfigurationCmd().then((r) =>
      console.log('configuration saving result', r)
    )

    //todo: reset updated fields and reload configuration values
    console.log('saveConfigurations')
  }

  useEffect(() => {
    if (updatedFields.length) {
      setUpdateInfo({
        marketplace_id: String(actualFields[0].marketplace_id),
        changes: updatedFields
      })
      // setHasChanges(updatedFields.length)
    } else {
      setUpdateInfo([])
      // setHasChanges(0)
    }
    // const titles =
    //   _loadedFields?.fields?.length &&
    //   _loadedFields?.fields?.map((field) => {
    //     return { title: field.title, id: field.field_id }
    //   })
    // console.log('updatedFields', updatedFields, _loadedFields, titles)
    //
    // const isTitlesSetNew =
    //   titles?.length && titleList?.find((item) => item.id === titles[0].id)
    //     ? false
    //     : true
    // isTitlesSetNew
    //   ? titles?.length && setTitleList([...titleList, ...titles])
    //   : null
  }, [updatedFields])

  useEffect(() => {
    // console.log('errorsInUpdate', errorsInUpdate)
    errorsInUpdate.length ? setStatus('error') : setStatus('')
  }, [errorsInUpdate])

  const handleChange = (evt) => {
    if (!errorsInUpdate.length) {
      hasChanges && handleShowAlert()
      return
    }
    alert(
      'You have invalid values to update! Check marked fields and correct them.'
    )
  }

  const handleCancelAlert = () => {
    setShowAlert(false)
  }

  const handleShowAlert = () => {
    setShowAlert(true)
  }

  const handleConfirmSave = () => {
    handleCancelAlert()
    saveChanges()
  }

  hasChanges
    ? console.log('getMarketplaceName', { update: updateInfo?.marketplace_id })
    : null

  console.log(
    'updateInfo.changes',
    updatedMap,
    hasChanges && updateInfo?.changes
  )

  const updateMessageContent = Object.keys(updatedMap).map((fieldKey) => (
    <MessageTextView key={fieldKey}>
      <UpdatedFieldInfo id={fieldKey} value={updatedMap[fieldKey]} />
    </MessageTextView>
  ))

  const updateMessageTitle = hasChanges ? (
    <>
      <SnowTypography component="p" variant="body1" fontWeight={500}>
        Do you want to save next changes for
      </SnowTypography>
      <SnowTypography component="p" variant="body1" fontWeight={500}>
        {loadedVendor?.name}
      </SnowTypography>
    </>
  ) : (
    ''
  )

  const theme = useContext(ThemeContext)
  return (
    <ConfigSaverView container aria-label="ConfigSaver">
      <SnowGrid item>
        <SnowFormControlLabel
          disabled={!hasChanges}
          control={
            <ScCrudButton
              color={theme.mainPalette.color.contrast}
              status={status}
              editMode={true}
              onSave={handleChange}
            />
          }
          label={status !== 'error' ? label : 'Invalid changes'}
        />
      </SnowGrid>
      <ScConfirmationAlert
        isShowAttention={showAlert}
        onClose={handleCancelAlert}
        onConfirm={handleConfirmSave}
        message={{
          title: updateMessageTitle,
          content: updateMessageContent
        }}
      />
    </ConfigSaverView>
  )
}

export default ConfigSaver
