import React from 'react'
import { useRecoilValue } from 'recoil'
import { ScWhiteButton } from '../../../../../shared/components/buttons'
import CancelOrderButton from './buttons/CancelOrderButton'
import CancelOrderItemsButton from './buttons/CancelOrderItemsButton'
import RevalidateOrderButton from './buttons/RevalidateOrderButton'
import { ReactComponent as HoldSVG } from '../../../../app/images/SVGs/hold.svg'
import { ReactComponent as UnHoldSVG } from '../../../../app/images/SVGs/unhold.svg'
import PackingSlipButton from './buttons/PackingSlipButton'
import ResendOrderItemsButton from './buttons/ResendOrderItemsButton'
import { useCurrentOrder } from '../../data/currentOrderState'
import ModeratePersonalizationButton from './buttons/ModeratePersonalizationButton'
import { selectedBulkItemIds } from '../../../../../shared/components/BulkComponents/data/selectedBulkItemsState'
import PropTypes from 'prop-types'
import { ScItemsInRow } from '../../../../../shared/components/LayoutComponents'
import ReceiptIcon from '@mui/icons-material/ReceiptLong';
import { Link } from 'react-router-dom';

function getHoldButtonText(status) {
  if (status?.includes('hold')) {
    return 'Un-Hold'
  } else {
    return 'Hold'
  }
}

const IconHoldSVG = ({ status }) => {
  if (status?.includes('hold')) {
    return <UnHoldSVG />
  } else {
    return <HoldSVG />
  }
}

const HeaderActions = ({
  // order,
  toggleShowOnHoldModal,
  toggleShowCancelModal,
  toggleShowCancelItemsModal,
  toggleShowRevalidateModal,
  toggleResendOrderItemsModal
}) => {
  const currentOrder = useCurrentOrder()
  const selectedOrderItemIds = useRecoilValue(selectedBulkItemIds)
  // const resetSelectedOrderItem = useResetRecoilState(selectedBulkItemIds)
  const resetSelectedOrderItem = () => window.location.reload()

  return (
    currentOrder && (
      <ScItemsInRow itemWidth={'auto'}>
        {/*{!!currentOrder.customized && (*/}
        {/*  <ApprovePersonalisationButton*/}
        {/*    isApproved={currentOrder.personalizationApproved}*/}
        {/*  />*/}
        {/*)}*/}
        <ScWhiteButton
          onClick={() => toggleShowOnHoldModal()}
          icon={() => (
            <IconHoldSVG width="24" status={currentOrder.orderhubStatus} />
          )}
          label={getHoldButtonText(currentOrder.orderhubStatus)}
        />
        {!!currentOrder.customized && <ModeratePersonalizationButton />}

        <CancelOrderButton toggleShowCancelModal={toggleShowCancelModal} />

        <CancelOrderItemsButton
          toggleShowCancelItemsModal={toggleShowCancelItemsModal}
        />

        <RevalidateOrderButton
          toggleShowRevalidateModal={toggleShowRevalidateModal}
        />
        {/*<CreateVendorOrderButton />*/}
        {!!selectedOrderItemIds.length && (
          <ResendOrderItemsButton
            idsToResend={selectedOrderItemIds}
            onResent={resetSelectedOrderItem}
          />
        )}

        {!!currentOrder.packingslip && (
          <PackingSlipButton openUrl={currentOrder.packingslip} />
        )}

        <Link to={`../order_logs/${currentOrder.id}`} target="_blank" rel="noopener noreferrer">
          <ScWhiteButton
            icon={() => <ReceiptIcon />}
            label={'View Logs'}
          />
        </Link>
      </ScItemsInRow>
    )
  )
}

HeaderActions.propTypes = {
  toggleShowOnHoldModal: PropTypes.func.isRequired,
  toggleShowCancelModal: PropTypes.func.isRequired,
  toggleShowCancelItemsModal: PropTypes.func.isRequired,
  toggleShowRevalidateModal: PropTypes.func.isRequired
}

export default HeaderActions
