import useFetchedResult from '../../../infrastructure/api/v2/useFetchedResult'
import { selectedBulkItemIds } from '../../../shared/components/BulkComponents/data/selectedBulkItemsState'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { useRecoilValue } from 'recoil'
import objWithParent from '../../../utils/parenter'

const MODERATION_ITEMS_APPROVE_URL = 'grid/approve'
const MODERATION_ITEM_FIELDS_URL = 'grid/fields'
const MODERATION_ITEMS_URL = 'grid/items'

const API_MODERATION = objWithParent({
  url: '/personalizationModeration',
  FETCH: {
    url: `/${MODERATION_ITEMS_URL}`,
    method: 'GET',
    caller: 'API_MODERATION fetch!',
    validator: () =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  },
  FETCH_FIELDS: {
    url: `${MODERATION_ITEM_FIELDS_URL}`,
    method: 'GET',
    caller: 'API_MODERATION FETCH_FIELDS fetch!',
    validator: () =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  },
  APPROVE_ITEMS: {
    url: `${MODERATION_ITEMS_APPROVE_URL}`,
    method: 'POST',
    caller: 'API_MODERATION APPROVE_ITEMS!',
    validator: () =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  }
})
export const useModerationItemFields = () => {
  console.log('useModerationItemFields')
  const url = `${API_MODERATION.url}/${API_MODERATION.FETCH_FIELDS.url}`
  const fields = useFetchedResult({ ...API_MODERATION.FETCH_FIELDS, url }, [])
  console.log('usePersonalizationItemFields end', fields)
}

export const useApproveModerationCmd = (
  {
    selectedItemIds,
    moderationStatus = 'moderation',
    perPage = 8,
    orderBy = 'desc'
  },
  responseHandler = (resp) => {
    console.log('useApproveModerationCmd responseHandler', resp)
    return resp
  }
) => {
  const selectedItems = useRecoilValue(selectedBulkItemIds)
  const approveCustomizationCmd = useEntityCommand(
    0,
    {
      ...API_MODERATION.APPROVE_ITEMS,
      params: {
        items: selectedItems,
        moderation_status: moderationStatus,
        per_page: perPage,
        order_by: orderBy
      }
    },
    responseHandler
  )
  return approveCustomizationCmd
}
