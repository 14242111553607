import React from 'react'
import { SnowMenu } from '../snowComponents'
import styled from 'styled-components'

const MenuView = styled(SnowMenu)`
  .MuiPaper-root {
    margin-top: 0;
    min-width: 180px;
    ${({theme})=>`
      border-radius: ${theme.borderRadius.main};
      color: ${theme.mainPalette.typography.main};
      box-shadow:  ${theme.shadow.big};
    `}
    .MuiMenu-list {
      padding: 4px 0;
    }
    .MuiMenuItem-root {
      ${({theme})=>`
        font-weight: ${theme.fontWeight.thin};
        .MuiSvgIcon-root {
          font-size: 18px;
          fill: ${theme.mainPalette.grey[200]};
          margin-right: ${theme.spacing.secondary};
        }
      `}
    }
  }
`

const ScPopupMenu = ({ commands, children, ...props }) => {
  return (
    <MenuView
      aria-label='sc-popup-menu'
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    >
      {children}
    </MenuView>
  )
}

export default ScPopupMenu
