import { SnowListItem, SnowTypography } from '../../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const TimelineItemView = styled(SnowListItem)`
&.MuiListItem-root {
    justify-content: space-between;
    align-items: flex-start;
  }
`

const TimelineText = styled(SnowTypography).attrs({
  component: 'p',
  variant: 'body2'
})``

const TimelineDescriptionView = styled(TimelineText)`
  &.MuiTypography-root {
    margin-right: ${({ theme }) => theme.spacing.secondary};
  }
`

const TimelineTimeView = styled(TimelineText)`
  flex: 0 0 12%;
  text-align: right;
`

export { TimelineItemView, TimelineDescriptionView, TimelineTimeView }