import React from 'react'
import { SnowLink } from '../../snowComponents'
import styled from 'styled-components'

const LinkButtonView = styled(SnowLink)`
  &.MuiLink-root {
    font-size: ${({ theme, fontSize }) =>
      fontSize ? fontSize : theme.typography.main};
    color: ${({ theme }) => theme.mainPalette.color.secondary};
  }
`

const ScLinkButton = ({ onClick, fontSize, label = '', ...props }) => {
  if (!label) return null
  return (
    <LinkButtonView
      underline="hover"
      onClick={onClick}
      fontSize={fontSize}
      style={{ cursor: 'pointer' }}
      {...props}
    >
      {label}
    </LinkButtonView>
  )
}

export default ScLinkButton
