import React, { useCallback } from 'react'
import SnowEditableField from '../../../SnowEditableField'

const ItemField = React.memo(
  ({ title, code, item, type, onFieldUpdate, editState, renderer }) => {
    const itemData = item.data
    const label =
      typeof itemData[code] == 'string'
        ? itemData[code]
        : JSON.stringify(itemData[code])
    const rawValue = itemData[code]
    const onEditEnd = useCallback((...params) => {
      console.log('BulkGrid onEditEnd', params)
    }, [])

    const onUpdate = useCallback(({ code, value }) => {
      onFieldUpdate({
        item,
        code,
        value
      })
    }, [])

    // console.log('renderer', renderer)
    return (
      <>
        {renderer ? (
          renderer(itemData)
        ) : (
          <div aria-label='item-field'>
            <SnowEditableField
              meta={{
                code: code,
                visible: true,
                editType: type === 'image' ? 'image' : 'text'
              }}
              value={label}
              label={title}
              code={code}
              isGroupField={true}
              inputWidth={60}
              edit={editState}
              onEditEnd={onEditEnd}
              // onEditStart = { () => {} }
              onUpdate={onUpdate}
              required={false}
            />
          </div>
        )}
      </>
    )
  }
)
ItemField.displayName = 'ItemField'
export default ItemField
