export const statusFilterTabsJson = [
  {
    code: 'all',
    label: 'All',
    filter: {
      shipping_import_error: '',
      shipping_export_error: '',
      orderStatus: '',
      personalized: false
    }
  },
  {
    code: 'preorder',
    label: 'Pre-Order',
    filter: {
      shipping_import_error: '',
      shipping_export_error: '',
      orderStatus: 'preorder',
      personalized: false
    }
  },
  {
    code: 'personalized',
    label: 'Personalized',
    filter: {
      shipping_import_error: '',
      shipping_export_error: '',
      orderStatus: '',
      personalized: true
    }
  },
  {
    code: 'order_created',
    label: 'New',
    filter: {
      shipping_import_error: '',
      shipping_export_error: '',
      orderStatus: 'order_created',
      personalized: false
    }
  },
  {
    code: 'on_hold',
    label: 'On Hold',
    filter: {
      shipping_import_error: '',
      shipping_export_error: '',
      orderStatus: 'on_hold',
      personalized: false
    }
  },
  {
    code: 'error_validation',
    label: 'Error_Validation',
    filter: {
      shipping_import_error: '',
      shipping_export_error: '',
      orderStatus: 'error_validation',
      personalized: false
    }
  },
  {
    code: 'error',
    label: 'Error',
    filter: {
      shipping_import_error: '',
      shipping_export_error: '',
      orderStatus: 'error',
      personalized: false
    }
  },
  {
    code: 'shipping_import_error',
    label: 'Shipping Import Error',
    filter: {
      shipping_import_error: 'error',
      shipping_export_error: '',
      orderStatus: '',
      personalized: false
    }
  },
  {
    code: 'shipping_export_error',
    label: 'Shipping Export Error',
    filter: {
      shipping_import_error: '',
      shipping_export_error: 'error',
      orderStatus: '',
      personalized: false
    }
  }
]
