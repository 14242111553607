import React from 'react'
import { ScDropdownField } from '../../../shared/components/SnowEditableField/baseFields'

const ModerationStatusSelector = ({
  defaultStatus,
  onChange,
  availableStatuses
}) => {
  console.log('ModerationStatusSelector!', defaultStatus, availableStatuses)
  return (
    <ScDropdownField
      value={defaultStatus}
      meta={{ options: availableStatuses }}
      required
      onUpdate={({ value }) => onChange(value)}
      helperText={'moderation status to refine'}
    />
  )
}

export default ModerationStatusSelector
