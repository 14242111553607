import React from 'react'
import { DropdownButton } from '../_styled'
import { Filter, Select } from '../_styled'

const OrderPortalStatusDropdown = (props) => {
  return (
    <Filter>
      <Select
        onChange={props.setFilter}
        name="orderPortalStatus"
        value={props.selectValue}
      >
        <option value="">Status</option>
        <option value="open">Open</option>
        <option value="closed">Closed</option>
        <option value="hold">Hold</option>
      </Select>
      <DropdownButton />
    </Filter>
  )
}

export default OrderPortalStatusDropdown
