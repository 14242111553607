import styled, { css } from 'styled-components'

const FilterPosition = css`
  min-width: 150px;
  min-height: 38px;
  position: relative;
  box-sizing: border-box;
`

const Filter = styled.div`
  .ScFieldWrapper {
    border: none;
  }
  ${({ type }) => {
    switch (type) {
      default:
        return FilterPosition
    }
  }
  }
`

export default Filter