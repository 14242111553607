import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { portalRequestor } from '../../../infrastructure/api/v1/requestors'

/**
 * Entity Config JSON
 * contains entity API base url, and commands configs set
 * @type {{APPROVE_CUSTOMIZATION: {caller: string, method: string, validator:
 *   (function({order?: *}): boolean), url: string}, url: string}}
 */
const API_ORDER_ITEMS = objWithParent({
  url: '/items',
  FETCH_ORDER_ITEMS: {
    url: '',
    method: 'GET',
    caller: 'API_ORDER_ITEMS FETCH_ORDER_ITEMS',
    validator: () => true //{page, per_page, filters??}
  },

  BULK_UPDATE: {
    url: 'bulkUpdate',
    method: 'POST',
    caller: 'API_ORDER_ITEMS BULK_UPDATE',
    validator: ({ action, item_ids, filters }) => true //{item_ids:[],fields:{}
  },
  GET_AVAILABLE_STATUSES: {
    url: 'getAvailableStatuses',
    method: 'GET',
    caller: 'API_ORDER_ITEMS FETCH_AVAILABLE_STATUSES',
    validator: () => true //{item_ids:[],fields:{}
  },
  FETCH_EDITABLE_FIELDS: {
    url: 'getEditableFields',
    method: 'GET',
    caller: 'FETCH_EDITABLE_FIELDS',
    validator: ({ action, itemIds, filters }) => true
  }
})

export const useOrderItemsFetchActionFieldsCmd = (
  { action, itemIds = [], filters = {} },
  onSuccess = (r) => console.log('useOrderItemsFetchEditableFields success', r),
  onFail = (r) => console.log('useOrderItemsFetchEditableFields fail', r)
) => {
  const result = useEntityCommand(
    null,
    {
      ...API_ORDER_ITEMS.FETCH_EDITABLE_FIELDS,
      params: { action, item_ids: itemIds, filters }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useOrderItemsGetAvailableStatusesCmd = (
  params,
  onSuccess = (r) => {
    console.log('useOrderItemsGetAvailableStatusesCmd success', r)
    return r
  },
  onFail = (r) => console.log('useOrderItemsGetAvailableStatusesCmd fail', r)
) => {
  const result = useEntityCommand(
    null,
    {
      ...API_ORDER_ITEMS.GET_AVAILABLE_STATUSES
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useOrderItemsBulkUpdateActionCmd = (
  { action, itemIds, updatedFields, filters },
  onSuccess = (r) => console.log('useBulkUpdateCmd success', r),
  onFail = (r) => console.log('useBulkUpdateCmd fail', r)
) => {
  console.log('useBulkUpdateCmd', itemIds, filters, updatedFields)
  const { url } = API_ORDER_ITEMS.BULK_UPDATE
  const result = useEntityCommand(
    null,
    {
      ...API_ORDER_ITEMS.BULK_UPDATE,
      url: `${url}/${action}`,
      params: { item_ids: itemIds, fields: updatedFields, filters }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useFetchItemsCmd = (
  { cursor, per_cursor, filters },
  onSuccess = (r) => console.log('useFetchItemsCmd success', r),
  onFail = (r) => console.log('useFetchItemsCmd fail', r)
) => {
  console.log('useFetchItemsCmd', cursor, per_cursor, filters, {
    cursor,
    per_cursor,
    ...filters
  })

  const cmd = useEntityCommand(
    null,
    {
      ...API_ORDER_ITEMS.FETCH_ORDER_ITEMS,
      params: { cursor, per_cursor, ...filters }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return cmd
}

// function to cancel an item on an order
export const cancelOrderItem = ({ itemId }) => {
  return portalRequestor({
    url: `item/${itemId}/cancel`,
    type: 'get',
    caller: 'cancelOrderItem'
  })
}
