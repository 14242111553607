import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useProduct } from '../../context/product'
import FieldContent from './AttributeContent/FieldContent'
import ArrayContent from './AttributeContent/ArrayContent'
import GroupContent from './AttributeContent/GroupContent'
import { ScPrimaryBtn } from '../../../../shared/components/buttons'
import styled from 'styled-components'
import _ from 'lodash'
import ScStackedItems from '../../../../shared/components/LayoutComponents/ScStackedItems'
import { useSyncCmd } from '../../infrastructure/apiV2'

const SyncButtonView = styled.div`
  padding: 10px 20px;
`

const ProductAttributes2 = () => {
  const createArrayItem = ({ meta }) => {
    const arrayMetaItems = Object.entries(meta.array_type).map(
      ([key, value]) => {
        const defaultFieldValue = ''
        return { [key]: defaultFieldValue }
      }
    )

    return _.merge({}, ...arrayMetaItems)
  }

  const { product, onAttributeUpdate, editState } = useProduct()
  const { fields, values } = product
  const [_values, setValues] = useState({ ...values })
  const pathMetas = useRef({})
  const [vendorIdSync, setVendorIdSync] = useState()
  const productId = product.values.product.id
  const inventoryItemsQuantity = _values?.inventory_items.length
  const initButtonsState = new Array(inventoryItemsQuantity).fill(false)
  const [buttonsDisable, setButtonsDisable] = useState(initButtonsState)

  const addArrayItem = ({ arrayPath, meta }) => {
    console.log('addArrayItem', arrayPath, meta, _values)
    const apath = arrayPath.replace('%key%.', '')
    const newItem = createArrayItem({ meta })
    const oldArray =
      _.get(_values, apath)?.filter(
        (item) => JSON.stringify(item) !== JSON.stringify(newItem)
      ) ?? []
    console.log('addArrayItem apath,newItem', apath, newItem)
    console.log(oldArray)
    const newArray = [...oldArray, newItem]
    const newValues = _.set(_.cloneDeep(_values), apath, newArray)
    console.log('addArrayItem newValues', newValues)
    setValues(newValues)
  }

  const idSet = { product_id: productId, vendor_id: vendorIdSync }

  const syncCmd = useSyncCmd(idSet)

  const getVendorIdx = (linePath) =>
    Number(linePath?.split('[')[1].split(']')[0])

  const getVendorId = (values, linePath) =>
    values.inventory_items[getVendorIdx(linePath)].vendor_id

  useEffect(() => {
    vendorIdSync && syncCmd()
    console.log('ProductAttributesV2! useEffect', { buttonsDisable })
  }, [vendorIdSync])

  const handleSyncClick = (linePath) => {
    buttonsDisable[getVendorIdx(linePath)] = 'Inventory syncing...'
    setButtonsDisable(buttonsDisable)
    const vendorId = linePath && getVendorId(_values, linePath)
    setVendorIdSync(vendorId)
    console.log('ProductAttributesV2! Sync click', {
      idSet,
      linePath,
      vendorId,
      buttonsDisable
    })
  }

  const getContent = ({ meta, values, level, linePath = '' }) => {
    pathMetas.current = { ...pathMetas.current, linePath: meta }
    console.log(`${meta.type} getContent`, {
      linePath,
      cd: meta.code,
      meta,
      values,
      level
    })
    switch (meta.type) {
      case 'group':
        return (
          <GroupContent
            {...{ getContent, meta, values, level: level + 1, linePath }}
          />
        )
      case 'array':
      case 'uniq_array':
        return (
          <ScStackedItems spacing={1}>
            <ArrayContent
              {...{
                addArrayItem,
                getContent,
                meta,
                values,
                level: level + 1,
                linePath
              }}
            />
          </ScStackedItems>
        )
      case 'button':
        return values ? (
          <SyncButtonView>
            <ScPrimaryBtn
              label={
                buttonsDisable[getVendorIdx(linePath)]
                  ? 'Inventory syncing...'
                  : meta.label
              }
              disabled={buttonsDisable[getVendorIdx(linePath)]}
              onClick={() => handleSyncClick(linePath)}
            />
          </SyncButtonView>
        ) : null

      default: {
        console.log('FieldContent!!', { meta, values })
        return <FieldContent {...{ meta, values, level, linePath }} />
      }
    }
  }

  console.log('ProductAttributesV2!', {
    product,
    _values,
    fields,
    inventoryItemsQuantity
  })

  return (
    <>
      {Object.entries(fields).map(([key, value]) => {
        return (
          <Fragment key={key}>
            {getContent({
              meta: { ...value, code: key },
              values: _values[key],
              level: 0
            })}
          </Fragment>
        )
      })}
    </>
  )
}

export default ProductAttributes2
