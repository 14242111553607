import React from 'react'
import {
  ScEditTextButton,
  ScResyncTextButton,
  ScSaveTextButton,
  ScOpenTextButton
} from '../../../../shared/components/buttons'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const ProductActionsView = styled.div`
  display: flex;
  margin: 0 -8px;
  .MuiButton-root {
    margin: 8px;
  }
`

const HeaderActions = ({
  product: {
    values: { product }
  },
  ...props
}) => {
  const { editState, toggleEditState, toggleShowResyncModal } = props
  return (
    <ProductActionsView>
      <ScResyncTextButton onClick={toggleShowResyncModal} />
      {editState ? (
        <ScSaveTextButton onClick={toggleEditState} />
      ) : (
        <ScEditTextButton onEdit={() => toggleEditState()} />
      )}
      <ScOpenTextButton
        href={`https://pim.snowmachine.info/?product_sku=${product.marketplace_product_sku}`}
        label={'Open in PIM'}
      />
    </ProductActionsView>
  )
}

export default HeaderActions

HeaderActions.propTypes = {
  toggleShowResyncModal: PropTypes.func.isRequired,
  toggleShowConfirmationModal: PropTypes.func.isRequired
}
