import React, { useEffect } from 'react'
import ScTabs from '../../../shared/components/form/ScTabs'
import { TabContent } from '../../../shared/components/form/ScTabs/ScTabs'
import { useNavigate, useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { integrationEntitiesModel } from '../data/integrationDeterminant'
import { setScPageHeaderTitleCmd } from '../../app/data/currentHeadingState'
import IntegrationVendorChooser from './vendors/IntegrationVendorChooser'
import IntegrationProjectChooser from './projects/IntegrationProjectChooser'

const withActive = (tabConfigs = [], activePath) => {
  return tabConfigs.map(({ path, ...rest }) => ({
    ...rest,
    path,
    active: path === activePath
  }))
}
const AccountTypeSelection = ({ type = 'projects' }) => {
  const { integrationDomain: activeDomain } = useParams()
  const navigate = useNavigate()

  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)

  const setIntegrationEntityModel = useSetRecoilState(integrationEntitiesModel)

  const onIntegrationSelected = (id, dto) => {
    console.log('onIntegrationSelected id, dto', id, dto)
    setIntegrationEntityModel(dto)
    id && navigate(`${id}`)
  }

  const integrationDomainsTabs = [
    {
      title: 'Vendors',
      path: 'vendors',
      element: (
        <IntegrationVendorChooser
          projectId={0}
          onIntegrationSelected={onIntegrationSelected}
        />
      )
    },
    // {
    //   title: 'Sales Channels',
    //   path: 'sales-channels',
    //   element: (
    //     <IntegrationChannelChooser
    //       projectId={0}
    //       onIntegrationSelected={onIntegrationSelected}
    //     />
    //   )
    // },
    {
      title: 'Projects',
      path: 'projects',
      element: (
        <IntegrationProjectChooser
          onIntegrationSelected={onIntegrationSelected}
        />
      )
    }
  ]

  const tabs = withActive(integrationDomainsTabs, activeDomain)

  const onTab = (e, value) => {
    console.log('AccountTypeSelection onTab', e, activeDomain, value)
    activeDomain !== value && navigate(`../${value}`, { replace: true })
  }
  console.log('activeDomain', activeDomain)

  useEffect(() => {
    console.log('activeDomain updated', activeDomain)

    setHeading(`${activeDomain} integrations`)

    !activeDomain && navigate('vendors')
  }, [activeDomain])

  useEffect(() => {
    return () => {
      console.log('Account type selection destroyed')
    }
  }, [])

  return (
    <>
      <ScTabs onTab={onTab}>
        {tabs.map((tab) => {
          return (
            <TabContent
              key={tab.path}
              label={tab.title}
              value={tab.path}
              active={tab.active}
            >
              {tab.element}
            </TabContent>
          )
        })}
      </ScTabs>
    </>
  )
}

export default AccountTypeSelection
