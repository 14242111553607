import React from 'react'
import { SnowInputLabel, SnowTooltip } from '../../snowComponents'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useLogger } from '../../../service/helpers/SnowLogger'

const StyledFieldWrapper = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 100%;
  min-width: min-content;
  box-sizing: border-box;
  ${({ variant }) =>
    variant === 'outlined'
      ? css`
          border: 1px solid;
          border-radius: 3px;
        `
      : css`
          border-bottom: 1px solid;
        `}
  border-color: ${({ theme, iserror }) =>
    iserror
      ? theme.mainPalette.warning.secondary
      : theme.mainPalette.grey.border};
  overflow: hidden;
  background: ${({ theme }) => theme.mainPalette.color.contrast};
  :hover {
    border-color: ${({ theme }) => theme.mainPalette.grey.dark};
  }
  input,
  div {
    color: ${({ theme }) => theme.mainPalette.typography.main};
    font-weight: ${({ theme, fontWeight }) =>
      fontWeight ? fontWeight : theme.fontWeight.thin};
  }
`

const StyledFieldset = styled.fieldset`
  ${StyledFieldWrapper}
  input, div {
    font-weight: ${({ theme, fontWeight }) =>
      fontWeight ? fontWeight : theme.fontWeight.thin};
  }
  display: inline-flex;
  .MuiSelect-select {
    min-height: 100%;
    margin-top: -3px;
  }
  input {
    margin-top: -3px;
  }
  input::placeholder {
    ${({ theme }) => `
      color: ${theme.mainPalette.grey.middle};
      font-size: ${theme.fontSize.main};
    `}
    opacity: 1;
  }
  div input {
    margin-top: -3px;
  }
  button {
    margin-top: -3px;
  }
  padding: 0;
  box-sizing: border-box;
  width: ${({ width }) => width};
  height: 100%;
`

const StyledLegend = styled.legend`
  margin-left: 5px;
  margin-bottom: 0;
  width: auto;
  font-weight: ${({ theme }) => theme.fontWeight.thin};
`

const StyledTopLabel = styled(SnowInputLabel)`
  margin-bottom: 0;
  cursor: pointer;
  &.MuiFormLabel-root {
    padding: 0 5px;
    font-size: 0.75rem;
    font-weight: ${({ theme }) => theme.fontWeight.thin};
    color: ${({ theme, iserror }) =>
      iserror
        ? theme.mainPalette.warning.secondary
        : theme.mainPalette.grey.main};
  }
`

const StyledStartEndLabel = styled(SnowInputLabel)`
  flex: 0 0 auto;
  max-width: 50%;
  cursor: pointer;
  &.MuiFormLabel-root {
    ${({ theme, fontSize, fontWeight }) => `
      font-size: ${fontSize ? fontSize : theme.fontSize.main};
      font-weight: ${fontWeight ? fontWeight : theme.fontWeight.thin};
    `}
    color: ${({ theme, iserror }) =>
      iserror
        ? theme.mainPalette.warning.secondary
        : theme.mainPalette.typography.main};
  }
  ${({ isstart, ishelpertext, istopbig }) => {
    if (isstart) {
      return ishelpertext
        ? css`
            margin: 13px 20px auto 0;
            order: 0;
          `
        : css`
            margin: auto 20px auto 0;
            order: 0;
          `
    } else {
      return ishelpertext
        ? css`
            margin: 13px 0 auto 20px;
            order: 1;
          `
        : css`
            margin: ${istopbig ? '0 0 8px' : 'auto 0 auto 20px'};
            order: 1;
          `
    }
  }}
`

const StyledWrapper = styled.div`
  width: ${({ width }) => width};
`

const StyledStartEndFieldWrapper = styled.div`
  ${StyledFieldWrapper}
`
const StyledStartEndField = styled.div`
  display: ${({ istopbig }) => (istopbig ? 'block' : 'flex')};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: start;
`

const StyledTopFieldWrapper = styled.div`
  max-width: auto;
  width: auto;
`

const StyledField = styled.div`
  ${({ width }) => (width ? `width: ${width};` : '')}
  margin-bottom: ${({ mb }) => mb};
`

const StyledHelperText = styled.p`
  padding: 5px 0;
  margin: 0;
  font-size: 12px;
  width: ${({ width }) => width};
  color: ${({ theme, iserror }) =>
    iserror
      ? theme.mainPalette.warning.secondary
      : theme.mainPalette.grey.main};
  text-align: left;
`

const ScFieldLabel = ({
  isInvalid = false,
  helperText = '',
  label = '',
  code = 'field',
  variant = 'outlined',
  labelPlacement = 'top',
  isGroupField = false,
  inputWidth = 100,
  mb = 0,
  children = {},
  tooltipText = '',
  labelFontProps = { fontWeight: '', fontSize: '' },
  fieldFontProps = { fontWeight: '', fontSize: '' },
  ...props
}) => {
  const { log } = useLogger('APP')
  log(
    'ScFieldLabel props',
    props.required,
    '\ninputProps',
    props.inputProps,
    '\ntooltipText',
    tooltipText
  )

  const isLabelExist = label ? true : false
  const isTopLabel = isLabelExist && labelPlacement === 'top' ? 1 : 0
  const isTopBigLabel = isLabelExist && labelPlacement === 'top-big' ? 1 : 0
  const isStartLabel = isLabelExist && labelPlacement === 'start' ? 1 : 0
  const isHelperText = helperText ? 1 : 0
  const width = typeof inputWidth === 'number' ? `${inputWidth}%` : inputWidth
  const isError = isInvalid ? 1 : 0
  const marginBottom = mb ? `${mb}px` : 0
  const fullLabel = isLabelExist && props.required ? `${label}*` : label
  const HelperElement = ({ helperText }) => {
    return (
      <StyledHelperText width={width} iserror={isError}>
        {helperText}
      </StyledHelperText>
    )
  }

  const child = {
    ...children,
    props: {
      // error: isInvalid,
      ...children.props
    }
  }

  return (
    <>
      <StyledField
        aria-label="field-label"
        mb={marginBottom}
        width={isTopLabel ? width : '100%'}
      >
        {isTopLabel ? (
          <StyledTopFieldWrapper
            className={'ScFieldWrapper'}
            role="field-wrapper"
            {...fieldFontProps}
          >
            <StyledFieldset variant={variant} width={width} iserror={isError}>
              {isLabelExist && (
                <StyledLegend>
                  <StyledTopLabel
                    htmlFor={code}
                    iserror={isError}
                    role="label"
                    id={`label-${code}`}
                  >
                    {fullLabel}
                  </StyledTopLabel>
                </StyledLegend>
              )}
              {tooltipText ? (
                <SnowTooltip
                  title={tooltipText}
                  placement="bottom-end"
                  role="tooltip"
                >
                  <div style={{ width: '100%' }}>{child}</div>
                </SnowTooltip>
              ) : (
                <>{child}</>
              )}
            </StyledFieldset>
            {Boolean(isHelperText) && <HelperElement helperText={helperText} />}
          </StyledTopFieldWrapper>
        ) : (
          <StyledStartEndField
            istopbig={isTopBigLabel}
            justifyContent={isGroupField ? 'space-between' : 'flex-start'}
          >
            {isLabelExist && (
              <StyledStartEndLabel
                htmlFor={code}
                id={`label-${code}`}
                isstart={isStartLabel}
                istopbig={isTopBigLabel}
                iserror={isError}
                ishelpertext={isHelperText}
                {...labelFontProps}
                role="label"
              >
                {fullLabel}
              </StyledStartEndLabel>
            )}
            <StyledWrapper width={width} role="field-wrapper">
              <StyledStartEndFieldWrapper
                {...fieldFontProps}
                variant={variant}
                iserror={isError}
                isstart={isStartLabel}
                className={'ScFieldWrapper'}
              >
                {tooltipText ? (
                  <SnowTooltip
                    title={tooltipText}
                    placement="top-start"
                    role="tooltip"
                  >
                    <div style={{ width: '100%' }}>{child}</div>
                  </SnowTooltip>
                ) : (
                  <>{child}</>
                )}
              </StyledStartEndFieldWrapper>
              {Boolean(isHelperText) && (
                <HelperElement helperText={helperText} />
              )}
            </StyledWrapper>
          </StyledStartEndField>
        )}
      </StyledField>
    </>
  )
}
export default ScFieldLabel

ScFieldLabel.propTypes = {
  isInvalid: PropTypes.bool,
  isGroupField: PropTypes.bool,
  helperText: PropTypes.string,
  toolTipText: PropTypes.string,
  label: PropTypes.string,
  code: PropTypes.string,
  mb: PropTypes.number,
  variant: PropTypes.oneOf(['outlined', 'standard']),
  labelPlacement: PropTypes.oneOf(['top', 'top-big', 'start', 'end', '']),
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.object
}
