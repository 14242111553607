import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  atomFamily,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'
import { SnowTypography } from '../../../../../shared/components/snowComponents'
import { ScConfirmationAlert } from '../../../../../shared/components/modals'
import { ScPageContent, ScSplitPanel } from '../../../../../shared/components/LayoutComponents'
import IntegrationSettings from '../../IntegrationSettings'
import { integrationCqrsRequestModel } from '../../../data/integrationDeterminant'
import { VendorStatusInput } from './VendorStatusInput'
import { setScPageHeaderTitleCmd } from '../../../../app/data/currentHeadingState'
import { vendorWithId } from '../../../hooks/useVendors'
import { currentIntegrationUpdatedFieldsList } from '../../../data/integrationModel'
import { useFetchVendorChannelsCmd } from '../../../infrastructure/v2/vendorsApiConfigs'
import usePlatforms from '../../../hooks/usePlatforms'
import ScopeSelector from './VendorScopeSelector/ScopeSelector'

const vendorChannelsAtom = atomFamily({
  key: 'vendorChannelsAtom',
  default: []
})
export const useVendorChannels = (vendorId) => {
  const setVendorChannels = useSetRecoilState(vendorChannelsAtom(vendorId))
  const vendorChannels = useRecoilValue(vendorChannelsAtom(vendorId))
  const fetchChannels = useFetchVendorChannelsCmd(vendorId)
  const loadedPlatforms = usePlatforms()
  const extractChannelsFromResponse = (rawData) =>
    (rawData?.data?.data ?? []).map((rawChannel) => validChannel(rawChannel))
  const validChannel = (rawData) => {
    // console.log('validChannel', rawData)
    const {
      alt_catalog_market_id = null,
      marketplace_id = 0,
      marketplace_name = '',
      platform_id = 'Global',
      shop_id = 0,
      url = ''
    } = rawData

    return {
      alt_catalog_market_id,
      marketplace_id,
      marketplace_name,
      platform:
        loadedPlatforms.find(({ id }) => platform_id === id)?.name ??
        platform_id,
      shop_id,
      url
    }
  }
  useEffect(() => {
    vendorId &&
      fetchChannels().then((r) => {
        console.log('fetchChannels', r)
        const channels = r?.data?.data

        Array.isArray(channels) &&
          setVendorChannels(
            channels.map((rawChannel) => validChannel(rawChannel))
          )
      })
  }, [vendorId])

  const updateChannels = (r) => {
    console.log('updateFromResponse', r, typeof r)
    const channels = r?.data?.data
    Array.isArray(channels) &&
      setVendorChannels(channels.map((rawChannel) => validChannel(rawChannel)))
  }
  return { vendorChannels, updateChannels }
}

const VendorIntegration = () => {
  const setIntegrationParams = useSetRecoilState(integrationCqrsRequestModel)
  const resetIntegrationParams = useResetRecoilState(
    integrationCqrsRequestModel
  )
  // const updatedInputs = useRecoilValue(currentIntegrationUpdatedFieldsList)

  const [selectedScope, setSelectedScope] = useState(0)
  const [addSelectedScope, setAddSelectedScope] = useState(0)

  const [ChannelTitle, setChannelTitle] = useState('Global Level')

  const { domainId: vendorId } = useParams()

  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)
  const loadedVendor = useRecoilValue(vendorWithId(vendorId))

  const [showAlert, setShowAlert] = useState(false)
  const [channel, setChannel] = useState()
  const [hasChanges, setHasChanges] = useState(false)
  const updatedFields = useRecoilValue(currentIntegrationUpdatedFieldsList)

  console.log('VendorIntegration loadedVendor', loadedVendor)

  useEffect(() => {
    setHasChanges(updatedFields.length)
  }, [updatedFields])

  const handleOpenAlert = () => {
    setShowAlert(true)
  }
  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const handleScopeSelect = () => {
    onScopeSelect(channel)
    handleCloseAlert()
  }

  useEffect(
    () =>
      loadedVendor && setHeading(`Integration for vendor ${loadedVendor.name}`),
    [loadedVendor]
  )

  useEffect(() => {
    console.log('VendorIntegration constructor')

    return () => {
      console.log('VendorIntegration destructor')
      resetIntegrationParams()
    }
  }, [])

  const onScopeSelect = (selectedScopeItem) => {
    console.log('onScopeSelect', selectedScopeItem)
    // prepareInputs()
    const { marketplace_id, shop_id, name, marketplace_name } = selectedScopeItem
    setSelectedScope(marketplace_id)
    setAddSelectedScope(shop_id)

    setIntegrationParams((integrationParams) =>
      integrationParams.channel.toString() !==
        marketplace_id.toString()
        ? {
          ...integrationParams,
          channel: marketplace_id, project: shop_id
        }
        : { ...integrationParams, project: shop_id }
    )

    setChannelTitle(
      selectedScopeItem !== 0
        ? `${marketplace_name ?? name}`
        : 'Global Vendor level'
    )
  }
  return (
    <ScPageContent p={2}>
      <ScSplitPanel ratio={[2, 1]}>
        <ScSplitPanel.Left>
          <VendorStatusInput id={vendorId} />
          <SnowTypography variant={'h5'} align={'center'}>
            {ChannelTitle
              ? `Integration settings: ${ChannelTitle}`
              : 'Global Integration'}
          </SnowTypography>
          <IntegrationSettings />
        </ScSplitPanel.Left>
        <ScSplitPanel.Right>
          <ScopeSelector
            vendorId={vendorId}
            loadedVendor={loadedVendor}
            onScopeSelect={
              hasChanges
                ? (e) => {
                  handleOpenAlert()
                  setChannel(e)
                }
                : onScopeSelect
            }
            selectedScope={selectedScope}
            addSelectedScope={addSelectedScope}
          />
          <ScConfirmationAlert
            isShowAttention={showAlert}
            onClose={handleCloseAlert}
            onConfirm={handleScopeSelect}
            message={{
              title: 'Your changes aren\'t saved',
              content: <SnowTypography variant={"body2"} align={'center'}>{'Changes will be lost on the channel switch. Do you want to continue?'}</SnowTypography>
            }}
          />
        </ScSplitPanel.Right>
      </ScSplitPanel>
    </ScPageContent>
  )
}

export default VendorIntegration
