export const fakeBatches = [
  {
    id: 2,
    batch_id: '79376380389720fe93d85859f2a3303b',
    status: 'pending',
    entity: 'orderItems',
    user: 'Viktor Miruschenko',
    filters: [],
    fields: {
      shipping_dates: {
        comment: 'no comment',
        earliest_ship_date: '2022-08-19'
      }
    },
    created_at: '2022-08-17T17:01:15+00:00',
    updated_at: '2022-08-17T17:01:15+00:00'
  },
  {
    id: 1,
    batch_id: '719fd91e74769397b6eee0d1bf15a14a',
    status: 'pending',
    entity: 'orderItems',
    user: 'Viktor Miruschenko',
    filters: [],
    fields: {
      shipping_dates: {
        comment: 'My Comm1',
        earliest_ship_date: '12'
      }
    },
    created_at: '2022-08-17T17:00:43+00:00',
    updated_at: '2022-08-17T17:00:43+00:00'
  }
]

const fakeBatchItems = [
  {
    id: 1,
    bulk_update_batch_id: 1,
    item_id: 227944,
    status: 'pending',
    message: '',
    created_at: '2022-08-17T17:00:43+00:00',
    updated_at: '2022-08-17T17:00:43+00:00'
  }
]
export const batchFieldsDescription = {
  id: {
    code: 'id',
    title: 'Batch Id',
    visible: true
  },

  batch_id: {
    code: 'batch_id',
    title: 'Batch Id',
    visible: false
  },
  status: {
    code: 'status',
    title: 'Status',
    visible: true,
    minWidth: 15,
    uiType: ''
  },
  entity: {
    code: 'entity',
    title: 'Entity',
    visible: true
  },
  user: {
    code: 'user',
    title: 'User',
    visible: true
  },
  filters: {
    code: 'filters',
    title: 'Applied Filters',
    visible: true,
    editType: 'flat_list'
  },
  fields: {
    code: 'fields',
    title: 'Updated fields',
    visible: true,
    editType: '2levels_map_list',
    minHeight: 3.5,
    minWidth: 30
  },
  created_at: {
    code: 'created_at',
    title: 'Created at',
    visible: true,
    editType: 'date'
  },
  updated_at: {
    code: 'updated_at',
    title: 'Updated at',
    visible: true,
    editType: 'date'
  }
}

export const batchItemFieldsDescription = {
  id: {
    code: 'id',
    title: 'Id',
    visible: false
  },
  bulk_update_batch_id: {
    code: 'bulk_update_batch_id',
    title: 'bulk_update_batch_id',
    visible: false
  },
  sku: {
    code: 'sku',
    title: 'Sku',
    visible: true
  },
  item_id: {
    code: 'item_id',
    title: 'item_id',
    visible: true
  },
  order_number: {
    code: 'order_number',
    title: 'order_number',
    visible: true
  },
  status: {
    code: 'status',
    title: 'status',
    visible: true
  },
  message: {
    code: 'message',
    title: 'message',
    visible: true
  },
  created_at: {
    code: 'created_at',
    title: 'created_at',
    visible: true,
    editType: 'date'
  },
  updated_at: {
    code: 'updated_at',
    title: 'Updated at',
    visible: true,
    editType: 'date'
  }
}
