import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import BundleBody from './BundleBody/BundleBody'
import { useSetRecoilState } from 'recoil'
import { setScPageHeaderTitleCmd } from '../../app/data/currentHeadingState'
import { useFetchBundleItemCmd } from '../infrastructure/apisV2'

const BundleDetail = (props) => {
  const [product, setProduct] = useState(false)
  const location = useLocation()
  const search = location.search
  const params = new URLSearchParams(search)
  const productId = params.get('id')
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)

  const fetchItemCmd = useFetchBundleItemCmd(
    { id: productId },
    ({ data: d }) => {
      const bundle = d?.data[0]
      console.log('fetchItemCmd bundle', bundle)
      setProduct(bundle)
      return bundle
    }
  )

  useEffect(() => {
    fetchItemCmd()
  }, [])

  useEffect(() => {
    console.log('Use effect product', product)
    if (!product) return
    const { sku } = product.parent || { sku: '' }
    setHeading(`Bundle product`)
  }, [product])

  const reload = () => {
    setProduct(false)
    fetchItemCmd()
  }

  return (
    <div>
      {product && (
        <BundleBody reload={reload} id={productId} product={product} />
      )}
    </div>
  )
}

export default BundleDetail
