import { useEffect, useState } from 'react'
import { fetchClients } from '../infrastructure/v1/apis'

const useClients = () => {
  const [clients, setClients] = useState([])
  const [loadingError, setLoadingError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const getClients = async () => {
    const { data: clients, ...serviceInfo } = await fetchClients({})
    console.log('get clients', clients)
    Array.isArray(clients)
      ? setClients(clients) && setLoadingError('')
      : setLoadingError(
        'Error in clients fetching ' + JSON.stringify(serviceInfo)
      )

    return clients
  }
  useEffect(() => {
    setIsLoading(true)
    const clients = getClients()
    console.log('fetch clients', clients)
    setIsLoading(false)
  }, [])
  return { data: clients, isLoading, loadingError }
}

export default useClients
