import styled from 'styled-components'

const OrderItem = styled.li`
  min-width: 700px;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: ${({ theme }) => theme.spacing.secondary} !important;
  box-sizing: border-box;
  position: relative;
  :last-child {
    margin-bottom: 0 !important;
  }
  box-shadow: ${({ theme }) => theme.shadow.main};
`

export default OrderItem