import React, { useEffect, useState } from 'react'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import SingleProductCancel from './SingleProductCancel'

// should an order be on hold in order to cancel items?
// this requirement isn't on the backend DB.

const CancelItemsModal = (props) => {
  const [availableItemsToCancel, setAvailableItemsToCancel] = useState({})
  const determineCancellableItems = (items) => {
    const availableItems = items.filter(
      (item) => item.status !== 'cancelled' && item.status !== 'shipped'
    )
    console.log('filter items,availableItems', items, availableItems)

    return availableItems
  }
  useEffect(() => {
    const orderItems = props.order.orderItems
    setAvailableItemsToCancel(determineCancellableItems(orderItems))
  }, [props.order])

  // TODO: handle confirmation

  return (
    <ScConfirmationAlert
      isShowAttention={props.showModal}
      onClose={props.toggleShowModal}
      message={{
        title: 'Please select the item to cancel',
        content: (
          <div>
            {availableItemsToCancel.length > 0
              ? availableItemsToCancel.map((product) => (
                  <SingleProductCancel
                    key={product.id}
                    item={product}
                    order={props.order}
                    toggleShowModal={props.toggleShowModal}
                    updateOrderInDetailState={props.updateOrderInDetailState}
                    toggleShowConfirmationModal={
                      props.toggleShowConfirmationModal
                    }
                  />
                ))
              : <SnowTypography>{'0 products avaialable to cancel'}</SnowTypography>
            }
          </div>
        )
      }}
    />
  )
}

CancelItemsModal.propTypes = {
  // order: PropTypes.shape().isRequired,
  // history: PropTypes.shape().isRequired,
  // showModal: PropTypes.bool.isRequired,
  // toggleShowModal: PropTypes.func.isRequired,
  // updateOrderInDetailState: PropTypes.func.isRequired,
  // updateOrderInGridState: PropTypes.func.isRequired,
  // toggleShowConfirmationModal: PropTypes.func.isRequired
}

export default CancelItemsModal
