import React, { useCallback, useRef } from 'react'
import { SnowGrid, SnowBox } from '../../snowComponents'
import BulkGridItem from './components/BulkGridItem'

const BulkGrid = ({
  fields,
  gridItems = [],
  selectable = true,
  onItemClick = null,
  onItemDblClick = null,
  onItemEdit = null,
  onItemSave = null,
  onItemRemove = null,
  preselected = []
}) => {
  const updatedItems = useRef({})
  console.log('bulkGrid', preselected)

  //select item by row  click
  const handleItemClick = useCallback((item, props) => {
    if (!onItemClick) return
    console.log('handleRowClick', item, props)
    onItemClick(item)
  }, [])

  const handleItemFieldUpdate = useCallback(
    ({ item, code, value }) => {
      console.log('handleRowUpdateField', item, code, value)
      const data = { ...item.data, [code]: value }
      updatedItems.current[item.uid] = { ...item, data }

      console.log('updatedItems', updatedItems.current)
    },
    [updatedItems.current]
  )

  //remove row
  const handleItemRemove = useCallback((item) => {
    // console.log('handleRowRemove', item)
    // clean internal rows status lists
    // switchOffRowEdit(item.uid)
    // setSelectedRows( removeElementInCollection( selectedRows, key ) )

    //call parental removing
    onItemRemove(item)
  }, [])

  const isEditable = onItemEdit || onItemSave || onItemRemove
  const hasItems = gridItems.length

  return (
    <>
      {!!fields && !!hasItems && (
        <SnowBox pt={3}>
          <SnowGrid container spacing={2}>
            {gridItems.map(({ uid, data, meta }) => (
              <BulkGridItem
                xs={3}
                fields={fields}
                key={uid}
                item={{ uid, data, meta }}
                isEditable={isEditable}
                inEditMode={false}
                handleItemClick={handleItemClick}
                handleItemDblClick={onItemDblClick}
                handleItemFieldUpdate={handleItemFieldUpdate}
                handleItemRemove={handleItemRemove}
                selectable={selectable}
              />
            ))}
          </SnowGrid>
        </SnowBox>
      )}
    </>
  )
}
export default BulkGrid
