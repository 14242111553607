// function to GET an all products infoghjc
import React, { Fragment } from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { GET_ORDER_LINK } from '../../orders/infrastructure/const'
import { ScJSONEditField } from '../../../shared/components/SnowEditableField/baseFields'
import ScList from '../../../shared/components/widgets/ScList/ScList'

const TextOutput = ({ values }) => {
  return (
    <div style={{ paddingTop: '2rem' }}
    >
      <ScList itemProps={{ divider: true }}>
        {Object.entries(values).map(([key, value]) => (
          <Fragment key={key}>{` ${key}: ${value} `}</Fragment>
        ))}
      </ScList>
    </div>
  )
}
const ImageOutput = ({ imageUrl }) => {
  return (
    <>
      <div
        style={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <Zoom>
          <img
            style={{
              backgroundColor: '#ddd',
              backgroundImage:
                'linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)',
              backgroundSize: '8px 8px',
              backgroundPosition: '0 0,  0 4px, 4px -4px,  4px 0px',
              maxHeight: '150px'
            }}
            src={`${imageUrl}`}
          />
        </Zoom>
      </div>
    </>
  )
}
export const defaultFieldList = [
  {
    code: 'preview',
    title: 'Preview',
    required: false,
    type: 'string',
    renderer: ({ type: personalizer, previewType = 'text', preview }) => {
      console.log('preview renderer', personalizer, preview)
      const { refnum, image_url, values, troubles } = preview || {}
      const jsonStr = JSON.stringify(preview)

      const imageOutput = image_url && <ImageOutput imageUrl={image_url}/>
      const textOutput = values && <TextOutput values={values}/>

      const jsonOutput = (
        <div style={{ paddingTop: '2rem'}} >
          <ScJSONEditField
            label={'Preview'}
            labelPlacement='top-big'
            value={jsonStr}
            viewOnly
            height={120}
          />
        </div>
      )

      // url, preview, output)
      return (imageOutput || textOutput) ?? jsonOutput
    }
  },
  {
    code: 'item_id',
    title: 'Id',
    type: 'string',
    editable: false
  },
  {
    code: 'item_name',
    title: 'Name',
    type: 'string',
    editable: false
  },
  {
    code: 'order_id',
    title: 'Order Id',
    type: 'string',
    renderer: ({ order_id, order_number }) => {
      const urlToOrder = GET_ORDER_LINK(order_id)
      return (
        <>
          Order Number:
          <a
            rel={'noreferrer'}
            style={{ paddingLeft: '0.3em', zIndex: '10' }}
            href={urlToOrder} onClick={(e) => {
            e.stopPropagation()
          }}
          >
            {order_number}
          </a>
        </>
      )
    }
  },
  {
    code: 'sales_channel',
    title: 'Sales Channel',
    type: 'string'
  },
  {
    code: 'moderation_status',
    title: 'Status',
    type: 'string'
  }

  // {
  //   code: 'created_at',
  //   title: 'Created At',
  //   type: 'string'
  // }
]
