import React from 'react'
import { ReactComponent as CancelImg } from '../../../../../app/images/SVGs/cancel.svg'
import { ScWhiteButton } from '../../../../../../shared/components/buttons'
import PropTypes from 'prop-types'

const CancelOrderItemsButton = (props) => {
  // TODO: add disabled handler
  const handleClick = () => {
    // TODO: check if disabeled with if condition
    props.toggleShowCancelItemsModal()
  }

  return (
    <ScWhiteButton
      onClick={handleClick}
      label={'Cancel Items'}
      icon={() => <CancelImg />}
    />
  )
}

CancelOrderItemsButton.propTypes = {
  toggleShowCancelItemsModal: PropTypes.func.isRequired
}

export default CancelOrderItemsButton
