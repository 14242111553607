import React, { useState, useContext } from 'react'
import { useProduct } from '../../context/product'
import { ReactComponent as BoxIcon } from '../../../app/images/SVGs/box.svg'
import ScImageModal from '../../../../shared/components/modals/ScImageModal'
import { SnowTypography, SnowList } from '../../../../shared/components/snowComponents'
import { ProductNoImageView, ProductImageView } from './_styled'
import { ThemeContext } from 'styled-components'

const ProductImages = () => {
  const theme = useContext(ThemeContext)
  const { product } = useProduct()

  const {
    values: {
      product: { images }
    }
  } = product
  const [modalContent, setModalContent] = useState(null)

  return (
    <>
      {/*todo: uncomment <SidebarSubtitleView> and fix 'Maximum call stack size exceeded' issue*/}
      {/*<SidebarSubtitleView>Image</SidebarSubtitleView>*/}
      {images?.length ? (
        <SnowList disablePadding={true}>
          {images.map(({ url, position }, idx) => (
            <ProductImageView
              key={idx}
              onClick={() => setModalContent({ url, position, isOpen: true })}
            >
              <img src={url} alt={'image'} />
            </ProductImageView>
          ))}
        </SnowList>
      ) : (
        <ProductNoImageView>
          <BoxIcon />
          <SnowTypography
            variant="h4"
            component="p"
            color={theme.mainPalette.warning.secondary}
          >
            No Image Yet
          </SnowTypography>
        </ProductNoImageView>
      )}
      {modalContent && (
        <ScImageModal
          url={modalContent.url}
          title={modalContent.position}
          isOpen={modalContent.isOpen}
          onClose={() => setModalContent({ ...modalContent, isOpen: false })}
        />
      )}
    </>
  )
}

export default ProductImages
