import { useEffect, useState } from 'react'
import { useEntityCommand } from '../../../../../infrastructure/api/v2/useEntityApi'
import useFetchedResult from '../../../../../infrastructure/api/v2/useFetchedResult'
import { useSnowShippingMethods } from '../../../../settings/SnowShippingMethods/data'
import objWithParent from '../../../../../utils/parenter'
import { responseHandler } from '../../../../../infrastructure/api/utils'

const CFG_CHANNEL_SHIPPING_METHODS = objWithParent({
  url: '/portal/v1/salesChannelsShippingMethods',
  FETCH: {
    urlType: 'root',
    url: '/portal/v1/salesChannelsShippingMethods',
    method: 'GET',
    caller: 'CHANNEL_SHIPPING_METHODS LIST!',
    validator: () => true
  },
  CREATE: {
    urlType: 'root',
    url: 'create',
    method: 'post',
    caller: 'CHANNEL_SHIPPING_METHODS CREATE!',
    validator: ({
      platform_id,
      snow_shipping_method_id,
      shop_id,
      sales_channel_id,
      type,
      value
    }) => true
  },
  BULK_UPDATE: {
    urlType: 'root',
    url: 'update',
    method: 'post',
    caller: 'CHANNEL_SHIPPING_METHODS BULK_UPDATE!',
    validator: (methods) => true
  },
  ITEM: {
    urlType: 'root',
    url: '/',
    method: 'get',
    caller: 'CHANNEL_SHIPPING_METHODS ITEM!',
    validator: ({ id }) => !!id
  },
  ITEM_REMOVE: {
    urlType: 'root',
    url: 'remove',
    method: 'get',
    caller: 'CHANNEL_SHIPPING_METHODS ITEM_REMOVE!',
    validator: ({ id }) => !!id
  },
  ITEM_UPDATE: {
    urlType: 'root',
    url: 'update',
    method: 'post',
    caller: 'CHANNEL_SHIPPING_METHODS ITEM_UPDATE!',
    validator: ({ snow_shipping_method_id, sales_channel_id, type, value }) =>
      true
  }
})
export const useChannelFields = () => {
  const snowMethods = useSnowShippingMethods()

  const fields = [
    {
      code: 'id',
      visible: false,
      title: 'Id',
      editType: 'text',
      editable: false,
      tooltip: 'SA internal shipping method Id'
    },
    {
      code: 'channelId',
      visible: false,
      title: 'Channel Id',
      editType: 'text',
      editable: false,
      tooltip: 'Sales Channel Id'
    },
    {
      code: 'channelShippingValue',
      visible: true,
      title: 'Value',
      editType: 'text',
      tooltip: 'Shipping method`s Value from sales channel',
      required: true,
      fullWidth: true
    },
    {
      code: 'channelShippingType',
      visible: true,
      disablePadding: false,
      title: 'Type',
      editType: 'select',
      options: [{ code: 'Code' }, { pattern: 'Pattern' }],
      tooltip: 'Shipping method value`s Type',
      required: true
    },
    {
      code: 'snowShippingMethodId',
      visible: true,
      disablePadding: false,
      title: 'Snow Method',
      editType: 'select',
      options: snowMethods.map(({ id, title }) => ({ [id]: title })),
      tooltip: 'Associated Snow shipping method',
      required: true
    }
  ]

  return fields
}

const entityToRequest = ({
  channelId = 0,
  snowShippingMethodId,
  channelShippingValue,
  projectId = 0,
  platformId = 0,
  channelShippingType
}) => ({
  snow_shipping_method_id: snowShippingMethodId,
  sales_channel_id: channelId,
  type: channelShippingType,
  value: channelShippingValue,
  shop_id: projectId,
  platform_id: platformId
})
const entityFromRequest = ({
  snow_shipping_method_id,
  sales_channel_id,
  value,
  type,
  shop_id = 0,
  id,
  platform_id = 0,
  ...rest
}) => ({
  id: id, //by serverside, r/o, required, visible
  channelId: sales_channel_id,
  snowShippingMethodId: snow_shipping_method_id,
  channelShippingValue: value,
  projectId: shop_id,
  platformId: platform_id,
  channelShippingType: type,
  notHandled: rest //r/o
})

export const generateChannelShippingMapping = ({ channelId }) => {
  console.log('generateChannelShippingMapping', channelId)
  return {
    id: 0,
    snowShippingMethodId: '',
    channelId,
    channelShippingType: '',
    channelShippingValue: ''
  }
}
export const useChannelShippingMethodCreate = (modelToCreate) => {
  const dataToCreate = {
    data: [entityToRequest(modelToCreate)]
  }
  // const responseHandler = (response) => {
  //   console.log('responsehandler', response)
  //   const {
  //     data: { data: createdItems, success, message }
  //   } = response
  //   return {
  //     data: createdItems.map((item) => entityFromRequest(item)),
  //     success,
  //     message
  //   }
  // }
  console.log('dataToCreate', dataToCreate)

  const createCmd = useEntityCommand(
    0,
    {
      ...CFG_CHANNEL_SHIPPING_METHODS.CREATE,
      params: dataToCreate
    },
    (r) =>
      responseHandler(
        r,
        (data) => {
          const { data: createdItems, success, message } = data
          return {
            data: createdItems.map((item) => entityFromRequest(item)),
            success,
            message
          }
        },
        (error) => {}
      )
  )
  return createCmd
}
export const useChannelShippingMethodUpdate = ({ id, ...modelToUpdate }) => {
  const dataToUpdate = {
    data: entityToRequest(modelToUpdate)
  }
  const responseHandler = (response) => {
    console.log('responseHandler responsehandler', response)
    const {
      data: { data: createdItems, success, isLoading, message }
    } = response

    console.log('responseHandler', createdItems, success, message)

    if (!success) return { message }

    return {
      data: createdItems.map((item) => entityFromRequest(item)),
      success,
      isLoading
    }
  }
  console.log('modelToUpdate, dataToUpdate', modelToUpdate, dataToUpdate)
  const updateCmd = useEntityCommand(
    id,
    {
      ...CFG_CHANNEL_SHIPPING_METHODS.ITEM_UPDATE,
      params: dataToUpdate
    },
    responseHandler
  )
  return updateCmd
}
export const useChannelShippingMethodRemove = ({ id }) => {
  console.log('idToRemove', id)
  const removeCmd = useEntityCommand(id, {
    ...CFG_CHANNEL_SHIPPING_METHODS.ITEM_REMOVE,
    params: { id }
  })
  return removeCmd
}

export const useChannelShippingMethodsModel = ({ channelId }) => {
  const { data } = useFetchedResult({
    ...CFG_CHANNEL_SHIPPING_METHODS.FETCH,
    params: { sales_channel_id: channelId }
  })
  console.log('useChannelShippingMethodsModel data', data)
  const [shippingMappings, setShippingMappings] = useState([])

  useEffect(() => {
    const shippingMethods =
      data && data.data && data.data.map((item) => entityFromRequest(item))
    shippingMethods && setShippingMappings(shippingMethods)
  }, [data])

  return { data: shippingMappings }
}
