import { atom, selector } from 'recoil'
import { clearProviderAuth, clearSaUser, keepProviderAuth } from './authInfra'
import { extractFromStorage } from '../../../infrastructure/service/localStorage/helper'

const providerData = atom({
  key: 'providerData',
  default: null
})

export const providerAuth = selector({
  /**/
  key: 'providerAuth',
  get: ({ get }) => {
    const operativeAuth = get(providerData) //take operative value
    const storedAuth = extractFromStorage('providerAuth')
    const actualAuth = operativeAuth ? operativeAuth : storedAuth
    const expirationAt =
      actualAuth && actualAuth.tokenObj && actualAuth.tokenObj.expires_at
    if (
      !expirationAt ||
      (expirationAt && new Date(expirationAt).valueOf() < new Date().valueOf())
    ) {
      clearSaUser()
      clearProviderAuth()
      console.log(`ProviderAuth expired at ${expirationAt}. Auth is cleared.`)
      return null
    }
    console.log('expirationAt date', new Date(expirationAt))
    return actualAuth
  },
  set: ({ get, set }, newAuth) => {
    if (newAuth) {
      keepProviderAuth(newAuth)
      set(providerData, newAuth)
      console.log('setProviderAuth newAuth', newAuth)
    } else {
      clearProviderAuth()
    }
  }

  // profileObj: {
  //   email: '',
  //   familyName: '',s/auth/hooks/useAuthToken.jsx:18
  //   givenName: '',
  //   googleId: '',
  //   imageUrl: '',
  //   name: ''
  // },
  // tokenObj: {
  //   access_token: '',
  //   expires_at: 0,
  //   expires_in: 0,
  //   first_issued_at: 0,
  //   id_token: '',
  //   idpId: 'google',
  //   login_hint: '',
  //   scope: '',
  //   token_type: 'Bearer'
  // }
})
