import React, { useContext } from 'react'
import { ScIconButton } from '../buttons'
import {
  SidebarSubtitleView,
  SidebarBlockTitleView
} from '../../../domains/orders/OrderDetail/DetailSidebar/components/_styled'
import { ThemeContext } from 'styled-components'

function ScTitleWithTrigger({
  title,
  onTrigger = () => console.log('triggered'),
  TriggerIcon
}) {
  const theme = useContext(ThemeContext)

  return (
    <SidebarBlockTitleView>
      <SidebarSubtitleView>{title}</SidebarSubtitleView>
      <ScIconButton
        size={30}
        aria-label="Trigger"
        onClick={() => onTrigger()}
        color={theme.mainPalette.grey[200]}
      >
        <TriggerIcon />
      </ScIconButton>
    </SidebarBlockTitleView>
  )
}

export default ScTitleWithTrigger
