import React, { useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import SnowEditableField from '../../../shared/components/SnowEditableField'
import filtersState from '../../../shared/components/BulkComponents/data/filterState'

const fakeFilters = [
  {
    field: 'personalizer_status',
    options: ['pending', 'processing', 'moderation', 'ready', 'error']
  }
]

const FilterField = ({ field }) => {
  const { options } = field
  const code = field.field

  const [filterValue, setFilterValue] = useRecoilState(filtersState(code))
  const resetFilterValue = useResetRecoilState(filtersState(code))
  const onFilterFieldUpdate = ({ code, value }) => {
    console.log('onFilterFieldUpdate', { code, value })
    setFilterValue(value)
  }
  useEffect(() => {
    return resetFilterValue()
  }, [])
  return (
    <SnowEditableField
      code={code}
      edit={true}
      label={code}
      meta={{
        editType: 'select',
        options: options.map((option) => ({ [option]: option }))
      }}
      value={filterValue}
      onUpdate={onFilterFieldUpdate}
    />
  )
}
/**
 *
 * @param filters [
    "personalizer_status": {
        "options": [
            "pending",
            "processing",
            "moderation",
            "ready",
            "error"
        ]
    }
}]
 * @returns {JSX.Element}
 * @constructor
 */
const FiltersBar = ({ filters: filterFields }) => {
  const [filters, setFilters] = useState([])
  const normalizeFilters = (filterObj) => filterObj
  useEffect(() => {
    setFilters(normalizeFilters(filterFields))
  }, [filterFields])
  console.log('FiltersBar', filterFields, filters)
  return (
    <>
      {filters.map((field) => (
        <FilterField key={field.field} field={field} />
      ))}
    </>
  )
}

export default FiltersBar
