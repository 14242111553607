import { useEffect, useCallback, useRef, useState } from 'react'
import { fetchFields, fetchProducts } from '../infrastructure/apiV1'
import useChannels from '../../integrations/hooks/useChannels'
import { setElementInCollectionByKey } from '../../../utils/helpers'
// make API calls and pass the returned data via dispatch
export const useFetchProducts = ({ data: { page }, search, productsDispatch }) => {
  const [oldSearch, setOldSearch] = useState({})
  const isLoading = useRef(0)
  const { data: channels } = useChannels()

  const marketplaceRenderer = (value) => {
    const val = channels?.find(({ marketplace_id }) => String(marketplace_id) === String(value))?.marketplace_name ?? value
    return val
  }
  console.log('useFetchProducts render:', { search, oldSearch, page })

  const getProducts = async ({ page, search, productsDispatch }) => {
    const { data: expectedProducts } = await fetchProducts({
      page,
      search
    })
    const products = expectedProducts.length ? expectedProducts : []

    console.log('getProducts!', { page, oldSearch, search, products })
    if (oldSearch.sku !== search.sku || oldSearch.channel !== search.channel)
      productsDispatch({ type: 'RESET_PRODUCTS', products })

    else productsDispatch({ type: 'STACK_PRODUCTS', products })

    isLoading.current = isLoading.current - 1
    productsDispatch({ type: 'FETCHING_DATA', fetching: isLoading.current })

    setOldSearch({ ...search })

    return products
  }
  useEffect(() => () => console.log('useFetchProducts destructed. page', page), [])
  useEffect(
    () => {
      console.log('useFetchProducts useEffect ', { search, page, productsDispatch })

      if (page === 0) return
      productsDispatch({ type: 'FETCHING_DATA', fetching: true })
      isLoading.current = isLoading.current + 1
      // productsDispatch({ type: 'SET_SEARCH', search: search })
      const products = getProducts({
        page,
        search,
        productsDispatch
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, search]
  )

  useEffect(() => {
    isLoading.current = isLoading.current + 1
    fetchFields()
      .then((columns) => {
        console.log('fetched products fields', columns)
        columns.length = 8
        const marketplaceField = {
          ...(columns.find(({ code }) => code === 'marketplace_id') ?? {}),
          title: 'Channel',
          renderer: marketplaceRenderer
        }
        const columnsWithUpdatedTitles = columns.map(({ title, ...props }) => ({
          ...props,
          title: title.replace('market', 'channel').replace('Market', 'Channel').replace('_', ' ')
        }))
        const updatedColumns = setElementInCollectionByKey(columnsWithUpdatedTitles, marketplaceField, 'code')
        console.log('updatedColumns', updatedColumns)
        isLoading.current = isLoading.current - 1
        productsDispatch({ type: 'SET_COLUMNS', columns: updatedColumns })
        productsDispatch({ type: 'FETCHING_DATA', fetching: isLoading.current })
      })
      .catch((e) => {
        // handle error
        console.log('fetched fields exceptions')

        isLoading.current = 0
        productsDispatch({ type: 'FETCHING_DATA', fetching: isLoading.current })
        return e
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsDispatch])
}


// infinite scrolling with intersection observer
export const useInfiniteScroll = (scrollRef, dispatch) => {
  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            dispatch({ type: 'ADVANCE_PAGE' })
          }
        })
      }).observe(node)
    },
    [dispatch]
  )

  useEffect(() => {
    if (scrollRef.current) {
      scrollObserver(scrollRef.current)
    }
  }, [scrollObserver, scrollRef])
}
