import React, {useContext} from 'react'
import JSONInput from 'react-json-editor-ajrm'
import locale from 'react-json-editor-ajrm/locale/en'
import themes from 'react-json-editor-ajrm/themes'
import { ScFieldLabel } from '../../../LayoutComponents'
import { ThemeContext } from 'styled-components'

const ScJSONEditComponent = ({
  onUpdate = (...up) => {
    console.log('ScJsonEditField update', up)
  },
  onClick,
  meta,
  value,
  code,
  label,
  updateDelay,
  required,
  viewOnly: viewonly,
  ...props
}) => {
  const theme = useContext(ThemeContext)
  const { ...rest } = props
  const lightPalette = themes.light_mitsuketa_tribute
  const darkPalette = {
    ...themes.dark_mitsuketa_tribute,
    background: theme.mainPalette.color.main
  }
  const palette = props.viewOnly ? lightPalette : darkPalette

  const placeholder = typeof value === 'string' ? JSON.parse(value) : value
  const isValidPlaceholder = typeof placeholder === 'object'
  console.log('ScJsonEditField', rest)
  return (
    <JSONInput
      locale={locale}
      id={code}
      placeholder={(isValidPlaceholder && placeholder) || {}}
      onChange={(editObj) => {
        console.log('JSON onChange', editObj)
        onUpdate({ value: editObj.jsObject })
      }}
      modifyErrorText={() => 'Incorrect JSON syntax'}
      colors={palette}
      viewonly={viewonly}
      height="150px"
      width="100%"
      // waitAfterKeyPress='3000'
      {...rest}
    />
  )
}

const ScJSONEditField = ({
  onUpdate = (...up) => {
    console.log('ScJsonEditField update', up)
  },
  onClick,
  meta,
  value,
  code,
  label,
  labelPlacement = 'top',
  fontSize = '',
  isGroupField = false,
  mb = 0,
  inputWidth = 100,
  updateDelay,
  required,
  viewOnly: viewonly,
  ...props
}) => {
  const labelProps = {
    label,
    code,
    labelPlacement,
    isGroupField,
    inputWidth,
    mb,
    fontSize
  }
  return (
    <ScFieldLabel {...labelProps}>
      <ScJSONEditComponent
        onUpdate={onUpdate}
        onClick={onClick}
        meta={meta}
        value={value}
        code={code}
        updateDelay={updateDelay}
        required={required}
        viewonly={viewonly}
      />
    </ScFieldLabel>
  )
}

export default ScJSONEditField
