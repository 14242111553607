import React, { Fragment, useEffect, useReducer, useRef } from 'react'
import { useSetRecoilState } from 'recoil'
import { ScComboField } from '../../../shared/components/SnowEditableField/baseFields'
import { ScPageContentWithHeader } from '../../../shared/components/LayoutComponents'
import useChannels from '../../integrations/hooks/useChannels'
import ProductsTable from './ProductsTable'
import ScSpinner from '../../../shared/components/ScSpinner/ScSpinner'
import { useFetchProducts, useInfiniteScroll } from './customHooks'
import { setScPageHeaderTitleCmd } from '../../app/data/currentHeadingState'
import UpdatePimValuesBtn from './UpdatePimValues/UpdatePimValuesBtn'

const pageReducer = (state, action) => {
  console.log('pageReducer dispatch', action, state)

  switch (action.type) {
    case 'ADVANCE_PAGE':
      console.log('advance page', state.page)
      return { ...state, page: state.page + 1 }
    case 'RESET':
      console.log('reset page from ', state.page)
      return { ...state, page: 0 }

    default:
      return state
  }
}
const productsReducer = (state, action) => {
  console.log('productsReducer dispatch', action, state)
  switch (action.type) {
    case 'STACK_PRODUCTS':
      return { ...state, products: state.products.concat(action.products) }
    case 'RESET_PRODUCTS':
      return { ...state, products: action.products }
    case 'FETCHING_DATA':
      return { ...state, fetching: action.fetching }
    case 'SET_COLUMNS':
      return { ...state, columns: action.columns }
    case 'SET_SEARCH':
      return { ...state, search: { ...state.search, ...action.search } }
    default:
      return state
  }
}

const ProductHubGrid = (props) => {
  const [productsData, productsDispatch] = useReducer(
    productsReducer,
    {
      products: [],
      columns: [],
      fetching: 0,
      search: {}
    },
    (args) => args
  )
  const { data: channels } = useChannels()
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)
  const [pager, pagerDispatch] = useReducer(
    pageReducer,
    { page: 1 },
    (args) => args
  )
  const search = useRef({})
  const bottomBoundaryRef = useRef(null)

  useEffect(() => {
    setHeading('Products Hub')
  }, [])

  useFetchProducts({
    data: pager,
    search: productsData.search,
    productsDispatch
  })

  const onSkuFilterChange = ({ value }) => {

    if (search.current.sku === value) return
    search.current.sku = value

    console.log('skuChannelFilter! change', value, search.current)
    pagerDispatch({ type: 'RESET' })
    productsDispatch({
      type: 'SET_SEARCH',
      search: { sku: value }
    })
    pagerDispatch({ type: 'ADVANCE_PAGE' })
  }

  const onChannelFilterChange = ({ value }) => {
    console.log('channelFilter!', value, search.current)

    if (search.current.channel === value) return

    search.current.channel = value
    pagerDispatch({ type: 'RESET' })
    productsDispatch({
      type: 'SET_SEARCH',
      search: { channel: value }
    })
    pagerDispatch({ type: 'ADVANCE_PAGE' })
  }

  console.log('productsData', channels, search.current, productsData)

  useInfiniteScroll(bottomBoundaryRef, pagerDispatch)



  return (
    <ScPageContentWithHeader
      formFields={
        [
          <Fragment key={'filter-by-sku'}>
            <ScComboField
              value={productsData.search.sku}
              onUpdate={onSkuFilterChange}
              tooltipNewValue={false}
              disableIndicatorNewValue={true}
              placeholder={'filter'}
              label={'By SKU'}
              tooltip={`Type SKU for search`}
            />
          </Fragment>,
          <Fragment key={'filter-by-sales-channel'}>
            <ScComboField
              meta={{
                options: channels,
                optionsDef: { value: 'marketplace_id', label: 'marketplace_name' }
              }}
              disableIndicatorNewValue={true}
              value={
                channels?.find(
                  ({ marketplace_id }) =>
                    marketplace_id === productsData.search.channel
                )?.marketplace_name
              }
              onUpdate={onChannelFilterChange}
              placeholder={'filter'}
              label={'By Sales Channel'}
              tooltip={`Type Sales Channel for search`}
            />
          </Fragment>
        ]
      }
      actionContent={
        <Fragment key={'update-pim-values'}>
          <UpdatePimValuesBtn />
        </Fragment >
      }
      pageContent={
        <>
          <div aria-label='product-grid'>
            {productsData.products && productsData.columns && (
              <ProductsTable
                columns={productsData.columns}
                products={productsData.products}
              />
            )}
            {!!productsData.fetching && <ScSpinner />}
          </div>
          <div
            id="page-bottom-boundary"
            ref={bottomBoundaryRef}
          >
            {' '}
          </div>
        </>
      }
    />
  )
}

export default ProductHubGrid
