import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  fetchProductBySaId,
  fetchProductSearchRemoved,
  updateProductDetails
} from "../infrastructure/apiV1";
import "../../app/scss/component/product-detail.scss";
import ResyncModal from "./Modals/ResyncModal.jsx";
import ConfirmationModal from "./Modals/ConfirmationModal.jsx";
import { ProductContext } from "../context/product";
import { setScPageHeaderTitleCmd } from "../../app/data/currentHeadingState";
import { ScEntityDetailsPage } from "../../../shared/components/LayoutComponents/";
import HeaderLinks from "./DetailHeader/HeaderLinks";
import HeaderActions from "./DetailHeader/HeaderActions";
import ProductAttributes2 from "./DetailBody/ProductAttributesV2";
import ProductSidebar from "./DetailSidebar/ProductSidebar";
import _ from "lodash";
import PropTypes from "prop-types";

export function getDateStr (timestamp) {
  const dateObj = new Date(timestamp);
  const locale = "en-US";
  const dateOptions = {
    month: "short",
    day: "numeric",
    year: "numeric"
  };
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short"
  };
  return `on ${dateObj.toLocaleDateString(
    locale,
    dateOptions
  )} at ${dateObj.toLocaleTimeString(locale, timeOptions)}`;
}

const ProductDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [removedProduct, setRemovedProduct] = useState(null);
  const [showResyncModal, setShowResyncModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [response, setResponse] = useState({});
  const [showNoDataAlert, setShowNoDataAlert] = useState(false);
  const [showMessage, setShowMessage] = useState(null);
  const [editState, setEditState] = useState(false);
  const updatedAttributes = useRef({});
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd);

  const getProduct = () => {
    const marketPlaceID = location.pathname.split("/")[4].split("=")[1];
    const productID = location.pathname.split("/")[5].split("=")[1];
    const saProductId = location.pathname.split("/")[6].split("=")[1];
    console.log("getProduct", productID, marketPlaceID);
    if (!saProductId && !productID) {
      setShowNoDataAlert(true);
    } else if (!saProductId) {
      fetchProductSearchRemoved({
        marketplaceId: marketPlaceID,
        marketProductId: productID
      }).then((d) => {
        console.log("getRemovedProduct then", d);
        if (d) {
          if (d.message) {
            setShowMessage(d.message);
          }
          if (d.data) {
            setRemovedProduct(d.data);
          }
          if (!d.message && !d.data) {
            setShowNoDataAlert(true);
          }
        }
      });
    } else {
      fetchProductBySaId({ saProductId })
        // .then((res) => {
        //   if (res.status === 404) {
        //     return setShowNoDataAlert(true)
        //   } else if (res.status === 401) {
        //     return navigate(`/`)
        //   } else {
        //     return res.json()
        //   }
        // })
        .then((d) => {
          console.log("getProduct then", d);
          if (d) {
            if (d.message) {
              setShowNoDataAlert(true);
            } else if (d.data) {
              setProduct(d.data);
            }
          }
        });
    }
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const {
      values: {
        product: { id }
      }
    } = product || { values: { product: { id: "" } } };
    console.log("productproductproduct", product);
    setHeading(`Product #${id} `);
  }, [product]);

  const onAttributeUpdate = ({ linePath, value, meta }) => {
    const { code, itemOf } = meta;
    updatedAttributes.current[`${linePath}`] = { value, meta };
    // const json = path
    console.log(
      "onAttributeUpdate1",
      linePath,
      meta,
      value,
      updatedAttributes.current
    );
  };
  const saveChanges = () => {
    const getCastedKey = (key, meta) => {
      const lastArrayStartPosition = key.lastIndexOf("[");
      const lastArrayEndPosition = key.lastIndexOf("]");
      const { itemOf } = meta;
      const isUniqArray = itemOf && itemOf.type === "uniq_array";
      const castedKey = _.trimEnd(
        lastArrayStartPosition === -1
          ? //if path hasn't array mention
          key // then save key:value 'as-is'
          : isUniqArray //if has array - check array type : 'array' or 'uniq_array'
            ? key // cast key to array item path for 'uniq_array'
            : key.slice(0, lastArrayStartPosition), // cast key to whole array
        // path
        // for 'array'
        "."
      );
      return castedKey;
    };

    const originValueItems = Object.entries(updatedAttributes.current).map(
      ([key, { meta }]) => {
        console.log("Initial Saving path", key, meta);
        const castedKey = getCastedKey(key, meta);
        console.log("castedKey", castedKey);
        const updatedPart = _.pick(product.values, castedKey);
        return updatedPart;
      }
    );

    const originObjData = _.merge({}, ...originValueItems);
    // const originArrayProcessedItems = Object.entries(originObjData)
    const originCopiedData = _.cloneDeep(originObjData);

    const newObjData = Object.entries(updatedAttributes.current).map(
      ([key, { value, meta }]) => {
        const castedKey = _.trimEnd(key, "."); //getCastedKey(key, meta)
        console.log("newObjData castedKey", castedKey, value);

        const updatedPart = _.set(originCopiedData, castedKey, value);
        console.log("newObjData updatedPart", updatedPart, castedKey, value);
        return updatedPart;
      }
    );

    const savedObj = {
      originValues: originObjData,
      updatedValues: newObjData[0]
    };
    console.log("saveObj in Product", savedObj, product);
    const response = updateProductDetails({
      id: product.values.product.id,
      data: savedObj
    }).then((res) => {
      console.log("updateProductDetails res", res);
    });
    console.log("saveChanges response", response);
  };

  const toggleEditState = () => {
    if (!editState) {
      //if we go to editing state
      console.log("Toggling into edit state");
    } else {
      //if we go to saving state
      console.log("Toggling into saving");
      saveChanges();
    }
    setEditState(!editState);
  };

  const toggleShowResyncModal = () => {
    setShowResyncModal(!showResyncModal);
  };

  const toggleShowConfirmationModal = (response) => {
    setShowConfirmationModal(!showConfirmationModal);

    setResponse(response);
  };

  const updateProductInDetailState = (product) => {
    console.log("updateProductInDetailState product", product);
    setProduct(product);
  };
  // console.log('ProductInDetailState product', { product, created_at: product?.values.product.created_at }, getDate(product?.values.product.created_at))

  return (
    <ProductContext.Provider
      value={{
        product,
        editState,
        onAttributeUpdate
      }}
    >
      <div>
        {showNoDataAlert ? (
          <div>No data available for this product yet!</div>
        ) : null}
        {showMessage ? <div>{showMessage}</div> : null}
        {!!product && (
          <>
            <ResyncModal
              product={product}
              showModal={showResyncModal}
              toggleShowModal={toggleShowResyncModal}
              updateProductInDetailState={updateProductInDetailState}
              updateOrderInGridState={props.updateOrderInGridState}
              toggleShowConfirmationModal={toggleShowConfirmationModal}
            />
            <ConfirmationModal
              product={product}
              showModal={showConfirmationModal}
              toggleShowModal={toggleShowConfirmationModal}
              response={response}
            />
            <ScEntityDetailsPage
              pageLinks={<HeaderLinks />}
              title={product.values.product.marketplace_product_sku}
              titleExt={`added to Snow Axis ${getDateStr(
                product?.values.product.created_at
              )}`}
              actionsContent={
                <HeaderActions
                  product={product}
                  {...{ toggleEditState }}
                  {...{ editState }}
                  toggleShowResyncModal={toggleShowResyncModal}
                  toggleShowConfirmationModal={toggleShowConfirmationModal}
                />
              }
              entityContent={<ProductAttributes2 />}
              sidebarWidgets={<ProductSidebar />}
            />
          </>
        )}
      </div>
    </ProductContext.Provider>
  );
};

ProductDetails.propTypes = {
  updateOrderInGridState: PropTypes.func
};

export default ProductDetails;
