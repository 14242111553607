import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  SnowBox,
  SnowGrid,
} from '../../../../../shared/components/snowComponents'
import { ScTextField, ScDropdownField } from '../../../../../shared/components/SnowEditableField/baseFields'
import { ScBasicModal } from '../../../../../shared/components/modals'
import {
  ScPrimaryBtn,
} from '../../../../../shared/components/buttons'
import usePlatforms from '../../../hooks/usePlatforms'
import {
  useCreateChannelCmd,
  useCreateChannelConnectionCmd,
  useFetchFreeConnectionsCmd
} from '../../../infrastructure/v2/channelsApiConfig'
import { currentMessageState } from '../../../../../shared/service/Message/data/currentMessageState'
import useCurrency from '../../../hooks/useCurrency'

export const ChannelConnector = ({
  projectId,
  onClose = () => {
    console.log('onClose')
  }
}) => {
  const setMessage = useSetRecoilState(currentMessageState)
  const [open, setOpen] = useState(false)
  const [channelName, setChannelName] = useState('')
  const [platformId, setPlatformId] = useState('')
  const [currencyCode, setCurrencyCode] = useState('')
  const [connectionId, setConnectionId] = useState('')
  const [channelId, setChannelId] = useState('')
  const [freeConnections, setFreeConnections] = useState([])

  const currencies = useCurrency()
  const loadedPlatforms = usePlatforms()

  const platforms = [...loadedPlatforms].sort((a, b) => {
    const first = a.name.toUpperCase()
    const second = b.name.toUpperCase()
    return first > second ? 1 : first === second ? 0 : -1
  })
  // platforms.sort((a, b) => a.name > b.name)
  console.log(
    'typeof platforms',
    platforms,
    Array.isArray(platforms),
    typeof platforms
  )
  const [isReadyToCreate, setIsReadyToCreate] = useState(false)
  const [needsResolveConnection, setNeedsResolveConnection] = useState(false)
  const fetchFreeConnectionsCmd = useFetchFreeConnectionsCmd(({ data }) => {
    setFreeConnections(
      (Array.isArray(data) && data.sort((a, b) => a.name > b.name)) ?? []
    )
  })
  const createChannelConnectionCmd = useCreateChannelConnectionCmd(
    {
      channelId,
      connectionId
    },
    (resp) => {
      const { data, message } = resp
      setMessage({ message, severity: 'success' })
      console.log('createChannelConnectionCmd onSuccess', data)
      closeAndReload()
    },
    (resp) => {
      const { message, ...rest } = resp
      setMessage({ message, severity: 'error' })
      console.log('createChannelConnectionCmd onFail', rest)
      setOpen(false)
    }
  )

  const getChannelCreateDto = () => ({
    platformId,
    channelName,
    projectId,
    currencyCode,
    ...(freeConnections.length && { connectionId })
  })

  // const setChannelConnectionCmd = use
  const createChannelCmd = useCreateChannelCmd(
    getChannelCreateDto(),
    (resp) => {
      console.log('createChannelCmd onSuccess', resp)
      const {
        data: { marketplace_id: channelId },
        message
      } = resp
      setMessage({ message, severity: 'success' })
      setChannelId(channelId)
      connectionId ? setNeedsResolveConnection(true) : closeAndReload()
    },
    (resp) => {
      const { message, ...rest } = resp
      setMessage({ message, severity: 'error' })
      setOpen(false)
      console.log('createChannelCmd onFail', rest)
    }
  )
  const onOpenModal = () => {
    setOpen(true)
    console.log('Show new channel modal')
  }
  const closeAndReload = () => {
    setOpen(false)
    setTimeout(() => location.reload(), 2000)
  }
  const createChannel = () => {
    isReadyToCreate && createChannelCmd()
  }
  useEffect(() => {
    connectionId &&
      channelId &&
      needsResolveConnection &&
      createChannelConnectionCmd().then((resp) => {
        setOpen(false)
        setNeedsResolveConnection(false)
        console.log('createChannelConnectionCmd resp', resp)
      })
  }, [needsResolveConnection])

  useEffect(() => {
    const emptyFields = Object.values(getChannelCreateDto()).filter(
      (value) => !value
    )
    setIsReadyToCreate(!emptyFields.length)
  }, [
    platformId,
    projectId,
    channelName,
    connectionId,
    currencyCode,
    freeConnections
  ])

  useEffect(() => {
    platformId.toString() === '2'
      ? fetchFreeConnectionsCmd()
      : setFreeConnections([])
  }, [platformId])

  console.log(
    'platformsplatforms',
    {
      currencies,
      isReadyToCreate,
      platformId,
      platforms,
      freeConnections
    }
  )

  return (
    <>
      <ScBasicModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={createChannel}
        title={`Connect New Channel`}
        labelForConfirmBtn={'Create Channel'}
        onConfirmProps={{ disabled: !isReadyToCreate }}
        content={
          <>
            <SnowGrid container justifyContent={'flex-start'}>
              <SnowGrid item sm={8}>
                <SnowBox pr={1}>
                  <ScTextField
                    label={'Sales Channel Name'}
                    value={channelName}
                    required={true}
                    focused={true}
                    code={'marketplace_name'}
                    onUpdate={({ key, value }) => {
                      setChannelName(value)
                    }}
                  />
                </SnowBox>
              </SnowGrid>
              <SnowGrid item sm={2}>
                <SnowBox pr={2}>
                  <ScDropdownField
                    label={'Platform'}
                    value={platformId}
                    required={true}
                    focused={true}
                    code={'platform_id'}
                    onUpdate={({ key, value }) => {
                      setPlatformId(value)
                    }}
                    meta={{
                      options: platforms,
                      optionsDef: { value: 'id', label: 'name' }
                    }}
                  />
                </SnowBox>
              </SnowGrid>
              <SnowGrid item sm={2}>
                <SnowBox pr={2}>
                  <ScDropdownField
                    label={'Currency'}
                    value={currencyCode}
                    required={true}
                    focused={true}
                    code={'currency_code'}
                    onUpdate={({ key, value }) => {
                      setCurrencyCode(value)
                    }}
                    meta={{
                      options: currencies,
                      optionsDef: { value: 'code', label: 'name' }
                    }}
                  />
                </SnowBox>
              </SnowGrid>
            </SnowGrid>
            {!!freeConnections.length && (
              <SnowGrid container>
                <SnowGrid>
                  <ScDropdownField
                    label={'Connection'}
                    value={connectionId}
                    required={true}
                    focused={true}
                    code={'connection_id'}
                    onUpdate={({ value }) => {
                      setConnectionId(value)
                    }}
                    meta={{
                      options: freeConnections,
                      optionsDef: { value: 'id', label: 'domain' }
                    }}
                  />
                </SnowGrid>
              </SnowGrid>
            )}
          </>
        }
      />
      <ScPrimaryBtn label={`Connect New Channel`} onClick={onOpenModal} />
    </>
  )
}
export default ChannelConnector
