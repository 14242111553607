import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { removeEmptyProps, removeUndefinedProps } from '../../../utils/helpers'
import PropTypes from 'prop-types'

const API_VENDOR_REGIONS = objWithParent({
  url: '/vendorRegions',
  FETCH_VENDOR_REGIONS: {
    url: '',
    method: 'GET',
    caller: 'API_VENDOR_REGIONS FETCH_REGION_VENDORS',
    validator: ({ vendor_id, area_group_id, per_page, page }) => true
  },
  CREATE_VENDOR_REGION: {
    url: '',
    method: 'POST',
    caller: 'API_VENDOR_REGIONS CREATE',
    validator: ({ vendor_id, area_group_id, priority, status = true }) => true
  },
  FETCH_VENDOR_REGION: {
    url: '',
    method: 'GET',
    caller: 'API_VENDOR_REGIONS FETCH VENDOR REGION',
    validator: () => true
  },
  UPDATE_VENDOR_REGION: {
    url: '',
    method: 'PUT',
    caller: 'API_VENDOR_REGIONS UPDATE_VENDOR_REGION',
    validator: () => true
  }
})

export const useFetchVendorRegionsCmd = (
  { page = 1, per_page = 20, vendor_id, area_group_id },
  onSuccess = (r) => console.log('useFetchVendorRegionsCmd success', r),
  onFail = (r) => console.log('useFetchVendorRegionsCmd fail', r)
) =>
  useEntityCommand(
    null,
    {
      ...API_VENDOR_REGIONS.FETCH_VENDOR_REGIONS,
      params: removeEmptyProps({
        page,
        per_page,
        vendor_id,
        area_group_id
      })
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useCreateVendorRegionCmd = (
  { vendor_id, area_group_id, priority, status },
  onSuccess = (r) => console.log('useCreateVendorRegionCmd success', r),
  onFail = (r) => console.log('useCreateVendorRegionCmd fail', r)
) =>
  useEntityCommand(
    null,
    {
      ...API_VENDOR_REGIONS.CREATE_VENDOR_REGION,
      params: removeEmptyProps({ vendor_id, area_group_id, priority, status })
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useFetchVendorRegionCmd = (
  { id },
  onSuccess = (r) => console.log('useFetchVendorRegionCmd success', r),
  onFail = (r) => console.log('useFetchVendorRegionCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_VENDOR_REGIONS.FETCH_VENDOR_REGION,
      params: {}
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useUpdateVendorRegionCmd = (
  { id, vendor_id, area_group_id, priority, status },
  onSuccess = (r) => console.log('useUpdateVendorRegionCmd success', r),
  onFail = (r) => console.log('useUpdateVendorRegionCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_VENDOR_REGIONS.UPDATE_VENDOR_REGION,
      params: { vendor_id, area_group_id, priority, status }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
