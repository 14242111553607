export const routesList = {
  DASHBOAD:'order_hub/dashboard',
  ORDERS:'order_hub/grid',
  ORDER_ITEMS:'order_items',
  PRODUCT:'product_hub/grid',
  BUNDLE:'bundle_hub/grid',
  INTEGRATIONS:'integrations',
  MODERATION:'moderationqueue',
  BATCHES:'batches',
  REGIONS:'area/groups',
  REGIONS_ID:'area/groups/:areaId',
  CARRIERS:'carriers'
}