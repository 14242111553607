import { css } from 'styled-components';

const TableColumnTitleView = css`
  ${({ theme }) => `
    color: ${theme.mainPalette.typography.mainDark};
    font-weight: ${theme.fontWeight.main};
    font-size: ${theme.fontSize.secondaryTitle};
    text-transform: capitalized;
  `}
`

const TableRowContentView = css`
  ${({ theme }) => `
    color: ${theme.mainPalette.typography.main};
    font-weight: ${theme.fontWeight.thin};
    font-size: ${theme.fontSize.main};
  `}
`

export {
  TableColumnTitleView, TableRowContentView
}