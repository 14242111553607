import React from 'react'
import { SnowMenuIcon, SnowMenuOpenIcon } from '../../icons'
import { ScIconButton } from '../../buttons'
import styled from 'styled-components'

const MenuView = styled(ScIconButton)`
  grid-column: 1/2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  &.MuiIconButton-edgeStart {
    margin-left: -8px;
  }
  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.mainPalette.color.contrast};
  }
`
const ScMenuButton = ({ isOpen, onOpen, onClose, ...props }) => {
  return (
    <MenuView
      aria-label={`Open-${isOpen} drawer`}
      onClick={isOpen ? onClose : onOpen}
      edge="start"
      {...props}
    >
      {isOpen ? <SnowMenuOpenIcon /> : <SnowMenuIcon />}
    </MenuView>
  )
}

export default ScMenuButton
