import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  SnowTypography,
  SnowBox,
  SnowList,
  SnowListItem
} from '../../../../shared/components/snowComponents'
import OrdersToModerate from './OrdersToModerate'
import OrdersWithError from './OrdersWithError'
import { setScPageHeaderTitleCmd } from '../../../app/data/currentHeadingState'
import { fetchPageOfOrders } from '../../infrastructure/fetches'
import styled from 'styled-components'

const OrderHubListView = styled(SnowList)`
  margin: -8px;
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 1 0 30%;
    margin: 8px;
  }
`

const OrderHubItemView = styled(SnowListItem).attrs({
  disableGutters: true
})``

const OrderHubDashboard = () => {
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)
  const [newData, setNewData] = useState(null)

  useEffect(() => {
    setHeading('Dashboard')
    const fetchDataNoFilter = () => {
      //  fetch first page of 50 orders
      fetchPageOfOrders().then((response) => {
        console.log('fetchPageOfOrders', response)
        const { data } = response
        setNewData(data)
      })
    }
    fetchDataNoFilter()
  }, [])
  return (
    <SnowBox aria-label="dashboard">
      <SnowTypography
        component={'p'}
        variant={'caption'}
        fontWeight={400}
        gutterBottom={true}
      >
        Tip: Click on a <strong>light or dark blue bar</strong> to view a brief
        summary of each order, or use the arrow buttons to scroll through
        orders. Click on the <strong>order number</strong> to be redirected to
        full order details.
      </SnowTypography>
      <OrderHubListView>
        <OrderHubItemView>
          <OrdersToModerate />
        </OrderHubItemView>
        <OrderHubItemView>
          <OrdersWithError />
        </OrderHubItemView>
      </OrderHubListView>
      {/* <OrdersOverdue /> */}
    </SnowBox>
  )
}
export default OrderHubDashboard
