import React, { useRef, useState, useContext } from 'react'
import { ScJSONEditField } from '../../../../../../../../../../shared/components/SnowEditableField/baseFields'
import { ScIconButton } from '../../../../../../../../../../shared/components/buttons'
import {
  SnowPaper,
  SnowBox,
  SnowClickAwayListener,
  SnowPopper,
  SnowTypography
} from '../../../../../../../../../../shared/components/snowComponents'
import { SnowInfoIcon } from '../../../../../../../../../../shared/components/icons'
import styled, { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'

const StyledPaper = styled(SnowPaper)`
  width: 30em;
`

export const InfoModalBtn = ({ jsonData }) => {
  const ref = useRef()
  const [showRaw, setShowRaw] = useState(false)
  console.log('showRaw', showRaw)
  const theme = useContext(ThemeContext)

  return (
    <>
      <SnowClickAwayListener
        mouseEvent={'onClick'}
        onClickAway={() => setShowRaw(false)}
      >
        <ScIconButton
          size={30}
          ref={ref}
          color={theme.mainPalette.color.secondary}
          onClick={() => setShowRaw(!showRaw)}
        >
          <SnowInfoIcon fontSize={'small'} />
        </ScIconButton>
      </SnowClickAwayListener>
      <SnowPopper open={showRaw} anchorEl={ref.current} keepMounted={false}>
        <StyledPaper>
          <SnowBox p={2}>
            <SnowTypography>Personalization Raw Data</SnowTypography>
            <ScJSONEditField
              viewOnly={true}
              value={jsonData}
              code={'personalization_info'}
              label={'personalizationInfo raw data'}
            />
          </SnowBox>
        </StyledPaper>
      </SnowPopper>
    </>
  )
}
export default InfoModalBtn

InfoModalBtn.propTypes = {
  jsonData: PropTypes.object.isRequired
}
