import { getStateAbbreviation } from './statesHelper'

const stableFields = [
  'firstname',
  'lastname',
  'email',
  'telephone',
  'street1',
  'city',
  'state',
  'postal_code',
  'country'
]
const typeSpecificFields = {
  billing: [],
  shipping: ['vat_number']
}
const allAddressFields = [
  {
    code: 'firstname',
    label: 'First Name',
    value: '',
    display: 'inline'
  },
  {
    code: 'middlename',
    label: 'Middle Name',
    value: '',
    display: 'inline'
  },
  {
    code: 'lastname',
    label: 'Last Name',
    value: '',
    display: 'inline'
  },
  { code: 'email', label: 'Email', value: '' },
  { code: 'telephone', label: 'Phone', value: '' },
  { code: 'street1', label: 'Street 1', value: '' },
  { code: 'street2', label: 'Street 2', value: '' },
  { code: 'street3', label: 'Street 3', value: '' },
  { code: 'street4', label: 'Street 4', value: '' },
  {
    code: 'city',
    label: 'City',
    value: '',
    display: 'inline'
  },
  {
    code: 'state',
    label: 'State',
    value: '',
    display: 'inline',
    cast: (state) => getStateAbbreviation(state)
  },
  {
    code: 'postal_code',
    label: 'Postal Code',
    value: '',
    display: 'inline'
  },
  { code: 'country', label: 'Country', value: '' },
  {
    code: 'vat_number',
    label: 'VAT Number',
    value: ''
  }
]
export const getAddressFieldsForValues = (
  addressValuesObj,
  originOrderAddressObj,
  type
) => {
  console.log('allAddressFields!', addressValuesObj, originOrderAddressObj)
  const addressFields = allAddressFields
    .map((addressField) => ({
      ...addressField,
      value: addressField.cast
        ? addressField.cast(addressValuesObj[addressField.code])
        : addressValuesObj[addressField.code]
    }))
    .filter(
      ({ code }) =>
        stableFields.includes(code) ||
        typeSpecificFields[type].includes(code) ||
        !!originOrderAddressObj[code]
    )

  return addressFields
}
