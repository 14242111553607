import styled from 'styled-components'
import { SnowTypography } from '../../../../../../../../../shared/components/snowComponents'

export const ShipmentInfoTypo = styled(SnowTypography).attrs({
  component: 'p'
})`
  &.MuiTypography-root {
    font-size: 0.9rem;
    margin-right: ${({ theme }) => theme.spacing.secondary};
  }
`
