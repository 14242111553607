import React, { useEffect, useRef, useState } from 'react'
import { Storage } from '../../../infrastructure/service/localStorage/helper'
import { atom, atomFamily, useRecoilValue, useSetRecoilState } from 'recoil'

export const LogLevel = {
  ALL: 'log',
  LOG: 'log',
  ERROR: 'error',
  WARN: 'warn',
  NO_OP: 'no_log'
}

const appLoggerSettingsAtom = atomFamily({
  key: 'appLoggerSettings',
  default: (scope) => ({
    log: () => ({}),
    warn: () => ({}),
    error: () => ({}),
    color: () => ({})
  })
})

const useSnowLoggerSetup = ({
  uxScope = 'APP',
  level = LogLevel.LOG,
  color: initialColors = ['white', 'black'],
  devOnly = false
}) => {
  const deusMode = useRef(false)

  const NO_OP = (message, optionalParams) => {
    deusMode.current && console.log('NO OP')
  }
  const LOG_OP = (message, colors, ...optionalParams) => {
    if (colors.color === 'black')
      console.log(`${uxScope}: ${message}`, ...optionalParams)
    else {
      console.log(
        `${uxScope}: %c${message}`,
        `color:${colors[0]};background-color:${colors[1]}`,
        ...optionalParams
      )
    }
  }

  const WARN_OP = (message, ...optionalParams) =>
    console.warn(message, ...optionalParams)
  const ERROR_OP = (message, ...optionalParams) =>
    console.error(message, ...optionalParams)

  const COLOR_LOG_OP = (message, ...optionalParams) => {
    deusMode.current && console.log('COLOR_LOG_OP', curColor)
    LOG_OP(message, curColor.current, ...optionalParams)
    curColor.current = initialColors
  }
  const setLoggerSettings = useSetRecoilState(appLoggerSettingsAtom(uxScope))

  const [_level = LogLevel.LOG] = useState(level)
  const curColor = useRef(initialColors)

  const [log, setLog] = useState(() => COLOR_LOG_OP)

  const [error, setError] = useState(() => ERROR_OP)

  const [warn, setWarn] = useState(() => WARN_OP)

  const color = (colorName = 'white', backgroundName = 'black') => {
    deusMode.current &&
      console.log('useSnowLoggerSetup color', { log, warn, error, curColor })
    curColor.current = [colorName, backgroundName]
    return { log: COLOR_LOG_OP, warn, error }
  }
  const godMode = deusMode.current
  const resurrectAllLogs = () => {
    deusMode.current && console.log('resurrectAllLogs')
    setError(() => ERROR_OP)
    setWarn(() => WARN_OP)
    setLog(() => COLOR_LOG_OP)
  }
  useEffect(() => {
    return () => {
      console.log('Logger destruction')
    }
  }, [])

  useEffect(() => {
    deusMode.current = devOnly && Boolean(Storage.get('deusMode'))
    deusMode.current && console.log({ deusMode })
    if (deusMode.current) resurrectAllLogs()
  }, [devOnly])

  useEffect(() => {
    deusMode.current && console.log('level', { level })
    if (deusMode.current) return

    if (_level === LogLevel.NO_OP) {
      setLog(() => NO_OP)
      setError(() => NO_OP)
      setWarn(() => NO_OP)
    }
    if (_level === LogLevel.ERROR) {
      setLog(() => NO_OP)
      setWarn(() => NO_OP)
    }

    if (_level === LogLevel.WARN) {
      setLog(() => NO_OP)
    }
  }, [level])

  useEffect(() => {
    godMode && console.log('useSnowLoggerSetup', log, warn, color, error)
    setLoggerSettings({ log, error, warn, color })
  }, [log, warn, color, error])

  return { log, warn, error, color }
}

export const useLogger = (uxScope) => {
  const { log, warn, error, color } = useRecoilValue(
    appLoggerSettingsAtom(uxScope)
  )
  return { log, warn, error, color }
}

export default useSnowLoggerSetup
