import React, { Suspense, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import {
  integrationCqrsRequestModel,
  integrationEntitiesModel,
  integrationEntityType
} from '../data/integrationDeterminant'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'

/**
 *
 * @param domainName - kind of top level entitiesHooks to configure like 'vendors',
 *   'projects' and etc
 * @returns {JSX.Element}
 * @constructor
 */
const IntegrationDomainIndex = ({ domainName }) => {
  const { domainId } = useParams()
  const integrationEntityTypeName = domainName.slice(0, -1)
  const resetIntegrationParams = useResetRecoilState(
    integrationCqrsRequestModel
  )
  const setIntegrationParams = useSetRecoilState(integrationCqrsRequestModel)
  const resetIntegrationEntities = useResetRecoilState(integrationEntitiesModel)

  const setIntegrationType = useSetRecoilState(integrationEntityType)
  const integrationType = useRecoilValue(integrationEntityType)

  useEffect(() => {
    resetIntegrationParams()
    setIntegrationParams((oldParams) => {
      console.log('setIntegrationParams', {
        ...oldParams,
        [integrationEntityTypeName]: domainId
      })
      return {
        ...oldParams,
        [integrationEntityTypeName]: domainId
      }
    })
  }, [domainId])

  useEffect(() => {
    console.log(
      'IntegrationDomainIndex constructor domainId',
      domainName,
      domainId,
      integrationType,
      integrationEntityTypeName
    )

    setIntegrationType(integrationEntityTypeName)

    return () => {
      console.log('IntegrationDomainIndex destructor')
      resetIntegrationParams()
      resetIntegrationEntities()
    }
  }, [])

  return (
    <div aria-label={`${domainName}-${domainId}-domainIntegrations`}>
      <Suspense fallback={'loading Domain Data'}>
        {domainName && <Outlet />}
      </Suspense>
    </div>
  )
}

export default IntegrationDomainIndex
