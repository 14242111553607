import React, { useEffect, useState } from 'react'
import {
  generateVendorShippingMapping,
  useVendorFields,
  useVendorShippingMethodCreate,
  useVendorShippingMethodRemove,
  useVendorShippingMethodsModel,
  useVendorShippingMethodUpdate
} from '../apiConfigs'

import { ScAddButton } from '../../../../../../shared/components/buttons'
import Mapper from '../../../../components/Mapper'
import useVendors from '../../../../hooks/useVendors'
import ScSpinner from '../../../../../../shared/components/ScSpinner/ScSpinner'

const VendorShippingMethodsMapper = ({
  onInit,
  onMap,
  vendor: vendorId,
  vendorName,
  defaultValue
}) => {
  const [newMappingSaver, setNewMappingSaver] = useState(null)
  const { data } = useVendors()
  // console.log('VendorShippingMethodsMapper useVendors data', data)
  const saveMap = () => {
    console.log('saveMap', newMappingSaver)
    return newMappingSaver()
  }

  useEffect(() => {
    console.log('VendorShippingMethodsMapper newMappingSaver', newMappingSaver)
    newMappingSaver && onMap({ saveCmd: saveMap })
  }, [newMappingSaver])

  return data ? (
    data.length && (
      <Mapper
        defaultValue={defaultValue}
        incomeValueFieldName={'code'}
        addBtn={ScAddButton}
        aggregateId={{ vendorId }}
        aggregateName={vendorName}
        aggregateTypeName={'vendor'}
        createCommandProvider={useVendorShippingMethodCreate}
        removeCommandProvider={useVendorShippingMethodRemove}
        updateCommandProvider={useVendorShippingMethodUpdate}
        fieldsProvider={useVendorFields}
        mappingsProvider={useVendorShippingMethodsModel}
        newMappingGenerator={generateVendorShippingMapping}
        onInit={onInit}
      />
    )
  ) : (
    <ScSpinner />
  )
}

export default VendorShippingMethodsMapper
