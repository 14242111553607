import React, { useEffect, useState } from 'react'
import {
  SnowBadge,
  SnowPaper,
  SnowTooltip,
  SnowButton,
  SnowDialog,
  SnowDialogActions,
  SnowDialogContent,
  SnowDialogTitle,
  SnowTable,
  SnowTableBody,
  SnowTableContainer,
  SnowTableHead,
  SnowTableRow,
  SnowTableCell,
  SnowTypography,
  SnowCheckbox
} from '../../../../../../../shared/components/snowComponents'
import {
  ScSecondaryBtn,
  ScPrimaryBtn
} from '../../../../../../../shared/components/buttons'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const StyledDialog = styled(SnowDialog)`
  .MuiDialog-paper {
    width: 80%;
    max-height: 435px;
  }
  .MuiDialogActions-root {
    justify-content: center;
  }
`

const StyledTitleBlock = styled(SnowDialogTitle).attrs({
  disableTypography: true
})`
  font-size: ${({ theme }) => theme.fontSize.main};
`

const StyledTitle = styled(SnowTypography).attrs({
  variant: 'h6'
})`
  &.MuiTypography-root {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.big};
      font-weight: ${theme.fontWeight.main};
    `}
  }
`

const StyledSubtitle = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.main};
    font-weight: ${theme.fontWeight.thin};
  `}
`

const StyledPaper = styled(SnowPaper)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.secondary};
`

const StyledTableRow = styled(SnowTableRow)`
  & td,
  & input,
  & svg,
  & path {
    ${({ iscolor, theme }) =>
    iscolor ? `color: ${theme.mainPalette.grey[100]};` : ''}
  }
`

const StyledBadge = styled(SnowBadge)`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.middle};
    font-weight: ${theme.fontWeight.bold};
    .MuiBadge-badge {
      background: ${theme.mainPalette.grey[100]};
    }
  `}
`

const testVendors = [
  // {
  //   code: 'ololo',
  //   enabled: false,
  //   market_vendor: 'monetyze-casetry3',
  //   priority: 100001,
  //   name: 'Ololo1'
  // }
]

const MultivendorsSelector = (props) => {
  const { onClose, vendors, open, title, ...other } = props
  const [values, setValues] = useState(vendors)
  useEffect(() => {
    if (!open) {
      setValues([...vendors, ...testVendors])
    }
  }, [vendors, open])

  const handleCancel = () => {
    onClose()
  }

  const handleSave = () => {
    onClose(values)
  }

  const handleClick = (clickedVendorCode) => {
    if (!clickedVendorCode) return
    const updatedVendors = values.map(({ enabled, code, ...rest }) => {
      return {
        ...rest,
        enabled: code === clickedVendorCode ? !enabled : enabled,
        code
      }
    })
    setValues(updatedVendors)
  }

  const handleChange = (event) => {
    const { name: checkedVendor } = event.target
    const updatedVendors = values.map(({ enabled, code, ...rest }) => {
      return {
        ...rest,
        enabled: code === checkedVendor ? !enabled : enabled,
        code
      }
    })
    setValues(updatedVendors)
  }

  return (
    <StyledDialog maxWidth="xs" open={open} {...other}>
      <StyledTitleBlock>
        <StyledTitle>
          <span>{title}</span>
          <StyledSubtitle>{' possible vendors'}</StyledSubtitle>
        </StyledTitle>
      </StyledTitleBlock>
      <SnowDialogContent dividers>
        <StyledPaper>
          <SnowTableContainer>
            <SnowTable
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <SnowTableHead>
                <SnowTableRow>
                  <SnowTableCell>Enable</SnowTableCell>
                  <SnowTableCell>Vendor</SnowTableCell>
                  <SnowTableCell>Priority</SnowTableCell>
                </SnowTableRow>
              </SnowTableHead>
              <SnowTableBody>
                {[...values]
                  .sort((a, b) => Number(b.priority) - Number(a.priority))
                  .map(({ enabled, priority, code, market_vendor, name }) => {
                    return (
                      <StyledTableRow
                        hover
                        onClick={(evt) => handleClick(code)}
                        role="checkbox"
                        aria-checked={enabled}
                        tabIndex={-1}
                        key={`${code}-${market_vendor}`}
                        selected={enabled}
                        iscolor={!code ? 1 : 0}
                      >
                        <SnowTableCell padding="checkbox">
                          <SnowCheckbox
                            checked={enabled}
                            disabled={!code}
                            inputProps={{ 'aria-labelledby': market_vendor }}
                            name={code}
                            onChange={handleChange}
                          />
                        </SnowTableCell>
                        <SnowTableCell>{name || market_vendor}</SnowTableCell>
                        <SnowTableCell>{priority}</SnowTableCell>
                      </StyledTableRow>
                    )
                  })}
              </SnowTableBody>
            </SnowTable>
          </SnowTableContainer>
        </StyledPaper>
      </SnowDialogContent>
      <SnowDialogActions>
        <ScSecondaryBtn autoFocus onClick={handleCancel} label={'Cancel'} />
        <ScPrimaryBtn onClick={handleSave} label={'Save'} />
      </SnowDialogActions>
    </StyledDialog>
  )
}

MultivendorsSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  values: PropTypes.string.isRequired
}

export const MultivendorSelectionBtn = ({
  label = '',
  title,
  vendors,
  handleSave
}) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (newValues) => {
    setOpen(false)
    console.log('handleClose', newValues)
    if (newValues) {
      handleSave(newValues)
    }
  }

  return (
    <>
      <SnowTooltip arrow title="This product has several possible vendors.">
        <span>
          <StyledBadge badgeContent={vendors.length}>
            <SnowButton
              onClick={handleClick}
              aria-haspopup="true"
              aria-controls="multivendors-menu"
              aria-label="multivendors menu"
            >
              {label ? label : 'Click for info'}
            </SnowButton>
          </StyledBadge>
        </span>
      </SnowTooltip>
      <MultivendorsSelector
        id="multivendors-menu"
        title={title}
        keepMounted
        open={open}
        onClose={handleClose}
        vendors={vendors}
      />
    </>
  )
}
MultivendorsSelector.propTypes = {
  vendors: PropTypes.array
}
