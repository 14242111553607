import React, { useEffect, useState } from 'react'
import useOrderLineItem from './useOrderLineItem'
import { ScEditButton } from '../../../../../../shared/components/buttons'
import _ from 'lodash'

export const useOrderItemField = ({ orderItem, path = '', edit = false }) => {
  const { repo, ux } = useOrderLineItem(orderItem)
  const rawVal = _.get(orderItem._rawData, path)
  const { isPathEditable: canEdit, getEditablePathField } = ux
  const { update, changed } = repo ?? {}

  const [isEdit, setEditFieldsState] = useState(edit)
  const [val, setVal] = useState(
    typeof rawVal !== 'undefined'
      ? rawVal
      : getEditablePathField(path)?.default_value ?? null
  )
  const toggleEdit = () => {
    setEditFieldsState(!isEdit)
  }
  const handleUpdate = ({ value }) => {
    setVal(value)
    update({ code: path, value })
  }
  const handleDateUpdate = (value) => {
    if (typeof value === 'undefined') return

    setVal(value)
    update({ code: path, value })
  }

  useEffect(() => {
    !changed && setEditFieldsState(false)
  }, [changed])

  useEffect(() => {
    setEditFieldsState(edit)
  }, [edit])

  console.log(
    'useOrderItemField!!',
    {
      getEditablePathField: getEditablePathField(path),
      orderItem,
      rawVal,
      path
    }
  )

  const EditToggler = () => {
    return <>{canEdit && !isEdit && <ScEditButton size={30} onClick={toggleEdit} />}</>
  }
  return {
    EditToggler,
    value: val,
    handleUpdate,
    handleDateUpdate,
    isEdit
  }
}
