import { atom, selector, DefaultValue, useRecoilState } from 'recoil'
import { useEffect } from 'react'

const currentHeadingState = atom({
  key: 'currentHeadingState',
  default: {
    heading: '',
    subtitle: '',
    extension: '',
    backgroundColor: '#084e8a'
  }
})
export const getScPageHeaderState = selector({
  key: 'getScPageHeaderState',
  get: ({ get }) => get(currentHeadingState)
})
export const setScPageHeaderExtensionCmd = selector({
  key: 'setScPageHeaderExtensionCmd',
  get: ({ get }) => get(currentHeadingState).extension,
  set: ({ get, set, reset }, newValue) => {
    console.log('set heading extension', newValue, DefaultValue)
    if (newValue instanceof DefaultValue) {
      console.log('going to reset setHeadingExtensionCmd')
      set(currentHeadingState, { ...get(currentHeadingState), extension: '' })
      return
    }
    set(currentHeadingState, {
      ...get(currentHeadingState),
      extension: newValue
    })
  }
})
export const setScPageHeaderTitleCmd = selector({
  key: 'setScPageHeaderTitleCmd',
  get: ({ get }) => get(currentHeadingState).heading,
  set: ({ get, set, reset }, newValue) => {
    console.log('set heading title', newValue, DefaultValue)

    if (newValue instanceof DefaultValue) {
      console.log('going to reset setHeadingTitleCmd')
      set(currentHeadingState, { ...get(currentHeadingState), heading: '' })
      return
    }
    set(currentHeadingState, {
      ...get(currentHeadingState),
      heading: newValue
    })
  }
})

export const useScPageHeaderTitle = () => {
  const [headerTitle, setHeaderTitle] = useRecoilState(setScPageHeaderTitleCmd)
  return [headerTitle, setHeaderTitle]
}
export const setScPageHeaderBgColorCmd = selector({
  key: 'setScPageHeaderBgColorCmd',
  get: ({ get }) => get(currentHeadingState).backgroundColor,
  set: ({ get, set, reset }, newValue) => {
    console.log('set heading bg color', newValue, DefaultValue)

    if (newValue instanceof DefaultValue || !newValue) {
      console.log('going to reset setHeadingBgColorCmd')
      set(currentHeadingState, {
        ...get(currentHeadingState),
        backgroundColor: '#084e8a'
      })
      return
    }
    set(currentHeadingState, {
      ...get(currentHeadingState),
      backgroundColor: newValue
    })
  }
})

export const setScPageHeaderSubtitleCmd = selector({
  key: 'setScPageHeaderSubtitleCmd',
  get: ({ get }) => get(currentHeadingState).subtitle,
  set: ({ get, set, reset }, newValue) => {
    console.log('set heading subtitle', newValue, DefaultValue)
    if (newValue instanceof DefaultValue)
      set(currentHeadingState, {
        ...get(currentHeadingState),
        subtitle: ''
      })
    set(currentHeadingState, {
      ...get(currentHeadingState),
      subtitle: newValue
    })
  }
})
export const useScPageHeaderSubtitle = () => {
  const [headerSubtitle, setHeaderSubtitle] = useRecoilState(
    setScPageHeaderSubtitleCmd
  )
  useEffect(() => () => setHeaderSubtitle(''), [])
  return [headerSubtitle, setHeaderSubtitle]
}
