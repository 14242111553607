import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSetRecoilState } from 'recoil'
import {
  SnowBox,
  SnowGrid,
  SnowList,
  SnowListItem,
  SnowTypography
} from '../../../../../../../../shared/components/snowComponents'

import SetupPersonalization from './components/SetupPersonalization'
import ModerationCmdBtn from './components/ModerationCmdsBtn'
import PersonalizationStatusViewer from './components/PersonalizationStatusViewer'
import PersonalizationActions from './components/PersonalizationActions'

import { useApplyOrderItemPersonalizationCmd } from '../../../../../../infrastructure/orderItemApi'
import { currentMessageState } from '../../../../../../../../shared/service/Message/data/currentMessageState'
import { currentOrderState } from '../../../../../data/currentOrderState'
import {
  Personalization,
  PersonalizationImagePreview,
  PersonalisationRowLabel,
  PersonalisationRowValue
} from './_styled'
import styled from 'styled-components'

const StyledList = styled(SnowList).attrs({
  disablePadding: true,
  dense: true
})`
  margin: -3px;
`

function getRefnum(item) {
  if (item.personalizations[0].preview.refnum) {
    return item.personalizations[0].preview.refnum
  }
}

const getOptions = (options) => {
  if (options && typeof options === 'object') {
    const arrayOfKeys = Object.keys(options)
    const arrayOfValues = Object.values(options)
    const rezult = arrayOfKeys?.length
      ? arrayOfKeys.map((_key, index) => {
          return { item: _key, value: arrayOfValues[index] }
        })
      : []
    return rezult
  } else {
    return []
  }
}

const hydratePersonalizationRaw = (personalizationRaw) => {
  const {
    cart_item_id,
    image_url,
    last_response,
    session_identifier,
    storefront_invoice_id,
    troubles,
    refnum,
    values,
    state_mode
  } = personalizationRaw?.preview || {}

  const preview = {
    troubles: typeof troubles === 'string' && troubles && JSON.parse(troubles),
    last_response:
      typeof last_response === 'string' &&
      last_response &&
      JSON.parse(last_response),
    cart_item_id,
    image_url,
    state_mode,
    values,
    refnum,
    session_identifier,
    storefront_invoice_id
  }
  console.log('personalizationRaw', personalizationRaw, preview)
  return { ...personalizationRaw, preview }
}
const PersonalizationInfo = ({ item }) => {
  console.log('item.personalizations', item.personalizations)
  const setMessage = useSetRecoilState(currentMessageState)
  const setOrderState = useSetRecoilState(currentOrderState)
  const firstPersonalization =
    Array.isArray(item.personalizations) &&
    hydratePersonalizationRaw(item.personalizations[0])

  const currentUrl = firstPersonalization?.preview.image_url || ''
  const moderationStatus = firstPersonalization?.moderation_status || ''
  const [personalizations, setUpdatedPersonalizations] = useState(
    item.personalizations
  )
  const possibleActions = item.meta.moderationOptions
  const [selectedAction, setSelectedAction] = useState('moderation')
  const applyModerationCmd = useApplyOrderItemPersonalizationCmd(
    { id: item.id },
    { action: selectedAction },
    (r) => {
      console.log('onSuccess Handler', r)
      const updatedOrder = r.order
      setOrderState(updatedOrder)
      setMessage({
        message: `Personalizations for ${item.saProductName} has been moderated into ${selectedAction}`,
        severity: 'success'
      })
    },
    (r) =>
      setMessage({
        message: `Moderation failed`,
        severity: 'error'
      })
  )

  const handlePersSetup = ({ personalizations, error }) => {
    if (!error) setUpdatedPersonalizations([personalizations])
  }
  const hasPersonalization = !!personalizations?.length
  const handleModerationSelected = (action) => {
    console.log('Selected personalizationInfo moderation', action)
    setSelectedAction(action)
  }
  const handleApply = () => {
    console.log('Gonna apply moderation', item.id, selectedAction)
    applyModerationCmd()
  }

  const personalisationData = [
    {
      title: 'Status:',
      value: (
        <PersonalizationStatusViewer personalization={firstPersonalization} />
      )
    },
    {
      title: 'Moderation:',
      value: (
        <ModerationCmdBtn
          actions={possibleActions}
          handleModerationSelected={handleModerationSelected}
          handleApply={handleApply}
          status={moderationStatus}
        />
      )
    }
  ]

  return (
    <Personalization>
      {hasPersonalization &&
        Array.isArray(personalizations) &&
        personalizations?.map((raw, index) => {
          const personalization = hydratePersonalizationRaw(raw)
          const { image_url, refnum, values } = personalization.preview
          console.log('PersonalizationInfo', getOptions(values))

          return (
            <SnowBox key={`${index}-${JSON.stringify(personalization)}`}>
              {image_url && (
                <PersonalizationImagePreview
                  href={image_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img alt="preview-image" src={image_url} />
                </PersonalizationImagePreview>
              )}
              {refnum && (
                <SnowTypography
                  variant={'subtitle2'}
                  component={'h4'}
                  fontWeight={400}
                  paragraph={true}
                >
                  {`ID: ${getRefnum(item)}`}
                </SnowTypography>
              )}
              {values && (
                <>
                  <SnowTypography
                    variant={'subtitle2'}
                    component={'h3'}
                    fontWeight={400}
                  >
                    Customized Text
                  </SnowTypography>
                  <StyledList>
                    {getOptions(values).map(({ item, value }, index) => (
                      <SnowListItem
                        key={`${index}-${value}`}
                        disableGutters={true}
                        dense={true}
                      >
                        <PersonalisationRowLabel>{item}</PersonalisationRowLabel>
                        <PersonalisationRowValue>
                          {`"${value}"`}
                        </PersonalisationRowValue>
                      </SnowListItem>
                    ))}
                  </StyledList>
                </>
              )}
              <SnowGrid
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                direction={'row'}
              >
                <PersonalizationActions
                  personalization={personalization}
                  handlePersSetup={handlePersSetup}
                  persHydrator={hydratePersonalizationRaw}
                  item={item}
                />
              </SnowGrid>
            </SnowBox>
          )
        })}
      {!hasPersonalization && (
        <SetupPersonalization
          orderItem={item}
          persUrl={currentUrl}
          onSetup={handlePersSetup}
        />
      )}
      {hasPersonalization && (
        <SnowList>
          {personalisationData?.length
            ? personalisationData.map(({ title, value }, index) => (
                <SnowListItem key={`${index}-${title}`} disableGutters={true}>
                  <PersonalisationRowLabel>{title}</PersonalisationRowLabel>
                  <PersonalisationRowValue>{value}</PersonalisationRowValue>
                </SnowListItem>
              ))
            : null}
        </SnowList>
      )}
    </Personalization>
  )
}

PersonalizationInfo.propTypes = {
  item: PropTypes.shape().isRequired
}

export default PersonalizationInfo
