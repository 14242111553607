import React, { useContext } from 'react'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import { SnowDoneAllIcon } from '../../../../shared/components/icons'
import { ReactComponent as ErrorIcon } from '../../../app/images/SVGs/error.svg'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'

function getMessage(response, product) {
  if (response.success) {
    return `${product?.values.product.marketplace_product_sku} has successfully been resynced.`
  } else {
    return `You may be logged out in order to re-enter your account credentials.`
  }
}

const ConfirmationModal = (props) => {
  const { product, response, showModal, toggleShowModal } = props
  const theme = useContext(ThemeContext)

  return (
    <ScConfirmationAlert
      isShowAttention={showModal}
      onClose={toggleShowModal}
      labelForCancelBtn="Close"
      icon={
        response.success ? (
          <SnowDoneAllIcon style={{ fill: theme.mainPalette.color.main }} />
        ) : (
          <ErrorIcon />
        )
      }
      message={{
        title: (
          <SnowTypography
            fontWeight={theme.fontWeight.bold}
            style={{ textTransform: 'uppercase' }}
          >
            {response.success ? 'success' : 'error'}
          </SnowTypography>
        ),
        content: (
          <SnowTypography align={'center'} variant={'body1'} component={'p'}>
            {getMessage(response, product)}
          </SnowTypography>
        )
      }}
    />
  )
}

ConfirmationModal.propTypes = {
  product: PropTypes.shape().isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  response: PropTypes.shape()
}

ConfirmationModal.defaultProps = {
  response: { success: true, message: 'default message' }
}

export default ConfirmationModal
