import React from 'react'
import { ScBasicModal } from '../modals'
import styled from 'styled-components'

const ImageBoxView = styled.div`
  width: auto;
  height: 400px;
  img {
    margin: 0 auto;
    display: block;
    width: auto;
    max-height: 100%;
  }
`

const ScImageModal = ({ title, url, isOpen = false, onClose }) => {

  return (
    <ScBasicModal
      open={isOpen}
      onClose={onClose}
      title={title}
      content={
        <ImageBoxView>
          <img id="transition-modal-description" src={url} />
        </ImageBoxView>
      }
    />
  )
}

export default ScImageModal
