import React from 'react'
import { SnowAccordionDetails } from '../../../../../../shared/components/snowComponents'
import FlatFieldList from '../../../../components/configuration/FlatFieldList'

const ConfigGroupFieldsList = ({
  groupCategory = 'ConfigGroupFieldsList',
  fields,
  onUpdate,
  onError
}) => {
  return (
    <SnowAccordionDetails
      aria-label={`ConfigGroupFieldsList-${groupCategory}`}
    >
      <FlatFieldList
        fieldList={fields}
        updateValue={onUpdate}
        onError={onError}
      />
    </SnowAccordionDetails>
  )
}
export default ConfigGroupFieldsList
