import React from 'react'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'

const TypographyView = styled(Typography)`
  &.MuiTypography-root {
    font-weight: ${({ fontWeight, theme }) => (fontWeight ? fontWeight : theme.fontWeight.thin)};
    color: ${({ textcolor, theme }) =>
      textcolor ? textcolor : theme.mainPalette.typography.main};
    text-transform: ${({ texttransform }) =>
      texttransform ? texttransform : 'none'};
  }
`

const SnowTypography = React.forwardRef(function SnowTypography(
  { children, fontWeight, color, textTransform, ...props },
  ref
) {
  return (
    <TypographyView
      fontWeight={fontWeight}
      textcolor={color}
      texttransform={textTransform}
      ref={ref}
      {...props}
    >
      {children}
    </TypographyView>
  )
})

export default SnowTypography
