import React from 'react'
import { SnowIconButton } from '../../snowComponents'
import { SnowShippingIcon } from '../../icons'

function ScShippingButton({ ...props }) {
  return (
    <SnowIconButton {...props}>
      <SnowShippingIcon />
    </SnowIconButton>
  )
}

export default ScShippingButton
