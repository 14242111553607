import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { ScBasicModal } from '../../../../shared/components/modals'
import { saveTrackingNumber } from '../../infrastructure/fetches'
import { currentMessageState } from '../../../../shared/service/Message/data/currentMessageState'
import EnterTrackingBody from './EnterTrackingBody'
import {
  ScPrimaryBtn,
  ScSecondaryBtn
} from '../../../../shared/components/buttons'
import { useLogger } from '../../../../shared/service/helpers/SnowLogger'

const EnterTrackingModal = ({ open, onClose, vendor, vendorOrder }) => {
  const { log } = useLogger('ORDER')
  const setMessage = useSetRecoilState(currentMessageState)
  const [params, setParams] = useState(null)
  const saveTN =
    params?
    (() => {
      saveTrackingNumber(params).then((resp) => {
        setMessage({ message: resp.message, severity: 'success' })
        onClose()
      })
    }):(()=>onClose())

  log('EnterTrackingModal123000', { params, vendor, vendorOrder })

  return (
    <ScBasicModal
      open={open}
      onClose={onClose}
      title={'Enter tracking number'}
      content={
        <EnterTrackingBody
          vendor={vendor}
          vendorOrder={vendorOrder}
          onTrackingReady={setParams}
          showShippingDate={true}
        />
      }
      labelForConfirmBtn={'Save Tracking'}
      labelForCancelBtn={'Cancel'}
      onConfirmProps={{disabled: !params}}
      onConfirm={saveTN}
    />
  )
}
export default EnterTrackingModal
