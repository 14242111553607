import React, { useEffect, Suspense } from 'react'
import VendorChooser from './VendorsChooser'
import { integrationCqrsRequestModel } from '../../data/integrationDeterminant'
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil'
import {
  setScPageHeaderExtensionCmd,
  useScPageHeaderSubtitle
} from '../../../app/data/currentHeadingState'

const IntegrationVendorChooser = ({
  projectId = '0',
  onIntegrationSelected
}) => {
  const [, setPageSubheader] = useScPageHeaderSubtitle()

  const setHeaderExtension = useSetRecoilState(setScPageHeaderExtensionCmd)
  const resetHeaderExtension = useResetRecoilState(setScPageHeaderExtensionCmd)

  const [integrationParams, setIntegrationParams] = useRecoilState(
    integrationCqrsRequestModel
  )
  const onVendorConfirm = (vendorDto) => {
    console.log('onVendorDoubleClicked vendorDto', vendorDto)
    const { id } = vendorDto

    console.log('IntegrationVendorChooser onVendorConfirm dto', vendorDto)

    setIntegrationParams((oldParams) => ({
      ...oldParams,
      vendor: vendorDto.id,
      project: '0',
      channel: '0'
    }))
    onIntegrationSelected(id, { vendor: vendorDto })
  }
  const onVendorSelected = (vendorDto) => {
    // setIntegrationParams((oldValues) => ({
    //   ...oldValues,
    //   vendor: vendorDto.id,
    //   project: '0',
    //   channel: '0'
    // }))
  }
  useEffect(() => {
    setPageSubheader('Double-click an item to open integration config')
  }, [])
  return (
    <Suspense fallback={'Vendors loading'}>
      <VendorChooser
        projectId={projectId + ''}
        onConfirm={onVendorConfirm}
        // onItemSelect={onVendorSelected}
        dtoFields={[
          'id',
          'name',
          'code',
          'reduced_script_list',
          'reduced_script_status'
        ]}
      />
    </Suspense>
  )
}

export default IntegrationVendorChooser
