import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState
} from 'recoil'
import {
  useCreateGroupCmd,
  useDeleteGroupCmd,
  useFetchAreaGroupDetailsCmd,
  useSaveAreaGroupCmd
} from '../infrastructure/areaApi'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useCountries, useLevels } from './areaDictionaries'

export const NEW_GROUP_ID = ''

function extractAreaGroupDetails(rawDetails) {
  const {
    id,
    name,
    description,
    countries: rawCountries,
    level,
    vendor_enabled_by_default,
    created_at,
    updated_at
  } = rawDetails

  const countries = Array.isArray(rawCountries)
    ? rawCountries?.reduce((acc, country) => {
      const { code, regions, name } = country
      return { ...acc, [code]: regions.map(({ code }) => code) }
    }, {})
    : rawCountries
  return { ...rawDetails, countries }
}

const loadedAreaGroups = atom({
  key: 'loadedAreaGroups',
  default: []
})
const loadedAreaGroupDetailsAtom = atomFamily({
  key: 'loadedAreaGroupDetailsAtom',
  default: null
})

const updatedAreaGroupDetails = atomFamily({
  key: 'updatedAreaGroupDetails',
  default: {}
})

const shownAreaGroupDetails = selectorFamily({
  key: 'shownAreaGroupDetails',
  get:
    (groupId) =>
      ({ get }) => {
        const originDetails = groupId
          ? get(loadedAreaGroupDetailsAtom(groupId))
          : createAreaGroup()
        const updatedDetails = get(updatedAreaGroupDetails(groupId))
        const mergedDetails = originDetails
          ? { ...originDetails, ...updatedDetails }
          : {}
        console.log('shownAreaGroupDetails', groupId, {
          originDetails,
          updatedDetails,
          mergedDetails
        })
        return mergedDetails
      }
})

export const createAreaGroup = () => ({
  id: NEW_GROUP_ID,
  name: 'New Group',
  description: '',
  countries: {},
  level: '',
  vendor_enabled_by_default: false
})

export const useAreaOptions = () => {
  const countriesDictionary = useCountries()
  const levelsOptions = useLevels()
  const countriesOptions = useMemo(
    () =>
      countriesDictionary.reduce(
        (acc, { id, name, code }) => ({ ...acc, [code]: name }),
        {}
      ),
    [countriesDictionary]
  )
  return { countriesOptions, levelsOptions }
}

export const useLoadedAreaGroupDetails = (id) => {
  const [loadedDetails, setLoadedDetails] = useRecoilState(
    loadedAreaGroupDetailsAtom(id)
  )
  const [loadedGroupIds, setLoadedGroupIds] = useRecoilState(loadedAreaGroups)
  const isLoading = useRef(false)

  const loadAreaGroupDetailsCmd = useFetchAreaGroupDetailsCmd(
    { id },
    (r) => {
      setLoadedDetails(extractAreaGroupDetails(r?.data))
    },
    (r) => { }
  )

  useEffect(() => {
    if (id === NEW_GROUP_ID) return

    if (
      !id ||
      isLoading.current ||
      loadedDetails ||
      loadedGroupIds.includes(id)
    )
      return

    isLoading.current = true
    setLoadedGroupIds((ids) => [...ids, id])

    try {
      loadAreaGroupDetailsCmd().then((r) => {
        console.log(
          'useLoadedAreaGroupDetails loadAreaGroupDetailsCmd response then',
          r
        )
        isLoading.current = false
      })
    } catch (e) {
      console.error('useLoadedAreaGroupDetail catch', e)
      isLoading.current = false
    }
  }, [id])

  if (!id) return []

  return [loadedDetails, isLoading]
}

const useAreaGroupApi = (id, model) => {
  const setUpdatedDetails = useSetRecoilState(updatedAreaGroupDetails(id))

  const saveCmd = useSaveAreaGroupCmd(
    {
      ...model,
      id
    },
    (r) => {
      console.log('useSaveAreaGroupCmd onSuccess', r)
    },
    (r) => {
      console.log('useSaveAreaGroupCmd onFail', r)
    }
  )
  const createCmd = useCreateGroupCmd(
    {
      ...model
    },
    (r) => {
      console.log('useCreateGroupCmd onSuccess', r)
      setUpdatedDetails({})
    },
    (r) => {
      console.log('useCreateGroupCmd onFail', r)
    }
  )

  const removeCmd = useDeleteGroupCmd(
    {
      id
    },
    (r) => {
      console.log('useDeleteGroupCmd onSuccess', r)
    },
    (r) => {
      console.log('useDeleteGroupCmd onFail', r)
    }
  )
  return { saveCmd, createCmd, removeCmd }
}
export const useUpdatedAreaGroupDetails = (id) => {
  const updatedDetails = useRecoilState(updatedAreaGroupDetails(id))
  return updatedDetails
}

export const useAreaGroupDetailsModel = (id) => {
  const [updatedDetails, setUpdatedDetails] = useRecoilState(
    updatedAreaGroupDetails(id)
  )

  const { saveCmd, createCmd, removeCmd } = useAreaGroupApi(id, updatedDetails)

  console.log('updatedDetails', id, updatedDetails)
  const hasUpdate = useMemo(
    () => Boolean(Object.keys(updatedDetails).length),
    [updatedDetails]
  )
  const shownModel = useRecoilValue(shownAreaGroupDetails(id))

  const updateObj = useMemo(
    () => ({
      countries: (countries) =>
        setUpdatedDetails({ ...updatedDetails, countries }),
      name: (name) => setUpdatedDetails({ ...updatedDetails, name }),
      level: (level) => setUpdatedDetails({ ...updatedDetails, level }),
      vendor_enabled_by_default: (vendor_enabled_by_default) =>
        setUpdatedDetails({ ...updatedDetails, vendor_enabled_by_default }),
      description: (description) =>
        setUpdatedDetails({ ...updatedDetails, description })
    }),
    [shownModel, updatedDetails]
  )
  console.log('useAreaGroupDetailsModel!', id, {
    updatedDetails,
    shownModel
  })
  useEffect(() => {
    return () => {
      console.log('useAreaGroupDetailsModel desctructor', {
        id,
        updatedDetails
      })
    }
  }, [])
  /**
   *
   * @param prop - area group field name to update
   * @param data - data to update area group field
   */
  const updateModelProp = useCallback(
    (prop, data) => {
      if (updateObj?.[prop]) updateObj[prop](data)
      else console.error(`useAreaGroupDetailsModel prop ${prop} not found`)
    },
    [id, updateObj]
  )

  return {
    shownModel,
    updateModelProp,
    saveCmd: id !== NEW_GROUP_ID && saveCmd,
    createCmd: id === NEW_GROUP_ID && createCmd,
    removeCmd: id !== NEW_GROUP_ID && removeCmd,
    hasUpdate
  }
}
