import React, { useMemo } from 'react'
import { useProduct } from '../../../context/product'
import SnowEditableField from '../../../../../shared/components/SnowEditableField'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'

export const AttributeRowV2 = ({ meta, value, linePath, fieldProps }) => {
  const { editState, onAttributeUpdate } = useProduct()
  const { label, editable, code } = meta
  const castedValue = value || ''

  console.log('AttributeRowV2', fieldProps)

  const uid = useMemo(() => uuidv4(), [linePath])

  const isUniqArrayItem = meta.itemOf && meta.itemOf.type === 'uniq_array'

  const onFieldUpdate = ({ code, value }) => {
    if (isUniqArrayItem) {
      // linePath: product_vendors[0].vendor
      //code: vendor
      //meta.itemOf['id'] : 27119
      console.log('onFieldUpdate uniqArray', meta.itemOf['id'], linePath, code)

      onAttributeUpdate({
        code: 'id',
        value: meta.itemOf['id'],
        linePath: _.trimEnd(linePath, code) + 'id',
        meta
      })
    }
    onAttributeUpdate({
      code,
      value,
      linePath,
      meta
    })
  }
  return (
    <SnowEditableField
      edit={editable && editState}
      label={label}
      variant={'outlined'}
      value={castedValue}
      code={code}
      onUpdate={onFieldUpdate}
      meta={meta}
      uid={uid}
      {...fieldProps}
    />
  )
}
