import React, { useEffect, useState, useContext } from 'react'
import {
  SnowAccessTimeOutlinedIcon,
  SnowArrowDropDownIcon,
  SnowCancelScheduleSendIcon,
  SnowThumbUpIcon
} from '../../../../../../../../../shared/components/icons'
import { SnowButton, SnowTypography, SnowMenuItem } from '../../../../../../../../../shared/components/snowComponents'
import { ScPrimaryBtn } from '../../../../../../../../../shared/components/buttons'
import ScPopupMenu from '../../../../../../../../../shared/components/widgets/ScPopupMenu'
import styled, { css, ThemeContext } from 'styled-components'

const StyledButtonContainer = styled.div`
  display: flex;
`

const ModerationBtnView = styled(SnowButton)`
  &.MuiButtonBase-root {
    ${({ theme }) => css`
      box-shadow: ${theme.shadow.secondary};
      border-color: ${theme.mainPalette.grey.border};
      font-weight: ${theme.fontWeight.thin};
      color: ${theme.mainPalette.typography.main};
      :hover {
        border: none;
        box-shadow: ${theme.shadow.big};
      }
    `}
    border: none;
    padding: 6px 0 6px 8px;
    text-transform: inherit;
  }
  .MuiButton-endIcon {
    margin: 0 0 0 5px !important;
  }
  .MuiButton-label {
    margin-right: 5px;
  }
`

const ModerationApplyBtnView = styled(ScPrimaryBtn)`
  &.MuiButton-root {
    margin-right: 12px;
  }
`

const defaultActions = [
  { icon: <SnowCancelScheduleSendIcon />, name: 'Refuse', action: 'refuse' },
  { icon: <SnowAccessTimeOutlinedIcon />, name: 'Waiting', action: 'waiting' },
  { icon: <SnowThumbUpIcon />, name: 'Approve', action: 'approve' }
]
const actionOptionToItem = (option) => {
  if (typeof option !== 'string') return null
  const icon = defaultActions.find(({ action }) => action === option).icon
  return { icon: icon, name: option, action: option }
}

export const ModerationCmdBtn = ({
  icon,
  selected,
  actions: options = defaultActions.map(({ action }) => action),
  status: moderationDefaultStatus = '',
  handleModerationSelected = (action) => {
    console.log('action', action)
  },
  handleApply = () => {
    console.log('gonna apply')
  },
  cmdStyle = 'text',
  applyStyle = 'contained'
}) => {
  const theme = useContext(ThemeContext)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const defaultState = moderationDefaultStatus || 'Set to ...'
  const [selectedCmd, setSelectedCmd] = useState(defaultState)

  const actions = options.map((option) => actionOptionToItem(option))
  useEffect(() => {
    if (!selected) setSelectedCmd(moderationDefaultStatus)
  }, [selected])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (cmd) => {
    setAnchorEl(null)
    const label = typeof cmd === 'string' ? cmd : cmd.name
    setSelectedCmd(label)
    cmd.name && handleModerationSelected(cmd.action)
  }
  console.log('Moderation button', actions, selectedCmd, defaultState)
  const showApplyButton = !!(selectedCmd !== defaultState && actions.length)
  const showActionsMenu = !!actions.length
  return (
    <StyledButtonContainer>
      {showApplyButton && (
        <ModerationApplyBtnView
          size={'medium'}
          id="apply-command-button"
          label={'Apply'}
          variant={applyStyle}
          onClick={handleApply}
        />
      )}
      {showActionsMenu ? (
        <>
          <ModerationBtnView
            id="moderation-commands-button"
            aria-controls="moderation-commands-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant={cmdStyle}
            disableElevation={true}
            onClick={handleClick}
            {...(!showApplyButton && icon && { startIcon: icon })}
            endIcon={<SnowArrowDropDownIcon />}
            disableTouchRipple={true}
          >
            {selectedCmd}
          </ModerationBtnView>

          <ScPopupMenu
            id="moderation-commands-menu"
            MenuListProps={{
              'aria-labelledby': 'moderation-commands-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose(defaultState)}
            variant={'menu'}
          >
            {
              actions?.length ? actions.map((act, index) =>
              (<SnowMenuItem key={`${index}`} onClick={() => handleClose(act)}>
                {act.icon}
                <SnowTypography component={'span'} variant={'inherit'} color={theme.mainPalette.typography.main}>{act.name}</SnowTypography>
              </SnowMenuItem>)) : null
            }
          </ScPopupMenu>
        </>
      ) : (
        'No Moderation options'
      )}
    </StyledButtonContainer>
  )
}
export default ModerationCmdBtn
