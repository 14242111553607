import React, { useState } from 'react'
import { SnowTypography } from '../snowComponents'
import { SnowKeyboardArrowDownIcon, SnowKeyboardArrowUpIcon } from '../icons'
import styled from 'styled-components'

const MenuView = styled.div`
  position: relative;
`

const MenuButtonView = styled.button`
  padding: 10px 15px;
  margin: 0 9px;
  display: flex;
  cursor: pointer;
  border: none;
  transition: background ${({ theme }) => theme.transition.main};
  box-shadow: none;
  background: transparent;
  border-radius: ${({ theme }) => theme.borderRadius.secondary};
  color: ${({ theme }) => theme.mainPalette.typography.contrast};
  .MuiTypography-root {
    color: inherit;
  }
  p {
    display: none;
    @media (min-width: 472px) {
      display: block;
      margin-right: ${({ theme }) => theme.spacing.main};
    }
  }
  svg {
    fill: ${({ theme }) => theme.mainPalette.color.contrast};
  }
  :hover {
    background: ${({ theme }) => theme.mainPalette.color.link};
    cursor: pointer;
  }
`

const MenuListView = styled.div`
  min-width: 150px;
  position: absolute;
  z-index: 100;
  right:0;
  color: ${({ theme }) => theme.mainPalette.typography.contrast};
  background: ${({ theme }) => theme.mainPalette.color.main};
  .MuiTypography-root {
    color: inherit;
  }
  a {
    padding: ${({ theme }) => theme.spacing.secondary};
    display: flex;
    align-items: center;
    color: inherit;
    position: relative;
    ::before {
      content: '';
      opacity: 0;
      position: absolute;
      right: 0;
      width: 4px;
      height: 100%;
      ${({ theme }) => `
        background: ${theme.mainPalette.color.hilight};
        transition: opacity ${theme.transition.main};
      `}
    }
    :hover::before {
      opacity: 1;
    }
  }
  svg {
    margin-right:  ${({ theme }) => theme.spacing.secondary};
    width: 16px;
  }
  span {
    color: inherit;
  }
`

const MenuBackView = styled.button`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 80;
  background: transparent;
  border: none;
`

const ScMenuButton = ({ label, options }) => {
  const [collapse, setCollapse] = useState(false)
  const toggle = () => {
    setCollapse(!collapse)
  }

  return (
    <MenuView>
      <MenuButtonView onClick={toggle}>
        <SnowTypography component={'p'} variant={'body2'}>{label}</SnowTypography>
        {collapse ? (
          <SnowKeyboardArrowUpIcon fontSize={'small'} />
        ) : (
          <SnowKeyboardArrowDownIcon fontSize={'small'} />
        )}
      </MenuButtonView>
      {collapse && <MenuBackView onClick={toggle} />}
      {collapse ? (
        <MenuListView >
          {options.map(({ onClick, label, icon: Icon }) => (
            <a
              key={label}
              href="/"
              onClick={() => {
                onClick()
                toggle()
              }
              }
            >
              <Icon color="secondary" />
              <SnowTypography component={'span'} variant='body2'>{label}</SnowTypography>
            </a>
          ))}
        </MenuListView>
      ) : null}
    </MenuView>
  )
}

export default ScMenuButton
