import objWithParent from '../../../../utils/parenter'
import { useEntityCommand } from '../../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../../infrastructure/api/utils'
const PROJECT_CREATE_URL = `add`

const API_PROJECTS = objWithParent({
  url: '/shops',
  ADD: {
    version: 'v1',
    url: `${PROJECT_CREATE_URL}`,
    method: 'POST',
    caller: 'PROJECT_CREATE_URL!',
    validator: ({ shop_name, client_id }) => true
  }
})

export const useCreateProjectCmd = (
  { clientId, projectName },
  onSuccess = (r) => console.log('useCreateProjectCmd onSuccess', r),
  onFail = (r) => console.log('useCreateProjectCmd onFail', r)
) => {
  const createChannelCmd = useEntityCommand(
    0,
    {
      ...API_PROJECTS.ADD,
      params: {
        client_id: clientId,
        shop_name: projectName
      }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return createChannelCmd
}
