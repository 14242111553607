import React, { useEffect, useState } from 'react'
import {
  generateChannelShippingMapping,
  useChannelFields,
  useChannelShippingMethodCreate,
  useChannelShippingMethodRemove,
  useChannelShippingMethodsModel,
  useChannelShippingMethodUpdate
} from '../apiConfigs'
import { ScAddButton } from '../../../../../../shared/components/buttons'
import Mapper from '../../../../components/Mapper'

const ChannelShippingMethodsMapper = ({
  onInit,
  onMap,
  channel: channelId,
  channelName,
  defaultValue
}) => {
  const [newMappingSaver, setNewMappingSaver] = useState(null)
  console.log(
    'ChannelShippingMethodsMapper',
    onInit,
    onMap,
    channelId,
    channelName,
    defaultValue
  )
  const saveMap = () => {
    console.log('saveMap', newMappingSaver)
    return newMappingSaver()
  }

  useEffect(() => {
    console.log('ChannelShippingMethodsMapper newMappingSaver', newMappingSaver)
    newMappingSaver && onMap({ saveCmd: saveMap })
  }, [newMappingSaver])

  return (
    <Mapper
      defaultValue={defaultValue}
      incomeValueFieldName={'channelShippingValue'}
      addBtn={ScAddButton}
      aggregateId={{ channelId }}
      aggregateName={channelName}
      aggregateTypeName={'sales channel'}
      createCommandProvider={useChannelShippingMethodCreate}
      removeCommandProvider={useChannelShippingMethodRemove}
      updateCommandProvider={useChannelShippingMethodUpdate}
      fieldsProvider={useChannelFields}
      mappingsProvider={useChannelShippingMethodsModel}
      newMappingGenerator={generateChannelShippingMapping}
      onInit={onInit}
    />
  )
}

export default ChannelShippingMethodsMapper
