import React, { useState, useContext } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import {
  SnowDivider,
  SnowList,
  SnowListItem,
  SnowListItemAvatar,
  SnowListItemText,
  SnowTypography
} from '../../../../shared/components/snowComponents'
import {
  SnowCheckBoxOutlineBlankIcon,
  SnowCheckBoxIcon,
  SnowDoneAllIcon
} from '../../../../shared/components/icons'
import {
  ScBasicModal,
  ScConfirmationAlert
} from '../../../../shared/components/modals'
import { currentOrderModalsData } from '../data/orderUxState'
import { useApplyOrderItemSolutionCmd } from '../../infrastructure/orderItemApi'
import ScSpinner from '../../../../shared/components/ScSpinner/ScSpinner'
import { ThemeContext } from 'styled-components'

function OrderItemResolvingModal({ isOpen = false }) {
  const theme = useContext(ThemeContext)
  const [open, setOpen] = useState(isOpen)
  const [successOpen, setSuccessOpen] = useState(false)
  const resetModalData = useResetRecoilState(
    currentOrderModalsData('orderItemResolving')
  )
  const item = useRecoilValue(currentOrderModalsData('orderItemResolving'))
  const [currentSolution, setCurrentSolution] = useState('')
  const { doActions } = item
  const applySolution = useApplyOrderItemSolutionCmd(item, currentSolution)
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState('')
  const handleApply = () => {
    console.log('applySolution', { item, currentSolution })
    setIsLoading(true)
    applySolution().then((resp) => {
      console.log('Solution applied', resp)
      setResponse(resp)
      setIsLoading(false)
    })
    setOpen(false)
    setSuccessOpen(true)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    resetModalData()
  }

  const handleListItemClick = (solution) => {
    setCurrentSolution(solution)
  }

  const handleSuccessClose = () => {
    setSuccessOpen(false)
  }

  return (
    <>
      <ScBasicModal
        maxWidth={'sm'}
        open={open}
        onClose={handleClose}
        onConfirm={handleApply}
        labelForConfirmBtn={'Apply solution'}
        onConfirmProps={{ disabled: currentSolution === '' }}
        title={
          <SnowTypography component={'p'} variant={'body1'} fontWeight={500}>
            {
              'Snow Axis cannot find a matching record for this item. In order to resolve the error, choose an option below and click "Apply"'
            }
          </SnowTypography>
        }
        content={
          <>
            {isLoading && <ScSpinner />}
            {!isLoading && !response ? (
              <>
                <SnowList>
                  {doActions.map((solution) => (
                    <SnowListItem
                      button
                      onClick={() => handleListItemClick(solution)}
                      key={JSON.stringify(solution)}
                    >
                      {solution.title === currentSolution.title ? (
                        <SnowListItemAvatar>
                          <SnowCheckBoxIcon sx={{fill: theme.mainPalette.grey.main}} />
                        </SnowListItemAvatar>
                      ) : (
                        <SnowListItemAvatar>
                          <SnowCheckBoxOutlineBlankIcon sx={{fill: theme.mainPalette.grey.main}}/>
                        </SnowListItemAvatar>
                      )}
                      <SnowListItemText
                        primary={solution.title}
                        secondary={solution.description}
                      />
                    </SnowListItem>
                  ))}
                </SnowList>
                <SnowDivider />
              </>
            ) : null}
          </>
        }
      />
      <ScConfirmationAlert
        isShowAttention={successOpen}
        onClose={handleSuccessClose}
        labelForCancelBtn="OK"
        icon={
          <SnowDoneAllIcon style={{ fill: theme.mainPalette.status.success }} />
        }
        message={{
          title: 'Solution was successfully applied',
          content: 'Order item state will be updated after solution handling.'
        }}
      />
    </>
  )
}

export default OrderItemResolvingModal
