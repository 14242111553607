import React from 'react'
import { SnowLink, SnowTypography, SnowListItem } from '../../snowComponents'
import styled from 'styled-components'

const AddListItemButtonView = styled(SnowTypography)`
  padding: 0 10px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({theme})=>theme.mainPalette.color.main};
  transition: background ${({theme})=>theme.transition.main};
  :hover {
    background: ${({theme})=>theme.mainPalette.color.secondary};
  }
  cursor: pointer;
  &.MuiTypography-root {
    color: ${({theme})=>theme.mainPalette.typography.contrast};
  }
`

const ScAddListItemButton = ({ onClick }) => {
  return (
    <SnowListItem aria-label = {'add-list-item-button'}>
      <SnowLink onClick={onClick}>
        <AddListItemButtonView
          variant={'body2'}
          component='span'
        >
          {'+'}
        </AddListItemButtonView>
      </SnowLink>
    </SnowListItem>
  )
}

export default ScAddListItemButton
