import { ScOutlinedBtn } from '../../../../../shared/components/buttons'
import styled, { css } from 'styled-components'

const OrderCardOutlinedBtn = styled(ScOutlinedBtn)`
  ${({ theme }) => css`
    &.MuiButton-outlinedPrimary {
      padding: 8px 11px;
      background: ${theme.mainPalette.color.lightContrast};
      span {
        color: ${theme.mainPalette.color.main};
      }
    }
  `}
`

export default OrderCardOutlinedBtn 
