import React, { Fragment, useCallback, useEffect } from 'react'
import { useProduct } from '../../../context/product'
import { usePrevious } from '../../../usePrevious'
import ScList from '../../../../../shared/components/widgets/ScList/ScList'
import ScArrayList from '../../../../../shared/components/ScNestedContent/ScArrayList'
import { SnowTypography } from '../../../../../shared/components/snowComponents'

const ArrayContent = ({
  addArrayItem,
  getContent,
  meta,
  values,
  level,
  linePath
}) => {
  const arrayValueItems = values ? values : []
  const previousItems = usePrevious(arrayValueItems)
  const { editState } = useProduct()
  console.log(
    'getArrayBloc path, meta, values',
    linePath,
    meta,
    'values',
    arrayValueItems
  )
  const refs = arrayValueItems.reduce((acc, value) => {
    acc[value.id] = React.createRef()
    return acc
  }, {})

  const { array_type, label, editable } = meta

  const arrayMetaItems = Object.entries(array_type).map(([key, value]) => {
    return { ...value, code: key }
  })

  const arrayLinePath = !linePath
    ? `${meta.code}%key%`
    : linePath.replace('%key%', `${meta.code}%key%`)

  console.log(
    'arrayMetaItems:',
    arrayMetaItems,
    label,
    editable,
    '  arrayLinePath:',
    arrayLinePath,
    previousItems
  )
  const getItemLinePath = useCallback(
    (idx = 0) => {
      return arrayLinePath.replace('%key%', `[${idx}].%key%`)
    },
    [arrayLinePath]
  )
  const handleLastElement = (id) =>
    refs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  const handleAddItem = () => {
    // setNewItemAdded(true)
    console.log('handleAddItem', { arrayLinePath, meta })
    addArrayItem({ arrayPath: arrayLinePath, meta })
  }

  useEffect(() => {
    console.log('useEffect arrayValueItems', arrayValueItems, previousItems)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayValueItems])

  const getArrayItem = (itemValue, itemIdx) => {
    return (
      <ScList
        key={JSON.stringify(itemValue) + itemIdx}
        itemProps={{
          disableGutters: true,
          style: {
            paddingBottom: 0,
            paddingTop: '5px',
            margin: '0',
            width: '100%',
            marginBottom: '0.3em',
            '& > div': {
              marginBottom: '5px'
            }
          }
        }}
      >
        {arrayMetaItems.map((itemMeta, idx) => {
          return (
            <Fragment
              key={JSON.stringify(itemMeta) + JSON.stringify(itemValue)}
            >
              {getContent({
                meta: {
                  ...itemMeta,
                  itemOf: { ...meta, id: itemValue['id'] }
                },
                values: itemValue[itemMeta.code],
                level,
                linePath: `${getItemLinePath(itemIdx)}`
              })}
            </Fragment>
          )
        })}
      </ScList>
    )
  }

  return (
    <>
      <ScArrayList
        paper
        items={arrayValueItems.map(getArrayItem)}
        label={label}
        editable={editable}
        editState={editState}
        onAddNew={handleAddItem}
      />
      {(!arrayValueItems || !arrayMetaItems.length) && (
        <SnowTypography variant={'h6'}>{label} not listed</SnowTypography>
      )}
    </>
  )
}

export default ArrayContent
