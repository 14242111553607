import React from 'react'
import Select from '@mui/material/Select'

const SnowSelect = React.forwardRef(function SnowSelect(
  { children, ...props },
  ref
) {
  return (
    <Select ref={ref} {...props}>
      {children}
    </Select>
  )
})

export default SnowSelect
