import { SnowTableCell } from '../../../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const PriceCell = styled(SnowTableCell).attrs({
  size: 'small',
  align: 'right'
})`
  white-space: nowrap;
`

export default PriceCell
