import React from 'react'
import { Filter } from '../_styled'
import Searcher2 from '../../../../../shared/components/searcher/Searcher2'
import PropTypes from 'prop-types'

const OrderNumberFilter = (props) => {
  console.log('OrderNumberFilter', props.meta)
  return (
    <Filter>
      <Searcher2
        code="orderNum"
        placeholder={'Order'}
        value={props.selectValue}
        meta={props.meta}
        loading={props.loading}
        tooltip={props.tooltip}
        disableClearable={false}
        onClear={() => props.setFilter('orderNum', '')}
        onSearchUpdate={(value) => {
          console.log('onSearchUpdate', value)
          props.setFilter('orderNum', value)
        }}
      />
    </Filter>
  )
}

OrderNumberFilter.propTypes = {
  selectValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  isFilterActive: PropTypes.string.isRequired
}

export default OrderNumberFilter
