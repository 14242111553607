import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { portalRequestor } from '../../../infrastructure/api/v1/requestors'

/**
 * Entity Config JSON
 * contains entity API base url, and commands configs set
 * @type {{APPROVE_CUSTOMIZATION: {caller: string, method: string, validator:
 *   (function({order?: *}): boolean), url: string}, url: string}}
 */
const API_ORDER_ITEM = objWithParent({
  url: '/item',
  UPDATE_POSSIBLE_VENDORS: {
    url: 'updatePossibleVendors',
    method: 'POST',
    caller: 'UPDATE_POSSIBLE_VENDORS',
    validator: () => true
  },
  APPLY_SOLUTION_TO_RESOLVE: {
    url: 'doAction',
    method: 'POST',
    caller: 'APPLY_SOLUTION_TO_RESOLVE',
    validator: () => true
  },
  SAVE: {
    url: 'update',
    method: 'POST',
    caller: 'ORDER ITEM EDIT SAVE ',
    validator: ({ data }) => true
  }
})

const API_ORDER_ITEM_V2 = objWithParent({
  url: '/order-item',
  SET_PERSONALIZATION_IMAGE: {
    url: 'set/personalization/image',
    method: 'POST',
    caller: 'SET_PERSONALIZATION_IMAGE',
    validator: () => true
  },

  DO_PERSONALIZATION_CMD: {
    url: 'personalization/',
    method: 'POST',
    caller: 'DO_PERSONALIZATION_CMD',
    validator: () => true
  },
  DO_PERSONALIZATION_APPROVE: {
    url: 'personalization/approve',
    method: 'POST',
    caller: 'DO_PERSONALIZATION_APPROVE',
    validator: () => true
  },
  DO_PERSONALIZATION_REFUSE: {
    url: 'personalization/refuse',
    method: 'POST',
    caller: 'DO_PERSONALIZATION_REFUSE',
    validator: () => true
  },
  DO_PERSONALIZATION_WAITING: {
    url: 'personalization/waiting',
    method: 'POST',
    caller: 'DO_PERSONALIZATION_WAITING',
    validator: () => true
  },
  DO_PERSONALIZATION_RESEND: {
    url: 'personalization/resend',
    method: 'POST',
    caller: 'DO_PERSONALIZATION_RESEND',
    validator: () => true
  }
})

export const useOrderItemSaveCmd = (
  id,
  { data },
  onSuccess = (r) => console.log('useOrderItemSaveCmd success', r),
  onFail = (r) => console.log('useOrderItemSaveCmd fail', r)
) => {
  const result = useEntityCommand(
    id,
    {
      ...API_ORDER_ITEM.SAVE,
      params: { fields: data }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}
export const useApplyOrderItemPersonalizationCmd = (
  { id: entityId },
  { action: actionName },
  onSuccess = (r) =>
    console.log('useApplyOrderItemPersonalizationCmd success', r),
  onFail = (r) => console.log('useApplyOrderItemPersonalizationCmd fail', r)
) => {
  console.log('useApplyOrderItemPersonalizationCmd', entityId, actionName)
  const cmdUrl = API_ORDER_ITEM_V2.DO_PERSONALIZATION_CMD.url + actionName
  const result = useEntityCommand(
    entityId,
    {
      ...API_ORDER_ITEM_V2.DO_PERSONALIZATION_CMD,
      url: cmdUrl
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}
export const useResendOrderItemPersonalizationCmd = (
  { id: entityId },
  { personalizer, type },
  onSuccess = (r) =>
    console.log('useResendOrderItemPersonalizationCmd success', r),
  onFail = (r) => console.log('useResendOrderItemPersonalizationCmd fail', r)
) => {
  console.log(
    'useResendOrderItemPersonalizationCmd',
    entityId,
    personalizer,
    type
  )
  const result = useEntityCommand(
    entityId,
    {
      ...API_ORDER_ITEM_V2.DO_PERSONALIZATION_RESEND,
      params: { data: { personalizer, type } }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useSetOrderItemPersonalizationImageCmd = (
  { entityId },
  { imageUrl, format },
  responseHandler = (resp) =>
    console.log(
      `${entityId} item useSetOrderItemPersonalizationImage ${imageUrl} response `,
      resp
    )
) => {
  const result = useEntityCommand(
    entityId,
    {
      ...API_ORDER_ITEM_V2.SET_PERSONALIZATION_IMAGE,
      params: { image: imageUrl, format }
    },
    responseHandler
  )
  return result
}

export const useApplyOrderItemSolutionCmd = (
  { id: entityId },
  { action },
  responseHandler
) => {
  console.log('useApplyOrderItemSolutionCmd', entityId, action)
  const result = useEntityCommand(
    entityId,
    {
      ...API_ORDER_ITEM.APPLY_SOLUTION_TO_RESOLVE,
      params: { action }
    },
    responseHandler
  )
  return result
}

export const useUpdateOrderItemPossibleVendorsCmd = (
  { id: entityId },
  vendors,
  responseHandler
) => {
  const result = useEntityCommand(
    entityId,
    {
      ...API_ORDER_ITEM.UPDATE_POSSIBLE_VENDORS,
      params: vendors
    },
    responseHandler
  )
  return result
}

// function to cancel an item on an order
export const cancelOrderItem = ({ itemId }) => {
  return portalRequestor({
    url: `item/${itemId}/cancel`,
    type: 'get',
    caller: 'cancelOrderItem'
  })
}
