import React from 'react'
import { integrationCqrsRequestModel } from '../../data/integrationDeterminant'
import { useRecoilState } from 'recoil'
import ProjectChooser from './ProjectChooser'
import ProjectConnector from './ProjectIntegration/ProjectConnector'
import { SnowBox } from '../../../../shared/components/snowComponents'

const IntegrationProjectChooser = ({
  projectId = 0,
  onIntegrationSelected
}) => {
  const [integrationParams, setIntegrationParams] = useRecoilState(
    integrationCqrsRequestModel
  )
  const onProjectConfirm = (projectDto) => {
    console.log('onProjectDoubleClicked projectDto', projectDto)
    const { id } = projectDto

    console.log('IntegrationProjectChooser onProjectConfirm dto', projectDto)

    setIntegrationParams((oldParams) => ({
      ...oldParams,
      vendor: '0',
      project: `${projectDto.id}`,
      channel: '0'
    }))
    onIntegrationSelected(id, { project: projectDto })
  }
  const onProjectClicked = (projectDto) => {
    setIntegrationParams((oldValues) => ({
      ...oldValues,
      vendor: '0',
      project: `${projectDto.id}`,
      channel: '0'
    }))
  }
  return (
    <>
      <SnowBox p={1}>
        <ProjectConnector />
      </SnowBox>
      <ProjectChooser
        projectId={projectId}
        onConfirm={onProjectConfirm}
        onItemSelect={onProjectClicked}
        dtoFields={['id', 'name']}
      />
    </>
  )
}

export default IntegrationProjectChooser
