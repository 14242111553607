import React, { useState } from 'react'
// import UploadModal from '../../../shared/components/uploadModal'
import { ScPrimaryBtn } from '../../../shared/components/buttons'
import { useUploadBundlesCmd } from '../infrastructure/apisV2'
import {ScUploadModalV2} from '../../../shared/components/modals/'

const useUploaderCmdWithCallback = (file) => {
  const [r, setR] = useState(null)

  const onUpload = (r) => {
    //you can set shared state with response or do other shared actions
    setR(r)

    //return value to allow uploader modal handle response too
    return r
  }
  return useUploadBundlesCmd(file, onUpload)
}

const UploadBundleModalBtn = () => {
  const [showUpload, setShowUpload] = useState(false)
  const toggleUpload = () => {
    setShowUpload(!showUpload)
  }
  console.log('useUploaderCmdWithCallback', useUploaderCmdWithCallback)
  return (
    <>
      <ScPrimaryBtn label={'Upload Bundles CSV'} onClick={toggleUpload} />
      <ScUploadModalV2
        modalTitle="Import Bundles"
        fileParamName="bundles_file"
        useUploadCmd={useUploaderCmdWithCallback}
        showModal={showUpload}
        toggleShowModal={toggleUpload}
      />
    </>
  )
}

export default UploadBundleModalBtn
