import React from 'react'
import Searcher from '../../../../shared/components/searcher/Searcher'

const VendorsSearcher = ({ label = '', placeholder = '' }) => {
  //todo: Abstract recoil states to Repository methods kinda {reset} = useProjectsRepository()
  const onSearchUpdate = (e) => {
    console.log('VendorsSearcher onSearchUpdate', e)
  }
  console.log('VendorsSearcher!')
  return <Searcher tooltip={label ? `Type to ${label}` : ''}
    onSearchUpdate={onSearchUpdate} label={label} placeholder={placeholder} />
}

export default VendorsSearcher
