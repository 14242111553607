import React from 'react'
import { SnowCheckbox } from '../../snowComponents'
import { SnowCheckBoxOutlineBlankOutlinedIcon, SnowIndeterminateCheckBoxOutlinedIcon, SnowCheckBoxOutlinedIcon } from '../../icons'

export const MULTICHECK_STATE = { None: 0, Partial: 1, Full: 2 }

const getOutlinedMultiCheckIcon = (status) => {
  switch (status) {
    case MULTICHECK_STATE.None:
      return <SnowCheckBoxOutlineBlankOutlinedIcon />
    case MULTICHECK_STATE.Partial:
      return <SnowIndeterminateCheckBoxOutlinedIcon />
    case MULTICHECK_STATE.Full:
      return <SnowCheckBoxOutlinedIcon />
    default:
      return <SnowCheckBoxOutlineBlankOutlinedIcon />
  }
}

const SnowMultiCheckbox = ({ selectionState, onChange }) => (
  <SnowCheckbox
    indeterminate={selectionState === MULTICHECK_STATE.Partial}
    checked={selectionState === MULTICHECK_STATE.Full}
    icon={getOutlinedMultiCheckIcon(selectionState)}
    onChange={onChange}
  />
)

export default SnowMultiCheckbox
