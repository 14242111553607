import React from 'react'
import {
  useCurrentOrder,
  getConsistentObjOrThrow
} from '../../../data/currentOrderState'

/*{
		"discount_lines": [
			{
				"type": "manual",
				"title": "",
				"value": "6.0",
				"value_type": "fixed_amount",
				"description": null,
				"target_type": "line_item",
				"target_selection": "explicit",
				"allocation_method": "one"
			} // Fixed amount discount of $6 is applied per line_item, with code
			{
      "type": "manual",
      "title": "Test Order",
      "value": "100.0",
      "value_type": "percentage",
      "description": "Test Order",
      "target_type": "line_item",
      "target_selection": "all",
      "allocation_method": "across"
    } // Percentage discount of 100% is applied per line_item, with code "Test Order"
		],
}*/
const wrapWithValueDomain = ({ value, value_type }) => {
  const valueDomains = { fixed_amount: `$${value}`, percentage: `${value}%` }
  return valueDomains[value_type]
}
const getAppliedCode = (appliedCode = '') => {
  return appliedCode ? `,with coupon code "${appliedCode}"` : ''
}

const getDiscountNote = (discountLine) => {
  const expectedShopifytFields = [
    'type', //type of application : manual | automatic
    'title', // title of the price rule
    'code', //applied coupon code
    'value', //applied discount value
    'value_type', //The value type of the price rule :fixed_amount|percentage
    'description',
    'target_type', // target type applies to : line_item | shipping_line
    'target_selection', //The target selection method of the price rule : all | entitled
    'allocation_method' //The allocation method of the price rule : each | across
  ]
  const required = ['type', 'value', 'value_type']
  try {
    const {
      type,
      title,
      code,
      value,
      value_type,
      description,
      target_type,
      target_selection,
      allocation_method
    } = getConsistentObjOrThrow(expectedShopifytFields, discountLine, required) // against unexpected fields

    console.log('Discount line passed validation', discountLine)
    const applyType = `${type}`
    const valueType = `${value_type.replace('_', ' ')}`
    const domainedValue = wrapWithValueDomain({
      value_type,
      value
    })
    const targetType = target_type.replace('_', ' ')
    const appliedCode = getAppliedCode(code)
    const appliedAllocationMethod =
      allocation_method === 'each' ? 'to each of' : 'across'
    return `${applyType[0].toUpperCase()+applyType.substring(1)} ${valueType} discount of ${domainedValue} is applied ${appliedAllocationMethod} the ${target_selection} ${targetType}. ${appliedCode} `
  } catch (e) {
    console.debug(e)
    return `Discount data obj error. '${JSON.stringify(e)}' is missed`
  }
}
const OrderDiscountNote = () => {
  const order = useCurrentOrder()
  const { discountLines } = order ?? {}

  console.log('discountLines', {discountLines})

  return (
    <>
      {discountLines?.map((line) => (
        <div key={JSON.stringify(line)}>{getDiscountNote(line)}</div>
      ))}
    </>
  )
}

export default OrderDiscountNote
