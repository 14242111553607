import React, { useState } from 'react'
import { SnowLink } from '../../../../../shared/components/snowComponents'
import EnterTrackingModal from '../../Modals/EnterTrackingModal'
import styled from 'styled-components'

const StyledLink = styled(SnowLink).attrs({
  component: "button",
  variant: "body2",
})`
  &.MuiTypography-root{
    color: ${({ theme }) => theme.mainPalette.color.secondary};
  }
`

function EnterTrackingRef({ vendor, vendorOrder = null }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <EnterTrackingModal
        open={open}
        onClose={handleClose}
        vendor={vendor}
        vendorOrder={vendorOrder}
      />
      <StyledLink
        onClick={handleOpen}
      >
        Enter tracking
      </StyledLink>
    </>
  )
}

export default EnterTrackingRef
