import styled, { css } from 'styled-components'

const OrderItemNumber = styled.div`
  ${({ theme }) => css`
    grid-row: 1/2;
    grid-column: 1/2;
    align-self: start;
    padding: 3px 10px;
    background: ${theme.mainPalette.grey.middle};
    border-radius: 25px;
    border: ${theme.border.chip};
    span {
      color: ${theme.mainPalette.color.contrast};
    }
  `}
`

export default OrderItemNumber