import React, { useEffect, useRef, useState } from 'react'
import { useLoadedVendorRegions } from './loadedVendorRegions'
import { removeEmptyProps } from '../../../utils/helpers'
import {
  useCreateVendorRegionCmd,
  useUpdateVendorRegionCmd
} from '../infrastructure/regionVendorsApi'

const useCreateRegionVendor = ({ area_group_id }) => {
  const [regionVendorFields, setRegionVendorFields] = useState(null)
  const createRegionVendorCmd = useCreateVendorRegionCmd({
    area_group_id,
    vendor_id: regionVendorFields?.vendorId,
    status: regionVendorFields?.status,
    priority: regionVendorFields?.priority
  })
  const executeCreate = useRef(null)
  const createRegionVendor = ({ vendorId, status, priority }) => {
    if (!vendorId || !status || !priority) return null
    setRegionVendorFields({ vendorId, status, priority })
    return { execute: executeCreate.current }
  }
  useEffect(() => {
    executeCreate.current = createRegionVendorCmd
  }, [regionVendorFields])
  return createRegionVendor
}
const useRegionVendor = ({ id }) => {
  const [regionVendorFields, setRegionVendorFields] = useState(null)

  const updateRegionVendorCmd = useUpdateVendorRegionCmd({
    id,
    vendor_id: regionVendorFields.vendorId,
    status: regionVendorFields.status,
    priority: regionVendorFields.priority
  })
  const executeUpdate = useRef(null)

  const updateRegionVendor = ({ areaGroupId, vendorId, status, priority }) => {
    setRegionVendorFields({ areaGroupId, vendorId, status, priority })
    return { execute: executeUpdate.current }
  }
  useEffect(() => {
    executeUpdate.current = updateRegionVendorCmd
  }, [regionVendorFields])
  return { updateRegionVendor }
}

const useRegionVendors = ({ area_group_id }) => {
  const [vendors, setVendors] = useState(null)
  const createRegionVendorCmd = useCreateRegionVendor({ area_group_id })
  const [page, setPage] = useState(1)
  const { loadedVendorRegions, onRemove } = useLoadedVendorRegions(
    removeEmptyProps({
      page,
      per_page: 50,
      area_group_id
    })
  )

  const updateVendors = (ids = []) => {
    Array.isArray(ids) && setVendors(ids)
  }

  return { areaGroupVendors: loadedVendorRegions, createRegionVendorCmd }
}

export default useRegionVendors
