import React, { useState } from 'react'
import { ScPrimaryBtn } from '../../../../shared/components/buttons'
import { ScUploadModal } from '../../../../shared/components/modals'
import { uploadOrders } from '../../infrastructure/fetches'

const UploadOrderModalBtn = () => {
  const [showUpload, setShowUpload] = useState(false)

  const toggleUpload = () => {
    setShowUpload(!showUpload)
  }
  return (
    <>
      <ScPrimaryBtn label={'Upload Orders CSV'} onClick={toggleUpload} />

      <ScUploadModal
        modalTitle="Import orders"
        fileParamName="orders_file"
        uploadFn={uploadOrders}
        showModal={showUpload}
        toggleShowModal={toggleUpload}
      />
    </>
  )
}

export default UploadOrderModalBtn
