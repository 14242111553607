import React, { useEffect } from 'react'
import ScTabs from '../../../../../shared/components/form/ScTabs'
import { integrationCqrsRequestModel } from '../../../data/integrationDeterminant'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { SnowTypography, SnowBox } from '../../../../../shared/components/snowComponents'
import { setScPageHeaderTitleCmd } from '../../../../app/data/currentHeadingState'
import { useNavigate, useParams } from 'react-router-dom'
import VendorChooser from '../../vendors/VendorsChooser'
import { TabContent } from '../../../../../shared/components/form/ScTabs/ScTabs'
import { projectWithId } from '../../../hooks/useProjects'
import IntegrationChannelChooser from '../../salesChannels/IntegrationChannelChooser'
import ChannelConnector from './ChannelConnector'

const withActive = (tabConfigs = [], activeValue) => {
  return tabConfigs.map(({ value, ...rest }) => ({
    ...rest,
    value,
    active: value === activeValue
  }))
}

export const ProjectIntegration = (props) => {
  const {
    clientId,
    domainId: projectId,
    projectEntity: projectEntityName
  } = useParams()
  console.log('integrationParams.project!', projectId, projectEntityName)

  const { id, name } = useRecoilValue(projectWithId(projectId))
  const projectEntitiesConfigTabs = [
    {
      title: 'Channels',
      value: 'channels',
      element: (
        <IntegrationChannelChooser
          projectId={projectId}
          onIntegrationSelected={() => console.log('onIntegrationSelected')}
        />
      )
    },
    {
      title: 'Vendors',
      value: 'vendors',
      element: <VendorChooser projectId={projectId} />
    }
  ]
  const navigate = useNavigate()
  const tabs = withActive(projectEntitiesConfigTabs, projectEntityName)
  const onChange = (e, value) => {
    console.log('ProjectIntegration onTab', { e, value })
    navigate(`../${value}`, { replace: true })
  }

  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)

  const setIntegrationParams = useSetRecoilState(integrationCqrsRequestModel)

  useEffect(() => {
    !projectEntityName && navigate('channels')
  }, [projectEntityName])

  useEffect(() => {
    console.log('useEffect integrationParams.project!', projectId)
    setIntegrationParams((oldValues) => ({
      ...oldValues,
      client: clientId,
      project: projectId,
      type: projectEntityName
    }))

    setHeading(`Integrations for project ${name}`)
  }, [clientId, projectId])

  return (
    <div>
      <SnowBox p={2}>
        <SnowTypography variant={'h6'}>
          Project ID: {projectId && projectId}
        </SnowTypography>
        <ChannelConnector projectId={projectId} />
      </SnowBox>
      {projectId && (
        <ScTabs onTab={onChange}>
          {tabs.map((tab) => {
            return (
              <TabContent
                key={tab.value}
                label={tab.title}
                value={tab.value}
                active={tab.active}
              >
                {tab.element}
              </TabContent>
            )
          })}
        </ScTabs>
      )}
    </div>
  )
}
export default ProjectIntegration
