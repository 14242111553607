import React from 'react'
import { ReactComponent as LoadingIconSVG } from '../../../app/images/SVGs/loading.svg'
import styled, {css} from 'styled-components'
import '../../../app/scss/component/general.scss'

const StyledLoadingIcon = styled(LoadingIconSVG)`
  ${({theme})=>css`
    width: 50px;
    animation: ${theme.animation.rotation};
  `}
`

const LoadingIcon = ({ ...props }) => <StyledLoadingIcon {...props} />

export default LoadingIcon
