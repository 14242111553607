import React, { useEffect } from 'react'
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { ScPrimaryBtn } from '../../../../shared/components/buttons'
import { setScPageHeaderTitleCmd } from '../../../app/data/currentHeadingState'
import { useDeleteBundleItemsCmd } from '../../infrastructure/apisV2'
import { selectedBundles } from '../data/bundlehubData'
import CreateBundleModalBtn from '../CreateBundleModalBtn'
import UploadBundleModalBtn from '../UploadBundleModalBtn'
import { ScItemsInRow } from '../../../../shared/components/LayoutComponents'

const TitleBar = ({ products, productsDispatcher }) => {
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)
  const selectedIds = useRecoilValue(selectedBundles)
  const resetSelectedIds = useResetRecoilState(selectedBundles)

  const deleteSelectedBundlesCmd = useDeleteBundleItemsCmd(
    { ids: selectedIds },
    (r) => {
      productsDispatcher({
        type: 'RESET_PRODUCTS',
        products: products.filter(({ id }) => !selectedIds.includes(id))
      })
      resetSelectedIds()
      console.log('deleteSelectedBundles success', products, r)
    },
    (r) => {
      console.log('deleteSelectedBundles fail', r)
    }
  )

  useEffect(() => {
    setHeading('Bundle Products')
  }, [])
  const deleteSelected = () => {
    deleteSelectedBundlesCmd()
  }

  console.log('Selected ids', selectedIds)
  const hasSelected = !!selectedIds.length
  return (
    <ScItemsInRow aria-label="TitleBar" itemWidth={'auto'}>
      {hasSelected && (
        <ScPrimaryBtn onClick={deleteSelected} label={`Delete ${selectedIds.length} selected bundles`} />
      )}
      <UploadBundleModalBtn />
      <CreateBundleModalBtn />
    </ScItemsInRow>
  )
}

export default TitleBar
