import React, { useEffect, useState } from 'react'
import CarriersTableTemplate from '../components/CarriersTableTemplate'
import { createCarrierItem, NEW_CARRIER_ID } from '../data/carriersList'
import { filtrateList } from '../../../utils/filters'
import { useCarriersApi } from '../infrastructure/carriersApi'

const CarriersContent = () => {
  const { carriers, updateCarrier, deleteCarrier, createCarrier } =
    useCarriersApi()

  const [shownList, setShownList] = useState()
  const [filters, setFilters] = useState({})

  const handleAddNew = () => {
    const newItem = createCarrierItem()
    setShownList((list) => [newItem, ...list])
  }

  const handleFilter = ({ code, value }) => {
    setFilters((applied) => ({ ...applied, [code]: value }))
  }

  const handleFilterClear = () => {
    console.log('CarriersContent filter clear click', filters)
    setFilters({})
  }

  const hasNewItem = () => {
    return Boolean(shownList?.find(({ id }) => id === NEW_CARRIER_ID))
  }

  const handleUpdate = (row) => {
    console.log('CarriersContent handle handleUpdate', row)
    updateCarrier({
      ...row
    })
  }

  const handleCreate = (row) => {
    setShownList((list) => [...list.filter(({ id }) => id !== NEW_CARRIER_ID)])

    createCarrier({
      ...row,
      id: carriers[carriers.length - 1]?.id + 1
    })
  }

  const handleRemove = ({ id: removeId }) => {
    deleteCarrier({ id: removeId })
  }

  const handleEdit = ({ id }) => {
    console.log('CarriersRow OnEdit', id)
  }

  const columns = [
    {
      field: 'code',
      headerName: 'SA carrier code',
      headerFieldType: 'combo',
      width: '30%',
      required: true,
      headerOptions: carriers?.map(({ code }) => code),
      onFilter: handleFilter
    },
    {
      field: 'title',
      headerName: 'Title',
      headerFieldType: 'combo',
      width: '30%',
      required: true,
      headerOptions: shownList?.map(({ title }) => title),
      onFilter: handleFilter
    },
    {
      field: 'tracking_url',
      headerName: 'Tracking url',
      headerFieldType: 'combo',
      width: '30%',
      headerOptions: shownList?.map(({ tracking_url }) => {
        if (tracking_url) return tracking_url
      }),
      onFilter: handleFilter
    }
  ]

  useEffect(() => {
    setShownList(filtrateList({ list: carriers, filters }))
  }, [carriers])

  useEffect(() => {
    filters && setShownList(filtrateList({ list: carriers, filters }))
    console.log('CarriersSimpleTable filters change', { shownList, filters })
  }, [filters])

  return (
    <CarriersTableTemplate
      title={'Carriers list'}
      data={shownList}
      columns={columns}
      filters={filters}
      handleFilterClear={handleFilterClear}
      handleAddNew={handleAddNew}
      hasNewItem={hasNewItem()}
      onEdit={handleEdit}
      onCreate={handleCreate}
      onRemove={handleRemove}
      onUpdate={handleUpdate}
    />
  )
}

export default CarriersContent
