import React, { useEffect, useState } from 'react'
import { useFetchBatchDetailsCmd } from '../../infrastructure/bachesApi'
import { prettifyJsonOutput } from '../../../../utils/helpers'
import ScPageContentPaper from '../../../../shared/components/LayoutComponents/ScPageContentPaper'

const batchConditions = (batchItemsScope) => {
  const { filters } = batchItemsScope
  if (filters?.length) return 'Order Items were selected manually'
  return prettifyJsonOutput({ filters })
  // return <pre> {JSON.stringify(filters, null, 2)}</pre>
}
const batchPayload = (payload) => {
  const { fields } = payload
  if (!fields) return null

  return prettifyJsonOutput(fields)
  // return <pre> {JSON.stringify(fields, null, 2)}</pre>
}
export const BatchDetailsView = ({ details }) => {
  const {
    id,
    batch_id,
    status,
    moderation_status,
    entity,
    user,
    filters,
    item_ids,
    fields,
    created_at,
    updated_at
  } = details
  batchConditions({ filters })
  batchPayload({ fields })
  return (
    <>
      <h4 style={{ marginBottom: '1rem' }}>
        {filters && !!Object.keys(filters).length
          ? 'Applied filters'
          : 'Order Items were selected manually'}
      </h4>
      {filters && !!Object.keys(filters).length && (
        <ScPageContentPaper isPaper>
          {batchConditions({ filters })}
        </ScPageContentPaper>
      )}
      <h4 style={{ marginBottom: '1rem' }}>Updated fields:</h4>
      <ScPageContentPaper isPaper>
        {batchPayload({ fields })}
      </ScPageContentPaper>
    </>
  )
}

export const useBatchDetails = ({ batchId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState({})
  const fetchBatchDetailsCmd = useFetchBatchDetailsCmd(
    { batchId },
    ({ data, ...r }) => {
      console.log('fetchBatchDetailsCmd1', data, r, 'aaa')
      data && setDetails(Array.isArray(data) ? data?.[0] : data)
    },
    (r) => console.log('onFail', r)
  )
  useEffect(() => {
    if (!batchId) return
    try {
      setIsLoading(true)
      fetchBatchDetailsCmd().then(() => setIsLoading(false))
    } catch (e) {
      setIsLoading(false)
    }
    // setItems
  }, [batchId])

  const { status, entity, user, filters, fields } = details || {}
  console.log('useBatchDetails', details)
  return {
    isLoading,
    data: details,
    renderer: () => prettifyJsonOutput(details)
  }

  // if (isLoading) return <ScSpinner />
  // if (details) return prettifyJsonOutput(details)
}
