import React, { useEffect, useState } from 'react'
import { ScFabButton } from '../../shared/components/buttons'
import ScCollapsibleCRUDTable from '../../shared/components/tables/ScCollapsibleTable/ScCollapsibleCRUDTable'
import AreaGroupTableRow from './AreaGroupTableRow'
import { useLevels } from './data/areaDictionaries'
import { NEW_GROUP_ID } from './data/areaGroupDetails'

const AreaGroupTable = ({
  handleFilter,
  groups,
  handleUpdate,
  handleRemove,
  handleCreate
}) => {
  const [areaGroupTableState, setAreaGroupTableState] = useState(null)
  const levelsOptions = useLevels()
  const hasNewGroup = () =>
    Boolean(groups.find(({ id }) => id === NEW_GROUP_ID))

  useEffect(() => {
    console.log('AreaGroupTable constructor')
    return () => console.log('AreaGroupTable destructor')
  }, [])

  return (
    <ScCollapsibleCRUDTable
      AddButton={() => !hasNewGroup() && <ScFabButton onClick={handleCreate} />}
      items={groups}
      columns={{
        name: { label: 'Group Name', onFilter: handleFilter, type: 'combo'
      },
        level: {
          label: 'Level',
          type: 'combo',
          options: levelsOptions,
          onFilter: handleFilter
        }
      }}
      onRemove={handleRemove}
      onUpdate={handleUpdate}
      rowComponent={AreaGroupTableRow}
    />
  )
}

export default AreaGroupTable
