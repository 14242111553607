import React, { useEffect, useState } from 'react'
import CarriersTableTemplate from '../components/CarriersTableTemplate'
import {
  createPlatformsMappingItem,
  NEW_PLATFORM_MAPPING_ID
} from '../data/carriersList'
import { filtrateList } from '../../../utils/filters'
import { useCarriersPlatformsMappingApi } from '../infrastructure/carriersPlatformsMappingApi'

const PlatformsMappingContent = ({ carriers, platforms }) => {
  const { mappings, updateMapping, deleteMapping, createMapping } =
    useCarriersPlatformsMappingApi()

  const [shownList, setShownList] = useState([])
  const [filters, setFilters] = useState({})

  const handleAddNew = () => {
    const newItem = createPlatformsMappingItem()
    setShownList((list) => [newItem, ...list])
  }

  const handleFilter = ({ code, value }) => {
    setFilters((applied) => ({ ...applied, [code]: value }))
  }

  const handleFilterClear = () => {
    console.log('PlatformsMappingContent filter clear click', filters)
    setFilters({})
  }

  const hasNewItem = () => {
    return Boolean(shownList?.find(({ id }) => id === NEW_PLATFORM_MAPPING_ID))
  }

  const handleUpdate = (row) => {
    console.log('PlatformsMappingContent handle handleUpdate', row)
    updateMapping({
      ...row
    })
  }

  const handleCreate = (row) => {
    console.log('PlatformsMappingContent handle handleCreate', row)

    setShownList((list) => [
      ...list.filter(({ id }) => id !== NEW_PLATFORM_MAPPING_ID)
    ])
    createMapping({
      ...row,
      id: mappings[mappings.length - 1]?.id + 1
    })
  }

  const handleRemove = ({ id: removeId }) => {
    deleteMapping({ id: removeId })
  }

  const handleEdit = (data, value) => {
    console.log('PlatformsMappingContent handle handleEdit', data, value)
  }

  const columns = [
    {
      headerName: 'SA carrier code',
      field: 'sa_carrier_code',
      headerFieldType: 'combo',
      fieldType: 'combo',
      freeSolo: false,
      width: '22%',
      headerOptions: carriers?.map(({ code }) => code),
      options: carriers?.map(({ code }) => code),
      required: true,
      onFilter: handleFilter
    },
    {
      headerName: 'Platform carrier code',
      field: 'platform_carrier_code',
      headerFieldType: 'combo',
      width: '22%',
      headerOptions: carriers?.map(({ title }) => title),
      required: true,
      onFilter: handleFilter
    },
    {
      headerName: 'Platform',
      field: 'platform',
      headerFieldType: 'combo',
      width: '22%',
      headerOptions: platforms?.map(({ code }) => code),
      required: true,
      onFilter: handleFilter
    },
    {
      headerName: 'Title',
      field: 'title',
      headerFieldType: 'combo',
      width: '22%',
      onFilter: handleFilter
    }
  ]

  useEffect(() => {
    setShownList(filtrateList({list:mappings, filters}))
    console.log('PlatformsMappingContent income data', {
      mappings,
      carriers,
      platforms
    })
  }, [mappings])

  useEffect(() => {
    setShownList(filtrateList({list:mappings, filters}))
    console.log('PlatformsMappingContent filters change', filters)
  }, [filters])

  return (
    <CarriersTableTemplate
      title={'Platform mappings list'}
      data={shownList}
      columns={columns}
      filters={filters}
      handleFilterClear={handleFilterClear}
      handleAddNew={handleAddNew}
      hasNewItem={hasNewItem()}
      onEdit={handleEdit}
      onCreate={handleCreate}
      onRemove={handleRemove}
      onUpdate={handleUpdate}
    />
  )
}

export default PlatformsMappingContent
