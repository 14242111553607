import React, { Fragment } from 'react'
import { AttributeRowV2 } from './AttributeRowV2'

const FieldContent = ({ meta, values, level, linePath = '' }) => {
  const { editable, label, type, code, values: options } = meta

  const fieldLinePath = linePath.replace('%key%', `${code}`)
  return (
    <Fragment>
      <AttributeRowV2
        value={values}
        meta={{ ...meta, options, editType: meta.type }}
        linePath={fieldLinePath}
        fieldProps={{isGroupField: true, inputWidth: 70, labelPlacement: 'start', fontSize:'0.9rem'}}
      />
    </Fragment>
  )
}

export default FieldContent
