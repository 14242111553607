import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { SnowTypography } from '../../../../../shared/components/snowComponents'
import { SnowShippingIcon } from '../../../../../shared/components/icons'
import ShippingMethodsModal from '../../Modals/ShippingMethodsModal'
import ScTitleWithTrigger from '../../../../../shared/components/widgets/ScTitleWithTrigger'
import { revalidateOrder } from '../../../infrastructure/fetches'
import { currentMessageState } from '../../../../../shared/service/Message/data/currentMessageState'

const ShippingMethod = ({ order }) => {
  const [open, setOpen] = useState(false)
  const setMessage = useSetRecoilState(currentMessageState)

  const onEdit = () => {
    setOpen(true)
  }
  const onClose = () => {
    console.log('ShippingMethod Order', order)
    setOpen(false)
    revalidateOrder({ orderID: order.id }).then((data) => {
      if (data.success) {
        setMessage({
          message: `Order has been revalidated. Refresh page to see an actual data.`,
          severity: 'success'
        })
      }
    })
  }
  return (
    <>
      <ShippingMethodsModal
        channel={order.channelId}
        channelName={order.channelName}
        open={open}
        onClose={onClose}
        defaultValue={order.shippingMethod}
      />
      <>
        <ScTitleWithTrigger
          title={'Shipping Method'}
          onTrigger={onEdit}
          TriggerIcon={SnowShippingIcon}
        />
        {(!!order.snowShippingMethod && (
          <SnowTypography display={'inline'} variant={'inherit'}>
            {order.snowShippingMethod}
          </SnowTypography>
        )) ||
          order.shippingMethod}
      </>
    </>
  )
}

export default ShippingMethod
