import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ScMultiComboField, ScTextField } from '../../../shared/components/SnowEditableField/baseFields'
import { ScPrimaryBtn } from '../../../shared/components/buttons'
import { ScFormModal } from '../../../shared/components/modals'
import { useCreateBundleItemCmd } from '../infrastructure/apisV2'
import useChannels from '../../integrations/hooks/useChannels'
import ScMessagesPanel from '../../../shared/components/widgets/ScMessagesPanel'

const CreateBundleModalBtn = () => {
  const navigate = useNavigate()
  const [showCreate, setShowCreate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [messages, setMessages] = useState([])
  const [salesChannelsIds, setSalesChannelsIds] = useState([])
  const [bundleSku, setBundleSku] = useState('')
  const { data: channels } = useChannels()

  const createBundleCmd = useCreateBundleItemCmd(
    {
      sku: bundleSku,
      salesChannelsIds
    },
    (r) => {
      console.log('r', r)
      const { data, messages, success } = r
      setLoading(false)
      data?.id
        ? navigate(`/home/bundle_detail?id=${data?.id}`)
        : setMessages(messages)
    },
    (r) => {
      setError(r.status)
      setMessages(r.messages)
    }
  )

  const inputChangeHandle = () => {
    messages.length && setMessages(false)
  }
  const updateBundleSkuState = ({ value }) => {
    inputChangeHandle()
    setBundleSku(value)
    console.log('inputChangeHandle', value)
  }
  const updateSalesChannelsState = ({ value }) => {
    inputChangeHandle()
    setSalesChannelsIds(value)
    console.log('updateSalesChannelsState', value)
  }
  const submitCreateBundle = (evt) => {
    evt.preventDefault()
    createBundleCmd().then((r) => console.log('createBundleCmd then', r))

    setLoading(true)
    setError(false)
    return false
  }
  const toggleCreate = () => {
    setShowCreate(!showCreate)
    if (!showCreate) {
      setError(false)
    }
  }

  useEffect(() => {
    console.log('CreateBundleModalBtn', { channels })
  }, [channels])

  return (
    <>
      <ScPrimaryBtn onClick={toggleCreate} label={'Create Bundle'} />
      <ScFormModal
        open={showCreate}
        onClose={toggleCreate}
        onSubmit={submitCreateBundle}
        loading={loading}
        title={'Create new bundle'}
        content={<>
          <ScTextField
            value={bundleSku}
            onUpdate={updateBundleSkuState}
            name={"sku"}
            label={'Bundle SKU'}
            required={true}
            mb={16}
            placeholder={"Bundle SKU"}
          />
          <ScMultiComboField
            label={'Enumerate Sale Channels Ids'}
            value={salesChannelsIds}
            meta={{
              options: channels.map(({ marketplace_name, marketplace_id }) => ({ id: marketplace_id, name: marketplace_name })),
              optionsDef: { value: 'id', label: 'name', tag: 'name' }
            }}
            onUpdate={updateSalesChannelsState}
            required={true}
            placeholder={"Sale Channels Ids"}
            name="marketplace_ids"
            mb={16}
          />
        </>}
        message={<ScMessagesPanel messages={messages} />}
        labelToSubmitBtn={'Create'}
      />
    </>
  )
}

export default CreateBundleModalBtn
