import React from 'react'
import { SnowCheckbox } from '../../snowComponents'
import {
  SnowCheckBoxOutlinedIcon,
  SnowIndeterminateCheckBoxOutlinedIcon,
  SnowCheckBoxOutlineBlankOutlinedIcon
} from '../../icons'

const getOutlinedMultiCheckIcon = (status) => {
  switch (status) {
    case 0:
      return <SnowCheckBoxOutlineBlankOutlinedIcon />
    case 1:
      return <SnowIndeterminateCheckBoxOutlinedIcon />
    case 2:
      return <SnowCheckBoxOutlinedIcon />
    default:
      return <SnowCheckBoxOutlineBlankOutlinedIcon />
  }
}

const ScMultiCheckButton = ({ selectionState, onChange }) => (
  <SnowCheckbox
    indeterminate={selectionState === 1}
    checked={selectionState === 2}
    icon={getOutlinedMultiCheckIcon(selectionState)}
    onChange={onChange}
  />
)

export default ScMultiCheckButton
