export const resolveId = (item, resolvingId, resolvingDict) => {
  const { [resolvingId]: idToResolve, ...withoutId } = item
  if (!resolvingDict || (resolvingDict && !resolvingDict.length)) return item
  const substitutedId = resolvingDict
    ? resolvingDict.find(
        ({ id }) => JSON.stringify(id) === JSON.stringify(idToResolve)
      ).name
    : idToResolve
  return {
    ...withoutId,
    [resolvingId]: substitutedId
  }
}
export const BULK_SELECTION_STATE = { None: 0, Partial: 1, All: 2 }

export const getBulkSelectionStatus = (items, selectedItems) => {
  const itemsCount = items.length
  const selectedCount = selectedItems.length
  return selectedCount === 0
    ? BULK_SELECTION_STATE.None
    : itemsCount - selectedCount
    ? BULK_SELECTION_STATE.Partial
    : BULK_SELECTION_STATE.All
}
