import React, { useEffect, useState, useContext } from 'react'
import { useSetRecoilState } from 'recoil'
import { SnowTypography } from '../../../shared/components/snowComponents'
import ScTable from '../../../shared/components/tables/sc-table'
import { currentMessageState } from '../../../shared/service/Message/data/currentMessageState'
import { ThemeContext } from 'styled-components'

const fieldsDataToRowData = (fields, modelData) => {
  console.log('fieldsDataToRowData', fields, modelData)
  return {
    data: fields.reduce(
      (acc, field) => ({ ...acc, [field.code]: modelData[field.code] }),
      {}
    )
  }
}

const Mapper = ({
  aggregateId = {}, // channelId or vendorId - entity to which sub-entitiesHooks the mapping is applied
  aggregateName,
  aggregateTypeName,
  fieldsProvider, //fields of map (rule params)
  mappingsProvider, // returns already existing mappings list
  newMappingGenerator, //returns fields list for new mapping
  createCommandProvider,
  removeCommandProvider,
  updateCommandProvider,
  addBtn: ScAddButton, // trigger btn to add new mapping
  onInit,
  incomeValueFieldName,
  defaultValue
}) => {
  const theme = useContext(ThemeContext)
  const setMessage = useSetRecoilState(currentMessageState)
  const fields = fieldsProvider(aggregateId)
  const { data: mappings } = mappingsProvider(aggregateId)
  const [rowToCreate, setRowToCreate] = useState(null)
  const [rowToUpdate, setRowToUpdate] = useState(null)
  const [rowToRemove, setRowToRemove] = useState(null)
  console.log('mappings', { aggregateId, rowToUpdate, fields, mappings })

  const [rowsData, setRowsData] = useState([])
  const createCmd = createCommandProvider({
    ...(rowToCreate ? rowToCreate : {}),
    ...aggregateId
  })

  const updateCmd = updateCommandProvider({
    ...(rowToUpdate ? rowToUpdate : {}),
    ...aggregateId
  })

  const removeCmd = removeCommandProvider({
    ...(rowToRemove ? rowToRemove : {})
  })

  const newRow =
    rowsData &&
    rowsData.find(({ data }) => {
      return data.id === 0
    })

  console.log('rowTo: Create, Update, Remove', {
    rowToCreate,
    rowToUpdate,
    rowToRemove
  })

  const generateNewRow = (incomeValue = '') => {
    const stub = newMappingGenerator(aggregateId, fields)
    console.log('generateNewRow', incomeValue, stub)
    const newMethodData = {
      ...stub,
      [incomeValueFieldName]: incomeValue
    }
    const newRowData = fieldsDataToRowData(fields, newMethodData)
    return newRowData
  }

  const doMappingAdd = () => {
    const newRowData = generateNewRow()
    console.log('newRowData', newRowData)
    setRowsData([{ ...newRowData, isEdit: true }, ...rowsData])
    console.log('ShippingMethods Mapper onShippingMethodAdd')
  }

  const onRowRemoved = (removedId, resp) => {
    console.log('onRowRemoved ', resp)
    if (resp.data && resp.data.success) {
      setRowsData((rows) => rows.filter(({ data }) => data.id !== removedId))
      setMessage({
        message: `Shipping Method Mapping #${removedId} has been removed`,
        severity: 'success'
      })
    }
  }

  const onRowUpdated = (resp) => {
    console.log('onRowUpdated! ', resp, data, success, message)
    const { data, success, message } = resp
    if (success) {
      setMessage({
        message: `Shipping Method Mapping has been updated`,
        severity: 'success'
      })
    } else {
      message &&
        setMessage({
          message: `Something went wrong. ${message && message.text}`,
          severity: 'error'
        })
    }
  }

  const onRowCreated = (resp) => {
    console.log('onRowCreated ', resp)

    if (resp.data && (resp.data.length || resp.data.id)) {
      const createdItems = Array.of(resp.data)
        .flat()
        .map((item) => {
          return fieldsDataToRowData(fields, item)
        })
      const createdIds = createdItems.map(({ data }) => data.id).join(', #')
      console.log('createdItems', createdItems)
      setRowsData([...createdItems, ...rowsData.filter(({ data }) => data.id)])
      setMessage({
        message: `Shipping Method Mapping #${createdIds} has been created`,
        severity: 'success'
      })
    }
  }

  useEffect(() => {
    if (!mappings) return

    const defaultEditRow =
      mappings
        .filter(
          (mapping) =>
            String(mapping[incomeValueFieldName]).toLowerCase() ===
            String(defaultValue).toLowerCase()
        )
        .map((methodData) => ({
          ...fieldsDataToRowData(fields, methodData),
          isEdit: true
        })) || []

    const rows =
      mappings
        .filter((mapping) => mapping[incomeValueFieldName] !== defaultValue)
        .map((methodData) => {
          return fieldsDataToRowData(fields, methodData)
        }) || []

    const rowToCreate =
      defaultValue && !defaultEditRow.length && generateNewRow(defaultValue)

    const rowsToSet = [
      ...(rowToCreate ? [{ ...rowToCreate, isEdit: true }] : []),
      ...defaultEditRow,
      ...rows
    ]

    setRowsData(rowsToSet)
  }, [mappings])

  useEffect(() => {
    //hook handles new row saving
    rowToCreate && createCmd && createCmd((resp) => onRowCreated(resp))
    rowToCreate && setRowToCreate(null)
  }, [rowToCreate])

  useEffect(() => {
    //hook handles new row saving
    console.log('rowToRemove', rowToRemove)
    if (!rowToRemove) return

    const idToRemove = rowToRemove.id
    if (idToRemove === 0)
      setRowsData((rows) => rows.filter(({ data }) => data.id !== 0))
    removeCmd && removeCmd((resp) => onRowRemoved(idToRemove, resp))
    setRowToRemove(null)
  }, [rowToRemove])

  useEffect(() => {
    //hook handles new row saving
    console.log('rowToUpdate', rowToUpdate)
    rowToUpdate && updateCmd((resp) => onRowUpdated(resp))
    rowToUpdate && setRowToUpdate(null)
  }, [rowToUpdate])

  useEffect(() => {
    //hook handles new row saving
    console.log('rowsData', rowsData)
    onInit({
      contentTitle: (
        <>
          <SnowTypography component={'span'} variant={'h6'} fontWeight={500} style={{ marginRight: '8px' }}>
            {`Shipping methods mapping for `}<span style={{ color: theme.mainPalette.color.secondary }}>{aggregateName}</span>{` ${aggregateTypeName}`}
          </SnowTypography>
          {!newRow && <ScAddButton onAdd={doMappingAdd} color={theme.mainPalette.grey.main} />}
        </>
      )
    })
  }, [rowsData])

  const onShippingMethodSelect = (e) => {
    console.log('ShippingMethods Mapper onShippingMethodSelect', e)
  }

  const onShippingMethodDelete = (e) => {
    console.log('ShippingMethods Mapper onShippingMethodDelete', e)
    const { data } = e
    data && setRowToRemove(data)
  }

  const onShippingMethodSave = (e) => {
    const { data } = e
    console.log('ShippingMethods Mapper onShippingMethodSave', data)
    data.id ? setRowToUpdate(data) : setRowToCreate(data)
  }

  console.log('ShippingMethods Mapper fields and rowsData', {
    fields,
    rowsData
  })
  const adoptTableItem = (rawData) => rawData
  const getItemData = (item) => item?.data
  return (
    <div>
      {!!rowsData.length && (
        <ScTable
          editable={true}
          onItemClick={onShippingMethodSelect}
          items={rowsData}
          dataKeyField={'id'}
          onItemDelete={onShippingMethodDelete}
          onItemSave={onShippingMethodSave}
          fieldsToColumnsMap={fields}
          adoptTableItem={adoptTableItem}
          getItemData={getItemData}
          padding={'small'}
        />
      )}
    </div>
  )
}

export default Mapper
