import React, { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { SnowImageSearchIcon } from '../../../../../../shared/components/icons'
import {
  currentOrderState,
  useCurrentOrder
} from '../../../data/currentOrderState'
import { useApplyOrderPersonalizationCmd } from '../../../infrastructure/orderApiService'
import { currentMessageState } from '../../../../../../shared/service/Message/data/currentMessageState'
import ModerationCmdBtn from '../../../DetailBody/OrderItems/OrderLineItem/components/personalizationInfo/components/ModerationCmdsBtn'

export const ModeratePersonalizationButton = ({ isApproved = false }) => {
  const currentOrder = useCurrentOrder()
  const setMessage = useSetRecoilState(currentMessageState)
  const [orderState, setOrderState] = useRecoilState(currentOrderState) //todo: needs to replace rawState mgmt to modelState mgmt
  const [selectedAction, setSelectedAction] = useState('')
  // const updatePersonalisationApprovement = (state) => {
  //   //todo: needs to validate type
  //   // window.location.reload()
  //   console.log(
  //     'updatePersonalisationApprovement selectedAction',
  //     selectedAction
  //   )
  //   setOrderState((oldRawData) => ({
  //     ...oldRawData,
  //     personalization_approved: state
  //   }))
  // }
  const resetBtn = () => setSelectedAction('')
  const applyModerationCmd = useApplyOrderPersonalizationCmd(
    {
      id: currentOrder.id
    },
    { action: selectedAction },
    (r) => {
      console.log('onSuccess Handler', r)
      const updatedOrder = r.order
      updatedOrder && setOrderState(updatedOrder)
      resetBtn()
      setMessage({
        message: `Order personalizations has been moderated into ${selectedAction}`,
        severity: 'success'
      })
    },
    (r) =>
      setMessage({
        message: `Moderation failed`,
        severity: 'error'
      })
  )

  const handleModerationSelected = (action) => {
    console.log('Selected personalizationInfo moderation', action)
    setSelectedAction(action)
  }
  const handleApply = () => {
    applyModerationCmd()
  }
  return (
    <ModerationCmdBtn
      icon={<SnowImageSearchIcon />}
      status={'Moderate personalizationInfo'}
      selected={selectedAction}
      handleModerationSelected={handleModerationSelected}
      handleApply={handleApply}
      cmdStyle={'outlined'}
      applyStyle={'outlined'}
    />
  )
}

export default ModeratePersonalizationButton
