import styled, { css } from 'styled-components';
import { SnowListItem } from '../../../../../../shared/components/snowComponents';

const OrderErrorsDetailItemView = styled(SnowListItem).attrs({
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.secondary};
    box-shadow: ${theme.shadow.main};
    :last-child{
      margin-bottom: 0;
    }
  `}
`

export default OrderErrorsDetailItemView