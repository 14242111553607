import React, { useState, Fragment } from 'react'
import { useRecoilValue } from 'recoil'
import {
  SnowBox
} from '../../../../../shared/components/snowComponents'
import { SnowShippingIcon } from '../../../../../shared/components/icons'
import { ScPageContentWithHeader } from '../../../../../shared/components/LayoutComponents'
import { ScIconButton } from '../../../../../shared/components/buttons'
import ScSpinner from '../../../../../shared/components/ScSpinner/ScSpinner'
import Error from '../../../../app/Error'
import { integrationCqrsRequestModel } from '../../../data/integrationDeterminant'
import useVendors from '../../../hooks/useVendors'
import VendorsSearcher from '../VendorsSearcher'
import { searcherState } from '../../../../../shared/components/searcher/data/searcherState'
import ShippingMethodsModal from '../modals/ShippingMethodsModal'
import ScTable from '../../../../../shared/components/tables/sc-table'
import PropTypes from 'prop-types'
import { toggleElementInCollection } from '../../../../../utils/helpers'

const fields = [
  {
    code: 'id',
    visible: false,
    title: 'Id',
    editType: 'text',
    editable: false,
    tooltip: 'Vendor Id'
  },
  {
    code: 'code',
    visible: true,
    title: 'Code',
    editType: 'text',
    tooltip: 'Internal Vendor Code',
    editable: false
    // fullWidth: true
  },
  {
    code: 'name',
    visible: true,
    title: 'Name',
    editType: 'text',
    tooltip: 'Vendor Name',
    editable: false
    // fullWidth: true
  },
  {
    code: 'reduced_script_status',
    visible: true,
    title: 'Scripts Disabled',
    editType: 'boolean',
    tooltip: 'Show if scripts are disabled',
    editable: false
  },
  {
    code: 'data_field_scheme',
    visible: false,
    title: 'Scripts Disabled',
    editType: 'boolean',
    tooltip: 'Show if scripts are disabled',
    editable: false
  }
]

/**
 * VendorChooser component
 *
 * @param projectId - project to load vendors for
 * @param dtoFields  -fields of selected vendor to be returned in onConfirm
 *   callback
 * @param onConfirm - callback on successful vendor selected
 * @returns {JSX.Element} - the visual list component of vendor tiles to select
 *   from
 * @constructor
 */
const VendorChooser = ({
  projectId = 0,
  dtoFields = ['id'],
  onConfirm = (dto) => {
    console.log(dto)
  },
  onItemSelect = null
}) => {
  const integrationParams = useRecoilValue(integrationCqrsRequestModel)
  const searchStr = useRecoilValue(searcherState)
  const [openShippingModalFor, setOpenShippingModalFor] = useState(null)
  const [selectedIds, setSelectedIds] = useState([])
  const project =
    typeof projectId === 'number' ? projectId : integrationParams.project
  const actionsComponent = ({ id, name, data_field_scheme }) => {
    return data_field_scheme ? (
      <ScIconButton
        onClick={() => setOpenShippingModalFor({ id, name })}
        tooltip={`Map Shipping Methods for vendor ${name}`}
      >
        <SnowShippingIcon />
      </ScIconButton>
    ) : null
  }
  const {
    data: vendors,
    isLoading,
    loadingError
  } = useVendors(project, searchStr)

  const onShippingModalClose = () => {
    setOpenShippingModalFor(null)
  }
  const handleVendorSelect = (r) => {
    if (!onItemSelect) return
    console.log('VendorChooser handleItemSelect', selectedIds, r.id)
    setSelectedIds(toggleElementInCollection(selectedIds, r.id))
    onItemSelect(r)
  }

  const handleVendorConfirmation = (item) => {
    const dtoObj = dtoFields.reduce(
      (dto, fieldName) => ({ ...dto, [fieldName]: item[fieldName] }),
      {}
    )
    console.log('VendorChooser onVendorConfirmed dto', item, dtoFields, dtoObj)

    onConfirm && onConfirm(dtoObj)
  }

  console.log('Vendor Chooser integrationParams', vendors, integrationParams)
  return (
    <>
      {!!openShippingModalFor && (
        <ShippingMethodsModal
          defaultValue={''}
          entityId={openShippingModalFor.id}
          entityName={openShippingModalFor.name}
          onClose={onShippingModalClose}
          open={!!openShippingModalFor}
        />
      )}
      <ScPageContentWithHeader
        formFields={[
          <Fragment key={'vendor-filter'}>
            <VendorsSearcher
              label={'Search by vendor'}
              placeholder={'type to search'}
            />
          </Fragment>
        ]}
        pageContent={
          <SnowBox>
            {loadingError && <Error error={loadingError} />}
            {isLoading && !vendors.length ? (
              <ScSpinner />
            ) : (
              <>
                <ScTable
                  dataKeyField={'id'}
                  items={vendors}
                  fieldsToColumnsMap={fields}
                  onItemClick={handleVendorSelect}
                  selectedItem={integrationParams.vendor}
                  onItemDblClick={handleVendorConfirmation}
                  editable={false}
                  actionsComponent={actionsComponent}
                  pad={'8px'}
                />
              </>
            )}
          </SnowBox>
        }
      />
    </>
  )
}

VendorChooser.propTypes = {
  projectId: PropTypes.string,
  dtoFields: PropTypes.array,
  onConfirm: PropTypes.func
}

export default VendorChooser
