import React from 'react'
import { SnowBox } from '../../../snowComponents'
import { ScCrudButton, ScCheckButton, ScSaveButton } from '../../../buttons'
import ScBulkCheckbox from '../../../BulkComponents/ScBulkCheckbox'
import { removeElementInCollection } from '../../../../../utils/helpers'

const ICON_WIDTH = 25 + 48
const PADDING = 24

export const getActionsColumnProps = ({
  keyField,
  rows,
  editedRows,
  customRowActions,
  selectedRows,
  onBulkSelect,
  setEditedRows,
  onBulkCrudEvent,
  onCrudTriggered,
  editable = false,
  selectable = true
}) => ({
  label: 'actions',
  dataKey: '',
  width:
    2 * PADDING +
    ICON_WIDTH * !!customRowActions +
    ICON_WIDTH * (!!onBulkSelect + 4 * !!onCrudTriggered * editable),
  minWidth: ICON_WIDTH,
  headerRenderer: () => {
    // console.log('headerRenderer rows', rows, selectedRows)
    return (
      <SnowBox aria-label="bulk-checkbox-wrapper">
        {editable && typeof onBulkCrudEvent === 'function' && (
          <ScSaveButton onSave={() => onBulkCrudEvent('save', rows)} />
        )}
        {selectable && (
          <ScBulkCheckbox
            rows={rows}
            selectedRows={selectedRows}
            onBulkSelect={onBulkSelect}
          />
        )}
      </SnowBox>
    )
  },
  cellRenderer: function cellRenderer({ rowData, ...rest }) {
    // console.log('cellRenderer CRUD', rowData, rest)
    const id = rowData[keyField]
    if (!id) return <></>
    return (
      <div aria-label='actions-column' style={{ display: 'flex', alignItems: 'center' }}>
        {/*<IconButton aria-label="selection">*/}
        {selectable && (
          <ScCheckButton
            checked={selectedRows?.includes(id)}
            fontSize={'medium'}
          />
        )}
        {/*<Checkbox checked={selectedRows?.includes(id)} />*/}
        {/*</IconButton>*/}
        {editable && onCrudTriggered && (
          <ScCrudButton
            editMode={editedRows.includes(id)}
            onEdit={() => {
              setEditedRows((prev) => [...prev, id])
              onCrudTriggered('edit', rowData)
            }}
            onSave={(props) => {
              console.log('OnRowSave', rowData, editedRows, id, props)
              setEditedRows((prev) => {
                return removeElementInCollection(prev, id)
              })
              onCrudTriggered('save', rowData)
            }}
            onRemove={(props) => {
              console.log('OnRowDelete', id)
              setEditedRows((prev) => {
                return removeElementInCollection(prev, id)
              })
              onCrudTriggered('delete', rowData)
            }}
          />
        )}
        {customRowActions && customRowActions(rowData)}
      </div>
    )
  }
})
export default getActionsColumnProps
