import { useCallback, useRef } from 'react'

const moment = require('moment')

export const getFilterConfigs = (filters, sortOrder) => {
  return {
    orderNum: {
      get: () => filters.orderNum,
      param: 'orderNum',
      castParam: (value) => value
    },
    customerName: {
      get: () => filters.customerName,
      param: 'customer',
      castParam: (value) => value
    },
    purchasedFrom: {
      get: () => filters.purchasedFrom,
      param: 'purchasedFrom',
      castParam: (value) => value
    },
    startDate: {
      get: () => {
        console.log(
          'filters.endDate',
          filters.endDate,
          moment(filters.startDate).toISOString()
        )
        return moment(filters.startDate).toISOString() || ''
      },
      param: 'startDate',
      castParam: (value) =>
        value ? moment(value).toDate().toISOString() : null
    },
    endDate: {
      get: () => moment(filters.endDate).toISOString() || '',
      param: 'endDate',
      castParam: (value) =>
        value ? moment(value).toDate().toISOString() : null
    },
    product: {
      get: () => filters.product,
      param: 'product',
      castParam: (value) => value
    },
    paymentMethod: {
      get: () => filters.paymentMethod,
      param: 'paymentMethod',
      castParam: (value) => value
    },
    grandTotal: {
      get: () => filters.grandTotal,
      param: 'grandTotal',
      castParam: (value) => value
    },
    orderItemStatus: {
      get: () => filters.orderItemStatus,
      param: 'orderItemStatus',
      castParam: (value) => value
    },
    orderStatus: {
      get: () => filters.orderStatus,
      param: 'orderStatus',
      castParam: (value) => value
    },
    orderPortalStatus: {
      get: () => filters.orderPortalStatus,
      param: 'orderPortalStatus',
      castParam: (value) => value
    },
    vendor: {
      get: () => filters.vendor,
      param: 'vendor',
      castParam: (value) => value
    },
    vendorStatus: {
      get: () => filters.vendorStatus,
      param: 'vendorStatus',
      castParam: (value) => value
    },
    sortOrder: {
      get: () => sortOrder,
      param: 'sortOrder',
      castParam: (value) => (!value ? 'desc' : 'asc')
    },
    personalized: {
      get: () => filters.personalized,
      param: 'personalized',
      castParam: (value) => !!value
    },
    shipping_import_error: {
      get: () => filters.shipping_import_error,
      param: 'shipping_import_error',
      castParam: (value) => value
    },
    shipping_export_error: {
      get: () => filters.shipping_import_error,
      param: 'shipping_export_error',
      castParam: (value) => value
    }
  }
}

// function to detect when user has scrolled to bottom of page
export const handleScroll = ({
  isLoading,
  noMorePages,
  loadNextPageOfOrders
}) => {
  console.log('isLoading', isLoading)
  // if the last fetch is still loading or if there are no more pages to load, return
  if (isLoading || noMorePages) {
    if (isLoading) {
      console.log('handle scroll loading')
    }
    if (noMorePages) {
      console.log('handle scroll no more pages')
    }
    return
  }
  const windowHeight =
    'innerHeight' in window
      ? window.innerHeight
      : document.documentElement.offsetHeight
  const { body } = document
  const html = document.documentElement
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  )
  const windowBottom = Math.round(windowHeight + window.pageYOffset)
  // calculate to 80% to bottom of Doc
  const scrollThreshold = docHeight * 0.6
  // if user has scrolled 80% down, fetch the next pagination of orders
  if (windowBottom >= scrollThreshold) {
    loadNextPageOfOrders()
  }
}

export const useInfiniteScroll = (callback, isFetching) => {
  //here we use useRef to store a DOM node and the returned object will persist regardless of re-renders
  const observer = useRef()

  //useCallback takes a callback argument and an array dependency list and returns a memoized callback
  //which is guaranteed to have the same reference
  const lastElementRef = useCallback(
    (node) => {
      // console.log('scroll is Fetching')
      if (isFetching) return

      //stop watching targets, you can think of it as a reset
      if (observer.current) observer.current.disconnect()

      //create a new intersection observer and execute the callback incase of an intersecting event
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          callback()
        }
      })

      //if there is a node, let the intersection observer watch that node
      if (node) observer.current.observe(node)
    },
    [callback, isFetching]
  )

  //return reference to the last element
  return [lastElementRef]
}
