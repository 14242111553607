import { SnowPaper } from '../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const OrderItemModalView = styled(SnowPaper)`
  padding: ${({ theme }) => theme.spacing.main};
  margin-bottom: ${({ theme }) => theme.spacing.secondary};
  :last-child{
    margin-bottom: 0;
  }
  &.MuiPaper-root{
    background: ${({ theme }) => theme.mainPalette.grey[50]};
    transition: background 0.3s ease-in;
    :hover {
      background: ${({ theme }) => theme.mainPalette.grey.light};
    }
  }
`

export default OrderItemModalView