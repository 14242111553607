import { SnowArrowDropDownIcon } from '../../../../../shared/components/icons';
import styled from 'styled-components'

const DropdownButton = styled(SnowArrowDropDownIcon).attrs({
  focusable: false
})`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
`

export default DropdownButton