// function to GET an all products infoghjc
import { portalRequestor } from '../../../infrastructure/api/v1/requestors'
import { removeEmptyProps } from '../../../utils/helpers'

export const fetchFields = () => {
  const fullURL = `product/grid`
  console.log('fetchFieldsDescription', fullURL)
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'fetchFields'
  })
}

// function to GET an all products info
export const fetchProducts = async ({ page = 1, search: searchObj }) => {
  const { sku, channel } = searchObj
  const params = {
    fields: [
      'id',
      'marketplace_id',
      'market_product_id',
      'market_sku',
      'name',
      'market_vendor',
      'vendor_sku',
      'preorder'
    ],
    filters: removeEmptyProps({
      market_sku: sku && `%${sku}%`,
      marketplace_id: channel && `${channel}`
    }),
    sortorder: { id: 'asc' },
    meta: { per_page: 20, page: page }
  }
  const url = `products`
  return await portalRequestor({
    type: 'post',
    fetchParams: params,
    url,
    caller: 'fetchProducts'
  })
}

export const fetchProductBySaId = ({ saProductId, version = 1 }) => {
  const fullURL = `product/${saProductId}`
  // if(version==2){
  //   return fakeFetch({data:fakeProductV2, timeout:2000})
  // }
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'fetchProductBySaId',
    version: 'v2'
  })
}
//id - Snow Axis Product ID
export const updateProductDetails = async ({ id, data }) => {
  const url = `product/update/details/${id}`
  return await portalRequestor({
    type: 'post',
    url,
    fetchParams: data,
    caller: 'updateProductDetails'
  })
}
export const fetchProductSearchRemoved = ({
  marketplaceId,
  marketProductId
}) => {
  const fullURL = `product/searchRemoved/${marketplaceId}/${marketProductId}`
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'fetchProductSearchRemoved'
  })
}

// function to resync a single product's info from external sources
export const resyncProduct = ({ marketPlaceID, productID, productSaID }) => {
  const fullURL = `product/update?marketplace_id=${marketPlaceID}&platform_product_id=${productID}&snowaxis_product_id=${productSaID}`
  console.log(fullURL)
  return portalRequestor({
    url: fullURL,
    type: 'get',
    caller: 'resyncProduct'
  })
}
