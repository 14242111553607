import React from 'react'
import {
  SnowCard,
  SnowAvatar,
  SnowCardHeader,
  SnowCardActionArea
} from '../../../snowComponents'
import { useLogos } from '../../../../../infrastructure/service/brand-logos'
import styled from 'styled-components'

const CardView = styled(SnowCard)`
  &.MuiCard-root {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  ${({ ischecked, theme }) =>
    ischecked
      ? `
  &.MuiCard-root{
    background: ${theme.mainPalette.color.main};
  }
  * {
      color: white!important;
    }
  `
      : ''}
`

const ScCard = ({
  data: { id, code, name = 'Card Name', description = '', ...rest },
  isSelected: checked,
  onClick = () => {
    console.log('onItemSelected is not defined')
  },
  onDoubleClick = () => {
    console.log('onItemConfirmed is not defined')
  }
}) => {
  const logosArray = useLogos()

  const handleChange = () => {
    console.log('handleChange', id, code)
    onClick && onClick()
  }
  const handleConfirmation = () => {
    console.log('handleConfirmated', id, code)
    onDoubleClick && onDoubleClick({ id, code })
  }

  const getLogo = (code) => code && logosArray[code]

  return (
    <CardView aria-label={'sc-card'} ischecked={checked ? 1 : 0}>
      <SnowCardActionArea>
        <SnowCardHeader
          onDoubleClick={handleConfirmation}
          onClick={handleChange}
          avatar={<SnowAvatar alt={name} src={getLogo(code)} />}
          title={name}
          subheader={description}
        />
      </SnowCardActionArea>
    </CardView>
  )
}

export default ScCard
