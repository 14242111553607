import React, { useEffect, useState } from 'react'
import { SnowGrid } from '../../../../shared/components/snowComponents'
import { ScDropdownField } from '../../../../shared/components/SnowEditableField/baseFields'

const moment = require('moment')

export const getNamedRangeDates = (rangeName) => {
  switch (rangeName) {
    case '2weeks':
      return [moment().subtract(14, 'days'), moment()]
    case '1month':
      return [moment().subtract(1, 'months'), moment()]
    case '1year':
      return [moment().subtract(1, 'years'), moment()]
    case 'all':
      return ['', '']
    case 'arbitrary':
      return []
  }
  return []
}
export const DateRangeScoper = ({
  defaultRange = '2weeks',
  onUpdate = (startDate, endDate, type) => {
    console.log('DateRangeScoper selected range', startDate, endDate, type)
  }
}) => {
  const [selectedRange, setRange] = useState(defaultRange)

  const handleChange = (ev) => {
    console.log('DateRangeScoper handleChange', ev)
    setRange(ev.target.value)
  }
  const handleUpdate = ({ value }) => setRange(value)
  useEffect(() => {
    console.log('DateRangeScoper selectedRange', selectedRange)
    onUpdate(getNamedRangeDates(selectedRange), selectedRange)
  }, [selectedRange])

  useEffect(() => {
    console.log('DateRangeScoper defaultRange ', defaultRange)
    setRange(defaultRange)
  }, [defaultRange])
  return (
    <SnowGrid item style={{ minWidth: '270px' }}>
      <ScDropdownField
        value={selectedRange}
        label={'Date Range'}
        labelPlacement={'start'}
        inputWidth={70}
        fontSize={'14px'}
        code={'dateRange'}
        meta={{
          options: {
            '2weeks': '2 weeks',
            '1month': '1 month',
            '1year': '1 year',
            arbitrary: 'custom range',
            all: 'All'
          }
        }}
        onUpdate={handleUpdate}
      />

      {/*<FormHelperText>Select time range to refine orders</FormHelperText>*/}
      {/*</div>*/}
    </SnowGrid>
  )
}
