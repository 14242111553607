import React, { useEffect, useState } from 'react'
import {
  SnowList,
  SnowListItem,
  SnowTypography
} from '../../../../../shared/components/snowComponents'
import { fetchOrderTimeline } from '../../../infrastructure/fetches'
import moment from 'moment-timezone'
import {
  TimelineHeaderView,
  TimelineItemView,
  TimelineDescriptionView,
  TimelineTimeView
} from './_styled'

const OrderTimeLine = ({ orderTimeline }) => {
  return (
    <SnowList>
      {orderTimeline?.length ? (
        Object.keys(orderTimeline)
          .map((index) => (
            <TimelineItemView key={index}>
              <TimelineDescriptionView>
                {(() => {
                  const { action_name, message, user } = orderTimeline[index]
                  switch (action_name) {
                    case 'order_revalidate':
                      return `${
                        message ? message : 'Order revalidation triggered by '
                      } by ${user}`
                    case 'order_cancel':
                      return `${
                        message ? message : 'Order cancelled by '
                      } by ${user}`
                    case 'order_cancel_item':
                      return `${
                        message ? message : 'Order item cancelled by '
                      } by ${user}`
                    case 'order_import':
                      return `${
                        message ? message : 'Order imported'
                      } by Snow Axis (${user}).`
                    case 'order_hold':
                    case 'order_unhold':
                    default:
                      return `${message} by ${user}`
                  }
                })()}
              </TimelineDescriptionView>
              <TimelineTimeView>
                {moment(orderTimeline[index].datetime)
                  .tz('America/New_York')
                  .format('MM/DD/YYYY, h:mm:ss a')}
              </TimelineTimeView>
            </TimelineItemView>
          ))
          .sort()
      ) : (
        <SnowListItem>0 actions are avaible for viewing</SnowListItem>
      )}
    </SnowList>
  )
}

const Timeline = ({ order: orderModel }) => {
  const [orderTimeline, setOrderTimeline] = useState(null)

  const getTimeline = (orderID) => {
    fetchOrderTimeline({ orderID }).then((data) => {
      console.log('TIMELINE data', data)
      setOrderTimeline(data)
    })
  }
  useEffect(() => {
    console.log('orderModel.id', orderModel.id)
    getTimeline(orderModel.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <TimelineHeaderView>
        <SnowTypography component={'h3'}>
          <SnowTypography
            component={'span'}
            variant="h6"
            style={{ marginRight: '16px' }}
          >
            Timeline
          </SnowTypography>
          <SnowTypography component={'span'} variant="caption" fontWeight={600}>
            (most recent first)
          </SnowTypography>
        </SnowTypography>
      </TimelineHeaderView>
      <SnowList>
        {/* TODO: to be used later to make pretty lines and bubbles next to action messages */}
        <OrderTimeLine orderTimeline={orderTimeline} />
      </SnowList>
    </>
  )
}

export default Timeline
