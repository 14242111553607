import React from 'react'
import { SnowBox } from '../../../snowComponents'
import SnowEditableField from '../../../SnowEditableField'
import { v4 } from 'uuid'

export const ScEditableCellRenderer = ({
  value,
  fieldDescription,
  edit,
  fontSize,
  onUpdate,
  ...props
}) => {
  const cellUid = v4()
  fieldDescription.code === 'currency' && console.log('ScEditableCellRenderer value', edit, value, fieldDescription)
  return (
    <SnowBox
      aria-label='editable-cell-render'
      sx={{ overflowY: 'visible' }}
      onClick={(evt) => {
        edit && evt.stopPropagation()
      }}
    >
      <SnowEditableField
        fontSize={fontSize}
        meta={fieldDescription}
        updateDelay={500}
        edit={typeof fieldDescription.editable !== 'undefined' ? fieldDescription.editable && edit : edit}
        // variant={'outlined'}
        // label={fieldDescription.title}
        code={fieldDescription.code}
        value={value ?? fieldDescription?.default}
        uid={cellUid}
        required={fieldDescription.required}
        onUpdate={onUpdate}
        {...props}
      />
    </SnowBox>
  )
}
export default ScEditableCellRenderer
