import { SnowTypography } from '../../../../../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const PersonalisationRowLabel = styled(SnowTypography).attrs({
  variant: 'inherit',
  component: 'p'
})`
  flex: 0 1 30%;
  &.MuiTypography-root {
    margin-right: ${({ theme }) => theme.spacing.secondary};
    word-break: break-all;
  }
`

export default PersonalisationRowLabel
