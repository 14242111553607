import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import {
  currentIntegrationConfigOriginState
} from '../../data/integrationModel'
import {
  integrationCqrsRequestModel,
  integrationEntityType,
  currentConfigEntityType
} from '../../data/integrationDeterminant'
import { fetchConfigurations } from '../../infrastructure/v1/apis'
import ScSpinner from '../../../../shared/components/ScSpinner/ScSpinner'
import { TabContent } from '../../../../shared/components/form/ScTabs/ScTabs'
import ScTabs from '../../../../shared/components/form/ScTabs'
import ConfigView from './ConfigView'
import ConfigSaver from './ConfigSaver'
import { setScPageHeaderExtensionCmd } from '../../../app/data/currentHeadingState'
import styled from 'styled-components'

const StyledErrorTypography = styled(SnowTypography)`
  color: ${({ theme }) => theme.mainPalette.warning.secondary};
`

const defaultTabConfig = [
  // {
  //   title: 'Shipping Methods mapping',
  //   path: 'mapping',
  //   element: <p>Coming Soon</p>
  // }
]
const withActive = (tabConfigs = [], activePath) => {
  return tabConfigs
    .filter((config) => config)
    .map(({ path, ...rest }) => ({
      ...rest,
      path,
      active: path === activePath
    }))
}
export const useIntegrationSettings = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  //get config entity id and core domain id
  const { configEntityType: integrationTab, domainId: integrationEntityId } =
    useParams()

  // get integration entity type of core domain - vendor| project  to get main configuration
  const integrationEntityTypeName = useRecoilValue(integrationEntityType)

  // get integration deterministic params {channel,vendor,project}
  const cqrs = useRecoilValue(integrationCqrsRequestModel)

  //setter for currently configuration entity (active tab) "vendor"|"scripts"|"mapping"
  const setConfigEntityType = useSetRecoilState(currentConfigEntityType)
  const [loadedConfiguration, setLoadedConfiguration] = useRecoilState(
    currentIntegrationConfigOriginState
  )
  const resetLoadedConfiguration = useResetRecoilState(
    currentIntegrationConfigOriginState
  )
  console.log('project, channel, vendor ', cqrs, loadedConfiguration)

  const onConfigTabPathChange = (e, configTabPath) => {
    navigate(`../${configTabPath}`, { replace: false })
  }
  const loadConfiguration = () => {
    setIsLoading(true)
    console.log('loadConfiguration', {
      integrationEntityTypeName,
      integrationEntityId,
      cqrs
    })
    fetchConfigurations({
      type: integrationEntityTypeName,
      entityId: integrationEntityId, //{vendor|project} id
      projectId: cqrs.project,
      channelId: cqrs.channel,
      vendor: cqrs.vendor
    }).then((resp) => {
      console.log('useIntegrationSettings fetchConfigurations', resp)
      const { error = false, ...rest } = resp
      if (error) {
        const {
          errorBody: {
            data: {
              error: errorMessage = 'No permission to access this resource.'
            },
            status: responseStatus = 403
          }
        } = rest ?? {}
        console.log(
          'useIntegrationSettings fetchConfigurations',
          responseStatus,
          errorMessage
        )
        setErrors({ status: responseStatus, message: errorMessage })
      } else {
        setLoadedConfiguration(resp)
      }

      setIsLoading(false)
    })
  }

  useEffect(() => {
    console.log(
      'Updated loadedConfiguration',
      cqrs,
      loadedConfiguration,
      typeof loadedConfiguration
    )
  }, [loadedConfiguration])

  //load config for corresponded integration type
  useEffect(() => {
    console.log(
      'integrationEntityType',
      integrationEntityTypeName,
      loadedConfiguration
    )
    // if (!loadedConfiguration) prepareInputs()
    integrationEntityTypeName && loadConfiguration()
  }, [integrationEntityTypeName, cqrs])

  useEffect(() => {
    return () => {
      console.log('integrationSettings destructor')
      resetLoadedConfiguration()
    }
  }, [])

  return {
    isLoading,
    errors,
    navigate,
    loadedConfiguration,
    onConfigTabPathChange,
    integrationEntityId,
    integrationTab,
    setConfigEntityType
  }
}

const IntegrationSettings = ({ ...props }) => {
  const {
    navigate,
    loadedConfiguration,
    onConfigTabPathChange,
    integrationTab,
    setConfigEntityType,
    isLoading,
    errors
  } = useIntegrationSettings()

  const [tabsConfig, setTabsConfig] = useState([])
  const setHeaderExtension = useSetRecoilState(setScPageHeaderExtensionCmd)
  const resetHeaderExtension = useResetRecoilState(setScPageHeaderExtensionCmd)

  useEffect(() => {
    console.log(
      'IntegrationSettings  loadedConfiguration changed',
      loadedConfiguration
    )
    if (!loadedConfiguration) return

    const { items } = loadedConfiguration

    if (!Array.isArray(items)) return

    const loadedConfigTabs = items.map((configItem) => ({
      title: configItem.title,
      path: configItem.config_entity_type,
      element: <ConfigView config={configItem} />
    }))

    setHeaderExtension(<ConfigSaver />)

    setTabsConfig(
      withActive([...loadedConfigTabs, ...defaultTabConfig], integrationTab)
    )
  }, [loadedConfiguration])

  useEffect(() => {
    if (!tabsConfig.length) return
    console.log('tabsConfig', tabsConfig, integrationTab)
    !integrationTab && navigate(tabsConfig[0].path)
  }, [tabsConfig])

  useEffect(() => {
    setConfigEntityType(integrationTab)
    tabsConfig &&
      integrationTab &&
      setTabsConfig(withActive(tabsConfig, integrationTab))
  }, [integrationTab])

  useEffect(() => {
    console.log('IntegrationSettings constructed. ')

    return () => {
      resetHeaderExtension()
      console.log('IntegrationSettings destructed. Reset origin values!')
    }
  }, [])

  console.log('tabsConfig render', {
    errors,
    loadedConfiguration,
    integrationTab,
    tabsConfig
  })
  return (
    <div>
      {isLoading && <ScSpinner />}
      {errors && (
        <StyledErrorTypography>{errors.message}</StyledErrorTypography>
      )}
      {loadedConfiguration ? (
        tabsConfig.length && (
          <ScTabs onTab={onConfigTabPathChange}>
            {tabsConfig.map((tab) => {
              return (
                <TabContent
                  key={tab.path}
                  label={tab.title}
                  value={tab.path}
                  active={tab.active}
                >
                  {tab.element}
                </TabContent>
              )
            })}
          </ScTabs>
        )
      ) : (
        <div>Integration is not defined</div>
      )}
    </div>
  )
}
export default IntegrationSettings
