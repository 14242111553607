import objWithParent from '../../../../utils/parenter'
import { useEntityCommand } from '../../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../../infrastructure/api/utils'

const PLATFORM_CONNECTIONS_URL = `connections`
const CHANNEL_CREATE_URL = `add`
const CHANNEL_CONNECTION_ADD_URL = `connections/add`
export const API_CHANNELS = objWithParent({
  url: '/marketplaces',
  FETCH_CHANNELS: {
    url: '',
    method: 'GET',
    caller: 'API_CHANNELS FETCH_CHANNELS',
    validator: () => true //{page, per_page, filters??}
  },
  FETCH_CONNECTIONS: {
    version: 'v1',
    url: `${PLATFORM_CONNECTIONS_URL}`,
    method: 'GET',
    caller: 'CHANNELS_FETCH_URL!',
    validator: ({ platformId }) => true
  },
  ADD: {
    version: 'v1',
    url: `${CHANNEL_CREATE_URL}`,
    method: 'POST',
    caller: 'CHANNEL_CREATE_URL!',
    validator: ({ platform_id, marketplace_id, shop_id }) => true
  },
  ADD_CONNECTION: {
    version: 'v1',
    url: `${CHANNEL_CONNECTION_ADD_URL}`,
    method: 'POST',
    caller: 'CHANNEL_CONNECTION_ADD_URL!',
    validator: ({ marketplace_id, connection_id }) => true
  }
})
export const useChannelsCmd = (
  onSuccess = (r) =>
    console.log('useFetchPlatformFreeConnectionsCmd onSuccess', r),
  onFail = (r) => console.log('useFetchPlatformFreeConnectionsCmd onFail', r)
) => {
  const cmd = useEntityCommand(
    null,
    {
      ...API_CHANNELS.FETCH_CHANNELS,
      params: {}
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return cmd
}
export const useFetchFreeConnectionsCmd = (
  onSuccess = (r) =>
    console.log('useFetchPlatformFreeConnectionsCmd onSuccess', r),
  onFail = (r) => console.log('useFetchPlatformFreeConnectionsCmd onFail', r)
) => {
  const fetchConnectionsCmd = useEntityCommand(
    0,
    {
      ...API_CHANNELS.FETCH_CONNECTIONS,
      params: {
        not_connected: true
      }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return fetchConnectionsCmd
}

export const useCreateChannelCmd = (
  { platformId, channelName, projectId, currencyCode },
  onSuccess = (r) => console.log('useCreateChannelCmd onSuccess', r),
  onFail = (r) => console.log('useCreateChannelCmd onFail', r)
) => {
  const createChannelCmd = useEntityCommand(
    0,
    {
      ...API_CHANNELS.ADD,
      params: {
        platform_id: platformId,
        marketplace_name: channelName,
        shop_id: projectId,
        currency_code: currencyCode
      }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return createChannelCmd
}

export const useCreateChannelConnectionCmd = (
  { channelId, connectionId },
  onSuccess = (r) => console.log('useCreateChannelConnectionCmd onSuccess', r),
  onFail = (r) => console.log('useCreateChannelConnectionCmd onFail', r)
) => {
  const createChannelConnectionCmd = useEntityCommand(
    0,
    {
      ...API_CHANNELS.ADD_CONNECTION,
      params: {
        connection_id: connectionId,
        marketplace_id: channelId
      }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return createChannelConnectionCmd
}
