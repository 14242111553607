import React, { Fragment } from 'react'
import {ReactComponent as ProductIcon} from '../../../../app/images/SVGs/product.svg'
import {ReactComponent as StoreIcon} from '../../../../app/images/SVGs/store.svg'
import ScGroupContent from '../../../../../shared/components/ScNestedContent/ScGroupContent'

const predefinedGroupIcons = {
  product: <ProductIcon/>,
  product_vendors: <StoreIcon/>
}

const GroupContent = ({ getContent, meta, values, level, linePath = '' }) => {
  console.group('GroupBlock', meta, values)

  const { group_data, label, code } = meta

  const groupItems = values
    ? !group_data.type
      ? Object.entries(group_data).map(([key, value]) => {
          return { meta: { ...value, code: key }, values: values[key] }
        })
      : [{ meta: { ...group_data, code: code  }, values: values }]
    : []

  const groupIcon = predefinedGroupIcons[meta.code]
  const groupLinePath = !linePath ? `%key%.` : linePath
  console.log('groupLinePath', groupLinePath)
  console.groupEnd()

  return (
    <Fragment>
      <ScGroupContent
        aria-label='group'
        groupIcon={groupIcon}
        groupCode={code}
        groupPath={groupLinePath}
        level={level}
        items={groupItems}
        label={label}
        getGroupContent={getContent}
      />
    </Fragment>
  )
}

export default GroupContent
