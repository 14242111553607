import React from 'react'
import { OpenInNewIcon } from 'mdi-react'
import { ScWhiteButton } from '../../buttons'

const ScOpenTextButton = ({ href, label, ...props }) => {
  return (
    <ScWhiteButton
      href={href}
      target="_blank"
      rel="noreferrer"
      component="a"
      label={label}
      icon={() => <OpenInNewIcon />}
      onClick={() => {}}
      {...props}
    />
  )
}

export default ScOpenTextButton
