/**
 *
 * @param key - key for storing data
 * @param dataJson - storing data
 */
export const keepInStorage = (key, dataJson) => {
  localStorage.setItem(key, JSON.stringify(dataJson))
}

/**
 *
 * @param key - key to get storing data
 * @returns {any} - storing data for given key
 */
export const extractFromStorage = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

/**
 *
 * @param key - clear storage for given key or clear all if nothing
 */
export const clearStorage = (key = '') =>
  key ? localStorage.removeItem(key) : localStorage.clear()

export const Storage = {
  keep: keepInStorage,
  get: extractFromStorage,
  clear: clearStorage
}
