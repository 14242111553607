import { ScIconButton } from '../../../../../shared/components/buttons';
import styled from 'styled-components'

const CancelButton = styled(ScIconButton).attrs({
  noPadding: true,
})`
  &.MuiIconButton-root {
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
  }
  svg {
    font-size: 18px;
  }
`

export default CancelButton