import {  useState } from 'react'
import { fetchProjectChannels } from '../infrastructure/v1/apis'
import { atomFamily, selectorFamily, useRecoilValue } from 'recoil'
import { replaceArrayItem } from '../../../utils/helpers'

const loadedChannelsAtom = atomFamily({
  key: 'loadedChannelsAtom',
  default: selectorFamily({
    key: 'loaderChannels',
    get:
      (projectId = '0') =>
      async ({ get }) => {
        const { data: channels, ...serviceInfo } = await fetchProjectChannels({
          projectId
        })
        return channels ?? []
      }
  })
})
export const channelWithId = selectorFamily({
  key: 'channelWithId',
  get:
    (channelId) =>
    ({ get }) =>
      get(loadedChannelsAtom('0')).find(({ id }) => +id === +channelId),
  set:
    (channelId) =>
    ({ set, get }, newValue) => {
      const channels = get(loadedChannelsAtom('0'))
      const index = channels.findIndex(({ id }) => +id === +channelId)
      set(loadedChannelsAtom('0'), [
        ...replaceArrayItem(channels, index, newValue)
      ])
    }
})

const useChannels = ({ projectId = '0', vendorId = '0' } = {}) => {
  // const [channels, setChannels] = useState([])
  const loadedChannels = useRecoilValue(loadedChannelsAtom(projectId))
  const [loadingError, setLoadingError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  return { data: loadedChannels, isLoading, loadingError }
}

export default useChannels
