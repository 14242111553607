import React, { useEffect, useState } from 'react'
import { SnowDivider } from '../../../../../shared/components/snowComponents'
import { ScCheckboxField } from '../../../../../shared/components/SnowEditableField/baseFields'
import ShippingAddress from './addresses/ShippingAddress'
import BillingAddress from './addresses/BillingAddress'
import { SidebarSubtitleView, SidebarBlockTitleView } from './_styled'

const CustomerAddress = ({ order }) => {
  const [actualBillingAddress, setActualBillingAddress] = useState(
    order[`customer_billing_address`]
  )
  const [actualShippingAddress, setActualShippingAddress] = useState(
    order[`customer_shipping_address`]
  )
  const [billingAsShipping, setBillingAsShipping] = useState(false)

  useEffect(() => {
    console.log('actualShippingAddress', actualShippingAddress)
    const { vat_number, ...addressWithoutWAT } = actualShippingAddress
    billingAsShipping && setActualBillingAddress(addressWithoutWAT)
  }, [billingAsShipping, actualShippingAddress])

  return (
    <div>
      <ShippingAddress
        values={actualShippingAddress}
        orderId={order.id}
        onUpdate={setActualShippingAddress}
      />
      <SnowDivider />
      <SidebarBlockTitleView>
        <SidebarSubtitleView>
          Billing address the same as shipping
        </SidebarSubtitleView>
        <ScCheckboxField
          value={billingAsShipping}
          code={'billingAsShipping'}
          onUpdate={({ value }) => {
            console.log('billingAsShipping ', value)
            setBillingAsShipping(value)
          }}
        />
      </SidebarBlockTitleView>

      {!billingAsShipping && (
        <>
          <SnowDivider />
          <BillingAddress
            values={actualBillingAddress}
            onUpdate={setActualBillingAddress}
            orderId={order.id}
          />
        </>
      )}
    </div>
  )
}

export default CustomerAddress
