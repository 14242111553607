import React, { Fragment, useEffect } from 'react'
import { SidebarContainerView } from './_styled/SidebarContainerView'
import { SidebarPaperView } from './_styled'

const ScSidebar = ({ children, props }) => {
  const _children = Array.isArray(children) ? children : [children]

  useEffect(() => {
    console.log('ScSidebar constructor')
    return () => console.log('ScSidebar destructor')
  }, [])

  return (
    <SidebarContainerView>
      {_children
        .filter((child) => Boolean(child))
        .map((child, idx) => (
          <Fragment key={idx}>
            <React.Suspense fallback={'loading '}>
              <SidebarPaperView>{child}</SidebarPaperView>
            </React.Suspense>
          </Fragment>
        ))}
    </SidebarContainerView>
  )
}

export default ScSidebar
