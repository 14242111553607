import React, { useEffect, useState } from 'react'
import { requestor } from './requestor'
import useAuthToken from '../../../domains/auth/hooks/useAuthToken'
// todo: token adding, result verification
const useFetchedResult = (requestConfig, noResults = null) => {
  const { getAuthToken } = useAuthToken()
  const token = getAuthToken()
  //request - funcName and params
  const [results, setResults] = useState(noResults)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const configMemo = JSON.stringify(requestConfig)
  // console.log('requestConfig', requestConfig)
  useEffect(() => {
    setIsLoading(true)
    requestor({ ...requestConfig, token })
      .then((response) => {
        console.log('response', response)
        if (response.status === 200) {
          setResults(response.data)
          setError(null)
        } else {
          setError(response.statusText)
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        const { messages, success } = err?.response?.data ?? {}

        setError(err.message ?? messages)
      })
  }, [configMemo])

  return { data: results, error, isLoading }
}

export default useFetchedResult
