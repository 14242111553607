import React, { Fragment, useContext, useState, useEffect } from 'react'
import {
  SnowTableCell,
  SnowTypography
} from '../../../shared/components/snowComponents'
import { ScCrudButton } from '../../../shared/components/buttons'
import { ScConfirmationAlert } from '../../../shared/components/modals'
import CarriersBasicRow from './CarriersBasicRow'
import styled, { ThemeContext } from 'styled-components'

const MappingsTableCellView = styled(SnowTableCell)`
  &.MuiTableCell-root {
    padding: 8px;
  }
`

const MappingCollapseCellView = styled(SnowTableCell).attrs({
  colSpan: 6
})`
  &.MuiTableCell-root {
    padding: 0;
    border: none;
    background: ${({ theme }) => theme.mainPalette.grey[50]};
  }
`

const CarriersRow = ({
  isCollapsible = false,
  newRowId = '',
  row = {},
  columns = [],
  onEdit = () => { },
  onRemove = () => { },
  onCreate = () => { },
  onUpdate = () => { }
}) => {
  const theme = useContext(ThemeContext)
  const [editState, setEditState] = useState(false)
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [rowData, setRowData] = useState(row)

  const isNewItem = row.id === newRowId

  const handleRowUpdate = ({ code, value }) => {
    const updateRow = (applied) => ({ ...applied, [code]: value })
    setRowData(updateRow)
    console.log('CarriersRow events update', { code, value })
  }

  const handleEdit = () => {
    onEdit && onEdit(rowData)
    setEditState(true)
    console.log('CarriersRow events edit', rowData)
  }

  const handleSave = () => {
    handleCreate()
    onUpdate && onUpdate(rowData)
    setEditState(false)
    console.log('CarriersRow events save')
  }

  const handleRemove = () => {
    onRemove && onRemove(rowData)
    setEditState(false)
    handleCloseRemoveDialog()
    console.log('CarriersRow events remove')
  }

  const handleOpenRemoveDialog = () => {
    setOpenRemoveDialog(true)
  }
  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false)
  }

  const handleCreate = () => {
    isNewItem && onCreate && onCreate(rowData)
    isNewItem && console.log('CarriersRow events create')
  }

  useEffect(() => {
    setRowData(row)
    isNewItem && setEditState(true)
  }, [row])

  return (
    <Fragment>
      <ScConfirmationAlert
        isShowAttention={openRemoveDialog}
        message={{
          title: 'Delete item',
          content: (
            <SnowTypography align="center">
              Are you sure you want to delete this item?
            </SnowTypography>
          )
        }}
        onClose={handleCloseRemoveDialog}
        onConfirm={handleRemove}
      />
      <>
        <MappingsTableCellView>
          <ScCrudButton
            editMode={editState}
            onEdit={handleEdit}
            onSave={handleSave}
            size={40}
            color={theme.mainPalette.color.secondary}
          />
        </MappingsTableCellView>
        <CarriersBasicRow
          edit={isCollapsible ? false : editState}
          row={rowData}
          columns={columns}
          handleRowUpdate={handleRowUpdate}
        />
        <MappingsTableCellView style={{ width: '5%' }} size={'small'}>
          <ScCrudButton
            editMode={true}
            onRemove={handleOpenRemoveDialog}
            size={40}
            color={theme.mainPalette.color.secondary}
          />
        </MappingsTableCellView>
      </>
      {/* </SnowTableRow> */}
      {/* {isCollapsible ? (
        <SnowTableRow>
          <MappingCollapseCellView>
            <SnowCollapse in={editState} timeout="auto" unmountOnExit>
              <SnowTable>
                <SnowTableRow>
                  <MappingsTableCellView />
                  <CarriersBasicRow
                    edit={editState}
                    row={rowData}
                    columns={columns}
                    handleRowUpdate={handleRowUpdate}
                  />
                  <MappingsTableCellView />
                </SnowTableRow>
              </SnowTable>
            </SnowCollapse>
          </MappingCollapseCellView>
        </SnowTableRow>
      ) : null} */}
    </Fragment>
  )
}

export default CarriersRow
