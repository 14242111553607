import React from 'react'
import { SnowList, SnowListItem } from '../../snowComponents'
import ScCard from './ScCard/ScCard'
import styled from 'styled-components'

const CardsList = styled(SnowList).attrs({
  disablePadding: true
})`
  &.MuiList-root{
    margin: -5px;
  }
  display: flex;
  flex-wrap: wrap;
  .MuiListItem-root {
    padding: 5px;
    flex: 0 0 25%;
    min-width: 170px;
    max-width: 345px;
    box-sizing: border-box;
  }
  .MuiImageListItem-item {
    overflow: visible;
  }
`

const SnowEntityCardsList = (
  {
    data = [],
    onItemSelect = null,
    onItemConfirmed = null,
    selectedItem = null
  } = {
    data: [],
    onItemSelect: null,
    onItemConfirmed: null,
    selectedItem: null
  }
) => {
  const selectItem = (item) => {
    console.log('SnowList selectItem', item)
    if (onItemSelect) {
      onItemSelect(item)
    }
  }

  return (
    <CardsList
      aria-label={'entity-card-list'}
    >
      {data.map((item) => (
        <SnowListItem key={item.id} dense={false}>
          <ScCard
            isSelected={selectedItem ? item.id == selectedItem : false}
            data={item}
            onDoubleClick={() => onItemConfirmed && onItemConfirmed(item)}
            onClick={() => selectItem(item)}
          />
        </SnowListItem>
      ))}
    </CardsList>
  )
}

export default SnowEntityCardsList
