import styled, { css } from 'styled-components'

const TimelineHeaderView = styled.header`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.main};
    padding: ${theme.spacing.secondary};
    border-bottom: ${theme.border.main};
    flex-shrink: 0;
  `}
`

export default TimelineHeaderView