import React from 'react'
import Tooltip from '@mui/material/Tooltip'

const SnowTooltip = React.forwardRef(function SnowTooltip(
  { children, ...props },
  ref
) {
  return (
    <Tooltip ref={ref} {...props}>
      {children}
    </Tooltip>
  )
})

export default SnowTooltip
