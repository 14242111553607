import axios from 'axios'
const BASE_URL = process.env.REACT_APP_URL

const processResponse = async (response, functionName) => {
  const data = await response
    .then(
      (response) => {
        // console.log(functionName +' 2response',response);
        return response
      },
      (error) => {
        console.log(functionName + ' error', error, error.response)
        return {
          data: { data: null },
          error: true,
          errorBody: error.response
        }
      }
    )
    .catch((error) => {
      console.log('processResponse exception', error)
      console.log(`${functionName} exception`, error.response)
      return { data: { data: null }, error: error, errorBody: error.response }
    })
  return data
}
export const saFetch = async ({
  token,
  type,
  url,
  fetchParams = {},
  caller = 'anonymous'
}) => {
  // console.log('saFetch: ', type, url, fetchParams)
  const params =
    type.toLowerCase() === 'post'
      ? { data: fetchParams }
      : { params: fetchParams }
  console.log('Requestors', params)
  const config = {
    method: type,
    url: url,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    ...params
  }
  // console.log('saFetch config', config)
  const response = axios(config)
  const data = await processResponse(response, caller)
  if (data.error) {
    if (data.errorBody && data.errorBody.status == 401) {
      // Auth.signout()
      console.log('Signed out because ', data.errorBody.data.status)
    }
    return data
  }
  // console.log('SA fetch', caller, data)
  return data.data
}

export const portalRequestor = ({
  type,
  url,
  fetchParams = {},
  caller = 'anonymous',
  version = 'v1'
}) => {
  const token = JSON.parse(localStorage.getItem('token'))
  const requestedURL = `${BASE_URL}/portal/${version}/${url}`
  // console.log('portalRequestor requestedURL', url, requestedURL, token)
  return saFetch({ token, url: requestedURL, type, fetchParams, caller })
}
