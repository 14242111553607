import React from 'react'
import {
  SnowAcUnitIcon, SnowListAltIcon,
  SnowFormatListBulletedIcon, SnowShoppingBasketOutlinedIcon,
  SnowGroupWorkOutlinedIcon, SnowSettingsInputComponentIcon,
  SnowImageSearchIcon, SnowTimelineIcon, SnowLanguageOutlinedIcon,
  SnowLocalShippingOutlinedIcon, SnowSettingsOutlinedIcon
} from '../../../../shared/components/icons'

import { routesList } from '../../App/routesList'

export const links = [
  {
    name: 'Dashboard',
    route: routesList.DASHBOAD,
    icon: <SnowAcUnitIcon />
  },
  { name: 'Orders', route: routesList.ORDERS, icon: <SnowListAltIcon /> },
  {
    name: 'Order Items',
    route: routesList.ORDER_ITEMS,
    icon: <SnowFormatListBulletedIcon />
  },
  {
    name: 'Products',
    route: routesList.PRODUCT,
    icon: <SnowShoppingBasketOutlinedIcon />
  },
  {
    name: 'Bundle Products',
    route: routesList.BUNDLE,
    icon: <SnowGroupWorkOutlinedIcon />
  },
  {
    name: 'Integrations',
    route: routesList.INTEGRATIONS,
    icon: <SnowSettingsInputComponentIcon />
  },
  {
    name: 'Moderation Queue',
    route: routesList.MODERATION,
    icon: <SnowImageSearchIcon />
  },
  { name: 'Batches', route: routesList.BATCHES, icon: <SnowTimelineIcon /> },
  {
    name: 'Settings',
    icon: <SnowSettingsOutlinedIcon />,
    submenu: [
      {
        name: 'Regions',
        route: routesList.REGIONS,
        icon: <SnowLanguageOutlinedIcon />
      },
      {
        name: 'Carriers',
        route: routesList.CARRIERS,
        icon: <SnowLocalShippingOutlinedIcon />
      }
    ]
  },

  // { name: 'Personalizations', route: '/home/personalizations' },
  // { name: 'Settings', route: '/home/settings' }
]
export default links
