import React, { useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import { resyncProduct, fetchProductBySaId } from '../../infrastructure/apiV1'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'

const ResyncModal = ({ showModal, toggleShowModal, ...props }) => {
  const theme = useContext(ThemeContext)
  const [showSyncingSpinner, setShowSyncingSpinner] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()

  const fetchUpdatedProduct = () => {
    const saProductId = location.pathname.split('/')[6].split('=')[1]
    const productID = location.pathname.split('/')[5].split('=')[1]
    const marketPlaceID = location.pathname.split('/')[4].split('=')[1]

    // fetchSingleProduct(marketPlaceID, productID)
    fetchProductBySaId({ saProductId }).then((response) => {
      // push new order to OrderDetail state
      props.updateProductInDetailState(response.data)

      // push new product to OrderGrid setState
      // props.updateOrderInGridState(response.data);

      // close modal
      toggleShowModal()
      props.toggleShowConfirmationModal({
        success: true,
        message: 'success'
      })
    })
  }

  const handleError = (data = '') => {
    props.toggleShowModal()
    props.toggleShowConfirmationModal({
      success: false,
      message: data.error
    })

    if (data.status === 'Token is Invalid') {
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }

  const handleConfirmation = (product) => {
    const productSaID = location.pathname.split('/')[6].split('=')[1]
    const productID = location.pathname.split('/')[5].split('=')[1]
    const marketPlaceID = location.pathname.split('/')[4].split('=')[1]

    resyncProduct({
      marketPlaceID,
      productID,
      productSaID
    }).then((data) => {
      // when the request finishes, stop syncing spinner
      setShowSyncingSpinner(false)
      if (data.success) {
        fetchUpdatedProduct()
      } else {
        console.log(data)
        handleError(data)
      }
    })
    // the promise above ^ will take about 20 seconds to return
    // so the code in the line below will actually trigger first and start the syncing spinner icon
    setShowSyncingSpinner(true)
  }

  return (
    <ScConfirmationAlert
      isShowAttention={showModal}
      onClose={toggleShowModal}
      onConfirm={() => handleConfirmation(props.product)}
      loading={showSyncingSpinner}
      message={{
        content: (
          <>
            {' '}
            <SnowTypography variant={'body1'} component={'p'} align={'center'}>
              {`Are you sure you would like to resync product data for `}
            </SnowTypography>
            <SnowTypography
              variant={'body1'}
              component={'p'}
              align={'center'}
              fontWeight={theme.fontWeight.main}
            >{`${props?.product?.values.product.marketplace_product_sku}`}</SnowTypography>
          </>
        ),
        syncText: `Product data is syncing. This could take up to 20 seconds. Thanks for your patience!`
      }}
    />
  )
}

ResyncModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  updateProductInDetailState: PropTypes.func.isRequired,
  // updateOrderInGridState: PropTypes.func.isRequired,
  toggleShowConfirmationModal: PropTypes.func.isRequired
}

export default ResyncModal
