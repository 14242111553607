import { useEffect, useState } from 'react'
import {
  atom,
  atomFamily,
  selector,
  useRecoilState,
  useRecoilValue
} from 'recoil'

import { useCarriersApi } from '../infrastructure/carriersApi'

export const NEW_CARRIER_ID = ''
export const NEW_PLATFORM_MAPPING_ID = ''
export const NEW_VENDOR_MAPPING_ID=''

export const createCarrierItem = () => ({
  id: NEW_CARRIER_ID,
  code: '',
  title: '',
  tracking_url: ''
})

export const createVendorsMappingItem = () => ({
  id: NEW_VENDOR_MAPPING_ID,
  code: '',
  carrier_code: '',
  vendor: '',
})

export const createPlatformsMappingItem = () => ({
  id: NEW_PLATFORM_MAPPING_ID,
  sa_carrier_code: '',
  platform_carrier_code: '',
  title: '',
  platform: '',
})

const carriersLoadedPage = atomFamily({
  key: 'carriersLoadedPage',
  default: (pageUid) => []
})
const loadedCarriersPageUids = atom({
  key: 'loadedCarriersPageUids',
  default: new Set()
})
const loadedCarrierSelector = selector({
  key: 'loadedCarrierSelector',
  get: ({ get }) => {
    console.log('get(loadedCarrierSelector)', get(loadedCarriersPageUids))
    return Array.from(get(loadedCarriersPageUids)).reduce(
      (acc) => [...acc, ...get(carriersLoadedPage)],
      []
    )
  }
})

export const useCarriersList = () => {
  const pageUid = JSON.stringify()
  const [, setCarriersList] = useRecoilState(carriersLoadedPage(pageUid))
  const loadedCarriers = useRecoilValue(loadedCarrierSelector)
  const [shownCarriers, setShownCarriers] = useState(loadedCarriers)

  const fetchCarriersCmd = useCarriersApi(
    (r) => {
      console.log('fetchCarriersCmd onSuccess ', r?.data)
      r?.data && setCarriersList(r?.data)
    },
    (r) => console.log('fetchCarriersCmd onFail r', r)
  )
  useEffect(() => {
    setShownCarriers(loadedCarriers)
    console.log('loadedCarriers', loadedCarriers)
  }, [])

  useEffect(() => {
    fetchCarriersCmd()
  }, [])

  return { loadedCarriers: shownCarriers }
}
