import React, { useEffect, useState } from 'react'
import { SnowTypography } from '../../shared/components/snowComponents'
import {
  ScTextField,
  ScCheckboxField,
  ScComboField
} from '../../shared/components/SnowEditableField/baseFields'
import { ScFlatCustomValuesMapField } from '../../shared/components/SnowEditableField/groupingFields'
import { useCountries } from './data/areaDictionaries'
import CountryRegions from './CountryRegions'
import {
  useAreaGroupDetailsModel,
  useAreaOptions,
  useLoadedAreaGroupDetails
} from './data/areaGroupDetails'
import { isEmpty } from 'lodash'
import ScSpinner from '../../shared/components/ScSpinner/ScSpinner'
import AreaGroupVendors from './AreaGroupVendors'
import useRegionVendors from './data/regionVendors'
import ScTabs from '../../shared/components/form/ScTabs'
import { TabContent } from '../../shared/components/form/ScTabs/ScTabs'
import { useAreaGroupLoadedVendors } from './data/loadedAreaGroupVendors2'
import useSnowLoggerSetup from '../../shared/service/helpers/SnowLogger'
import styled from 'styled-components'

const StyledMainRow = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: 1fr 200px;
  column-gap: 16px;
`

function AreaGroupSettings({ areaGroup, currentTab, onTab }) {
  const { id } = areaGroup
  const countries = useCountries()
  const logger = useSnowLoggerSetup({ level: 'ALL', color: ['blue', 'yellow'] })
  useLoadedAreaGroupDetails(id)

  const { updatedVendors, saveCmd: saveUpdatedVendorsCmd } =
    useAreaGroupLoadedVendors(id)

  const { shownModel, updateModelProp: updateCmd } =
    useAreaGroupDetailsModel(id)

  const { level, name, vendor_enabled_by_default, description } = shownModel

  const { countriesOptions, levelsOptions } = useAreaOptions()

  const [groupCountries, setGroupCountries] = useState({})

  const {
    areaGroupVendors: groupVendors,
    createRegionVendorCmd: createGroupVendor
  } = useRegionVendors({
    area_group_id: id
  })

  const handleStatusChange = ({ value: status }) => {
    console.log('handleStatusChange', name, status)
    updateCmd('vendor_enabled_by_default', status)
  }
  const handleCountriesChange = ({
    value: updatedCountriesValue,
    ...updatedProps
  }) => {
    console.log(
      'handleChangeCountries updatedCountry',
      updatedCountriesValue,
      updatedProps
    )
    const prettifiedValue = Object.keys(updatedCountriesValue).reduce(
      (acc, countryKey) => ({
        ...acc,
        [countryKey]: Array.isArray(updatedCountriesValue[countryKey])
          ? updatedCountriesValue[countryKey]
          : []
      }),
      {}
    )
    setGroupCountries(prettifiedValue)
    updateCmd('countries', prettifiedValue)
  }

  const handleNameChange = ({ value: name, ...updatedProps }) => {
    console.log('handleChangeName', name, updatedProps)
    updateCmd('name', name)
  }
  const handleLevelChange = ({ value: level, ...updatedProps }) => {
    console.log('handleChangeLevel', level, updatedProps)
    updateCmd('level', level)
  }
  const handleDescriptionChange = ({ value: description, ...updatedProps }) => {
    updateCmd('description', description)
  }

  const handleTab = (e, val) => onTab(val)

  useEffect(() => {
    logger.color('yellow', 'magenta').log('shownModel!!!', shownModel)
    shownModel && setGroupCountries(shownModel?.countries)
  }, [shownModel])

  useEffect(() => {
    console.log('updatedVendors', updatedVendors)
    // shownModel && setGroupCountries(shownModel?.countries)
  }, [updatedVendors])

  useEffect(() => {
    return () => console.log('AreaGroupSettings destructor')
  }, [])

  logger.log('AreaGroupSettings!!', {
    countries,
    areaGroup,
    updatedVendors,
    groupCountries
  })

  if (isEmpty(shownModel)) return <ScSpinner />

  return (
    <>
      <ScTabs onTab={handleTab}>
        <TabContent
          value={0}
          label={'Area Group Settings'}
          active={currentTab === 0}
        >
          {/*<pre>{prettifyJsonOutput(shownModel)}</pre>*/}
          <SnowTypography gutterBottom component="h3">
            <ScCheckboxField
              code={'vendor_enabled_by_default'}
              label={'Vendors status by default'}
              value={vendor_enabled_by_default}
              onUpdate={handleStatusChange}
            />
          </SnowTypography>
          <StyledMainRow>
            <ScTextField
              code={'name'}
              label={'Group name'}
              value={name}
              onUpdate={handleNameChange}
            />
            <ScComboField
              code={'level'}
              label={'Level'}
              value={level}
              meta={{ options: levelsOptions }}
              onUpdate={handleLevelChange}
            />
          </StyledMainRow>
          <ScTextField
            code={'description'}
            label={'Description'}
            value={description}
            onUpdate={handleDescriptionChange}
          />
          <br />
          {groupCountries && !isEmpty(countriesOptions) && (
            <ScFlatCustomValuesMapField
              addButtonLabel="Countries"
              code={'countries'}
              keyLabel="Country"
              value={groupCountries}
              // fixedKeys={true}
              isGroupField={true}
              meta={{
                options: countriesOptions
              }}
              onUpdate={handleCountriesChange}
              valueRenderer={(countryId, onUpdate) =>
                countries.find(({ code }) => code === countryId)?.regions ? (
                  <CountryRegions
                    countryId={countryId}
                    value={groupCountries[countryId]}
                    onUpdate={onUpdate}
                  />
                ) : (
                  <span
                    style={{
                      textTransform: 'lowercase',
                      letterSpacing: '2px'
                    }}
                  >
                    Unitary state
                  </span>
                )
              }
            />
          )}
        </TabContent>
        {id && (
          <TabContent
            value={1}
            label={'Area Group Vendors'}
            active={currentTab === 1}
          >
            <AreaGroupVendors area_group_id={id} />
          </TabContent>
        )}
      </ScTabs>
    </>
  )
}
export default AreaGroupSettings
