import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { removeEmptyProps, removeUndefinedProps } from '../../../utils/helpers'
import PropTypes from 'prop-types'
import { NIL } from 'uuid'

const API_COUNTRIES = objWithParent({
  url: '/area/countries',
  FETCH_COUNTRIES: {
    url: '',
    method: 'GET',
    caller: 'API_COUNTRIES FETCH_COUNTRIES',
    validator: ({ orderby = 'desc', per_page = 20, name, code }) => true
  },
  FETCH_COUNTRY_DETAILS: {
    url: '',
    method: 'GET',
    caller: 'API_COUNTRIES FETCH_DETAILS',
    validator: () => true
  }
})
const API_GROUP = objWithParent({
  url: '/area/group',
  FETCH_GROUP_LEVELS: {
    url: 'level-options',
    method: 'GET',
    caller: 'API_GROUP FETCH_GROUP_LEVELS',
    validator: () => true
  }
})

const API_AREAS = objWithParent({
  url: '/area/groups',
  FETCH_AREA_GROUPS: {
    url: '',
    method: 'GET',
    caller: 'API_AREA FETCH_AREA_GROUPS',
    validator: ({
      orderby = 'desc',
      page = 1,
      per_page = 20,
      name,
      description,
      level
    }) => true
  },
  FETCH_GROUP_DETAIL: {
    url: '',
    method: 'GET',
    caller: 'API_AREA FETCH_GROUP_DETAIL',
    validator: () => true
  },
  CREATE_GROUP: {
    url: '',
    method: 'POST',
    caller: 'API_AREA CREATE_GROUP',
    validator: ({ name, description, level, vendor_enabled_by_default }) => true
  },
  UPDATE_GROUP: {
    url: '',
    method: 'PUT',
    caller: 'API_AREA UPDATE_GROUP',
    validator: ({ name, description, level, vendor_enabled_by_default }) => true
  },
  DELETE_GROUP: {
    url: '',
    method: 'DELETE',
    caller: 'API_AREA DELETE_GROUP',
    validator: () => true
  },
  FETCH_GROUP_VENDORS: {
    url: 'vendors',
    method: 'GET',
    caller: 'FETCH_GROUP_VENDORS',
    validator: () => true,
    responseObject: () => ({
      data: {
        area_group_id: 26,
        vendor_id: 1,
        vendor_code: 'amazon',
        vendor_name: 'Amazon FBA',
        vendor_priority: 10000,
        vendor_status: 0
      }
    })
  },
  STORE_GROUP_VENDORS: {
    url: 'vendors',
    method: 'PUT',
    caller: 'STORE_GROUP_VENDORS',
    validator: ({
      vendors = [{ vendor_id: NIL, vendor_priority: 10000, vendor_status: 0 }]
    }) => true
  }
})

export const useFetchAreaGroupsCmd = (
  { orderby = 'desc', page = 1, per_page = 20, name, description, level },
  onSuccess = (r) => console.log('useFetchAreasCmd success', r),
  onFail = (r) => console.log('useFetchAreasCmd fail', r)
) =>
  useEntityCommand(
    null,
    {
      ...API_AREAS.FETCH_AREA_GROUPS,
      params: removeEmptyProps({
        orderby,
        page,
        per_page,
        name,
        description,
        level
      })
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useFetchGroupsLevelsCmd = (
  onSuccess = (r) => console.log('useFetchGroupsLevelsCmd success', r),
  onFail = (r) => console.log('useFetchGroupsLevelsCmd fail', r)
) =>
  useEntityCommand(
    null,
    {
      ...API_GROUP.FETCH_GROUP_LEVELS,
      params: {}
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useFetchAreaGroupDetailsCmd = (
  { id },
  onSuccess = (r) => console.log('useFetchGroupDetailsCmd success', r),
  onFail = (r) => console.log('useFetchGroupDetailsCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_AREAS.FETCH_GROUP_DETAIL
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useCreateGroupCmd = (
  {
    name,
    countries = {},
    description = '',
    level = '10',
    vendor_enabled_by_default = '0'
  },
  onSuccess = (r) => console.log('useCreateGroupCmd success', r),
  onFail = (r) => console.log('useCreateGroupCmd fail', r)
) =>
  useEntityCommand(
    null,
    {
      ...API_AREAS.CREATE_GROUP,
      params: { name, countries, description, level, vendor_enabled_by_default }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useSaveAreaGroupCmd = (
  { id, name, description, level, vendor_enabled_by_default, countries },
  onSuccess = (r) => console.log('useUpdateGroupCmd success', r),
  onFail = (r) => console.log('useUpdateGroupCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_AREAS.UPDATE_GROUP,
      params: removeUndefinedProps({
        name,
        description,
        level,
        vendor_enabled_by_default,
        countries
      })
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useDeleteGroupCmd = (
  { id },
  onSuccess = (r) => console.log('useDeleteGroupCmd success', r),
  onFail = (r) => console.log('useDeleteGroupCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_AREAS.DELETE_GROUP
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useFetchCountriesCmd = (
  { orderby = 'desc' } = {},
  onSuccess = (r) => console.log('useFetchGroupCountriesCmd success', r),
  onFail = (r) => console.log('useFetchGroupCountriesCmd fail', r)
) =>
  useEntityCommand(
    null,
    {
      ...API_COUNTRIES.FETCH_COUNTRIES,
      params: removeEmptyProps({ orderby, page: 1, per_page: 400 })
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useFetchCountryDetailsCmd = (
  { id },
  onSuccess = (r) => console.log('useFetchCountryDetailsCmd success', r),
  onFail = (r) => console.log('useFetchCountryDetailsCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_COUNTRIES.FETCH_COUNTRY_DETAILS
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useFetchAreaGroupVendorsCmd = (
  { id },
  onSuccess = (r) => console.log('useFetchAreaGroupVendorsCmd success', r),
  onFail = (r) => console.log('useFetchAreaGroupVendorsCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_AREAS.FETCH_GROUP_VENDORS
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )

export const useSaveAreaGroupVendorsCmd = (
  { id, vendors = [] },
  onSuccess = (r) => console.log('useFetchAreaGroupVendorsCmd success', r),
  onFail = (r) => console.log('useFetchAreaGroupVendorsCmd fail', r)
) =>
  useEntityCommand(
    id,
    {
      ...API_AREAS.STORE_GROUP_VENDORS,
      params: { vendors }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
