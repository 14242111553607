import { atom, atomFamily, selector, DefaultValue } from 'recoil'

export const cleanCqrs = (cqrsObj) =>
  Object.entries(cqrsObj)
    .filter(([key, value]) => !!value && value !== '0')
    .reduce((acc, [key, value]) => {
      acc[key] = +value
      return acc
    }, {})

const getIntegrationHash = (cqrsObj) => JSON.stringify(cleanCqrs(cqrsObj))

const integrationRequestParams = atomFamily({
  key: 'integrationRequestParams',
  default: (paramName) => '0'
})

export const integrationEntityType = atom({
  //ex: vendor {entity}, channel {entity}
  key: 'integrationEntityType',
  default: 0
})

export const salesChannelIdSelector = selector({
  key: 'salesChannelIdSelector',
  get: ({ get }) => get(integrationCqrsRequestModel)?.channel ?? '0'
})

export const currentConfigEntityType = atom({
  //ex: vendor {config}, script {config}
  key: 'currentConfigEntityType',
  default: ''
})

/**
 *  selector to set and get id param of integration entity
 */
export const integrationCqrsRequestModel = selector({
  key: 'integrationCqrsRequestModel',
  get: ({ get }) => {
    const project = get(integrationRequestParams('project'))
    const channel = get(integrationRequestParams('channel'))
    const vendor = get(integrationRequestParams('vendor'))
    console.log('integrationCqrsRequestModel get', { project, channel, vendor })
    return { project, channel, vendor }
  },
  set: ({ get, set, reset }, newValues) => {
    if (newValues instanceof DefaultValue) {
      // DefaultValue means reset context
      // console.log('going to reset integration entity model')
      reset(integrationRequestParams('project'))
      reset(integrationRequestParams('channel'))
      reset(integrationRequestParams('vendor'))
      return
    }
    const { client, project, channel, vendor } = newValues
    console.log('integrationCqrsRequestModel set', newValues)
    set(integrationRequestParams('project'), '' + project)
    set(integrationRequestParams('channel'), '' + channel)
    set(integrationRequestParams('vendor'), '' + vendor)
  }
})

export const cqrsHash = selector({
  key: 'cqrsHash',
  get: ({ get }) => getIntegrationHash(get(integrationCqrsRequestModel))
})

const integrationEntityModel = atomFamily({
  key: 'integrationEntityModel',
  default: (param) => null
})
/**
 *  selector to set and get internal model of integration entity
 */
export const integrationEntitiesModel = selector({
  key: 'integrationEntitiesModel',
  get: ({ get }) => {
    const project = get(integrationEntityModel('project'))
    const channel = get(integrationEntityModel('channel'))
    const vendor = get(integrationEntityModel('vendor'))

    return { project, channel, vendor }
  },
  set: ({ get, set, reset }, newValues) => {
    console.log('integrationEntitiesModel', newValues)
    if (newValues instanceof DefaultValue) {
      // DefaultValue means reset context
      // console.log('going to reset integration entity model')
      reset(integrationEntityModel('project'))
      reset(integrationEntityModel('channel'))
      reset(integrationEntityModel('vendor'))
      return
    }
    const { project, channel, vendor } = newValues
    set(integrationEntityModel('project'), project)
    set(integrationEntityModel('channel'), channel)
    set(integrationEntityModel('vendor'), vendor)
  }
})
