import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  SnowTypography
} from '../../../../../../shared/components/snowComponents'
import { ScBasicModal } from '../../../../../../shared/components/modals'
import { SnowListAltIcon } from '../../../../../../shared/components/icons'
import { ScTextField } from '../../../../../../shared/components/SnowEditableField/baseFields'
import {
  ScWhiteButton,
} from '../../../../../../shared/components/buttons'
import { useCurrentOrder } from '../../../data/currentOrderState'
import { currentMessageState } from '../../../../../../shared/service/Message/data/currentMessageState'
import { useResendOrderItemsCmd } from '../../../../infrastructure/apisV2'
import styled from 'styled-components'

const StyledTextContent = styled(SnowTypography)`
  padding-bottom: ${({ theme }) => theme.spacing.secondary};
`

const ResendOrderItemsModal = ({ open, onClose, idsToResend }) => {
  const [reasonTxt, setReasonTxt] = useState('')
  const { id } = useCurrentOrder()
  const setMessage = useSetRecoilState(currentMessageState)

  const resendCmd = useResendOrderItemsCmd(
    {
      id,
      selectedItemIds: idsToResend,
      reason: reasonTxt
    },
    (result) => {
      // result?.data?.success === 'true' &&
      setMessage({
        message: result?.data?.messages?.text,
        severity: 'success'
      })
      onClose()
    }
  )
  const resendItems = () => {
    reasonTxt
      ? resendCmd()
      : setMessage({
          message: 'You should type the reason of items resending',
          severity: 'error'
        })
  }
  return (
    <ScBasicModal
      open={open}
      onClose={onClose}
      onConfirm={resendItems}
      labelForConfirmBtn={'Send'}
      title={
        <SnowTypography component="span" variant="h6" fontWeight={500}>
          Resend Order Items
        </SnowTypography>
      }
      content={
        <>
          <StyledTextContent>
            Warning! You could duplicate orders. Please don&apos;t resend unless
            you&apos;re sure about the order resending process
          </StyledTextContent>
          <ScTextField
            variant={'outlined'}
            multiline={true}
            value={reasonTxt}
            required={true}
            placeholder={'Leave a message here'}
            helperText={'Reason to resend order items'}
            onUpdate={({ value }) => setReasonTxt(value)}
            rows={2}
          />
        </>
      }
    />
  )
}

/**
 * @param idsToResend {[]}
 * @returns {JSX.Element}
 * @constructor
 */

function ResendOrderItemsButton({ idsToResend, onResent }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    onResent()
  }
  console.log('ResendOrderItemsButton idsToResend', { idsToResend })
  return (
    <>
      <ResendOrderItemsModal
        open={open}
        onClose={handleClose}
        idsToResend={idsToResend}
      />
      <ScWhiteButton
        onClick={handleOpen}
        icon={() => <SnowListAltIcon />}
        label={'Resend Items'}
      />
    </>
  )
}

export default ResendOrderItemsButton
