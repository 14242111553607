const paramToEntityValue = (value, type) => {
  switch (type) {
    case 'numeric':
      return Number(value)
    case 'string':
      return `${value}`
  }
  return value
}

const orderVendorMap = [
  { requestParam: 'errors', entityProp: 'errors' },
  { requestParam: 'status', entityProp: 'status' },
  { requestParam: 'vendor', entityProp: 'vendorCode' },
  { requestParam: 'vendor_id', entityProp: 'vendorId' },
  { requestParam: 'vendor_name', entityProp: 'vendorName' },
  { requestParam: 'vendor_orders', entityProp: 'vendorOrders' },
  { requestParam: 'order_vendor_id', entityProp: 'orderVendorId' }
]
const responseToEntity = (params) => {
  return orderVendorMap.reduce((acc, { entityProp, requestParam, type }) => {
    return {
      ...acc,
      [entityProp]: paramToEntityValue(params[requestParam], type)
    }
  }, {})
}
export const orderVendorModel = (rawData) => {
  const vendorEntity = responseToEntity(rawData)
  const legacy = Object.entries(rawData) //.filter(([key,value])=>)
  console.log('orderVendorModel', rawData, vendorEntity, legacy, orderVendorMap)
  return {
    ...vendorEntity
  }
}
export default orderVendorModel
