import React, { useState, useEffect } from 'react'
import {
  SnowTypography,
  SnowClickAwayListener
} from '../../../../../../../../shared/components/snowComponents'
import DatePicker from 'react-datepicker'
import { format, formatISO, isDate } from 'date-fns'
import { useOrderItemField } from '../../useOrderItemField'
import styled, { css } from 'styled-components'

const OrderItemDateView = styled.div`
  button {
    margin-right: ${({ theme }) => theme.spacing.secondary};
  }
`

const StyledDate = styled.div`
  .react-datepicker__day {
    width: 1.6rem;
    line-height: ${({ theme }) => theme.lineHeight.main};
  }
  .react-datepicker__day--keyboard-selected:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range
    ) {
    background-color: inherit;
    color: inherit;
    text-decoration: inherit;
  }
`

const StyledTypography = styled(SnowTypography).attrs({
  noWrap: true,
  component: 'span'
})`
  &.MuiTypography-root {
    ${({ theme }) => css`
    font-size: ${theme.fontSize.secondary};
    font-weight: ${theme.fontWeight.heavy};
  }
  `}
}}
`

const getFormattedDate = (date, dateFormat = 'eee P') =>
  date ? format(new Date(date), dateFormat) : '(Not set)'

const ScOrderItemDateRange = ({ orderItem, startDatePath, endDatePath }) => {
  const [isEdit, setIsEdit] = useState(false)
  const {
    EditToggler: StartEditToggler,
    value: startValue,
    handleDateUpdate: handleStartValueUpdate,
    isEdit: isTogglerEdit
  } = useOrderItemField({
    orderItem,
    path: startDatePath,
    edit: isEdit
  })

  const { value: endValue, handleDateUpdate: handleEndValueUpdate } =
    useOrderItemField({
      orderItem,
      path: endDatePath
    })

  const [startDate, setStartDate] = useState(
    startValue ? new Date(startValue) : null
  )
  const [endDate, setEndDate] = useState(endValue ? new Date(endValue) : null)

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    start && handleStartValueUpdate(formatISO(start))
    end && handleEndValueUpdate(formatISO(end))
    if (start && end) setIsEdit(false)
  }

  useEffect(() => {
    setIsEdit(isTogglerEdit)
  }, [isTogglerEdit])

  console.log(
    'DATE RANGE!! ',
    startDate,
    isDate(startDate),
    typeof startDate,
    endDate,
    isDate(endDate),
    typeof endDate
  )
  return (
    <OrderItemDateView>
      {StartEditToggler && <StartEditToggler />}
      {isEdit ? (
        <SnowClickAwayListener
          onClickAway={(evt) => {
            console.log('onClickAway!', evt)
            setIsEdit(false)
          }}
        >
          <StyledDate>
            <DatePicker
              selected={startDate}
              onCalendarClose={() => {
                console.log('onCalendarClose')
              }}
              onChange={onChange}
              onSelect={(...dates) => console.log('dates onSelect', dates)}
              startDate={startDate}
              endDate={endDate}
              monthsShown={2}
              selectsRange
              inline
              isClearable={true}
            />
          </StyledDate>
        </SnowClickAwayListener>
      ) : (
        <>
          <StyledTypography>
            {getFormattedDate(startDate)} to {getFormattedDate(endDate)}{' '}
          </StyledTypography>
        </>
      )}
    </OrderItemDateView>
  )
}

export default ScOrderItemDateRange
