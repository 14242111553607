import React, { useState } from 'react'
import { SnowGrid } from '../../../../../shared/components/snowComponents'
import { ScComboField } from '../../../../../shared/components/SnowEditableField/baseFields'
import { ScPrimaryBtn } from '../../../../../shared/components/buttons'
import useChannels from '../../../hooks/useChannels'
import { useAddVendorChannelsCmd } from '../../../infrastructure/v2/vendorsApiConfigs'
import styled from 'styled-components'

const StyledButtonWrapper = styled(SnowGrid)`
  padding-top: 5px;
  display: flex;
  align-items: center;
`

export const NewChannelAttacher = ({
  vendorId,
  excludedChannelIds,
  onChannelAdded
}) => {
  const { data: allChannels } = useChannels({ projectId: 0 })

  const availableChannels = allChannels?.filter(
    ({ marketplace_id }) => !excludedChannelIds.includes(marketplace_id)
  ) ?? []
  const [channelId, setChannelId] = useState('')
  const [showInputs, setShowInputs] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const addVendorChannelCmd = useAddVendorChannelsCmd(vendorId, channelId)
  const commitAddedChannel = () => {
    channelId && addVendorChannelCmd().then((r) => onChannelAdded(r))
    setShowInputs(false)
    setChannelId('')
    setIsValid(false)
  }

  return (
    <SnowGrid container>
      {!showInputs && (
        <ScPrimaryBtn
          onClick={() => setShowInputs(true)}
          label={'Connect new channel'}
        />
      )}
      {showInputs && (
        <>
          <SnowGrid item md={8} xs={12}>
            {availableChannels && (
              <ScComboField
                meta={{
                  options: availableChannels.map(
                    ({ marketplace_id, marketplace_name }) => ({
                      label: marketplace_name.toString(),
                      id: marketplace_id.toString()
                    })
                  ),
                  optionsDef: { value: 'id', label: 'label' }
                }}
                size={'small'}
                free={false}
                // getOptionLabel={(option) => option.label}
                // getOptionSelected={(option) => option.id}
                value={channelId}
                label={'Channel Name'}
                onUpdate={({ value, isValid: _isValid }) => {
                  console.log(
                    'ScComboField onUpdate newData',
                    value,
                    _isValid,
                    typeof value
                  )
                  setIsValid(_isValid)
                  setChannelId(value)
                }}
              />
            )}
          </SnowGrid>
          <StyledButtonWrapper item md={4} >
            <ScPrimaryBtn
              style={{ marginLeft: '1em' }}
              onClick={commitAddedChannel}
              disabled={!isValid}
              label={'ATTACH'}
            />
          </StyledButtonWrapper>
        </>
      )}
    </SnowGrid>
  )
}
