const parenter = {
  get: function (target, prop, receiver) {
    if (typeof target[prop] === 'object') {
      return { ...Reflect.get(target, prop, receiver), parent: target }
    }
    return Reflect.get(target, prop, receiver)
  }
}

export const objWithParent = (obj = {}) => {
  return new Proxy(obj, parenter)
}

export default objWithParent
