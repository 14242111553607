import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toggleElementInCollection } from '../../../utils/helpers'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { selectedBundles } from './data/bundlehubData'
import ScSimpleTable from '../../../shared/components/tables/ScSimpleTable'

const ProductsTable = ({ columns, products }) => {
  const navigate = useNavigate()

  const [selectedIds, setSelectedIds] = useRecoilState(selectedBundles)
  const resetSelectedIds = useResetRecoilState(selectedBundles)
  useEffect(() => {
    return resetSelectedIds
  }, [])

  const handleRowDblClick = ({ marketplace_id, market_product_id, id }) => {
    console.log('handleRowDblClick', id)
    navigate(`/home/bundle_detail?id=${id}`)
  }
  const checkItem = (itemId) => {
    setSelectedIds((ids) => toggleElementInCollection(ids, itemId))
  }
  const handleRowClick = ({ id }) => {
    console.log('handleRowClick', id, selectedIds)
    checkItem(id)
  }
  const hasProducts = !!products.length
  if (!Array.isArray(columns)) return

  const getColumnTitle = (field) => {
    return typeof field === 'string' ? field.replace('_', ' ') : field?.title
  }
  const getColumnCode = (field) => {
    return typeof field === 'string' ? field : field?.code
  }
  const getRenderedValue = (row, field) => {
    const code = getColumnCode(field)
    const value = row?.[code]
    return typeof value === 'string'
      ? value
      : Array.isArray(value)
        ? value
          .map((item) => (item?.name ? item.name : JSON.stringify(item.name)))
          .join(', ')
        : JSON.stringify(value)
  }
  console.log('Bundle Render', selectedIds)
  return (
    <ScSimpleTable
      aria-label="ProductTable"
      selectable={true}
      preselected={selectedIds}
      keyField={'id'}
      fieldsList={columns}
      dataRows={products}
      onRowClick={handleRowClick}
      onRowDblClick={handleRowDblClick}
      getRenderedValue={getRenderedValue}
      getColumnTitle={getColumnTitle}
    />
  )
}

export default ProductsTable
