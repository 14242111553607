import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { format, formatISO, parseISO } from 'date-fns'
import { ScFieldLabel } from '../../../LayoutComponents'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

/**
 *
 * @param onUpdate - callback function {key,value}
 * @param mb - margin-bottom, in 'px', number
 * @param inputWidth - max-width for text field, in '%', number
 * @param labelPlacement - where label render, can be  'start', 'end'
 * @param value
 * @param code - id for label and input connection
 * @param label
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const StyledDatePickerWrapper = styled.div`
  width: 100%;  
  .react-datepicker-wrapper{
    display: block;
  }
  .react-datepicker__input-container{
    display: block;
    input{
      width: 100%;
      padding: 9px 10px;
      min-height: 40px;
      box-sizing: border-box;
      border: none;
      text-align: left;
      color: ${({ theme }) => theme.mainPalette.grey[200]} !important;
      font-size: ${({ theme }) => theme.fontSize.main};
    }
  }
`

export const scDateFormat = (date, dateFormat = 'yyyy-MM-dd') => {
  console.log('scDateFormat', date, date.toISOString(), format(date, dateFormat), formatISO(date, { representation: 'date' }),)
  return format(date, dateFormat)
}
export const scDateIsoFormat = (date) => {
  console.log('scDateIsoFormat', date, date.toISOString(), formatISO(date, { representation: 'date' }),)
  return formatISO(date, { representation: 'date' })
}

const ScDateField = ({
  onUpdate,
  onClick,
  value = '',
  code = '',
  label = '',
  helperText = '',
  tooltipText = '',
  popperClassName = '',
  popperPlacement = 'bottom',
  labelPlacement = 'start',
  dateFormat = 'yyyy-MM-dd',
  placeholderText = '(Not set)',
  updateDelay = 500,
  inputWidth = 100,
  fontSize='',
  mb = 0,
  isGroupField = false,
  isInvalid = false,
  required = false,
  isClearable = false,
  inline = false,
  meta = {},
  ...props
}) => {
  const [dateValue, setDateValue] = useState(value ? parseISO(value) : null)
  const timeOutId = useRef(null)

  const handleChange = (date) => {
    const formatedDate = date && scDateIsoFormat(date)

    const previousDate = dateValue && scDateIsoFormat(dateValue)

    const datesAreDifferent = formatedDate !== previousDate

    datesAreDifferent && setDateValue(date)
    if (!onUpdate) return
    // console.log('handleChange dateValue=', dateValue, ' previousDate=', previousDate, ' formatedDate=', formatedDate)
    const updateValue = () => {
      // console.log('handleChange onUpdate formatedDate=', formatedDate)
      onUpdate({ value: formatedDate, key: code })
    }

    timeOutId.current && clearTimeout(timeOutId.current)
    timeOutId.current = updateDelay
      ? setTimeout(() => updateValue(), updateDelay)
      : updateValue()
  }
  useEffect(() => {
    setDateValue(value ? new parseISO(value) : null)
  }, [value])
  const dateToOpen = dateValue ?? new Date()
  console.log('ScDateField ', { code, value, dateValue, dateToOpen, label })

  return (
    <ScFieldLabel
      label={label}
      labelPlacement={labelPlacement}
      isGroupField={isGroupField}
      inputWidth={inputWidth}
      isInvalid={isInvalid}
      code={code}
      helperText={helperText}
      tooltipText={tooltipText}
      fontSize={fontSize}
      mb={mb}
    >
      <StyledDatePickerWrapper>
        <DatePicker
          id={code}
          selected={dateValue}
          onChange={handleChange}
          dateFormat={dateFormat}
          popperPlacement={popperPlacement}
          popperClassName={popperClassName}
          isClearable={isClearable}
          openToDate={dateToOpen}
          value={value}
          inline={inline}
          placeholderText="(Not set)"
        />
      </StyledDatePickerWrapper>
    </ScFieldLabel>
  )

}

export default ScDateField

ScDateField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'end']),
  popperPlacement: PropTypes.oneOf(['top', 'start', 'end']),
  isGroupField: PropTypes.bool,
  isInvalid: PropTypes.bool,
  required: PropTypes.bool,
  updateDelay: PropTypes.number,
  code: PropTypes.string,
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.number,
  onUpdate: PropTypes.func,
  isClearable: PropTypes.bool,
  dateFormat: PropTypes.string,
  placeholderText: PropTypes.string
}


