import React from 'react'
import { ScWhiteButton } from '../../buttons'
import { SnowPublishIcon } from '../../icons'

const ScSaveTextButton = ({
  onClick = () => console.log('ScSaveBtn clicked')
}) => {
  return (
    <ScWhiteButton
      onClick={onClick}
      icon={() => <SnowPublishIcon />}
      label={'Save'}
    />
  )
}

export default ScSaveTextButton
