import { useState } from 'react'

const brandLogos = {
  cbs: '../../resources/test/channels/cbs1.png',
  'cbs-cw': '../../resources/test/channels/cbs1.png',
  'cbs-showtime': '../../resources/test/channels/cbs-showtime.png',
  cnbc: '../../resources/test/channels/cnbc.png',
  msbc: '../../resources/test/channels/msnbc.png',
  nbc: '../../resources/test/channels/nbc.jpg',
  fox: '../../resources/test/channels/fox.png',
  get: '../../resources/test/channels/get1.png',
  dell: '../../resources/test/channels/dell.png'
}
const platformLogos = {
  magento1: '../../resources/test/platforms/magento.jpg',
  shopify: '../../resources/test/platforms/shopify.png',
  ebay: '../../resources/test/platforms/ebay.png',
  amazon: '../../resources/test/platforms/amazon.png'
}
const useLogos = () => {
  const [logosArray, setLogosArray] = useState({
    ...brandLogos,
    ...platformLogos
  })
  return logosArray
}
export { brandLogos, platformLogos, useLogos }
