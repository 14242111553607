import React, { useEffect, useRef, useState } from 'react'
import SnowEditableField from '../../../../../../shared/components/SnowEditableField'
import { getGoogleMapsURL } from '../CustomerInformation'
import { updateOrderAddress } from '../../../../infrastructure/fetches'
import { getAddressFieldsForValues } from './addressConfigs'
import {
  ScEditButton,
  ScSaveButton
} from '../../../../../../shared/components/buttons'
import {
  SnowTypography,
  SnowList,
  SnowListItem
} from '../../../../../../shared/components/snowComponents'
import { SidebarBlockTitleView, SidebarSubtitleView } from '../_styled'
import styled from 'styled-components'

const StyledListItem = styled(SnowListItem).attrs({
  disableGutters: true,
  dense: true
})`
  & label {
    font-size: ${({ theme }) => theme.fontSize.middle};
  }
  & .MuiTypography-root {
    font-size: ${({ theme }) => theme.fontSize.middle};
  }
`

const StyledErrorMesage = styled(SnowTypography).attrs({
  variant: 'caption'
})`
  margin-top: -20px;
  margin-bottom: 20px;
  display: block;
  font-family: inherit;
  color: ${({ theme }) => theme.mainPalette.warning.secondary};
`

const StyledLinkBlock = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.secondary};
`

const ScAddress = ({ orderId, orderAddress, type = 'shipping', onUpdate }) => {
  const [currentEdit, setCurrentEdit] = useState('')
  const [editMode, setEditMode] = useState(false)

  const [addressToEdit, setAddressToEdit] = useState({})

  const [error, setError] = useState('')
  const validationString = useRef('')

  const [addressFields, setAddressFields] = useState([])

  useEffect(() => {
    const { name, ...cleanedAddress } = orderAddress
    console.log('ScAddress', type, orderAddress, name, cleanedAddress)
    setAddressToEdit(cleanedAddress)
    setAddressFields(
      getAddressFieldsForValues(cleanedAddress, orderAddress, type)
    )
  }, [orderAddress])

  // console.log('addressFields!', addressFields)
  const updateAddressField = ({ code, value }) => {
    console.log('address', code, value)
    const updatedAddress = { ...addressToEdit, [code]: value }
    setAddressToEdit(updatedAddress)
    onUpdate(updatedAddress)
  }
  const onEditEnd = ({ code, value }) => {
    console.log(`${type}Address onEditEnd`, { code, value })
    updateAddressField({ code, value })
  }
  const onEditStart = (fieldCode) => {
    console.log('onEditStart', fieldCode)
    setCurrentEdit(fieldCode)
  }
  const toggleEditMode = () => {
    setEditMode(!editMode)
    console.log(editMode)
  }
  const onAddressSave = async () => {
    try {
      console.log('onAddressSave', addressToEdit)
      setEditMode(false)

      await updateOrderAddress({
        type: type,
        orderID: orderId,
        addressObj: addressToEdit
      })
    } catch (e) {
      setEditMode(true)
      setError(`Can't save address because of ${e.message}`)
    }
  }

  useEffect(() => {
    setError('')
  }, [addressToEdit])

  return (
    <address>
      <SidebarBlockTitleView>
        <SidebarSubtitleView>{type} address </SidebarSubtitleView>
        {/*<StyledTitle>{type} address </StyledTitle>*/}
        {!editMode ? (
          <ScEditButton size={30} onClick={toggleEditMode} />
        ) : (
          <ScSaveButton
            size={30}
            aria-label="Save"
            tooltip="Save"
            onSave={() => onAddressSave()}
          />
        )}
      </SidebarBlockTitleView>
      {!!error && (
        <StyledErrorMesage variant={'caption'} ref={validationString}>
          {error}
        </StyledErrorMesage>
      )}
      <SnowList>
        {addressFields?.length
          ? addressFields.map((item) => (
              <StyledListItem key={item.label}>
                <SnowEditableField
                  {...item}
                  noPadding={true}
                  inputWidth={editMode ? 100 : 70}
                  isGroupField={true}
                  onEditEnd={onEditEnd}
                  onEditStart={onEditStart}
                  onUpdate={updateAddressField}
                  edit={editMode}
                  updateDelay={500}
                  variant={'outlined'}
                  fontSize={'0.9rem'}
                />
              </StyledListItem>
            ))
          : null}
      </SnowList>
      <StyledLinkBlock>
        <a
          href={getGoogleMapsURL(orderAddress)}
          rel="noopener noreferrer"
          target="_blank"
        >
          View map
        </a>
      </StyledLinkBlock>
    </address>
  )
}

export default ScAddress
