import React from 'react'
import { ScWhiteButton } from '../../../../../../shared/components/buttons'
import { ReactComponent as RevalidateImg } from '../../../../../app/images/SVGs/revalidate.svg'
import PropTypes from 'prop-types'

const RevalidateOrderButton = (props) => {
  const handleClick = () => {
    props.toggleShowRevalidateModal()
  }
  return (
    <ScWhiteButton
      onClick={handleClick}
      label={'Revalidate'}
      icon={() => <RevalidateImg />}
    />
  )
}

RevalidateOrderButton.propTypes = {
  toggleShowRevalidateModal: PropTypes.func.isRequired
}

export default RevalidateOrderButton
