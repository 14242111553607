import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import {
  SnowBox,
  SnowGrid,
  SnowTable,
  SnowTableRow,
  SnowTableHead,
  SnowTableBody,
  SnowTableCell
} from "../../../../shared/components/snowComponents";
import { SnowCheckIcon } from "../../../../shared/components/icons";
import { ScTextField, ScDropdownField } from "../../../../shared/components/SnowEditableField/baseFields";
import { ScBasicModal } from "../../../../shared/components/modals";
import {
  ScMultiCheckButton
} from "../../../../shared/components/buttons";
import { useCurrentOrder } from "../data/currentOrderState";
import { toggleElementInCollection } from "../../../../utils/helpers";
import { createVendorOrderApi } from "../../infrastructure/fetches";
import { fetchProjectVendors } from "../../../integrations/infrastructure/v1/apis";
import { currentMessageState } from "../../../../shared/service/Message/data/currentMessageState";
import {
  BULK_SELECTION_STATE,
  getBulkSelectionStatus
} from "../../../../shared/components/BulkComponents/infrastructure/helpers";
import styled, { css } from "styled-components";

const StyledRow = css`
  ${({ theme }) => `
    .MuiTableCell-root {
      border: none;
      padding: ${theme.spacing.main}; 
      cursor: pointer;
    }
    &.Mui-selected {
      &.MuiTableRow-root{
        background: ${theme.mainPalette.grey[50]};
        :hover {
          background: ${theme.mainPalette.color.secondary};
        }
      }
      .MuiSvgIcon-root path {
        color: ${theme.mainPalette.color.secondary}; 
      }
    }
    :hover {
      &.MuiTableRow-root{
        background: ${theme.mainPalette.color.secondary};
      }
      .MuiSvgIcon-root path {
        color: ${theme.mainPalette.color.contrast}; 
      }
      .MuiTableCell-root {
        color: ${theme.mainPalette.color.contrast};
      }
    }
  `}
`;

const StyledTableHeadRow = styled(SnowTableRow)`
  ${StyledRow}
  ${({ theme }) => css`
    &.MuiTableRow-root{
      border-bottom: ${theme.border.main};
    }`
}
`;

const StyledTableBodyRow = styled(SnowTableRow)`
  ${StyledRow}
  &.MuiTableCell-root {
    color: ${({ theme }) => theme.mainPalette.grey.middle};
  }
`;


const HeaderForm = ({
  vendor,
  projectVendors,
  setVendorOrderNumber,
  vendorOrderNumber,
  selectedVendor,
  setSelectedVendor
}) => {
  return (
    <>
      {vendor?.vendorCode ? (
        <ScTextField
          size={"small"}
          label={"Vendor Order Number"}
          variant={"outlined"}
          onUpdate={({ value }) => setVendorOrderNumber(value)}
        >
          {vendorOrderNumber}
        </ScTextField>
      ) : (
        <ScDropdownField
          label="Select vendor"
          value={selectedVendor}
          onUpdate={({ value }) => setSelectedVendor(value)}
          inputProps={{
            name: "vendor",
            id: "vendor"
          }}
          meta={{
            options: projectVendors?.length ? projectVendors.map((vendor) => ({
              label: vendor.name, value: vendor.code
            })) : []
          }}
        />
      )}
    </>
  );
};

const ContentElement = ({
  orderVendorItems,
  toggleSelectedVendor,
  selectionState,
  orderModel,
  getItemVendorOrderId,
  selectedIds,
  setSelectedIds,
  vendor,
  setVendorOrderNumber,
  vendorOrderNumber
}) => {
  const tableHeadData = [
    { title: <ScMultiCheckButton selectionState={selectionState} /> },
    { title: "Product Name" },
    { title: "Vendor Order" }
  ];
  return (
    <>
      <SnowTable>
        <SnowTableHead>
          <StyledTableHeadRow
            onClick={toggleSelectedVendor}
          >
            {tableHeadData.map(({ title }, index) => (
              <SnowTableCell key={`${title}-${index}`}>{title}</SnowTableCell>
            ))}
          </StyledTableHeadRow>
        </SnowTableHead>
        <SnowTableBody>
          {orderVendorItems.map(({ id, name, snowaxis_product_name }) => {
            console.log("vo.order_items", orderModel.vendorOrders);
            const vendorOrderId = getItemVendorOrderId(id);
            const toggleSelectedItem = () => {
              setSelectedIds(toggleElementInCollection(selectedIds, id));
            };
            const isSelected = selectedIds?.includes(id);
            return (
              <StyledTableBodyRow
                key={id}
                selected={isSelected}
                disabled={vendorOrderId}
                {...(!vendorOrderId && { onClick: toggleSelectedItem })}
              >
                <SnowTableCell padding={"checkbox"}>
                  {isSelected && (
                    <SnowCheckIcon fontSize={"small"} />
                  )}
                </SnowTableCell>
                <SnowTableCell>
                  {name || snowaxis_product_name}
                </SnowTableCell>
                <SnowTableCell>
                  {vendorOrderId ?? "-"}
                </SnowTableCell>
              </StyledTableBodyRow>
            );
          })}
        </SnowTableBody>
      </SnowTable>
      {!vendor?.vendorCode && (
        <SnowBox p={2}>
          <SnowGrid container alignItems="flex-start" spacing={2}>
            <SnowGrid item xs={6}>
              <ScTextField
                size={"small"}
                label={"Vendor Order Number"}
                variant={"outlined"}
                onUpdate={({ value }) => setVendorOrderNumber(value)}
              >
                {vendorOrderNumber}
              </ScTextField>
            </SnowGrid>
          </SnowGrid>
        </SnowBox>
      )}
    </>
  );
};

const CreateVendorOrderModal = ({ vendor, open, onClose }) => {
  const orderModel = useCurrentOrder();
  const setMessage = useSetRecoilState(currentMessageState);
  const { orderItems, projectId } = orderModel;

  const orderVendorItems = vendor?.vendorCode
    ? orderItems.filter((item) => item.vendor === vendor?.vendorCode)
    : orderItems;

  const [projectVendors, setProjectVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(vendor?.vendorCode ?? "");
  const [selectedIds, setSelectedIds] = useState([]); //selectedItems
  const [vendorOrderNumber, setVendorOrderNumber] = useState("");
  const [isReadyToCreate, setIsReadyToCreate] = useState(false);

  const getDtoObj = () => {
    return {
      order_item_ids: selectedIds.map((id) => id),
      order_vendor_id: vendor.orderVendorId,
      vendor_order_number: vendorOrderNumber
    };
  };

  /**
   * calculate completeness status of selected order_items for certain {vendor}
   * @param vendor
   * @returns {number} 0|1|2, where
   * 0 - nothing is selected, 1 - partial selection, 2 - all items selection
   */
  const itemsSelectionStatus = () => {
    return getBulkSelectionStatus(orderVendorItems, selectedIds);
  };
  const getItemVendorOrderId = (id) =>
    orderModel.vendorOrders.find((vo) => vo.orderItems?.includes(id))?.id;

  const createVendorOrder = () => {
    isReadyToCreate &&
    createVendorOrderApi(getDtoObj()).then((res) => {
      console.log("createVendorOrderApi res", res);
      typeof res.message === "string" &&
      setMessage({ message: `${res.message}`, severity: "success" });
      onClose();
    });
  };

  useEffect(() => {
    !vendor &&
    fetchProjectVendors({ projectId }).then((res) => {
      setProjectVendors(res.data);
    });
  }, []);

  useEffect(() => {
    const willReady = selectedIds.length && vendor.orderVendorId;
    if (isReadyToCreate === willReady) return;
    setIsReadyToCreate(willReady);
  }, [selectedIds, vendor]);

  useEffect(() => {
    setSelectedIds(
      orderVendorItems
        .filter((item) => !getItemVendorOrderId(item.id))
        .map(({ id }) => id)
    );
  }, [selectedVendor]);

  const toggleSelectedVendor = () => {
    console.log("itemsSelectionStatus()", itemsSelectionStatus());
    if (itemsSelectionStatus() === BULK_SELECTION_STATE.All) setSelectedIds([]);
    else
      setSelectedIds(
        orderVendorItems
          .filter((item) => !getItemVendorOrderId(item.id))
          .map(({ id }) => id)
      );
  };
  const selectionState = itemsSelectionStatus();

  return (
    <ScBasicModal
      open={open}
      onClose={onClose}
      onConfirm={createVendorOrder}
      title={"Create Vendor Order"}
      headForm={<HeaderForm vendor={vendor} setVendorOrderNumber={setVendorOrderNumber}
                            vendorOrderNumber={vendorOrderNumber} selectedVendor={selectedVendor}
                            setSelectedVendor={setSelectedVendor} projectVendors={projectVendors} />}
      content={<ContentElement orderVendorItems={orderVendorItems} toggleSelectedVendor={toggleSelectedVendor}
                               selectionState={selectionState} orderModel={orderModel}
                               getItemVendorOrderId={getItemVendorOrderId} selectedIds={selectedIds}
                               setSelectedIds={setSelectedIds} vendor={vendor}
                               setVendorOrderNumber={setVendorOrderNumber} vendorOrderNumber={vendorOrderNumber} />}
      labelForConfirmBtn={"Create"}
      onConfirmProps={{ disabled: !isReadyToCreate }}
    />
  );
};

export default CreateVendorOrderModal;
