import React from 'react'
import { SnowBox, SnowGrid } from '../../snowComponents'

const ScPageContent = ({ children, ...props }) => {
  return (
    <SnowBox className={'ScPageContent'} {...props}>
      <SnowGrid spacing={2} container direction="column">
        {children}
      </SnowGrid>
    </SnowBox>
  )
}

export default ScPageContent
