import { useEffect, useState } from 'react'
import { useEntityCommand } from '../../../../../infrastructure/api/v2/useEntityApi'
import useFetchedResult from '../../../../../infrastructure/api/v2/useFetchedResult'
import { useSnowShippingMethods } from '../../../../settings/SnowShippingMethods/data'
import { useRecoilValue } from 'recoil'
import useVendors, { vendorWithId } from '../../../hooks/useVendors'
import objWithParent from '../../../../../utils/parenter'
import { responseHandler } from '../../../../../infrastructure/api/utils'
import useProjects from '../../../hooks/useProjects'
import useChannels from '../../../hooks/useChannels'
import { fetchProjectChannels } from '../../../infrastructure/v1/apis'

const CFG_VENDOR_SHIPPING_METHODS = objWithParent({
  url: '/portal/v1/vendorShippingMethods',
  FETCH: {
    urlType: 'root',
    url: '/portal/v1/vendorShippingMethods',
    method: 'GET',
    caller: 'CFG_VENDOR_SHIPPING_METHODS LIST!',
    validator: () => true
  },
  CREATE: {
    urlType: 'root',
    url: 'create',
    method: 'post',
    caller: 'VENDOR_SHIPPING_METHODS CREATE!',
    validator: ({
      platform_id,
      snow_shipping_method_id,
      shop_id,
      sales_channel_id,
      type,
      value
    }) => true
  },
  BULK_UPDATE: {
    urlType: 'root',
    url: 'update',
    method: 'post',
    caller: 'VENDOR_SHIPPING_METHODS BULK_UPDATE!',
    validator: (methods) => true
  },
  ITEM: {
    urlType: 'root',
    url: '/',
    method: 'get',
    caller: 'VENDOR_SHIPPING_METHODS ITEM!',
    validator: ({ id }) => !!id
  },
  ITEM_REMOVE: {
    urlType: 'root',
    url: 'remove',
    method: 'get',
    caller: 'VENDOR_SHIPPING_METHODS ITEM_REMOVE!',
    validator: ({ id }) => !!id
  },
  ITEM_UPDATE: {
    urlType: 'root',
    url: 'update',
    method: 'post',
    caller: 'VENDOR_SHIPPING_METHODS ITEM_UPDATE!',
    validator: ({ snow_shipping_method_id, sales_channel_id, type, value }) =>
      true
  }
})
export const useVendorFields = ({ vendorId }) => {
  const snowMethods = useSnowShippingMethods()
  const vendor = useRecoilValue(vendorWithId(vendorId))
  const { data: projects } = useProjects()
  const [projectId, setProjectId] = useState(0)
  const { data: channels } = useChannels({ projectId, vendorId })
  console.log('useVendorFields', vendorId, vendor, projects)
  const { data_field_scheme: dataFieldScheme } = vendor
  console.log('recoil vendor', vendorId, dataFieldScheme)

  const getProjectChannels = async (projectId) => {
    const channels = await fetchProjectChannels({ projectId })
    console.log('getProjectChannels', channels)
    return channels
  }
  const fields = [
    {
      code: 'id',
      visible: false,
      title: 'Id',
      editType: 'text',
      editable: false,
      tooltip: 'Serverside Entity unique id',
      default: 0,
      request_param: 'id'
    },
    {
      code: 'snowShippingMethodId',
      visible: true,
      disablePadding: false,
      title: 'Snow Method',
      editType: 'select',
      options: snowMethods.map(({ id, title }) => ({ [id]: title })),
      tooltip: 'Associated Snow shipping method',
      required: true,
      default: '',
      request_param: 'snow_shipping_method_id'
    },
    {
      code: 'vendorId',
      visible: false,
      title: 'Vendor Id',
      editType: 'text',
      editable: false,
      tooltip: 'Vendor Id',
      default: 0,
      request_param: 'vendor_id'
    },

    {
      code: 'areaGroupId',
      visible: true,
      title: 'Area Group',
      editType: 'text',
      editable: true,
      tooltip: 'Area Group for Vendor shipping method',
      fullWidth: false,
      default: 1,
      request_param: 'area_group_id'
    },
    {
      code: 'projectId',
      visible: true,
      title: 'Project Id',
      editType: 'select',
      tooltip: 'Project Id',
      fullWidth: false,
      options: [
        { 0: 'General' },
        ...projects.map(({ id, name }) => ({ [id]: name }))
      ],
      default: '0',
      request_param: 'shop_id'
    },
    {
      code: 'salesChannelId',
      visible: true,
      title: 'Channel Id',
      editType: 'combo',
      tooltip: 'Channel Id',
      fullWidth: true,
      options: channels.reduce(((acc, { marketplace_id, marketplace_name }) => ({
        ...acc, [marketplace_id]: marketplace_name
      })), { 0: 'General' }),
      default: '0',
      request_param: 'sales_channel_id',
      minWidth: 15,
      dependentOnFieldCode: 'projectId',
      updaterFunc: async (
        projectId,
        callback = (r) => console.log('updaterFunc callback r', r)
      ) => {
        const { data: channels } = await getProjectChannels(projectId)
        console.log('updaterFunc channels', channels)
        callback && callback(channels)
        return {
          options: [
            { 0: 'General' },
            ...channels.map(({ marketplace_id, marketplace_name }) => ({
              [marketplace_id]: marketplace_name
            }))
          ]
        }
      }
    },
    {
      code: 'vendorShippingData',
      visible: false,
      disablePadding: false,
      title: 'vendorShippingData',
      editType: 'dataFieldScheme',
      tooltip: 'Vendor Shipping method data',
      required: false,
      default: [],
      request_param: 'data'
    }
  ]
  const dataFields =
    (dataFieldScheme && dataFieldScheme.filter(({ code }) => code).map(
      ({ code, required, type: editType, values, ...rest }) => ({
        code,
        required,
        editType,
        title: code,
        visible: true,
        editable: true,
        fullWidth: true,
        tooltip: 'Vendor Shipping method data',
        default: '',
        options: values ?? [],
        ...rest
      })
    )) ||
    []
  return [...dataFields, ...fields]
}

const entityToRequest = ({
  vendorId = 0,
  snowShippingMethodId,
  areaGroupId = 1,
  projectId = 0,
  salesChannelId = 0,
  vendorShippingData = []
}) => ({
  snow_shipping_method_id: snowShippingMethodId,
  sales_channel_id: salesChannelId,
  vendor_id: vendorId,
  area_group_id: areaGroupId,
  data: vendorShippingData,
  shop_id: projectId
})
const entityFromRequest = (
  {
    area_group_id,
    data,
    id,
    sales_channel_id,
    shop_id = 0,
    snow_shipping_method_id,
    vendor_id,
    ...rest
  },
  fields
) => {
  console.log('vendors shippingMethods entityFromRequest ', data, rest)
  return {
    id: id, //by serverside, r/o, required, visible
    snowShippingMethodId: snow_shipping_method_id, //required, r/w
    salesChannelId: sales_channel_id, //optional, r/o , [] by default
    vendorId: vendor_id, //required, r/w
    areaGroupId: area_group_id, // required, r/o, 1 by default
    ...data,
    projectId: shop_id, //optional, r/o 0 by default
    notHandled: rest //r/o
  }
}

export const generateVendorShippingMapping = ({ vendorId }, fields = []) => {
  const mappingStub = fields.reduce(
    (acc, item) => ({ ...acc, [item.code]: item.default }),
    {}
  )
  console.log(
    'generateVendorShippingMapping fields,mappingStub',
    fields,
    mappingStub
  )

  return { ...mappingStub, vendorId }
}

//todo: if necessary data to create entity are not ready - provide null instead of createCommand
export const useVendorShippingMethodCreate = (modelToCreate) => {
  const { vendorId } = modelToCreate

  const { data_field_scheme: dataFieldScheme } = useRecoilValue(
    vendorWithId(vendorId)
  )

  const vendorShippingData = dataFieldScheme.reduce(
    (acc, { code }) => ({ ...acc, [code]: modelToCreate[code] }),
    {}
  )
  const dataToCreate = {
    data: [entityToRequest({ ...modelToCreate, vendorShippingData })]
  }
  console.log(
    'modelToCreate,dataFieldScheme,dataToCreate',
    modelToCreate,
    dataFieldScheme,
    dataToCreate
  )

  const createCmd = useEntityCommand(
    0,
    {
      ...CFG_VENDOR_SHIPPING_METHODS.CREATE,
      params: dataToCreate
    },
    (r) =>
      responseHandler(
        r,
        (data) => {
          const { data: createdItems, success, message } = data
          return {
            data: createdItems.map((item) => entityFromRequest(item)),
            success,
            message
          }
        },
        (error) => {}
      )
  )

  return createCmd
}
export const useVendorShippingMethodUpdate = ({ id, ...modelToUpdate }) => {
  const { vendorId } = modelToUpdate
  const { data_field_scheme: dataFieldScheme } = useRecoilValue(
    vendorWithId(vendorId)
  )
  const vendorShippingData = dataFieldScheme.reduce(
    (acc, { code }) => ({ ...acc, [code]: modelToUpdate[code] }),
    {}
  )
  const dataToUpdate = {
    data: entityToRequest({ ...modelToUpdate, vendorShippingData })
  }

  console.log('modelToUpdate, dataToUpdate', modelToUpdate, dataToUpdate)
  const updateCmd = useEntityCommand(
    id,
    {
      ...CFG_VENDOR_SHIPPING_METHODS.ITEM_UPDATE,
      params: dataToUpdate
    },
    (r) =>
      responseHandler(
        r,
        (data) => {
          const { data: createdItems, success, message } = data
          return {
            data: createdItems.map((item) => entityFromRequest(item)),
            success,
            message
          }
        },
        (r) => {
          return r
        }
      )
  )
  return updateCmd
}
export const useVendorShippingMethodRemove = ({ id }) => {
  console.log('idToRemove', id)
  const removeCmd = useEntityCommand(id, {
    ...CFG_VENDOR_SHIPPING_METHODS.ITEM_REMOVE,
    params: { id }
  })
  return removeCmd
}

export const useVendorShippingMethodsModel = ({ vendorId }) => {
  const { data } = useFetchedResult({
    ...CFG_VENDOR_SHIPPING_METHODS.FETCH,
    params: { vendor_id: vendorId }
  })
  const fields = useVendorFields({ vendorId })
  const [shippingMappings, setShippingMappings] = useState([])

  useEffect(() => {
    const shippingMethods =
      data &&
      data.data &&
      data.data.map((item) => entityFromRequest(item, fields))
    shippingMethods && setShippingMappings(shippingMethods)
  }, [data])
  console.log('useVendorShippingMethodsModel data', data)

  return { data: shippingMappings }
}
