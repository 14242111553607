import React from 'react'
import { SnowBox } from '../../../../../shared/components/snowComponents'
import ScSpinner from '../../../../../shared/components/ScSpinner/ScSpinner'
import Error from '../../../../app/Error'
import { integrationCqrsRequestModel } from '../../../data/integrationDeterminant'
import usePlatforms from '../../../hooks/usePlatforms'
import useChannels from '../../../hooks/useChannels'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import SnowEntityCardsList from '../../../../../shared/components/widgets/SnowEntityCardsList'

const ChannelChooser = (props) => {
  const [integrationParams, setIntegrationParams] = useRecoilState(
    integrationCqrsRequestModel
  )
  console.log('channelChooseer integrationParams', integrationParams)
  const {
    data: channels,
    isLoading,
    loadingError
  } = useChannels({ projectId: integrationParams.project })

  const platforms = usePlatforms()
  const navigate = useNavigate()

  //map raw marketplace data to channel code structure
  const normalizedChannels = channels.map((raw) => {
    const { marketplace_name, marketplace_id, shop_id, platform_id } = raw

    const platformName = !platforms.length
      ? platform_id
      : platforms.find((item) => item.id === platform_id).name
    return {
      name: marketplace_name,
      id: marketplace_id,
      projectId: shop_id,
      platformId: platform_id,
      description: `(${platformName})`
    }
  })
  console.log('channelChooseer', { normalizedChannels })

  const onItemSettings = (item) => {
    console.log('ChannelChooser onItemSettings', item)
    if (!item) return
    const fullItem = normalizedChannels.find(({ id }) => id === item.id)
    setIntegrationParams((oldParams) => ({
      ...oldParams,
      channel: item.id
    }))
    // setIntegrationModel((oldParams) => ({ ...oldParams, channel: fullItem }))
    navigate(`${item.id}/settings`)
  }
  const onChannelSelect = (item) => {
    console.log('channel chooser', item)
    setIntegrationParams((oldValues) => ({ ...oldValues, channel: item.id }))
  }

  const selectedItem = integrationParams.channel

  return (
    <SnowBox p={1}>
      {loadingError && <Error error={loadingError} />}
      {isLoading && !channels.length ? (
        <ScSpinner />
      ) : (
        <SnowEntityCardsList
          data={normalizedChannels}
          onItemSelect={onChannelSelect}
          onItemConfirmed={onItemSettings}
          selectedItem={selectedItem}
        />
      )}
    </SnowBox>
  )
}

export default ChannelChooser
