import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { format, formatISO, isDate } from 'date-fns'
import { SnowClickAwayListener } from '../../../snowComponents'
import { ScFieldLabel } from '../../../LayoutComponents'
import { SnowArrowForwardIcon } from '../../../icons'
import styled from 'styled-components'

const DatePickerBoxView = styled.div`
  position: absolute;
  z-index: 1000;

  .react-datepicker__day {
    width: 1.6rem;
    line-height: 1.6rem;
  }
  .react-datepicker__day--keyboard-selected:not(
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range
    ) {
    background: inherit;
    color: inherit;
    text-decoration: inherit;
  }
`

const DateRangeView = styled.div`
  padding: 6px 3px;
  min-height: 37px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  align-items: center;
  justify-content: space-between;
  font-size: ${({theme, fontSize})=>fontSize?fontSize:theme.fontSize.main};
  text-align: center;
`

const DateRangeStartView = styled.div`
  grid-column: 1/2;
`

const DateRangeArrowView = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: 15px;
  }
`
const DateRangeEndView = styled.div`
  grid-column: 3/4;
`

//todo would be good to specify different standards for date formats in different contexts
const getFormattedDate = (date, dateFormat = 'P') => {
  const defaultDate = '________'
  try {
    return date && isDate(new Date(date))
      ? format(date, dateFormat)
      : defaultDate
  } catch (e) {
    console.log(
      'getFormattedDate exception e',
      date,
      isDate(date),
      typeof date,
      e?.message
    )
  }
  return defaultDate
}

const ScDateRange = ({
  code,
  label = '',
  value = { start: '', end: '' },
  inputFontSize='',
  edit = false,
  onUpdate
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const { start, end } = value
  // console.log('ScDateRange  { start, end }', { start, end })
  const [startDate, setStartDate] = useState(start ? new Date(start) : null)

  //trick to handle react-data-picker bugs: not working events
  const [checkRange, setCheckRange] = useState(false)
  const [endDate, setEndDate] = useState(end ? new Date(end) : null)
  // console.log('ScDateRange  { startDate, endDate }', { startDate, endDate })

  const handleCalendarClose = () => console.log('ScDateRange Calendar closed')
  const handleCalendarOpen = () => console.log('ScDateRange Calendar opened')

  useEffect(() => {
    console.log('useEffect ScDateRange value', value)
    const { start, end } = value
    // if (typeof start === 'undefined' || typeof end === 'undefined') return

    start
      ? setStartDate(isDate(start) ? start : new Date(start))
      : setStartDate(null)
    end ? setEndDate(isDate(end) ? end : new Date(end)) : setEndDate(null)
  }, [value])

  useEffect(() => {
    if (!checkRange) return

    console.log('ScDateRange checkRange', startDate, endDate)
    ;(startDate || endDate) &&
      onUpdate({
        code,
        value: { start: formatISO(startDate), end: formatISO(endDate) }
      })
    setCheckRange(false)
    setIsEdit(false)
  }, [checkRange])

  const handleDateUpdate = (dates) => {
    if (typeof dates === 'undefined') return

    console.log(
      'ScDateRange handleDateUpdate dates',
      value,
      dates,
      typeof dates
    )
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
    start && end && setCheckRange(true)
  }

  console.log(
    'ScDateRange DATE RANGE!! ',
    value,
    startDate,
    isDate(startDate),
    endDate,
    isDate(endDate)
  )
  return (
    <>
      {isEdit ? (
        <SnowClickAwayListener
          onClickAway={(evt) => {
            console.log('ScDateRange onClickAway!', evt)
            setCheckRange(true)
          }}
        >
          <DatePickerBoxView>
            <DatePicker
              selected={startDate}
              onCalendarOpen={handleCalendarOpen}
              onCalendarClose={handleCalendarClose}
              onChange={handleDateUpdate}
              // onSelect={(...dates) => console.log('dates onSelect', dates)}
              startDate={startDate}
              endDate={endDate}
              monthsShown={2}
              selectsRange={true}
              inline
              isClearable={true}
              portalId="root-portal"
              withPortal
              shouldCloseOnSelect={false}
            />
          </DatePickerBoxView>
        </SnowClickAwayListener>
      ) : (
        <ScFieldLabel label={label}>
          <DateRangeView onClick={() => setIsEdit(true)} fontSize={inputFontSize}>
            <DateRangeStartView>
              {getFormattedDate(startDate)}
            </DateRangeStartView>
            <DateRangeArrowView>
              <SnowArrowForwardIcon
                fontSize={'small'}
                style={{ color: 'lightgray' }}
              />
            </DateRangeArrowView>
            <DateRangeEndView>{getFormattedDate(endDate)}</DateRangeEndView>
          </DateRangeView>
        </ScFieldLabel>
      )}
    </>
  )
}

export default ScDateRange
