import React from 'react'
// import PropTypes from 'prop-types';

import OrdersTable from './components/OrdersTable2'

const OrderHubGrid = (props) => <OrdersTable data={props.data} />

// OrderHubGrid.propTypes = {
//   // data: PropTypes.instanceOf(Array).isRequired,
// };

export default OrderHubGrid
