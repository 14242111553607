import { differenceInCalendarDays } from 'date-fns'

export const getShippingColor = (shippingDate) => {
  const colorStates = [
    { state: 'alert', color: 'red' },
    { state: 'warning', color: 'orange' },
    { state: 'info', color: '' }
  ]
  const severityLevel = Math.max(
    Math.min(
      1 + differenceInCalendarDays(new Date(shippingDate), new Date()),
      2
    ),
    0
  )
  console.log(
    'today, shippingDate, difference, severity, color',
    new Date(),
    shippingDate,
    differenceInCalendarDays(new Date(shippingDate), new Date()),
    severityLevel,
    colorStates[severityLevel]?.color
  )
  return colorStates[severityLevel]?.color
}
