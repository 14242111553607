import {
  atom,
  atomFamily,
  selector,
  useRecoilState,
  useRecoilValue
} from 'recoil'
import { useFetchAreaGroupsCmd } from '../infrastructure/areaApi'
import { useEffect, useState } from 'react'

const areaGroupsLoadedPage = atomFamily({
  key: 'areaGroupsLoadedPage',
  default: (pageUid) => []
})
const loadedAreaGroupsPageUids = atom({
  key: 'loadedAreaGroupsPagesAtom',
  default: new Set()
})
const loadedAreasSelector = selector({
  key: 'loadedAreasSelector',
  get: ({ get }) => {
    console.log('get(loadedAreaGroupsPageUids)', get(loadedAreaGroupsPageUids))
    return Array.from(get(loadedAreaGroupsPageUids)).reduce(
      (acc, pageUid) => [...acc, ...get(areaGroupsLoadedPage(pageUid))],
      []
    )
  }
})

export const useAreaGroups = ({
  orderby = 'desc',
  page = 1,
  per_page = 40,
  name,
  description,
  level
}) => {
  const pageUid = JSON.stringify({
    orderby,
    page,
    per_page,
    name,
    description,
    level
  })
  const [, setAreaGroups] = useRecoilState(areaGroupsLoadedPage(pageUid))
  const loadedGroups = useRecoilValue(loadedAreasSelector)
  const [pagesUids, setAreaGroupsPagesUids] = useRecoilState(
    loadedAreaGroupsPageUids
  )

  const [shownGroups, setShownGroups] = useState(loadedGroups)

  const onRemove = (removedId) => {
    setShownGroups((groups) => groups.filter(({ id }) => removedId !== id))
  }
  const fetchAreaGroupCmd = useFetchAreaGroupsCmd(
    {
      orderby,
      page,
      per_page,
      name,
      description,
      level
    },
    (r) => {
      console.log('useFetchAreaGroupsCmd onSuccess ', r?.data)
      r?.data && setAreaGroups(r?.data)
    },
    (r) => console.log('useFetchAreaGroupsCmd onFail r', r)
  )

  useEffect(() => {
    setShownGroups(loadedGroups)
  }, [loadedGroups])

  useEffect(() => {
    console.log('useEffect pageUid', pagesUids.has(pageUid), pageUid, pagesUids)
    !pagesUids.has(pageUid) &&
      setAreaGroupsPagesUids((pUids) => new Set([...pUids, pageUid]))
    !pagesUids.has(pageUid) && fetchAreaGroupCmd()
  }, [pageUid])

  console.log('useAreaGroups!', ...loadedGroups)
  return { loadedGroups: shownGroups, onRemove }
}
