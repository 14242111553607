import styled from 'styled-components'

const OrderErrorsHeaderView = styled.header`
  margin-bottom: ${({theme})=>theme.spacing.secondary};
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({theme})=>theme.spacing.secondary};
  }
`

export default OrderErrorsHeaderView