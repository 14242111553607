import React from 'react'
import { SnowDrawer } from '../../../../shared/components/snowComponents'
import SidebarContent from './SidebarContent'
import styled from 'styled-components'

const drawerWidth = 240
const miniDrawerWidth = 60

const GridDrawer = styled(SnowDrawer)`
  z-index: 500;
  width: ${({ width }) => (width ? `${width}px` : 0)};
  transition: width 0.3s ease-in;
  overflow: hidden;
  .MuiDrawer-paper {
    position: fixed;
    top: ${({ top }) => (top ? `${top}px` : 0)};
    width: ${({ width }) => (width ? `${width}px` : 0)};
    height: ${({ height }) => (height ? `${height}px` : 0)};
    transition: width 0.3s ease-in;
    box-shadow: 0 1px 30px 1px ${({ theme }) => theme.mainPalette.grey.shadow};
    overflow: hidden;
  }
`

function SidebarMini({ isOpen = true, currentTab, top, docwidth, height }) {
  console.log('SidebarMini', height)
  return (
    <>
      {top ? (
        <GridDrawer
          width={isOpen ? drawerWidth : docwidth < 576 ? 0 : miniDrawerWidth}
          top={top}
          height={height}
          variant="permanent"
        >
          <SidebarContent
            onClick={() => {}}
            isOpen={isOpen}
            height={height}
            currentTab={currentTab}
            miniDrawerWidth={miniDrawerWidth}
            drawerWidth={docwidth < 576 ? 0 : drawerWidth}
          />
        </GridDrawer>
      ) : null}
    </>
  )
}

export default SidebarMini
