import React, {
  createContext,
  useEffect,
} from 'react'
import {
  useScPageHeaderTitle
} from '../../../app/data/currentHeadingState'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { BatchDetailsView, useBatchDetails } from './BatchDetails'
import BatchItemsList, { useBatchItems } from './BatchItemsList'
import ScPageContentPaper from '../../../../shared/components/LayoutComponents/ScPageContentPaper'
import { ScEntityDetailsPage, ScItemsInRow } from '../../../../shared/components/LayoutComponents'
import { ScGoBackButton, ScPrimaryBtn, ScWhiteButton } from '../../../../shared/components/buttons'
import ScStatusChip from '../../../../shared/components/ScStatusChip'
import { useScMessage } from '../../../../shared/service/Message/data/currentMessageState'
import {
  useApproveBatchCmd, useCancelBatchCmd,
  useRefuseBatchCmd
} from '../../infrastructure/bachesApi'

export const BatchContext = createContext({ batchId: null })

const useBatchView = ({ batchId }) => {
  const [, setTitle] = useScPageHeaderTitle()

  useEffect(() => {
    setTitle(`Batch #${batchId}`)
  }, [])
}

const BatchView = () => {
  const { batchId } = useParams()
  const { send } = useScMessage()
  const showMessage = (...props) => {
    console.log('BatchView showMessage', props)
    send(...props)
  }
  const {
    isLoading: isDetailsLoading,
    data: details,
    renderer: detailsRenderer
  } = useBatchDetails({ batchId })
  const {
    action,
    batch_id,
    created_at,
    entity,
    status,
    updated_at,
    user,
    moderation_status,
    filters,
    item_ids
  } = details

  const approveBatchCmd = useApproveBatchCmd({ batchId }, (r) => {

    console.log('approveBatchCmd onSuccess', r)
    const { messages } = r
    messages?.map(({ type, text }) => showMessage(text, type))
  }, (r) => {
    console.log('approveBatchCmd onFail', r)
    const { messages } = r
    messages?.map(({ type, text }) => showMessage(text, type))
  })

  const refuseBatchCmd = useRefuseBatchCmd({ batchId }, (r) => {
    console.log('refuseBatchCmd onSuccess', r)
    const { messages } = r
    messages?.map(({ type, text }) => showMessage('refuseBatchCmd ' + text, type))
  }, (r) => {
    const { messages } = r
    messages?.map(({ type, text }) => showMessage('refuseBatchCmd ' + text, type))
  })

  const cancelBatchCmd = useCancelBatchCmd({ batchId }, (r) => {
    console.log('cancelBatchCmd onSuccess', r)
    const { messages } = r
    messages?.map(({ type, text }) => showMessage(text, type))
  }, (r) => {
    const { messages } = r
    messages?.map(({ type, text }) => showMessage(text, type))
  })

  const {
    data: { fields, items }, totals,
    isLoading: isItemsLoading
  } = useBatchItems({ batchId })

  const navigate = useNavigate()

  useBatchView({ batchId })

  console.log('BatchView', details, detailsRenderer)

  const refuseBatch = () => {
    refuseBatchCmd().then(r => console.log('refuseBatch then', r))
    showMessage('Gonna refuse batch')
    console.log('Gonna refuse batch')
  }
  const cancelBatch = () => {
    cancelBatchCmd().then(r => console.log('cancelBatch then', r))
    showMessage('Gonna to cancel batch')
    console.log('Gonna cancel batch')
  }
  const approveBatch = () => {
    approveBatchCmd().then(r => console.log('approveBatch then', r))

    showMessage('Gonna approve batch')

    console.log('Gonna approve batch')
  }

  const actionsButtons = {
    Approve: {
      type: ScWhiteButton,
      onClick: refuseBatch,
      label: 'Approve execution'
    },
    Refuse: {
      type: ScWhiteButton,
      onClick: refuseBatch,
      label: 'Approve execution'
    }
  }
  return (
    <>
      <BatchContext.Provider value={{ batchId }}>
        <ScEntityDetailsPage
          pageLinks={
            <ScGoBackButton
              label={'Bulk Update'}
              onBack={() => navigate('/home/order_items')}
            />
          }
          title={`${entity}'s ${action} batch `}
          titleExt={`${created_at} from ${user}`}
          subtitle={<ScStatusChip status={status ?? ''} />}
          actionsContent={
            <>
              {status === 'new' || status === 'processing' ? (
                <ScItemsInRow itemWidth={'auto'}>
                  {
                    status === 'new' && <ScPrimaryBtn size={'middle'}
                      onClick={approveBatch}
                      label={'Approve batch to execute'}
                    />
                  }
                  {status === 'new' && <ScWhiteButton onClick={refuseBatch} label={'Refuse batch'} />}
                  {status === 'processing' && <ScWhiteButton onClick={cancelBatch} label={'Cancel batch'} />}
                </ScItemsInRow>
              ) : null}
            </>
          }
          entityContent={
            <>
              <BatchDetailsView details={details} />
              <h4 style={{ marginBottom: '1rem' }}>Batch Items ( {totals} of total):</h4>
              <ScPageContentPaper isPaper>
                <BatchItemsList fields={fields} items={items} />
              </ScPageContentPaper>
            </>
          }
          sidebarWidgets={''}
        />
        {/*<BatchDetails />*/}
      </BatchContext.Provider>
    </>
  )
}

export default BatchView
