import React, { createContext, useContext } from 'react'
import { SnowGrid } from '../../snowComponents'

const ScSplitPanelContext = createContext()

/**
 *
 * @param children
 * @param ratio pair array with left/right panels ration [left, right]. Ex.[2,1]
 * @returns {JSX.Element}
 * @constructor
 */
const ScSplitPanel = ({ children, ratio }) => {
  console.log('SnowSplitPanel props', ratio)
  const value = { ratio: [2, 1] }
  return (
    <ScSplitPanelContext.Provider value={value}>
      <SnowGrid container className={'SnowSplitPanel'} spacing={2}>
        {children}
      </SnowGrid>
    </ScSplitPanelContext.Provider>
  )
}
const SnowPanelLeft = ({ children }) => {
  const { ratio } = useContext(ScSplitPanelContext)
  const all = ratio[0] + ratio[1]
  const leftWidth = ratio[0] * (12 / all)
  return (
    <SnowGrid item xs={leftWidth} style={{ overflow: 'scroll' }}>
      {children}
    </SnowGrid>
  )
}
const SnowPanelRight = ({ children }) => {
  const { ratio } = useContext(ScSplitPanelContext)
  const all = ratio[0] + ratio[1]
  const rightWidth = ratio[1] * (12 / all)
  return (
    <SnowGrid item xs={rightWidth} style={{ overflow: 'scroll' }}>
      {children}
    </SnowGrid>
  )
}
ScSplitPanel.Left = SnowPanelLeft
ScSplitPanel.Right = SnowPanelRight
export default ScSplitPanel
