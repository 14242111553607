import React, { useState, useEffect } from 'react'
import SnowEditableField from '../../../../shared/components/SnowEditableField'
import {
  currentIntegrationActualFieldsState,
  isFieldFromOriginValues
} from '../../data/integrationModel'
import { useRecoilValue } from 'recoil'

export const ConfigField = ({ field, updateValue, onError, ...props }) => {
  console.log('ConfigField props', props)
  const actualValues = useRecoilValue(currentIntegrationActualFieldsState)

  // const value = (typeof(values[field.id])=='undefined' || values[field.id] === null) ? field.default_value : values[field.id];
  const {
    field_id,
    title,
    required,
    description,
    type,
    default_value,
    depends,
    options = [],
    editable
  } = field

  const isFromOriginPreset = useRecoilValue(isFieldFromOriginValues(field_id))

  const [value, setValue] = useState(default_value || '')

  console.log(
    `ConfigField field_id=${field_id}, value=`, { value, type }
  )

  useEffect(() => {
    //values array to obj for quick access by field_id
    const actualValuesMap = actualValues.reduce((acc, item) => {
      acc[item.field_id] = item.value
      return acc
    }, {})

    const _value = isFromOriginPreset
      ? actualValuesMap[field_id]
      : actualValuesMap[field_id] ?? default_value
    console.log(
      'ConfigField  optimisedValues!!!',
      {
        field_id,
        isFromOriginPreset,
        _value,
        actualValuesMap: actualValuesMap[field_id]
      }
    )

    setValue(_value)
  }, [actualValues])

  const handleError = (error) => {
    onError(error)
  }

  return (
    <SnowEditableField
      value={value}
      label={title}
      variant="outlined"
      required={required}
      code={'' + field_id}
      edit={true}
      description={description}
      depends={depends}
      meta={{
        editType: type.replace('custom_fields_description_list', 'JSON'), //text|enum| upcoming :checkbox, numbers
        options, //for case of enum - pairs array [{value:label},..]
        editable,
        isInvalid: false
      }}
      updateDelay={1000}
      onUpdate={updateValue}
      onError={handleError}
      {...props}
    />
  )
}
