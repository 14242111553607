export const refreshTokenSetup = (
  res,
  callback = () => console.log('Google Token refreshed')
) => {
  // console.debug('refreshTokenSetup', res)
  // Timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000

  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse()
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000
    console.log('newAuthRes:', newAuthRes)
    callback(newAuthRes)
    console.log('Google Token refreshed')
    // Setup the other timer after the first one
    setTimeout(refreshToken, refreshTiming)
  }
  callback(res)
  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming)
}
