import React from 'react'
import styled, { css } from 'styled-components'

const VersionBlockView = styled.span`
  ${({ theme }) => css`
    color: ${theme.mainPalette.typography.light};
    font-size: ${theme.fontSize.small};
    line-height: ${theme.lineHeight.small};
    font-weight: ${theme.fontWeight.thin};
  `}
  padding: 10px;
  margin-top: auto;
  box-sizing: border-box;
  width: 100%;
  min-height: 3rem;
  background: transparent;
  text-align: center;
`

const VersionBlock = () => {
  return <VersionBlockView>Snow Axis v2.8.0</VersionBlockView>
}

export default VersionBlock
