import React, { Children } from 'react'
import { SnowList, SnowListItem } from '../../snowComponents'

const ScList = ({ children, itemProps = {}, ...props }) => {
  console.log('ScList props', itemProps, props, children)
  const listItemProps = (idx) =>
    typeof itemProps === 'function' ? itemProps(idx) : itemProps
  return (
    <SnowList {...props} className={'ScList'}>
      {Children.map(children, (child, idx) => (
        <SnowListItem
          key={idx}
          // style={{ marginBottom: '0.3em' }}
          {...listItemProps(idx)}
        >
          {child}
        </SnowListItem>
      ))}
    </SnowList>
  )
}

export default ScList
