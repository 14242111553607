import React, { useEffect, useState } from 'react'
import { ScPrimaryBtn } from '../../../../shared/components/buttons'
import SyncProductsDataModal from './SyncProductsDataModal'

const UpdatePimValuesBtn = () => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      <ScPrimaryBtn
        size={'medium'}
        onClick={() => {
          toggleModal()
        }}
        label={'Sync SKUs data'}
      />
      <SyncProductsDataModal onClose={toggleModal} isOpen={showModal} />
    </>
  )
}

export default UpdatePimValuesBtn
