import styled, { css } from 'styled-components'

const OrderCardInfoView = styled.div`
  ${({ theme }) => css`
    .MuiTypography-root {
      color: ${theme.mainPalette.grey.main};
      line-height: 1;
    }
  `}
`

export default OrderCardInfoView
