import { SnowTableRow } from '../../../../../../../shared/components/snowComponents'
import styled, { css } from 'styled-components'

const PriceRow = styled(SnowTableRow)`
  .MuiTableCell-root {
    min-width: 100px;
    border-bottom: none;
    font-weight: ${({ theme }) => theme.fontWeight.thin};
  }
  ${({ istotal, theme }) =>
    istotal
      ? css`
          &.MuiTableRow-root {
            border-top: ${theme.border.main};
          }
          .MuiTableCell-root {
            font-weight: ${theme.fontWeight.bold};
          }
        `
      : ''}
`

export default PriceRow
