import { useEntityCommand } from '../../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../../infrastructure/api/utils'
import objWithParent from '../../../../utils/parenter'

const API_ORDER = objWithParent({
  url: '/order',
  REFUSE_CUSTOMIZATION: {
    url: 'personalization/refuse',
    method: 'POST',
    caller: 'REFUSE_CUSTOMIZATION!',
    validator: () => true
  },
  APPROVE_CUSTOMIZATION: {
    url: 'personalization/approve',
    method: 'POST',
    caller: 'APPROVE_CUSTOMIZATION!',
    validator: () => true
  },
  DO_PERSONALIZATION_CMD: {
    url: 'personalization/',
    method: 'POST',
    caller: 'API_ORDER DO_PERSONALIZATION_CMD',
    validator: () => true
  }
})

export const useApplyOrderPersonalizationCmd = (
  { id: entityId },
  { action: actionName },
  onSuccess = (r) => console.log('useApplyOrderPersonalizationCmd success', r),
  onFail = (r) => console.log('useApplyOrderPersonalizationCmd fail', r)
) => {
  console.log('useApplyOrderPersonalizationCmd', entityId, actionName)
  const cmdUrl = API_ORDER.DO_PERSONALIZATION_CMD.url + actionName
  const result = useEntityCommand(
    entityId,
    {
      ...API_ORDER.DO_PERSONALIZATION_CMD,
      url: cmdUrl
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

/** hook that commands to approve given customization
 *
 * @param entityId
 */
export const useApproveCustomizationCmd = ({ id: entityId }) => {
  const result = useEntityCommand(entityId, API_ORDER.APPROVE_CUSTOMIZATION)
  console.log('useApproveCustomizationCmd result', result)
  return result
}

/** hook that commands to  refuse given customization
 *
 * @param entityId
 */
export const useRefuseCustomizationCmd = ({ id: entityId }) => {
  const result = useEntityCommand(entityId, API_ORDER.REFUSE_CUSTOMIZATION)
  console.log('useRefuseCustomizationCmd result', result)

  return result
}
// const useFetchedProjects = ({ params }) => {
//   const { data, error, isLoading } = useFetchedResult(
//     { ...API_PROJECTS.FETCH, params },
//     { data: [] }
//   )
//   const [projects, setProjects] = useRecoilState(projectsState)
//   useEffect(() => {
//     setProjects(data.data)
//   }, [data.data])
//   return {
//     data: projects,
//     isLoading,
//     error
//   }
// }
