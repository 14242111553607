import React, { Suspense, createRef, useContext } from 'react'
import { SnowWarningIcon } from '../../../../shared/components/icons'
import OrderItems from './OrderItems/OrderItems'
import OrderPayment from './OrderPayment/OrderPayment'
import OrderErrors from './OrderErrors/OrderErrors'
import Timeline from './Timeline/TimelineSingleAction'
import OrderVendorItems from './OrderVendorItems/OrderVendorItems'
import {
  SnowTabContent,
} from '../../../../shared/components/form/ScTabs/ScTabs'
import ScTabs from '../../../../shared/components/form/ScTabs'
import { useCurrentOrder } from '../data/currentOrderState'
import GiftMessage from './GiftMessage/GiftMessage'
import ScSpinner from '../../../../shared/components/ScSpinner/ScSpinner'
import { OrderDetailsView } from './_styled'
import styled, { ThemeContext } from 'styled-components'

const StyledTitle = styled.span`
  margin-right: ${({ theme }) => theme.spacing.main};
`

const OrderTabTitle = ({ label, iconClick, theme }) => (
  <span style={{ display: 'flex', alignItems: 'center' }}>
    <StyledTitle>{label} </StyledTitle>
    {typeof iconClick === 'function' && (
      <div onClick={iconClick}>
        <SnowWarningIcon fontSize={'medium'} />
      </div>
    )}
  </span>
)
const OrderItemsTabTitle = ({ iconClick, theme }) => (
  <OrderTabTitle iconClick={iconClick} theme={theme} label={'Order Items'} />
)
const OrderVendorsTabTitle = ({ iconClick, theme }) => (
  <OrderTabTitle iconClick={iconClick} label={'Order Vendors'} theme={theme} />
)

const DetailBody = () => {
  const theme = useContext(ThemeContext)
  const currentOrder = useCurrentOrder()
  console.log('currentOrderModel', currentOrder)
  const orderErrorBox = createRef()
  const { vendorOrders, vendors, id } = currentOrder
  const hasVendorErrors = vendors?.find(({ errors }) => {
    console.log('errors', errors)
    return errors.length !== 0
  })
  const hasOrderLevelErrors = currentOrder.calculatedData.hasErrors
  const hasErrors = hasOrderLevelErrors || hasVendorErrors
  console.log('hasVendorErrors', hasVendorErrors)

  const scrollToErrors =
    hasErrors &&
    (() => {
      console.log('orderErrorBox', orderErrorBox)
      orderErrorBox &&
        orderErrorBox.current.scrollIntoView({
          block: 'center',
          behavior: 'smooth'
        })
    })

  console.log('DetailBody currentOrder', currentOrder.gift_messages)
  const handleErrorClick = (hasErrors) => hasErrors && scrollToErrors

  const { gift_messages, orderItems } = currentOrder

  if (!id) return null

  return (
    <OrderDetailsView>
      <ScTabs>
        <SnowTabContent
          label={
            <OrderItemsTabTitle
              theme={theme}
              iconClick={handleErrorClick(hasOrderLevelErrors)}
            />
          }
        >
          <OrderItems items={orderItems} />
        </SnowTabContent>
        {vendors.length && (
          <SnowTabContent
            label={
              <OrderVendorsTabTitle
                theme={theme}
                iconClick={handleErrorClick(hasVendorErrors)}
              />
            }
          >
            <Suspense fallback={<ScSpinner />}>
              <OrderVendorItems />
            </Suspense>
          </SnowTabContent>
        )}
      </ScTabs>

      <OrderPayment />

      {gift_messages ? <GiftMessage messages={gift_messages} mb={30} /> : null}
      {hasErrors && <OrderErrors ref={orderErrorBox} />}
      <Timeline order={currentOrder} />
    </OrderDetailsView>
  )
}

export default DetailBody
