import styled, { css } from 'styled-components'

export const SidebarPaperView = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.secondary};
    margin-bottom: ${theme.spacing.secondary};
    box-shadow: ${theme.shadow.main};
    background: ${theme.mainPalette.color.contrast};
  `}
  box-sizing: border-box;
  .MuiDivider-root {
    margin: ${({theme})=>theme.spacing.secondary} 0;
  }
`

export default SidebarPaperView
