import React from 'react'
import { ScWhiteButton } from '../../buttons'
import { SnowEditIcon } from '../../icons'

const ScEditTextButton = ({ onEdit, ...props }) => {
  return (
    <ScWhiteButton
      icon={() => <SnowEditIcon />}
      onClick={() => onEdit()}
      label={'Edit'}
      {...props}
    />
  )
}

export default ScEditTextButton
