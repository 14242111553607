import React from 'react'
import { SnowButton } from '../snowComponents'
import styled, { css } from 'styled-components'

const ButtonBaseView = css`
  &.MuiButtonBase-root {
    color: ${({ theme }) => theme.mainPalette.color.contrast} !important;
    font-weight:  ${({ theme }) => theme.fontWeight.thin};
    white-space: nowrap;
    box-shadow: none;
  }
  &.Mui-disabled {
    color: ${({ theme }) => theme.mainPalette.color.contrast};
    opacity: 0.5;
  }
`

const StyledPrimaryButton = styled(SnowButton)`
  background: ${({ theme }) => theme.mainPalette.gradient.primary};
  ${ButtonBaseView}
`

const StyledSecondaryButton = styled(SnowButton)`
  background: ${({ theme }) => theme.mainPalette.gradient.secondary};
  ${ButtonBaseView}
`

export const ScPrimaryBtn = ({ label, onClick, size = 'large', ...props }) => {
  return (
    <StyledPrimaryButton
      {...props}
      aria-label={'sc-primary-button'}
      variant={'contained'}
      onClick={onClick}
      size={size}
    >
      {label}
    </StyledPrimaryButton>
  )
}
export const ScSecondaryBtn = ({ label, onClick, ...props }) => {
  const { className, ..._props } = props
  return (
    <StyledSecondaryButton
      {..._props}
      onClick={onClick}
    >
      {label}
    </StyledSecondaryButton>
  )
}
export const ScOutlinedBtn = ({ label, onClick, ...props }) => {
  return (
    <SnowButton {...props} onClick={onClick} color={'primary'} variant={'outlined'} style={{ fontWeight: '300', padding: '7px 21px' }}>
      {label}
    </SnowButton>
  )
}
