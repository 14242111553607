import { useCountryRegions } from "./data/areaDictionaries";
import React, { useEffect, useState } from "react";
import { ScMultiComboField } from "../../shared/components/SnowEditableField/baseFields";

const CountryRegions = ({ countryId, onUpdate, value: incomeRegions = [] }) => {

  const regionsOptions = useCountryRegions({ id: countryId });


  const [countryRegions, setCountryRegions] = useState(null);

  const hasRegions = Boolean(regionsOptions?.length);

  const handleCountryRegionsUpdate = ({ value: updatedRegions }) => {

    const availableRgns = regionsOptions?.map(({ code }) => code);
    // allRegionOption?.code

    const matchedRgns = Array.isArray(updatedRegions)
      ? updatedRegions.filter((rgn) => availableRgns.includes(rgn))
      : [];

    const shownRegions = updatedRegions;

    console.log(
      "CountryRegions handleCountryRegionsUpdate",
      countryId,
      {
        matchedRgns,
        shownRegions,
        regionsOptions,
        updatedRegions
      }
    );
    onUpdate({ value: updatedRegions });

    // setCountryRegions(shownRegions)
  };

  useEffect(() => {
    if (!Array.isArray(regionsOptions)) return;
    console.log("CountryRegions useEffect regionsOptions", { regionsOptions });
    const availableRgns = regionsOptions?.map(({ code }) => code);
    // allRegionOption?.code
    const matchedIncomeRgns = Array.isArray(incomeRegions)
      ? incomeRegions.filter((rgn) => availableRgns.includes(rgn))
      : [];

    console.log("CountryRegions useEffect regionsOptions", {
      countryId,
      incomeRegions,
      regionsOptions,
      matchedIncomeRgns,
      availableRgns
    });

    setCountryRegions(matchedIncomeRgns);

  }, [regionsOptions]);
  useEffect(() => {
    if (!Array.isArray(countryRegions) || !Array.isArray(incomeRegions)) return;
    console.log("incomeRegionsStr", incomeRegions);
    const countryRgnStr = JSON.stringify([...countryRegions].sort());
    const incomeRegionsStr = JSON.stringify([...incomeRegions].sort());
    if (countryRgnStr === incomeRegionsStr) return;
    // if(countryRegions)
    handleCountryRegionsUpdate(countryRegions);

  }, [countryRegions]);
  useEffect(() => {
    console.log("CountryRegions useEffect countryId", countryId, incomeRegions);
    //todo: wait for regions and update country regions?
  }, [countryId]);

  useEffect(() => {
    const availableRgns = Array.isArray(regionsOptions) && regionsOptions?.map(({ code }) => code);
    // allRegionOption?.code
    console.log("CountryRegions useEffect incomeRegions", { countryId, regionsOptions, availableRgns, incomeRegions });

  }, [incomeRegions]);

  useEffect(() => {
    return () => console.log("CountryRegions destructor");
  }, []);

  console.log("CountryRegions!!", {
    countryId,
    incomeValue: incomeRegions,
    regionsOptions,
    countryRegions,
    hasRegions
  });
  if (!regionsOptions?.length) return <span
    style={{ textTransform: "lowercase", letterSpacing: "2px" }}>Unitary state</span>;
  if (!hasRegions) return null;
  if (!countryRegions) return null;
  return (
    <ScMultiComboField
      code={`country-regions-${countryId}`}
      onUpdate={handleCountryRegionsUpdate}
      value={countryRegions}
      label={"Regions/States"}
      multiple={true}
      meta={{
        options: regionsOptions ?? [],
        optionsDef: { value: "code", label: "name", tag: "code" },
        emptyOption: { id: "", name: "All regions", code: "All" }
      }}
    />
  );
};

export default CountryRegions;
