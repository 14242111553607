import React, { useEffect, useState } from 'react'
import { useAreaGroups } from './data/areaGroups'
import {
  useScPageHeaderSubtitle,
  useScPageHeaderTitle
} from '../app/data/currentHeadingState'
import { useLoadLevels } from './data/areaDictionaries'
import { createAreaGroup, NEW_GROUP_ID } from './data/areaGroupDetails'
import AreaGroupTable from './AreaGroupTable'

const useAreaPageUX = () => {
  const [, setPageHeader] = useScPageHeaderTitle()
  const [, setPageSubheader] = useScPageHeaderSubtitle()

  useEffect(() => {
    setPageHeader('Regions & Vendors')
    setPageSubheader('Feel free to add/update/remove global and local regions')
    // return () => {
    //   setPageSubheader('')
    // }
  }, [])
}

function AreasPage() {
  useAreaPageUX()
  useLoadLevels()

  const [loadedAreasPage, setLoadedPage] = useState(1)

  const [filters, setFilters] = useState({})

  const { loadedGroups, onRemove } = useAreaGroups({
    page: loadedAreasPage,
    per_page: 60
  })

  const [shownGroups, setShownGroups] = useState([])

  const filtrateGroups = (groups) => {
    const filteredGroups = groups.filter(
      ({ level, name }) =>
        (filters['level']
          ? String(level) === String(filters['level'])
          : true) && (filters['name'] ? name.includes(filters['name']) : true)
    )
    console.log('filters', groups, filters, filteredGroups)

    return filteredGroups
  }

  const loadMore = () => {
    setLoadedPage((page) => page + 1)
  }
  const handleCreate = (evt) => {
    const newGroup = createAreaGroup()
    console.log('AreaGrid handleAddNewArea', newGroup)
    setShownGroups((groups) => [newGroup, ...groups])
  }
  const handleUpdate = ({ id: updatedId, data }) => {
    // const newGroup = createAreaGroup()
    console.log('AreaGrid handleUpdate', updatedId, data)
    if (updatedId === NEW_GROUP_ID && data?.id) {
      setShownGroups((groups) => [
        ...groups.filter(({ id }) => id !== NEW_GROUP_ID),
        data
      ])
    }
    // setShownGroups((groups) => [newGroup, ...groups])
  }

  const handleFilter = ({ code, value }) => {
    setFilters((applied) => ({ ...applied, [code]: value }))
  }
  useEffect(() => {
    console.log('loadedGroups', loadedGroups)
    setShownGroups(filtrateGroups(loadedGroups))
  }, [loadedGroups])

  useEffect(() => {
    if (!Object.keys(filters).length) return
    setShownGroups(filtrateGroups(loadedGroups))
  }, [filters])

  return (
    <div>
      <AreaGroupTable
        groups={shownGroups}
        handleUpdate={handleUpdate}
        handleFilter={handleFilter}
        handleRemove={onRemove}
        handleCreate={handleCreate}
      />
    </div>
  )
}
export default AreasPage
