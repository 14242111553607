import { selectedBulkItemIds } from '../../../shared/components/BulkComponents/data/selectedBulkItemsState'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import objWithParent from '../../../utils/parenter'

const ORDERS_URL = '/orders'
const ORDERS_RESEND_ITEMS = 'resendItems'

export const API_ORDERS = objWithParent({
  url: ORDERS_URL,

  RESEND_ITEMS: {
    url: `${ORDERS_RESEND_ITEMS}`,
    method: 'POST',
    caller: 'RESEND_ITEMS!',
    validator: () =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  }
})

export const useResendOrderItemsCmd = (
  { id, selectedItemIds = [], reason = 'By some reason' },
  responseHandler = (resp) =>
    console.log('useResendItemsCmd responseHandler', resp)
) => {
  console.log('useResendOrderItemsCmd', id, selectedItemIds, API_ORDERS)
  const resendItemsCmd = useEntityCommand(
    id,
    {
      ...API_ORDERS.RESEND_ITEMS,
      params: { data: { ids: selectedItemIds, reason } }
    },
    responseHandler
  )
  return resendItemsCmd
}
