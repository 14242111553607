import { atom, useSetRecoilState } from 'recoil'

/**
 *
 * @type {RecoilState<{severity: string, message: string}>}
 */

export const currentMessageState = atom({
  key: 'currentMessageState',
  default: { severity: '', message: '' }
})
export const useScMessage = () => {
  const setMessage = useSetRecoilState(currentMessageState)
  /**
   *
   * @param message
   * @param severity
   */
  const send = (message = 'message', severity = 'info') => {
    console.log('useScMessage send ', { severity, message })
    setMessage({ severity, message })
  }
  return { send }
}
