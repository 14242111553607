import React from 'react'
import { SnowAddCircleIcon } from '../../icons'
import { ScIconButton } from '../../buttons'
import styled from 'styled-components'

const StyledButton = styled(ScIconButton)`
  background: ${({ theme }) => theme.mainPalette.color.contrast};
  x-index: 1;
  &.MuiIconButton-root {
    padding: ${({ ispadding }) => (ispadding ? '0' : 'inherit')};
  }
`

const StyledIcon = styled(SnowAddCircleIcon)`
  &.MuiSvgIcon-root {
    fill: ${({ theme, iconcolor }) =>
      iconcolor ? iconcolor : theme.mainPalette.grey[100]};
  }
`

const ScAddButton = ({ onAdd, noPadding = false, color }) => {
  return (
    <StyledButton
      aria-label="Add"
      onClick={() => onAdd()}
      size={'medium'}
      ispadding={noPadding ? 1 : 0}
    >
      <StyledIcon iconcolor={color}/>
    </StyledButton>
  )
}

export default ScAddButton
