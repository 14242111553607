import React from 'react'
import { SnowExpandLessIcon, SnowExpandMoreIcon } from '../../icons'
import { ScIconButton } from '..'

const ScCollapseButton = ({ onClick, open=true }) => {
  return (
    <ScIconButton onClick={onClick}>
      {
        open ? <SnowExpandLessIcon /> : <SnowExpandMoreIcon />
      }
    </ScIconButton>
  )
}

export default ScCollapseButton
