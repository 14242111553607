import { SnowListItem } from '../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const ProductNoImageView = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    max-width: 300px;
    height: auto;
  }
  p {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`

const ProductImageView = styled(SnowListItem).attrs({
  disableGutters: true,
  dense: true
})`
  &.MuiListItem-root{
    justify-content: center;
    align-items: center;
  }
  img {
    display: block;
    width: 100%;
    max-width: 300px;
    height: auto;
  }
`

export {
  ProductNoImageView,
  ProductImageView
}