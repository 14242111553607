import React from 'react'
import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily
} from 'recoil'

import {
  removeElementInCollection,
  addElementInCollection
} from '../../../../utils/helpers'

const filterAtom = atomFamily({ key: 'filterSAtom', default: '' })

const filterCodesAtom = atom({
  key: 'filterCodesAtom',
  default: []
})

// abstraction
export const filtersState = selectorFamily({
  key: 'filtersState',
  get:
    (code) =>
    ({ get }) => {
      return get(filterAtom(code))
    },
  set:
    (code) =>
    ({ get, set, reset }, newValue) => {
      const registeredCodes = get(filterCodesAtom)
      console.log(
        'set filterState registeredCodes',
        registeredCodes,
        code,
        newValue
      )
      if (newValue instanceof DefaultValue) {
        // DefaultValue means reset context
        console.log('going to reset filter')
        reset(filterAtom(code))
        reset(filterCodesAtom)
        return
      }
      console.log('filterCodesAtom', filterCodesAtom)
      set(filterAtom(code), newValue)
      set(filterCodesAtom, (prev) =>
        newValue !== ''
          ? addElementInCollection(prev, code)
          : removeElementInCollection(prev, code)
      )
    }
})
export const filterCodesSelector = selector({
  key: 'filterCodesSelector',
  get: ({ get }) => get(filterCodesAtom)
})

export const filtersDtoSelector = selectorFamily({
  key: 'filtersDtoSelector',
  get:
    (requestType) =>
    ({ get }) => {
      const stativeFilters = []
      const fieldFilters = []
      const allFilterCodes = get(filterCodesSelector)

      const allParams = allFilterCodes
        .map((code) => ({ [code]: get(filterAtom(code)) }))
        .reduce((acc, cur) => {
          console.log('cur', cur)
          return { ...acc, ...cur }
        }, {})

      if (requestType === 'GET') return allParams

      const propsFilters = allFilterCodes.filter(
        (code) => !stativeFilters.includes(code)
      )
      const filterParams = propsFilters
        .map((code) => {
          const filter = get(filterAtom(code))
          return { [`field[${[code]}]`]: filter }
        })
        .reduce((acc, cur) => {
          console.log('cur', cur)
          return { ...acc, ...cur }
        }, {})

      const stativeCodes = allFilterCodes.filter((code) =>
        stativeFilters.includes(code)
      )

      const stativeParams = stativeCodes
        .map((code) => ({ [code]: get(filterAtom(code)) }))
        .reduce((acc, cur) => {
          console.log('cur', cur)
          return { ...acc, ...cur }
        }, {})

      console.log(
        'allFilterCodes, filterParams, stativeParams',
        filterParams,
        stativeParams
        // new URLSearchParams(stativeParams).toString()
      )
      // console.log('stative,fields',filterParams)
      return {
        filterParams, //: new URLSearchParams(filterParams).toString(),
        stativeParams
      }
    }
})
export default filtersState
