import React, { Fragment, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  SnowBadge,
  SnowMenu,
  SnowButton,
  SnowTypography,
  SnowTooltip,
  SnowClickAwayListener
} from '../../../../shared/components/snowComponents'
import { SnowArrowForwardIosIcon } from '../../../../shared/components/icons'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const NavItemView = styled.li`
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background: transparent;
  ${({ theme }) => `
    border-bottom: ${theme.border.secondary};
    transition: background ${theme.transition.main};
  `}
  ${({ lastBorderEnable = true }) =>
    lastBorderEnable
      ? ''
      : `
    :last-child{
      border-bottom: none;
    }
  `}
  a {
    display: flex;
    align-items: center;
    width: ${({ width }) => (width ? `${width}px` : '100%')};
    min-height: ${({ height }) => height}px;
    padding: 0 43px 0 0;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background: ${({ theme }) => theme.mainPalette.color.hilight};
      opacity: 0;
      transition: all ${({ theme }) => theme.transition.main};
    }
    &.sidebar__link-active:before {
      opacity: 1;
    }
  }
  :hover {
    button{
      background: transparent;
    }
    .sidebar-icon-box {
      background: ${({ theme }) => theme.mainPalette.grey.light};
    }
  }
`

const LinkTitleView = styled(SnowTypography).attrs({
  component: 'p',
  variant: 'body2'
})`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.MuiTypography-root,
  .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.mainPalette.typography.secondary};
  }
`

const SideBarIconView = styled.div`
  ${({ width, theme }) => `
  width: ${width}px;
  min-width: ${width}px;
  height: ${width}px;
  transition: ${theme.transition.main};
  :hover {
    background: ${theme.mainPalette.grey.light};
  }
  svg {
    fill: ${theme.mainPalette.color.main};
  }
`}
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const MenuItemContent = (props) => {
  const { isOpen, miniDrawerWidth, iconItem, newLink, title, submenuExist } =
    props
  return (
    <Fragment>
      <SnowTooltip
        title={isOpen ? '' : title}
        placement={'right'}
        PopperProps={{ sx: { marginLeft: '-1.2rem !important' } }}
      >
        <SideBarIconView width={miniDrawerWidth} className='sidebar-icon-box'>{iconItem}</SideBarIconView>
      </SnowTooltip>
      <LinkTitleView>
        {newLink ? (
          <SnowBadge badgeContent={'New'}>
            <span>{title}</span>
          </SnowBadge>
        ) : (
          <>
            <SnowTypography component="span">{title}</SnowTypography>
            {submenuExist ? (
              <SnowArrowForwardIosIcon fontSize={'small'} />
            ) : null}
          </>
        )}
      </LinkTitleView>
    </Fragment>
  )
}

const SidebarLink = ({
  title,
  newLink,
  route,
  iconItem,
  onClick,
  isOpen,
  miniDrawerWidth,
  drawerWidth,
  submenuContent,
  key
}) => {
  const submenuExist = submenuContent?.length ? true : false
  const menuItemProps = {
    isOpen,
    miniDrawerWidth,
    iconItem,
    newLink,
    title,
    submenuExist
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const openSubmenu = Boolean(anchorEl)

  const handleClickToSubmenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseSubmenu = () => {
    setAnchorEl(null)
  }

  return (
    <NavItemView
      width={drawerWidth}
      height={miniDrawerWidth}
      key={key}
      onClick={onClick}
      submenuExist={submenuExist}
    >
      {submenuExist ? (
        <SnowClickAwayListener onClickAway={handleCloseSubmenu}>
          <div>
            <SnowButton
              aria-controls={openSubmenu ? 'submenu-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openSubmenu ? 'true' : undefined}
              onClick={handleClickToSubmenu}
              fullWidth
              sx={{ p: 0, pr:"10px", justifyContent: 'flex-start' }}
              disableRipple
            >
              <MenuItemContent {...menuItemProps} />
            </SnowButton>
            <SnowMenu
              anchorEl={anchorEl}
              open={openSubmenu}
              onClose={handleCloseSubmenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              {submenuContent.map(({ route, name, icon }, index) => (
                <NavItemView key={`${name}-${index}`} lastBorderEnable={false}>
                  <NavLink
                    to={route}
                    className={({ isActive }) =>
                      (isActive && 'sidebar__link-active') || ''
                    }
                    onClick={onClick}
                  >
                    <MenuItemContent
                      miniDrawerWidth={miniDrawerWidth}
                      title={name}
                      iconItem={icon}
                      isOpen={true}
                    />
                  </NavLink>
                </NavItemView>
              ))}
            </SnowMenu>
          </div>
        </SnowClickAwayListener>
      ) : (
        <NavLink
          to={route}
          className={({ isActive }) =>
            (isActive && 'sidebar__link-active') || ''
          }
          onClick={onClick}
        >
          <MenuItemContent {...menuItemProps} />
        </NavLink>
      )}
    </NavItemView>
  )
}

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func
}

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/home',
  onClick: () => {}
}

export default SidebarLink
