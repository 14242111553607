import styled from 'styled-components'

const OrderItemInfo = styled.section`
  display: grid;
  justify-content: space-between;
  grid-template-rows: repeat(2,min-content);
  grid-template-columns: min-content 1fr 200px;
  gap: 16px;
  justify-content: space-between;
`

export default OrderItemInfo