import React, { Children, useEffect, useState } from 'react'
import { SnowTab, SnowTabs, SnowBox } from '../../snowComponents'
import styled from 'styled-components'

const TabContentView = styled(SnowBox)`
  padding: 0;
  ${({ theme }) => `
    padding-top: ${theme.spacing.main};
    padding-bottom: ${theme.spacing.secondary};
  `}
`

const TabsView = styled(SnowTabs)`
  .MuiButtonBase-root {
    flex-grow: 0;
    background: ${({theme})=>theme.mainPalette.color.contrast};
  }
`

const TabView = styled(SnowTab)`
  &.MuiTab-root{
    &[aria-selected='true']{
      color: ${({theme})=>theme.mainPalette.typography.main};
    }
  }
`

// Example to use:
// const MyTabs = withStyles(styles) (({classes}) => {
//   return (
//     <div className={classes.root}>
//        <SnowTabs>
//          <TabContent label={"item 1"} >Item 1</TabContent>
//          <TabContent label={"item 2"} >Item 2</TabContent>
//          <TabContent label={"item 3"} >Item 3</TabContent>
//        </SnowTabs>
//     </div>
//   )
// })

const ScTabs = ({
  children,
  onTab = (e, value) => console.log(`${value} tab selected`),
  ...props
}) => {
  const [value, setValue] = useState(null)

  const onChange = (e, value) => {
    console.log('SnowTabs onChange', e, value)
    setValue(value)
    onTab(e, value)
  }
  //filtered with label
  const validTabs = children.filter(({ props }) => props && props.label)

  useEffect(() => {
    const activeTab = validTabs && validTabs.find(({ props }) => props.active)
    activeTab && setValue(activeTab.props.value)
  }, [validTabs])

  useEffect(() => {
    const activeTab = validTabs.find(({ active }) => active)
    console.log('SnowTabs validTabs[0].props.value', validTabs[0].props.value)
    const initialTabValue =
      validTabs && (validTabs[0].props.value ? validTabs[0].props.value : 0)

    setValue(initialTabValue)
  }, [])

  const snowTabs = validTabs.map(({ props, ...rest }, index) => {
    return {
      ...rest, //enrich tabs props with key and passed value
      props: {
        ...props,
        key: index,
        value: props.value ? props.value : index
      }
    }
  })
  // console.log('SnowTabs snowTabs', snowTabs)
  return (
    <div aria-label={'sc-tabs'} {...props}>
      {value !== null && snowTabs.length && (
        <TabsView indicatorColor="primary" value={value} onChange={onChange}>
          {Children.map(snowTabs, ({ props: { key, label, value } }) => (
            <TabView
              key={key}
              label={label}
              value={value}
            />
          ))}
        </TabsView>
      )}
      {value !== null &&
        Children.map(snowTabs, (child, index) => {
          return child.props.value === value ? child : null
        })}
    </div>
  )
}

const TabContent = ({ children }) => (
  <TabContentView >
    {children}
  </TabContentView>
)

export { TabContent, TabContent as SnowTabContent }
export default ScTabs
