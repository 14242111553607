import React from 'react'
import spinner from '../../resources/assets/snow-mark.png'
import styled from 'styled-components'

const SpinnerBoxView = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.mainPalette.color.contrast07};
  z-index: 1000000;
  top: 0;
  left: 0;
  ${({ iscentered }) =>
    iscentered
      ? `
      justify-content: center;
      align-items: center;
    `
      : ''}
`

const SpinnerView = styled.div`
  width: 80px;
  height: 80px;
  animation: ${({ theme }) => theme.animation.rotationSlow};
  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`

const ScSpinner = ({ centered = true }) => {
  return (
    <SpinnerBoxView
      iscentered={centered ? 1 : 0}
      aria-label={'spinner-wrapper'}
    >
      <SpinnerView>
        <img alt="Snow Commerce" src={spinner} width={80} />
      </SpinnerView>
    </SpinnerBoxView>
  )
}

export default ScSpinner
