import React, { Fragment, Suspense } from 'react'
import Snowfall from 'react-snowfall'
import { ScLoginWithGoogleBtn } from '../../../../../shared/components/buttons'
import { ReactComponent as SnowAxisLogo } from '../../../../app/images/snowaxis-logo-color.svg'
import useGoogleLoginHook from '../../../hooks/useGoogleLoginHook'
import styled, { css } from 'styled-components'

const LoginView = styled.div`
  height: 100vh;
  width: 100%;
  min-height: 100vh;
  display: flex;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.mainPalette.color.mainDark};
`

const LoginAccountCard = styled.section`
  padding: 50px 60px 30px;
  margin: auto;
  box-sizing: border-box;
  max-width: 437px;
  min-height: 256px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.main};
    background-color: ${theme.mainPalette.color.contrast};
    box-shadow: ${theme.shadow.main};
  `}
`

const LoginAccountLogo = styled.div`
  margin: 0 auto 16px;
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: auto;
  svg {
    max-width: 100%;
    height: auto;
  }
`

// Background for LoginBtn
// determine date to be used for displaying snowfall or not
const currentDate = new Date()

const JAN = 1
const DEC = 1
const NOV = 1

const winterMonths = [NOV, DEC, JAN]
const isItWinter = winterMonths.includes(currentDate.getMonth())
console.log(isItWinter ? 'It is winter' : 'It is not winter')

const LogIn = ({ children, ...props }) => {
  const { signIn } = useGoogleLoginHook()

  return (
    <Fragment>
      {isItWinter && <Snowfall snowflakeCount={200} />}
      <LoginView>
        <LoginAccountCard>
          <LoginAccountLogo>
            <SnowAxisLogo width='250' height='89' />
          </LoginAccountLogo>
          <Suspense fallback={'Logging in'}>
            <ScLoginWithGoogleBtn onClick={signIn} />
          </Suspense>
        </LoginAccountCard>
      </LoginView>
    </Fragment>
  )
}

LogIn.propTypes = {
  // setUser: PropTypes.func.isRequired
}

export default LogIn
