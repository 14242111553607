import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import FlatFieldList from '../../../components/configuration/FlatFieldList'
import ConfigGroup from './components/ConfigGroup'
import { currentIntegrationActualFieldsState } from '../../../data/integrationModel'
import { ScStackedItems } from '../../../../../shared/components/LayoutComponents'

const ConfigView = ({ config }) => {
  const integrationActualFields = useRecoilValue(
    currentIntegrationActualFieldsState
  )

  console.log('ConfigView! o a u!', { integrationActualFields, config })
  const [configGroups, setConfigGroups] = useState([])
  const updateValue = ({ code, value }) => {
    console.log('ConfigView updateValue', { code, value })
  }

  const updateError = (error) => {
    console.log('ConfigView level field update error handler')
  }
  useEffect(() => {
    console.log(`${config.title} tab ConfigView constructed`)
    return () =>
      console.log(`${config.title} tab ConfigView destructed`, config.title)
  }, [])

  useEffect(() => {
    console.log('ConfigView useEffect config', config)
    config?.groups &&
      setConfigGroups(
        <>
          {!!config.fields.length && (
            <FlatFieldList
              fieldList={config.fields}
              updateValue={updateValue}
              onError={updateError}
            />
          )}
          {config.groups.map((groupConfig) => {
            console.log('fieldGroup', groupConfig)
            return (
              <ConfigGroup
                key={`${groupConfig.title}-${groupConfig.group_id}`}
                groupConfig={{
                  config_entity_id: config.config_entity_id,
                  config_entity_type: config.config_entity_type,
                  ...groupConfig
                }}
                updateValue={updateValue}
                onError={updateError}
              />
            )
          })}
        </>
      )
  }, [integrationActualFields, config])

  return (
    <ScStackedItems aria-label={`ConfigView`}>{configGroups}</ScStackedItems>
  )
}

export default ConfigView
