import React, { useState } from 'react'
import { IntegrationContext } from './data/integrationModel'
import { SnowBox } from '../../shared/components/snowComponents'
import { Outlet } from 'react-router-dom'

import useVendors from './hooks/useVendors'
import useProjects from './hooks/useProjects'

const Integrations = () => {
  const [selectedClient, setClient] = useState(null)
  const [selectedChannel, setChannel] = useState(null)
  const [selectedVendor, setVendor] = useState(null)
  const [selectedIntegration, setIntegration] = useState(null)
  const [selectedProject, setProject] = useState(null)

  useVendors()
  useProjects()
  return (
    <SnowBox>
      <IntegrationContext.Provider
        value={{
          selectedClient,
          selectedChannel,
          selectedVendor,
          selectedIntegration,
          selectedProject,
          setClient,
          setChannel,
          setVendor,
          setProject,
          setIntegration
        }}
      >
        <Outlet />
      </IntegrationContext.Provider>
    </SnowBox>
  )
}

export default Integrations
