import styled, { css } from 'styled-components'

const SidebarBlockTitleView = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-root {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.secondary};
    `}
  }
  margin-bottom: 1rem;
`

export default SidebarBlockTitleView
