import { atom, atomFamily, selector, selectorFamily } from 'recoil'
import { apiDataRequestor } from '../../../infrastructure/api/v2/apiDataRequestor'
import { SA_SETTINGS } from '../infrastructure/apiConfigs'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

const snowShippingMethodsData = atom({
  key: 'snowShippingMethodsData',
  default: selector({
    key: 'shippingMethodsGetter',
    get: async ({ get }) => {
      const { data } = await apiDataRequestor(SA_SETTINGS.SNOW_SHIPPING_METHODS)
      console.log('shippingMethodsGetter data', data)
      const { data: methods } = data
      return methods
    }
  })
})

export const useSnowShippingMethods = () => {
  const shippingMethods = useRecoilValue(snowShippingMethodsData)
  return shippingMethods
}
