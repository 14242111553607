import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { ScFormModal } from '../../../../shared/components/modals'
import { ScTextField, ScCheckboxField } from '../../../../shared/components/SnowEditableField/baseFields'
import { currentMessageState } from '../../../../shared/service/Message/data/currentMessageState'
import { useUpdatePimProductsCmd } from '../../infrastructure/apiV2'
import ScMessagesPanel from '../../../../shared/components/widgets/ScMessagesPanel'

const useSyncProducts = ({ onClose }) => {
  const [pimOnly, setPimOnly] = useState(true)
  const [skusStr, setSkusStr] = useState('')
  const [subscribersStr, setSubscribesStr] = useState('')
  const setNotification = useSetRecoilState(currentMessageState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [messages, setMessages] = useState([])

  const submitCmd = useUpdatePimProductsCmd(
    {
      pimOnly,
      skusStr,
      subscribersStr
    },
    (r) => {
      console.log('onSuccess r', r)
      if (r.success) {
        console.log('All is good. Response', r)
        setNotification({
          message: 'All SKUs were successfully requested to pull',
          severity: 'success'
        })
        onClose()
      } else {
        console.log('onSuccess. But something went wrong. Response', r)
        setMessages([
          { type: 'error', text: r.error ?? 'Something went wrong.' }
        ])
      }
    },
    (r) => {
      console.log('onFail. something went wrong. Response', r)
      setMessages([{ type: 'error', text: r.error ?? 'Something went wrong.' }])
    }
  )
  const handleSubmit = (evt) => {
    evt.preventDefault()
    submitCmd().then((r) => {
      setLoading(false)
      console.log('UpdatePimValuesBtn then', r)
    })
    setLoading(true)
    setError(false)

    return false
  }
  useEffect(() => {
    return () => setSkusStr('')
  }, [])
  return {
    handleSubmit,
    loading,
    messages,
    pimOnly,
    setPimOnly,
    skusStr,
    setSkusStr,
    subscribersStr,
    setSubscribesStr
  }
}

const SyncProductsDataModal = ({ isOpen, onClose }) => {
  const {
    handleSubmit,
    loading,
    messages,
    setSkusStr,
    subscribersStr,
    setSubscribesStr,
    pimOnly,
    setPimOnly
  } = useSyncProducts({
    onClose
  })
  return (
    <ScFormModal
      loading={loading}
      open={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={'Sync SnowAxis products data'}
      content={
        <>
          <ScTextField
            label={'SKUs'}
            value={subscribersStr}
            code={'skus'}
            onUpdate={({ value }) => setSkusStr(value)}
            fullWidth
            multiline
            rows={3}
            mb={16}
          />
          <ScTextField
            label={'Subscribers'}
            value={subscribersStr}
            code={'subscribers'}
            onUpdate={({ value }) => setSubscribesStr(value)}
            fullWidth
            multiline
            rows={3}
            mb={16}
          />
          <ScCheckboxField
            label={'Sync products from PIM only'}
            onUpdate={({ value }) => {
              console.log('status changes', value)
              setPimOnly((value) => !value)
            }}
            value={pimOnly}
          />
        </>
      }
      message={<ScMessagesPanel messages={messages}/>}
      labelToSubmitBtn={'Sync SKUs'}
    />
  )
}

export default SyncProductsDataModal
