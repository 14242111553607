import { SnowTableCell } from '../../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const OrderPaymentCellView = styled(SnowTableCell).attrs({
  size: 'small'
})`
  &.MuiTableCell-root {
    border-bottom: none;
    font-weight: ${({ theme }) => theme.fontWeight.thin};
  }
`

const OrderPaymentTitleCellView = styled(OrderPaymentCellView)`
  &.MuiTableCell-root {
    width: 120px;
    box-sizing: border-box;
    vertical-align: top;
  }
`

export { OrderPaymentCellView, OrderPaymentTitleCellView }