import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import { revalidateOrder, fetchSingleOrder } from '../../infrastructure/fetches'
import { ThemeContext } from 'styled-components'

const RevalidateModal = (props) => {
  const theme = useContext(ThemeContext)

  const navigate = useNavigate()
  const fetchUpdatedOrder = (orderID) => {
    fetchSingleOrder({ orderID }).then((response) => {
      // push new order to OrderDetail state
      props.updateOrderInDetailState(response.data)
      // push new order to OrderGrid setState
      // props.updateOrderInGridState(response.data)
      // close modal
      props.toggleShowModal()
      props.toggleShowConfirmationModal({
        success: true,
        message: 'Data has been revalidated for order number'
      })
    })
  }

  const handleError = (data = '') => {
    console.log('RevalidateModal handle Error', data)
    props.toggleShowModal()
    props.toggleShowConfirmationModal({
      success: false,
      message: data.error
    })

    if (data.status === 'Token is Invalid') {
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }

  const handleConfirmation = (order) => {
    console.log('handleConfirmation order', order)
    const orderID = order.id
    revalidateOrder({ orderID }).then((data) => {
      console.log('handleConfirmation revalidateOrder then()', data)
      if (data.success) {
        fetchUpdatedOrder(orderID)
      } else {
        handleError(data)
      }
    })
  }

  return (
    <ScConfirmationAlert
      isShowAttention={props.showModal}
      onClose={props.toggleShowModal}
      onConfirm={() => handleConfirmation(props.order)}
      labelForConfirmBtn={'Yes, Revalidate'}
      labelForCancelBtn={'Go Back'}
      message={{
        content: (
          <>
            {' '}
            <SnowTypography variant={'body1'} component={'p'} align={'center'}>
              {`Are you sure you would like to revalidate order number`}
            </SnowTypography>
            <SnowTypography
              variant={'h6'}
              component={'p'}
              align={'center'}
              fontWeight={theme.fontWeight.bold}
            >
              {props.order.orderNumber}
            </SnowTypography>
          </>
        )
      }}
    />
  )
}

RevalidateModal.propTypes = {
  // order: PropTypes.shape().isRequired,
  // history: PropTypes.shape().isRequired,
  // showModal: PropTypes.bool.isRequired,
  // toggleShowModal: PropTypes.func.isRequired,
  // updateOrderInDetailState: PropTypes.func.isRequired,
  // updateOrderInGridState: PropTypes.func.isRequired,
  // toggleShowConfirmationModal: PropTypes.func.isRequired
}

export default RevalidateModal
