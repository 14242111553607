import React from 'react'
import { DropdownButton } from '../_styled'
import { Filter, Select } from '../_styled'
import PropTypes from 'prop-types'

const PaymentFilter = (props) => (
  <Filter>
    <Select
      name="paymentMethod"
      value={props.selectValue}
      onChange={props.setFilter}
    >
      <option value="">Payment</option>
      <option value="shopify_payments">Shopify</option>
      <option value="paypal">Paypal</option>
      <option value="evergent">Evergent</option>
      <option value="braintree">Braintree</option>
    </Select>
    <DropdownButton/>
  </Filter>
)

PaymentFilter.propTypes = {
  selectValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired
}

export default PaymentFilter
