import React from 'react'
import { ScGoBackButton } from '../../../../../../shared/components/buttons'
import { useNavigate } from 'react-router-dom'

const HeaderButtons = () => {
  const navigate = useNavigate()
  const goBack = () => {
    navigate(-1)
  }
  return <ScGoBackButton onBack={goBack} label={'Go Back'} />
}

export default HeaderButtons
