import React from 'react'
import { SnowBox, SnowChip } from './snowComponents'
import { SnowDoneIcon, SnowReportProblemIcon } from './icons'
import styled, { css } from 'styled-components'

const StyledChip = styled(SnowChip)`
  &.MuiChip-root {
    ${({ theme, colorchip }) => css`
      border: 2px solid ${theme.mainPalette.color.contrast};
      background: ${theme.mainPalette.chipStatus[colorchip]};
    `}
    padding: 0.7rem 0;
    ${({ isaction, theme }) =>
    isaction
      ? css`
            cursor: pointer;
            box-shadow: ${theme.shadow.chip};
          `
      : ''}
  }
  .MuiChip-label {
    text-transform: capitalize;
    ${({ theme }) => css`
      color: ${theme.mainPalette.grey[500]};
      font-size: ${theme.fontSize.middle};
      font-weight: ${theme.fontWeight.main};
      line-height: ${theme.lineHeight.main};
    `}
  }
`

const StyledIcon = styled.span`
  svg {
    fill: orange;
    color: yellow;
    ${({ isaction, theme }) =>
    isaction
      ? css`
            box-shadow: ${theme.shadow.chip};
          `
      : ''}
  }
`

export const getStatusColor = (status) => {
  switch (String(status).toLowerCase()) {
    case 'new':
    case 'ready':
      return 'ready'
    case 'paid':
    case 'closed':
    case 'shipped':
    case 'complete':
      return 'closed'
    case 'personalization_validation':
    case 'sent':
    case 'processing':
      return 'inProcess'
    case 'cancelled':
      return 'cancelled'
    case 'pending':
    case 'notready':
    case 'not_ready':
    case 'holded':
    case 'holded_admin':
    case 'error_validation':
    case 'hold':
    case 'error':
      return 'error'
    default:
      return 'inProcess'
  }
}

const StatusIcon = (props) => {
  const { status } = props
  const icons = {
    error: <SnowReportProblemIcon />,
    ready: <SnowDoneIcon />
  }
  console.log('StatusIcon', {
    props,
    icons,
    status,
    iconsStatus: icons[status]
  })
  return icons[status] ? icons[status] : <SnowReportProblemIcon />
}

function ScStatusChip({ status, handleClick, icon: Icon = null, ...props }) {
  console.log('StatusChip', { status, handleClick })

  return (
    <SnowBox {...props} onClick={handleClick}>
      <StyledChip
        clickable={false}
        size="small"
        // icon={<StatusIcon status={status}/>}
        label={<>{String(status).replace('_', ' ')}</>}
        colorchip={getStatusColor(status)}
        isaction={handleClick ? 1 : 0}
        className={'status-' + status}
      />
      {Icon && (
        <StyledIcon>
          <Icon
            isaction={handleClick ? 1 : 0}
            fontSize={'small'}
            color={'secondary'}
          />
        </StyledIcon>
      )}
    </SnowBox>
  )
}

export default ScStatusChip
