// route to get configuration field's groups:
// /portal/v2/configurations/groups?type=marketplace&entity_id=8
// possible types:  marketplace, shop, vendor, script.
//
// route to get configuration fields of particular group for marketplace:
// /portal/v2/configurations/groups?type=marketplace&entity_id=8&group_id=65
//
// route to get configuration fields of particular group for vendor:
// /portal/v2/configurations/groups?type=vendor&entity_id=8&shop_id=10&marketplace_id=25&group_id=21

import objWithParent from '../../../../utils/parenter'
import { useEntityCommand } from '../../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../../infrastructure/api/utils'

const CONFIGURATION_SAVE_URL = 'save'

const API_CONFIGURATION = objWithParent({
  url: '/configurations',
  SAVE_CONFIGURATION: {
    version: 'v2',
    url: `${CONFIGURATION_SAVE_URL}`,
    method: 'POST',
    caller: 'CONFIGURATION_SAVE_URL!',
    validator: ({
      config_entity_type,
      config_entity_id,
      new_values,
      marketplace_id,
      ...rest
    }) => {
      console.log(
        'validator',
        rest,
        config_entity_type,
        config_entity_id,
        new_values,
        marketplace_id
      )
      return (
        config_entity_type && config_entity_id && new_values && marketplace_id
      )
    }
  }
})

export const useSaveConfigurationCmd = (
  {
    config_entity_type,
    config_entity_id,
    new_values,
    shop_id = '0',
    marketplace_id = '0'
  },
  onSuccess = (r) => console.log('useCreateBundleItemCmd onSuccess', r),
  onFail = (r) => console.log('useCreateBundleItemCmd onFail', r)
) => {
  const saveConfigurationCmd = useEntityCommand(
    0,
    {
      ...API_CONFIGURATION.SAVE_CONFIGURATION,
      params: {
        config_entity_type,
        config_entity_id,
        new_values,
        marketplace_id,
        shop_id
      }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return saveConfigurationCmd
}
