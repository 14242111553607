import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { ScComboField } from '../SnowEditableField/baseFields'
import { searcherState } from './data/searcherState'

const Searcher = ({
  onSearchUpdate = (str) => {
    console.log('onSearchUpdate', str)
  },
  label = 'search',
  placeholder = '',
  tooltip = '',
  tooltipNewValue = false,
  disa
}) => {
  const [searchStr, setSearchStr] = useRecoilState(searcherState)
  const resetSearchStr = useResetRecoilState(searcherState)
  const [inputValue, setInputValue] = useState(searchStr)
  console.log('Searcher searchStr', searchStr, inputValue)

  const searchTimer = useRef(0)
  useEffect(() => {
    // console.log('Searcher useEffect searchStr ', searchStr)

    setInputValue(searchStr)
  }, [searchStr])
  useEffect(() => {
    return () => {
      // console.log('resetSearchStr')
      resetSearchStr()
      clearTimeout(searchTimer.current)
    }
  }, [])

  const onSearchChange = (evt) => {
    const typedStr = evt.value

    console.log('typedStr', typedStr)

    const runSearch = () => {
      setSearchStr(typedStr)
      onSearchUpdate(typedStr)
    }
    setInputValue(typedStr)
    if (inputValue !== typedStr) {
      if (searchTimer.current) {
        clearTimeout(searchTimer.current)
      }
      searchTimer.current = setTimeout(runSearch, 1000)
    }
  }
  return (
    <ScComboField
      focused={true}
      // autoFocus={true}
      tooltip={tooltip}
      tooltipNewValue={tooltipNewValue}
      placeholder={placeholder}
      variant={'outlined'}
      size={'small'}
      label={label}
      disableIndicatorNewValue={true}
      value={inputValue}
      onUpdate={onSearchChange}
      code={'input-search'}
    // ref={searcherRef}
    />
  )
}

export default Searcher
