import objWithParent from '../../../../utils/parenter'
import { useEntityCommand } from '../../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../../infrastructure/api/utils'
const CLIENT_CREATE_URL = `add`

const API_CLIENTS = objWithParent({
  url: '/clients',
  ADD: {
    version: 'v1',
    url: `${CLIENT_CREATE_URL}`,
    method: 'POST',
    caller: 'CLIENT_CREATE_URL!',
    validator: ({ client_name }) => true
  }
})

export const useCreateClientCmd = (
  { clientName },
  onSuccess = (r) => console.log('useCreateClientCmd onSuccess', r),
  onFail = (r) => console.log('useCreateClientCmd onFail', r)
) => {
  const createClientCmd = useEntityCommand(
    0,
    {
      ...API_CLIENTS.ADD,
      params: {
        client_name: clientName
      }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return createClientCmd
}
