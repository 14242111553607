const orderVendorOrderMap = [
  { requestParam: 'errors', entityProp: 'errors' },
  { requestParam: 'id', entityProp: 'id' },
  { requestParam: 'number', entityProp: 'number' },
  { requestParam: 'order_items', entityProp: 'orderItems' },
  {
    requestParam: 'shipment_import_status',
    entityProp: 'shipmentImportStatus'
  },
  { requestParam: 'status', entityProp: 'status' },
  { requestParam: 'vendor', entityProp: 'vendorCode' },
  { requestParam: 'vendor_name', entityProp: 'vendorName' },
  { requestParam: 'vendor_number', entityProp: 'vendorNumber' },
  { requestParam: 'vendor_status', entityProp: 'vendorStatus' },
  { requestParam: 'created_at', entityProp: 'createdAt' }
]
const responseToEntity = (params, paramsMap) => {
  const paramToEntityValue = (value, type) => {
    switch (type) {
      case 'numeric':
        return Number(value)
      case 'string':
        return `${value}`
    }
    return value
  }
  return paramsMap.reduce((acc, { entityProp, requestParam, type }) => {
    return {
      ...acc,
      [entityProp]: paramToEntityValue(params[requestParam], type)
    }
  }, {})
}
export const orderVendorOrderModel = (rawData) => {
  const vendorOrderEntity = responseToEntity(rawData, orderVendorOrderMap)
  console.log('vendorOrderEntity', vendorOrderEntity)
  return {
    ...vendorOrderEntity
  }
}
export default orderVendorOrderModel
