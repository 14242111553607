import React, { useEffect, useState } from 'react'
import {
  SnowTabs,
  SnowTab,
  SnowBox
} from '../../shared/components/snowComponents'
import {
  useScPageHeaderTitle,
  useScPageHeaderSubtitle
} from '../app/data/currentHeadingState'
import { useCarriersApi } from './infrastructure/carriersApi'
import usePlatforms from '../../domains/integrations/hooks/usePlatforms'
import CarriersContent from './tabs/CarriersContent'
import PlatformsMappingContent from './tabs/PlatformsMappingContent'
import VendorsMappingContent from './tabs/VendorsMappingContent'
import styled from 'styled-components'

const CarriersTitleBlockView = styled.div`
  margin-bottom: 16px;
`

const CarriersTabsView = styled(SnowTabs)`
  margin-bottom: ${({ theme }) => theme.spacing.secondary};
`

const useAreaPageUX = () => {
  const [, setPageHeader] = useScPageHeaderTitle()
  const [, setPageSubheader] = useScPageHeaderSubtitle()

  useEffect(() => {
    setPageHeader('Carriers Management')
    setPageSubheader('Feel free to add/update/remove carriers')
  }, [])
}

function a11yProps(index) {
  return {
    id: `carrier-tab-${index}`,
    'aria-controls': `carrier-tabpanel-${index}`
  }
}

function TabPanel(props) {
  const { children, tabIndex, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={tabIndex !== index}
      id={`carriers-tabpanel-${index}`}
      aria-labelledby={`carriers-tab-${index}`}
      {...other}
    >
      {tabIndex === index && <SnowBox>{children}</SnowBox>}
    </div>
  )
}

const CarriersPage = () => {
  const { carriers } = useCarriersApi()
  const platforms = usePlatforms()

  const [tabIndex, setTabIndex] = useState(0)

  const handleChange = (event, newValue) => {
    setTabIndex(newValue)
  }

  useAreaPageUX()

  const tabsData = [
    { title: 'Carriers', content: <CarriersContent /> },
    {
      title: 'Platform Mappings',
      content: (
        <PlatformsMappingContent carriers={carriers} platforms={platforms} />
      )
    },
    {
      title: 'Vendor Mappings',
      content: <VendorsMappingContent carriers={carriers} />
    }
  ]

  return (
    <>
      {/* <CarriersTitleBlockView>
        <SnowTypography component={'h1'} variant={'h6'} fontWeight={500}>
          Carriers Management
        </SnowTypography>
      </CarriersTitleBlockView> */}
      <CarriersTabsView
        value={tabIndex}
        onChange={handleChange}
        aria-label="carriers mapping tabs"
        indicatorColor="primary"
        textColor="primary"
      >
        {tabsData?.map(({ title }, index) => (
          <SnowTab
            key={`${title}-${index}`}
            label={title}
            {...a11yProps(index)}
          />
        ))}
      </CarriersTabsView>
      {tabsData?.map(({ title, content }, index) => (
        <TabPanel
          key={`${title}-content-${index}`}
          tabIndex={tabIndex}
          index={index}
        >
          {content}
        </TabPanel>
      ))}
    </>
  )
}

export default CarriersPage
