import React, { useEffect, useState } from 'react'
import { SnowTableRow, SnowTableCell, SnowCollapse } from '../../snowComponents'
import { ScCollapseButton, ScCrudButton } from '../../buttons'
import styled from 'styled-components'
import { TableRowContentView } from '../../../../domains/app/App/_styled/TableContentView'

const CollapsibleTableHeaderRowView = styled(SnowTableRow)`
  &.MuiTableRow-root {
    ${TableRowContentView}
  }
  .MuiTableCell-root {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    border-top: ${({ theme }) => theme.border.main};
  }
`

const CollapsibleTableContentRowView = styled(SnowTableRow)`
  .MuiTableCell-root {
    border-bottom: 3px solid lightgrey;
  }
`

const CollapsibleTableButtonsCellView = styled(SnowTableCell)`
  width: ${({ width }) => (width ? width : 'auto')};
  display: flex;
  box-sizing: border-box;
  &.MuiTableCell-root {
    padding: 0;
  }
  .ScCrudButton {
    svg {
      fill: ${({ theme }) => theme.mainPalette.grey.middle};
    }
  }
  .ScCrudButton--edit, .ScCrudButton--remove {
    svg {
      fill: ${({ theme }) => theme.mainPalette.color.secondary};
    }
  }
`

const ScCollapsibleTableRow = ({
  data,
  columns = {},
  CollapsibleContent,
  open = false,
  isNew = false,
  onSave,
  onEdit,
  onRemove
}) => {
  const [isOpen, setIsOpen] = useState(open || isNew)
  const [rowData, setRowData] = useState(data)

  const handleSave = () => {
    onSave && onSave(data)
    setIsOpen(false)
  }
  const handleEdit = () => {
    onEdit && onEdit(data)
    setIsOpen(true)
  }
  const handleRemove = () => {
    onRemove && onRemove(data)
    setIsOpen(false)
  }
  useEffect(() => {
    setRowData(data)
  }, [data])
  console.log('ScCollapsibleTableRow data', isOpen, data, rowData)
  return (
    <>
      <CollapsibleTableHeaderRowView aria-label={`isOpen-${isOpen}`}>
        <CollapsibleTableButtonsCellView width={'96px'}>
          <ScCollapseButton open={isOpen} onClick={() => setIsOpen(!isOpen)} />
          <ScCrudButton
            editMode={isOpen}
            onEdit={handleEdit}
            onSave={onSave && handleSave}
          />
        </CollapsibleTableButtonsCellView>
        {Object.keys(columns).map((fieldKey) => (
          <SnowTableCell key={fieldKey} component="th" scope="row">
            {columns[fieldKey].options
              ? columns[fieldKey].options[rowData[fieldKey]]
              : rowData[fieldKey]}
          </SnowTableCell>
        ))}
        <CollapsibleTableButtonsCellView>
          <ScCrudButton editMode={true} onRemove={handleRemove} />
        </CollapsibleTableButtonsCellView>
      </CollapsibleTableHeaderRowView>
      {isOpen && (
        <CollapsibleTableContentRowView>
          <SnowTableCell colSpan={6}>
            <SnowCollapse in={isOpen} timeout="auto" unmountOnExit>
              <CollapsibleContent data={rowData} />
            </SnowCollapse>
          </SnowTableCell>
        </CollapsibleTableContentRowView>
      )}
    </>
  )
}

export default ScCollapsibleTableRow
