import React from 'react'
import { SnowArrowBackIcon } from '../../icons'
import { SnowButton } from '../../snowComponents'
import styled from 'styled-components'

const ButtonView = styled(SnowButton)`
  &.MuiButtonBase-root{
    ${({theme})=>`
      font-weight: ${theme.fontWeight.thin};
      color: ${theme.mainPalette.typography.main};
      :hover {
        background: ${theme.mainPalette.grey.soLight};
      }
    `}
  }
  svg {
    width: 15px;
  }

`

const ScGoBackButton = ({ onBack, label = 'Go Back' }) => {
  return (
    <ButtonView onClick={onBack} startIcon={<SnowArrowBackIcon />}>
      {label}
    </ButtonView>
  )
}

export default ScGoBackButton
