import React from 'react'
import { integrationCqrsRequestModel } from '../../data/integrationDeterminant'
import { useRecoilState } from 'recoil'
import ChannelChooser from './ChannelChooser'

const IntegrationChannelChooser = ({
  projectId = '0',
  onIntegrationSelected
}) => {
  const [integrationParams, setIntegrationParams] = useRecoilState(
    integrationCqrsRequestModel
  )
  console.log('IntegrationChannelChooser', projectId)
  const onChannelConfirm = (channelDto) => {
    console.log('onChannelDoubleClicked channelDto', channelDto)
    const { id } = channelDto

    console.log('IntegrationChannelChooser onChannelConfirm dto', channelDto)

    setIntegrationParams((oldParams) => ({
      ...oldParams,
      channel: channelDto.id,
      project: projectId,
      vendor: '0'
    }))
    onIntegrationSelected(id, { channel: channelDto })
  }
  const onChannelClicked = (channelDto) => {
    setIntegrationParams((oldValues) => ({
      ...oldValues,
      channel: channelDto.id,
      project: projectId,
      vendor: '0'
    }))
  }
  return (
    <ChannelChooser
      projectId={projectId}
      onConfirm={onChannelConfirm}
      onItemSelect={onChannelClicked}
      dtoFields={['id', 'name']}
    />
  )
}

export default IntegrationChannelChooser
