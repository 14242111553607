import { useEffect, useState } from 'react'
import { useOrderItemsFetchActionFieldsCmd } from '../../infrastructure/orderItemsApi'
import { pathifyObjectFields } from '../../../../utils/helpers'

const useUnpreorderFields = ({ itemIds = [], filters }) => {
  const fetchEditableFieldsCmd = useOrderItemsFetchActionFieldsCmd({
    action: 'unpreorder',
    itemIds,
    filters
  })

  const [fieldsObj, setFieldsObj] = useState({})
  const [updatedItems, setUpdatedItems] = useState({})
  const [originPathsProps, setOriginPathsProps] = useState({})

  const onUpdate = ({ code, value, key, ...rest }) => {
    console.log('onUpdate', { code, value, key, rest })
  }

  useEffect(() => {
    const getEditableFields = async () => {
      const { data: fields, error, isLoading } = await fetchEditableFieldsCmd()
      if (error) {
        console.log('useUnpreorderFields error: ' + error)
        return false
      }
      console.log('useUnpreorderFields', fields)
      setOriginPathsProps(pathifyObjectFields(fields))
      setFieldsObj(fields)
    }
    getEditableFields()
  }, [])
  console.log('originPathsProps', originPathsProps)
  return { fields: fieldsObj, updatedItems, onUpdate }
}

export default useUnpreorderFields
