import React, { useEffect, useState } from 'react'
import {
  SnowTable,
  SnowTableBody,
  SnowTableHead,
  SnowTableRow,
  SnowTableCell,
  SnowPaper,
  SnowTypography,
} from '../../snowComponents'
import ScTableRow from './sc-table-row'
import { TableColumnTitleView } from '../../../../domains/app/App/_styled/TableContentView'
import styled from 'styled-components'

const TableTitleView = styled(SnowTypography).attrs({
  component: 'span'
})`
  &.MuiTypography-root{
    ${TableColumnTitleView}
  }
`

const ScTablePaperView = styled(SnowPaper)`
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.mainPalette.grey.main};
  overflow: scroll;
`

export const ScTable = ({
  items: tableItems, // table rows
  fieldsToColumnsMap, //map from raw fields to table columns
  onItemClick: onItemSelect,
  onItemDblClick,
  dataKeyField = 'id',
  onItemSave,
  onItemDelete,
  editable = false,
  actionsComponent = null,
  // padding = 'normal',
  pad = '16px',
  adoptTableItem = (rawData) => ({ data: rawData }),
  getItemData = (item) => item
}) => {
  const [isInvalid, setInvalid] = useState(false)

  //rows are channelItems (data with custom props for table) or empty array
  const [items, setItems] = useState(
    Array.isArray(tableItems) ? tableItems.map(adoptTableItem) : []
  )

  const fieldsToShow = fieldsToColumnsMap.filter(({ visible }) => !!visible)

  //todo: map to tableColumns only fields crossed with exisiting in channels
  // really
  const tableColumns = fieldsToShow.map((field) => ({
    title: field.title,
    active: false,
    code: field.code
  }))

  const getListItemByRow = (_rowData) => {
    console.log(tableItems, _rowData)
    return tableItems.find(
      (item) => getItemData(item)[dataKeyField] === _rowData[dataKeyField]
    )
  }

  const onListItemSave = (_rowData) => {
    console.log('onListItemSave', _rowData)
    if (onItemSave)
      onItemSave({ ...getListItemByRow(_rowData), data: _rowData })
    setInvalid(true)
  }

  const onListItemSelect = (_rowData) => {
    console.log('onListItemSelect', _rowData, getListItemByRow(_rowData))
    if (onItemSelect) {
      onItemSelect(getListItemByRow(_rowData))
    }
    // setInvalid(true)
  }

  const onListItemDelete = (_rowData) => {
    console.log('onListItemDelete rows', _rowData, onItemDelete)
    if (onItemDelete) {
      onItemDelete(getListItemByRow(_rowData))
    }
  }

  useEffect(() => {
    console.log('useEffect tableItems, fieldsToColumnsMap', {
      tableItems,
      fieldsToColumnsMap
    })
    const newListItems = tableItems
      ?.map(adoptTableItem)
      ?.map(({ data, ...other }) => {
        const matchedFields = Object.keys(data).filter((fieldCode) =>
          fieldsToColumnsMap.map((field) => field.code).includes(fieldCode)
        ) // get row fields matched to fields map  (may be needs
        // simplify then)
        return matchedFields.reduce(
          (obj, key) => {
            obj.data[key] = data[key]
            return obj
          },
          { data: {}, ...other }
        ) //fill up row with matched fields
        // only
      })
    console.log('table data , fieldsToColumnsMap, tableRows', {
      tableItems,
      fieldsToColumnsMap,
      newListItems
    })
    setItems(newListItems)
    setInvalid(false)
  }, [tableItems, fieldsToColumnsMap])

  console.log('ScTable render', {
    items,
    tableItems,
    fieldsToColumnsMap,
    onItemSelect,
    isInvalid
  })

  return (
    <ScTablePaperView aria-label={'sc-table'}>
      {/*{*/}
      {/*    JSON.stringify(items)*/}
      {/*}*/}
      {
        <SnowTable>
          <SnowTableHead>
            <SnowTableRow>
              {(editable || actionsComponent) && (
                <SnowTableCell sx={{ p: pad }}
                >
                  <TableTitleView>Actions</TableTitleView>
                </SnowTableCell>
              )}
              {tableColumns.map((column) => (
                <SnowTableCell key={column.code} sx={{ p: pad }}
                >
                  <TableTitleView >{column.title}</TableTitleView>
                </SnowTableCell>
              ))}
            </SnowTableRow>
          </SnowTableHead>
          <SnowTableBody>
            {items.map((_row, idx) => {
              const { isEdit, isSelected, data } = _row
              return (
                <ScTableRow
                  // padding={padding}
                  pad={pad}
                  actionsComponent={actionsComponent}
                  key={data[dataKeyField]}
                  data={data}
                  isEdit={isEdit}
                  selected={isSelected}
                  {...{ editable }}
                  onRowSelect={onListItemSelect}
                  onRowDblclick={onItemDblClick}
                  onRowSave={onListItemSave}
                  onRowDelete={onListItemDelete}
                  fieldsToShow={fieldsToShow}
                />
              )
            })}
          </SnowTableBody>
        </SnowTable>
      }
    </ScTablePaperView>
  )
}
export default ScTable
