import React from 'react'
import { SnowTypography } from '../../../shared/components/snowComponents'
import { ScDropdownField } from '../../../shared/components/SnowEditableField/baseFields'

export const PerPageSelector = ({
  defaultLimit = 8,
  total = 0,
  onChange = (limit) => {
    console.log('new limit', limit)
  }
}) => {
  const limits = [4, 8, 12, 16, 24]
  const handleChange = ({ value }) => onChange(value)
  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      {/*Show Per Page:{' '}*/}
      <ScDropdownField
        labelPlacement={'start'}
        label={'Show Per Page:'}
        value={defaultLimit.toString()}
        meta={{ options: limits.map(limit=>typeof limit==='string'? limit:limit.toString()) }}
        onUpdate={handleChange}
      />
      {/*<ScSelect value={defaultLimit} options={limits} onChange={handleChange} />*/}
      <SnowTypography sx={{minWidth:'fit-content', ml:'8px'}}>{!!total && `of ${total}`}</SnowTypography>
    </div>
  )
}
