import React from 'react'
import Searcher2 from '../../../../../shared/components/searcher/Searcher2'
import { Filter } from '../_styled'
import PropTypes from 'prop-types'

const StoreFilter = (props) => (
  <Filter>
    <Searcher2
      code="purchasedFrom"
      placeholder={'Store'}
      value={props.selectValue}
      meta={props.meta}
      tooltip={props.tooltip}
      disableClearable={false}
      onClear={() => props.setFilter('purchasedFrom', '')}
      onSearchUpdate={(value) => {
        console.log('onSearchUpdate', value)
        props.setFilter('purchasedFrom', value)
      }}
    />
  </Filter>
)

StoreFilter.propTypes = {
  selectValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  isFilterActive: PropTypes.string.isRequired
}

export default StoreFilter
