import { selectorFamily } from 'recoil'
import { apiDataRequestor } from '../../../infrastructure/api/v2/apiDataRequestor'
import { SA_AUTH } from './authInfra'
import {
  extractFromStorage,
  keepInStorage
} from '../../../infrastructure/service/localStorage/helper'

export const fetchClientId = selectorFamily({
  key: 'fetchClientId',
  get:
    (authProvider) =>
      async ({ get }) => {
        const checkAuthClient = () => { //BYPASS_AUTH
          if (process.env.REACT_APP_BYPASS_AUTH === 'bypass') {
            return 'placeholder'
          }
          return extractFromStorage('clientId')
        }
        const storedClient = checkAuthClient()
        if (storedClient) return { clientId: storedClient }
        const {
          data: { client_id: clientId }
        } = await apiDataRequestor({
          ...SA_AUTH.CLIENT_ID,
          params: authProvider
        })
        keepInStorage('clientId', clientId)
        return { clientId }
      }
})
export default fetchClientId
