// This file contains functions used in Order Grid -- functions that filter
// orders using API calls

import { saFetch } from '../../../infrastructure/api/v1/requestors'

const BASE_URL = process.env.REACT_APP_URL

// single bundle info
export const fetchSingleBundle = ({ token, productId }) => {
  const fullURL = `${BASE_URL}/portal/v1/bundle/${productId}`
  return saFetch({
    token,
    url: fullURL,
    type: 'get',
    caller: 'fetchSingleBundle'
  })
}

//update bundle details - children {create,update,delete}
export const saveBundle = ({ token, data }) => {
  const fullURL = `${BASE_URL}/portal/v1/product/bundle/update`
  return saFetch({
    token,
    url: fullURL,
    type: 'post',
    caller: 'saveBundle',
    fetchParams: data
  })
}
