import styled from 'styled-components'

const PersonalizationImagePreview = styled.a`
  margin-bottom: ${({theme})=>theme.spacing.main};
  width: 100%;
  height: auto;
  min-width: 50px;
  min-height: 2em;
  display: block;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  box-shadow: ${({ theme }) => theme.shadow.main};
`

export default PersonalizationImagePreview