import React, { Fragment, useEffect, useState } from 'react'
import {
  SnowCardContent,
  SnowPaper,
  SnowTypography
} from '../../../../../shared/components/snowComponents'
import EnterTrackingRef from './EnterTrackingRef'
import styled from 'styled-components'
import { useCurrentOrder } from '../../data/currentOrderState'
import { prettifyJsonOutput } from '../../../../../utils/helpers'
import { ScLinkButton, ScPrimaryBtn } from '../../../../../shared/components/buttons'
import ScList from '../../../../../shared/components/widgets/ScList/ScList'
import { useVendorOrderApi } from '../../../infrastructure/vendorOrderApi'

const StyledCardContent = styled(SnowCardContent)`
  &.MuiCardContent-root{
      :last-child {
        padding-bottom: ${({ theme }) => theme.spacing.secondary};
      }
    }
  }
`
const StyledOrderVendorInfoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
    padding: 12px;

`

const StyledOrderVendorInfo = styled.div`
  margin-right: ${({ theme }) => theme.spacing.secondary};
`

const VendorOrderView = styled(SnowPaper)`
  padding: 12px;
  margin:0 1rem 1em;

  .MuiTypography-root {
    font-style: italic;
  }
`
const vendorOrderExample = {
  errors: [],
  id: 101135,
  number: 'Snow072E',
  orderItems: [227830, 227831],
  shipmentImportStatus: 'pending',
  status: 'processing',
  vendorCode: 'technicolor',
  vendorName: 'Technicolor',
  vendorNumber: null,
  vendorStatus: null,
  createdAt: '2022-01-27T19:52:46.000000Z'
}
const VendorOrder = ({ vendor, vendorOrder }) => {
  const currentModel = useCurrentOrder()
  const { availableActions, cancelVendorOrder, runVendorOrderAction } = useVendorOrderApi(vendorOrder?.id)
  const { orderItems } = currentModel
  const orderItemIds = vendorOrder.orderItems ?? []

  const vendorOrderItems = orderItems.filter(({ id }) => orderItemIds.includes(id))

  useEffect(() => {
    console.log('VendorOrder constructor', vendorOrderItems)
    return () => console.log('VendorOrder destructor')
  }, [])
  useEffect(() => {
    console.log('useEffect availableActions', availableActions)
  }, [availableActions])
  if (!vendorOrder) return null


  return (
    <Fragment key={JSON.stringify(vendorOrder)}>
      <VendorOrderView>
        <StyledOrderVendorInfoBlock>
          <StyledOrderVendorInfo>
            {!vendor && <SnowTypography component={'span'} fontWeight={400}>
              {`Vendor ${vendorOrder?.vendorName}`}
            </SnowTypography>}
            <SnowTypography>
              <SnowTypography component={'span'} fontWeight={400}>
                {`Vendor Order Number: `}
              </SnowTypography>
              <SnowTypography component={'span'}>
                {vendorOrder.number}
              </SnowTypography>
            </SnowTypography>
            <SnowTypography component={'p'}>
              <SnowTypography
                component={'span'}
                fontWeight={400}
              >{`Created at: `}</SnowTypography>
              <SnowTypography component={'span'}>
                {new Date(vendorOrder.createdAt).toUTCString()}
              </SnowTypography>
            </SnowTypography>
          </StyledOrderVendorInfo>
          <ScList style={{ padding: 0 }} itemProps={{ style: { fontWeight: '400', padding: '0 0 0.5rem' } }}>
            <EnterTrackingRef vendor={vendor} vendorOrder={vendorOrder}/>
            {availableActions.map(({ action_code, fields, title }) => <ScLinkButton key={action_code} label={title}
                                                                                    onClick={() => runVendorOrderAction(action_code)}/>)}

          </ScList>
        </StyledOrderVendorInfoBlock>
        <StyledOrderVendorInfoBlock>
          <div><SnowTypography component={'h3'} variant="body1" fontWeight={400}>
            Vendor Order Items:{' '}
          </SnowTypography>
            {vendorOrderItems.map((oItem) => {
              // ...new Set( //use Set to get rid of duplicates
              //           order_items.reduce(
              return (
                <SnowTypography key={oItem.sku}>
                  {oItem.name} / Sku: {oItem.sku} / Vendor Sku {oItem.vendorSku}
                </SnowTypography>
              )
            })}</div>
        </StyledOrderVendorInfoBlock>
      </VendorOrderView>
      {/*{idx !== vendorOrders.length - 1 && <Divider />}*/}
    </Fragment>
  )
}

export default VendorOrder
