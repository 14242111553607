import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { SnowMuiAlert, SnowSnackbar, SnowSlide } from '../../../components/snowComponents'
import { currentMessageState } from '../data/currentMessageState'
import styled from 'styled-components'

const MessageView = styled(SnowSnackbar)`
  margin: 0 auto;
  .MuiSnackbarContent-root {
    min-width: 0;
    box-shadow: ${({ theme }) => theme.shadow.big};
    padding: 0;
  }
  .MuiSnackbarContent-message {
    padding: 0;
  }
`

const SlideTransition = (props) => {
  return <SnowSlide {...props} direction="up" />
}

const Alert = (props) => {
  const { onClose, outputMessage, subtitle, severity } = props
  console.log('Alert!', outputMessage, props, severity)
  if (!outputMessage) return null
  return (
    <SnowMuiAlert
      variant="filled"
      sx={{ zIndex: 10000 }}
      severity={severity}
      onClose={onClose}
    >
      <>
        {outputMessage}
        <br />
        {subtitle}
      </>
    </SnowMuiAlert>
  )
}

const ScMessage = ({ message = '', severity = 'success', onReset, timeout = 10000 }) => {
  const resetMessage = useResetRecoilState(currentMessageState)
  const { subtitle, severity: currentSeverity, message: currentMessage } = useRecoilValue(currentMessageState)
  const outputMessage = currentMessage || message || ''
  const outputSeverity = (currentSeverity || severity) ?? 'info'
  const [openMessage, setOpenMessage] = useState(!!outputMessage)
  const timer = useRef(null)

  useEffect(() => {
    if (!currentMessage) return
    console.log('message', outputMessage)
    setOpenMessage(!!outputMessage)
    // if (!timer.current)
    //   timer.current = setTimeout(() => {
    //     setOpenMessage(false)
    //     timer.current = null
    //   }, timeout)
  }, [currentMessage])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    onReset()
    resetMessage()
    setOpenMessage(false)
  }
  console.log('SC MESSAGE outputMessage', outputMessage, message, outputSeverity, openMessage)
  return (
    <MessageView
      TransitionComponent={SlideTransition}
      open={openMessage}
      autoHideDuration={6000}
      onClose={handleClose}
      message={<Alert outputMessage={outputMessage} onClose={handleClose} severity={outputSeverity} subtitle={subtitle} />}
    />
  )
}

export default ScMessage
