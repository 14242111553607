import React from 'react'
import styled, { css } from 'styled-components'

const StyledStartEndWrapper = css`
  ${({ istop, justifyContent }) =>
    !istop
      ? `
    display: flex;
    justify-content: ${justifyContent};
  `
      : ``}
`

const StyledTopWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const ListLabelFieldBoxView = styled.div`
  display: flex;
  ${({ istop }) => (istop ? StyledTopWrapper : StyledStartEndWrapper)}
`

const StyledStartEndLabel = css`
  padding-top: 12px;
  margin-bottom: auto;
  flex: 0 0 auto;
  max-width: 50%;
  ${({ isstart, theme }) =>
    isstart
      ? `
    order: 0;
    margin-right: ${theme.spacing.secondary};
  `
      : `
    order: 1;
    margin-left:${theme.spacing.secondary};
  `}
`

const ListLabelView = styled.label`
  margin-bottom: 0;
  cursor: pointer;
  ${({ theme }) => `
    color: ${theme.mainPalette.typography.main};
    font-size: ${theme.fontSize.main};
    font-weight: ${theme.fontWeight.thin};
  `}
  max-width: 50%;
  ${({ istop, theme }) =>
    istop
      ? css`
          max-width: auto;
          font-size: ${theme.fontSize.soSmall};
        `
      : StyledStartEndLabel}
`

const StyledFieldStartEndWrapper = css`
  display: flex;
  justify-content: ${({ isstart }) => (isstart ? 'flex-start' : 'flex-end')};
`

const ListLabelFieldsView = styled.div`
  ${({ istop }) => (istop ? css`` : StyledFieldStartEndWrapper)}
  width: ${({ width }) => width};
`
const ScListLabel = ({
  label = '',
  labelPlacement = 'top',
  isGroupField = false,
  inputWidth = 100,
  children = {},
  code = '',
  fontSize = '',
  fontWeight = '',
  ...props
}) => {
  const isTopLabelPlacement = label && labelPlacement === 'top' ? 1 : 0
  const isStartLabelPlacement = label && labelPlacement === 'start' ? 1 : 0
  const isLabelExist = label ? true : false

  const _inputWidth = typeof inputWidth === 'string' ? 'auto' : `${inputWidth}%`

  const child = {
    ...children,
    props: {
      ...children.props
    }
  }

  return isLabelExist ? (
    <ListLabelFieldBoxView
      istop={isTopLabelPlacement}
      justifyContent={isGroupField ? 'space-between' : 'flex-start'}
      width={_inputWidth}
      aria-label="simple-label"
    >
      {isLabelExist && (
        <ListLabelView
          istop={isTopLabelPlacement}
          isstart={isStartLabelPlacement}
          htmlFor={'code-0'}
          fontSize={fontSize}
          fontWeight={fontWeight}
        >
          {label}
        </ListLabelView>
      )}
      <ListLabelFieldsView
        isstart={isStartLabelPlacement}
        width={_inputWidth}
        istop={isTopLabelPlacement}
        className="field-wrapper"
      >
        {child}
      </ListLabelFieldsView>
    </ListLabelFieldBoxView>
  ) : (
    <>{child}</>
  )
}
export default ScListLabel
