import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import Layout from '../Layout'

const WrappedRoutes = (props) => {
  const [theme] = useState('light')

  return (
    <div>
      <Layout theme={theme} mainContent={Outlet} />
    </div>
  )
}

export default WrappedRoutes
