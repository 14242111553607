import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import {
  useScPageHeaderSubtitle,
  useScPageHeaderTitle
} from '../app/data/currentHeadingState'
import { useFetchItemsCmd } from './infrastructure/orderItemsApi'
import {
  removeEmptyProps,
  toggleElementInCollection
} from '../../utils/helpers'
import { ScPrimaryBtn, ScSecondaryBtn } from '../../shared/components/buttons'
import { useSearchParams } from 'react-router-dom'
import { isDate, lightFormat } from 'date-fns'

const getFormattedDate = (date) =>
  isDate(date) && lightFormat(date, 'yyyy-MM-dd')

const prepareDateRange = (dateRange) => {
  const [_start, _end] = dateRange
  const start = isDate(_start) ? _start : new Date(_start)
  const end = isDate(_end) ? _end : new Date(_end)
  console.log('prepareDateRange', start, end, {
    start: getFormattedDate(start),
    end: getFormattedDate(end)
  })
  return JSON.stringify({
    start: getFormattedDate(start),
    end: getFormattedDate(end)
  })
}
export const useOrderItems = ({ filters = {} }) => {
  const [items, setItems] = useState([])
  const [selectedItems, setSelectedItems] = useState([])

  const [cursor, setCursorToLoad] = useState(null)
  const [total, setTotal] = useState(null)
  const [perCursor, setPerCursor] = useState(40)
  const [restRecordsCount, setRestRecordsCount] = useState(null)

  const [, setHeaderTitle] = useScPageHeaderTitle()
  const [, setHeaderSubtitle] = useScPageHeaderSubtitle()
  const [forceLoad, setForceLoad] = useState(false)
  const [forceRestLoad, setRestForceLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  console.log('filtersfilters', filters)

  const notInSelected = (id) => !selectedItems.map(({ id }) => id).includes(id)

  //map filter values to [filter]:'string value'
  const prepareFiltersToSearchParams = (filters) => {
    console.log('prepareFiltersToSearchParams', filters)
    return removeEmptyProps(
      Object.keys(filters).reduce((acc, filterKey) => {
        const fValue = filters[filterKey]
        if (typeof fValue === 'undefined') return acc

        const { start, end } = fValue //hardcode for DataRange :(

        const castedFValue = start
          ? prepareDateRange([start, end])
          : typeof fValue === 'object'
          ? JSON.stringify(fValue)
          : fValue
        console.log(
          'prepareFiltersToSearchParams castedFValue',
          filterKey,
          fValue,
          castedFValue
        )
        return { ...acc, [filterKey]: castedFValue }
      }, {})
    )
  }

  //map filter values to [filter]:'string value'
  const prepareFiltersToLoad = (filters) => {
    console.log('prepareFiltersToLoad', filters)

    return removeEmptyProps(
      Object.keys(filters).reduce((acc, filterKey) => {
        console.log('prepareFiltersToLoad castedFValue1', filterKey, filters)

        const fValue = filters[filterKey]
        if (typeof fValue === 'undefined') return acc
        const { start, end } = fValue //hardcode for DataRange :(

        const castedFValue = start
          ? prepareDateRange([start, end])
          : typeof fValue === 'object'
          ? JSON.stringify(fValue)
          : fValue
        console.log(
          'prepareFiltersToLoad castedFValue2',
          filterKey,
          fValue,
          castedFValue
        )
        return { ...acc, [filterKey]: castedFValue }
      }, {})
    )
  }
  const fetchItemsCursorCmd = useFetchItemsCmd({
    cursor,
    per_cursor: perCursor,
    filters: prepareFiltersToLoad(filters)
  })

  const fetchItemsRestCmd = useFetchItemsCmd({
    cursor,
    per_cursor: restRecordsCount,
    filters: prepareFiltersToLoad(filters)
  })

  const toggleSelectedCmd = (item) => {
    const toggledCollection = toggleElementInCollection(
      selectedItems,
      item,
      'id'
    )
    setSelectedItems(toggledCollection)
  }

  const selectItemsCmd = (ids) => {
    if (!Array.isArray(ids)) return
    if (ids.find((item) => typeof item === 'object')) return

    const keepInSelectedItems = selectedItems.filter(({ id }) =>
      ids.includes(id)
    )
    const itemsWithoutSelected = items.filter(
      ({ id }) =>
        ids.includes(id) &&
        !keepInSelectedItems.find((selected) => selected.id === id)
    )
    console.log('selectItemsCmd ', ids, selectedItems, itemsWithoutSelected)

    setSelectedItems([...keepInSelectedItems, ...itemsWithoutSelected])
  }

  const loadMore = () => {
    setCursorToLoad(cursor)
    setForceLoad(true)
  }
  const loadRest = () => {
    setCursorToLoad(cursor)
    setRestForceLoad(true)
  }
  const showLoadMore = () => {}
  const loadMoreBtn = () =>
    cursor && (
      <ScPrimaryBtn label={`Load ${perCursor} items more`} onClick={loadMore} />
    )

  const loadRestBtn = () =>
    restRecordsCount > 0 && restRecordsCount < 350 ? (
      <ScPrimaryBtn label={`Load All`} onClick={loadRest} />
    ) : restRecordsCount === 0 ? (
      <ScSecondaryBtn label={`All matched items loaded`} disabled />
    ) : null

  const loadCursorCmd = useCallback(async () => {
    const { data: payload, error, isLoading } = await fetchItemsCursorCmd()
    console.log('isLoading', isLoading)
    setForceLoad(false)
    console.log('useOrderItems loadCursorCmd loadedData', cursor, payload)
    if (!payload) return
    const {
      data,
      meta: { next_cursor },
      total_records
    } = payload
    setCursorToLoad(next_cursor)
    !cursor && setTotal(total_records)

    Array.isArray(data) &&
      setItems((items) => [
        ...items,
        ...data.filter((loadedItem) => notInSelected(loadedItem.id))
      ])
  }, [
    cursor,
    JSON.stringify(filters),
    JSON.stringify(selectedItems),
    isLoading
  ])

  const loadRestCmd = useCallback(async () => {
    const { data: payload, error, isLoading } = await fetchItemsRestCmd()
    console.log('isLoading', isLoading)
    setRestForceLoad(false)

    console.log('useOrderItems loadRestCmd loadedData', payload)

    if (!payload) return

    const {
      data,
      meta: { next_cursor }
    } = payload
    setCursorToLoad(next_cursor)
    // setTotal(total_records)
    Array.isArray(data) &&
      setItems((items) => [
        ...items,
        ...data.filter(
          (loadedItem) =>
            !selectedItems.map(({ id }) => id).includes(loadedItem.id)
        )
      ])
  }, [forceRestLoad, JSON.stringify(filters), isLoading])

  useEffect(() => {
    console.log('useOrderItems filters', filters, Object.keys(filters).length)
    if (Object.keys(filters).length > 0) {
      setItems([])
      setSelectedItems([])
      setCursorToLoad(null)
      setRestRecordsCount(null)
    }
    const preparedFilters = prepareFiltersToSearchParams(filters)
    console.log('useOrderItems preparedFilters', preparedFilters)
    setSearchParams(preparedFilters)
    setForceLoad(true)
  }, [filters])

  useEffect(() => {
    console.log('useEfffec forceLoad', forceLoad, isLoading)
    forceLoad && loadCursorCmd()
    setIsLoading(forceLoad)
  }, [forceLoad])

  useEffect(() => {
    console.log('useEfffec forceLoad', forceLoad, isLoading)
    forceRestLoad && loadRestCmd()
    setIsLoading(forceRestLoad)
  }, [forceRestLoad])

  useLayoutEffect(() => {
    items && setHeaderTitle(`Bulk Update`)
    console.log('restRecordsCount \ntotal:', total, ' loaded:', items.length)
    setRestRecordsCount(total - items.length)
  }, [items])

  useEffect(() => {
    console.log('useEffect selectedItems', selectedItems)
  }, [selectedItems])

  console.log('useOrderItems!!!', selectedItems, items, filters)

  return {
    items,
    totalFound: total,
    isLoading,
    loadMoreBtn,
    loadRestBtn,
    selectedIds: selectedItems.map(({ id }) => id),
    selectItemsCmd,
    toggleSelectedCmd
  }
}
