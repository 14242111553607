import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import { cancelOrder, fetchSingleOrder } from '../../infrastructure/fetches'
import { ThemeContext } from 'styled-components'

function getAction(status, part) {
  if (status.includes('hold')) {
    if (part === 1) {
      return 'un-hold'
    } else if (part === 2) {
      return "off of 'Hold'?"
    }
  } else {
    if (part === 1) {
      return 'hold'
    } else if (part === 2) {
      return "on 'Hold'?"
    }
  }
}

function getButtonText(status) {
  if (status.includes('hold')) {
    return 'Un-Hold'
  } else {
    return 'Hold'
  }
}

const CancelModal = (props) => {
  const theme = useContext(ThemeContext)
  const navigate = useNavigate()

  const fetchUpdatedOrder = (orderID) => {
    fetchSingleOrder({ orderID }).then((response) => {
      // push new order to OrderDetail state
      props.updateOrderInDetailState(response.data)
      // push new order to OrderGrid setState
      // props.updateOrderInGridState(response.data)
      // close modal
      props.toggleShowModal()
      props.toggleShowConfirmationModal({
        success: true,
        message: 'The following order number has been sucessfully cancelled:'
      })
    })
  }

  const handleError = (data = '') => {
    props.toggleShowModal()
    props.toggleShowConfirmationModal({
      success: false,
      message: data.error
    })

    // TODO: Match the logout based on HTTP error code and not string match of status
    if (data.status === 'Token is Invalid') {
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }

  const handleConfirmation = (order) => {
    const orderID = order.id
    cancelOrder({ orderID }).then((data) => {
      if (data.success) {
        fetchUpdatedOrder(orderID)
      } else {
        console.log(data)
        handleError(data)
      }
    })
  }

  return (
    <ScConfirmationAlert
      isShowAttention={props.showModal}
      onClose={props.toggleShowModal}
      onConfirm={() => handleConfirmation(props.order)}
      labelForConfirmBtn="Yes, Cancel"
      labelForCancelBtn="Go back"
      message={{
        content: (
          <>
            <SnowTypography variant={'body1'} component={'p'} align={'center'}>
              {'Are you sure you would like to cancel order number'}
            </SnowTypography>
            <SnowTypography
              variant={'h6'}
              component={'p'}
              align={'center'}
              fontWeight={theme.fontWeight.bold}
            >
              {props.order.orderNumber}
            </SnowTypography>
          </>
        )
      }}
    />
  )
}

CancelModal.propTypes = {
  // order: PropTypes.shape().isRequired,
  // showModal: PropTypes.bool.isRequired,
  // toggleShowModal: PropTypes.func.isRequired,
  // updateOrderInDetailState: PropTypes.func.isRequired,
  // updateOrderInGridState: PropTypes.func.isRequired,
  // toggleShowConfirmationModal: PropTypes.func.isRequired
}

export default CancelModal
