import React from 'react'
import styled from 'styled-components'

const StackedItemsView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  >*{
    margin-bottom: ${({ spacing, theme }) => spacing ? `${8 * spacing}px` : theme.spacing.main};
  }
`

const ScStackedItems = ({ children, spacing = 0, ...props }) => {
  const { className: derivedClassName = '', ..._props } = props
  return (
    <StackedItemsView
      aria-label='array/uniq-array'
      spacing={spacing}
      {..._props}
      className={`ScStackedItems ${derivedClassName}`}
    >
      {children}
    </StackedItemsView>
  )
}

export default ScStackedItems
