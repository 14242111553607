import React, { useEffect, useState, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import TrackingInformation from './components/TrackingInformation/TrackingInformation'
import PersonalizationInfo from './components/personalizationInfo/PersonalizationInfo'
import ScStatusChip from '../../../../../../shared/components/ScStatusChip'
import { MultivendorSelectionBtn } from './components/MultivendorSelectionBtn'
import { useUpdateOrderItemPossibleVendorsCmd } from '../../../../infrastructure/orderItemApi'
import { currentOrderModalsData } from '../../../data/orderUxState'
import { useBulkGridItem } from '../../../../../../shared/components/BulkComponents/hooks/useBulkGridItem'
import OrderItemShippingDates from './components/OrderItemShipping'
import { useOrderLineItem } from './useOrderLineItem'
import { ScActionsBar } from '../../../../../../shared/components/LayoutComponents'
import {
  ScDirectionButton,
  ScCheckButton,
  ScSaveButton
} from '../../../../../../shared/components/buttons'
import {
  SnowListItem,
  SnowTable,
  SnowTableBody,
  SnowTypography
} from '../../../../../../shared/components/snowComponents'
import {
  OrderItem,
  OrderItemHeader,
  OrderItemInfo,
  OrderItemNumber,
  OrderItemVariant,
  OrderItemPrice,
  VendorsTitle,
  OrderItemShippingView,
  PriceRow,
  PriceCell
} from './_styled'
import PropTypes from 'prop-types'

function getTitle(title) {
  return title
}

function getOrderItemStatusColor(status) {
  // TODO: check this with available statuses an item can have as it is not a
  // currently complete list
  switch (status) {
    case 'ready':
      return 'success'
    case 'cancelled':
      return 'dark'
    default:
      return 'danger'
  }
}
//
// function checkForPersonalization(item) {
//   if (
//     !item.hasCustomization &&
//     item.hasOrderItemPersonalizationInOptionsField
//   ) {
//     return <PersonalizationInfoFromOptions item={item} />
//   }
//   return <PersonalizationInfo item={item} />
// }

const OrderLineItem = ({ item }) => {
  const { repo } = useOrderLineItem(item)
  const { possibleVendors } = item
  const [updatedVendors, setUpdatedVendors] = useState([])
  const navigate = useNavigate()

  const { internalSelected, toggleSelected } = useBulkGridItem({
    item,
    uidFieldName: 'id'
  })

  const onPossibleVendorsUpdated = (response) => {
    console.log('response', response)
  }

  const setItemsToResolve = useSetRecoilState(
    currentOrderModalsData('orderItemResolving')
  )

  const resetModals = useResetRecoilState(
    currentOrderModalsData('orderItemResolving')
  )

  const updatePossibleVendors = useUpdateOrderItemPossibleVendorsCmd(
    item,
    updatedVendors.map(({ code, enabled }) => ({ code, enabled })),
    onPossibleVendorsUpdated
  )

  const savePossibleVendors = (vendors) => {
    console.log(
      'OrderLineItem updatePossibleVendors',
      vendors,
      updatePossibleVendors
    )
    setUpdatedVendors(vendors)
  }

  useEffect(() => {
    updatedVendors.length && updatePossibleVendors()
  }, [updatedVendors])

  useEffect(() => {
    return () => resetModals()
  }, [])

  const vendorsToShow = updatedVendors.length ? updatedVendors : possibleVendors

  const handleStatusClick = () => {
    if (item.doActions.length) {
      console.log('handleStatusClick', item)
      setItemsToResolve(item)
    }
  }

  const priceData = [
    {
      title: 'Price',
      value: `${item.qtyOrdered} x $${item.price.toFixed(2)}`,
      isRender: true
    },
    {
      title: 'Discount',
      value: `-$${item.discount.toFixed(2)}`,
      isRender: !!item.discount
    },
    {
      title: 'Tax',
      value: `$${item.lineItemTax.toFixed(2)}`,
      isRender: true
    },
    {
      title: 'Total',
      value: `$${item.subtotal.toFixed(2)}`,
      isRender: true
    }
  ]

  const productData = [
    {
      isRender: Boolean(item.saProductName),
      value: <a href={item.productLink}>{getTitle(item.saProductName)}</a>
    },
    {
      isRender: Boolean(!item.saProductName),
      value: <a href={item.productLink}>View Item Details</a>
    },
    {
      isRender: Boolean(item.name),
      value: `Order Item: ${getTitle(item.name)}`
    },
    {
      isRender: Boolean(item.sku),
      value: (
        <>
          {`SKU: ${item.sku}`}
          <ScDirectionButton
            size={30}
            tooltip={`Show ALL order items for SKU "${item.sku}"`}
            onClick={() => navigate(`/home/order_items?sku=${item.sku}`)}
          />
        </>
      )
    },
    {
      isRender: Boolean(item.vendorSku),
      value: `Vendor SKU: ${item.vendorSku}`
    }
  ]

  return (
    <OrderItem>
      <OrderItemHeader>
        <ScActionsBar noPaddings>
          <ScCheckButton
            fontSize="medium"
            checked={internalSelected}
            onClick={toggleSelected}
          />
          <ScStatusChip
            status={item.status}
            {...(item.doActions.length && {
              handleClick: handleStatusClick
            })}
          />
          <ScDirectionButton
            onClick={() =>
              navigate(`/home/order_items?item_status=${item.status}`)
            }
            tooltip={`Show ALL order items with status "${item.status}"`}
            size={30}
          />
        </ScActionsBar>
        <VendorsTitle ismulti={vendorsToShow.length > 1 ? 1 : 0}>
          {repo?.changed && (
            <ScSaveButton
              onSave={() => {
                repo.save().then((r) => console.log('Repo Saved', r))
              }}
              tooltip="Save"
              status={'error'}
            />
          )}
          <SnowTypography variant={'subtitle1'} component={'h3'}>
            <span>{`Vendor: `}</span>
            {vendorsToShow.length === 1 && (
              <SnowTypography
                fontWeight={500}
                variant="subtitle1"
                component="span"
              >
                {item.vendor}
              </SnowTypography>
            )}
            {vendorsToShow.length > 1 && (
              <MultivendorSelectionBtn
                vendors={vendorsToShow}
                handleSave={savePossibleVendors}
                title={getTitle(item.saProductName)}
                label={item.vendor}
              />
            )}
          </SnowTypography>
        </VendorsTitle>
      </OrderItemHeader>
      {item.hasTrackingInfo ? <TrackingInformation item={item} /> : null}
      <OrderItemInfo>
        <OrderItemNumber>
          <span>{item.qtyOrdered}</span>
        </OrderItemNumber>
        <OrderItemVariant>
          {productData.length
            ? productData.map(({ isRender, value }, index) => (
                <Fragment key={index}>
                  {isRender ? (
                    <SnowListItem
                      dense={true}
                      disableGutters={true}
                      style={{ minHeight: '24px', padding: '0' }}
                    >
                      <SnowTypography variant={'inherit'} component={'p'}>
                        {value}
                      </SnowTypography>
                    </SnowListItem>
                  ) : null}
                </Fragment>
              ))
            : null}
          {item.isPersonalizable && (
            <SnowListItem
              dense={true}
              disableGutters={true}
              key={'PersonalizationInfo'}
            >
              <PersonalizationInfo item={item} />
            </SnowListItem>
          )}
        </OrderItemVariant>
        <OrderItemPrice>
          <SnowTable>
            <SnowTableBody>
              {priceData.map(({ title, value, isRender }, index) =>
                isRender ? (
                  <PriceRow
                    key={`table-${index}`}
                    istotal={title === 'Total' ? 1 : 0}
                  >
                    <PriceCell>{title}</PriceCell>
                    <PriceCell>{value}</PriceCell>
                  </PriceRow>
                ) : null
              )}
            </SnowTableBody>
          </SnowTable>
        </OrderItemPrice>
        <>
          {item.shippingDates && (
            <OrderItemShippingView>
              <OrderItemShippingDates item={item} />
            </OrderItemShippingView>
          )}
        </>
      </OrderItemInfo>
    </OrderItem>
  )
}
OrderLineItem.propTypes = {
  item: PropTypes.shape().isRequired
}

export default OrderLineItem
