import React, { useReducer, useRef, Fragment } from 'react'
import { ScPageContentWithHeader } from '../../../shared/components/LayoutComponents'
import ScSpinner from '../../../shared/components/ScSpinner/ScSpinner'
import TitleBar from './TitleBar/TitleBar'
import ProductsTable from './ProductsTable'
import { useFetchProducts, useInfiniteScroll } from './customHooks'
import Searcher2 from '../../../shared/components/searcher/Searcher2'

const pageReducer = (state, action) => {
  switch (action.type) {
    case 'ADVANCE_PAGE':
      return { ...state, page: state.page + 1 }
    case 'RESET':
      return { ...state, page: 1 }
    default:
      return state
  }
}

const productsReducer = (state, action) => {
  switch (action.type) {
    case 'STACK_PRODUCTS':
      return { ...state, products: state.products.concat(action.products) }
    case 'RESET_PRODUCTS':
      return { ...state, products: action.products }
    case 'FETCHING_DATA':
      return { ...state, fetching: action.fetching }
    case 'SET_COLUMNS':
      return {
        ...state,
        columns: [
          { code: 'id', title: 'Id', type: 'string' },
          { code: 'sku', title: 'Sku', type: 'string' },
          { code: 'marketplaces', title: 'Sales Channels', type: 'string' }
        ]
      }
    case 'SET_SEARCH':
      return { ...state, search: action.search }
    default:
      return state
  }
}

const BundleHubGrid = (props) => {
  const searcherRef = useRef(null)
  const [productsData, productsDispatch] = useReducer(productsReducer, {
    products: [],
    columns: [],
    fetching: false,
    search: ''
  })
  const [pager, pagerDispatch] = useReducer(pageReducer, { page: 1 })
  const bottomBoundaryRef = useRef(null)
  console.log(
    `useFetchProducts page=${pager.page} search=${productsData.search} `,
    productsData
  )
  useFetchProducts({
    data: pager,
    search: productsData.search,
    productsDispatch: productsDispatch
  })

  const onSearchUpdate = (search) => {
    console.log('onSearchUpdate', search)
    productsDispatch({ type: 'SET_SEARCH', search })
    pagerDispatch({ type: 'RESET' })
  }

  useInfiniteScroll(bottomBoundaryRef, pagerDispatch)
  console.log('productsData.products', productsData.products)
  return (
    <ScPageContentWithHeader
      formFields={[
        <Fragment key={'Search-by-parent-SKU'}>
          <Searcher2
            code="sku"
            placeholder={'filter'}
            tooltip={'Type parent SKU'}
            disableIndicatorNewValue={true}
            label={'Search by parent SKU'}
            labelPlacement={'top'}
            value={productsData.search}
            onSearchUpdate={onSearchUpdate}
          />
        </Fragment>
      ]}
      actionContent={
        <TitleBar
          key={'title-bar'}
          products={productsData.products}
          productsDispatcher={productsDispatch}
        />
      }
      pageContent={
        <>
          {productsData.products && productsData.columns && (
            <ProductsTable
              columns={productsData.columns}
              products={productsData.products}
            />
          )}
          {!!productsData.fetching && <ScSpinner />}
          <div
            id="page-bottom-boundary"
            style={{ border: '1px solid #ddd' }}
            ref={bottomBoundaryRef}
          />
        </>
      }
    />
  )
}

export default BundleHubGrid
