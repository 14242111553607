import React, { useRef, useEffect, useState } from 'react'
import { Filter, Select, DropdownButton } from '../_styled'
import { fetchOrderGridFields } from '../../../infrastructure/fetches'
import PropTypes from 'prop-types'

// const orderItemStatuses = [
//   {code:'',label:'Fulfillment'},
//   {code:'ready',label:'Ready'},
//   {code:'not_ready',label:'Not Ready'},
//   {code:'sent',label:'Sent'},
//   {code:'complete',label:'Complete'},
//   {code:'error',label:'Error'},
//   {code:'shipped',label:'Shipped'},
//   {code:'n/a',label:'N / A'},
// ]

const OrderItemStatusFilter = (props) => {
  const [orderItemStatuses, setOrderItemStatuses] = useState([])
  const shouldCleanUp = useRef(false)
  const getOrderItemStatuses = async () => {
    const data = await fetchOrderGridFields()
    if (shouldCleanUp.current) return

    console.log('data', data)
    const { data: fields } = data
    if (!fields) return
    console.log('fields', fields)
    const options = fields['order_line_status'].options.map((code) => ({
      code: code,
      label: code.toLowerCase().replace('_', ' ')
    }))
    setOrderItemStatuses([{ code: '', label: 'All' }, ...options])
  }

  useEffect(() => {
    getOrderItemStatuses()
    return () => (shouldCleanUp.current = true)
  }, [])

  return (
      <Filter>
        <Select
          type="text"
          name="orderItemStatus"
          value={props.selectValue}
          onChange={props.setFilter}
        >
          {orderItemStatuses.map(({ code, label }) => (
            <option key={code} value={code}>
              {label}
            </option>
          ))}
        </Select>
        <DropdownButton/>
      </Filter>
  )
}

OrderItemStatusFilter.propTypes = {
  selectValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired
}

export default OrderItemStatusFilter
