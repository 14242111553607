import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { providerAuth } from '../data/providerAuth'
import { saUser } from '../data/saUser'

const useAuthToken = () => {
  // todo : return token expiration countdown timer component
  // todo: implement persistent token ( may be with Google OAuth 2)

  const provider = useRecoilValue(providerAuth)
  // const { signOut } = useLogoutHook()
  const { user, error: saAuthError } = useRecoilValue(
    saUser({
      providerName: 'google',
      providerToken: provider ? provider.tokenObj.access_token : ''
    })
  )

  const getUserToken = (user) => {
    const { auth } = user ? user : { auth: null }
    const { access_token } = auth ? auth : { access_token: null }
    return access_token
  }
  const [error, setError] = useState(false)

  useEffect(() => {
    saAuthError && console.error('saAuthError', saAuthError)
    if (saAuthError) {
      // signOut()
      setError(saAuthError)
    }
  }, [user])

  // const [loading, setLoading] = useState(false)
  const access_token = getUserToken(user)

  const resetError = () => {
    console.log('error reseted')
    setError(false)
  }

  const getAuthToken = () => {
    // console.log('getAuthToken', authToken,localStorage.getItem('token'))
    return access_token //localStorage.getItem('token')
    // return authToken
  }
  const fetchWithToken = (fetchCallout, props = {}) => {
    // console.log('fetchWithToken', access_token, props, fetchCallout)
    return new Promise((resolve, reject) => {
      access_token
        ? resolve(fetchCallout({ ...props, token: access_token }))
        : resolve('Not Token ')
    })
  }
  !!saAuthError && console.error('Sa Auth', user, saAuthError)
  return {
    error: saAuthError,
    isAuthorized: !!access_token,
    resetError,
    getAuthToken,
    fetchWithToken
  }
}

export default useAuthToken
