import React from 'react'
import { SnowButton } from '../../snowComponents'
import styled, { css } from 'styled-components'

const ButtonView = styled(SnowButton)`
  svg {
    height: 20px;
  }
  ${({ theme }) => css`
    box-shadow: ${theme.shadow.secondary};
    :hover {
      box-shadow: ${theme.shadow.big};
    }
    .MuiButton-startIcon {
      margin-left: 0;
    }
    &.MuiButton-root {
      font-weight: ${theme.fontWeight.thin};
      font-size: ${theme.fontSize.middle};
      text-transform: inherit;
      color: ${theme.mainPalette.typography.main};
      :hover{
        background-color: ${theme.mainPalette.grey.lightSecondary};
      }
    }
  `}
`

const ScWhiteButton = React.forwardRef(function ScWhiteButton(
  { icon: Icon, label, ...props },
  ref
) {
  return (
    <ButtonView startIcon={Icon && <Icon />} ref={ref} {...props}>
      {label}
    </ButtonView>
  )
})

export default ScWhiteButton
