import React from 'react'
import { SnowSwitch, SnowGrid } from '../../../snowComponents'
import styled from 'styled-components'

const SwitchView = styled(SnowSwitch)`
  .MuiSwitch-switchBase {
    & + .MuiSwitch-track {
      opacity: 1;
      background: ${({theme})=>theme.mainPalette.color.secondary};
    }
    &.Mui-checked {
      & + .MuiSwitch-track {
        opacity: 1;
        background: ${({theme})=>theme.mainPalette.color.main};
      }
    }
  }
`

const ScSwitchField = ({ value, onUpdate, labelStart, labelEnd }) => {
  const handleChange = (event) => {
    onUpdate({ value: event.target.checked })
  }
  return (
    <SnowGrid component="label" container alignItems="center" spacing={1} aria-label={'sc-switch'} >
      <SnowGrid item>{labelStart}</SnowGrid>
      <SnowGrid item>
        <SwitchView
          checked={value}
          onChange={handleChange}
          name="checkedC"
        />
      </SnowGrid>
      <SnowGrid item>{labelEnd}</SnowGrid>
    </SnowGrid>
  )
}

export default ScSwitchField
