import React from 'react'
import { SnowTableCell } from '../../../shared/components/snowComponents'
import SnowEditableField from '../../../shared/components/SnowEditableField'
import styled from 'styled-components'

const MappingsTableCellView = styled(SnowTableCell)`
  &.MuiTableCell-root {
    padding: 8px;
  }
`

const CarriersBasicRow = (props) => {
  const { edit, row, columns, handleRowUpdate } = props
  return (
    <>
      {columns.map(
        ({ field, width, fieldType, options, required, headerName, freeSolo }, index) => (
          <MappingsTableCellView
            style={{ width }}
            key={`${headerName}-${index}}`}
          >
            <SnowEditableField
              edit={edit}
              required={required}
              variant={'outlined'}
              label={edit ? `${headerName}` : ''}
              code={field}
              freeSolo={freeSolo}
              value={row[field]}
              onUpdate={handleRowUpdate}
              meta={{ editType: fieldType || 'text', options }}
            />
          </MappingsTableCellView>
        )
      )}
    </>
  )
}

export default CarriersBasicRow
