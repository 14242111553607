import React from 'react'

import PropTypes from 'prop-types'
import { ShipmentInfoTypo } from './styled/ShipmentInfoTypo'

function TrackingInfo({ trackingInfo }) {
  const { carrierName, trackingLink, trackingNumber } = trackingInfo
  const trackingText = `${
    (carrierName && String(carrierName)?.toUpperCase()) || 'Tracking Number'
  } : ${trackingNumber}`

  return (
    <ShipmentInfoTypo>
      {/*{carrierName?.toUpperCase() || '(Carrier is missing)'} TN:&nbsp;*/}
      {trackingLink ? (
        <a href={trackingLink} target="_blank" rel="noreferrer">
          {trackingText}
        </a>
      ) : (
        trackingText
      )}
    </ShipmentInfoTypo>
  )
}
function ShipmentInfo({ shipmentInfo }) {
  const { status, shippingId, method, timeFrame, tracks } = shipmentInfo
  return (
    <div>
      {/*<ShipmentInfoTypo>shipping status: {status}</ShipmentInfoTypo>*/}
      {/*<ShipmentInfoTypo>method: {method}</ShipmentInfoTypo>*/}

      {tracks.map((track) => (
        <TrackingInfo key={track.trackingNumber} trackingInfo={track} />
      ))}
    </div>
  )
}
const OrderItemShippingInformation = ({ item }) => {
  const { shipments } = item
  if (!Array.isArray(shipments)) return null
  return (
    <>
      {shipments.map((shipment) => (
        <ShipmentInfo key={shipment.shippingId} shipmentInfo={shipment} />
      ))}
      <hr />
    </>
  )
}

OrderItemShippingInformation.propTypes = {
  item: PropTypes.shape().isRequired
}

export default OrderItemShippingInformation
