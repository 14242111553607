import React, {useRef} from 'react'
import {
  SnowTypography,
  SnowTable,
  SnowTableHead,
  SnowTableRow,
  SnowTableCell,
  SnowTableBody,
  SnowPaper,
  SnowTableContainer
} from '../../../shared/components/snowComponents'
import VerticalAlignBottomRoundedIcon from '@mui/icons-material/VerticalAlignBottomRounded';
import { TableVirtuoso } from 'react-virtuoso'
import CarriersRow from './CarriersRow'
import SnowEditableField from '../../../shared/components/SnowEditableField'
import { ScFabButton, ScIconButton } from '../../../shared/components/buttons'
import styled from 'styled-components'
import { SnowFilterAltOffIcon, SnowKeyboardArrowDownIcon } from '../../../shared/components/icons'

const CarriersTableTitleBlockView = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.main} ${theme.spacing.secondary}`};
`

const CarriersTableHeadRowView = styled(SnowTableRow)`
  background: ${({ theme }) => theme.mainPalette.grey[50]};
`

const CarriersTableHeadCellView = styled(SnowTableCell)`
  &.MuiTableCell-root {
    padding: 8px;
    vertical-align: bottom;
  }
`

const CarriersTableRowView = styled(SnowTableRow)`
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.mainPalette.grey[50]};
  }
`

const FilterHeader = ({
  columns = [],
  hasNewItem,
  handleAddNew,
  filters,
  handleFilterClear
}) => {
  const handleFilterChange = ({ value, ...props }) => {
    const { code } = props
    const { onFilter } = columns.filter((column) => column.field === code)[0]
    typeof onFilter === 'function' && onFilter({ value, ...props })
    console.log('Carriers handleFilterChange', {
      value,
      columns,
      filters,
      onFilter,
      ...props
    })
  }
  return (
    <CarriersTableHeadRowView>
      <CarriersTableHeadCellView
        align={'center'}
        style={{ width: '5%' }}
        size={'small'}
      >
        {!hasNewItem ? <ScFabButton onClick={handleAddNew} /> : null}
      </CarriersTableHeadCellView>
      {columns?.map(
        ({ field, width, headerName, headerFieldType, headerOptions }) => (
          <CarriersTableHeadCellView key={field} style={{ width }}>
            <SnowEditableField
              label={headerName}
              labelPlacement={'top-big'}
              variant={'outlined'}
              value={filters?.[field]}
              edit={true}
              tooltip={`Type ${headerName} for search`}
              placeholder={'search'}
              disableIndicatorNewValue={true}
              tooltipNewValue={false}
              labelFontProps={{ fontWeight: 500, fontSize: '20px' }}
              code={field}
              onUpdate={handleFilterChange}
              meta={{
                editType: headerFieldType ? headerFieldType : 'text',
                options: headerOptions
              }}
            />
          </CarriersTableHeadCellView>
        )
      )}
      <CarriersTableHeadCellView style={{ width: '5%' }} size={'small'}>
        <ScIconButton size={40} onClick={handleFilterClear} tooltip={'Clear filters'}>
          <SnowFilterAltOffIcon />
        </ScIconButton>
      </CarriersTableHeadCellView>
    </CarriersTableHeadRowView>
  )
}

const TableComponents = {
  Scroller: React.forwardRef(function Scroller(props, ref) {
    return <SnowTableContainer component={'div'} {...props} ref={ref} />
  }),
  Table: (props) => (
    <SnowTable {...props} style={{ borderCollapse: 'separate' }} />
  ),
  TableHead: SnowTableHead,
  TableRow: ({ item: _item, ...props }) => {
    console.log('TableComponents', { _item, props })
    return <CarriersTableRowView {...props} />
  },
  TableBody: React.forwardRef(function TableBody(props, ref) {
    return <SnowTableBody {...props} ref={ref} />
  })
}

const ROW_HEIGHT = 57
const HEADER_HEIGHT = 96

const CarriersTableTemplate = (props) => {
  const {
    title,
    columns,
    data,
    filters,
    handleFilterClear,
    handleAddNew,
    hasNewItem,
    onEdit,
    onCreate,
    onRemove,
    onUpdate
  } = props
  const virtuoso = useRef(null);

  return (
    <SnowPaper>
      <CarriersTableTitleBlockView>
        <SnowTypography component={'h2'} variant={'h6'} fontWeight={500}>
          {title}
        </SnowTypography>
        <ScIconButton tooltip={'Scroll to the last item'} size={30} onClick={()=>{
          virtuoso.current.scrollToIndex({
            index: data?.length-1})
        }}>
          <VerticalAlignBottomRoundedIcon/>
        </ScIconButton>
      </CarriersTableTitleBlockView>
      <TableVirtuoso
        style={{ height: ROW_HEIGHT * 10 + HEADER_HEIGHT, width: '100%' }}
        fixedHeaderContent={() =>
          FilterHeader({ columns, hasNewItem, handleAddNew, filters, handleFilterClear })
        }
        components={TableComponents}
        data={data}
        ref={virtuoso}
        itemContent={(_index, row) => (
          <CarriersRow
            row={row}
            columns={columns}
            onEdit={onEdit}
            onCreate={onCreate}
            onRemove={onRemove}
            onUpdate={onUpdate}
          />
        )}
      />
    </SnowPaper>
  )
}

export default CarriersTableTemplate
