import React, { useEffect } from 'react'
import { SnowCard, SnowCardContent, SnowGrid, SnowList, SnowListItem } from '../../../snowComponents'
import ItemField from './ItemField'
import { SnowCheckIcon } from '../../../icons'
import { useBulkGridItem } from '../../hooks/useBulkGridItem'
import styled from "styled-components"

const BulkGridItemCardContentView = styled(SnowCardContent)`
  position: relative;
  padding: 5px;
  overflow-y: visible;
  .MuiSvgIcon-root {
    position: absolute;
    z-index: 2;
    left: 1px;
    top: 1px;
    fill: ${({ theme }) => theme.mainPalette.color.secondary};
  }
`

const BulkGrigItemFieldListView = styled(SnowList)`
  padding-top: 0;

  .MuiListItem-root {
    padding: 0;
    :first-child {
      padding-top: 0;
    }
  }

  li>div{
    width: 100%;
  }
`

const BulkGridItemCardView = styled(SnowCard)`
  min-height: 350px;
  height: 350px;
  cursor: pointer;
  &.MuiCard-root{
    overflow-y: auto;
  }
  ${({ theme }) => `
    border: ${theme.border.contrast};
    border-radius: ${theme.borderRadius.secondary};
    transition: all ${theme.transition.main} !important;
    :hover {
      background-color: ${theme.mainPalette.color.hover} !important;
    }
  `}
  ${({ selected, theme }) => selected ? `
    border-color: ${theme.mainPalette.grey[50]};
    background-color: rgba(29, 175, 236, 0.18) !important;`
    : ''
  }`

const BulkGridItem = ({
  item = {},
  fields = [],
  selectable: isSelectable,
  selected,
  isEditable = false,
  inEditMode = false,
  handleItemClick = () => { },
  handleItemDblClick = () => { },
  handleItemSave = null,
  handleItemStartEdit = null,
  handleItemRemove = null,
  handleItemFieldUpdate = () => { },
  ...props
}) => {

  const { internalSelected, toggleSelected } = useBulkGridItem({
    item
  })

  const onClick = (props) => {
    handleItemClick(item, props)
    console.log('itemUid', item, item.uid, isSelectable, internalSelected)
    toggleSelected()
  }
  const onDoubleClick = (props) =>
    handleItemDblClick && handleItemDblClick(item, props)

  return (
    <SnowGrid item {...props}>
      <BulkGridItemCardView
        aria-label={'bulk-grid-item'}
        sx={{ maxWidth: 345 }}
        {...props}
        {...{ onClick }}
        {...{ onDoubleClick }}
        selected={internalSelected ? 1 : 0}
        title={item.uid}
        square={true}
        raised={true}
        elevation={2}
      >
        <BulkGridItemCardContentView>
          {isSelectable && !!internalSelected && (
            <SnowCheckIcon fontSize={'large'} />
          )}
          <BulkGrigItemFieldListView aria-label='item-field-list'>
            {fields.map(({ code, title, type, renderer }) => {
              return (
                <SnowListItem key={code} disableGutters={true} dense >
                  <ItemField
                    title={title}
                    code={code}
                    item={item}
                    renderer={renderer}
                    type={type}
                    editState={inEditMode}
                    onFieldUpdate={handleItemFieldUpdate}
                  />
                </SnowListItem>
              )
            })}
          </BulkGrigItemFieldListView>
        </BulkGridItemCardContentView>
      </BulkGridItemCardView>

    </SnowGrid>
  )
}
// FieldsRow.whyDidYouRender = true
export default React.memo(BulkGridItem)
