import React, { useEffect } from 'react'
import ScBaseMultiAutocomplete from './ScBaseMultiAutocomplete'
import PropTypes from 'prop-types'

const getOptions = (meta) => {
  if (meta?.options?.length)
    return meta?.emptyOption
      ? [...meta.options, meta.emptyOption]
      : meta.options
  return []
}

const getError = (meta, required) => {
  return !getOptions(meta)?.length && required ? true : false
}

const getErrorText = (meta, required) => {
  let rezult = ''
  if (getOptions(meta)?.length === 0) {
    rezult = required ? 'Error: There are no options' : 'No options to choose'
  }
  return rezult
}

function ScMultiComboField({
  onUpdate = (evt) => console.log(evt),
  code = '',
  value: incomeValue = [],
  meta = {
    options: [],
    emptyOption: { id: '', name: '', code: '' },
    optionsDef: { value: 'id', label: 'name', tag: 'code' }
  },
  labelPlacement = 'top',
  label = '',
  inputWidth = 100,
  multiple = true,
  isInvalid = false,
  helperText = '',
  tooltipText = '',
  fontSize='',
  placeholder='',
  variant = 'outlined',
  isGroupField = false,
  mb = 0,
  ...props
}) {
  console.log('ScMultiComboField income', {
    meta,
    incomeValue,
    type: typeof incomeValue
  })

  useEffect(() => {
    console.log('ScMultiComboField value,options', incomeValue)
  }, [incomeValue])

  console.log(
    'getOptions',
    getOptions(meta),
    'empty',
    meta.emptyOption,
    'meta',
    meta
  )

  return (
    <ScBaseMultiAutocomplete
      value={incomeValue}
      code={code}
      label={label}
      placeholder={placeholder}
      labelPlacement={labelPlacement}
      inputWidth={inputWidth}
      variant={variant}
      fontSize={fontSize}
      metaOptions={getOptions(meta)}
      tooltipText={tooltipText}
      emptyOption={meta.emptyOption}
      required={props.required}
      isGroupField={isGroupField}
      optionsDef={meta.optionsDef}
      helperText={getErrorText(meta, props.required) || helperText}
      multiple={multiple}
      InputProps={{
        disabled: props.disabled,
        'aria-invalid': isInvalid,
      }}
      isInvalid={getError(meta, props.required) ? true : isInvalid}
      onUpdate={onUpdate}
    />
  )
}

export default ScMultiComboField

ScMultiComboField.propTypes = {
  value: PropTypes.array,
  code: PropTypes.string,
  tooltipText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'end']),
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meta: PropTypes.oneOfType([
    PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.string)
    }),
    PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object)
    }),
    PropTypes.shape({
      options: PropTypes.object
    }),
    PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
      optionsDef: {
        label: PropTypes.string,
        value: PropTypes.string,
        tag: PropTypes.string
      }
    }),
    PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
      emptyOption: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string
      }),
      optionsDef: {
        label: PropTypes.string,
        value: PropTypes.string,
        tag: PropTypes.string
      }
    })
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isGroupField: PropTypes.bool,
  mb: PropTypes.number,
  onUpdate: PropTypes.func
}
