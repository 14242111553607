import 'react-virtualized/styles.css'
import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import {
  Column as VirtualColumn,
  Table as VirtualTable,
  WindowScroller,
  AutoSizer
} from 'react-virtualized'
import { NIL as NIL_UUID } from 'uuid'
import { getActionsColumnProps } from './components/getActionsColumnProps'
import getColumnProps from './components/getColumnProps'
import useTableFieldsDescription from './components/useTableFieldsDescription'
import { isJSON, addElementInCollection, toggleElementInCollection } from '../../../../utils/helpers'
import { useLogger } from '../../../service/helpers/SnowLogger'
import styled from 'styled-components'

const VirtualizedTableView = styled.div`
  .ReactVirtualized__Table__headerColumn{
  }
  .ReactVirtualized__Table__headerRow {
    padding: 10px 0;
    background: ${({theme})=>theme.mainPalette.grey.light};
    border-bottom: ${({theme})=>theme.border.main};
  }
  .ReactVirtualized__Table__row {
    cursor: pointer;
    ${({ theme }) => `
      transition: ${theme.transition.main};
      border-bottom: ${theme.border.main};
      :hover {
        background: ${theme.mainPalette.grey.lightSecondary};
      }
    `}
  }
  .virtualized-table-row--selected {
    ${({ theme }) => `
    background: ${theme.mainPalette.color.select};
    transition: ${theme.transition.main};
      :hover {
        background: ${theme.mainPalette.color.hover};
      }
    `}
  }
`

/**
 *
 * @param filterList - array of existed filters keys
 * @param searchParams - search url params from useSearchParams
 * @returns {{[filterName]:string, [filterValue]:any}} - the map object of <filterUniqName:filterValue>
 */
export const getFilterFromSearchParams = (filterList, searchParams) => {
  return filterList.reduce((acc, dataKey) => {
    const filterParam = searchParams.get(dataKey)
    console.log('getFilterFromSearchParam ', dataKey, filterParam)

    acc[dataKey] = filterParam
      ? isJSON(filterParam)
        ? JSON.parse(filterParam)
        : filterParam
      : ''
    return acc
  }, {})
}

/**
 *
 * @param editable :boolean - if table can be switched into edit row fields mode
 * @param edit : boolean - is table in edit mode
 * @param fields : object typeof {fieldKey:fieldDescription, ...}
 * @param keyField : string which row field uses as row entity uniq key
 * @param filters : object the map object of filters
 * @param dataRows : Array<Row> Row objects
 * @param customRowActions
 * @param selectable : boolean
 * @param onRowDoubleClick:func
 * @param onRowClick : function|null
 * @param onBulkCrud : function|null
 * @param onBulkSelect : function
 * @param onRowUpdate : function
 * @param onCrudEvent : function
 * @param onFilterChange : function - callback function to handle filter inputs changes
 * @param autoFilter : boolean -  true to have the table to apply the filters for dataRows, if false - don't apply filters - just call onFilterChange
 * @param contentAdaptiveColumnsWidth : boolean
 * @param uid : string
 * @param height : number
 * @returns {JSX.Element}
 * @constructor
 */

const ScEditableVirtualizedTable = ({
  editable,
  edit = false,
  fields,
  keyField = 'id',
  filters,
  fontSize,
  autoWidth = false,
  dataRows,
  customRowActions,
  selectable = true,
  onRowDoubleClick = (item) => console.log('Confirmed Row item', item),
  onRowClick = (item) => console.log('Selected Row item', item),
  onBulkCrud,
  onBulkSelect = (items) => console.log('onBulkSelect Items', items),
  onRowUpdate,
  onCrudEvent,//onItemCrudEvent
  onFilterChange = (name, value) => console.log('Filter change', name, value),
  autoFilter = true,
  contentAdaptiveColumnsWidth = true,
  uid = '',
  height = 700
}) => {
  // function * genItems (rawItems) {
  //   for (let i = 0; i <= rawItems.length - 1; i++) {
  //     yield rawItems[i]
  //   }
  // }
  const { log } = useLogger('VirtualisedTable')
  const inputTable = useRef(null)
  const [items, setItems] = useState([])
  const [renderedRange, setRenderedRange] = useState({
    startIndex: 0,
    stopIndex: 0
  })

  const [editedRowIds, setEditedRowIds] = useState([])
  const [selectedRowIds, setSelectedRowIds] = useState([])


  const handleFilterChange = (name, value) => {
    log('ScEditableVirtualizedTable handleFilterChange', name, value)
    setSelectedRowIds([])
    onFilterChange(name, value)
  }
  const {
    fieldsToShow,
    updateRelatedFields,
    filteredItems,
    tableWidth,
    headerRenderer,
    getCellsMeasuredDimension,
    getRowHeight
  } = useTableFieldsDescription({
    fields: fields ?? {},
    rows: items,
    keyField,
    filters,
    onFilterChange: handleFilterChange,
    autoFilter,
    contentAdaptiveColumnsWidth,
    uid
  })

  log('ScEditableVirtualizedTable filters', fieldsToShow, filters, filteredItems)
  const filteredItemIds = useMemo(
    () => filteredItems.map((item) => item[keyField]),
    [filteredItems]
  )

  const getRenderedItemsMeasuredDimension = useCallback(() => {
    return getCellsMeasuredDimension(
      renderedRange.stopIndex
        ? filteredItems.slice(renderedRange.startIndex, renderedRange.stopIndex)
        : filteredItems
    )
  }, [filteredItems])

  const rowClassName = useCallback(
    ({ index }) => {
      return selectedRowIds.includes(filteredItems[index]?.[keyField]) && 'virtualized-table-row--selected'
    },
    [selectedRowIds, filteredItems]
  )

  const handleBulkSelect = (rowIds) => {
    console.log('handleBulkSelect', rowIds)
    onBulkSelect(rowIds)
    setSelectedRowIds(rowIds)
  }

  const handleRowClick = (item) => {
    log(
      'selectedRows',
      selectedRowIds,
      item,
      toggleElementInCollection(selectedRowIds, item[keyField])
    )
    if (item[keyField] === NIL_UUID) return
    if (editedRowIds.includes(item[keyField])) return
    onRowClick(item)

    setSelectedRowIds((prev) =>
      toggleElementInCollection(selectedRowIds, item[keyField])
    )
  }

  const handleBulkCrud = typeof onBulkCrud === 'function' ? () => {
    onBulkCrud(filteredItems)
  } : null

  const refreshRowsHeight = () => {
    inputTable?.current?.recomputeRowHeights()
  }

  const handleRowUpdate = (updatedId, { code, value }) => {
    log(
      'ScEditableVirtualizedTable handleRowUpdate',
      updatedId,
      code,
      value
    )
    const changedRowData = {
      ...filteredItems.find((item) => item[keyField].toString() === updatedId.toString()),
      [code]: value
    }
    log('changedRowData', changedRowData, onRowUpdate)


    updateRelatedFields(updatedId, { code, value })
    refreshRowsHeight()
    typeof onRowUpdate === 'function' && onRowUpdate(updatedId, changedRowData)
  }

  const onCrudTriggered = (action, data) => {
    onCrudEvent && onCrudEvent({ action, data })
    refreshRowsHeight()
  }

  useEffect(() => {
    log('ScEditableVirtualizedTable useEffect dataRows', dataRows)
    setItems(dataRows)
    edit && setEditedRowIds(dataRows.map(item => item[keyField]))
  }, [dataRows])

  useEffect(() => {
    const hasCreatingRow = items.find((item) => item[keyField] === NIL_UUID)
    hasCreatingRow &&
    setEditedRowIds(addElementInCollection(editedRowIds, NIL_UUID))

    refreshRowsHeight()
  }, [items, filteredItems])

  const getRowPixelHeight = ({ index }) => {
    const rowUnitHeight = editedRowIds.includes(filteredItems[index]?.[keyField])
      ? 65
      : 53
    const rowHeight = rowUnitHeight * (getRowHeight(filteredItems[index]) ?? 1)
    return rowHeight
  }
  const getFieldColumnProps = (fieldDescription) => {
    const props = getColumnProps({
      keyField,
      fieldDescription,
      editedRows: editedRowIds,
      headerRenderer,
      measuredRenderedItems: getRenderedItemsMeasuredDimension(),
      onRowUpdate: handleRowUpdate,
      fontSize
    })
    return props
  }

  const getActionsFieldColumnProps = () => {
    const props = getActionsColumnProps({
      customRowActions,
      keyField,
      rows: filteredItemIds,
      editedRows: editedRowIds,
      selectedRows: selectedRowIds,
      onBulkSelect: handleBulkSelect,
      onBulkCrudEvent: handleBulkCrud,
      setEditedRows: setEditedRowIds,
      onCrudTriggered: onCrudEvent && onCrudTriggered,
      editable,
      selectable
    })
    return props
  }


  return (
    <VirtualizedTableView>
      {!filteredItems.length && 'No filtered items'}
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight
            disableWidth={autoWidth ? false : true}
          >
            {({ width }) => (
              <VirtualTable
                isScrolling={isScrolling}
                ref={inputTable}
                noRowsRenderer={() => 'Nothing to show'}
                width={autoWidth ? width : tableWidth}
                height={height ?? 580}
                rowHeight={getRowPixelHeight}
                headerHeight={70}
                rowCount={filteredItems.length}
                rowGetter={({ index }) => filteredItems[index]}
                onRowsRendered={setRenderedRange}
                onRowDoubleClick={({ rowData, event, index }) => {
                  log('onRowDoubleClick e', event)
                  onRowDoubleClick(rowData)
                }}
                onRowClick={({ rowData, event }) => {
                  log('onRowClick e', event)
                  handleRowClick(rowData)
                }}
                rowClassName={rowClassName}
              >
                {(editable || selectable) && <VirtualColumn {...getActionsFieldColumnProps()} />}

                {fieldsToShow?.map((fieldDescription) => (
                  <VirtualColumn
                    flexGrow={1}
                    maxWidth={400}
                    minWidth={autoWidth ? 0 : 160}
                    key={fieldDescription.code}
                    
                    {...getFieldColumnProps(fieldDescription)}
                  />
                ))}
              </VirtualTable>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </VirtualizedTableView>
  )
}
export default ScEditableVirtualizedTable
