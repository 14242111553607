import React, { useContext } from 'react'
import { ScLinkButton } from '../../../../shared/components/buttons'
import { SnowLink, SnowTypography } from '../../../../shared/components/snowComponents'
import UploadOrderModalBtn from '../Modals/UploadOrderModalBtn'
import { ScActionsBar } from '../../../../shared/components/LayoutComponents/'
import { DateRangeScoper } from './DateRangeScoper'
import styled, { ThemeContext } from 'styled-components'

const HubActionClearLinkView = styled(SnowLink)`
  &.MuiTypography-root {
    color: ${({ theme }) => theme.mainPalette.color.secondary};
    text-decoration: none;
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
    :active {
      color: ${({ theme }) => theme.mainPalette.color.secondary};
    }
  }
`

const HubActions = ({
  clearAllFilters,
  totalOrderCount,
  dateRangeName,
  onDateRangeUpdate
}) => {
  const theme = useContext(ThemeContext)
  return (
    <>
      <ScActionsBar justifyContent={'space-between'}>
        <UploadOrderModalBtn />
      </ScActionsBar>
      <ScActionsBar justifyContent={'flex-start'}>
        <SnowTypography
          component='p'
          variant='body2'
          style={{
            marginRight: '1rem',
          }}
        >
          <SnowTypography component='span' variant='inherit'>
            {`Total Orders: `}
          </SnowTypography>
          <SnowTypography component='span' variant='inherit' fontWeight={500} color={theme.mainPalette.color.link}>{totalOrderCount}</SnowTypography>
        </SnowTypography>
        <DateRangeScoper
          defaultRange={dateRangeName}
          onUpdate={onDateRangeUpdate}
        />
        <ScLinkButton
          onClick={clearAllFilters}
          sx={{ ml: '5rem' }}
          label={'Clear search and filters'}
        />
      </ScActionsBar>
    </>
  )
}

export default HubActions
