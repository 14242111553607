import React from 'react'
import {
  SnowTable, SnowTableBody,
  SnowTableCell,
  SnowTableContainer,
  SnowTableHead,
  SnowTableRow,
  SnowPaper
} from '../../snowComponents'
import { prettifyJsonOutput } from '../../../../utils/helpers'
import SnowEditableField from '../../SnowEditableField'
import styled from 'styled-components'

const StyledMainTableHead = styled(SnowTableHead)`
  background: ${({ theme }) => theme.mainPalette.grey[50]};
`

const StyledButtonCell = styled(SnowTableCell)`
  width: 48px;
  box-sizing: border-box;
`

const getFilterHeaders = (columns = {}) => {
  const handleFilterChange = ({ value, ...props }) => {
    console.log('handleFilterChange', { value, ...props })
    const { code } = props
    const { onFilter, options } = columns[code]
    typeof onFilter === 'function' && onFilter({ value, ...props })
  }
  return Object.keys(columns)?.map((columnCode) => (
    <SnowTableCell key={columnCode}>
      <SnowEditableField
        code={columnCode}
        edit={true}
        label={'Search'}
        placeholder={columns[columnCode].label}
        onUpdate={handleFilterChange}
        disableIndicatorNewValue={true}
        tooltipNewValue={false}
        variant={'outlined'}
        meta={{
          editType: columns[columnCode]?.type,
          options: columns[columnCode]?.options
        }}
      />
    </SnowTableCell>
  ))
}

const ScCollapsibleCRUDTable = ({
  AddButton = {},
  onRemove = (id) => {
    console.log('Gonnar to remove id', id)
  },
  onUpdate = (id, data) => {
    console.log('Gonnar to update id', id, data)
  },
  items = [],
  columns = {},
  rowComponent: RowComponent = ({ data }) => (
    <SnowTableRow>
      <SnowTableCell>{prettifyJsonOutput(data)}</SnowTableCell>
    </SnowTableRow>
  ),
  ...props
}) => {
  console.log('ScCollapsibleTable items', items)
  return (
    <SnowTableContainer component={SnowPaper}>
      <SnowTable aria-label="collapsible table">
        <StyledMainTableHead>
          <SnowTableRow >
            <StyledButtonCell>
              <AddButton />
            </StyledButtonCell>
            {getFilterHeaders(columns)}
            <StyledButtonCell />
          </SnowTableRow>
        </StyledMainTableHead>
        {Array.isArray(items) && (
          <SnowTableBody>
            {items.map((item) => (
              <RowComponent
                key={item.name}
                columns={columns}
                data={item}
                onRemove={onRemove}
                onUpdate={onUpdate}
              />
            ))}
          </SnowTableBody>
        )}
      </SnowTable>
    </SnowTableContainer>
  )
}

export default ScCollapsibleCRUDTable
