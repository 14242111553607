import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { useEffect, useState } from 'react'
import { useScMessage } from '../../../shared/service/Message/data/currentMessageState'
import { removeEmptyProps } from '../../../utils/helpers'

const API_CARRIER_VENDOR_MAPPINGS = objWithParent({
  url: '/carriers/vendors',
  LIST: {
    url: '/',
    method: 'GET',
    caller: 'FETCH_CARRIER_VENDOR_MAPPINGS!',
    validator: () => true
  },
  CREATE: {
    url: '',
    method: 'POST',
    caller: 'CREATE_CARRIER_VENDOR_MAPPING!',
    validator: () => true
  },
  UPDATE: {
    url: '',
    method: 'PUT',
    caller: 'UPDATE_CARRIER_VENDOR_MAPPING!',
    validator: () => true
  },
  DELETE: {
    url: '',
    method: 'DELETE',
    caller: 'DELETE_CARRIER_VENDOR_MAPPING!',
    validator: () => true
  }
})

export const useCarriersVendorsMappingApi = () => {
  const [mappings, setMappings] = useState([])
  const { send: sendMessage } = useScMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [appliedMappingParams, setAppliedMappingParams] = useState({})
  const [appliedMappingId, setAppliedMappingId] = useState(0)
  const [mappingAction, setMappingAction] = useState('')
  const fetchMappingsCmd = useEntityCommand(
    0,
    API_CARRIER_VENDOR_MAPPINGS.LIST,
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('fetchMappingsCmd success', r)
          setMappings(r)
        },
        (r) => console.log('fetchMappingsCmd fail', r)
      )
  )
  const createMappingCmd = useEntityCommand(
    0,
    {
      ...API_CARRIER_VENDOR_MAPPINGS.CREATE,
      params: appliedMappingParams ? removeEmptyProps(appliedMappingParams) : {}
    },
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('createMappingCmd success', r)
          // setAvailableActions(r)
        },
        (r) => console.log('createMappingCmd fail', r)
      )
  )
  const deleteMappingCmd = useEntityCommand(
    appliedMappingId,
    {
      ...API_CARRIER_VENDOR_MAPPINGS.DELETE
    },
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('deleteMappingCmd success', r)
          // setAvailableActions(r)
        },
        (r) => console.log('deleteMappingCmd fail', r)
      )
  )
  const updateMappingCmd = useEntityCommand(
    appliedMappingId,
    {
      ...API_CARRIER_VENDOR_MAPPINGS.UPDATE,
      params: appliedMappingParams ? removeEmptyProps(appliedMappingParams) : {}
    },
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('deleteMappingCmd success', r)
          // setAvailableActions(r)
        },
        (r) => console.log('deleteMappingCmd fail', r)
      )
  )

  const createMapping = ({ code, vendor, carrier_code }) => {
    if (!(code && vendor && carrier_code)) {
      console.error('Some param is not pointed')
      console.log({
        code,
        carrier_code,
        vendor,
      })
      return
    }
    setAppliedMappingParams({
      code,
      vendor,
      carrier_code,
      marketplace_id: 0,
      shop_id: 0
    })
    setMappingAction('create')
  }
  const updateMapping = ({ id, code, vendor, carrier_code }) => {
    if (!(id && code && vendor && carrier_code)) {
      console.error('Id, Code or Title or Tracking Url is not pointed')
      console.log({ code, vendor, carrier_code })
      return
    }
    setAppliedMappingId(id)
    setAppliedMappingParams({
      code,
      vendor,
      carrier_code,
      shop_id: 0,
      marketplace_id: 0
    })
    setMappingAction('update')
  }
  const deleteMapping = ({ id }) => {
    if (!id) {
      console.error(' Id is not pointed')
      console.log({ id })
      return
    }
    setAppliedMappingId(id)
    setMappingAction('delete')
  }
  const cmdRunner = (cmd) => {
    setIsLoading(true)
    cmd().then((r) => {
      console.log('cmdRunner response', r)
      setIsLoading(false)
      fetchMappingsCmd()
    })
    setMappingAction('')
    setAppliedMappingParams({})
  }

  useEffect(() => {
    if (!mappingAction || isLoading) return

    // if (carrierAction === 'list') cmdRunner(fetchCarriersCmd())
    if (mappingAction === 'create') cmdRunner(createMappingCmd)
    if (mappingAction === 'delete') cmdRunner(deleteMappingCmd)
    if (mappingAction === 'update') cmdRunner(updateMappingCmd)
  }, [mappingAction])

  useEffect(() => {
    if (mappings.length) return
    fetchMappingsCmd((r) => console.log('fetchMappingsCmd response', r))
  }, [])

  return {
    mappings,
    createMapping,
    updateMapping,
    deleteMapping
  }
}
