import React, { useEffect, useState } from 'react'
import { ScBasicModal } from '../../../../shared/components/modals'
import ChannelShippingMethodsMapper from '../../../integrations/entities/salesChannels/shippingMethods/shippingMethodsMapper/ChannelShippingMethodsMapper'

const ShippingMethodsModal = ({
  open,
  onClose,
  channel = 0,
  channelName = '',
  defaultValue = ''
}) => {
  const [mapping, setMapping] = useState([])
  const [saveCmd, setSaveCmd] = useState(null)
  const [savingResult, setSavingResult] = useState(null)
  const [title, setTitle] = useState(
    `Shipping Methods Mapping for Channel #${channel}`
  )
  const onContentInit = ({ contentTitle }) => {
    console.log('onContentInit', contentTitle)
    setTitle(contentTitle)
  }
  const onMap = ({ shippingMap, saveCmd: save }) => {
    console.log('ShippingMethodsModal onMap', shippingMap, save)
    setSaveCmd(() => save)
    setMapping(shippingMap)
  }

  const onSave = () => {
    console.log('ShippingMethodsModal onSave', mapping, saveCmd)
    setSavingResult(typeof saveCmd === 'function' && saveCmd())
  }

  useEffect(() => {
    console.log('ShippingMethodsModal savingResult', !!savingResult)
    !!savingResult && onClose()
  }, [savingResult])

  return (
    <ScBasicModal
      open={open}
      onClose={onClose}
      title={title}
      content={<ChannelShippingMethodsMapper
        onMap={onMap}
        onInit={onContentInit}
        channel={channel}
        channelName={channelName}
        defaultValue={defaultValue}
      />}
      labelForCancelBtn={'Close'}
    />
  )
}

export default ShippingMethodsModal
