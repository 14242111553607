import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { platformsSelector } from '../data/dictionaries'

const usePlatforms = () => {
  const loadedPlatforms = useRecoilValue(platformsSelector)
  useEffect(() => {
    // setPlatforms(loadedPlatforms)
    console.log('usePlatforms', loadedPlatforms)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedPlatforms])
  return loadedPlatforms
}

export default usePlatforms
