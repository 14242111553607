import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  SnowBox,
  SnowAvatar,
  SnowDivider,
  SnowGrid,
  SnowList,
  SnowListItem,
  SnowTypography,
  SnowCard,
  SnowCardContent,
  SnowCardHeader
} from '../../../../../shared/components/snowComponents'
import { ScShippingButton } from '../../../../../shared/components/buttons'
import ScStatusChip from '../../../../../shared/components/ScStatusChip'
import { useCurrentOrder } from '../../data/currentOrderState'
import EnterTrackingRef from './EnterTrackingRef'
import ShippingMethodsModal from '../../../../integrations/entities/vendors/modals/ShippingMethodsModal'
import { vendorWithId } from '../../../../integrations/hooks/useVendors'
import CreateVendorOrderButton from '../../DetailHeader/components/buttons/CreateVendorOrderButton'
import styled, { css } from 'styled-components'
import VendorOrder from './VendorOrder'

const StyledCard = styled(SnowCard)`
  min-width: 275px;
  margin-bottom: 1rem;
`

const StyledCardContent = styled(SnowCardContent)`
  &.MuiCardContent-root{
      :last-child {
        padding-bottom: ${({ theme }) => theme.spacing.secondary};
      }
    }
  }
`

const StyledAvatar = styled(SnowAvatar)`
  .MuiAvatar-root {
    padding: 3px;
  }
  span {
    color: inherit;
  }
`

const StyledError = styled(SnowTypography).attrs({
  variant: 'body2',
  component: 'span'
})`
  &.MuiTypography-root {
    ${({ theme }) => css`
      color: ${theme.mainPalette.warning.secondary};
      font-weight: ${theme.fontWeight.bold};
    `}
  }
`

function getVendorStatusColor(status) {
  switch (status) {
    case 'ready':
      return 'success'
    case 'new':
      return 'success'
    case 'sent':
      return 'warning'
    case 'processing':
      return 'warning'
    case 'complete':
      return 'secondary'
    case 'cancelled':
      return 'dark'
    default:
      return 'danger'
  }
}

function getVendorStatus(order, vendorCode) {
  const vendorObj = order.vendor_orders.find(
    (vendorObj) => vendorObj.vendor === vendorCode
  )
  if (vendorObj) {
    // if it exists, return the vendor status listed on the specific ITEM
    return vendorObj.status
  } else {
    // if none is listed, then return the vendor status listed on order[vendors]
    const vendorObj = order.vendors.find((obj) => obj.vendor === vendorCode)
    if (vendorObj) {
      return vendorObj.status
    } else {
      // if none is listed there either, return 'N/A'
      return 'N/A'
    }
  }
}

const VendorItemTitle = ({ vendor }) => {
  console.log('VendorItemTitle', { vendor })
  const {
    vendorName: label,
    vendorCode: vendorCode,
    vendorId: id,
    status,
    ...rest
  } = vendor
  const orderModel = useCurrentOrder()

  const fullVendor = useRecoilValue(vendorWithId(id))
  const { data_field_scheme } = fullVendor || {}
  const hasShippingMappingAbility =
    data_field_scheme && data_field_scheme.length

  console.log('VendorTitle', vendor, fullVendor)

  const [openShippingModalFor, setOpenShippingModalFor] = useState(null)
  const labelForModal = `${label}`
  const onShippingModalClose = () => {
    setOpenShippingModalFor(null)
  }
  const orderVendorItemsCount = orderModel.orderItems.filter(
    ({ vendorName }) => vendorName === vendor.vendorName
  ).length

  const vendorOrderItemsCount = orderModel.vendorOrders
    .filter(({ vendorName }) => vendorName === vendor.vendorName)
    .reduce((vendorOrderItemsCount, vendorOrder) => {
      return vendorOrderItemsCount + vendorOrder.orderItems.length
    }, 0)

  const needsToShowVOCreate = orderVendorItemsCount > vendorOrderItemsCount

  return (
    <>
      {!!openShippingModalFor && (
        <ShippingMethodsModal
          defaultValue={''}
          entityId={id}
          entityName={labelForModal}
          onClose={onShippingModalClose}
          open={!!openShippingModalFor}
        />
      )}
      <SnowGrid
        container
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        wrap={'wrap'}
        direction={'row'}
      >
        {hasShippingMappingAbility ? (
          <SnowGrid item>
            {label} <ScStatusChip status={status} />
            <ScShippingButton
              aria-label="Trigger"
              onClick={() => setOpenShippingModalFor(vendor)}
            />
          </SnowGrid>
        ) : (
          <>
            <SnowGrid item>
              {label} <ScStatusChip status={status} />{' '}
            </SnowGrid>
          </>
        )}
        {needsToShowVOCreate && (
          <SnowGrid>
            <CreateVendorOrderButton vendor={vendor} />
          </SnowGrid>
        )}
      </SnowGrid>
    </>
  )
}

const OrderVendors = () => {
  const currentModel = useCurrentOrder()

  const {
    vendors: actualVendors,
    orderItems,
    vendorOrders: allVendorOrders
  } = currentModel
  const actualVendorCodes = actualVendors.map(({ vendorCode }) => vendorCode)
  const abandonedVendorOrders = allVendorOrders.filter(
    ({ vendorCode }) => !actualVendorCodes.includes(vendorCode)
  )
  if (!Array.isArray(actualVendors)) return null

  return (
    <>
      <StyledCard>
        <SnowBox>
          {/*
          <CompleteOrderItemsButton
            onComplete={(r) => console.log('orderItems completed', r)}
          />
         */}
          {actualVendors.map((vendor, vendorIdx) => {
            const hasErrors = !!Object.keys(vendor.errors).length

            const vendorOrders = allVendorOrders.filter(
              ({ vendorCode }) => vendorCode === vendor.vendorCode
            )
            console.log('vendor, vendorOrder', vendor, vendorOrders)

            const hasVendorOrders = !!vendorOrders
            const hasVendorOrderErrors = vendorOrders?.find(
              (vo) => vo.errors?.length
            )

            return (
              <SnowBox key={JSON.stringify(vendor)}>
                <SnowCardHeader
                  avatar={
                    <StyledAvatar aria-label="recipe">
                      <SnowTypography
                        variant="caption"
                        component="span"
                        fontWeight={400}
                      >
                        Vend
                      </SnowTypography>
                    </StyledAvatar>
                  }
                  title={<VendorItemTitle vendor={vendor} />}
                  subheader={
                    <SnowTypography>
                      {!hasVendorOrders && <EnterTrackingRef vendor={vendor} />}
                    </SnowTypography>
                  }
                />
                {/*Vendor: {prettifyJsonOutput(vendor, true)}*/}
                {/*Vendor Order {prettifyJsonOutput(vendorOrders, true)}*/}
                {vendorOrders.map((vo, idx) => (
                  <VendorOrder key={idx} vendor={vendor} vendorOrder={vo} />
                ))}
                {hasErrors && (
                  <StyledCardContent style={{ padding: '16px' }}>
                    <div>
                      Errors:
                      <SnowList>
                        {Object.values(vendor.errors).map((key) => (
                          <SnowListItem key={key}>
                            <StyledError>
                              {typeof key === 'string'
                                ? key
                                : JSON.stringify(key)}
                            </StyledError>
                          </SnowListItem>
                        ))}
                      </SnowList>
                    </div>{' '}
                  </StyledCardContent>
                )}
                {vendorIdx !== actualVendors.length - 1 && <SnowDivider />}
              </SnowBox>
            )
          })}
        </SnowBox>
      </StyledCard>
      {Boolean(abandonedVendorOrders.length) && (
        <StyledCard>
          <SnowBox>
            <SnowCardHeader title={'Abandoned Vendor Orders'} />
            {abandonedVendorOrders.map((vo) => (
              <VendorOrder key={vo.id} vendorOrder={vo} />
            ))}
          </SnowBox>
        </StyledCard>
      )}
    </>
  )
}

export default OrderVendors
