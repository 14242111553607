import styled from 'styled-components'
import { SnowList } from '../../../../../../../shared/components/snowComponents'

const OrderItemVariant = styled(SnowList).attrs({
  disablePadding: true,
})`
  grid-row: 1/2;
  grid-column: 2/3;
`

export default OrderItemVariant