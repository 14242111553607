import React from 'react'
import { RecoilRoot } from 'recoil'
// import { hot } from 'react-hot-loader'
import { BrowserRouter } from 'react-router-dom'
// import 'bootstrap/dist/css/bootstrap.css'
import './_styled/normalize.scss'
import { GlobalStyle } from './_styled/GlobalStyle'
import Router from './router'
import ScrollToTop from './ScrollToTop'
// import { ThemeProvider as MuiThemeProvider, createTheme } from '@material-ui/core/styles';

// import { MuiThemeProvider } from '@material-ui/core'
// import { createTheme } from '@material-ui/core/styles'
import ScTheme from './ScTheme'
import themeApp from './themeApp'
import { ThemeProvider } from 'styled-components'

// const theme = createTheme({
//   typography: {
//     fontSizeBasic: '16px',
//     lineHeightBasic: '1.5',
//     fontWeightBasic: '400',
//     color: '#637381',
//   },
//   palette: {
//     type: 'light',
//     primary: {
//       main: '#084e8a',
//       light: '#FFFFFF',
//       contrast: '#ffffff',
//       accent: '#4ce1b6',
//       background: '#084e8a',
//       border: '#333246',
//       highlight: '#e6f7ff'
//       // main:'#28365d'
//     },
//     warning: {
//       main: '#FFA500',
//       secondary: 'rgba(255,0,0,1)'
//     },
//     secondary: {
//       main: '#1dafec'
//     },
//     grey: {
//       50: '#F2F2F2',
//       100: 'rgba(0, 0, 0, 0.125)',
//       200: '#637381',
//       main: 'rgba(0, 0, 0, 0.54)',
//       light: '#F1F1F1',
//       middle: 'rgba(181, 181, 181, 1)',
//       dark: '#757575',
//       border: 'rgba(0, 0, 0, 0.3)',
//       shadow: 'rgba(0, 0, 0, 0.11)'
//     },
//     text: {
//       primary: '#637381'
//     }
//   },
//   overrides: {
//     MuiFilledInput: {
//       input: {
//         paddingTop: '5px'
//       }
//     },
//     MuiLinkButton: {
//       color: '#70bbfd'
//     }
//   }
// })

const App = () => {
  return (

    <BrowserRouter>
      <ScrollToTop>
        <RecoilRoot>
          {/* <MuiThemeProvider theme={theme}> */}
            <ThemeProvider theme={themeApp}>
            <GlobalStyle />
              <ScTheme>
                <Router />
              </ScTheme>
            </ThemeProvider>
          {/* </MuiThemeProvider> */}
        </RecoilRoot>
      </ScrollToTop>
    </BrowserRouter>

  )
}

export default App
