import React from 'react'
import styled from 'styled-components'

const StackedItemsView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > * {
    margin: ${({ spacing }) => spacing}rem 0;
  }
`

const ScStackedItems = ({ children, spacing = 0, ...props }) => {
  const { className: derivedClassName = '', ..._props } = props
  return (
    <StackedItemsView
      spacing={spacing}
      aria-labelledby={`ScStackedItems-${derivedClassName}`}
      {..._props}
    >
      {children}
    </StackedItemsView>
  )
}

export default ScStackedItems
