import React, { useEffect } from 'react'
import {
  Route,
  Navigate,
  Routes
} from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import MainWrapper from './MainWrapper'
import LogIn from '../../auth/components/LogInForm'
import WrappedRoutes from './wrappedRoutes'
import useAuthToken from '../../auth/hooks/useAuthToken'
import ScMessage from '../../../shared/service/Message/ScMessage'
import OrderHubDashboard from '../../orders/OrderHubDashboard'
import ProductHubGrid from '../../products/ProductHubGrid'
import OrderHubGrid from '../../orders/OrderHubGrid'
import BundleHubGrid from '../../bundles/BundleHubGrid/BundleHubGrid'
import Integrations from '../../integrations/Integrations'
import ProductDetails from '../../products/ProductDetails/ProductDetails'
import OrderDetail from '../../orders/OrderDetail/OrderDetail'
import BundleDetail from '../../bundles/BundleDetail'
import { currentMessageState } from '../../../shared/service/Message/data/currentMessageState'
import ProjectIntegration from '../../integrations/entities/projects/ProjectIntegration'
import AccountTypeSelection from '../../integrations/entities/AccountTypeSelection'
import IntegrationSettings from '../../integrations/entities/IntegrationSettings'
import IntegrationDomainIndex from '../../integrations/routing/IntegrationDomainIndex'
import VendorIntegration from '../../integrations/entities/vendors/VendorIntegration'
import PersonalizationList from '../../personalization/PersonalizationList'
import ModerationQueue from '../../personalization/moderation/ModerationQueue'
import ChannelIntegration from '../../integrations/entities/salesChannels/Channelntegration'
import OrderItemsHub from '../../orderItems/OrderItemsHub'
import ScSpinner from '../../../shared/components/ScSpinner/ScSpinner'
import TreeTest from '../../orderItems/BulkUpdateModal/TreeTest'
import BatchesHub from '../../batches/pages/BatchesHub'
import BatchView from '../../batches/pages/batchView/BatchView'
import AreasPage from '../../area/AreasPage'
import CarriersPage from '../../carriers/CarriersPage'

import { routesList } from './routesList'
import OrderLogs from '../../orders/OrderLogs'

const ContentLoading = () => <ScSpinner />
const Router = (props) => {
  const { isAuthorized, resetError, error } = useAuthToken()

  const checkAuth = () => { //BYPASS_AUTH
    if (process.env.REACT_APP_BYPASS_AUTH === 'bypass') {
      return true
    }
    return isAuthorized
  }

  const authCheck = checkAuth()
  const setMessage = useSetRecoilState(currentMessageState)

  useEffect(() => {
    if (error) {
      console.log('Auth Error')
      setMessage({ severity: 'error', message: error })
    }
  }, [error])
  // TODO: rethink, refactor and remove this function

  !authCheck && console.log('Route isNot Authorized')

  return (
    // <Suspense fallback={<ContentLoading />}>
    <>
      <MainWrapper>
        <main>
          <Routes>
            <Route index element={<Navigate to="/home" replace />} />
            <Route
              path="home"
              element={authCheck ? <WrappedRoutes /> : <LogIn />}
            >
              <Route index element={<OrderHubDashboard {...props} />} />
              <Route path="order_items" element={<OrderItemsHub />} />
              <Route path="order_items/test" element={<TreeTest />} />
              <Route path="batches" element={<BatchesHub />} />
              <Route path="batches/:batchId" element={<BatchView />} />
              <Route path="order_hub">
                <Route
                  path="dashboard"
                  element={<OrderHubDashboard {...props} />}
                />
                <Route path="grid/" element={<OrderHubGrid {...props} />} />
                <Route
                  path="order_details/*"
                  element={<OrderDetail {...props} />}
                />
                <Route
                  path="order_logs/:orderId"
                  element={<OrderLogs />}
                />
                <Route
                  path="product_details/*"
                  element={<ProductDetails {...props} />}
                />
              </Route>
              <Route
                path="product_hub/grid"
                element={<ProductHubGrid {...props} />}
              />
              <Route
                path="bundle_hub/grid"
                element={<BundleHubGrid {...props} />}
              />
              <Route
                path="bundle_detail"
                element={<BundleDetail {...props} />}
              />
              {/*<Route path="settings/*" element={<SnowShippingMethods />} />*/}
              <Route
                path="personalizations/*"
                element={<PersonalizationList />}
              />
              <Route path="moderationqueue/*" element={<ModerationQueue />} />
              <Route path="integrations" element={<Integrations {...props} />}>
                <Route index element={<AccountTypeSelection />} />
                <Route
                  path=":integrationDomain"
                  element={<AccountTypeSelection />}
                />
                <Route
                  path="projects/:domainId"
                  element={<IntegrationDomainIndex domainName={'projects'} />}
                >
                  <Route index element={<ProjectIntegration />} />
                  <Route
                    path=":projectEntity"
                    element={<ProjectIntegration />}
                  />
                  <Route path="channels/:aggregateId">
                    <Route index element={<IntegrationSettings />} />
                    <Route
                      path=":configEntityType"
                      element={<IntegrationSettings />}
                    />
                  </Route>
                </Route>

                <Route
                  path="vendors/:domainId"
                  element={<IntegrationDomainIndex domainName={'vendors'} />}
                >
                  <Route index element={<VendorIntegration />} />
                  <Route
                    path=":configEntityType"
                    element={<VendorIntegration />}
                  />
                </Route>
                <Route
                  path="sales-channels/:projectId"
                  element={<IntegrationDomainIndex domainName={'channels'} />}
                >
                  <Route index element={<ChannelIntegration />} />
                  <Route
                    path=":configEntityType"
                    element={<ChannelIntegration />}
                  />
                </Route>
              </Route>
              <Route path="area/groups" element={<AreasPage />}></Route>
              <Route path="area/groups/:areaId" element={<AreasPage />}></Route>
              <Route
                path={routesList.CARRIERS}
                element={<CarriersPage />}
              ></Route>
              {/*<Route path="carriers/" element={<CarriersPage />}></Route>*/}
            </Route>
          </Routes>
        </main>
      </MainWrapper>
      <ScMessage onReset={resetError} />
    </>
  )
}

Router.propTypes = {}

export default Router
