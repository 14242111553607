import React, { useEffect, useState } from 'react'
import { fetchItemsToModerate } from './moderationApiService'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'
import { SnowTypography } from '../../../shared/components/snowComponents'
import { ScActionsBar, ScPageContent } from '../../../shared/components/LayoutComponents'
import BulkGrid from '../../../shared/components/BulkComponents/BulkGrid'
import loadedPersonalizationItemsFields from '../data/loadedPersonalizationItemsFields'
import { setScPageHeaderTitleCmd } from '../../app/data/currentHeadingState'
import { useApproveModerationCmd } from './moderationApisV2'
import ScSpinner from '../../../shared/components/ScSpinner/ScSpinner'
import { PerPageSelector } from '../components/PerPageSelector'
import { ScPrimaryBtn } from '../../../shared/components/buttons'
import BulkSelector from '../../../shared/components/BulkComponents/BulkGrid/components/BulkSelector'
import { moderationListAtom } from './moderationState'
import ModerationStatusSelector from '../components/ModerationStatusSelector'

const moderationRawToModel = (raw) => {
  const {
    personalization,
    meta,
    order_id,
    order_number,
    created_at,
    item_id,
    item_name,
    sales_channel
  } = raw

  const {
    status,
    moderation_status,
    type,
    preview,
    refnum,
    image_url,
    troubles
  } = personalization[0] || {}

  return {
    uid: item_id,
    data: {
      order_id,
      order_number,
      created_at,
      item_id,
      item_name,
      sales_channel,
      personalizer_status: status,
      moderation_status,
      type,
      preview,
      refnum,
      image_url,
      troubles
    },
    meta
  }
}

const useModerationItems = () => {
  const [itemsToModerate, setItemsToModerate] =
    useRecoilState(moderationListAtom)
  const resetItemsToModerate = useResetRecoilState(moderationListAtom)
  return [itemsToModerate, setItemsToModerate, resetItemsToModerate]
}

const ModerationQueue = () => {
  const [loadLimit, setLoadLimit] = useState(8)
  const [isLoading, setIsLoading] = useState(false)
  const [reloadRequired, wantToReload] = useState(false)
  const [idsToPreselect, setIdsToPreselect] = useState([])
  const [doBulkSelectedResetCmd] = useState(null)

  const statusesToModerate = ['waiting', 'moderation']
  const [currentStatus, setCurrentStatus] = useState('moderation')
  const [currentMeta, setCurrentMeta] = useState({})

  const [forceSelect, setForceSelect] = useState(false)
  const [itemsToModerate, setItemsToModerate, resetItemsToModerate] =
    useModerationItems()

  const { data: columns } = useRecoilValue(loadedPersonalizationItemsFields)

  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)

  const resetModerationQueueState = () => {
    console.log('resetPersonalizationStates', doBulkSelectedResetCmd)
    setIdsToPreselect([])
    resetItemsToModerate()
    doBulkSelectedResetCmd && doBulkSelectedResetCmd()
  }

  const approveItemsCmd = useApproveModerationCmd(
    { orderBy: 'desc', moderationStatus: currentStatus, perPage: loadLimit },
    (resp) => {
      const newItemsToModerate = resp?.data?.data

      console.log('newItemsToModerate', resp, newItemsToModerate)

      resp?.data.meta && setCurrentMeta(resp.data.meta)

      setItemsToModerate([
        ...newItemsToModerate.map((items) => moderationRawToModel(items))
      ])
      setIdsToPreselect([
        ...newItemsToModerate.map((items) => moderationRawToModel(items).uid)
      ])
      setForceSelect(true)
    }
  )

  useEffect(() => {
    const fetchParams = {
      orderby: 'desc',
      per_page: loadLimit,
      moderation_status: currentStatus
    }
    console.log('params to fetch', fetchParams)
    setIsLoading(true)

    fetchItemsToModerate(fetchParams).then((resp) => {
      console.log('fetchItemsToModerate resp', resp)
      setIsLoading(false)
      wantToReload(false)

      const personalizations = (Array.isArray(resp.data) && resp.data) || []
      const hasItems = personalizations.length

      if (!hasItems) return

      resp.meta && setCurrentMeta(resp.meta)

      setItemsToModerate((prevItems) => [
        ...prevItems,
        ...personalizations.map((items) => moderationRawToModel(items))
      ])
      setIdsToPreselect((preselectedIds) => [
        ...preselectedIds,
        ...personalizations.map((items) => moderationRawToModel(items).uid)
      ])
    })
  }, [loadLimit, currentStatus, reloadRequired])

  useEffect(() => {
    resetModerationQueueState()
  }, [loadLimit, reloadRequired, currentStatus])

  useEffect(() => {
    setHeading('Design Moderation Queue')
    return () => {
      console.log('personalizationList destructor')
      resetItemsToModerate()
    }
  }, [])

  const onPerPageChange = (newLimit) => {
    console.log('onPerPageChange', newLimit)
    setLoadLimit(newLimit)
  }

  const hasItemsToModerate = !!itemsToModerate.length

  const onStatusFilterChange = (status) => {
    console.log('onStatusFilterChange', status)
    setCurrentStatus(status)
  }

  console.log(
    'ModerationQueue forceSelect, redraw',
    currentStatus,
    forceSelect,
    idsToPreselect,
    itemsToModerate
  )
  return (
    <>
      <ScPageContent>
        <ScActionsBar justifyContent={'space-between'}>
          {hasItemsToModerate && (
            <>
              <BulkSelector
                gridItemIds={itemsToModerate.map(({ uid }) => uid)}
                isEditable={false}
                preselected={idsToPreselect}
                forceSelect={forceSelect}
              />
              <ScPrimaryBtn
                size={'medium'}
                onClick={() => {
                  approveItemsCmd()
                }}
                label={'Approve selected'}
              />
            </>
          )}
          <ModerationStatusSelector
            onChange={onStatusFilterChange}
            defaultStatus={currentStatus}
            availableStatuses={statusesToModerate}
          />
          <PerPageSelector
            defaultLimit={loadLimit}
            onChange={onPerPageChange}
            total={currentMeta?.total ?? 0}
          />
        </ScActionsBar>

        <BulkGrid
          fields={columns}
          gridItems={itemsToModerate}
          preselected={idsToPreselect}
        />
      </ScPageContent>
      {!hasItemsToModerate && !isLoading && (
        <SnowTypography>
          There is no Personalization items to moderate. Consult with specialist
          if you expected more personalizations or try to increase Per page
          limit.
        </SnowTypography>
      )}
      {isLoading && <ScSpinner />}
    </>
  )
}

export default ModerationQueue
