import React, { useEffect } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { ScDateRange } from '../../../SnowEditableField/baseFields'
import SnowEditableField from '../../../SnowEditableField'
import filterState from '../../../BulkComponents/data/filterState'

export const HeaderFilter = ({
  meta,
  uid,
  label,
  value,
  dataKey,
  onFilterChange
}) => {
  const uniqDataKey = `${uid}.${dataKey}`
  const [filterVal, setFilterVal] = useRecoilState(filterState(uniqDataKey))
  const resetFilterStr = useResetRecoilState(filterState(uniqDataKey))

  useEffect(() => {
    meta.editType === 'date' &&
      console.log('HeaderFilter useEffect value ', value)
    meta.editType === 'date' &&
      console.log('HeaderFilter useEffect filterVal ', filterVal)
    onFilterChange(filterVal)
  }, [filterVal])

  useEffect(() => {
    setFilterVal(value)
    return () => resetFilterStr()
  }, [])

  useEffect(() => {
    console.log('HeaderFilter value', meta, meta.editType, value)
    setFilterVal(value)
  }, [value])

  meta.editType === 'dropdown' &&
    console.log('HeaderFilter meta', { filterVal, value, meta })

  if (meta.editType === 'date')
    return (
      <ScDateRange
        label={label}
        edit={true}
        inputFontSize={'0.75rem'}
        value={filterVal}
        onUpdate={({ value }) => setFilterVal(value)}
      />
    )
  const filterType = meta.filterType || meta.editType === 'text' && 'combo' || 'combo'
  console.log('HeaderFilter', { ...meta, editType: filterType })
  return (
    <SnowEditableField
      tooltip={label ? `Type ${label}` : ''}
      disableIndicatorNewValue={true}
      tooltipNewValue={false}
      meta={{ ...meta, editType: filterType }}
      code={`${uniqDataKey}`}
      uid={`filter-${uniqDataKey}`}
      label={label}
      value={filterVal}
      edit={true}
      variant={'outlined'}
      updateDelay={700}
      onUpdate={({ value }) => setFilterVal(value)}
      onClear={() => setFilterVal('')}
    />
  )
}
export default HeaderFilter
