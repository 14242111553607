import { portalRequestor } from "../../../infrastructure/api/v1/requestors";
import { useParams } from "react-router-dom";
import { useSetRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';
import { setScPageHeaderTitleCmd } from "../../app/data/currentHeadingState";


async function fetchLogs(orderId, callback) {
    const logs = await portalRequestor({
        type: 'GET',
        url: `logs/order/${orderId}`,
        caller: 'orderLogs',
    });

    callback(logs);
}

export default function OrderLogs() {
    const setHeading = useSetRecoilState(setScPageHeaderTitleCmd);
    const { orderId } = useParams();
    const [logs, setLogs] = useState(null);

    useEffect(() => {
        setHeading(`Request Logs: Order #${orderId}`);
        fetchLogs(orderId, setLogs);
    }, []);
    console.log('OrderLogs result', logs);

    return (
        <pre>
            {JSON.stringify(logs, null, 4)}
        </pre>
    );
}