import React from 'react'
import { ScPrimaryBtn, ScSecondaryBtn } from '../../buttons'
import {
  SnowDialog,
  SnowDialogActions,
  SnowDialogContentText,
  SnowPaper,
  SnowTypography
} from '../../snowComponents'
import { SnowWarningIcon } from '../../icons'
import { ReactComponent as ResyncIcon } from '../../../../domains/app/images/SVGs/resync.svg'
import styled, { css } from 'styled-components'

const ConfirmationAlertView = styled(SnowDialog)`
  position: relative;
  .MuiDialog-paper {
    ${({ theme, width }) => css`
      padding: ${theme.spacing.secondary};
      width: ${width ? `${width}px` : '500px'};
      background: ${theme.mainPalette.grey.light};
    `}
  }
`

const ConfirmationAlertHeaderView = styled.div`
  padding: ${({ theme }) => theme.spacing.secondary};
  display: flex;
  justify-content: center;
  svg {
    width: 40px;
    height: auto;
  }
`

const ConfirmationAlertPaperView = styled(SnowPaper)`
  ${({ theme }) => css`
    padding: ${theme.spacing.secondary};
    margin-bottom: ${theme.spacing.secondary};
  `}
`

const ConfirmationAlertTitleView = styled(SnowTypography)`
  text-align: center;
  &.MuiTypography-root {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.secondary};
    font-weight: ${({ theme }) => theme.fontWeight.main};
  }
`

const ConfirmationAlertActionView = styled(SnowDialogActions)`
  &.MuiDialogActions-root {
    justify-content: center;
  }
`

const ConfirmationAlertLoadingView = styled.div`
  padding: ${({ theme }) => theme.spacing.main};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ConfirmationAlertResyncIconView = styled(ResyncIcon)`
  ${({ theme }) => css`
    width: 24px;
    animation: ${theme.animation.rotation};
  `}
`

const ScConfirmationAlert = ({
  isShowAttention = false,
  message = { title: '', content: '', syncText: '' },
  onClose = () => {},
  onConfirm,
  loading = false,
  icon,
  isIconExist = true,
  labelForConfirmBtn = '',
  labelForCancelBtn = '',
  isHtmlInContent = false,
  onConfirmProps = {}
}) => {
  const { title, content, syncText } = message ?? {}
  console.log('message content', { content, isHtmlInContent, onConfirmProps })

  return (
    <ConfirmationAlertView
      open={isShowAttention}
      onClose={onClose}
      aria-label="alert-dialog"
      aria-describedby="alert-dialog-description"
    >
      <ConfirmationAlertHeaderView>
        {icon ? icon : isIconExist && <SnowWarningIcon />}
      </ConfirmationAlertHeaderView>
      <ConfirmationAlertPaperView>
        {title ? (
          <ConfirmationAlertTitleView component="h3" variant="h6" id="alert-dialog-title">
            {title}
          </ConfirmationAlertTitleView>
        ) : null}
        {content ? (
          <div>
            {isHtmlInContent ? (
              <SnowDialogContentText
                id="alert-dialog-description"
                dangerouslySetInnerHTML={{ __html: `${content}` }}
              ></SnowDialogContentText>
            ) : (
              <div id="alert-dialog-description">{content}</div>
            )}
          </div>
        ) : null}
        {loading ? (
          <ConfirmationAlertLoadingView>
            <p>{syncText ? syncText : `Data is syncing`}</p>
            <ConfirmationAlertResyncIconView />
          </ConfirmationAlertLoadingView>
        ) : null}
      </ConfirmationAlertPaperView>
      <ConfirmationAlertActionView>
        <ScSecondaryBtn
          style={{ textTransform: 'capitalize', padding: '9px 22px' }}
          onClick={onClose}
          label={labelForCancelBtn ? labelForCancelBtn : 'Cancel'}
        />
        {onConfirm ? (
          <ScPrimaryBtn
            onClick={onConfirm}
            disabled={onConfirmProps.disabled}
            variant="contained"
            label={labelForConfirmBtn ? labelForConfirmBtn : 'OK'}
            style={{ minWidth: '100px', textTransform: 'capitalize' }}
          />
        ) : null}
      </ConfirmationAlertActionView>
    </ConfirmationAlertView>
  )
}
export default ScConfirmationAlert
