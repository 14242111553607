import styled, {css} from 'styled-components'

const OrderPaymentView = styled.div`
  ${({theme})=>css`
    padding: ${theme.spacing.secondary};
    margin-bottom: ${theme.spacing.secondary};
    box-shadow: ${theme.shadow.main};
  `}
`

export default OrderPaymentView