import React from 'react'
import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily
} from 'recoil'
import {
  addElementInCollection,
  removeElementInCollection
} from '../../../../utils/helpers'

const selectedBulkItemsState = atom({
  key: 'selectedBulkItemsState',
  default: []
})

const selectedBulkItemFamily = atomFamily({
  key: 'selectedBulkItemFamily',
  default: false
})

export const selectedBulkItem = selectorFamily({
  key: 'selectedBulkItem',
  get:
    (uid) =>
    ({ get }) => {
      return get(selectedBulkItemFamily(uid))
    },
  reset:
    (uid) =>
    ({ reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        // DefaultValue means reset context
        // console.log('going to reset filter')
        reset(selectedBulkItemFamily(uid))
        // reset(selectedBulkItemsState)
        return
      }
    },
  set:
    (uid) =>
    ({ get, set, reset }, newValue) => {
      if (newValue instanceof DefaultValue) {
        // DefaultValue means reset context
        // console.log('going to reset filter')
        reset(selectedBulkItemFamily(uid))
        // reset(selectedBulkItemsState)
        return
      }
      console.log('selectedBulkItem newValue', uid, newValue)
      // console.log('selected bulk UIDs', get(selectedBulkItemsState), newValue)
      set(selectedBulkItemFamily(uid), newValue)
      set(selectedBulkItemsState, (prev) =>
        newValue !== '' && newValue !== false
          ? addElementInCollection(prev, uid)
          : removeElementInCollection(prev, uid)
      )
    }
})

export const selectedBulkItemIds = selector({
  key: 'selectedBulkItemIds',
  get: ({ get }) => get(selectedBulkItemsState)
})

export const selectItemsCmd = selectorFamily({
  key: 'selectItemsCmd',
  get:
    (items) =>
    ({ get }) => {
      console.log('selectItemsCmd get', get(selectedBulkItemIds))
      return get(selectedBulkItemIds)
    },
  set:
    (items) =>
    ({ set, get, reset }, newValue) => {
      console.log('selectItemsCmd set', items, newValue)
      items.map((uid) => set(selectedBulkItem(uid), newValue))
      newValue
        ? set(selectedBulkItemsState, items)
        : set(selectedBulkItemsState, [])
    }
})
