import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'

const FETCH_BATCHES_URL = ''
const FETCH_BATCH_URL = '?'

const API_BATCHES = objWithParent({
  url: '/batches',
  FETCH_BATCHES: {
    url: '',
    method: 'GET',
    caller: 'API_BATCHES FETCH_BATCHES_URL',
    validator: () => true //{page, per_page, filters??}
  },
  FETCH_BATCH_DETAILS: {
    url: '',
    method: 'GET',
    caller: 'API_BATCHES FETCH_1BATCH_URL',
    validator: ({ batchId }) => true //{page, per_page, filters??}
  },
  FETCH_BATCH_ITEMS_URL: {
    url: 'items',
    method: 'GET',
    caller: 'API_BATCHES FETCH_BATCH_ITEMS',
    validator: ({ batchId }) => true //{page, per_page, filters??}
  },
  APPROVE_BATCH_EXECUTION: {
    url: 'approve',
    method: 'POST',
    caller: 'API_BATCHES APPROVE BATCH TO EXECUTE',
    validator: ({ batchId }) => true //{page, per_page, filters??}
  },
  REFUSE_BATCH_EXECUTION: {
    url: 'refuse',
    method: 'POST',
    caller: 'API_BATCHES REFUSE BATCH EXECUTION',
    validator: ({ batchId }) => true //{page, per_page, filters??}
  },
  CANCEL_BATCH_EXECUTION: {
    url: 'cancel',
    method: 'POST',
    caller: 'API_BATCHES CANCEL BATCH EXECUTION',
    validator: ({ batchId }) => true //{page, per_page, filters??}
  }
})

export const useFetchBatchesCmd = (
  { cursor, per_cursor, filters },
  onSuccess = (r) => console.log('useFetchBatchesCmd success', r),
  onFail = (r) => console.log('useFetchBatchesCmd fail', r)
) => {
  console.log('useFetchBatchesCmd', cursor, per_cursor, filters, {
    cursor,
    per_cursor,
    ...filters
  })

  const result = useEntityCommand(
    null,
    {
      ...API_BATCHES.FETCH_BATCHES,
      params: { cursor, per_cursor, ...filters }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useFetchBatchItemsCmd = (
  { batchId, cursor, per_cursor },
  onSuccess = (r) => console.log('useFetchBatchesCmd success', r),
  onFail = (r) => console.log('useFetchBatchesCmd fail', r)
) => {
  console.log('useFetchBatchesCmd', batchId)

  const result = useEntityCommand(
    batchId,
    {
      ...API_BATCHES.FETCH_BATCH_ITEMS_URL,
      params: { cursor, per_cursor }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useFetchBatchDetailsCmd = (
  { batchId },
  onSuccess = (r) => console.log('useFetchBatchDetailsCmd success', r),
  onFail = (r) => console.log('useFetchBatchDetailsCmd fail', r)
) => {
  console.log('useFetchBatchDetailsCmd init for batchId', batchId)

  const result = useEntityCommand(
    batchId,
    {
      ...API_BATCHES.FETCH_BATCH_DETAILS,
      params: {}
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useApproveBatchCmd = (
  { batchId },
  onSuccess = (r) => console.log('useApproveBatchCmd success', r),
  onFail = (r) => console.log('useApproveBatchCmd fail', r)
) => {
  console.log('useApproveBatchCmd init for batchId', batchId)

  const result = useEntityCommand(
    batchId,
    {
      ...API_BATCHES.APPROVE_BATCH_EXECUTION,
      params: {}
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useRefuseBatchCmd = (
  { batchId },
  onSuccess = (r) => console.log('useRefuseBatchCmd success', r),
  onFail = (r) => console.log('useRefuseBatchCmd fail', r)
) => {
  console.log('useRefuseBatchCmd init for batchId', batchId)

  const result = useEntityCommand(
    batchId,
    {
      ...API_BATCHES.REFUSE_BATCH_EXECUTION,
      params: {}
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}

export const useCancelBatchCmd = (
  { batchId },
  onSuccess = (r) => console.log('useCancelBatchCmd success', r),
  onFail = (r) => console.log('useCancelBatchCmd fail', r)
) => {
  console.log('useCancelBatchCmd init for batchId', batchId)

  const result = useEntityCommand(
    batchId,
    {
      ...API_BATCHES.CANCEL_BATCH_EXECUTION,
      params: {}
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return result
}
