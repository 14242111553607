import {
  atom,
  atomFamily,
  selector,
  DefaultValue
} from 'recoil'
import { fetchCurrencies } from '../infrastructure/v1/apis'

const currencyAtomFamily = atomFamily({
  key: 'currencyAtomFamily',
  default: (currencyCode) => {}
})

const currencyCodesAtom = atom({
  key: 'currencyCodesAtom',
  default: []
})

const currencyLoadedAtom = atom({
  key: 'currencyLoadedAtom',
  default: selector({
    key: 'currencyLoaderSelector',
    get: async ({ get }) => {
      const currencies = await fetchCurrencies()
      console.log('currencyLoaderSelector', currencies)
      if (!Array.isArray(currencies)) {
        console.log(
          'fetchCurrencies error. Currencies is not array',
          currencies
        )
        return []
      }
      return currencies
    }
  })
})

export const currencySelector = selector({
  key: 'currencySelector',
  get: ({ get }) => get(currencyLoadedAtom),
  set: ({ get, set, reset }, newValue) => {
    console.log('set currencySelector')
    const loadedCurrencies = get(currencyLoadedAtom)
    if (newValue instanceof DefaultValue) {
      loadedCurrencies.map((currency) =>
        reset(currencyAtomFamily(currency.code))
      )
      reset(currencyCodesAtom)
      return
    }
    set(
      currencyCodesAtom,
      newValue.map((currency) => currency.code)
    )
    newValue.map((currency) => set(currencyAtomFamily(currency.code), currency))
  }
})
