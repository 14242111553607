import React, { useEffect, useState } from 'react'
import CarriersTableTemplate from '../components/CarriersTableTemplate'
import {
  createVendorsMappingItem,
  NEW_VENDOR_MAPPING_ID
} from '../data/carriersList'
import { filtrateList } from '../../../utils/filters'
import { useCarriersVendorsMappingApi } from '../infrastructure/carriersVendorsMappingApi'

const VendorsMappingContent = ({ carriers }) => {
  const { mappings, updateMapping, deleteMapping, createMapping } =
    useCarriersVendorsMappingApi()

  const [shownList, setShownList] = useState([])
  const [filters, setFilters] = useState({})

  const handleAddNew = (evt) => {
    const newItem = createVendorsMappingItem()
    setShownList((list) => [newItem, ...list])
  }

  const handleFilter = ({ code, value }) => {
    setFilters((applied) => ({ ...applied, [code]: value }))
  }

  const handleFilterClear = () => {
    console.log('VendorMappingsContent filter clear click', filters)
    setFilters({})
  }

  const hasNewItem = () => {
    return Boolean(shownList?.find(({ id }) => id === NEW_VENDOR_MAPPING_ID))
  }

  const handleUpdate = (row) => {
    console.log('VendorMappingsContent handle handleUpdate', row)
    updateMapping({
      ...row
    })
  }

  const handleCreate = (row) => {
    console.log('VendorMappingsContent handle handleCreate', row)

    setShownList((list) => [
      ...list.filter(({ id }) => id !== NEW_VENDOR_MAPPING_ID)
    ])
    createMapping({
      ...row,
      id: mappings[mappings.length - 1]?.id + 1
    })
  }

  const handleRemove = ({ id: removeId }) => {
    deleteMapping({ id: removeId })
  }

  const handleEdit = (data, value) => {
    console.log('VendorMappingsContent handle handleEdit', data, value)
  }

  const columns = [
    {
      field: 'carrier_code',
      headerName: 'SA carrier code',
      headerFieldType: 'combo',
      fieldType: 'combo',
      width: '30%',
      headerOptions: carriers?.map(({ code }) => code),
      options: carriers?.map(({ code }) => code),
      required: true,
      onFilter: handleFilter
    },
    {
      field: 'code',
      headerName: 'Vendor carrier code',
      headerFieldType: 'combo',
      width: '30%',
      required: true,
      onFilter: handleFilter
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      headerFieldType: 'combo',
      width: '30%',
      onFilter: handleFilter
    }
  ]

  useEffect(() => {
    setShownList(filtrateList({list:mappings, filters}))
    console.log('VendorMappingsContent income data', mappings)
  }, [mappings])

  useEffect(() => {
    setShownList(filtrateList({list:mappings, filters}))
    console.log('VendorMappingsContent filters change', filters)
  }, [filters])

  return (
    <CarriersTableTemplate
      title={'Vendor mappings list'}
      data={shownList}
      columns={columns}
      filters={filters}
      handleFilterClear={handleFilterClear}
      handleAddNew={handleAddNew}
      hasNewItem={hasNewItem()}
      onEdit={handleEdit}
      onCreate={handleCreate}
      onRemove={handleRemove}
      onUpdate={handleUpdate}
    />
  )
}

export default VendorsMappingContent
