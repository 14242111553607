// This file contains functions used in Order Grid -- functions that filter
// orders using API calls
import {
  portalRequestor,
  saFetch
} from '../../../infrastructure/api/v1/requestors'
const BASE_URL = process.env.REACT_APP_URL

//create bundle parent
export const addBundle = ({ token, data }) => {
  const postData = JSON.stringify(data)
  const fullURL = `${BASE_URL}/portal/v1/bundles/create`
  return saFetch({
    token,
    url: fullURL,
    type: 'post',
    caller: 'addBundle',
    fetchParams: data
  })
  // return fetch(fullURL, {
  //   method: 'POST',
  //   headers: {
  //     Accept: 'application/json',
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   },
  //   body: postData
  // })
}

// function to GET an all products info
export const fetchProducts = async ({ page = 1, search: searchStr, sort }) => {
  const filterArray = searchStr ? { sku: `%${searchStr}%` } : false
  const params = {
    filters: filterArray,
    sortorder: { id: 'desc' },
    meta: { per_page: 20, page: page }
  }
  const url = `bundles?page=${page}`
  return await portalRequestor({
    type: 'post',
    fetchParams: params,
    url,
    caller: 'fetchBundles'
  })
}
