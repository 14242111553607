import {
  atom,
  atomFamily,
  selector,
  useRecoilState,
  useRecoilValue
} from 'recoil'
import { useEffect, useState } from 'react'
import { useFetchVendorRegionsCmd } from '../infrastructure/regionVendorsApi'

const vendorRegionsLoadedPage = atomFamily({
  key: 'vendorRegionsLoadedPage',
  default: []
})

const vendorRegionsLoadedPageUids = atom({
  key: 'vendorRegionsLoadedPageUids',
  default: new Set()
})
const vendorRegionsLoadedSelector = selector({
  key: 'vendorRegionsLoadedSelector',
  get: ({ get }) => {
    console.log(
      'get(vendorRegionsLoadedSelector)',
      get(vendorRegionsLoadedPageUids)
    )
    return Array.from(get(vendorRegionsLoadedPageUids)).reduce(
      (acc, pageUid) => [...acc, ...get(vendorRegionsLoadedPage(pageUid))],
      []
    )
  }
})

export const useLoadedVendorRegions = ({
  page = 1,
  per_page = 40,
  vendor_id,
  area_group_id
}) => {
  const pageUid = JSON.stringify({
    page,
    per_page,
    vendor_id,
    area_group_id
  })
  const [, setVendorRegions] = useRecoilState(vendorRegionsLoadedPage(pageUid))
  const loadedVendorRegions = useRecoilValue(vendorRegionsLoadedSelector)
  const [pagesUids, setVendorRegionsPagesUids] = useRecoilState(
    vendorRegionsLoadedPageUids
  )

  const [shownVendorRegions, setShownVendorRegions] =
    useState(loadedVendorRegions)

  const onRemove = (removedId) => {
    setShownVendorRegions((groups) =>
      groups.filter(({ id }) => removedId !== id)
    )
  }
  const fetchVendorRegionsCmd = useFetchVendorRegionsCmd(
    {
      page,
      per_page,
      vendor_id,
      area_group_id
    },
    (r) => {
      console.log('useFetchAreaGroupsCmd onSuccess ', r?.data)
      r?.data && setVendorRegions(r?.data)
    },
    (r) => console.log('useFetchAreaGroupsCmd onFail r', r)
  )

  useEffect(() => {
    setShownVendorRegions(loadedVendorRegions)
  }, [loadedVendorRegions])

  useEffect(() => {
    console.log('useEffect pageUid', pagesUids.has(pageUid), pageUid, pagesUids)
    !pagesUids.has(pageUid) &&
      setVendorRegionsPagesUids((pUids) => new Set([...pUids, pageUid]))
    !pagesUids.has(pageUid) && fetchVendorRegionsCmd()
  }, [pageUid])

  console.log('useVendorRegions!', ...loadedVendorRegions)
  return { loadedVendorRegions: shownVendorRegions, onRemove }
}
