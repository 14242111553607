import React, { useEffect, useState } from 'react'
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil'
import { ScOutlinedBtn, ScPrimaryBtn } from '../../shared/components/buttons'
import { ScPageContent, ScActionsBar } from '../../shared/components/LayoutComponents'
import ScSpinner from '../../shared/components//ScSpinner/ScSpinner'
import {
  fetchPersonalizationFields,
  fetchPersonalizations
} from './infrastructure/personalizationApiService'
import { personalizationListAtom } from './data/personalisationsModel'
import BulkGrid from '../../shared/components/BulkComponents/BulkGrid'
import loadedPersonalizationItemsFields, {
  filterablePersonalizationItemFields
} from './data/loadedPersonalizationItemsFields'
import { setScPageHeaderTitleCmd } from '../app/data/currentHeadingState'
import { useApprovePersonalizationsCmd } from './infrastructure/apisV2'
import FiltersBar from './components/FiltersBar'
import { filtersDtoSelector } from '../../shared/components/BulkComponents/data/filterState'
import BulkSelector from '../../shared/components/BulkComponents/BulkGrid/components/BulkSelector'

const rawToModel = (raw) => {
  const {
    personalization,
    meta,
    order_id,
    order_number,
    created_at,
    personalizer_status,
    item_id
  } = raw

  const { status, type, preview, refnum, image_url, troubles } =
    personalization[0] || {}

  const linkToOrder = `home/order_hub/order_details/${order_id}`

  return {
    uid: item_id,
    data: {
      order_id,
      order_number,
      created_at,
      item_id,
      personalizer_status,
      type,
      preview,
      refnum,
      image_url,
      troubles
    },
    meta
  }
}
const PersonalizationList = () => {
  const [lastLoadedPage, setLastLoadedPage] = useState(1)
  const loadLimit = 12
  const [noMore, setNoMore] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const approvementCmdHandler = (resp) => {
    console.log('PersonalizationList approved', resp)
  }
  const approveItemsCmd = useApprovePersonalizationsCmd(
    [],
    approvementCmdHandler
  )

  const [filterableFields, setFilterableFields] = useRecoilState(
    filterablePersonalizationItemFields
  )
  const filtersDto = useRecoilValue(filtersDtoSelector('GET'))

  const { data: columns } = useRecoilValue(loadedPersonalizationItemsFields)

  // const { updateBulkItemsToSelected } = useSelectBulkItemsCommands()
  const setHeading = useSetRecoilState(setScPageHeaderTitleCmd)

  const [personalizationList, setPersonalizationList] = useRecoilState(
    personalizationListAtom
  )
  const resetPersonalizationList = useResetRecoilState(personalizationListAtom)

  useEffect(() => {
    const fetchParams = {
      ...filtersDto,
      page: lastLoadedPage,
      limit: loadLimit
    }
    setIsLoading(true)
    fetchPersonalizations(fetchParams).then((resp) => {
      console.log('fetchPersonalizations resp', resp)
      const loadedPers = (Array.isArray(resp.data) && resp.data) || []
      loadedPers.length < loadLimit && setNoMore(true)
      loadedPers.length &&
        setPersonalizationList((lst) => [
          ...lst,
          ...loadedPers.map((rawPers) => rawToModel(rawPers))
        ])
      setIsLoading(false)
    })
  }, [filtersDto, lastLoadedPage])

  useEffect(() => {
    // console.log('useEffect filtersDto', filtersDto)
    resetPersonalizationList()
    setNoMore(false)
    setLastLoadedPage(1)
  }, [filtersDto])

  useEffect(() => {
    setHeading('Personalizations Hub')
    fetchPersonalizationFields().then((r) => {
      console.log('useEffect fetchPersonalizationFields', r)
      r.filters && setFilterableFields(r.filters)
    })
    return () => {
      console.log('personalizationList destructor')
      resetPersonalizationList()
    }
  }, [])

  const loadMore = () => {
    setLastLoadedPage((last) => last + 1)
  }

  const acceptItems = () => {
    console.log('approveItems')
    approveItemsCmd()
    //todo: clear selected state after accepting
    //todo:refresh items
  }
  const onSelectAll = () => {
    // setSelectAllFlag(1)
    console.log('personalizationList selectall')
  }
  const onUnselectAll = () => {
    console.log('personalizationList unselectAll')
  }

  console.log('personalizationList', personalizationList)

  return (
    <ScPageContent>
      <ScActionsBar>
        <FiltersBar filters={filterableFields} />
        <BulkSelector
          gridItemIds={personalizationList.map(({ uid }) => uid)}
          isEditable={false}
        />
        <ScPrimaryBtn
          label={'Approve selected'}
          size={'medium'}
          onClick={acceptItems}
        />
      </ScActionsBar>
      {personalizationList.length && (
        <BulkGrid fields={columns} gridItems={personalizationList} />
      )}
      {isLoading ? (
        <ScSpinner />
      ) : (
        !noMore && (
          <ScActionsBar>
            <ScOutlinedBtn label={'Load More'} onClick={loadMore} />
          </ScActionsBar>
        )
      )}{' '}
    </ScPageContent>
  )
}

export default PersonalizationList
