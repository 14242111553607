import React, { useState } from 'react'
import { SnowList, SnowListItem, SnowTypography } from '../../snowComponents'
import { ScConfirmationAlert } from '..'
import useAuthToken from '../../../../domains/auth/hooks/useAuthToken'
import styled from 'styled-components'

const FileInputView = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  &:focus-visible ~ * {
    outline: 1px solid #000000;
  }
`

const FileInputLableView = styled.label`
  ${({ theme }) => `
    padding: ${theme.spacing.secondary};
    border: ${theme.border.decorative};
    border-radius: ${theme.borderRadius.secondary};
  `}
  ${({ theme, isfile }) =>
    isfile ? `border-color: ${theme.mainPalette.grey.main}` : ''};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  cursor: pointer;
`

const UploadErrorItemView = styled(SnowListItem)`
  color: ${({ theme }) => theme.mainPalette.warning.secondary};
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: 4px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${({ theme }) => theme.mainPalette.warning.secondary};
  }
`

const ScUploadModal = ({
  modalTitle = 'Import orders',
  fileParamName,
  uploadFn = () => {
    console.log('uploader cmd')
  },
  ...props
}) => {
  const [filename, setFilename] = useState('')
  const [messages, setMessages] = useState([])
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const { fetchWithToken } = useAuthToken()

  const inputChange = (e) => {
    const input = e.target
    if (input.value) {
      setFilename(input.files[0].name)
      setFile(input.files[0])
    } else {
      setFilename('')
      setFile(null)
    }
  }

  const reset = () => {
    setFile(null)
    setFilename('')
    setMessages([])
  }

  const cancel = () => {
    reset()
    props.toggleShowModal()
  }

  const upload = () => {
    setLoading(true)
    const fd = new FormData()
    fd.append(fileParamName, file)
    console.log('upload fileParamName', fileParamName)
    fetchWithToken(uploadFn, { formData: fd }).then((res) => {
      setLoading(false)
      console.log('uploaded')

      if (!res.data) {
        setMessages([
          { type: 'error', message: 'No response data from server' }
        ])
        return false
      }
      res.data.messages && setMessages(res.data.messages)
    })
  }

  return (
    <ScConfirmationAlert
      isShowAttention={props.showModal}
      isIconExist={false}
      loading={loading}
      message={{
        title: `${modalTitle} - ${fileParamName}`,
        syncText: 'Uploading',
        content: (
          <>
            <form>
              <FileInputLableView htmlFor="csvInput" isfile={Boolean(filename)}>
                <SnowTypography component="span" variant="body1">
                  {filename ? filename : 'Drop .csv file here'}
                </SnowTypography>
              </FileInputLableView>
              <FileInputView
                id="csvInput"
                onChange={inputChange}
                type="file"
                accept=".csv"
              />
            </form>
            {messages?.length ? (
              <SnowList>
                {messages.map((msg, i) => (
                  <>
                    {
                      <UploadErrorItemView aria-label={msg.type} key={i}>
                        {msg.text ? msg.text : 'Something went wrong'}
                      </UploadErrorItemView>
                    }
                  </>
                ))}
              </SnowList>
            ) : null}
          </>
        )
      }}
      labelForConfirmBtn={'Upload'}
      onClose={props.toggleShowModal}
      onConfirm={upload}
      onConfirmProps={{ disabled: Boolean(!filename || filename.length===0)}}
    />
  )
}

export default ScUploadModal
