import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import objWithParent from '../../../utils/parenter'

const BUNDLES_BULK_DELETE_URL = 'delete'
const BUNDLES_BULK_CREATE_URL = 'create'
const BUNDLES_ITEM_UPDATE_URL = 'update'
const BUNDLES_ITEM_UPLOAD_URL = 'upload'
const BUNDLES_ITEM_FETCH_URL = ''

const API_BUNDLES_BULK = objWithParent({
  url: '/bundles',
  FETCH_ALL: {
    url: `/`,
    method: 'GET',
    caller: 'API_BUNDLES_BULK FETCH_ALL!',
    validator: ({ page, limit }) =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  },
  FETCH_ITEM: {
    url: `${BUNDLES_ITEM_FETCH_URL}`,
    method: 'GET',
    caller: 'BUNDLES_ITEM_FETCH_URL!',
    validator: () => true
  },
  DELETE: {
    url: `${BUNDLES_BULK_DELETE_URL}`,
    method: 'POST',
    caller: 'BUNDLES_BULK_DELETE_URL!',
    validator: ({ data: { ids } }) =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  },

  CREATE: {
    url: `${BUNDLES_BULK_CREATE_URL}`,
    method: 'POST',
    caller: 'BUNDLES_BULK_CREATE_URL!',
    validator: ({ data: { sku, marketplace_ids } }) =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  },
  UPDATE: {
    url: `${BUNDLES_ITEM_UPDATE_URL}`,
    method: 'POST',
    caller: 'API_BUNDLES_BULK BUNDLES_ITEM_UPDATE_URL!',
    validator: ({ data }) => true //limit && offset && search && searchFields
  },
  UPLOAD: {
    url: `${BUNDLES_ITEM_UPLOAD_URL}`,
    method: 'POST',
    caller: 'API_BUNDLES_BULK BUNDLES_ITEM_UPLODE_URL!',
    validator: ({ data }) => true //limit && offset && search && searchFields
  }
})
export const useUploadBundlesCmd = (
  bundles_file,
  responseHandler = (resp) => {
    console.log('useUploadBundlesCmd responseHandler', resp)
    return resp
  }
) => {
  console.log('useUploadBundlesCmd', bundles_file)
  const uploadBundlesCmd = useEntityCommand(
    0,
    {
      ...API_BUNDLES_BULK.UPLOAD,
      params: bundles_file
    },
    responseHandler
  )
  return uploadBundlesCmd
}

export const useUpdateBundleItemCmd = (
  id,
  { sku, salesChannelsIds },
  responseHandler = (resp) =>
    console.log('useUpdateBundleItemCmd responseHandler', resp)
) => {
  const updateBundleItemCmd = useEntityCommand(
    id,
    {
      ...API_BUNDLES_BULK.UPDATE,
      params: { data: { sku, marketplace_ids: salesChannelsIds } }
    },
    responseHandler
  )
  return updateBundleItemCmd
}

export const useDeleteBundleItemsCmd = (
  { ids },
  onSuccess = (r) => console.log('useDeleteBundleItemsCmd onSuccess', r),
  onFail = (r) => console.log('useDeleteBundleItemsCmd onFail', r)
) => {
  const deleteBundleItemsCmd = useEntityCommand(
    0,
    {
      ...API_BUNDLES_BULK.DELETE,
      params: { data: { ids } }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return deleteBundleItemsCmd
}

export const useCreateBundleItemCmd = (
  { sku, salesChannelsIds },
  onSuccess = (r) => console.log('useCreateBundleItemCmd onSuccess', r),
  onFail = (r) => console.log('useCreateBundleItemCmd onFail', r)
) => {
  const createBundleItemCmd = useEntityCommand(
    0,
    {
      ...API_BUNDLES_BULK.CREATE,
      params: { data: { sku, marketplace_ids: salesChannelsIds } }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return createBundleItemCmd
}

export const useFetchBundleItemCmd = (
  { id },
  responseHandler = (resp) =>
    console.log('useUpdateBundleItemCmd responseHandler!', resp)
) => {
  const fetchBundleItemCmd = useEntityCommand(
    id,
    {
      ...API_BUNDLES_BULK.FETCH_ITEM
    },
    responseHandler
  )
  return fetchBundleItemCmd
}
