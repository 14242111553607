import React, { useState } from 'react'
import {
  SnowTypography,
  SnowDivider
} from '../../../../../shared/components/snowComponents'
import {
  ScEditButton,
  ScSaveButton
} from '../../../../../shared/components/buttons'
import { updateOrderNote } from '../../../infrastructure/fetches'
import SnowEditableField from '../../../../../shared/components/SnowEditableField'
import ScStatusChip from '../../../../../shared/components/ScStatusChip'
import {
  SidebarPaperView,
  SidebarSubtitleView,
  SidebarBlockTitleView
} from './_styled'

function OrderNotes({ order: orderModel }) {
  const currentOrder = orderModel
  const [editMode, setEditMode] = useState(false)
  const [note, setNote] = useState(currentOrder.note)
  const [message, setMessage] = useState()
  const toggleEditMode = () => {
    setEditMode(!editMode)
  }

  const noteUpdate = ({ code, value }) => {
    setNote(value)
  }
  const noteSave = async () => {
    setEditMode(false)
    updateOrderNote({ orderID: currentOrder.id, note }).then((res) => {
      if (!res.success && res.message) {
        setMessage(res.message)
      }
    })
  }

  console.log('currentOrder2', currentOrder)

  return (
    <SidebarPaperView>
      <SidebarBlockTitleView>
        <SidebarSubtitleView>Sales Channel Status</SidebarSubtitleView>
        <ScStatusChip status={currentOrder.platformStatus} />
      </SidebarBlockTitleView>
      <SnowDivider />
      <SidebarBlockTitleView>
        <SidebarSubtitleView>Notes</SidebarSubtitleView>
        {!editMode ? (
          <ScEditButton
            size={30}
            aria-label="edit"
            onClick={() => toggleEditMode()}
          />
        ) : (
          <ScSaveButton size={30} aria-label="Save" onSave={() => noteSave()} />
        )}
      </SidebarBlockTitleView>
      <div>
        <SnowEditableField
          code="note"
          value={note}
          onUpdate={noteUpdate}
          edit={editMode}
          isGroupField={true}
          inputWidth={editMode ? 100 : 80}
          variant={'outlined'}
        />
        {message ? <p>{message}</p> : ''}
      </div>
    </SidebarPaperView>
  )
}
export default OrderNotes
