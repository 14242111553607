import React, { useState } from 'react'
import { ScConfirmationAlert } from '../../shared/components/modals'

const useCommandWithAlert = (cmdToRun, message) => {
  const [isOpen, setIsOpen] = useState(false)

  const onConfirm = () => {
    console.log('onConfirm', cmdToRun, message.title)

    if (typeof cmdToRun === 'function') cmdToRun()

    setIsOpen(false)

  }
  const { title = 'Warning!', content = 'Potentially dangerous action.' } =
    typeof message === 'string' ? { content: message } : message ?? {}

  const onCancel = () => setIsOpen(false)

  const cmdWithAlert = () => {
    setIsOpen(true)
  }
  const modal = () => (
    <ScConfirmationAlert
      isShowAttention={isOpen}
      onConfirm={onConfirm}
      onClose={onCancel}
      message={{ title, content }}
    />
  )
  console.log('cmdToRun', cmdToRun)

  return { cmdWithAlert, modal }
}
export default useCommandWithAlert