import React, { useState, Fragment } from 'react'
import { SnowLink } from '../../../../../../shared/components/snowComponents'
import CreateVendorOrderModal from '../../../Modals/CreateVendorOrderModal'

function CreateVendorOrderButton({ vendor, onCreateVendorOrder }) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <Fragment>
      <CreateVendorOrderModal
        open={open}
        onClose={handleClose}
        vendor={vendor}
        onCreateVendorOrder={onCreateVendorOrder}
      />
      <SnowLink
        component="button"
        variant="body2"
        color="secondary"
        onClick={handleOpen}
      >
        Set Vendor Order Number
      </SnowLink>
    </Fragment>
  )
}

export default CreateVendorOrderButton
