import { portalRequestor } from '../../../infrastructure/api/v1/requestors'
import fakePersonalizationsFetch from "../infrastructure/personalizationsFakeData"
export const FETCH_ITEMS_TO_MODERATE_URL =
  'personalizationModeration/grid/items'

export const fetchItemsToModerate = async (params) => {
  const { per_page, ...restParams } = params
  const url = FETCH_ITEMS_TO_MODERATE_URL
  const fetchParams = { ...restParams, per_page }

  // const resp = await fakePersonalizationsFetch({ page: 1, limit:24 })
  // return resp.json()

  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchItemsToModerate',
    fetchParams: fetchParams
  })
}
