import React, { Suspense, useState, useEffect } from 'react'
import { vendorWithId } from '../../../hooks/useVendors'
import { updateVendorScriptStatus } from '../../../infrastructure/v1/apis'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { SnowFormControlLabel, SnowFormGroup, SnowSwitch } from '../../../../../shared/components/snowComponents'
import { currentMessageState } from '../../../../../shared/service/Message/data/currentMessageState'
import ScSpinner from '../../../../../shared/components/ScSpinner/ScSpinner'
import { salesChannelIdSelector } from '../../../data/integrationDeterminant'
import {ScConfirmationAlert} from '../../../../../shared/components/modals'

export const VendorStatusInput = ({ id }) => {
  const loadedVendor = useRecoilValue(vendorWithId(id))
  const setLoadedVendor = useSetRecoilState(vendorWithId(id))
  const setNotification = useSetRecoilState(currentMessageState)
  const { reduced_script_status, name: vendorName } = loadedVendor ?? {}
  const channelId = useRecoilValue(salesChannelIdSelector)
  const [vendorScriptsStatus, setVendorScriptsStatus] = useState(
    reduced_script_status === '1'
  )
  const [showAlert, setShowAlert] = useState(false)

  // console.log(
  //   'loadedVendor',
  //   loadedVendor,
  //   vendorScriptsStatus,
  //   reduced_script_status
  // )

  const alertMessage = {
    toEnable: {
      title: 'Are you sure you want to enable this vendor?',
      content: 'This will start all scheduled processes from running.'
    },
    toDisable: {
      title: 'Are you sure you want to disable this vendor?',
      content: 'This will stop all scheduled processes from running.'
    }
  }

  const chooseAlertMessage = (isChecked) => {
    return isChecked ? alertMessage.toDisable : alertMessage.toEnable
  }

  const handleOpenAlert = () => {
    setShowAlert(true)
  }
  const handleCloseAlert = () => {
    setShowAlert(false)
  }
  const handleChangeVendorStatus = () => {
    updateVendorScriptsStatusCmd(!vendorScriptsStatus)
    handleCloseAlert()
    // console.log('checked', !vendorScriptsStatus)
  }

  const updateVendorScriptsStatusCmd = (status = false) => {
    if (typeof status !== 'boolean') return
    const statusToUpdate = status ? '1' : '0'
    const oldStatus = vendorScriptsStatus

    //do optimistic state update
    setVendorScriptsStatus((val) => !val)

    updateVendorScriptStatus({
      vendorId: id,
      channelId,
      status: statusToUpdate
    }).then((r) => {
      console.log('updateVendorScriptStatus response', r, loadedVendor)
      if (r.success === 'true') {
        setLoadedVendor((vendor) => {
          console.log('updateVendorScript Statusvendor', vendor)
          return {
            ...vendor,
            reduced_script_status: statusToUpdate
          }
        })
        const touchedScripts = r?.data?.reduced_script_list.join('", "')

        setNotification({
          severity: 'success',
          message: ` ${vendorName} scripts : "${touchedScripts}" has been ${status ? 'enabled' : 'disabled. '
          }`
        })
      } else {
        setVendorScriptsStatus(oldStatus)
        const errorReason = r.message && `The reason - ${r.message}.`
        setNotification({
          severity: 'error',
          message: `Something went wrong. Vendor ${vendorName} hasn't been ${status ? 'enabled' : 'disabled'
          }. ${errorReason}`
        })
      }
    })
  }

  useEffect(() => {
    if (!loadedVendor) return
    setVendorScriptsStatus(reduced_script_status === '1')
  }, [loadedVendor])

  const inputLabel = 'Enable Vendor'
  const isEnabled = reduced_script_status === '1'
  return (
    <SnowFormGroup style={{ flexDirection: 'row' }}>
      <Suspense fallback={ScSpinner}>
        <SnowFormControlLabel
          labelPlacement={'start'}
          control={
            <SnowSwitch checked={vendorScriptsStatus} onChange={handleOpenAlert}/>
          }
          label={`${inputLabel} `}
        />
        <ScConfirmationAlert
          isShowAttention={showAlert}
          onClose={handleCloseAlert}
          onConfirm={handleChangeVendorStatus}
          message={chooseAlertMessage(vendorScriptsStatus)}
        />
      </Suspense>
    </SnowFormGroup>
  )
}
