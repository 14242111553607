import { atomFamily, atom, selector, selectorFamily } from 'recoil'
import { apiDataRequestor } from '../../../infrastructure/api/v2/apiDataRequestor'
import {
  clearProviderAuth,
  clearSaUser,
  keepSaUser,
  SA_AUTH
} from './authInfra'
import jwt_decode from 'jwt-decode'
const fetchSaAuthorisation = selectorFamily({
  key: 'fetchSaAuthorisation',
  get:
    ({ providerToken, providerName }) =>
    async ({ get }) => {
      console.log('fetchSaAuthorisation get', providerToken)

      try {
        const data = await apiDataRequestor({
          ...SA_AUTH.LOGIN,
          params: {
            token: providerToken,
            provider: providerName
          }
        })
        console.log('fetchSaAuthorisation data', data)
        return data
      } catch (e) {
        console.log('Exception in apiDataRequestor:SA_AUTH.LOGIN')
        console.log(e)
        return { error: e, data: null }
      }
    }
  // set: ({ providerToken, providerName }) => ({ set }, newSaAuth) => {
  //   set(saUser, newSaAuth)
  // }
})

export const saUser = atomFamily({
  /*auth: {
      access_token: '',
      expires_at: 'google',
      token_type: 'bearer'
   }*/
  key: 'saUser',
  default: selectorFamily({
    key: 'saUser/fromSA',
    get:
      ({ providerName, providerToken }) =>
      ({ get }) => {
        // console.log('providerName, providerAuth ', providerName, providerToken)
        if (!providerToken) {
          console.log('no passed token provider to get sa auth. saUser cleared')
          clearSaUser()
          return { user: null }
        }

        const res = get(fetchSaAuthorisation({ providerName, providerToken }))
        const { data, error } = res
        console.log('saUser/fromSA', res)
        const { roles } = data
          ? new jwt_decode(data.access_token)
          : { roles: null }

        const user = { auth: data, profile: { roles } }
        error ? clearSaUser() : keepSaUser(user)
        return { error, user }
      }
  })
})
