import ScEditableCellRenderer from './ScEditableCellRenderer'
import React from 'react'

export const getColumnProps = ({
  keyField = 'id',
  fieldDescription,
  editedRows,
  headerRenderer,
  measuredRenderedItems,
  onRowUpdate,
  fontSize
}) => {
  const { code, title } = fieldDescription
  const [_w, _c] = measuredRenderedItems[code]
  const _colWidth = _w * 10
  return {
    label: title ?? code.replace('_', ' '),
    dataKey: code,
    width: _colWidth,
    columnData: fieldDescription,
    headerRenderer,
    cellRenderer: function cellRenderer ({ rowData, ...rest }) {
      // console.log('rowData, cellData', rowData, code, rowData[code])
      return (
        <ScEditableCellRenderer
          fieldDescription={fieldDescription}
          value={rowData[code]}
          fontSize={fontSize}
          edit={editedRows.includes(rowData[keyField])}
          onUpdate={({ code, value }) => {
            console.log('getColumnProps onUpdate code, value', { code, value })
            onRowUpdate(rowData[keyField], { code, value })
          }}
        />
      )
    }
  }
}
export default getColumnProps
