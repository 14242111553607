import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { SnowTypography } from '../../snowComponents'

const EntityHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.secondary};
  display: grid;
  grid-template-rows: repeat(
    ${({ countHeaderRows }) => countHeaderRows},
    min-content
  );
  grid-template-columns: 1fr;
  @media (min-width: 1172px) {
    grid-template-rows: repeat(
      ${({ countHeaderRows }) => countHeaderRows - 1},
      min-content
    );
    grid-template-columns: auto 1fr;
  }
  align-items: end;
  gap: 10px;
`

const EntityTitle = styled(SnowTypography).attrs({
  component: 'h2'
})`
  @media (min-width: 1172px) {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  text-transform: uppercase;
  &.MuiTypography-root {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.soBig};
    line-height: 1;
    vertical-align: bottom;
  }
  color: ${({ theme }) => theme.mainPalette.color.secondary} !important;
`

const EntityTitleExt = styled.div`
  @media (min-width: 1172px) {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .MuiTypography-root {
    font-weight: ${({ theme }) => theme.fontWeight.thin};
    line-height: 1;
    vertical-align: bottom;
  }
`

const EntitySubTitle = styled(SnowTypography).attrs({
  component: 'h3'
})`
  @media (min-width: 1172px) {
    grid-column: 1/3;
  }
  &.MuiTypography-root {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`

const EntityActions = styled.div`
  @media (min-width: 1172px) {
    grid-column: 1/3;
  }
`

const DetailsBody = styled.div`
  display: grid;
  gap: 16px;
  grid-template-rows: min-content;
  grid-template-columns: 3fr;
  @media (min-width: 1172px) {
    grid-template-rows: min-content min-content;
    grid-template-columns: 3fr auto;
  }
`

const EntityContent = styled.div`
  grid-row: 1/2;
  ${({ hasWidgets }) =>
    hasWidgets
      ? css`
          grid-column: 1/2;
        `
      : css`
          grid-column: 1/3;
        `}
`

const EntityLayoutSidebar = styled.div`
  grid-row: 2/3;
  grid-column: 1/2;
  @media (min-width: 1172px) {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  ${({ hasWidgets, sideBarWidth }) =>
    hasWidgets
      ? `
        width: 100%;
        @media (min-width: 1172px){
          width: ${sideBarWidth}px;
        }
        `
      : `width: 0;`}
`

const PageLinks = ({ pageLinks }) => {
  return pageLinks
}
const DetailsHeader = ({ children }) => {
  return children
}

const ScEntityDetailsPage = ({
  entityId,
  title = '', //Entity title
  pageLinks = '', //breadcrumbs, links, special links
  titleExt = '', //clarification info
  subtitle = '', //Statuses chips, tags and etc might be here
  actionsContent = '', //Buttons to act
  sidebarWidgets = '', //tabs tabs tabs tabs
  sideBarWidth = 300,
  entityContent = '' //entity info detail
}) => {
  const hasWidgets = sidebarWidgets ? 1 : 0
  const hasTitleExt = titleExt ? true : false
  const headerData = [
    { type: 'title', value: title || '' },
    { type: 'titleExt', value: titleExt || '' },
    { type: 'subtitle', value: subtitle || '' },
    { type: 'actionsContent', value: actionsContent || '' }
  ]
  const countHeaderRows = headerData.filter(({ value }) => value !== '')?.length
  console.log('ScEntityDetailsPage', countHeaderRows)

  return (
    <Fragment>
      <DetailsHeader countHeaderRows={countHeaderRows}>
        <PageLinks pageLinks={pageLinks} />
        <EntityHeader>
          <>
            {title ? <EntityTitle>{title}</EntityTitle> : null}
            {titleExt ? (
              <EntityTitleExt>
                <SnowTypography>{titleExt}</SnowTypography>
              </EntityTitleExt>
            ) : null}
          </>
          {subtitle ? <EntitySubTitle>{subtitle}</EntitySubTitle> : null}
          {actionsContent ? (
            <EntityActions>{actionsContent}</EntityActions>
          ) : null}
        </EntityHeader>
      </DetailsHeader>
      <DetailsBody>
        {entityContent ? (
          <EntityContent hasWidgets={hasWidgets}>{entityContent}</EntityContent>
        ) : null}
        {sidebarWidgets ? (
          <EntityLayoutSidebar
            hasWidgets={hasWidgets}
            sideBarWidth={sideBarWidth}
          >
            {sidebarWidgets}
          </EntityLayoutSidebar>
        ) : null}
      </DetailsBody>
    </Fragment>
  )
}

export default ScEntityDetailsPage
