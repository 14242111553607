import React, { useContext } from 'react'
import { useSetRecoilState } from 'recoil'
import SetupPersonalization from './SetupPersonalization'
import InfoModalBtn from './InfoModalBtn/InfoModalBtn'
import { useResendOrderItemPersonalizationCmd } from '../../../../../../../infrastructure/orderItemApi'
import { currentMessageState } from '../../../../../../../../../shared/service/Message/data/currentMessageState'
import { SnowGrid } from '../../../../../../../../../shared/components/snowComponents'
import { ScIconButton } from '../../../../../../../../../shared/components/buttons'
import { SnowRefreshIcon } from '../../../../../../../../../shared/components/icons'
import { ThemeContext } from 'styled-components'

function PersonalizationActions({
  item,
  handlePersSetup,
  personalization,
  persHydrator
}) {
  const theme = useContext(ThemeContext)
  const setMessage = useSetRecoilState(currentMessageState)
  const currentUrl = personalization?.preview.image_url || ''
  console.log('PersonalizationActions item', item)
  const resendPersonalizationCmd = useResendOrderItemPersonalizationCmd(
    { id: item.id },
    { type: personalization.type, personalizer: personalization.personalizer },
    (r) => {
      const message = `Personalizations for ${item.name} has been resent ${r.data?.troubles ? 'with errors:' : 'successfully'
        }`
      const subtitle = r.data?.troubles ?? ''

      setMessage({
        message: message,
        severity: subtitle ? 'error' : 'success',
        subtitle: subtitle.map(({ message }) => message)?.join(';')
      })
    },
    (r) =>
      setMessage({
        message: `Resend failed`,
        severity: 'error'
      })
  )
  return (
    <>
      <SnowGrid item>
        <SetupPersonalization
          orderItem={item}
          persUrl={currentUrl}
          onSetup={handlePersSetup}
        />
      </SnowGrid>
      <SnowGrid item>
        <InfoModalBtn jsonData={personalization} />
        <ScIconButton
          size={30}
          color={theme.mainPalette.color.secondary}
          onClick={() =>
            resendPersonalizationCmd((resp) =>
              console.log('resendPersonalizationCmd resp', resp)
            )
          }
        >
          <SnowRefreshIcon fontSize={'small'} />
        </ScIconButton>
      </SnowGrid>
    </>
  )
}

export default PersonalizationActions
