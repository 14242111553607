import React, { useRef } from 'react'
import { SnowAutocomplete, SnowTextField } from '../../../snowComponents'
import { transformOptions } from '../../../../../utils/transformOptions'
import { SnowCancelIcon } from '../../../icons'
import { ScFieldLabel } from '../../../LayoutComponents'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const AutocompleteView = styled.div`
  width: 100%;
  .MuiAutocomplete-popper {
    margin: 0;
    padding: 0;
  }
  ul {
    position: absolute;
  }
`

const StyledTextField = styled(SnowTextField)`
  width: 100%;
  fieldset {
    display: none;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']
    .MuiAutocomplete-input:first-child {
    padding: 10px ${({ isbuttonexist }) => (isbuttonexist ? '30px' : '10px')}
      10px 10px;
    box-sizing: border-box;
    min-height: 40px;
    border-bottom: ${({ border }) => border};
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding-right: 0 !important;
    .MuiAutocomplete-endAdornment {
      right: 2px;
    }
  }
  .MuiAutocomplete-clearIndicator {
  }
`

const isSimpleType = (value) => ['string', 'number'].includes(typeof value)

export const ScComboField = ({
  onUpdate = (evt) => console.log(evt),
  value: _value = '',
  code = '',
  label = '',
  inputWidth = 100,
  labelPlacement = 'top',
  fieldFontProps = {},
  labelFontProps = {},
  variant = 'outlined',
  isGroupField = false,
  isInvalid = false,
  mb = 0,
  freeSolo = true, //freeSolo property of Autocomplete in material ui
  updateDelay = 500,
  tooltipNewValue = true,
  disableIndicatorNewValue = false,
  disableClearable = true,
  closeIcon,
  onClear = () => { },
  meta = { options: {}, optionsDef: {} },
  ...props
}) => {
  const value = String(_value)
  const comboValue = useRef({ value: '', label: '' })
  const inputValue = useRef('')

  const castedAutoOptions = transformOptions(meta)

  const getIsNewValue = (val) =>
    Boolean(
      (val || props.required) &&
      !castedAutoOptions.find(
        ({ value: optionVal }) => String(optionVal) === String(val)
      )
    )

  // const [isNew, setIsNew]=useState(getIsNewValue(value))

  const getOptionValueByLabel = (comboText) => {
    return (
      castedAutoOptions.find(({ value, label }) => comboText === label)
        ?.value ?? comboText
    )
  }

  const getOptionLabelByValue = (comboValue) => {
    return (
      castedAutoOptions.find(
        ({ value, label }) => String(value) === String(comboValue)
      )?.label ?? comboValue
    )
  }

  const handleClose = () => {
    const comboVal = getOptionValueByLabel(comboValue?.current?.label) || ''
    const inputVal = inputValue.current

    console.log(
      'ScComboField!! onClose rezult1',
      getIsNewValue(inputVal || comboVal)
    )
    console.log(
      'ScComboField!! onClose rezult2',
      comboVal,
      ', ',
      inputVal,
      ', ',
      inputVal || comboVal,
      ',',
      comboValue.current,
      ', ',
      inputValue.current,
      ', '
    )
    const isValid = freeSolo || !getIsNewValue(inputVal || comboVal)

    if (value === (inputVal || comboVal)) return

    if (!freeSolo && getIsNewValue(inputVal || comboVal)) return
    onUpdate({
      value: inputVal || comboVal || '',
      code,
      isValid,
      label: comboValue?.current?.label || ''
    })
  }
  console.log(`ScComboField!!${code} render`, {
    value,
    isInvalid,
    required: props.required,
    getIsNewValue: getIsNewValue(value),
    meta,
    inputValue,
    comboValueCur: comboValue.current,
    castedAutoOptions
  })

  const getBorderStyle = (value) => {
    const isNew = getIsNewValue(value)
    // console.log('getBorderStyle', value, isNew, castedAutoOptions,)
    if (disableIndicatorNewValue) return 'none'
    if (props.required) return isNew ? '2px solid orange' : 'none'
    return isNew && value !== '' ? '2px solid orange' : 'none'
  }

  const getTooltip = (isNewValue) => {
    const tooltipForNewValue = tooltipNewValue
      ? "Value doesn't match to existing values."
      : ''
    return isNewValue
      ? tooltipForNewValue
      : props?.tooltip || meta?.tooltip || 'Type or choose option'
  }

  return (
    <ScFieldLabel
      label={label}
      isGroupField={isGroupField}
      inputWidth={inputWidth}
      labelPlacement={labelPlacement}
      isInvalid={isInvalid}
      fieldFontProps={fieldFontProps}
      labelFontProps={labelFontProps}
      code={code}
      required={props.required}
      tooltipText={
        getTooltip(getIsNewValue(value)) ? getTooltip(getIsNewValue(value)) : ''
      }
    >
      <AutocompleteView>
        <SnowAutocomplete
          disablePortal={false}
          freeSolo={freeSolo}
          id={code}
          options={castedAutoOptions}
          autoComplete={true}
          disabled={props.disabled}
          disableClearable={disableClearable}
          placeholder={props.placeholder}
          ListboxComponent={'ul'}
          multiple={false}
          ListboxProps={{
            style: {
              background: 'white',
              fontWeight: '300',
              border: '1px solid lightgrey',
              borderRadius: '4px',
              overflowY: 'scroll',
              boxShadow:
                '0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)'
            }
          }}
          forcePopupIcon={false}
          clearIcon={
            closeIcon ? (closeIcon) : (
              <SnowCancelIcon
                size={'small'}
                style={{ width: '20px', height: '20px' }}
              />
            )
          }
          getOptionLabel={(option) => {
            // console.log('ScComboField getOptionLabel', option, isSimpleType(option))
            return isSimpleType(option)
              ? String(option)
              : String(option.label ?? '')
          }}
          isOptionEqualToValue={(option, _value) => {
            // console.log('ScComboField!! getOptionSelected < ', option, ',', _value, ' >')
            if (typeof _value === 'undefined') return null
            if (isSimpleType(_value)) return option?.label === _value
            return option?.label === _value?.label
          }}
          selectOnFocus
          clearOnBlur={false}
          handleHomeEndKeys
          value={getOptionLabelByValue(value) || ''}
          onChange={(evt, selectedOption, reason) => {
            console.log(
              'ScComboField!! onChange',
              evt,
              evt.target.innerHTML,
              selectedOption,
              reason,
              typeof selectedOption
            )
            comboValue.current = isSimpleType(selectedOption)
              ? { label: selectedOption, id: selectedOption }
              : selectedOption
            if (reason === 'clear') {
              onClear()
            }
            inputValue.current = ''
          }}
          onInputChange={(evt, textVal, reason) => {
            console.log('ScComboField!! onInputChange', evt, reason)
            // if(reason==='reset') {onClear()}
            comboValue.current = {
              value: getOptionValueByLabel(textVal),
              label: textVal
            }
          }}
          onClose={handleClose}
          renderInput={(params) => {
            // console.log('ScComboField!! renderInput', params)
            return (
              <StyledTextField
                {...params}
                mb={mb}
                border={getBorderStyle(value)}
                variant={variant}
                id={code}
                error={isInvalid}
                isbuttonexist={disableClearable ? 0 : 1}
                data-testid="combobox-text"
                value={comboValue.current?.label}
                InputProps={{
                  ...params.InputProps,
                  type: 'search'
                }}
                disabled={props.disabled}
                placeholder={props.placeholder}
                onChange={(evt, val) => {
                  const { value } = evt.target
                  console.log(
                    'ScComboField! Combo TextField renderInput onChange comboValue, targetValue, val',
                    comboValue.current,
                    value,
                    evt
                  )
                  inputValue.current = value
                }}
              />
            )
          }}
        />
      </AutocompleteView>
    </ScFieldLabel>
  )
}

export default ScComboField

ScComboField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.string,
  label: PropTypes.string,
  isInvalid: PropTypes.bool,
  labelPlacement: PropTypes.oneOf(['top', 'top-big', 'start', 'end', '']),
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isGroupField: PropTypes.bool,
  mb: PropTypes.number,
  updateDelay: PropTypes.number,
  meta: PropTypes.object,
  freeSolo: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onUpdate: PropTypes.func
}
