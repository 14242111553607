import React from 'react'
import useSnowLoggerSetup, {
  LogLevel
} from '../../../shared/service/helpers/SnowLogger'

const MainWrapper = (props) => {
  useSnowLoggerSetup({
    uxScope: 'APP',
    level: LogLevel.ERROR,
    color: ['blue', 'yellow'],
    devOnly: true
  })
  return (
    <div >
      <div aria-label="wrapper">{props.children}</div>
    </div>
  )
}

export default MainWrapper
