import React, { useContext } from 'react'
import { SnowSaveIcon } from '../../icons'
import { ScIconButton } from '../../../components/buttons'
import { ThemeContext } from 'styled-components'

const ScSaveButton = ({ onSave, status = 'success', ...props }) => {
  const theme = useContext(ThemeContext)
  return (
    <ScIconButton
      aria-label="Save"
      color={
        status === 'success'
          ? theme.mainPalette.status.success
          : theme.mainPalette.status.error
      }
      onClick={(evt) => {
        evt.stopPropagation()
        onSave()
      }}
      {...props}
      className={'ScSaveButton'}
    >
      <SnowSaveIcon {...props} />
    </ScIconButton>
  )
}

export default ScSaveButton
