import React, { Fragment } from 'react'
import { SnowList } from '../../../../shared/components/snowComponents'
import SidebarLink from './SidebarLink'
import sidebarLinks from './sidebarLinks'
import VersionBlock from '../widgets/VersionBlock'
import styled from 'styled-components'

const SidebarView = styled.div`
  height: ${({ height }) => (height ? `${height}px` : 0)};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const SidebarListView = styled(SnowList)`
  padding: 15px 0;
  flex: 1 1 auto;
`

const SidebarContent = ({
  miniDrawerWidth,
  drawerWidth,
  isOpen,
  height = 0
}) => {
  return (
    <SidebarView height={height}>
      <SidebarListView>
        {sidebarLinks.map((link, index) => (
          <Fragment key={index}>
            <SidebarLink
              title={link.name}
              icon="exit"
              route={link.route ? link.route : ''}
              iconItem={link.icon}
              miniDrawerWidth={miniDrawerWidth}
              drawerWidth={drawerWidth}
              isOpen={isOpen}
              submenuContent={link.submenu?.length ? link.submenu : []}
            />
          </Fragment>
        ))}
      </SidebarListView>
      <VersionBlock />
    </SidebarView>
  )
}

export default SidebarContent
