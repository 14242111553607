import { useEffect, useCallback, useRef } from 'react'
import { fetchProducts } from './fetches'

// make API calls and pass the returned data via dispatch
export const useFetchProducts = ({ data, search, productsDispatch }) => {
  const oldSearch = useRef(search)
  const isLoading = useRef(0)

  const getProducts = async ({ page, search, productsDispatch }) => {
    console.log('getProducts oldSearch,search', oldSearch.current, search)
    const { data: products, meta: meta } = await fetchProducts({
      page: page,
      search: search,
      sort: 'desc'
    })
    if (oldSearch.current !== search)
      productsDispatch({ type: 'RESET_PRODUCTS', products })
    else productsDispatch({ type: 'STACK_PRODUCTS', products })

    isLoading.current = isLoading.current - 1
    productsDispatch({ type: 'FETCHING_DATA', fetching: isLoading.current })

    oldSearch.current = search

    return products
  }

  useEffect(
    () => {
      console.log('gonna fetch', data.page, search)
      productsDispatch({ type: 'FETCHING_DATA', fetching: true })
      isLoading.current = isLoading.current + 1
      const products = getProducts({
        page: data.page,
        search: search,
        productsDispatch: productsDispatch
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productsDispatch, data.page, search]
  )

  useEffect(() => {
    productsDispatch({ type: 'SET_COLUMNS' })
  }, [productsDispatch])
}

// infinite scrolling with intersection observer
export const useInfiniteScroll = (scrollRef, dispatch) => {
  const scrollObserver = useCallback(
    (node) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.intersectionRatio > 0) {
            dispatch({ type: 'ADVANCE_PAGE' })
          }
        })
      }).observe(node)
    },
    [dispatch]
  )

  useEffect(() => {
    if (scrollRef.current) {
      scrollObserver(scrollRef.current)
    }
  }, [scrollObserver, scrollRef])
}
