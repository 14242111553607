import { SnowTypography } from '../../../snowComponents'
import styled, { css } from 'styled-components'

const SidebarSubtitleView = styled(SnowTypography).attrs({
  component: 'h3',
  variant: 'body1',
})`
  display: inline-block;
  &.MuiTypography-root {
    ${({ theme }) => css`
      text-transform: uppercase;
      display: ${({ display = 'block' }) => display};
      margin-bottom: 1rem;
      font-size: ${theme.fontSize.small};
      font-weight: ${theme.fontWeight.bold};
    `}
  }
`

export default SidebarSubtitleView
