import { useEffect, useState } from 'react'
import { atom, atomFamily, useSetRecoilState, useRecoilState } from 'recoil'
import { useOrderItemSaveCmd } from '../../../../infrastructure/orderItemApi'
import {
  getObjValueByPath,
  getFieldsPathArray
} from '../../../../../../utils/helpers'
import _ from 'lodash'
import { currentMessageState } from '../../../../../../shared/service/Message/data/currentMessageState'

const checkFieldEditable = (fieldCode, checkedConfigObj) => {
  console.log('checkFieldEditable 1', fieldCode, checkedConfigObj)

  //check against null or not object
  if (typeof checkedConfigObj !== 'object' || !checkedConfigObj) return false

  const checkedaArrayItems =
    Array.isArray(checkedConfigObj) &&
    checkedConfigObj.reduce((acc, item) => {
      console.log(
        'checkFieldEditable 2 checkedSubArrays map',
        item,
        checkedConfigObj
      )
      return acc || checkFieldEditable(fieldCode, item)
    }, false)

  console.log('checkFieldEditable 3 checkedaArrayItems', checkedaArrayItems)

  const { id, label, type } = checkedConfigObj

  if (id && label) return fieldCode === id

  // const arrayFields = Object.values(checkedConfigObj).filter((value) =>
  //   Array.isArray(value)
  // )

  const checkedSubObjects = Object.values(checkedConfigObj)
    .filter((item) => item && typeof item === 'object')
    .reduce((acc, item) => acc || checkFieldEditable(fieldCode, item), false)

  console.log('checkFieldEditable 4 checkedSubObjects', checkedSubObjects)

  return checkedSubObjects
  // if (typeof findedField !== 'object') return false
}
const orderItemsIdsAtom = atom({
  key: 'orderItemsIdsAtom',
  default: []
})

const orderItemRepoFamily = atomFamily({
  key: 'orderItemRepoFamily',
  default: (orderItemId) => {}
})

export const useOrderLineItem = (item) => {
  const { id } = item
  const [repo, setRepo] = useRecoilState(orderItemRepoFamily(id))

  const { moderation_options, editable_fields, ...rest } = item.meta
  const editablePaths = getFieldsPathArray(editable_fields) ?? []

  const [changedData, setChangedData] = useState({})

  const isPathEditable = (fieldPath) => editablePaths?.includes(fieldPath)
  const getEditablePathField = (fieldPath) =>
    getObjValueByPath(fieldPath, editable_fields)

  const setMessage = useSetRecoilState(currentMessageState)

  const [objToSave, setObjToSave] = useState({})

  const saveCmd = useOrderItemSaveCmd(
    id,
    { data: objToSave },
    (r) => {
      console.log('useOrderLineItem saveCmd r', r)
      setChangedData({})
      setMessage({
        severity: 'success',
        message: 'Order Item was successfully updated'
      })
    },
    (r) => {
      console.log('useOrderLineItem saveCmd r', r)
      setChangedData({})
      setMessage({
        severity: 'error',
        message:
          'Something went wrong and Order Item was not successfully updated'
      })
    }
  )

  const updateField = ({ code, value }) => {
    setChangedData((prev) => ({ ...prev, [code]: value }))
    console.log('useOrderLineItem updateField', code, value)
  }

  useEffect(() => {
    console.log(
      'useOrderLineItem useEffect objToSave',
      objToSave,
      !!Object.keys(changedData)?.length
    )

    setRepo((prev) => ({
      update: updateField,
      save: saveCmd,
      changed: !!Object.keys(changedData)?.length
    }))
  }, [objToSave])

  useEffect(() => {
    // console.log('useOrderLineItem useEffect changedData', changedData)
    const obj = {}
    Object.entries(changedData).map(([key, value]) =>
      _.update(obj, key, _.constant(value))
    )
    setObjToSave(obj)
  }, [changedData])

  return {
    id,
    data: item,
    ux: {
      isPathEditable,
      getEditablePathField
    },
    repo
  }
}
export default useOrderLineItem
