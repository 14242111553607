import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  currentConfigGroupLoadableFieldsSelector
} from '../../../../data/integrationModel'
import {
  integrationEntityType,
  salesChannelIdSelector
} from '../../../../data/integrationDeterminant'
import { SnowExpandMoreIcon } from '../../../../../../shared/components/icons'
import {
  SnowList,
  SnowTypography,
  SnowAccordion,
  SnowAccordionSummary,
  SnowAccordionDetails
} from '../../../../../../shared/components/snowComponents'
import ScSpinner from '../../../../../../shared/components/ScSpinner/ScSpinner'
import { useParams } from 'react-router-dom'
import { fetchConfigurationGroupItems } from '../../../../infrastructure/v1/apis'
import ConfigGroupFieldsList from './ConfigGroupFieldsList'
import { loadedFields } from '../../../../data/integrationModel'
import styled from 'styled-components'

const AccordionView = styled(SnowAccordion)`
  width: 100%;
`

const AccordionDetailsView = styled(SnowAccordionDetails)`
  align-items: center;
`

const AccordionContentView = styled.div`
  background: ${({ theme }) => theme.mainPalette.grey[50]};
`

const StyledList = styled(SnowList)`
  width: 100%;
`

const SubgroupsDetails = ({
  subGroups,
  handleError,
  handleUpdate,
  groupId,
  configId,
  configType
}) => {
  if (Array.isArray(subGroups) && subGroups.length) {
    return (
      <AccordionDetailsView aria-label={`SubgroupsDetails-${groupId}`}>
        <StyledList>
          {subGroups.map((subGroup) => (
            <ConfigGroup
              key={subGroup.group_id}
              groupConfig={{
                config_entity_type: configType,
                config_entity_id: configId,
                ...subGroup
              }}
              updateValue={handleUpdate}
              onError={handleError}
            />
          ))}
        </StyledList>
      </AccordionDetailsView>
    )
  }
  return null
}

const ConfigGroup = ({
  groupConfig,
  // actualValues,
  updateValue,
  onError
}) => {
  const [fieldsLoading, setFieldsLoading] = useState(false)
  const {
    group_id: groupId,
    config_entity_type: configType,
    config_entity_id: configId,
    title,
    fields: presetFields,
    groups: subGroups
  } = groupConfig
  const [isLoading, setIsLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const { domainId: integrationEntityId } = useParams()
  const entityType = useRecoilValue(integrationEntityType)
  const salesChannelId = useRecoilValue(salesChannelIdSelector)
  const [loadableFields, setLoadableFields] = useRecoilState(
    currentConfigGroupLoadableFieldsSelector(groupId)
  )
  const setLoadedFields = useSetRecoilState(loadedFields)

  const handleUpdate = ({ code, value }) => {
    console.log('ConfigGroup handleUpdate', code, value, groupConfig)
    updateValue({ code, value })
  }
  const handleError = (error) => {
    console.log('ConfigGroup handleError', error)
    onError(error)
  }
  const loadGroupFields = async () => {
    console.log('ConfigGroup loadGroupFields loadableFields', {
      groupId,
      loadableFields
    })

    if (loadableFields.fields) {
      setLoadableFields(loadableFields)
      return
    }

    setFieldsLoading(true)

    const { fields, values, ...serviceInfo } =
      await fetchConfigurationGroupItems({
        type: entityType,
        entityId: integrationEntityId,
        configType,
        configId,
        groupId,
        salesChannelId
      })
    setIsLoading(false)
    console.log('loadConfigurationGroupItems fields, values ', fields, values)
    setFieldsLoading(false)
    fields && values && setLoadableFields({ fields, values })
  }
  const toggleGroup = () => {
    setExpanded((open) => !open)
    !expanded && loadGroupFields()
  }
  useEffect(() => {
    console.log(`${groupId} ConfigGroup constructed`)
    return () => console.log(`${groupId} ConfigGroup destructed`)
  }, [])
  useEffect(() => {
    console.log(`ConfigGroup groupId`, groupId)
  }, [groupId])

  useEffect(() => {
    console.log(`ConfigGroup salesChannelId`, salesChannelId)
    setExpanded(false)
  }, [salesChannelId])

  useEffect(() => {
    console.log(`${title} useEffect loadableFields loaded`, {
      groupConfig,
      loadableFields
    })
    setLoadedFields(loadableFields)
  }, [loadableFields])
  console.log('groupConfig', groupConfig)

  return (
    <AccordionView
      key={groupId}
      aria-label={`ConfigGroup-${groupId}`}
      expanded={expanded}
      onChange={(e, wantToExpand) => toggleGroup()}
    >
      <SnowAccordionSummary
        expandIcon={<SnowExpandMoreIcon />}
        aria-controls={title + '-content'}
        id={groupId + '-header'}
      >
        <SnowTypography varaint={'inherit'} component={'h3'}>
          {title}
        </SnowTypography>
      </SnowAccordionSummary>
      <AccordionContentView aria-label={'ConfigGroup-detail'}>
        {!!presetFields?.length && (
          <ConfigGroupFieldsList
            groupCategory={`PresetFieldsDetails-${groupId}`}
            fields={presetFields}
            onError={handleError}
            onUpdate={handleUpdate}
          />
        )}
        {fieldsLoading && <ScSpinner />}

        {!!loadableFields?.fields?.length && (
          <ConfigGroupFieldsList
            groupCategory={`LoadableFieldsDetails-${groupId}`}
            fields={loadableFields.fields}
            onError={handleError}
            onUpdate={handleUpdate}
          />
        )}
        <SubgroupsDetails
          groupId={groupId}
          configId={configId}
          configType={configType}
          subGroups={subGroups}
          handleError={handleError}
          handleUpdate={handleUpdate}
        />
      </AccordionContentView>
    </AccordionView>
  )
}
export default ConfigGroup
