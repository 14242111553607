import React from 'react'
import { SnowCheckbox } from '../../../snowComponents'
import { useBulkSelection } from '../../hooks/useBulkSelection'

const BulkSelector = ({
  gridItemIds,
  label = 'Select All',
  preselected = [],
  isEditable,
  forceSelect
}) => {
  const { selectTrippleState, updateBulkSelection } = useBulkSelection({
    itemIds: gridItemIds,
    preselectedIds: preselected,
    forceSelect
  })
  console.log('BulkSelector redraw', gridItemIds, preselected)
  return (
    <>
      {label}
      <SnowCheckbox
        indeterminate={selectTrippleState === 1}
        checked={selectTrippleState === 2}
        onChange={updateBulkSelection}
      />
    </>
  )
}
export default BulkSelector
