import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { useEffect, useState } from 'react'
import { useScMessage } from '../../../shared/service/Message/data/currentMessageState'

const API_VENDOR_ORDER = objWithParent({
  url: '/vendorOrders',
  FETCH_ACTIONS: {
    url: 'actions',
    method: 'GET',
    caller: 'FETCH VENDOR ORDER ACTION!',
    validator: () => true
  },
  CANCEL_VENDOR_ORDER: {
    url: 'action/cancel',
    method: 'POST',
    caller: 'CANCEL VENDOR ORDER!',
    validator: () => true
  },
  DO_VENDOR_ORDER_ACTION: {
    url: 'action',
    method: 'POST',
    caller: 'DO_VENDOR_ORDER_ACTION!',
    validator: () => true
  }
})
export const useVendorOrderApi = (vendorOrderId) => {
  const [availableActions, setAvailableActions] = useState([])
  const [vendorOrderAction, setVendorOrderAction] = useState('')
  const { send: sendMessage } = useScMessage()
  const [actionIsRunning, setActionIsRunning] = useState(false)
  const actionCmdUrl = `${API_VENDOR_ORDER.DO_VENDOR_ORDER_ACTION.url}/${vendorOrderAction}`
  const fetchAvailableActionsCmd = useEntityCommand(
    vendorOrderId,
    API_VENDOR_ORDER.FETCH_ACTIONS,
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('fetchAvailableActionsCmd success', r)
          setAvailableActions(r)
        },
        (r) => console.log('fetchAvailableActionsCmd fail', r)
      )
  )
  const applyActionCmd = useEntityCommand(
    vendorOrderId,
    {
      ...API_VENDOR_ORDER.DO_VENDOR_ORDER_ACTION,
      url: actionCmdUrl
    },
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('applyActionCmd success', vendorOrderAction, r)
          // setAvailableActions(r)
        },
        (r) => console.log('applyActionCmd fail', vendorOrderAction, r)
      )
  )
  const vendorOrderCancelCmd = useEntityCommand(
    vendorOrderId,
    API_VENDOR_ORDER.CANCEL_VENDOR_ORDER,
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('vendorOrderCancelCmd success', r)
          // setAvailableActions(r)
        },
        (r) => console.log('vendorOrderCancelCmd fail', r)
      )
  )

  const cancelVendorOrder = (
    callback = (r) => console.log('cancelVendorOrder callback', r)
  ) => {
    console.log('cancelVendorOrder', callback, typeof callback)
    typeof callback === 'function' && vendorOrderCancelCmd(callback)
  }

  const runVendorOrderAction = (action) => {
    if (!action || actionIsRunning) return
    setVendorOrderAction(action)
  }

  useEffect(() => {
    if (!vendorOrderId) return

    fetchAvailableActionsCmd((r) =>
      console.log('fetchAvailableActionsCmd response', r)
    )
  }, [vendorOrderId])
  useEffect(() => {
    if (!vendorOrderAction || actionIsRunning) return
    setActionIsRunning(true)
    setVendorOrderAction('')
    applyActionCmd().then((r) => {
      setActionIsRunning(false)
      if (!r.error && r?.data?.success === 'true' && r?.data?.message) {
        sendMessage(r.data.message, 'success')
      }
      if (r.error || r?.data?.success !== 'true') {
        sendMessage('something went wrong', 'error')
      }
    })
  }, [vendorOrderAction])

  return { availableActions, cancelVendorOrder, runVendorOrderAction }
}
