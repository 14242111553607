import objWithParent from '../../../../utils/parenter'
import { useEntityCommand } from '../../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../../infrastructure/api/utils'
const CHANNELS_FETCH_URL = 'marketplaces'
const CHANNELS_ADD_URL = 'marketplaces/add'

const API_VENDORS = objWithParent({
  url: '/vendors',
  FETCH_VENDOR__CHANNELS: {
    version: 'v2',
    url: `${CHANNELS_FETCH_URL}`,
    method: 'GET',
    caller: 'CHANNELS_FETCH_URL!',
    validator: () => true
  },
  ADD_VENDOR_CHANNELS: {
    version: 'v2',
    url: `${CHANNELS_ADD_URL}`,
    method: 'POST',
    caller: 'CHANNELS_ADD_URL!',
    validator: ({ marketplace_id }) => true
  }
})

export const useFetchVendorChannelsCmd = (
  id,
  responseHandler = (resp) =>
    console.log('useFetchVendorChannelsCmd responseHandler!', resp)
) => {
  const fetchVendorChannelsCmd = useEntityCommand(
    id,
    {
      ...API_VENDORS.FETCH_VENDOR__CHANNELS
    },
    responseHandler
  )
  return fetchVendorChannelsCmd
}

export const useAddVendorChannelsCmd = (
  id,
  channelId,
  responseHandler = (resp) =>
    console.log('useUpdateBundleItemCmd responseHandler!', resp)
) => {
  const addVendorChannelsCmd = useEntityCommand(
    id,
    {
      ...API_VENDORS.ADD_VENDOR_CHANNELS,
      params: { marketplace_id: channelId }
    },
    responseHandler
  )
  return addVendorChannelsCmd
}
