import React from 'react'
import { ScWhiteButton } from '../../buttons'
import AutorenewIcon from 'mdi-react/AutorenewIcon'

const ScResyncTextButton = ({
  onClick = () => console.log('ScResyncBtn clicked')
}) => (
  <ScWhiteButton
    onClick={onClick}
    icon={() => <AutorenewIcon />}
    label={'Resync'}
  />
)

export default ScResyncTextButton
