import React, { useEffect, useRef, useState } from 'react'
import {
  SnowDivider,
  SnowList,
  SnowListItem,
  SnowPaper
} from '../../../snowComponents'
import { ScTextField } from '../../baseFields'
import { ScFlatValuesMapField } from '../../groupingFields'
import { ScAddListItemButton } from '../../../buttons'
import { ScListLabel } from '../../../LayoutComponents'
import { pairsToObj, replaceArrayItem } from '../../../../../utils/helpers'
import { useMapType } from '../../hooks/useMapType'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const NestedMapPaperView = styled(SnowPaper)`
  width: 100%;
  .MuiPaper-root {
    min-width: 100%;
  }
`

const NestedMapListView = styled(SnowList)`
  width: 100%;
`

const NestedMapListItemView = styled(SnowListItem)`
  box-sizing: border-box;
  &.MuiListItem-root {
    min-width: 100%;
    display: grid;
    align-items: start;
    grid-template-rows: min-content;
    grid-template-columns: 30% 1fr;
  }
`

const NestedMapKeyFieldView = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing.secondary};
    padding-right: ${theme.spacing.secondary};
  `}
  grid-row: 1/2;
  grid-column: 1/2;
`

const NestedMapValueFieldView = styled.div`
  grid-row: 1/2;
  grid-column: 2/3;
`

const ScNestedValuesMapField = ({
  fixedKeys = false,
  label = '',
  labelPlacement = 'top',
  inputWidth = '100',
  isGroupField = false,
  code = '',
  value = { '': { '': '' } },
  required = false,
  readonlyKeys = false,
  onUpdate = (evt) => console.log('Map changes', evt),
  updateDelay = 500,
  variant = 'outlined',
  onError = (evt) => console.log('Error', evt),
  meta = {},
  alertOnNewValue = true,
  ...props
}) => {
  const mapObject = value ?? {}
  const originMapArray = Object.entries(mapObject)
  const [mapArray, setMapArray] = useState(originMapArray)

  const { canAddNew, showNewPair, isValidValue, isInvalidKey } = useMapType({
    code,
    label,
    onError
  })

  const currentPosition = useRef([0, null])

  const [newIndexes, setNewIndexes] = useState([])

  useEffect(() => {
    setMapArray(Object.entries(mapObject))
  }, [value])

  const handleError = (errors) => {
    onError(errors)
  }
  const handleUpdate = ([row, col], val, nestedPosition) => {
    // console.log('Nested handleUpdate')
    showNewPair(false)
    currentPosition.current = [row, nestedPosition]

    const isNewItem = row === mapArray.length
    isNewItem && setNewIndexes([...newIndexes, row])

    const updatedMapArray = isNewItem
      ? [...mapArray, [col === 0 ? val : '', col === 1 ? val : { '': '' }]]
      : replaceArrayItem(
          mapArray,
          row,
          replaceArrayItem(mapArray[row], col, val)
        )
    isValidValue(updatedMapArray)
      ? onUpdate({
          value: pairsToObj(updatedMapArray),
          position: currentPosition.current
        })
      : setMapArray(updatedMapArray)
  }

  const keyIsImmutable = (idx) => fixedKeys && !newIndexes.includes(idx)

  const pairIsFocused = (idx) => {
    return currentPosition.current && idx === currentPosition.current[0]
  }

  const keyIsFocused = (idx) =>
    pairIsFocused(idx) && currentPosition.current[1] === null

  const valueIsFocused = (idx) =>
    pairIsFocused(idx) && currentPosition.current[1] === 1
  // console.log('currentPosition.current', currentPosition.current)

  return (
    <ScListLabel
      label={label}
      labelPlacement={labelPlacement}
      inputWidth={inputWidth}
      isGroupField={isGroupField}
    >
      <NestedMapPaperView aria-label="nested-map-field">
        <NestedMapListView>
          {Array.isArray(mapArray) &&
            mapArray.map((mapPair, idx) => (
              <NestedMapListItemView
                key={`${idx}-${JSON.stringify(mapPair)}`}
                alignItems={'flex-start'}
              >
                {' '}
                <NestedMapKeyFieldView>
                  <ScTextField
                    code={`code-${idx}`}
                    value={mapPair[0]}
                    meta={meta}
                    variant={variant}
                    updateDelay={updateDelay}
                    onUpdate={({ value }) =>
                      handleUpdate([idx, 0], value, null)
                    }
                    disabled={keyIsImmutable(idx)}
                    isInvalid={isInvalidKey(mapPair[0])}
                    focused={keyIsFocused(idx) && !keyIsImmutable(idx)}
                    selected={keyIsFocused(idx)}
                  />
                </NestedMapKeyFieldView>
                <NestedMapValueFieldView>
                  <ScFlatValuesMapField
                    code={`value-${idx}`}
                    value={mapPair[1]}
                    updateDelay={updateDelay}
                    meta={meta}
                    fixedKeys={keyIsImmutable(idx)}
                    onError={handleError}
                    variant={variant}
                    readonlyKeys={readonlyKeys}
                    onUpdate={({ value, position }) =>
                      handleUpdate([idx, 1], value, position)
                    }
                    focusedOn={pairIsFocused(idx) && currentPosition.current[1]}
                  />
                </NestedMapValueFieldView>
              </NestedMapListItemView>
            ))}
          {!canAddNew && (
            <>
              <SnowListItem>
                <SnowDivider style={{ width: '100%' }} />
              </SnowListItem>
              <NestedMapListItemView>
                <NestedMapKeyFieldView>
                  <ScTextField
                    code={`key-new`}
                    value={''}
                    meta={meta}
                    updateDelay={updateDelay}
                    onUpdate={({ value }) =>
                      handleUpdate([mapArray.length, 0], value, null)
                    }
                    focused={true}
                  />
                </NestedMapKeyFieldView>
                <NestedMapValueFieldView>
                  <ScFlatValuesMapField
                    code={`value-new`}
                    value={{ '': '' }}
                    meta={meta}
                    onError={handleError}
                    updateDelay={updateDelay}
                    onUpdate={({ value, position }) =>
                      handleUpdate([mapArray.length, 1], value, position)
                    }
                    focusedOn={currentPosition.current[1]}
                  />
                </NestedMapValueFieldView>
              </NestedMapListItemView>
            </>
          )}
          {canAddNew && <ScAddListItemButton onClick={showNewPair} />}
        </NestedMapListView>
      </NestedMapPaperView>
    </ScListLabel>
  )
}

export default ScNestedValuesMapField

ScNestedValuesMapField.propTypes = {
  fixedKeys: PropTypes.bool,
  label: PropTypes.string,
  code: PropTypes.string,
  value: PropTypes.object,
  required: PropTypes.bool,
  onUpdate: PropTypes.func,
  isGroupField: PropTypes.bool,
  updateDelay: PropTypes.number,
  meta: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'outlined']),
  onError: PropTypes.func,
  alertOnNewValue: PropTypes.bool
}
