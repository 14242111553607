import React from 'react'
import Alert from '@mui/material/Alert'

const SnowMuiAlert = React.forwardRef(function SnowMuiAlert ({ children, ...props }, ref) {
  return (
    <Alert ref={ref} {...props}>
      {children}
    </Alert>
  )
})

export default SnowMuiAlert
