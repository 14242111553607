import React, { useContext } from 'react'
import { format, isDate, parseISO } from 'date-fns'
import { SnowTypography } from '../../snowComponents'
import { ScBasicModal } from '../../modals'
import ScSpinner from '../../ScSpinner/ScSpinner'
import styled, { ThemeContext } from 'styled-components'

const BulkActionView = styled.div`
  max-width: 100%;

  .box-shipping_dates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  .col1 {
    grid-column: 1/2;
  }
  .col2 {
    grid-column: 2/3;
  }
  .field-shipping_dates_comment {
    grid-column: 1/3;
  }
`

const getFormattedDate = (isoDate, dateFormat = 'P') => {
  const defaultDate = '________'
  try {
    console.log('getFormattedDate isoDate', isoDate)
    console.log('getFormattedDate Date(isoDate)', new Date(isoDate))
    console.log('getFormattedDate isDate', isDate(new Date(isoDate)))
    console.log('getFormattedDate parseISO(isoDate)', parseISO(isoDate))
    console.log(
      'getFormattedDate format(parseIso(isoDate)',
      format(parseISO(isoDate), dateFormat)
    )
    return isoDate && isDate(new Date(isoDate))
      ? format(parseISO(isoDate), dateFormat)
      : '()'
  } catch (e) {
    console.error(
      'getFormattedDate exception e',
      isoDate,
      typeof isoDate,
      e?.message
    )
  }
  return defaultDate
}
const getFormattedDateRange = ({ start, end }) =>
  `${getFormattedDate(start)} -> ${getFormattedDate(end)}`

const isRangeValue = (val) => {
  const { start, end } = val
  return typeof start !== 'undefined' && typeof end !== 'undefined'
}
const prepareFilterValue = (value) => {
  return isRangeValue(value) ? getFormattedDateRange(value) : value
}

const UpdateTitle = ({ updatingItemsInfo }) => {
  const theme = useContext(ThemeContext)

  return (
    <SnowTypography component={'span'} variant={'h6'} fontWeight={theme.fontWeight.bold}>
      {`Fields to update for `}
      <SnowTypography component={'span'} variant={'inherit'} color={theme.mainPalette.typography.dark} fontWeight={theme.fontWeight.bold}>
        {updatingItemsInfo || 'Manually selected items.'}
      </SnowTypography>
    </SnowTypography>
  )
}

function BulkActionModal({
  onClose,
  selectedIds,
  filters,
  useBulkActionContent
}) {
  const { content, isLoading, title, confirmBtnProps } = useBulkActionContent({
    onClose,
    selectedIds,
    filters
  })
  console.log('BulkActionModal filters', { filters })
  const updatingItemsInfo = Object.keys(filters)
    .map(
      (filterName) =>
        `${filterName} = ${prepareFilterValue(filters[filterName])}`
    )
    .join(', ')

  return (
    <ScBasicModal
      open={true}
      onClose={onClose}
      onConfirm={confirmBtnProps.onClick}
      onConfirmProps={{ disabled: confirmBtnProps.disabled }}
      labelForConfirmBtn={confirmBtnProps.label}
      title={<UpdateTitle updatingItemsInfo={updatingItemsInfo} />}
      content={
        <BulkActionView aria-label={'bulk-action-content'}>
          {isLoading && <ScSpinner />}
          {content}
        </BulkActionView>
      }
    />
  )
}

BulkActionModal.propTypes = {}
export default BulkActionModal
