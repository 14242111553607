import styled from 'styled-components'

const OrderDashboardActionsView = styled.div`
  padding: 8px 12px;
  background: ${({ theme }) => theme.mainPalette.grey.veryLight};
  box-sizing: border-box;
  button {
    margin: 4px 0;
    width: 100%;
    box-sizing: border-box;
    text-transform: capitalize;
    font-weight: ${({ theme }) => theme.fontWeight.thin};
  }
`

export default OrderDashboardActionsView