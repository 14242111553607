import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { useScMessage } from '../../../shared/service/Message/data/currentMessageState'

const PRODUCTS_BASE = '/products'
const PRODUCTS_UPDATE = 'update'
const PRODUCT_VENDOR_SYNC = 'vendorInventoryUpdate'

const API_PRODUCTS = objWithParent({
  url: PRODUCTS_BASE,
  UPDATE: {
    url: PRODUCTS_UPDATE,
    method: 'POST',
    caller: 'API_PRODUCTS PRODUCTS_UPDATE!',
    validator: ({ source, skus, subscribers }) => true,
    domain: () => API_PRODUCTS
  },
  FETCH_EDD: {
    url: 'edd',
    method: 'GET',
    caller: 'API_PRODUCTS FETCH_EDD',
    validator: () => true
  },
  REQUEST_EDD_UPDATE: {
    url: 'edd',
    method: 'POST',
    caller: 'API_PRODUCTS REQUEST_EDD_UPDATE',
    validator: () => true
  },
  'PRODUCT.SYNC_VENDOR_INVENTORY': {
    url: PRODUCT_VENDOR_SYNC,
    method: 'POST',
    caller: 'API_PRODUCTS PRODUCT.SYNC_VENDOR_INVENTORY!',
    validator: () => true,
    domain: () => API_PRODUCTS
  }
})

export const useProductEddCmd = (
  id,
  onSuccess = () => true,
  onFail = () => false
) => {
  const fetchEddCmd = useEntityCommand(id, { ...API_PRODUCTS.FETCH_EDD }, (r) =>
    responseHandler(r, onSuccess, onFail)
  )
  const requestEddUpdateCmd = useEntityCommand(
    id,
    { ...API_PRODUCTS.REQUEST_EDD_UPDATE },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return { fetchEddCmd, requestEddUpdateCmd }
}
export const useUpdatePimProductsCmd = (
  { pimOnly = true, skusStr = '', subscribersStr = '' },
  onSuccess = () => true,
  onFail = () => false
) => {
  const cmd = useEntityCommand(
    0,
    {
      ...API_PRODUCTS.UPDATE,
      params: { pim_only: pimOnly, skus: skusStr, subscribers: subscribersStr }
    },
    (r) => responseHandler(r, onSuccess, onFail)
  )
  return cmd
}

export const useSyncCmd = (
  { product_id = '', vendor_id = '' },
  onSuccess = () => true,
  onFail = () => false
) => {
  const messenger = useScMessage()

  const cmd = useEntityCommand(
    product_id,
    {
      ...API_PRODUCTS['PRODUCT.SYNC_VENDOR_INVENTORY'],
      params: { vendor_id }
    },
    (r) => {
      console.log('Product sync success', {
        err_text: r.messages,
        err_data: r.data,
        r
      })
      const { messages, data, error, success } = r

      Array.isArray(messages) &&
        messages.forEach((message) =>
          data
            ? messenger.send(message, 'success')
            : messenger.send(message, 'error')
        )
      responseHandler(r, onSuccess, onFail)
    },
    (r) => {
      console.log('Product sync fail', r)
      messenger.send(`Something went wrong`, 'error')
    }
  )
  return cmd
}
