import React, { useEffect, useState } from 'react'
import { SnowArrowDropDownIcon, SnowArrowDropUpIcon } from '../../../icons'
import { ScIconButton } from '../../../buttons'
import { ScFieldLabel } from '../../../LayoutComponents'
import ScComboListbox from './ScComboListbox'
import ScTag from './ScTag'
import useAutocomplete from '@mui/material/useAutocomplete'
import { transformOptions } from '../../../../../utils/transformOptions'
import styled from 'styled-components'
import { autocompleteClasses } from '@mui/material/Autocomplete'

const InputWrapper = styled('div')`
  display: flex;
  min-width: 100%;
  flex: 1 1 100%;
  align-items: center;
  flex-wrap: wrap;
  transition: boxShadow 0.3s ease-in;
  li.${autocompleteClasses.focused} {
    box-shadow: 0 -2px 0 ${({ theme }) => theme.mainPalette.grey.dark} inset;
    box-sizing: border-box;
  }
  &:hover {
    border-color: ${({ theme }) => theme.mainPalette.grey.dark};
  }

  input {
    min-width: 10%;
    max-width: 100%;
    flex: 1 1 20%;
    font-size: 14px;
    min-height: 40px;
    box-sizing: border-box;
    padding: 9px 10px;
    border: none;
    margin: 0;
    outline: none;
    background: transparent;
  }
`

const getTagNameByValue = (value, valueName, tag, options) => {
  return (
    (!!options?.length &&
      !!value &&
      options?.filter((option) => option[valueName] === value)?.[0]?.[tag]) ??
    value
  )
}

const getValidOptions = (options) => {
  return options?.length > 0
    ? options.filter(
      (option) => option.value !== null && option.value !== 'undefined'
    )
    : []
}

const getInitValue = (incomeValue, emptyOption, optionsDef) => {
  if (Array.isArray(incomeValue) && incomeValue?.length) return incomeValue
  if (emptyOption[optionsDef?.value]) return [emptyOption[optionsDef?.value]]
  return []
}

function ScBaseMultiAutocomplete({
  labelPlacement = 'top',
  label = '',
  value: incomeValue = [],
  optionsDef = { value: 'value', label: 'label', tag: 'value' },
  metaOptions = {},
  emptyOption = {},
  code = '',
  multiple = true,
  onButtonClick = () => { },
  isInvalid = false,
  focused: incomeFocused = false,
  required = false,
  InputProps = {},
  inputWidth = 100,
  fontSize = '',
  helperText = '',
  variant = 'outlined',
  isGroupField = false,
  tooltipText = '',
  mb = 0,
  onUpdate = ({ value, ...otherProps }) => console.log({ value, otherProps }),
  ...props
}) {
  const _optionsDef = optionsDef
    ? optionsDef
    : { value: 'value', label: 'label', tag: 'value' }
  const initValue = getInitValue(incomeValue, emptyOption, _optionsDef)
  const [val, setVal] = useState(initValue)
  const [hasChanges, setHasChanges] = useState(false)
  const [del, setDel] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const emptyOptionValue = emptyOption[_optionsDef?.value]
  const options = transformOptions({
    options: metaOptions,
    optionsDef: _optionsDef
  })
  // incomeValue?.length && setVal(initValue)

  console.log('SnowAutocomplete', {
    metaOptions,
    _optionsDef,
    val,
    incomeValue
  })
  const handleUpdate = ({ value: updatedValue, ...props }) => {
    const allowedValue =
      Array.isArray(updatedValue) &&
      options
        .map(({ value }) => value)
        .filter((optionVal) => updatedValue.includes(optionVal))
    console.log('handleUpdate', { updatedValue, allowedValue, props, options })

    onUpdate({ value: allowedValue, ...props })
  }
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl
  } = useAutocomplete({
    id: code,
    disableCloseOnSelect: true,
    multiple: multiple,
    autoComplete: true,
    value: val,
    options: getValidOptions(options),
    open: showPopup,
    onChange: (_, _val, reason, details) =>
      handleChange(_, _val, reason, details),
    onInputChange: () => handleInputChange(),
    isOptionEqualToValue: (option, value) => {
      return option?.value === value || option?.value === value?.value
    },
    getOptionLabel: (option) => option.label
  })

  const handleChange = (evt, _val, reason, details) => {
    console.log('ScMultiComboField change', {
      reason,
      details,
      _val,
      val
    })

    setHasChanges(true)
    if (reason === 'selectOption') {
      let rezult = []
      if (emptyOptionValue) {
        if (details.option.value === emptyOptionValue) {
          console.log('SnowAutocomplete handleChange empty')
          rezult = [emptyOptionValue]
          handleUpdate({
            value: [],
            reason: 'SnowAutoComplete handleChange handleUpdate empty'
          })
        } else {
          console.log('ScMultiComboField select val', val)
          rezult = val?.length && Array.isArray(val) ? [
            ...val.filter((value) => value !== emptyOptionValue),
            details.option.value
          ]
            : [...val, details.option.value]
        }
      } else {
        rezult = [...val, details.option.value]
      }
      setVal(rezult)
      console.log(
        'ScMultiComboField select _val',
        _val,
        details.option.value,
        rezult
      )
    }
    if (reason === 'removeOption') {
      console.log('SnowAutocomplete remove', _val)
      if (emptyOptionValue) {
        if (_val?.length) {
          setDel(false)
          setVal(_val)
        } else {
          setVal([emptyOptionValue])
          setDel(true)
        }
      } else {
        setVal(_val)
        console.log('SnowAutocomplete remove option', details.option, _val)
        _val.length === 0 &&
          handleUpdate({
            value: [],
            reason: {
              message: 'SnowAutocomplete handleUpdate  remove option',
              data: {
                details,
                _val
              }
            }
          })
      }
    }
  }
  const handleInputChange = () => {
    setShowPopup(true)
  }

  const handleShowPopup = () => {
    setShowPopup(!showPopup)
  }

  useEffect(() => {
    !focused && setShowPopup(false)
  }, [focused])

  useEffect(() => {
    console.log('SnowAutocomplete useEffect del', del)
    del &&
      handleUpdate({
        value: [],
        reason: { message: 'SnowAutocomplete useEffect handleUpdate del', del }
      })
    // setVal(getInitValue(incomeValue, emptyOption))
    // const v = getInitValue(incomeValue, emptyOption)
    // console.log({ v, incomeValue, emptyOption })
    // setVal(v)
  }, [del])

  useEffect(() => {
    const needsToUpdate =
      val?.length && !val.includes(emptyOptionValue) && hasChanges
    console.log('SnowAutocomplete useEffect val', {
      val,
      needsToUpdate,
      hasChanges
    })

    needsToUpdate &&
      handleUpdate({
        value: val,
        reason: 'SnowAutoComplete useEffect handleUpdate val updated'
      })
  }, [val])

  console.log('SnowAutocomplete values', {
    InputProps,
    getInputProps: getInputProps(),
    value,
    metaOptions,
    options,
    incomeValue,
    val
  })

  useEffect(() => {
    console.log('SnowAutocomplete useEffect download', {
      income: incomeValue,
      val,
      initValue
    })
    Array.isArray(incomeValue) && incomeValue?.length && setVal(incomeValue)
    return () => {
      console.log('SnowAutocomplete useEffect destruct')
    }
  }, [incomeValue?.length])

  return (
    <ScFieldLabel
      label={label}
      code={code}
      labelPlacement={labelPlacement}
      inputWidth={inputWidth}
      isInvalid={isInvalid}
      tooltipText={tooltipText}
      helperText={helperText}
      variant={variant}
      required={required}
      isGroupField={isGroupField}
      fontSize={fontSize}
      mb={mb}
      {...getInputLabelProps()}
      {...props}
    >
      <div style={{ width: '100%' }} aria-label="multi-combofield">
        <div style={{ display: 'flex' }}>
          <div {...getRootProps()} style={{ flex: '1 1 auto' }}>
            <InputWrapper ref={setAnchorEl} focused={focused}>
              {val?.length
                ? val.map((_val, index) => (
                  <ScTag
                    key={index}
                    label={getTagNameByValue(
                      _val,
                      _optionsDef.value,
                      _optionsDef.tag,
                      metaOptions
                    )}
                    {...getTagProps({ index })}
                  />
                ))
                : null}
              <input
                onClick={handleShowPopup}
                {...props}
                {...InputProps}
                {...getInputProps()}
              />
            </InputWrapper>
          </div>
          <ScIconButton size={40} onClick={handleShowPopup}>
            {showPopup ? <SnowArrowDropUpIcon /> : <SnowArrowDropDownIcon />}
          </ScIconButton>
        </div>
        <ScComboListbox
          options={groupedOptions}
          listProps={getListboxProps}
          itemProps={getOptionProps}
        />
      </div>
    </ScFieldLabel>
  )
}
export default ScBaseMultiAutocomplete
