import React from 'react'
import ScAddress from './ScAddress'
import PropTypes from 'prop-types'

const BillingAddress = ({ orderId, values, onUpdate }) => {
  console.log('Billing address', values)
  return (
    <ScAddress
      onUpdate={onUpdate}
      type={'billing'}
      orderId={orderId}
      orderAddress={values}
    />
  )
}

BillingAddress.propTypes = {
  values: PropTypes.shape().isRequired
}

export default BillingAddress
