import styled, { css } from 'styled-components';

const OrderErrorsView = styled.div`
  ${({ theme, mb }) => css`
    padding: ${theme.spacing.secondary};
    margin-bottom: ${mb ? mb : theme.spacing.secondary};
    box-shadow: ${theme.shadow.main};
  `}
`

export default OrderErrorsView