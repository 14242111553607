import objWithParent from '../../../utils/parenter'
import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { responseHandler } from '../../../infrastructure/api/utils'
import { useEffect, useState } from 'react'
import { useScMessage } from '../../../shared/service/Message/data/currentMessageState'
import { removeEmptyProps } from '../../../utils/helpers'

const API_CARRIERS = objWithParent({
  url: '/carriers',
  FETCH_CARRIERS: {
    url: '/',
    method: 'GET',
    caller: 'FETCH_CARRIERS!',
    validator: () => true
  },
  FETCH_CARRIER: {
    url: '/',
    method: 'GET',
    caller: 'FETCH_CARRIER!',
    validator: () => true
  },
  CREATE_CARRIER: {
    url: '',
    method: 'POST',
    caller: 'CREATE_CARRIER!',
    validator: () => true
  },
  UPDATE_CARRIER: {
    url: '',
    method: 'PUT',
    caller: 'UPDATE_CARRIER!',
    validator: () => true
  },
  DELETE_CARRIER: {
    url: '/',
    method: 'DELETE',
    caller: 'DELETE_CARRIER!',
    validator: () => true
  }
})
const API_CARRIER_VENDOR_MAPPINGS = objWithParent({
  url: '/carriers/vendors',
  FETCH_CARRIER_VENDOR_MAPPINGS: {
    url: '/',
    method: 'GET',
    caller: 'FETCH_CARRIER_VENDOR_MAPPINGS!',
    validator: () => true
  },
  CREATE_CARRIER_VENDOR_MAPPING: {
    url: '/',
    method: 'POST',
    caller: 'CREATE_CARRIER_VENDOR_MAPPING!',
    validator: () => true
  },
  UPDATE_CARRIER_VENDOR_MAPPING: {
    url: '/',
    method: 'PUT',
    caller: 'UPDATE_CARRIER_VENDOR_MAPPING!',
    validator: () => true
  },
  DELETE_CARRIER_VENDOR_MAPPING: {
    url: '/',
    method: 'DELETE',
    caller: 'DELETE_CARRIER_VENDOR_MAPPING!',
    validator: () => true
  }
})

const API_CARRIER_PLATFORM_MAPPINGS = objWithParent({
  url: '/carriers/platforms',

  FETCH_CARRIER_PLATFORM_MAPPINGS: {
    url: '/',
    method: 'GET',
    caller: 'FETCH_CARRIER_PLATFORM_MAPPINGS!',
    validator: () => true
  },
  CREATE_CARRIER_PLATFORM_MAPPING: {
    url: '/',
    method: 'POST',
    caller: 'CREATE_CARRIER_PLATFORM_MAPPING!',
    validator: () => true
  },
  UPDATE_CARRIER_PLATFORM_MAPPING: {
    url: '/',
    method: 'PUT',
    caller: 'UPDATE_CARRIER_PLATFORM_MAPPING!',
    validator: () => true
  },
  DELETE_CARRIER_PLATFORM_MAPPING: {
    url: '/',
    method: 'DELETE',
    caller: 'DELETE_CARRIER_PLATFORM_MAPPING!',
    validator: () => true
  }
})

export const useCarriersApi = () => {
  const [carriers, setCarriers] = useState([])
  const { send: sendMessage } = useScMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [appliedCarrierParams, setAppliedCarrierParams] = useState({})
  const [appliedCarrierId, setAppliedCarrierId] = useState(0)
  const [carrierAction, setCarrierAction] = useState('')
  const fetchCarriersCmd = useEntityCommand(
    0,
    API_CARRIERS.FETCH_CARRIERS,
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('fetchCarriersCmd success', r)
          setCarriers(r)
        },
        (r) => console.log('fetchCarriersCmd fail', r)
      )
  )
  const createCarrierCmd = useEntityCommand(
    0,
    {
      ...API_CARRIERS.CREATE_CARRIER,
      params: appliedCarrierParams ? removeEmptyProps(appliedCarrierParams) : {}
    },
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('createCarrierCmd success', r)
          // setAvailableActions(r)
        },
        (r) => console.log('createCarrierCmd fail', r)
      )
  )
  const deleteCarrierCmd = useEntityCommand(
    appliedCarrierId,
    {
      ...API_CARRIERS.DELETE_CARRIER
    },
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('deleteCarrierCmd success', r)
          // setAvailableActions(r)
        },
        (r) => console.log('deleteCarrierCmd fail', r)
      )
  )
  const updateCarrierCmd = useEntityCommand(
    appliedCarrierId,
    {
      ...API_CARRIERS.UPDATE_CARRIER,
      params: appliedCarrierParams ? removeEmptyProps(appliedCarrierParams) : {}
    },
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('deleteCarrierCmd success', r)
          // setAvailableActions(r)
        },
        (r) => console.log('deleteCarrierCmd fail', r)
      )
  )
  const fetchCarrierCmd = useEntityCommand(
    appliedCarrierId,
    API_CARRIERS.FETCH_CARRIER,
    (r) =>
      responseHandler(
        r,
        (r) => {
          console.log('fetchCarriersCmd success', r)
        },
        (r) => console.log('fetchCarriersCmd fail', r)
      )
  )

  const carrier = ({ id = 0 }) => {
    setAppliedCarrierId(id)
    const fetch = () => {
      setIsLoading(true)
      if (!id) {
        console.error('ID to fetch is not pointed')
        return
      }

      console.log('id', id)
      const carrier = fetchCarrierCmd()
      console.log('fetched Carrier', carrier)
      setIsLoading(false)
      return carrier
    }

    return { fetch }
  }
  const createCarrier = ({ code, title, tracking_url }) => {
    if (!(code && title)) {
      console.error('Code or Title or Tracking Url is not pointed')
      console.log({ code, title})
      return
    }
    setAppliedCarrierParams({ code, title, tracking_url })
    setCarrierAction('create')
  }
  const updateCarrier = ({ id, code, title, tracking_url }) => {
    if (!(id && code && title)) {
      console.error('Id, Code or Title or Tracking Url is not pointed')
      console.log({ code, title })
      return
    }
    setAppliedCarrierId(id)
    setAppliedCarrierParams({ code, title, tracking_url })
    setCarrierAction('update')
  }
  const deleteCarrier = ({ id }) => {
    if (!id) {
      console.error(' Id is not pointed')
      console.log({ id })
      return
    }
    setAppliedCarrierId(id)
    setCarrierAction('delete')
  }
  const cmdRunner = (cmd) => {
    setIsLoading(true)
    cmd().then((r) => {
      console.log('cmdRunner response', r)
      setIsLoading(false)
      fetchCarriersCmd()
    })
    setCarrierAction('')
    setAppliedCarrierParams({})
  }

  useEffect(() => {
    if (!carrierAction || isLoading) return

    // if (carrierAction === 'list') cmdRunner(fetchCarriersCmd())
    if (carrierAction === 'create') cmdRunner(createCarrierCmd)
    if (carrierAction === 'delete') cmdRunner(deleteCarrierCmd)
    if (carrierAction === 'update') cmdRunner(updateCarrierCmd)
  }, [carrierAction])

  useEffect(() => {
    if (carriers.length) return
    fetchCarriersCmd((r) => console.log('fetchCarriersCmd response', r))
  }, [])

  // useEffect(() => {
  //   if (!vendorOrderAction || actionIsRunning) return
  //   setActionIsRunning(true)
  //   setVendorOrderAction('')
  //   applyActionCmd().then((r) => {
  //     setActionIsRunning(false)
  //     if (!r.error && r?.data?.success === 'true' && r?.data?.message) {
  //       sendMessage(r.data.message, 'success')
  //     }
  //     if (r.error || r?.data?.success !== 'true') {
  //       sendMessage('something went wrong', 'error')
  //     }
  //   })
  // }, [vendorOrderAction])

  return {
    carriers,
    createCarrier,
    updateCarrier,
    deleteCarrier,
    getCarrier: carrier
  }
}
