import React from 'react'
import { SnowBox } from '../snowComponents'
import { getBulkSelectionStatus } from './infrastructure/helpers'
import SnowMultiCheckbox from '../snowComponents/SnowMultiCheckbox'
import { MULTICHECK_STATE } from '../snowComponents/SnowMultiCheckbox'

const ScBulkCheckbox = ({
  rows,
  selectedRows,
  onBulkSelect = () => console.log('Gonna Set Selected rows')
}) => {
  const bulkSelectionState = getBulkSelectionStatus(rows, selectedRows)
  const bulkSelectionCmd = () =>
    onBulkSelect(bulkSelectionState === MULTICHECK_STATE.Full ? [] : rows)
  return (
    <SnowBox>
      <SnowMultiCheckbox
        selectionState={bulkSelectionState}
        onChange={bulkSelectionCmd}
      />
    </SnowBox>
  )
}
export default ScBulkCheckbox
