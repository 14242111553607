import React, { useEffect, useState } from 'react'
import { useFetchBatchesCmd } from '../infrastructure/bachesApi'
import {
  useScPageHeaderTitle
} from '../../app/data/currentHeadingState'
import ScEditableVirtualizedTable from '../../../shared/components/tables/ScEditableVirtualizedTable/ScEditableVirtualizedTable'
import ScSpinner from '../../../shared/components/ScSpinner/ScSpinner'
import {
  ScActionsBar,
  ScPageContent,
  ScPageContentPaper
} from '../../../shared/components/LayoutComponents'
import {
  batchFieldsDescription
} from '../infrastructure/fakeBatches'
import { useNavigate } from 'react-router-dom'

const useBatchesHub = () => {
  const [items, setItems] = useState([])
  const fields = batchFieldsDescription
  const [cursor, setCursorToLoad] = useState(null)
  const [perCursor, setPerCursor] = useState(200)
  const [isLoading, setIsLoading] = useState(false)

  const [, setTitle] = useScPageHeaderTitle()

  const fetchItemsCursorCmd = useFetchBatchesCmd(
    {
      cursor,
      per_cursor: perCursor,
      filters: { entity: 'orderItem' }
    },
    ({ data }) => {
      data && setItems(data)
      // data && setItems(fakeBatches)
    },
    (r) => console.log('onFail', r)
  )

  useEffect(() => {
    setTitle('Batches List')
    setIsLoading(true)
    try {
      const {
        data: payload,
        error,
        isLoading
      } = fetchItemsCursorCmd().then(() => setIsLoading(false))
    } catch (e) {
      setIsLoading(false)
    }
  }, [])

  return { items, fields, isLoading }
}

const BatchesHub = () => {
  const navigate = useNavigate()

  const { items, fields, isLoading } = useBatchesHub()
  const [, setTitle] = useScPageHeaderTitle()
  console.log('BatchesHub', items)
  const goToBatch = ({ id }) => {
    navigate(`/home/batches/${id}`)
  }

  return (
    <>
      <ScPageContent py={2}>
        <ScPageContentPaper isPaper={false}>
          <div style={{ overflow: 'scroll' }}>
            <ScEditableVirtualizedTable
              editable={false}
              selectable={false}
              keyField={'id'}
              autoWidth={true}
              // onCrudEvent={onCrudEvent}
              onRowClick={goToBatch}
              fields={fields}
              // filters={filters}
              dataRows={items}
              // onFilterChange={onFilterChange}
              autoFilter={false}
              contentAdaptiveColumnsWidth={false}
            // customRowActions={(item) => (
            //   <ScDirectionIcon
            //     fontSize={'medium'}
            //     tooltip={`Go to parent order`}
            //     onClick={() =>
            //       navigate(`/home/order_hub/order_details/${item.order_id}`)
            //     }
            //   />
            // )}
            />
            {isLoading && <ScSpinner centered />}
            <ScActionsBar borderTop>
              {/*<LoadMoreBtn />*/}
              {/*<LoadRestBtn />*/}
            </ScActionsBar>
          </div>
        </ScPageContentPaper>
      </ScPageContent>
    </>
  )
}

export default BatchesHub
