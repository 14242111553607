import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  SnowBox,
  SnowGrid,
} from '../../../../../shared/components/snowComponents'
import { ScTextField, ScComboField } from '../../../../../shared/components/SnowEditableField/baseFields'
import { ScBasicModal } from '../../../../../shared/components/modals'
import {
  ScPrimaryBtn,
} from '../../../../../shared/components/buttons'
import { currentMessageState } from '../../../../../shared/service/Message/data/currentMessageState'
import useClients from '../../../hooks/useClients'
import { useCreateProjectCmd } from '../../../infrastructure/v2/projectsApiConfig'
import { useCreateClientCmd } from '../../../infrastructure/v2/clientsApiConfig'

export const ProjectConnector = ({
  onClose = () => {
    console.log('onClose')
  }
}) => {
  const setMessage = useSetRecoilState(currentMessageState)
  const [open, setOpen] = useState(false)
  const [projectName, setProjectName] = useState('')
  const [clientId, setClientId] = useState('')
  const [clientName, setClientName] = useState('')
  const [projectId, setProjectId] = useState('')
  const { data: clients } = useClients()
  const [createProjectState, setCreateProjectState] = useState('notready')
  const createProjectConnectionCmd = useCreateProjectCmd(
    {
      clientId,
      projectName
    },
    (resp) => {
      const { data, message } = resp
      setMessage({ message, severity: 'success' })
      console.log('createProjectConnectionCmd onSuccess', data)
      setCreateProjectState('done')
      closeAndReload()
    },
    (resp) => {
      const { message, ...rest } = resp
      setMessage({ message, severity: 'error' })
      console.log('createProjectConnectionCmd onFail', rest)
      setCreateProjectState('failed')
      setOpen(false)
    }
  )

  const getProjectCreateDto = () => ({
    clientName,
    projectName
  })

  const createClientCmd = useCreateClientCmd(
    { clientName },
    (resp) => {
      console.log('createClientCmd onSuccess', resp)
      const {
        data: { client_id: clientId },
        message
      } = resp
      setMessage({ message, severity: 'success' })
      setClientId(clientId)
    },
    (resp) => {
      const { message, ...rest } = resp
      setMessage({ message, severity: 'error' })
      setCreateProjectState('failed')
      setOpen(false)
      console.log('createClientCmd onFail', rest)
    }
  )
  const onOpenModal = () => {
    setOpen(true)
  }
  const closeAndReload = () => {
    setOpen(false)
    // setTimeout(() => location.reload(), 2000)
  }
  const startCreateProject = () => {
    if (createProjectState !== 'ready') return
    setCreateProjectState('started')
  }
  useEffect(() => {
    if (createProjectState === 'started') {
      if (clientId && projectName && !projectId) {
        createProjectConnectionCmd().then((resp) => {
          console.log('createProjectCmd resp', resp)
          const { shop_id } = resp?.data ?? {}
          shop_id && setProjectId(shop_id)
        })
      }
      if (!clientId && projectName) {
        createClientCmd().then((resp) => {
          console.log('createClientCmd resp', resp)
          const { client_id } = resp?.data ?? {}
          client_id && setClientId(client_id)
        })
      }
    }
  }, [createProjectState, clientId, projectName])

  useEffect(() => {
    const emptyFields = Object.values(getProjectCreateDto()).filter(
      (value) => !value
    )
    setCreateProjectState(!emptyFields.length ? 'ready' : 'notready')
  }, [clientId, clientName, projectId, projectName])

  console.log('clients', createProjectState, clientId, clientName, clients)

  return (
    <>
      <ScBasicModal
        open={open}
        labelForConfirmBtn="Create Project"
        onConfirm={startCreateProject}
        onClose={() => setOpen(false)}
        onConfirmProps={{ disabled: createProjectState !== 'ready' }}
        title={`Connect New Project`}
        content={
          <SnowGrid container justifyContent={'flex-start'}>
            <SnowGrid item sm={8}>
              <SnowBox pr={1}>
                <ScTextField
                  label={'Project Name'}
                  value={projectName}
                  required={true}
                  focused={true}
                  code={'project_name'}
                  onUpdate={({ key, value }) => {
                    setProjectName(value)
                  }}
                />
              </SnowBox>
            </SnowGrid>
            <SnowGrid item sm={4}>
              <SnowBox pr={2}>
                <ScComboField
                  label={'Client'}
                  value={clientName}
                  required={true}
                  focused={true}
                  code={'client_id'}
                  onUpdate={({ value: id, label }) => {
                    
                    console.log('ScComboField Client ', id, label)
                    id && setClientId(id)
                    setClientName(label)
                  }}
                  meta={{
                    options: clients,
                    optionsDef: { value: 'id', label: 'name' }
                  }}
                />
              </SnowBox>
            </SnowGrid>
          </SnowGrid>
        }
      />
      <ScPrimaryBtn label={`Connect New Project`} onClick={onOpenModal}/>
    </>
  )
}
export default ProjectConnector
