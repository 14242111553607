import React, { useContext } from 'react'
import { SnowButton } from '../../snowComponents'
import { ReactComponent as GoogleLogo } from '../../../../domains/app/images/SVGs/googleLogo.svg'
import { ThemeContext } from 'styled-components'

const ScLoginWithGoogleBtn = ({ onClick }) => {
  const theme = useContext(ThemeContext)
  return (
    <SnowButton
      onClick={onClick}
      startIcon={<GoogleLogo />}
      sx={{ color: theme.mainPalette.grey.secondary }}
    >
      Login with Google
    </SnowButton>
  )
}

export default ScLoginWithGoogleBtn
