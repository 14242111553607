import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { ScComboField } from '../SnowEditableField/baseFields'
import { searcherFamily } from './data/searcherState'
import styled from 'styled-components'

const SearcherView = styled(ScComboField)`
  background: ${({ theme }) => theme.mainPalette.color.contrast};
  width: 100%;
  height: 100%;
  .MuiInputBase-input {
    height: 38px !important;
    padding: 0 1rem;
    border: ${({ theme }) => theme.border.main};
  }
`

const Searcher2 = ({
  onSearchUpdate = (str) => {
    console.log('onSearchUpdate', str)
  },
  value = '',
  code = '',
  placeholder,
  label = '',
  labelPlacement = '',
  meta,
  tooltip,
  loading = false,
  tooltipNewValue = false,
  disableClearable = true,
  onClear
}) => {
  const [searchStr, setSearchStr] = useRecoilState(searcherFamily(code))
  const resetSearchStr = useResetRecoilState(searcherFamily(code))
  const [inputValue, setInputValue] = useState(searchStr)
  console.log('Searcher searchStr', { searchStr, inputValue })

  const searchTimer = useRef(0)
  useEffect(() => {
    // console.log('Searcher useEffect searchStr ', searchStr)

    setInputValue(searchStr)
  }, [searchStr])

  useEffect(() => {
    return () => {
      console.log('resetSearchStr')
      resetSearchStr()
      clearTimeout(searchTimer.current)
    }
  }, [])
  useEffect(() => {
    setSearchStr(value)
  }, [value])

  const onSearchChange = (evt) => {
    const typedStr = evt.value
    const runSearch = () => {
      setSearchStr(typedStr)
      onSearchUpdate(typedStr)
    }
    setInputValue(typedStr)
    if (inputValue !== typedStr) {
      if (searchTimer.current) {
        clearTimeout(searchTimer.current)
      }
      searchTimer.current = setTimeout(runSearch, 1000)
    }
  }
  const inputProps = {
    disableUnderline: true
  }
  return (
    <SearcherView
      // autoFocus={true}
      loading={loading}
      label={label ? label : ''}
      labelPlacement={labelPlacement ? labelPlacement : ''}
      placeholder={placeholder}
      disableIndicatorNewValue={true}
      value={inputValue}
      tooltip={tooltip}
      tooltipNewValue={tooltipNewValue}
      onUpdate={onSearchChange}
      disableClearable={disableClearable}
      code={`input-search-${code}`}
      InputProps={inputProps}
      meta={meta}
      onClear={onClear}
    // ref={searcherRef}
    />
  )
}

export default Searcher2
