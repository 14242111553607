import React, {  useEffect, useMemo, useState } from 'react'
import HeaderFilter from './HeaderFilter'
import { NIL as NIL_UUID } from 'uuid'
import { setElementInCollectionByKey } from '../../../../../utils/helpers'
import styled from 'styled-components'
import { TableColumnTitleView } from '../../../../../domains/app/App/_styled/TableContentView'
import { SnowTypography } from '../../../snowComponents'

const TableTitleView = styled(SnowTypography).attrs({
  component: 'span'
})`
  &.MuiTypography-root{
    ${TableColumnTitleView}
  }
`

const arrayTypes = ['fixed_flat_list']
const jsonTypes = [
  'map',
  'fixed_key_map',
  'readonly_key_map',
  'JSON',
  'flat_list',
  '2levels_map_list'
]
const MIN_HEIGHT = 1
const MIN_WIDTH = 12
const MAX_WIDTH = 50

const useTableFieldsDescription = ({
  keyField = 'id',
  fields,
  filters: incomeFilters,
  rows,
  onFilterChange,
  autoFilter = true,
  contentAdaptiveColumnsWidth = true,
  uid
}) => {
  const fieldKeyList = Object.keys(fields)
  const visibleFields = Object.entries(fields)
    .filter(([, { visible }]) => visible)
    .reduce((acc, [key, value]) => {
      return { ...acc, [key]: value }
    }, {})

  const visibleFieldKeyList = Object.keys(visibleFields)
  const visibleFieldValueList = Object.values(visibleFields)
  const filterableFieldKeyList = Object.keys(visibleFields).filter(
    (key) => Boolean(visibleFields[key]?.filterable) === true
  )

  const [filters, setFilters] = useState(incomeFilters)

  // console.log(
  //   'useTableFieldsDescription render:',
  //   fields,
  //   filters,
  //   incomeFilters
  // )
  const generateDataStub = () => {
    return fieldKeyList.reduce((acc, dataKey) => {
      acc[dataKey] = arrayTypes.includes(fields[dataKey].type)
        ? []
        : jsonTypes.includes(fields[dataKey].type)
          ? {}
          : ''
      return acc
    }, {})
  }
  const [filteredItems, setFilteredItems] = useState([])

  const isMatchedToFilter = (filterKey, filterValue, row) => {
    const fieldDescription = fields[filterKey]
    if (row[keyField] === NIL_UUID || typeof row[filterKey] === 'undefined')
      return true

    const cellValue = row[filterKey]

    const { value: optKey, label: optLabel } =
    fieldDescription?.optionsDef ?? {}

    const possibleOptions =
      Array.isArray(fieldDescription.options) &&
      fieldDescription.options
        .filter((option) =>
          option[optLabel].toLowerCase()?.includes(filterValue.toLowerCase())
        )
        .map((option) => option[optKey])

    const hitOption =
      possibleOptions &&
      (Array.isArray(cellValue)
        ? cellValue.find((item) =>
          possibleOptions?.includes(
            optKey && fieldDescription.listType !== 'flat'
              ? item[optKey]
              : item
          )
        )
        : possibleOptions?.includes(cellValue))

    console.log('cellValue!', { filterValue: String(filterValue), cellValue })
    // const toBoolean = (val) => !(!val || val === '0' || String(val).toLowerCase() === 'false')
    // const hitBoolean = toBoolean(cellValue) === toBoolean(filterValue)
    const hitString = JSON.stringify(cellValue)
      .toLowerCase()
      .includes(String(filterValue).toLowerCase())

    return (hitOption || hitString)// || ((fields[filterKey].editType === 'boolean' || fields[filterKey].editType === 'checkbox') && hitBoolean)
  }

  const filtrateItems = (items) => {
    const filteredItems = autoFilter
      ? items.filter((row) =>
        Object.entries(filters).reduce((acc, [filterKey, filterValue]) => {
          const matchedByFilter = isMatchedToFilter(
            filterKey,
            filterValue,
            row
          )
          return acc && matchedByFilter
        }, true)
      )
      : items
    return filteredItems.length ? filteredItems : [generateDataStub()]
  }
  const getRowHeight = (row) => {
    const rHeight = visibleFieldKeyList?.reduce((rowHeight, fieldCode) => {
      const cellValue = row[fieldCode]
      const { getDimension, minHeight } = fields[fieldCode]
      const [, cellHeight] = getDimension?.call(
        this,
        cellValue,
        fields[fieldCode]?.options
      ) ?? [0, 1]

      return Math.max(cellHeight, rowHeight, minHeight ?? 0)
    }, 1)
    return rHeight
  }
  //todo: work on minimal height value
  const fieldsMinimalDimension =
    visibleFieldKeyList?.reduce((acc, fieldCode) => {
      const { minWidth = MIN_WIDTH } = fields[fieldCode]
      return { ...acc, [fieldCode]: [Math.max(minWidth, MIN_WIDTH), 1] }
    }, {}) || {}

  const minTableWidth =
    Object.values(fieldsMinimalDimension).reduce(
      (tableWidth, [_width, _height]) => tableWidth + _width,
      0
    ) || 0

  const getCellsMeasuredDimension = (_rows) => {
    // console.log(
    //   '> getCellsMeasuredDimension',
    //   _rows,
    //   contentAdaptiveColumnsWidth,
    //   fieldsMinimalDimension
    // )
    if (!contentAdaptiveColumnsWidth) return fieldsMinimalDimension

    return visibleFieldKeyList?.reduce((dict, fieldCode) => {
      dict[fieldCode] = _rows.reduce(
        (acc, rowItem) => {
          const cellValue = rowItem[fieldCode]
          const [_prevW, _prevH] = acc

          const {
            minWidth = MIN_WIDTH,
            maxWidth = MAX_WIDTH,
            getDimension = (val) => [val ? JSON.stringify(val).length : 12, 1]
          } = fields[fieldCode]

          const [_width, _height] = getDimension?.call(
            this,
            cellValue,
            fields[fieldCode]?.options
          ) ?? [0, 0]

          // console.log('MaMeasured widths', _prevW, minWidth, _width)

          return [
            Math.min(Math.max(_prevW, minWidth, _width), maxWidth, MAX_WIDTH),
            Math.max(_prevH, _height, 1)
          ]
        },
        [MIN_WIDTH, MIN_HEIGHT]
      )
      return dict
    }, {})
  }
  // console.log('getCellsMeasuredDimension > ', rows)
  const cellsMaxDimensionsDict = useMemo(
    () => getCellsMeasuredDimension(rows),
    [rows]
  )
  const tableWidth =
    10 *
    Math.max(
      minTableWidth,
      contentAdaptiveColumnsWidth &&
      Object.keys(cellsMaxDimensionsDict).reduce((acc, code) => {
        const [w] = cellsMaxDimensionsDict[code]
        return acc + w
      }, 0)
    )

  const updateRelatedFields = (updatedItemId, { code, value }) => {
    console.log('updateRelatedFields', keyField, filteredItems)
    const changedRowData = {
      ...filteredItems.find(
        (item) => item[keyField].toString() === updatedItemId.toString()
      ),
      [code]: value
    }

    const updatedRelatedValues = fieldKeyList.reduce((acc, fieldCode) => {
      const reviewedField = fields[fieldCode]
      if (reviewedField?.relatedFieldCode !== code) return acc

      console.log(
        `wanna to update related value of ${fieldCode} with value ${value} by func:`,
        reviewedField?.valueFunc
      )
      const updatedValue = reviewedField?.valueFunc?.call(this, value)
      console.log('updatedRelatedValue', updatedValue)
      return { ...acc, [fieldCode]: updatedValue }
    }, {})

    const readyRowData = { ...changedRowData, ...updatedRelatedValues }

    setFilteredItems((prev) =>
      setElementInCollectionByKey(prev, readyRowData, keyField)
    )
    console.log('updateRelatedFields', readyRowData)
  }
  const headerRenderer = function ({ dataKey, label, columnData }) {
    // console.log(
    //   'useTableDescription headerRenderer ',
    //   fields,
    //   filterableFieldKeyList,
    //   dataKey,
    //   columnData,
    //   filters
    // )
    const { editType, meta, options } = columnData ?? {}
    const children = [
      <div
        aria-label="virtualized-table-header"
        key="label"
        title={typeof label === 'string' ? label : null}
        style={{
          maxWidth: '100%',
          whiteSpace: 'pre-line',
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden'
        }}
      >
        {filterableFieldKeyList.includes(dataKey) ? (
          <HeaderFilter
            label={label}
            uid={uid}
            meta={{ ...meta, editType, options }}
            dataKey={dataKey}
            value={filters[dataKey]}
            onFilterChange={(filterValue) => {
              console.log(
                'useTableFieldsDescription onFilterChange',
                filters,
                dataKey,
                filterValue,
                typeof filterValue
              )
              if (typeof filterValue === 'undefined') return

              // setFilters({ ...filters, [dataKey]: filterValue })
              //
              onFilterChange && onFilterChange(dataKey, filterValue)
            }}
          />
        ) : (
          <TableTitleView>{label}</TableTitleView>
          
        )}
      </div>
    ]
    return children
  }

  useEffect(() => {
    // const updatedFilters = getFilterFromSearchParams()
    setFilters(incomeFilters)
    console.log('updatedFilters', incomeFilters)
  }, [incomeFilters])

  useEffect(() => {
    // const updatedFilters = getFilterFromSearchParams()
    console.log('useTableFieldsDescription fields', fields)
  }, [fields])

  useEffect(() => {
    console.log('useTableFieldsDescription useEffect[filters, rows]', filters)
    filters && setFilteredItems(filtrateItems(rows))
    typeof filters === 'undefined' && setFilteredItems(rows)
  }, [filters, rows])

  useEffect(() => {
    filterableFieldKeyList.map(
      (filterName) =>
        onFilterChange &&
        filters[filterName] &&
        onFilterChange(filterName, filters[filterName])
    )
  }, [])
  return {
    fieldsToShow: visibleFieldValueList,
    updateRelatedFields,
    filteredItems,
    getCellsMeasuredDimension,
    getRowHeight,
    headerRenderer,
    tableWidth
  }
}
export default useTableFieldsDescription
