import React, { Suspense, useLayoutEffect, useEffect, useState, useRef } from 'react'
import useResizeObserver from 'use-resize-observer'
import PropTypes from 'prop-types'
import Header from './Header'
import SidebarMini from './sidebar/SidebarMini'
import styled from 'styled-components'

const GridMain = styled.div`
  display: grid;
  grid-template-rows: 100vh;
  grid-template-columns: min-content 1fr;
  transition: all 0.3s ease-in;
`

const StyledMenuBar = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  overflow: hidden;
`

const StyledContent = styled.div`
  grid-row: 1/2;
  grid-column: 2/3;
`

const LayoutContainerView = styled.div`
  padding: ${({ top }) => top ? `${top + 20}px` : 0} 3rem 3rem;
  margin: 0 auto;
  min-height: 98vh;
  min-width: 100%;
  width: fit-content;
  transition: padding-left 0.3s;
`

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}

const Layout = ({ mainContent: MainContent }) => {
  const [currentTab, setCurrentTab] = useState('order hub')
  const [headerHeight, setHeaderHeight] = useState()
  const [documentWidth, setDocumentWidth] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)
  const [open, setOpen] = useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const headerRef = useRef(null)

  const { width, height } = useResizeObserver({
    ref: headerRef,
    box: 'border-box'
  })

  useLayoutEffect(() => {
    height && setHeaderHeight(height)
    width && setDocumentWidth(width)
  }, [height, width])

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowHeight(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    setWindowHeight(getWindowDimensions().height)
    return () => window.removeEventListener('resize', handleResize)
  }, [windowHeight])

  const sidebarHeight = windowHeight - headerHeight?windowHeight - headerHeight:0

  return (
    <>
      <Suspense fallback="Loading...">
        <Header
          ref={headerRef}
          isOpen={open}
          onOpen={handleDrawerOpen}
          onClose={handleDrawerClose}
        />
      </Suspense>
      <GridMain>
        <StyledMenuBar>
          <SidebarMini
            isOpen={open}
            currentTab={currentTab}
            top={headerHeight}
            docwidth={documentWidth}
            height={sidebarHeight}
          />
        </StyledMenuBar>
        <StyledContent>
          <LayoutContainerView top={headerHeight}>
            <MainContent />
          </LayoutContainerView>
        </StyledContent>
      </GridMain>
    </>
  )
}
Layout.propTypes = {
  // changeDataFilter: PropTypes.func,
  theme: PropTypes.string,
  toggleSideBarCollapse: PropTypes.func
}

export default Layout
