import React from 'react'
import { transformOptions } from '../../../../../utils/transformOptions'
import { SnowSelect, SnowMenuItem } from '../../../snowComponents'
import { ScFieldLabel } from '../../../LayoutComponents'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SelectFieldView = styled(SnowSelect)`
  .MuiSelect-root {
    border: none;
  }
  width: 100%;
  div {
    margin-top: 0;
  }
  fieldset {
    top: 0;
    display: none;
  }
  legend {
    display: none;
  }
  .MuiSelect-nativeInput {
    padding: 9px 10px;
    min-height: 40px;
    box-sizing: border-box;
  }
  label {
    display: none;
  }
`

const SelectItemView = styled(SnowMenuItem)`
  &.MuiMenuItem-root{
    color: ${({theme})=>theme.mainPalette.typography.main};
    &[aria-selected='true'] {
      background:  ${({theme})=>theme.mainPalette.grey.light} !important;
    }
  }
`

const ScDropdownField = ({
  label = '',
  labelPlacement = 'top',
  mb = 0,
  code = '',
  value = '',
  isInvalid = false,
  isGroupField = false,
  variant = 'outlined',
  inputWidth = 100,
  meta = {},
  updateDelay = 500,
  helperText = '',
  fontSize = '',
  onUpdate,
  ...props
}) => {
  console.log('ScDropdownField', {
    label,
    code,
    value,
    meta,
    props
  })

  //dropdown options can be tree types:
  // 1 - string list ['first','second',...etc] where item is value and label both
  // 2 - value-as-key objects list [{23:'Label1'},{'ZZ':'Label 2'},...etc]
  // 3 - formalized objects list [{"valueKey":value,"labelKey":label},...,{"valueKey":valueX,"labelKey":labelX}]

  const normalisedOptions = transformOptions(meta)
  const hasEmptyOption = normalisedOptions.find(({ value }) => value === '')

  return (
    <ScFieldLabel
      label={label}
      labelPlacement={labelPlacement}
      isGroupField={isGroupField}
      inputWidth={inputWidth}
      variant={variant}
      fontSize={fontSize}
      code={`select-${code}`}
      tooltipText={props.tooltip}
      mb={mb}
    >
      <SelectFieldView
        size={'small'}
        autoWidth={true}
        inputProps={{
          name: label,
          id: `select-${code}`,
          'data-testid': 'select',
          'aria-required': props.required
        }}
        disabled={props.disabled}
        required={props.required}
        error={isInvalid}
        displayEmpty
        labelId={`sc-dropdown-label-${code}`}
        value={value || ''}
        onChange={(evt) => {
          onUpdate({ value: evt.target.value })
        }}
      >
        {!hasEmptyOption && <SelectItemView value="">Not selected</SelectItemView>}
        {normalisedOptions.map((item, index) => {
          const { value, label } = item
          return (
            <SelectItemView key={index} value={value}>
              {label}
            </SelectItemView>
          )
        })}
      </SelectFieldView>
    </ScFieldLabel>
  )
}

export default ScDropdownField

ScDropdownField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'end']),
  isGroupField: PropTypes.bool,
  code: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  variant: PropTypes.oneOf(['standard', 'outlined']),
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meta: PropTypes.oneOfType([
    PropTypes.shape({
      options: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)
      ])
    }),
    PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.object),
      optionsDev: PropTypes.object
    })
  ]),
  updateDelay: PropTypes.number,
  mb: PropTypes.number,
  onUpdate: PropTypes.func
}
