import styled, { css } from 'styled-components';

const OrderPaperView = styled.section`
  ${({ theme, mb }) => css`
    padding: ${theme.spacing.secondary};
    margin-bottom: ${mb ? mb : theme.spacing.secondary};
    box-shadow: ${theme.shadow.main};
    :last-child{
      margin-bottom: 0;
    }
  `}
`

export default OrderPaperView