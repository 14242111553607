import React, { useContext } from 'react'
import ScList from '../widgets/ScList/ScList'
import { ScAddButton } from '../buttons'
import { SnowListItem, SnowTypography } from '../snowComponents'
import styled, { css, ThemeContext } from 'styled-components'

const ArrayListFieldsetView = styled.fieldset`
  box-shadow: ${({ theme, isempty }) => isempty ? theme.shadow.main : theme.shadow.secondary};
  border-radius: ${({ theme }) => theme.borderRadius.main};
`

const ArrayListLabelView = styled(SnowTypography).attrs({
  variant: 'h6',
  component: 'h4'
})`
  ${({ theme, isbutton }) => css`
    .MuiTypography-root{
      padding: 0 10px;
      ${isbutton ? `
        margin-right: ${theme.spacing.secondary};
      `: ''}
      font-size: ${theme.fontSize.middle};
      color: ${theme.mainPalette.color.contrast};
      font-weight: ${theme.fontWeight.thin};
      background: ${theme.mainPalette.color.main};
      border-radius: 10px;
    }
  `}
  display: inline-flex;
  align-items: center;
  
`

const ArrayListView = styled(ScList).attrs({
})`
  margin: -4px;
  padding: 10px 0 16px 0;
  border-radius: 5px;
  width: 100%;
  ul{
    width: 100%;
  }
`

const ArrayListItemView = styled(SnowListItem).attrs({
  component: 'div',
})`
  padding-bottom: 0;
  padding-top: 5px;
  margin: 4px;
  width: 100%;
  border-bottom: ${({ idx, theme }) => idx ? 'none' : `${theme.border.main}`};
  ul{
    width: 100%;
  }
`

const ArrayLabel = ({ label, onAddNew, hasItems, isAddButtonExist }) => {
  const theme = useContext(ThemeContext)

  return (
    <ArrayListLabelView isbutton={isAddButtonExist ? 1 : 0}>
      {label && (
        <SnowTypography
          component={'p'}
          isempty={!hasItems ? 1 : 0}
        >
          {hasItems ? label : `No ${label}`}
        </SnowTypography>
      )}
      {isAddButtonExist && (
        <ScAddButton onAdd={onAddNew} color={theme.mainPalette.color.main} noPadding />
      )}
    </ArrayListLabelView>
  )
}

const ScArrayList = ({
  items: arrayValueItems,
  label = '',
  editable,
  editState,
  onAddNew,
}) => {
  const hasItems = Boolean(arrayValueItems.length)
  const isAddButtonExist = (editable && editState && typeof onAddNew === 'function')

  return (
    <>
      {!hasItems ? (<ArrayLabel label={label} isAddButtonExist={isAddButtonExist} onAddNew={onAddNew} hasItems={hasItems} />) : (
        <ArrayListFieldsetView isempty={!hasItems ? 1 : 0}>
          <legend style={{ marginLeft: '8px' }}>
            <ArrayLabel label={label} isAddButtonExist={isAddButtonExist} onAddNew={onAddNew} hasItems={hasItems} />
          </legend>
          <ArrayListView>
            {arrayValueItems.map((itemValue, itemIdx) => {
              return (
                <ArrayListItemView key={itemIdx} idx={itemIdx === arrayValueItems?.length - 1 ? 1 : 0}>
                  {itemValue}
                </ArrayListItemView>
              )
            })}
          </ArrayListView>
        </ArrayListFieldsetView>
      )}
    </>
  )
}

export default ScArrayList
