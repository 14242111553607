// This file contains functions used in Order Grid -- functions to filter orders, WITHOUT using API calls

// function to filter orders by payment method
export const filterByPaymentMethod = (data, filter) => {
  const inputLength = filter.length
  // use inputLength to search from the beginning of the word, not just ANYWHERE in the word
  const filteredData = data.filter(
    (obj) =>
      obj.paymentMethod &&
      obj.paymentMethod.slice(0, inputLength).toLowerCase() ===
        filter.toLowerCase()
  )
  return filteredData
}

// function to filter orders by vendor status
export const filterByVendorStatus = (data, filter) => {
  const filteredData = []
  const inputLength = filter.length

  // loop through all orders
  data.forEach((orderObj) => {
    let orderObjIncludesVendorStatus = false

    // if vendor_orders is NOT empty
    if (orderObj.vendors.length > 0) {
      // for each order, loop through all vendors
      orderObj.vendors.forEach((vendorObj) => {
        // if ANY vendor status maches the search term...
        if (
          // use inputLength to search for matches from the BEGINNING of word
          vendorObj.status.toLowerCase().slice(0, inputLength) ===
          filter.toLowerCase()
        ) {
          orderObjIncludesVendorStatus = true
        }
      })
    } else {
      // if the search term matches 'N/A'...
      if (filter.toLowerCase() === 'n/a'.slice(0, inputLength)) {
        orderObjIncludesVendorStatus = true
      } else {
        return
      }
    }

    // ...then push it to the filtered data array
    if (orderObjIncludesVendorStatus) {
      filteredData.push(orderObj)
    }
  })
  return filteredData
}
