import React, { useContext } from 'react'
import { SnowSwapVertIcon } from '../../../../shared/components/icons'
import {
  SnowTableRow,
  SnowTableCell,
  SnowTypography
} from '../../../../shared/components/snowComponents'
import { ScIconButton } from '../../../../shared/components/buttons'
import { ThemeContext } from 'styled-components'

const ColumnTitles = (props) => {
  const theme = useContext(ThemeContext)
  const titleData = [
    { title: 'Order', button: null },
    { title: 'Store', button: null },
    {
      title: 'Date',
      button: (
        <ScIconButton size={30} onClick={props.toggleDateFilter}>
          <SnowSwapVertIcon />
        </ScIconButton>
      )
    },
    { title: 'Product', button: null },
    { title: '', button: null },
    { title: 'Customer', button: null },
    { title: 'Payment', button: null },
    { title: 'Vendor', button: null },
    { title: 'Status', button: null }
  ]
  return (
    <SnowTableRow>
      {titleData?.length
        ? titleData.map(({ title, button }, index) => (
            <SnowTableCell key={index}>
              <SnowTypography
                component="span"
                variant="body1"
                fontWeight={500}
                color={theme.mainPalette.typography.soDark}
              >
                {title}
              </SnowTypography>
              {button ? button : null}
            </SnowTableCell>
          ))
        : null}
    </SnowTableRow>
  )
}

ColumnTitles.propTypes = {
  // data: PropTypes.instanceOf(Array).isRequired,
}

export default ColumnTitles
