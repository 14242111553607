import React from 'react'
import { SnowBox, SnowPaper } from '../../snowComponents'

const SnowPageContentPaper = ({ children, isPaper = false, mb = '1em' }) => {
  const elevation = isPaper ? 2 : 0
  return (
    <SnowPaper
      aria-label={'SnowPageContentPaper'}
      elevation={elevation}
      style={{ marginBottom: mb, width: '100%' }}
    >
      <SnowBox px={2}>{children}</SnowBox>
    </SnowPaper>
  )
}

export default SnowPageContentPaper
