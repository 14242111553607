import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchSingleOrder } from '../../infrastructure/fetches'
import { cancelOrderItem } from '../../infrastructure/orderItemApi'
import PropTypes from 'prop-types'
import { OrderItemModalView } from './_styled'
import { SnowTypography } from '../../../../shared/components/snowComponents'

function itemIsCancelled(item) {
  return item.status === 'cancelled'
}

const itemIsComplete = (item) => {
  if (item.status === 'complete') {
    return true
  } else {
    return false
  }
}

const SingleProductCancel = (props) => {
  const navigate = useNavigate()
  const [selectToggle, setSelectToggle] = useState(false)

  // TODO: use this below for when state is lifted up and need to toggle select with multiple items
  // handleToggleSelect() {
  //   this.setState({selectToggle: !this.state.selectToggle});
  // }
  const fetchUpdatedOrder = (orderID, itemsCancelled) => {
    fetchSingleOrder({ orderID }).then((response) => {
      // push new order to OrderDetail state
      props.updateOrderInDetailState(response.data)
      // close modal
      props.toggleShowModal()
      console.log('sending items')
      console.log(itemsCancelled)
      props.toggleShowConfirmationModal({
        success: true,
        message: 'The item was successfully cancelled'
        // items: itemsCancelled,
      })
    })
  }

  const handleError = (data = '') => {
    console.error('SingleProductCancel handleError', data)
    props.toggleShowModal()
    props.toggleShowConfirmationModal({
      success: false,
      // need to update message from the error
      // message: data.error,
      message: 'Error in cancelling the item'
    })

    // TODO: Match the logout based on HTTP error code and not string match of status
    if (data.status === 'Token is Invalid') {
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }

  const cancelItem = (item) => {
    console.error('SingleProductCancel cancelItem', item)

    const itemId = item.id
    cancelOrderItem({ itemId }).then((data) => {
      if (data.success) {
        fetchUpdatedOrder(props.order.id, item)
        // window.location.reload();
        // alert("this page has reloaded");
      } else {
        console.log(data)
        handleError(data)
      }
    })
  }
  console.log('cancelItem props', props)
  return (
    // TODO: use this div below for when state is lifted up and need to toggle select with multiple items
    // <div id={props.item.id}
    //   className={`single-cancel-item__container btn ${this.state.selectToggle ? "selected-cancel-item" : null}`}
    //   onClick={() => this.handleToggleSelect()}>

    // to press an item and then cancel it with request - only handles one item and doesn't toggle
    <OrderItemModalView
      id={props.item.id}
      onClick={() => cancelItem(props.item)}
    >
      <SnowTypography
        component={'p'}
        variant={'body2'}
        gutterBottom={true}
        fontWeight={400}
        align={'center'}
      >
        Item: {props.item.id}
      </SnowTypography>
      <SnowTypography
        component={'p'}
        variant={'body2'}
        fontWeight={400}
        align={'center'}
      >
        {props.item.name}
      </SnowTypography>
    </OrderItemModalView>
  )
}

SingleProductCancel.propTypes = {
  item: PropTypes.shape().isRequired,
  order: PropTypes.shape().isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  updateOrderInDetailState: PropTypes.func.isRequired,
  toggleShowConfirmationModal: PropTypes.func.isRequired
}

export default SingleProductCancel
