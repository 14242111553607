import React from 'react'
import { SnowCheckBoxOutlineBlankIcon, SnowCheckBoxIcon } from '../../icons'
import styled from 'styled-components'

const CheckButtonView = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  svg {
    ${({ color }) => (color ? `fill: ${color};` : '')};
  }
`

const ScCheckButton = ({
  fontSize = 'large',
  checked = true,
  color = 'grey',
  onClick = () => 'checked'
}) => {
  return (
    <CheckButtonView
      className={'ScCheckButton'}
      type="button"
      color={color}
      onClick={onClick}
    >
      {checked ? (
        <SnowCheckBoxIcon fontSize={fontSize} />
      ) : (
        <SnowCheckBoxOutlineBlankIcon fontSize={fontSize} />
      )}
    </CheckButtonView>
  )
}

export default ScCheckButton
