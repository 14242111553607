import React from 'react'
import useAuthToken from '../../../domains/auth/hooks/useAuthToken'
import { apiRequest } from './apiDataRequestor'

export const prepareEntityCommandConfig = (entityId, cmdCfg) => {
  // console.log('useEntityCommand cfg', entityId, cmdCfg)

  /** function build domain-free URL string to call certain entity command
   *
   * @param entityUrl
   * @param entityId
   * @param entityCommand
   * @returns {string}
   */
  const buildEntityCommandUrl = (entityId, entityUrl, cmdUrl) => {
    const cmdPath = cmdUrl && `/${cmdUrl}`
    // console.log(
    //   'cfg entityId, entityUrl, cmdUrl',
    //   entityId,
    //   entityUrl,
    //   cmdUrl,
    //   cmdPath
    // )
    const url = entityId
      ? `${entityUrl}/${entityId}${cmdPath}`
      : `${entityUrl}${cmdPath}`
    // console.log('buildEntityCommandUrl', url)
    return url
  }

  // console.log('prepareEntityCommandConfig cmdCfg', cmdCfg)

  const entityUrl = cmdCfg?.parent?.url

  return (
    entityUrl && {
      ...cmdCfg,
      url: buildEntityCommandUrl(entityId, entityUrl, cmdCfg.url)
    }
  )
}

export const useEntityCommand = (
  entityId,
  cmdCfg,
  responseHandler = (response) => {
    console.debug('useEntityCommand dummy response handler', response)
    return response
  }
) => {
  const { getAuthToken } = useAuthToken()
  const token = getAuthToken()
  const cfg = {
    ...prepareEntityCommandConfig(entityId, cmdCfg),
    token
  }
  console.log('useEntityCommand cfg', entityId, cfg)
  const runCommand = (
    callback = (response) => {
      console.debug('runCommand dummy callback handler', response)
      return response
    }
  ) => {
    return new Promise((resolve, reject) => {
      try {
        apiRequest(cfg, (response) => {
          console.log('useEntityCommand apiRequest response', response)
          callback(responseHandler(response))
          resolve(response)
        })
      } catch (err) {
        console.log('useEntityCommand apiRequest reject', err)

        reject(err)
      }
    })
  }
  return runCommand
}
