import React, { useContext } from 'react'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import { SnowDoneAllIcon } from '../../../../shared/components/icons'
import { ReactComponent as ErrorIcon } from '../../../app/images/SVGs/error.svg'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'

import '../../../app/scss/component/order-detail-2.scss'

function getAction(order, part) {
  switch (order) {
    case 'holded_admin':
      switch (part) {
        case 1:
          return 'take Order Number'
        case 2:
          return "off of 'Hold'?"
        default:
          return ''
      }

    default:
      switch (part) {
        case 1:
          return 'put Order Number'
        case 2:
          return "on 'Hold'?"
        default:
          return ''
      }
  }
}

function getButtonText(status) {
  if (status === 'holded_admin') {
    return 'Un-Hold'
  } else {
    return 'Hold'
  }
}

function getMessage(response, order) {
  if (response.success && response.message) {
    if (response.message == 'The item was successfully cancelled') {
      return (
        <>
          {/* {response.message} <h3>{items.name}</h3> */}
          {response.message}
        </>
      )
    } else if (response.message != 'hold-message-success') {
      return (
        <>
          {response.message} <h3>{order.orderNumber}</h3>
        </>
      )
    } else if (order.orderhubStatus.includes('hold')) {
      return (
        <>
          Order Number <strong>{order.orderNumber}</strong> has been put on
          hold.
        </>
      )
    } else if (order.orderhubStatus.includes('cancelled')) {
      return (
        <>
          Order Number <strong>{order.orderNumber}</strong> has been cancelled.
        </>
      )
    } else {
      return (
        <>
          Order Number <strong>{order.orderNumber}</strong> is no longer on
          hold.
        </>
      )
    }
  } else {
    return (
      <>
        <p>{response.message}</p>
        {console.log('getMessage', response)}
        <SnowTypography component="p" variant="body1">
          You may be logged out in order to re-enter your account credentials.
        </SnowTypography>
      </>
    )
  }
}

const ConfirmationModal = (props) => {
  const theme = useContext(ThemeContext)
  const handleConfirmation = (order) => {}
  const status = props.order.orderhubStatus

  return (
    <ScConfirmationAlert
      isShowAttention={props.showModal}
      labelForCancelBtn="OK"
      onClose={props.toggleShowModal}
      icon={
        props.response.success ? (
          <SnowDoneAllIcon style={{ fill: theme.mainPalette.color.main }} />
        ) : (
          <ErrorIcon />
        )
      }
      message={{
        title: (
          <SnowTypography
            fontWeight={theme.fontWeight.bold}
            style={{ textTransform: 'uppercase' }}
          >
            {props.response.success ? 'success' : 'error'}
          </SnowTypography>
        ),
        content: (
          <SnowTypography align={'center'} variant={'body1'} component={'p'}>
            {getMessage(props.response, props.order, props.items)}
          </SnowTypography>
        )
      }}
    />
  )
}

ConfirmationModal.propTypes = {
  order: PropTypes.shape().isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleShowModal: PropTypes.func.isRequired,
  response: PropTypes.shape()
}

ConfirmationModal.defaultProps = {
  response: { success: true, message: 'default message' }
}

export default ConfirmationModal
