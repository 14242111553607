import ScCollapsibleTableRow from '../../shared/components/tables/ScCollapsibleTable/ScCollapsibleTableRow'
import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import useCommandWithAlert from './useCommandWithAlert'
import {
  useAreaGroupDetailsModel,
  useUpdatedAreaGroupDetails
} from './data/areaGroupDetails'
import AreaGroupSettings from './AreaGroupSettings'
import { useScMessage } from '../../shared/service/Message/data/currentMessageState'
import {
  useAreaGroupLoadedVendors,
  useAreaGroupUpdatedVendors
} from './data/loadedAreaGroupVendors2'

const responseHandler = (r, sendMessage, onSuccess, onFail) => {
  console.log('responseHanlder', r)
  if (r.messages && typeof sendMessage === 'function') {
    r.messages.map((msg) => {
      const msgToSend =
        typeof msg === 'string'
          ? msg
          : typeof msg?.type === 'string' &&
            typeof msg?.text === 'string' &&
            msg?.text
      const severity = typeof msg?.type === 'string' ? msg.type : 'info'
      sendMessage(msgToSend, severity)
    })
  }

  if (!r.error) {
    typeof onSuccess === 'function' && onSuccess(r?.data)
  } else if (typeof onFail === 'function') onFail(r)
}
const AreaGroupTableRow = ({ data, columns, onRemove, onUpdate, ...props }) => {
  const { areaId } = useParams()
  const { id, name } = data
  const [deleted, setDeleted] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const { send } = useScMessage()
  const { hasUpdate, createCmd, saveCmd, removeCmd } =
    useAreaGroupDetailsModel(id)

  const updatedAreaData = useUpdatedAreaGroupDetails(id)
  const [updatedVendors, saveUpdatedVendors] = useAreaGroupUpdatedVendors(id)

  const removeCmdWithCallback = () => {
    typeof removeCmd === 'function'
      ? removeCmd().then((r) =>
          responseHandler(r, send, () => {
            setDeleted(true)
          })
        )
      : setDeleted(true)

    onRemove()
  }
  const { modal: RemoveAlertModal, cmdWithAlert: removeWithAlert } =
    useCommandWithAlert(removeCmdWithCallback, {
      title: `Can't be undone!`,
      content: `Are you sure to remove AreaGroup ${name}`
    })

  const handleSave = () => {
    console.log('Wanna save AreaGroup', id, saveCmd)
    id
      ? typeof saveCmd === 'function' &&
        saveCmd().then((r) => responseHandler(r, send))
      : typeof createCmd === 'function' &&
        createCmd().then((r) =>
          responseHandler(r, send, (data) => {
            console.log('created data', data)
            onUpdate({ ...data, id })
          })
        )

    id &&
      updatedVendors.length &&
      typeof saveUpdatedVendors === 'function' &&
      saveUpdatedVendors().then(
        (r) => responseHandler(r, send),
        (data) => {
          console.log('saveUpdatedVendors response', data)
          // onUpdate({ ...data, id })
        }
      )
  }

  const handleRemove = () => {
    console.log('Wanna remove AreaGroup', removeCmd)
    removeWithAlert()
  }
  const handleTab = (tabId) => {
    setCurrentTab(tabId)
  }
  // console.log('updatedAreaData!!', {
  //   id,
  //   removeCmd,
  //   deleted,
  //   updatedVendors,
  //   updatedAreaData
  // })

  if (deleted) return null

  return (
    <>
      <RemoveAlertModal />
      <ScCollapsibleTableRow
        open={String(areaId) === String(id)}
        isNew={id === ''}
        data={{ ...data, ...updatedAreaData }}
        columns={columns}
        onEdit={({ id }) => {
          console.log('OnEdit', id)
        }}
        onSave={(hasUpdate || Boolean(updatedVendors.length)) && handleSave}
        onRemove={handleRemove}
        CollapsibleContent={({ data }) => (
          <>
            <AreaGroupSettings
              areaGroup={data}
              onTab={handleTab}
              currentTab={currentTab}
              // onInit={handleAreaSettingsInit}
            />
          </>
        )}
        {...props}
      />
    </>
  )
}
export default AreaGroupTableRow
