import React from 'react'
import { useRecoilValue } from 'recoil'
import { SnowBox } from '../../../../../shared/components/snowComponents'
import ScSpinner from '../../../../../shared/components/ScSpinner/ScSpinner'
import { integrationCqrsRequestModel } from '../../../data/integrationDeterminant'
import Error from '../../../../app/Error'
import SnowEntityCardsList from '../../../../../shared/components/widgets/SnowEntityCardsList'
import useProjects from '../../../hooks/useProjects'
import PropTypes from 'prop-types'

const ProjectChooser = ({
  dtoFields = ['id'],
  onConfirm = (dto) => {
    console.log(dto)
  },
  onItemSelect = (item) => {
    console.log('ProjectChooser onItemSelect', item)
  }
}) => {
  const integrationParams = useRecoilValue(integrationCqrsRequestModel)
  const selectedProject = integrationParams.project ?? 0

  const { data: projects, isLoading, loadingError, ...rest } = useProjects()

  console.log('IntegrationParams', integrationParams, projects, rest)

  const onItemConfirmed = (item) => {
    console.log('ProjectChooser onProjectSelect', item)
    const dtoObj = dtoFields.reduce(
      (dto, fieldName) => ({ ...dto, [fieldName]: item[fieldName] }),
      {}
    )
    console.log(
      'ProjectChooser onProjectConfirmed dto',
      item,
      dtoFields,
      dtoObj
    )

    onConfirm && onConfirm(dtoObj)
  }

  return (
    <SnowBox p={1}>
      {loadingError && <Error error={loadingError}/>}
      {isLoading && !projects.length ? (
        <ScSpinner/>
      ) : (
        <SnowEntityCardsList
          data={projects ?? []}
          onItemSelect={onItemSelect}
          onItemConfirmed={onItemConfirmed}
          selectedItem={selectedProject}
        />
      )}
    </SnowBox>
  )
}
ProjectChooser.propTypes = {
  dtoFields: PropTypes.array,
  onConfirm: PropTypes.func
}

export default ProjectChooser
