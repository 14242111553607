import React from 'react'
import { SnowBox, SnowGrid } from '../../snowComponents'

const ScActionsBar = ({ children, justifyContent = 'flex-start', ...rest }) => {
  console.log('ScActionBar', { children, rest })
  const _children = Array.isArray(children) ? children : [children]

  return (
    <SnowBox p={rest.noPaddings ? 0 : 2} aria-label={'ScActionsBar'}>
      <SnowGrid
        container
        justifyContent={justifyContent}
        spacing={1}
        alignItems={'center'}
      >
        {_children.map((ActionElement, i) =>
          ActionElement ? (
            <SnowGrid key={i} item>
              {ActionElement}
            </SnowGrid>
          ) : null
        )}
      </SnowGrid>
    </SnowBox>
  )
}

export default ScActionsBar
