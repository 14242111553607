import React, { useContext } from 'react'
import {
  SnowListItem,
  SnowTypography
} from '../../../../../shared/components/snowComponents'
import { SnowWarningIcon } from '../../../../../shared/components/icons'
import { useCurrentOrder } from '../../data/currentOrderState'
import ErrorDetail from './components/ErrorDetail'
import {
  OrderErrorsView,
  OrderErrorsHeaderView,
  OrderErrorsListView,
  OrderErrorsDetailItemView
} from './_styled'
import { ThemeContext } from 'styled-components'
import PropTypes from 'prop-types'

const getIndividualError = (item) => {
  if (Object.keys(item.errors).length > 0) {
    const firstKey = Object.keys(item.errors)[0]
    return item.errors[firstKey]
  }
}

const getItemsError = (item) => {
  if (item.errors === null) {
    return 0
  } else {
    console.log(item.errors)
    return Object.keys(item.errors).length
  }
}

const ErrorOutput = ({ errorValue }) => {
  // const [errorType, errorValue] = errorPair
  const theme = useContext(ThemeContext)

  const errorDescription =
    typeof errorValue === 'string' || !errorValue
      ? [String(errorValue)]
      : (Array.isArray(errorValue) &&
          errorValue.map((error) => ErrorOutput(error))) ||
        'Unknown error'
  console.log('errorDescription', errorDescription)
  return (
    <SnowTypography
      variant={'inherit'}
      component={'span'}
      fontWeight={theme.fontWeight.bold}
      color={theme.mainPalette.warning.secondary}
    >
      {errorDescription.join(', ')}
    </SnowTypography>
  )
}

const OrderErrors = React.forwardRef((props, ref) => {
  const theme = useContext(ThemeContext)
  const orderModel = useCurrentOrder()
  const vendorsWithError = orderModel.vendors.filter(
    ({ errors }) => errors.length !== 0
  )
  console.log('OrderErrors ', orderModel, vendorsWithError)

  return (
    <OrderErrorsView ref={ref}>
      <OrderErrorsHeaderView>
        <SnowWarningIcon width="32" />
        <SnowTypography
          variant={'body1'}
          component={'h3'}
          fontWeight={theme.fontWeight.bold}
        >
          Order Errors
        </SnowTypography>
      </OrderErrorsHeaderView>
      <OrderErrorsListView>
        {orderModel.orderItems.length > 0 ? (
          orderModel.orderItems.map((item, idx) =>
            getItemsError(item) > 0 ? (
              <SnowListItem dense={true} key={idx}>
                <ErrorDetail item={item} />
              </SnowListItem>
            ) : null
          )
        ) : (
          <SnowListItem dense={true}>N/A</SnowListItem>
        )}
        {orderModel.errors &&
          Object.entries(orderModel.errors).map(([key, value]) => {
            console.log('orderModel.error', key, value)
            return (
              <OrderErrorsDetailItemView key={key}>
                <SnowTypography
                  component={'p'}
                  variant={'body2'}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <SnowTypography variant={'inherit'} component={'span'}>
                    {key}:
                  </SnowTypography>
                  <ErrorOutput errorValue={value} />
                </SnowTypography>
              </OrderErrorsDetailItemView>
            )
          })}
        {vendorsWithError.map((vendorObj, idx) => (
          <OrderErrorsDetailItemView key={idx}>
            <ErrorDetail vendorObj={vendorObj} />
          </OrderErrorsDetailItemView>
        ))}
      </OrderErrorsListView>
    </OrderErrorsView>
  )
})
OrderErrors.displayName = 'OrderErrors'
OrderErrors.propTypes = {
  order: PropTypes.shape()
}

export default OrderErrors
