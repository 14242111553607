import React, { useEffect, useRef, useState } from 'react'
import { SnowList, SnowListItem } from '../../../snowComponents'
import { ScListLabel } from '../../../LayoutComponents'
import { ScTextField, ScComboField } from '../../baseFields'
import { ScClearButton, ScFabButton } from '../../../buttons'
import {
  pairsToObj,
  removeArrayItem,
  replaceArrayItem
} from '../../../../../utils/helpers'
import { useMapType } from '../../hooks/useMapType'
import { transformOptions } from '../../../../../utils/transformOptions'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const _ = require('lodash')

const FlatMapFieldView = styled.fieldset`
  ${({ theme }) => `
    padding: ${theme.spacing.secondary};
    border-radius: ${theme.borderRadius.main};
    border: ${theme.border.main};
  `}
`

const FlatMapLegendView = styled.legend`
  width: auto;
`

const FlatMapListView = styled(SnowList)`
  &.MuiList-padding {
    padding: 0;
  }
`

const FlatMapListItemView = styled(SnowListItem)`
  margin-bottom: 13px;
  :last-child {
    margin-bottom: 0px;
  }
  &.MuiListItem-gutters {
    padding: 0;
  }
  &.MuiListItem-root {
    width: 100%;
    display: grid;
    align-items: center;
    grid-template-rows: min-content;
    grid-template-columns: 1fr 1fr 48px;
  }
`

const FlatMapKeyFieldView = styled.div`
  padding-right: 7px;
  grid-row: 1/2;
  grid-column: 1/2;
`

const FlatMapValueFieldView = styled.div`
  padding-left: 7px;
  grid-row: 1/2;
  grid-column: 2/3;
`

const FlatMapClearButtonBoxView = styled.div`
  margin-top: 4px;
  grid-row: 1/2;
  grid-column: 3/4;
`

const _getAvailableOptions = (keyVal, values, meta) => {
  const valueKeys = values?.length ? values.map((val) => val[0]) : []
  valueKeys.filter((val) => val !== keyVal)
  return _.omit(meta?.options, valueKeys)
}

const ScFlatCustomValuesMapField = ({
  valueRenderer = null,
  fixedKeys = false,
  readonlyKeys = false,
  isGroupField = false,
  label = '',
  keyLabel = '',
  valueLabel = '',
  addButtonLabel = '',
  labelPlacement = 'top',
  inputWidth = '100',
  code = '',
  value = {}, //mapObject
  required = false,
  onUpdate,
  updateDelay = 500,
  onError,
  meta = {},
  variant = 'outlined',
  alertOnNewValue = true,
  focusedOn, //used for nested map
  viewOnly = false,
  ...props
}) => {
  const mapObject = { ...value } ?? {}
  const keyOptions = transformOptions(meta)
  // const getAvailableOptions = useCallback(
  //   (mapObject) =>
  //     keyOptions.filter((option) => !Object.keys(mapObject).includes(option)),
  //   [mapObject]
  // )
  // console.log('ScFlatValuesMapField', code, value, mapObject, options)

  const originMapArray = Object.entries(mapObject)
  const [mapArray, setMapArray] = useState(originMapArray)
  const [showList, setShowList] = useState(!!originMapArray?.length)
  console.log(
    'ScFlatCustomValuesMapField mapArray',
    mapArray,
    '\nincomeValue',
    value,
    '\nincomeMeta',
    meta
  )

  const [availableOptions, setAvailableOptions] = useState(
    _getAvailableOptions(mapObject, meta)
  )

  const { canAddNew, showNewPair, isValidValue, isInvalidKey } = useMapType({
    code,
    label,
    onError
  })
  const currentPosition = useRef()

  if (focusedOn) currentPosition.current = focusedOn

  const showNewItem = () => {
    showNewPair(true)
    currentPosition.current = [mapArray.length, 0]
  }

  const [newIndexes, setNewIndexes] = useState([])

  useEffect(() => {
    if (!value) return

    console.log('value changed', value, mapArray)
    const mapObject = value ?? {}
    setMapArray(Object.entries(mapObject))
  }, [value])

  useEffect(() => {
    console.log('ScFlatCustomValuesMapField meta', meta, '\nmetaAvailable', {
      options: _getAvailableOptions('', mapArray, meta)
    })
  }, [mapArray])

  const handleUpdate = ([row, col], val) => {
    showNewPair(false)
    currentPosition.current = [row, col]
    console.log('update at position', { val, mapArray })
    const isNewItem = row === mapArray.length
    isNewItem && setNewIndexes([...newIndexes, row])
    const updatedMapArray = isNewItem
      ? [...mapArray, [col === 0 ? val : '', col === 1 ? val : '']]
      : replaceArrayItem(
          mapArray,
          row,
          replaceArrayItem(mapArray[row], col, val)
        )
    const isValid = isValidValue(updatedMapArray)

    isValid
      ? onUpdate({
          value: pairsToObj(updatedMapArray),
          position: [row, col]
        })
      : setMapArray(updatedMapArray)
  }

  const handleRemove = (idx) => {
    const newArray = removeArrayItem(mapArray, idx)
    console.log('handleClear', idx, newArray)
    newArray?.length === 0 && setShowList(false)
    onUpdate({
      value: pairsToObj(newArray),
      position: [0, 0]
    })
  }

  const handleShowNewList = () => {
    setShowList(true)
    setMapArray([''])
  }

  //if we have fixed keys field and map line idx isn't new map line fields
  const keyIsImmutable = (idx) => fixedKeys && !newIndexes.includes(idx)

  const keyIsReadonly = () => readonlyKeys

  const pairIsFocused = (idx) => {
    return currentPosition.current && idx === currentPosition.current[0]
  }

  const keyIsFocused = (idx) =>
    pairIsFocused(idx) && currentPosition.current[1] === 0

  const valueIsFocused = (idx) =>
    pairIsFocused(idx) && currentPosition.current[1] === 1

  console.debug('ScFlatCustomValuesMapField!', {
    value,
    valueRenderer,
    mapObject,
    mapArray,
    fixedKeys,
    meta
  })
  console.log('Meta, AvailableOptions', meta, {
    options: _getAvailableOptions('', mapArray, meta)
  })

  return mapArray?.length || showList ? (
    <ScListLabel
      code={`${code}-value-0`}
      label={label}
      labelPlacement={labelPlacement}
      isGroupField={isGroupField}
    >
      <FlatMapFieldView>
        <FlatMapLegendView>
          {!readonlyKeys && canAddNew && mapArray[0] !== '' && (
            <ScFabButton label={addButtonLabel} onClick={showNewItem} />
          )}
        </FlatMapLegendView>
        <FlatMapListView className="flat-map-list">
          {Array.isArray(mapArray) &&
            mapArray.map(([hashKey, hashValue], idx) => (
              <FlatMapListItemView key={idx}>
                <FlatMapKeyFieldView>
                  {
                    <ScComboField
                      code={`${code}-key-${idx}`}
                      // free={!fixedKeys}
                      free={false}
                      value={hashKey}
                      {...(isInvalidKey(hashKey) && { isInvalid: true })}
                      meta={meta}
                      // meta={{ options: _getAvailableOptions(hashKey, mapArray, meta) }}
                      label={keyLabel}
                      variant={variant}
                      updateDelay={500}
                      disabled={keyIsImmutable(idx) || readonlyKeys}
                      onUpdate={({ value }) => handleUpdate([idx, 0], value)}
                      focused={keyIsFocused(idx) && !keyIsImmutable(idx)}
                      selected={keyIsFocused(idx)}
                    />
                    // )
                  }
                </FlatMapKeyFieldView>
                <FlatMapValueFieldView>
                  {valueRenderer ? (
                    valueRenderer(hashKey, ({ value }) =>
                      handleUpdate([idx, 1], value)
                    )
                  ) : (
                    <ScTextField
                      code={`value-${idx}`}
                      value={hashValue}
                      meta={meta}
                      label={valueLabel}
                      updateDelay={500}
                      variant={variant}
                      onUpdate={({ value }) => handleUpdate([idx, 1], value)}
                      focused={valueIsFocused(idx)}
                    />
                  )}
                </FlatMapValueFieldView>
                {!readonlyKeys && (
                  <FlatMapClearButtonBoxView>
                    <ScClearButton onClear={() => handleRemove(idx)} />
                  </FlatMapClearButtonBoxView>
                )}
              </FlatMapListItemView>
            ))}
          {!canAddNew && (
            <FlatMapListItemView>
              <FlatMapKeyFieldView>
                {
                  //   fixedKeys ? (
                  //   <ScDropdownField
                  //     options={availableOptions}
                  //     code={`key-new`}
                  //     value={''}
                  //     label={keyLabel}
                  //     meta={meta}
                  //     variant={variant}
                  //     updateDelay={500}
                  //     onUpdate={({ value }) =>
                  //       handleUpdate([mapArray.length, 0], value)
                  //     }
                  //     focused={true}
                  //   />
                  // ) : (
                  <ScComboField
                    code={`key-new`}
                    // free={!fixedKeys}
                    free={false}
                    value={''}
                    meta={meta}
                    // meta={{ options: _getAvailableOptions('', mapArray, meta) }}
                    label={keyLabel}
                    updateDelay={updateDelay}
                    variant={variant}
                    disabled={readonlyKeys}
                    onUpdate={({ value }) =>
                      handleUpdate([mapArray.length, 0], value)
                    }
                    focused={true}
                  />
                  // )
                }
              </FlatMapKeyFieldView>
              <FlatMapValueFieldView>
                {valueRenderer ? (
                  valueRenderer('', ({ value }) =>
                    handleUpdate([mapArray.length, 1], value)
                  )
                ) : (
                  <ScTextField
                    code={`value-new`}
                    value={''}
                    label={keyLabel}
                    meta={meta}
                    variant={variant}
                    updateDelay={updateDelay}
                    onUpdate={({ value }) =>
                      handleUpdate([mapArray.length, 1], value)
                    }
                  />
                )}
              </FlatMapValueFieldView>
            </FlatMapListItemView>
          )}
        </FlatMapListView>
      </FlatMapFieldView>
    </ScListLabel>
  ) : (
    !readonlyKeys && canAddNew && (
      <ScFabButton label={addButtonLabel} onClick={handleShowNewList} />
    )
  )
}

export default ScFlatCustomValuesMapField

ScFlatCustomValuesMapField.propTypes = {
  fixedKeys: PropTypes.bool,
  readonlyKeys: PropTypes.bool,
  label: PropTypes.string,
  addButtonLabel: PropTypes.string,
  keyLabel: PropTypes.string,
  valueLabel: PropTypes.string,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'end']),
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code: PropTypes.string,
  value: PropTypes.object,
  required: PropTypes.bool,
  onUpdate: PropTypes.func,
  updateDelay: PropTypes.number,
  onError: PropTypes.func,
  meta: PropTypes.object,
  variant: PropTypes.oneOf(['standard', 'outlined']),
  alertOnNewValue: PropTypes.bool,
  viewOnly: PropTypes.bool,
  focusedOn: PropTypes.bool,
  valueRenderer: PropTypes.elementType,
  isGroupField: PropTypes.bool
}
