import React from 'react'
import styled from 'styled-components'

const StyledPanel = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  padding: 10px;
  p {
    line-height: 1.2em;
  }
`

// messages: array of {type:'error|success', text:'msg'}
const ScMessagesPanel = ({ messages = [] }) => {
  if (!messages?.length) return null

  return (
    <StyledPanel>
      {messages.map(({ type, text }, idx) => {
        return (
          <p key={idx} className={type === 'error' ? 'error-message' : ''}>
            {text}
          </p>
        )
      })}
    </StyledPanel>
  )
}

export default ScMessagesPanel
