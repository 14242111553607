import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ScEditableVirtualizedTable, {
  getFilterFromSearchParams
} from '../../shared/components/tables/ScEditableVirtualizedTable/ScEditableVirtualizedTable'
import ScSpinner from '../../shared/components/ScSpinner/ScSpinner'
import { useOrderItems } from './useOrderItems'
import ScBulkActionsBar from './ScBulkActionsBar'
import { ScDirectionButton } from '../../shared/components/buttons'
import { ScPageContentPaper, ScPageContent, ScActionsBar } from '../../shared/components/LayoutComponents'
import { useOrderItemsGetAvailableStatusesCmd } from './infrastructure/orderItemsApi'
import { useFetchCountriesCmd } from '../area/infrastructure/areaApi'

export const oiFieldsDescription = {
  id: {
    code: 'id',
    title: 'ID',
    visible: false,
    editType: 'text'
  },
  order_number: {
    code: 'order_number',
    title: 'Order #',
    visible: true,
    editType: 'text',
    minWidth: 10,
    filterable: true
  },
  order_platform_created_at: {
    code: 'order_platform_created_at',
    title: 'Order Created At',
    visible: true,
    editType: 'date',
    filterable: true,
    minWidth: 21
  },
  sku: {
    code: 'sku',
    title: 'Sku',
    visible: true,
    editType: 'text',
    minWidth: 19,
    filterable: true
  },
  name: {
    code: 'name',
    title: 'Name',
    visible: true,
    editType: 'text',
    minWidth: 30,
    maxWidth: 30,
    filterable: false
  },
  country_code: {
    code: 'country_code',
    title: 'Country',
    visible: true,
    editType: 'combo',
    minWidth: 5,
    maxWidth: 10,
    useOptionsFetcher: useFetchCountriesCmd,
    filterable: true
  },
  marketplace_name: {
    code: 'marketplace_name',
    title: 'Channel',
    visible: true,
    editType: 'text',
    minWidth: 15,
    maxWidth: 40,
    filterable: false
  },
  market_vendor: {
    code: 'market_vendor',
    title: 'Channel Vendor',
    visible: true,
    editType: 'text',
    minWidth: 14,
    filterable: true
  },
  vendor: {
    code: 'vendor',
    title: 'vendor',
    visible: true,
    editType: 'text',
    minWidth: 14,
    filterable: true

  },
  order_id: {
    code: 'order_id',
    title: 'order_id',
    visible: false,
    editType: 'text',
    minWidth: 12
  },
  item_status: {
    code: 'item_status',
    title: 'Status',
    visible: true,
    editType: 'dropdown',
    useOptionsFetcher: useOrderItemsGetAvailableStatusesCmd, //? to think about use field options source/fetcher
    minWidth: 15,
    filterable: true
  },

  earliest_ship_date: {
    code: 'earliest_ship_date',
    title: `Earliest 
    Ship Date`,
    visible: true,
    editType: 'date',
    filterable: true,
    minWidth: 21
  },

  latest_ship_date: {
    code: 'latest_ship_date',
    title: `Latest 
    Ship Date`,
    visible: true,
    editType: 'date',
    filterable: true,
    minWidth: 21
  },
  earliest_delivery_date: {
    code: 'earliest_delivery_date',
    title: `Earliest 
    Delivery Date`,
    visible: true,
    editType: 'date',
    filterable: false,
    minWidth: 15
  },
  latest_delivery_date: {
    code: 'latest_delivery_date',
    title: `Latest 
    Delivery Date`,
    visible: true,
    editType: 'date',
    filterable: false,
    minWidth: 21
  }
}
const isJSON = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

const useOrderItemFields = (fieldsDescription) => {
  const [fields, setFields] = useState(fieldsDescription ?? {})
  const [statuses, setStatuses] = useState(null)
  const [countries, setCountries] = useState(null)

  const updateAvailableStatusesCmd = useOrderItemsGetAvailableStatusesCmd(
    null,
    (r) => {
      console.log('getStatusesCmd onSuccess r', r)
      const { statuses } = r
      setStatuses(statuses)
    },
    (r) => {
      console.log('getStatusesCmd onFail r', r)
    }
  )

  const updateCountriesOptionsCmd = useFetchCountriesCmd(
    {},
    (r) => {
      console.log('getCountriesCmd onSuccess r', r)
      const { data } = r

      Array.isArray(data) && setCountries(data.reduce((acc, { code, name }) => ({ ...acc, [code]: name }), {}))

    },
    (r) => {
      console.log('getCountriesCmd onFail r', r)
    }
  )

  useEffect(() => {
    if (!statuses) return
    const { item_status } = fieldsDescription
    const updatedStatusField = item_status && { ...item_status, options: [' ', ...statuses] }
    setFields((fields) => ({ ...fields, item_status: updatedStatusField }))

  }, [statuses])

  useEffect(() => {
    if (!countries) return
    const { country_code } = fieldsDescription
    const updatedCountryField = country_code && { ...country_code, options: countries }
    setFields((fields) => ({ ...fields, country_code: updatedCountryField }))
  }, [countries])

  useEffect(() => {
    updateAvailableStatusesCmd()
    updateCountriesOptionsCmd()
  }, [])
  return fields
}

const OrderItemsHub = () => {
  const [searchParams] = useSearchParams()
  const orderItemFields = useOrderItemFields(oiFieldsDescription)
  console.log('OrderItemsHub orderItemFields', orderItemFields)
  const filterableFieldKeyList = Object.keys(oiFieldsDescription).filter(
    (key) => oiFieldsDescription[key]?.filterable === true
  )

  const [filters, setFilters] = useState(
    getFilterFromSearchParams(filterableFieldKeyList, searchParams)
  )

  const {
    items,
    isLoading,
    loadMoreBtn: LoadMoreBtn,
    loadRestBtn: LoadRestBtn,
    selectedIds,
    totalFound,
    toggleSelectedCmd,
    selectItemsCmd
  } = useOrderItems({ filters })

  const navigate = useNavigate()

  const onCrudEvent = (...params) => {
    console.log('onCrudEvent', ...params)
  }
  const onFilterChange = (name, value) => {
    console.log('OrderItemsHub onFilterChange', name, value)
    setFilters((filters) => ({ ...filters, [name]: value })) //Map as alternative to destruction
  }
  const handleFilterClear = () => {
    console.log('OrderItemsHub handleFilterClear')
    setFilters((filters) =>
      Object.keys(filters).reduce((acc, key) => {
        return { ...acc, [key]: '' }
      }, {})
    ) //Map as alternative to destruction
  }
  console.log('orderItems!!', { filters, orderItemFields })

  return (
    <ScPageContent>
      <ScBulkActionsBar
        selectedIds={selectedIds}
        bulkCount={totalFound}
        filters={filters}
        clearFilters={handleFilterClear}
      />

      <ScPageContentPaper>
        <ScEditableVirtualizedTable
          editable={false}
          // onCrudEvent={onCrudEvent}
          onRowClick={toggleSelectedCmd}
          onBulkSelect={selectItemsCmd}
          fields={orderItemFields}
          filters={filters}
          dataRows={items}
          onFilterChange={onFilterChange}
          autoFilter={false}
          contentAdaptiveColumnsWidth={false}
          customRowActions={(item) => (
            <ScDirectionButton tooltip={`Go to parent order`}
              onClick={() =>
                navigate(`/home/order_hub/order_details/${item.order_id}`)}
            />
          )}
        />
        {isLoading && <ScSpinner centered />}
        <ScActionsBar borderTop>
          <LoadMoreBtn />
          <LoadRestBtn />
        </ScActionsBar>
      </ScPageContentPaper>
    </ScPageContent>
  )
}

export default OrderItemsHub
