import React, { useEffect, useState } from 'react'
import {
  SnowTypography,
  SnowListItem,
  SnowDivider
} from '../../../../../shared/components/snowComponents'
import { fetchCustomerOrderCount } from '../../../infrastructure/fetches'
import ShippingMethod from './ShippingMethod'
import CustomerAddress from './CustomerAddress'
import PropTypes from 'prop-types'
import { SidebarSubtitleView, SidebarPaperView } from './_styled'

const URL = 'https://orderhub.snowaxis.com'

// TODO: fix url call to use environment urls

export function getGoogleMapsURL(address) {
  let url = 'https://www.google.com/maps/place/'
  if (address.street1) {
    url = url.concat(address.street1)
  }
  // don't include street address lines other than street1 (because google maps
  // cant look up apartment units, etc)

  if (address.city) {
    url = url.concat(`,${address.city}`)
  }
  if (address.state) {
    url = url.concat(`,${address.state}`)
  }
  if (address.postal_code) {
    url = url.concat(`,${address.postal_code}`)
  }
  return url
}

const CustomerInformation = ({ order }) => {
  console.log('CustomerInformation', { order })
  const [customerOrders, setCustomerOrders] = useState(null)

  const getTotalCustomerOrders = (customerEmail) => {
    let orderTotal = 1
    let ordersNoun = ''

    fetchCustomerOrderCount({ customerEmail }).then((data) => {
      console.log('CustomerInformation getTotalCustomerOrders data', data)

      orderTotal = data.count
      if (orderTotal === 1) {
        ordersNoun = 'order'
      } else {
        ordersNoun = 'orders'
      }
      orderTotal
        ? setCustomerOrders(`${orderTotal} ${ordersNoun}`)
        : setCustomerOrders(`No orders`)
    })
  }

  useEffect(() => {
    setCustomerOrders(getTotalCustomerOrders(order.customerEmail))
  }, [order])
  if (!order) return null

  return (
    <SidebarPaperView>
      {order.customerName ? (
        <SidebarSubtitleView>{order.customerName}</SidebarSubtitleView>
      ) : null}
      <SnowListItem component={'div'} disableGutters={true} dense={true}>
        {customerOrders}
      </SnowListItem>
      <SnowDivider />
      <SidebarSubtitleView>Contact Information</SidebarSubtitleView>
      <SnowListItem component={'div'} disableGutters={true} dense={true}>
        <SnowTypography component={'p'} variant="inherit">
          {order.customerEmail}
        </SnowTypography>
      </SnowListItem>
      <SnowDivider />
      <CustomerAddress order={order} />
      <SnowDivider />
      <ShippingMethod order={order} />
    </SidebarPaperView>
  )
}

CustomerInformation.propTypes = {
  order: PropTypes.shape().isRequired
}

export default CustomerInformation
