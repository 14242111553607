import React, { useRef, useState, useContext } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  SnowBox,
  SnowGrid,
  SnowPopper,
  SnowTypography,
  SnowPaper
} from '../../../../../../../../../shared/components/snowComponents'
import { SnowImageIcon } from '../../../../../../../../../shared/components/icons'
import { ScTextField } from '../../../../../../../../../shared/components/SnowEditableField/baseFields'
import {
  ScCrudButton,
  ScWhiteButton
} from '../../../../../../../../../shared/components/buttons'
import { useSetOrderItemPersonalizationImageCmd } from '../../../../../../../infrastructure/orderItemApi'
import { currentMessageState } from '../../../../../../../../../shared/service/Message/data/currentMessageState'
import styled, { ThemeContext } from 'styled-components'

const StyledPaper = styled(SnowPaper)`
  width: 33em;
`

function SetupPersonalization({ orderItem, persUrl = '', onSetup }) {
  const theme = useContext(ThemeContext)
  const ref = useRef()
  const [showDlg, setShowDlg] = useState(false)
  const [imageUrl, setImageUrl] = useState(persUrl)
  const [error, setError] = useState('')
  const setMessage = useSetRecoilState(currentMessageState)
  const savePersonalization = useSetOrderItemPersonalizationImageCmd(
    { entityId: orderItem.id },
    { imageUrl: imageUrl },
    (resp) => {
      console.log('imageUrl', resp, imageUrl)
      if (!resp.error && resp.data && resp?.data?.success !== 'false') {
        onSetup({ personalizations: resp.data?.customizations, error: '' })
        setShowDlg(false)
        // window.location.reload()
      } else if (resp?.error || resp?.data?.success === 'false')
        setMessage({
          message: resp?.data?.messages?.[0]?.text,
          severity: 'error'
        })
    }
  )

  console.log(savePersonalization)
  const handleUrlUpdate = ({ value }) => {
    setImageUrl(value)
  }
  // const handleFormatUpdate = ({ value }) => {
  //   setImageFormat(value)
  // }
  const handlePersonalizationSave = () => {
    console.log('orderItem.id', orderItem.id)
    const image = new Image()
    image.onload = function (e) {
      savePersonalization()
      console.log('valid image', e)
    }
    image.onerror = function () {
      setError('Invalid image')
    }
    image.src = imageUrl
  }
  console.log('showRaw', showDlg)
  return (
    <>
      <ScWhiteButton
        icon={() => <SnowImageIcon />}
        label={'Set up personalizationInfo'}
        ref={ref}
        onClick={() => setShowDlg(!showDlg)}
      />
      <SnowPopper
        open={showDlg}
        placement={'bottom-start'}
        anchorEl={ref.current}
        keepMounted={false}
      >
        <StyledPaper>
          <SnowBox p={2}>
            <SnowGrid container>
              <ScTextField
                value={imageUrl}
                code={'personalization_image_url'}
                label={'URL of personalizationInfo image'}
                required={true}
                onUpdate={handleUrlUpdate}
              />
              <ScCrudButton
                color={theme.mainPalette.color.secondary}
                editMode={true}
                onSave={handlePersonalizationSave}
              />
            </SnowGrid>
            {!!error && <SnowTypography>{error}</SnowTypography>}
          </SnowBox>
        </StyledPaper>
      </SnowPopper>
    </>
  )
}

export default SetupPersonalization
