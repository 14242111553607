import { fetchProjects } from '../infrastructure/v1/apis'
import {
  atom,
  atomFamily,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue
} from 'recoil'
import { replaceArrayItem } from '../../../utils/helpers'

const loadingStateAtom = atomFamily({
  key: 'loadingState',
  default: (loadingEntityName) => ({ isLoading: false, loadingError: false })
})

const loadedProjectsAtom = atom({
  key: 'loadedProjectsAtom',
  default: selector({
    key: 'projectsLoaderSelector',
    get: async ({ get }) => {
      const { data: projects, ...serviceInfo } = await fetchProjects({
        clientId: 0
      })
      return Array.isArray(projects) ? projects : []
    }
  })
})

export const projectWithId = selectorFamily({
  key: 'projectWithId',
  get:
    (projectId) =>
    ({ get }) =>
      get(loadedProjectsAtom).find(({ id }) => +id === +projectId) ?? {},
  set:
    (projectId) =>
    ({ set, get }, newValue) => {
      const projects = get(loadedProjectsAtom)
      const index = projects.findIndex(({ id }) => +id === +projectId)
      set(loadedProjectsAtom, [...replaceArrayItem(projects, index, newValue)])
    }
})

export const useProjects = (clientId = '0', searchStr = '') => {
  const loadedProjects2 = useRecoilValue(loadedProjectsAtom)
  const [loadingState, setLoadingState] = useRecoilState(
    loadingStateAtom('projects')
  )

  console.log('useProjects', loadedProjects2, loadingState)

  return { data: loadedProjects2, ...loadingState }
}

export default useProjects
