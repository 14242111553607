import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { ScDropdownField, ScSwitchField } from "../../../shared/components/SnowEditableField/baseFields";
import { ScPageContentPaper } from "../../../shared/components/LayoutComponents";
import {
  useOrderItemsBulkUpdateActionCmd
} from "../infrastructure/orderItemsApi";
import { useScMessage } from "../../../shared/service/Message/data/currentMessageState";
import { removeEmptyProps } from "../../../utils/helpers";
import ScEditableFieldsTree from "../../../shared/components/SnowEditableField/ScEditableFieldsTree";
import { useOrderItemsEditableFields } from "./useOrderItemsEditableFields";


const prepareDateRange = (dateRange) => {
  const [start, end] = dateRange;
  console.log("prepareDateRange", start, end, typeof end);
  return { start, end: end || new Date() };
};

const useShippingDatesContent = ({ onClose, selectedIds, filters }) => {
  const prepareFiltersToLoad = (filters) => {
    return removeEmptyProps(
      Object.keys(filters).reduce((acc, filterKey) => {
        const fValue = filters[filterKey];
        const castedFValue = Array.isArray(fValue)
          ? prepareDateRange(fValue)
          : fValue;
        return { ...acc, [filterKey]: castedFValue };
      }, {})
    );
  };
  const [dateMode, setDateMode] = useState("number");
  const [shiftScope, setShiftScope] = useState("all");

  const navigate = useNavigate();
  const {
    fields: fieldsToEdit,
    updatedItems,
    onUpdate
  } = useOrderItemsEditableFields({
    itemIds: selectedIds,
    filters,
    dateMode,
    shiftScope
  });
  const messenger = useScMessage();
  const [isLoading, setIsLoading] = useState(false);

  const bulkUpdateCmd = useOrderItemsBulkUpdateActionCmd(
    {
      action: "shippingDates",
      itemIds: selectedIds,
      filters: prepareFiltersToLoad(filters),
      updatedFields: _.zipObjectDeep(
        Object.keys(updatedItems),
        Object.values(updatedItems)
      )
    },
    (r) => {
      const { messages, data, success } = r;
      const { bulk_update_batch_id } = data ?? {};
      console.log("bulkUpdateCmd success", r, messages);

      Array.isArray(messages) &&
        messages.forEach((message) =>
          messenger.send(message.text, message.type)
        );
      bulk_update_batch_id && navigate(`/home/batches/${bulk_update_batch_id}`);
      onClose();
    },
    (r) => {
      console.log("bulkUpdateCmd fail", r);
      messenger.send("Something went wrong", "error");
    }
  );

  const doBulkUpdate = () => {
    setIsLoading(true);
    bulkUpdateCmd().then((r) => {
      console.log("after bulk update", r);
      setIsLoading(false);
    });
  };


  console.log(
    "editable fields,updatedItems", dateMode,
    Object.keys(removeEmptyProps(filters)),
    filters,
    fieldsToEdit,
    updatedItems
  );
  const handleSwitchShiftScope = ({ value }) => {
    console.log("handleSwitchShiftScope", value);
    setShiftScope(value);
  };
  useEffect(() => {
    dateMode === "date" && setShiftScope("individual");

  }, [dateMode]);

  return {
    content: <>
      <ScSwitchField
        labelStart={"Date Shift"}
        labelEnd={"Specify Date"}
        value={dateMode === "date"}
        onUpdate={({ value }) => setDateMode(value ? "date" : "number")}
      />
      {dateMode === "number" && (
        <ScDropdownField
          required={true}
          label={"Application type"}
          code={"shiftScope"}
          inputWidth={30}
          labelPlacement={"start"}
          meta={{
            options: {
              all: "The same as earliest",
              individual: "Individual shift"
              // auto: 'Automatically shift'
            }
          }}
          value={shiftScope}
          onUpdate={handleSwitchShiftScope}
        />
      )}
      <br />
      <ScPageContentPaper isPaper mb={"1rem"}>
        <div>
          <ScEditableFieldsTree onUpdate={onUpdate} fieldsObj={fieldsToEdit} />
        </div>
      </ScPageContentPaper>
    </>,
    confirmBtnProps: {
      label: "Update",
      onClick: doBulkUpdate,
      disabled: !Object.keys(updatedItems).length
    },
    title: "Fields to update ",
    isLoading
  };

};

useShippingDatesContent.propTypes = {};

export default useShippingDatesContent;
