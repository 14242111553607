import React, { Fragment, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = (props) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return <Fragment>{props.children}</Fragment>
}

ScrollToTop.propTypes = {
  // location: PropTypes.shape({
  //   pathname: PropTypes.string
  // }).isRequired,
  // children: PropTypes.element.isRequired
}
export default ScrollToTop
