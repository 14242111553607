import React from 'react'
import { SnowListAltIcon } from '../../../../../../shared/components/icons'
import { ScWhiteButton } from '../../../../../../shared/components/buttons'

const PackingSlipButton = ({ openUrl, ...props }) => {
  return (
    <ScWhiteButton
      icon={() => <SnowListAltIcon />}
      label={'Packing Slip'}
      onClick={() => window.open(openUrl, '_blank')}
      {...props}
    />
  )
}

export default PackingSlipButton
