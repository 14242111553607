import React, { Suspense } from 'react'
import OrderActions from './components/OrderActions'
import ScSpinner from '../../../../shared/components/ScSpinner/ScSpinner'
import { ErrorBoundary } from 'react-error-boundary'
import PropTypes from 'prop-types'

const DetailHeader = ({
  toggleShowOnHoldModal,
  toggleShowCancelModal,
  toggleShowCancelItemsModal,
  toggleShowRevalidateModal,
  toggleResendOrderItemsModal
}) => (
  <header>
    <ErrorBoundary
      fallback={<div>Error</div>}
      onError={() => alert('Order Action error')}
    >
      <Suspense fallback={<ScSpinner />}>
        <OrderActions
          toggleShowOnHoldModal={toggleShowOnHoldModal}
          toggleShowCancelModal={toggleShowCancelModal}
          toggleShowCancelItemsModal={toggleShowCancelItemsModal}
          toggleShowRevalidateModal={toggleShowRevalidateModal}
          toggleResendOrderItemsModal={toggleResendOrderItemsModal}
        />
      </Suspense>
    </ErrorBoundary>
  </header>
)

DetailHeader.propTypes = {
  // order: PropTypes.shape().isRequired,
  toggleShowOnHoldModal: PropTypes.func.isRequired,
  toggleShowCancelModal: PropTypes.func.isRequired,
  toggleShowCancelItemsModal: PropTypes.func.isRequired,
  toggleShowRevalidateModal: PropTypes.func.isRequired
}

export default DetailHeader
