import React from 'react'
import { SnowBox, SnowPaper } from '../snowComponents'

const ScPageContentPaper = ({ children, isPaper = false, mb = '1em' }) => {
  const elevation = isPaper ? 2 : 0
  return (
    <SnowPaper
      elevation={elevation}
      style={{ width: 'fit-content', marginBottom: mb }}
    >
      <SnowBox px={2}>{children}</SnowBox>
    </SnowPaper>
  )
}

export default ScPageContentPaper
