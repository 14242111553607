import React, { Suspense } from 'react'
import { useRecoilValue } from 'recoil'
import { Link } from 'react-router-dom'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import {ScMenuButton} from '../../../../shared/components/buttons'
import { ReactComponent as LogoImg } from '../../../app/images/snow_axis_logo2_white.svg'
import { getScPageHeaderState } from '../../data/currentHeadingState'
import TopbarProfileMenu from '../../Layout/Header/TopbarProfileMenu'
// import ScMenuBtn from '../../../../shared/components/buttons/ScMenuBtn'
import styled, { css } from 'styled-components'

const PageHeader = styled.header`
  position: fixed;
  z-index: 1000;
  min-width: 100%;
  display: grid;
  grid-template-rows: min-content;
  grid-template-columns: min-content 1fr ${({ countHeaderElement }) =>
      countHeaderElement === 4 ? 'auto' : ``} auto;
  column-gap: 20px;
  align-items: center;
  ${({ theme }) => `
    padding: ${theme.spacing.main};
    color: ${theme.mainPalette.typography.contrast};
    background: ${theme.mainPalette.color.main};
  `}
`

const PageMenuView = styled.div`
  display: flex;
  min-width: 232px;
`

const PageLogoLink = styled(Link)`
  display: block;
  width: 150px;
  height: 60px;
  svg {
    height: 100%;
  }
`
const PageMenuBtn = styled(ScMenuButton)``

const PageHeadingView = styled(SnowTypography).attrs({
  component: 'h1'
})`
  padding-left: 2rem;
  grid-row: 1/2;
  grid-column: 2/3;

  &.MuiTypography-root {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.soBig};
      color: ${theme.mainPalette.color.contrast};
    `}
  }
`

const TopbarProfileMenuView = styled(TopbarProfileMenu)`
  grid-row: 1/2;
  grid-column: ${({ countHeaderElement }) =>
    `${countHeaderElement}/${countHeaderElement + 1}`};
`

function Logo() {
  return (
    <PageLogoLink to="/home/order_hub/dashboard">
      <LogoImg />
    </PageLogoLink>
  )
}

const Header = React.forwardRef(function Header({ ...props }, ref) {
  const { heading, subtitle, extension, backgroundColor } =
    useRecoilValue(getScPageHeaderState)
  let countHeaderElement = 3
  if (subtitle) {
    countHeaderElement++
  }
  if (extension) {
    countHeaderElement++
  }
  console.log('Header income', {
    heading,
    subtitle,
    extension,
    backgroundColor,
    countHeaderElement
  })
  const { isOpen, onOpen, onClose } = props
  return (
    <PageHeader
      ref={ref}
      aria-label="page-header"
      countHeaderElement={countHeaderElement}
    >
      <PageMenuView>
        <PageMenuBtn isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
        <Logo />
      </PageMenuView>
      <PageHeadingView>{heading}</PageHeadingView>
      {subtitle && (
        <SnowTypography variant="subtitle1" color={'#70bbfd'}>
          {subtitle}
        </SnowTypography>
      )}
      {extension && <div>{extension}</div>}
      <Suspense fallback={'loading'}>
        <TopbarProfileMenuView countHeaderElement={countHeaderElement} />
      </Suspense>
    </PageHeader>
  )
})

export default Header
