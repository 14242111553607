import React from 'react'
import { SnowTypography } from '../../../../../shared/components/snowComponents'
import { SnowSpeakerNotesIcon } from '../../../../../shared/components/icons'
import styled, { css } from 'styled-components'

const StyledMessageContainer = styled.div`
  padding: 20px 16px;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : 0)};
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadow.main};
`

const StyledTitle = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.secondary};
  display: flex;
  align-items: center;
  svg {
    margin-right: ${({ theme }) => theme.spacing.main};
  }
`

const StyledList = styled.ul`
  list-style: none;
`

const StyledItem = styled.li`
  padding-left: ${({ theme }) => theme.spacing.main};
  ${({ iscount, theme }) =>
    iscount
      ? css`
          position: relative;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 8px;
            height: 2px;
            background: ${theme.mainPalette.grey.middle};
          }
        `
      : null}
`

function GiftMessage({ messages, mb = 0 }) {
  console.log('Gift messages', messages)
  return (
    <StyledMessageContainer mb={mb}>
      <StyledTitle>
        <SnowSpeakerNotesIcon />
        <SnowTypography component="h3" variant="subtitle1" fontWeight={400}>
          Gift messages
        </SnowTypography>
      </StyledTitle>
      <StyledList>
        {messages.map((message, index) => (
          <StyledItem key={index} iscount={messages.length > 1 ? 1 : 0}>
            <SnowTypography variant="body1">{`"${message.message}"`}</SnowTypography>
          </StyledItem>
        ))}
      </StyledList>
    </StyledMessageContainer>
  )
}

export default GiftMessage
