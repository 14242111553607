import React from 'react'
import OrderNotes from './components/OrderNotes'
import CustomerInformation from './components/CustomerInformation'
import { useCurrentOrder } from '../data/currentOrderState'
import styled from 'styled-components'

const StyledSidebarContainer = styled.aside`
  padding: 3px;
`

function DetailSidebar() {
  const currentOrder = useCurrentOrder()

  return (
    <StyledSidebarContainer>
      <OrderNotes order={currentOrder} />
      <CustomerInformation order={currentOrder} />
    </StyledSidebarContainer>
  )
}
export default DetailSidebar
