import React, { useEffect } from 'react'
import { useGoogleLogin } from 'react-google-login'

// refresh token
import { refreshTokenSetup } from '../utils/refreshToken'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import fetchClientId from '../data/fetchClientId'
import { providerAuth } from '../data/providerAuth'

function useGoogleLoginHook() {
  const { clientId } = useRecoilValue(fetchClientId({ provider: 'google' }))
  const setProviderAuth = useSetRecoilState(providerAuth)

  const onSuccess = (res) => {
    console.log(`Logged in successfully welcome ${res.profileObj.name}`)

    refreshTokenSetup(res, (refreshedData) => {
      const { profileObj, tokenObj } = refreshedData
      setProviderAuth({ profileObj, tokenObj })
    })
  }
  const onFailure = (res) => {
    console.log('useGoogleLoginHook failed: res:', res)
    setProviderAuth(null)
    // alert(`Failed to login. 😢 Please ping this to developer`)
  }

  // returns google SignIn method for given ClientID
  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: true,
    cookiePolicy: 'single_host_origin'
    // accessType: 'offline',
    // responseType: 'code',
    // prompt: 'consent'
  })
  console.log('useGoogleLoginHook providerAuth, clientId', clientId)

  return { signIn, clientId }
}

export default useGoogleLoginHook
