import styled, { css } from 'styled-components'

const Personalization = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.secondary};
    max-width: 400px;
    box-shadow: ${theme.shadow.main};
  `}
`

export default Personalization