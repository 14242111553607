import React, { useContext } from 'react'
import {
  SnowList,
  SnowListItem,
  SnowTypography
} from '../../../../../../shared/components/snowComponents'
import styled, { ThemeContext } from 'styled-components'

const ErrorDetailView = styled.div`
  display: flex;
  justify-content: space-between;
`

const ErrorDetailListView = styled(SnowList).attrs({
  disablePadding: true
})`
  flex: 0 1 50%;
`

const ErrorDetailItemView = styled(SnowListItem).attrs({
  dense: true
})`
  color: ${({ theme }) => theme.mainPalette.warning.secondary};
`

const getErrorKeys = (errorObj) => {
  // check error's data type
  // if array, just return the error array
  const isArray = Array.isArray(errorObj)
  if (isArray) {
    return []
    // else, if its an object, parse the error object into an array
  } else {
    const finalKeysArray = []
    const errorObject = errorObj
    const errorObjKeys = Object.keys(errorObj)
    errorObjKeys.forEach((key) => {
      const innerObj = errorObj[key]
      // check data type of object's value
      const valueType = typeof innerObj
      // if value type is string, just return the string
      if (valueType === 'string') {
        finalKeysArray.push(key)
        // otherwise if its an object, continue to parse out the object
      } else {
        const innerObjKeys = Object.keys(innerObj)
        if (innerObjKeys.length > 0) {
          innerObjKeys.forEach((key) => {
            finalKeysArray.push(key)
          })
        }
      }
    })
    return finalKeysArray
  }
}

const getErrorValues = (errorObj) => {
  // check error's data type
  // if array, just return the error array
  console.log(errorObj)
  const isArray = Array.isArray(errorObj)
  if (isArray) {
    return errorObj
    // else, if its an object, parse the error object into an array
  } else {
    const finalValuesArray = []

    const errorObject = errorObj
    const errorObjKeys = Object.keys(errorObj)
    errorObjKeys.forEach((key) => {
      const innerObj = errorObj[key]
      // check data type of object's value
      const valueType = typeof innerObj
      // if value type is string, just return the string
      if (valueType === 'string') {
        finalValuesArray.push(innerObj)
        // otherwise if its an object, continue to parse out the object
      } else {
        const innerObjValues = Object.values(innerObj)
        if (innerObjValues.length > 0) {
          innerObjValues.forEach((value) => {
            finalValuesArray.push(value)
          })
        }
      }
    })
    return finalValuesArray
  }
}

const getErrors = (errorObj) => {
  console.log('getErrors', {
    errors: errorObj,
    getErrorKeys: getErrorKeys(errorObj),
    getErrorValues: getErrorValues(errorObj)
  })
  const rezult = errorObj?.length
    ? getErrorValues(errorObj).map((error, index) => ({
        key: getErrorKeys(errorObj)[index],
        value: error
      }))
    : null
  return rezult
}

const ErrorDetail = ({ item, vendorObj }) => {
  const theme = useContext(ThemeContext)

  return (
    <>
      {!!item && (
        <ErrorDetailView>
          <SnowList disablePadding={true}>
            <SnowListItem dense={true}>
              <SnowTypography variant={'body2'} component={'p'}>
                SKU: <b>{item.sku}</b>
              </SnowTypography>
            </SnowListItem>
            <SnowListItem dense={true}>
              <SnowTypography
                component={'p'}
                variant={'inherit'}
                color={theme.mainPalette.color.link}
              >
                {item.name}
              </SnowTypography>
            </SnowListItem>
          </SnowList>
          <ErrorDetailListView>
            {Object.keys(item.errors).map((error, index) => (
              <ErrorDetailItemView key={index}>
                {item.errors[Object.keys(item.errors)[index]]}
              </ErrorDetailItemView>
            ))}
          </ErrorDetailListView>
        </ErrorDetailView>
      )}
      {vendorObj ? (
        <ErrorDetailView>
          <SnowTypography variant={'body2'} component={'p'}>
            {`Vendor: `}{' '}
            <b style={{ fontWeight: '500' }}>{vendorObj.vendorName}</b>
          </SnowTypography>
          {getErrors(vendorObj.errors)?.length ? (
            <ErrorDetailListView>
              {getErrors(vendorObj.errors).map(({ key, value }, index) => (
                <ErrorDetailItemView key={index}>
                  <div>{key}</div>
                  <div>{value}</div>
                </ErrorDetailItemView>
              ))}
            </ErrorDetailListView>
          ) : null}
        </ErrorDetailView>
      ) : null}
    </>
  )
}

ErrorDetail.propTypes = {}

export default ErrorDetail
