import React, { Fragment, useContext } from 'react'
import {
  SnowTypography,
  SnowTable,
  SnowTableBody
} from '../../../../../shared/components/snowComponents'
import { ReactComponent as PaidIcon } from '../../../../app/images/SVGs/paid.svg'
import OrderDiscountNote from './components/OrderDiscountNote'
import { useCurrentOrder } from '../../data/currentOrderState'
import {
  OrderPaymentView,
  OrderPaymentHeaderView,
  OrderPaymentRowView,
  OrderPaymentCellView,
  OrderPaymentTitleCellView
} from './_styled'
import { ThemeContext } from 'styled-components'

function castToPlural(count) {
  // code to display text 'items' (plural) or 'item' (singular)
  let itemString = ''
  if (count === 1) {
    itemString = 'item'
  } else {
    itemString = 'items'
  }
  return `${count} ${itemString}`
}

const OrderPayment = () => {
  const theme = useContext(ThemeContext)
  const orderModel = useCurrentOrder()
  const getTaxInfo = () => (
    <>
      {orderModel.calculatedData.uniqTaxLines.map((taxLine, index) => (
        <div key={`${JSON.stringify(taxLine)}-${index}`}>{taxLine}</div>
      ))}
    </>
  )

  const paymentData = [
    {
      title: 'Subtotal',
      addInfo: castToPlural(orderModel.calculatedData.itemsCount),
      value: `$${orderModel.calculatedData.subtotal}`,
      isRender: true
    },
    {
      title: 'Discount',
      addInfo: <OrderDiscountNote />,
      value: `-$${orderModel.calculatedData.totalDiscount}`,
      isRender: !!orderModel.calculatedData.hasDiscount
    },
    {
      title: 'Shipping',
      addInfo: null,
      value: `$${orderModel.calculatedData.shippingTotal}`,
      isRender: true
    },
    {
      title: 'Tax',
      addInfo: getTaxInfo(),
      value: `$${orderModel.calculatedData.taxTotal}`,
      isRender: true
    },
    {
      title: 'Total',
      addInfo: null,
      value: `$${orderModel.calculatedData.grandTotal}`,
      isRender: true
    }
  ]

  return (
    <OrderPaymentView>
      <OrderPaymentHeaderView>
        <PaidIcon width="32" />
        <SnowTypography
          variant={'body1'}
          component={'h3'}
          fontWeight={theme.fontWeight.bold}
        >
          Payment
        </SnowTypography>
      </OrderPaymentHeaderView>
      <SnowTable>
        <SnowTableBody>
          {paymentData?.length
            ? paymentData.map(({ title, addInfo, value, isRender }, index) => (
                <Fragment key={`${title}-${index}`}>
                  {isRender ? (
                    <OrderPaymentRowView istotal={title === 'Total' ? 1 : 0}>
                      <OrderPaymentTitleCellView>
                        {title}
                      </OrderPaymentTitleCellView>
                      <OrderPaymentCellView>{addInfo}</OrderPaymentCellView>
                      <OrderPaymentCellView align={'right'}>
                        {value}
                      </OrderPaymentCellView>
                    </OrderPaymentRowView>
                  ) : null}
                </Fragment>
              ))
            : null}
        </SnowTableBody>
      </SnowTable>
    </OrderPaymentView>
  )
}

export default OrderPayment
