import {
  atom,
  atomFamily,
  selectorFamily,
  useRecoilState,
  useRecoilValue
} from 'recoil'
import { useEffect } from 'react'
import {
  useFetchAreaGroupVendorsCmd,
  useSaveAreaGroupVendorsCmd
} from '../infrastructure/areaApi'

const areaGroupLoadedVendorsState = atomFamily({
  key: 'areaGroupLoadedVendorsState',
  default: (groupId) => null
})
const loadedVendorsAreaGroupsIdsState = atom({
  key: 'loadedVendorsAreaGroupsIdsState',
  default: []
})
const loadingVendorsAreaGroupsIdsState = atom({
  key: 'loadingVendorsAreaGroupsIdsState',
  default: []
})

const updatedAreaGroupVendorsState = atomFamily({
  key: 'updatedAreaGroupVendorsState',
  default: (areaId) => ({})
})

const entityKeyField = 'vendor_id'


const shownAreaGroupVendors = selectorFamily({
  key: 'shownAreaGroupVendors',
  get: (groupId) => ({ get }) => {
    const updatedVendors = get(updatedAreaGroupVendorsState(groupId))
    const loadedVendors = get(areaGroupLoadedVendorsState(groupId))
    console.log({ groupId, updatedVendors, loadedVendors })

    const updatedVendorIds = Object.keys(updatedVendors)

    const preparedVendors =
      (loadedVendors &&
        loadedVendors.map((vendor) => {
          return updatedVendorIds.includes(String(vendor[entityKeyField]))
            ? updatedVendors[vendor[entityKeyField]]
            : vendor
        })) ?? []
    return preparedVendors
  }
})

export const useAreaGroupLoadedVendors = (areaGroupId) => {
  const [areaGroupLoadedVendors, setAreaGroupLoadedVendors] = useRecoilState(
    areaGroupLoadedVendorsState(areaGroupId)
  )
  const [loadedIds, setLoadedIds] = useRecoilState(
    loadedVendorsAreaGroupsIdsState
  )
  const [loadingIds, setLoadingIds] = useRecoilState(
    loadingVendorsAreaGroupsIdsState
  )

  const [areaGroupUpdatedVendors, setAreaGroupUpdatedVendors] = useRecoilState(
    updatedAreaGroupVendorsState(areaGroupId)
  )
  const shownGroupVendors = useRecoilValue(shownAreaGroupVendors(areaGroupId))


  const onBulkSave = (vendorsToSave) => {
    // setAreaGroupUpdatedVendors(vendorsToSave)
  }
  const onVendorUpdate = (vendorId, vendorData) => {
    console.log('onVendorUpdate', vendorData)
    setAreaGroupUpdatedVendors({
      ...areaGroupUpdatedVendors,
      [vendorId]: vendorData
    })
  }
  const fetchGroupVendorsCmd = useFetchAreaGroupVendorsCmd(
    {
      id: areaGroupId
    },
    (r) => {
      console.log('useFetchAreaGroupsCmd onSuccess ', r?.data)
      r?.data && setAreaGroupLoadedVendors(r?.data)
    },
    (r) => console.log('useFetchAreaGroupsCmd onFail r', r)
  )

  useEffect(() => {
    // setShownGroupVendors(areaGroupLoadedVendors)
    setLoadedIds((ids) => [...ids, areaGroupId])
    setLoadingIds((ids) => ids.filter((loadingId) => loadingId !== areaGroupId))
  }, [areaGroupLoadedVendors])

  useEffect(() => {
    console.log('useAreaGroupLoadedVendors useEffect', { id: areaGroupId })
    if (loadingIds.includes(areaGroupId) || loadedIds.includes(areaGroupId)) return

    setLoadingIds((ids) => [...ids, areaGroupId])

    areaGroupId && fetchGroupVendorsCmd()
  }, [areaGroupId])

  console.log('useAreaGroupLoadedVendors!', shownGroupVendors)
  return {
    vendorKeyField: entityKeyField,
    shownGroupVendors: shownGroupVendors, updatedVendors: areaGroupUpdatedVendors,
    onVendorUpdate
  }
}
/**
 *
 * @param areaGroupId - id points to Id of AreaGroup for returned updated vendors
 * @returns {[{},((function(*=): Promise<unknown>)|*)]}
 */
export const useAreaGroupUpdatedVendors = (areaGroupId) => {
  const updatedVendors = Object.values(useRecoilValue(updatedAreaGroupVendorsState(areaGroupId)))
  const saveVendorsCmd = useSaveAreaGroupVendorsCmd({
    id: areaGroupId,
    vendors: updatedVendors
  })
  return [updatedVendors, saveVendorsCmd]
}
