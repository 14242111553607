import React from 'react'
import { SnowTypography } from '../../../../../../../../../shared/components/snowComponents'
import PropTypes from 'prop-types'

const statuses = {
  error: {
    color: 'red',
    image: './resources/assets/snow-mark-error.png'
  },
  ready: {
    color: 'green'
  },
  rebuild: {
    color: 'blue'
  },
  pending: {
    color: 'gray'
  },
  processing: {
    color: 'gray'
  },
  hold: {
    color: 'gray'
  }
}
/**
 *
 * @param personalization : object
 */
export const getPersonalizationStatusColor = (personalization) => {
  return personalization && statuses[personalization.status]?.color
}
export const getPersonalizationStatusImage = (personalization) => {
  return personalization && statuses[personalization.status]?.image
}
const PersonalizationStatusViewer = ({ personalization, type = 'text' }) => {
  const { image_url, refnum, values } = personalization?.preview ?? {}
  return (
    <>
      {type === 'text' && (
        <SnowTypography
          component='span'
          variant='inherit'
          style={{
            color: `${getPersonalizationStatusColor(personalization) ?? 'black'
              }`
          }}
        >
          {personalization.status}
        </SnowTypography>
      )}
      {type === 'image' && !image_url && (
        <img src={getPersonalizationStatusImage(personalization)} />
      )}
    </>
  )
}
PersonalizationStatusViewer.propTypes = {
  type: PropTypes.oneOf(['image', 'text']),
  personalization: PropTypes.shape({
    moderation_status: PropTypes.string,
    personalizer: PropTypes.string, //for case of enum - pairs array [{value:label},..]
    preview: PropTypes.object,
    status: PropTypes.oneOf([
      'pending',
      'processing',
      'ready',
      'error',
      'rebuild',
      'hold'
    ]),
    type: PropTypes.string
  })
}

export default PersonalizationStatusViewer
