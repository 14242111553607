import React from 'react'
import { Filter } from '../_styled'
import Searcher2 from '../../../../../shared/components/searcher/Searcher2'
import PropTypes from 'prop-types'

const VendorFilter = (props) => (
  <Filter>
    <Searcher2
      code="vendor"
      placeholder={'Vendor'}
      value={props.selectValue}
      tooltip={props.tooltip}
      meta={props.meta}
      disableClearable={false}
      onClear={() => props.setFilter('vendor', '')}
      onSearchUpdate={(value) => {
        console.log('onSearchUpdate', value)
        props.setFilter('vendor', value)
      }}
    />
  </Filter>
)

VendorFilter.propTypes = {
  inputValue: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  isFilterActive: PropTypes.string.isRequired
}

export default VendorFilter
