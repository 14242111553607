import React, { useEffect, useState } from 'react'
import {
  SnowLink,
  SnowTypography
} from '../../../../shared/components/snowComponents'
import { ScPrimaryBtn } from '../../../../shared/components/buttons'
import {
  SidebarSubtitleView
} from '../../../../shared/components/LayoutComponents/ScSidebar/_styled'
import { useProductEddCmd } from '../../infrastructure/apiV2'
import { useProduct } from '../../context/product'
import { prettifyJsonOutput } from '../../../../utils/helpers'
import { getDateStr } from '../ProductDetails'
import styled from 'styled-components'

const parseEddResp = ({ data }) => {
  console.log('parseEddResp', data)
  if (!data) return {}
  const {
    service_available,
    service_error,
    product_found,
    edd_local: eddLocal,
    edd_global: eddGlobal,
    pushed_at,
    report
  } = data

  if (!service_available)
    return { title: `EDD Service isn't available for this sales channel` }
  if (service_error) return { title: 'EDD Service Error' }
  if (!product_found)
    return { title: 'Product missed in EDD. Request Edd update', eddBtn: true }

  const eddValues = eddLocal?.use
    ? [{ region: 'Global', value: eddLocal.pushed }]
    : eddGlobal?.use
      ? eddGlobal.pushed
      : []
  return {
    eddBtn: true,
    title: 'EDD Service Info',
    eddLocal,
    eddGlobal,
    eddValues,
    pushed_at,
    report
  }
}
const EddContentView = styled(SnowTypography).attrs({
  display: 'block',
  variant: 'subtitle'
})`
  pre {
    font-size: 0.875rem;
    overflow: auto;
    text-overflow: ellipsis;
  }
`
const useProductEdd = (props) => {
  const {
    product: {
      values: {
        product: { id }
      }
    }
  } = useProduct()

  const [edd, setEdd] = useState({})

  console.log('useProductEdd', id, edd)

  const { fetchEddCmd, requestEddUpdateCmd } = useProductEddCmd(id)

  useEffect(() => {
    fetchEddCmd().then((r) => setEdd(parseEddResp(r?.data)))
  }, [])

  return [edd, requestEddUpdateCmd]
}

const ProductEdd = () => {
  const [{ eddBtn, title, pushed_at, report, eddValues }, requestEddUpdateCmd] =
    useProductEdd()
  const [reportShown, setReportShown] = useState(false)
  const handleRequestEdd = () => requestEddUpdateCmd()

  if (!title) return null

  return (
    <>
      {/*todo: uncomment <SidebarSubtitleView> and fix 'Maximum call stack size exceeded' issue*/}
      <SidebarSubtitleView>{title}</SidebarSubtitleView>
      {eddBtn && (
        <SidebarSubtitleView>
          <ScPrimaryBtn
            label={'Request EDD update'}
            onClick={handleRequestEdd}
          />
        </SidebarSubtitleView>
      )}

      {pushed_at && (
        <SidebarSubtitleView>
          Sales Channel updated at:
          <SnowTypography variant={'subtitle2'}>
            {getDateStr(pushed_at)}
          </SnowTypography>
        </SidebarSubtitleView>
      )}

      {Array.isArray(eddValues) && (
        <SidebarSubtitleView>
          EDD values:
          <EddContentView>
            {eddValues.length
              ? eddValues.map(({ region, value }) => (
                <SnowTypography key={region} display={'block'}>
                  {region}: {value}
                </SnowTypography>
              ))
              : `EDD values aren't set`}
          </EddContentView>
        </SidebarSubtitleView>
      )}

      {report && (
        <SidebarSubtitleView color={report.success ? 'inherit' : 'red'}>
          <SnowLink onClick={() => setReportShown(!reportShown)}>
            Report
          </SnowLink>
          {reportShown && (
            <EddContentView>{prettifyJsonOutput(report, true)}</EddContentView>
          )}
        </SidebarSubtitleView>
      )}
    </>
  )
}

export default ProductEdd
