import React from 'react'
import styled from 'styled-components'

const StyledFieldsGroup = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
`

const StyledItem = styled.li`
  margin-bottom: 10px;
`

function ScFieldsGroup({ children, ...props }) {
  const _children = Array.isArray(children) ? children : [children]
  console.log('FieldsGroup props', props.inputWidth)

  return (
    <StyledFieldsGroup className={'FieldsGroup'}>
      {_children.map((child, index) =>
        child ? (
          <StyledItem key={index}>
            {{
              ...child,
              props: {
                ...child.props,
                ...props,
                isGroupField: true
              }
            }}
          </StyledItem>
        ) : null
      )}
    </StyledFieldsGroup>
  )
}
export default ScFieldsGroup
