import React, { Suspense } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  background: ${({theme})=>theme.mainPalette.color.main};
  width: 100%;
  height: 100vh;
  color: ${({theme})=>theme.mainPalette.color.contrast};
  display: flex;
  align-items: center;
  justify-content: center;
`

const AuthorizingSpinner = () => {

  return (
    <StyledContainer >
      Snow Axis Authorizing and loading routes
    </StyledContainer>
  )
}
const ScTheme = (props) => {
  return (
    <Suspense fallback={<AuthorizingSpinner />}>
      <div>{props.children}</div>
    </Suspense>
  )
}

export default ScTheme
