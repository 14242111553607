import { SnowTableRow } from '../../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const OrderPaymentRowView = styled(SnowTableRow)`
  ${({ istotal, theme }) =>
    istotal
      ? `
      &.MuiTableRow-root{
        border-top: ${theme.border.main};
      }
      .MuiTableCell-root {
        font-weight: ${theme.fontWeight.bold}!important;
      }
    `
      : ''}
`

export default OrderPaymentRowView