import React, { useEffect, useState } from 'react'
import { useAreaGroupLoadedVendors } from './data/loadedAreaGroupVendors2'
import ScEditableVirtualizedTable, {
  getFilterFromSearchParams
} from '../../shared/components/tables/ScEditableVirtualizedTable/ScEditableVirtualizedTable'
import { useSearchParams } from 'react-router-dom'

const areaGroupVendorFieldsDescription = [
  {
    code: 'vendor_id',
    editType: 'text',
    title: 'vendor_id',
    visible: false,
    default: ''
  },
  {
    code: 'vendor_code',
    editType: 'text',
    title: 'Code',
    visible: false,
    default: ''
  },
  {
    code: 'area_group_id',
    editType: 'text',
    title: 'area_group_id',
    visible: false
  },
  {
    code: 'vendor_name',
    editable: false,
    title: 'Name',
    visible: true,
    filterable: true,
    minWidth: 30
  },
  {
    code: 'ship_from_regions',
    editType: 'text',
    title: 'Ship from',
    // options: { 1: 'Enabled', 0: 'Disabled' },
    visible: true,
    editable: false,
    filterable: true,
    minWidth: 15,
    filterType: 'text'
  },
  {
    code: 'vendor_priority',
    editType: 'text',
    title: 'Priority',
    visible: true,
    filterable: true,
    width: 30
  },
  {
    code: 'vendor_status',
    editType: 'dropdown',
    title: 'Status',
    options: { 1: 'Enabled', 0: 'Disabled' },
    visible: true,
    filterable: true,
    minWidth: 23
  }
].reduce((acc, { code, ...rest }) => {
  return { ...acc, [code]: { code, ...rest } }
}, {})

const getFilterableFieldCodes = () => {
  return Object.keys(areaGroupVendorFieldsDescription).filter(
    (key) => areaGroupVendorFieldsDescription[key]?.filterable === true
  )
}

const AreaGroupVendors = ({ area_group_id }) => {
  const [searchParams] = useSearchParams()

  const {
    vendorKeyField,
    shownGroupVendors,
    updatedVendors,
    vendors,
    onVendorUpdate
  } = useAreaGroupLoadedVendors(area_group_id)

  const [filters, setFilters] = useState(
    getFilterFromSearchParams(getFilterableFieldCodes(), searchParams)
  )

  const handleRowClick = (row) => console.log('handleRowClcik', row)

  const handleFilterChange = (filterCode, filterValue) =>
    setFilters({ ...filters, [filterCode]: filterValue })

  const handleRowUpdate = (rowId, rowData) => {
    console.log('handleRowUpdate', { rowId, updatedVendors, rowData })
    onVendorUpdate(rowId, rowData)
  }
  useEffect(() => {
    console.log('AreaGroupVendors constructor')
    return () => console.log('AreaGroupVendors destructor')
  }, [])

  console.log('AreaGroupVendors render', {
    filters,
    areaGroupVendorFieldsDescription,
    updatedVendors,
    shownGroupVendors
  })

  return (
    <div>
      {Boolean(shownGroupVendors) && (
        <div style={{ boxShadow: '0 0 3px', paddingTop: '1em' }}>
          <ScEditableVirtualizedTable
            editable={true}
            edit={true}
            // onCrudEvent={handleCrudEvent}
            //onBulkCrud={handleBulkCrud}
            selectable={false}
            autoWidth={true}
            onRowClick={handleRowClick}
            fields={areaGroupVendorFieldsDescription}
            filters={filters}
            dataRows={shownGroupVendors}
            onFilterChange={handleFilterChange}
            onRowUpdate={handleRowUpdate}
            autoFilter={true}
            contentAdaptiveColumnsWidth={false}
            keyField={vendorKeyField}
            uid={area_group_id}
            height={600}
          />
        </div>
      )}
    </div>
  )
}

export default AreaGroupVendors
