import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  SnowTypography,
  SnowList,
  SnowListItem
} from '../../../../shared/components/snowComponents'
import {
  ScPrimaryBtn,
  ScIconButton
} from '../../../../shared/components/buttons'
import { BarChart, Bar, Cell, ResponsiveContainer } from 'recharts'
import { fetchUsingStatusDateProductAndPaginationLimit } from '../../infrastructure/fetches'
import LoadingIcon from './LoadingIcon'
import { ReactComponent as ArrowBack } from '../../../app/images/arrow_back.svg'
import { ReactComponent as ArrowNext } from '../../../app/images/arrow_next.svg'
import {
  OrderDashboardCardView,
  OrderDashboardFooterView,
  OrderDashboardArrowsView,
  OrderDashboardActionsView,
  OrderCardHeaderView,
  OrderCardLoadingView,
  OrderTotalView,
  OrderCardView,
  OrderCardInfoView,
  OrderCardContentView,
  OrderCardTitleView,
  OrderCardOutlinedBtn
} from './_styled'
import styled, { css, ThemeContext } from 'styled-components'

const OrdersToModerate = (props) => {
  const theme = useContext(ThemeContext)
  const [activeIndex, setActiveIndex] = useState(0)
  const [data, setData] = useState(null)
  const navigate = useNavigate()

  const sortByNewestOrder = (a, b) =>
    Date.parse(b.created_at) - Date.parse(a.created_at)

  // / function to format an order's placement-date
  const getDate = (timestamp) => {
    const dateArray = new Date(timestamp).toUTCString().split(' ')
    return (
      <div>
        {dateArray[0]} {dateArray[2]} {dateArray[1]} <br />
      </div>
    )
  }

  // function for determining which order / order bar the user has clicked on
  // in the dashboard widget, in order to view its order details the active
  // item is the order that appears as a GREEN bar in the dashboard widget
  const getActiveItem = () => {
    if (data.length != 0) {
      return data[activeIndex]
    } else {
      return false
    }
  }

  // function invoked when a user clicks on a different order in widget
  const handleClick = (index) => {
    setActiveIndex(index)
  }

  // function to count how many orders need moderating / how many orders are
  // displayed in widget
  const getLength = () => {
    return data.length
  }

  // function to calculate how many days overdue an order is
  // based on how overdue an order is, it will appear with a shorter or taller
  // bar in the widget
  const getDaysOverdue = (x) => {
    // calculate number of milliseconds from the order's purchase date till
    // today
    const millisecondsUntilToday =
      new Date().getTime() - new Date(x.created_at).getTime()

    // convert milliseconds to days
    const total_seconds = parseInt(Math.floor(millisecondsUntilToday / 1000))
    const total_minutes = parseInt(Math.floor(total_seconds / 60))
    const total_hours = parseInt(Math.floor(total_minutes / 60))
    const days = parseInt(Math.floor(total_hours / 24))
    console.log('days', days)
    // adding + 1 to "days" count in line below because otherwise...
    // ...an order placed today (aka "zero" days overdue) will have "zero"
    // height to its bar on the widget chart...
    return days + 1
  }

  const redirectToOrderGrid = () => {
    const today = new Date()
    const thirtyDaysAgo = new Date().setDate(today.getDate() - 30)
    const startDateString = new Date(thirtyDaysAgo).toISOString()

    return navigate(
      `/home/order_hub/grid?startDate=${startDateString}&product=personalize&orderStatus=on_hold`
    )
  }

  // function to display each vendor's name
  const getVendorName = (vendorObj) => {
    return vendorObj.vendor
  }

  const handleArrowBack = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1)
    }
  }

  const handleArrowNext = () => {
    if (activeIndex < data.length - 1) {
      setActiveIndex(activeIndex + 1)
    }
  }

  const getActiveOrderNumber = () => {
    if (getActiveItem()) {
      return getActiveItem().orderNumber
    }
  }
  useEffect(() => {
    const today = new Date()
    const thirtyDaysAgo = new Date().setDate(today.getDate() - 30)
    const startDateString = new Date(thirtyDaysAgo).toISOString()

    const endDateString = ''
    const purchasedFrom = ''
    const product = 'personalized'
    const orderStatus = 'on_hold'
    // change pagination limit to allow up to 1000 orders
    const perPage = 1000

    // request orders from API with status 'on_hold'
    fetchUsingStatusDateProductAndPaginationLimit({
      startDate: startDateString,
      endDate: endDateString,
      orderStatus: orderStatus,
      product: product,
      perPage: perPage
    }).then((response) => {
      if (response.data) {
        // this return all orders on hold
        const { data } = response
        // then set orders in state
        setData(data)
      } else {
        return navigate(`/`)
      }
    })
  }, [])

  return (
    <OrderDashboardCardView>
      {data != null && data?.length ? (
        <OrderCardView>
          <OrderCardContentView>
            <OrderCardHeaderView>
              <OrderCardTitleView>
                <SnowTypography
                  component="h5"
                  variant="body2"
                  fontWeight={theme.fontWeight.bold}
                  style={{ textTransform: 'uppercase' }}
                >
                  Orders to Moderate:
                </SnowTypography>
                <SnowTypography component="p" variant="caption">
                  * this month
                </SnowTypography>
              </OrderCardTitleView>
              <OrderCardInfoView>
                <SnowTypography component="p" variant="h4">
                  {getLength()}
                </SnowTypography>
              </OrderCardInfoView>
            </OrderCardHeaderView>
            <OrderTotalView>
              <ResponsiveContainer height={50} width={'inherit'}>
                <BarChart data={data.slice(0, 15)} width={150} height={40}>
                  <Bar dataKey={getDaysOverdue}>
                    {data.slice(0, 15).map((entry, index) => (
                      <Cell
                        onClick={() => handleClick(index)}
                        dataset={index}
                        cursor="pointer"
                        fill={index === activeIndex ? '#013778' : '#03aef0'}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </OrderTotalView>
            <OrderDashboardArrowsView>
              <ScIconButton size={30} onClick={() => handleArrowBack()}>
                <ArrowBack width={20} onClick={() => handleArrowBack()} />
              </ScIconButton>
              <ScIconButton size={30} onClick={() => handleArrowNext()}>
                <ArrowNext width={20} />
              </ScIconButton>
            </OrderDashboardArrowsView>
          </OrderCardContentView>
          <OrderDashboardActionsView>
            {data.length != 0 ? (
              <OrderDashboardFooterView>
                <SnowTypography component="p" variant="body2">
                  <SnowTypography component="span" variant="body2">
                    Purchase Date:
                  </SnowTypography>
                  <SnowTypography
                    component="span"
                    variant="body2"
                    color={theme.mainPalette.color.main}
                  >
                    {getDate(getActiveItem().created_at)}
                  </SnowTypography>
                </SnowTypography>
                <SnowTypography component="p" variant="body2">
                  <SnowTypography component="span" variant="body2">
                    Vendors:
                  </SnowTypography>
                  <SnowList disableGutters={true} dense={true}>
                    {getActiveItem().vendors.map((vendorObj, idx) => (
                      <SnowListItem
                        disableGutters={true}
                        dense={true}
                        key={idx}
                      >
                        <SnowTypography
                          component="span"
                          variant="body2"
                          color={theme.mainPalette.color.main}
                        >
                          {getVendorName(vendorObj)}
                        </SnowTypography>
                      </SnowListItem>
                    ))}
                  </SnowList>
                </SnowTypography>
              </OrderDashboardFooterView>
            ) : null}

            {getActiveItem() ? (
              <OrderCardOutlinedBtn
                onClick={() =>
                  navigate(
                    `/home/order_hub/order_details/${getActiveItem().id}`
                  )
                }
                label={`View ${getActiveOrderNumber()}`}
              />
            ) : null}
            <ScPrimaryBtn
              onClick={() => redirectToOrderGrid()}
              label={'View All'}
            />
          </OrderDashboardActionsView>
        </OrderCardView>
      ) : (
        <OrderCardLoadingView>
          <LoadingIcon />
        </OrderCardLoadingView>
      )}
    </OrderDashboardCardView>
  )
}

export default OrdersToModerate
