import React, { useEffect, useState } from 'react'
import { NewChannelAttacher } from '../NewChannelAttacher'
import { useVendorChannels } from '../VendorIntegration'
import Searcher2 from '../../../../../../shared/components/searcher/Searcher2'
import { SnowBox } from '../../../../../../shared/components/snowComponents'
import { ScSimpleTable as ScSimpleTable2 } from '../../../../../../shared/components/tables/ScSimpleTable'

const ScopeSelector = ({ vendorId, onScopeSelect, selectedScope }) => {
  const { vendorChannels: channelList, updateChannels } =
    useVendorChannels(vendorId)

  const settingsScopeListWithGlobal = [
    {
      shop_id: '0',
      platform: '',
      marketplace_id: 0,
      marketplace_name: 'View Global Level'
    },
    ...channelList
  ]

  const [filteredScopeList, setFilteredScopeList] = useState(
    settingsScopeListWithGlobal
  )

  const handleScopeSelection = (scopeItem) => {
    console.log('handleScopeSelection', scopeItem)
    onScopeSelect(scopeItem)
  }
  const handleSearchStrUpdate = (searchStr) => {
    console.log('handleSearchStrUpdate str', searchStr)

    setFilteredScopeList(
      settingsScopeListWithGlobal.filter(
        ({ platform, marketplace_name }) =>
          platform.toLowerCase().includes(searchStr.toLowerCase()) ||
          marketplace_name.toLowerCase().includes(searchStr.toLowerCase())
      )
    )
  }

  const settingsScopeFields = ['marketplace_name', 'shop_id', 'platform']

  useEffect(() => {
    setFilteredScopeList(settingsScopeListWithGlobal)
  }, [channelList])

  // console.log('channelList', channelList)
  return (
    <>
      <NewChannelAttacher
        vendorId={vendorId}
        excludedChannelIds={channelList.map(
          ({ marketplace_id }) => marketplace_id
        )}
        onChannelAdded={updateChannels}
      />
      <SnowBox pt={1}>
        <Searcher2
          placeholder={'search'}
          code={'vendorscope'}
          onSearchUpdate={handleSearchStrUpdate}
        />
      </SnowBox>
      <ScSimpleTable2
        fieldsList={settingsScopeFields}
        d
        dataRows={filteredScopeList}
        preselected={selectedScope}
        keyField={'marketplace_id'}
        onRowClick={handleScopeSelection}
      // groupedBy={'shop_id'}
      />
    </>
  )
}

export default ScopeSelector
