import React, { Fragment, useEffect, useState } from 'react'
import { SnowTableRow, SnowTableCell, SnowFormControl, SnowTooltip } from '../../snowComponents'
import { ScCrudButton } from '../../buttons'
import SnowEditableField from '../../SnowEditableField'
import { setElementInCollectionByKey } from '../../../../utils/helpers'
import styled from 'styled-components'

const TableRowView = styled(SnowTableRow)`
  ${({ selected, theme }) => selected ? `
    border-left: ${theme.border.accent};
  ` : ''
}
  .MuiTooltip-tooltipPlacementBottom{
    offset-y: 0;
  }
  .MuiTableCell-root{
    padding: ${({ pad }) => pad};
    }
  }
`

const TableFormControlView = styled(SnowFormControl)`
  margin: ${({ theme }) => theme.spacing.main};
  min-width: 120px;
  width: 100%;
  ${({ isinvalid, theme }) => isinvalid ? `
    p {
      color: ${theme.mainPalette.warning.secondary};
    }
  ` : ''}
`

const TooltipedCellContent = (content, tooltip) => {
  return (
    <SnowTooltip title={`${tooltip}`}>
      <TableFormControlView>{content}</TableFormControlView>
    </SnowTooltip>
  )
}

const getPadding = (padding) => {
  switch (padding) {
    case 'none':
      return 0
    case 'small':
      return '4px'
    case 'big':
      return '16px'
    default:
      return '8px'
  }
}

const ScTableRow = ({
  actionsComponent = null,
  padding = 'normal',
  data,
  isEdit, // is Row in Edit mode
  editable, //show editable actions
  selected, //show editable actions
  fieldsToShow, // certain fields to show from row data
  // fields scope
  onRowSelect,
  onRowDblclick = null,
  onRowUpdate,
  onRowSave,
  onRowDelete,
  pad='16px'
}) => {
  const [isEditing, setEditing] = useState(isEdit)

  const [rowData, setRowData] = useState(data)
  const [invalidFields, setInvalidFields] = useState([])
  const getCellReadyData = (field) => {
    const { tooltip, code, ...meta } = field
    return {
      value: rowData[code],
      code: code,
      meta: {
        ...meta,
        code,
        isInvalid: invalidFields.includes(code),
        title: `${meta.title || meta.code} ${!!meta.required && '*'}`,
        ...(tooltip && { tooltip })
      }
    }
  }

  const [cellsToRepresent, setCellsToRepresent] = useState(
    fieldsToShow
      .filter(({ code }) => typeof rowData[code] !== 'undefined')
      .map(getCellReadyData)
  )

  //todo: think about to move a dependent fields functionality on upper level
  //todo: to update income data (though it will have minus - all rows re-rendering)
  const fieldsDependencesManager = fieldsToShow
    .filter(({ dependentOnFieldCode }) => dependentOnFieldCode)
    .reduce((acc, field) => {
      const masterFieldCode = field.dependentOnFieldCode
      const subscribedFields = [...(acc[masterFieldCode] ?? []), field]
      return { ...acc, [masterFieldCode]: subscribedFields }
    }, {})

  const updateDependences = async ({ code, value }) => {
    const dependentFields = fieldsDependencesManager[code]?.map((field) => {
      const { updaterFunc, code } = field
      console.log('updateDependences execute with', code, value)
      updaterFunc(value).then((data) => {
        const cellData = getCellReadyData({ ...field, ...data })
        setCellsToRepresent(
          setElementInCollectionByKey(cellsToRepresent, cellData, 'code')
        )
      })
    })
    console.log('updateDependences updatedFields', dependentFields)
  }

  // console.log(
  //   'cellsToRepresent',
  //   fieldsToShow,
  //   fieldsDependencesManager,
  //   rowData,
  //   data,
  //   cellsToRepresent
  // )
  const handleEdit = () => {
    console.log('ScAreaGroupTableRow handleEdit', rowData)
    setEditing(true)
  }

  const handleRemove = () => {
    console.log('ScAreaGroupTableRow handleRemove', rowData)
    onRowDelete(rowData)
  }

  const handleUpdate = ({ code, value }) => {
    const val = value?.label || value
    setRowData({ ...rowData, [code]: val })
    updateDependences({ code, value })
    if (onRowUpdate) {
      onRowUpdate({ code, value })
    }
    console.log('handleUpdate rowData', rowData)
    console.log('ScAreaGroupTableRow field handleUpdate', code, value)
  }

  const handleSelect = () => {
    !isEditing && onRowSelect && onRowSelect(rowData)
  }

  const handleDoubleClick = () => {
    onRowDblclick && onRowDblclick(rowData)
  }

  const handleSave = () => {
    console.log('ScAreaGroupTableRow handleSave', data, fieldsToShow, rowData)
    const absentFields = fieldsToShow
      .filter(({ required }) => required)
      .filter(({ code }) => {
        console.log(rowData, code, rowData[code])
        return !rowData[code]
      })
      .map(({ code }) => code)

    absentFields.length && console.error('requried fields missed', absentFields)

    setInvalidFields(absentFields)

    if (!absentFields.length && onRowSave) {
      onRowSave(rowData)
    }

    !absentFields.length && setEditing(false)
  }

  useEffect(() => {
    // console.log('useEffect project.isEdit', isEdit)
    setEditing(isEdit)
  }, [isEdit])

  return (
    <Fragment>
      <TableRowView
        onClick={handleSelect}
        onDoubleClick={handleDoubleClick}
        selected={selected}
        pad={getPadding(padding)}
      >
        {(typeof actionsComponent === 'function' || editable) && (
          <SnowTableCell sx={{ p: pad}}>
            {editable && (
              <ScCrudButton
                editMode={isEditing}
                onSave={handleSave}
                onEdit={handleEdit}
                onRemove={handleRemove}
                size={40}
              />
            )}
            {typeof actionsComponent === 'function' && actionsComponent(data)}
          </SnowTableCell>
        )}

        {cellsToRepresent.map(({ value, code, meta }) => (
          <SnowTableCell
            key={code}
            padding={padding ? padding : 'normal'}
            sx={{
              p: pad,
              width: meta.fullWidth ? '100%' : 'auto',
              whiteSpace: 'nowrap',
              cursor: 'pointer',
              minWidth: '180px'
            }}
          >
            {/*<SnowTooltip title={`${meta.tooltip}`} placement={'top-end'} PopperProps={{ style: { marginBottom: '-1em' } }}>*/}
            <div>
              <TableFormControlView isinvalid={meta.isInvalid ? 1 : 0}>
                <SnowEditableField
                  meta={meta}
                  edit={isEditing}
                  code={code}
                  value={value}
                  required={meta?.required}
                  onEditEnd={() => { }}
                  onEditStart={() => { }}
                  onUpdate={handleUpdate}
                  variant="outlined"
                />
              </TableFormControlView>
            </div>
            {/*</SnowTooltip>*/}
          </SnowTableCell>
        ))}
      </TableRowView>
    </Fragment>
  )
}

export default ScTableRow
