import {
  atom,
  atomFamily,
  selector,
  DefaultValue
} from 'recoil'
import { fetchPlatforms } from '../infrastructure/v1/apis'

const platformAtomFamily = atomFamily({
  key: 'platformAtom',
  default: (platformId) => { }
})
const platformIdsAtom = atom({
  key: 'platformIdsAtom',
  default: []
})

const platformsLoadedAtom = atom({
  key: 'platformsLoadedAtom',
  default: selector({
    key: 'platformsLoaderSelector',
    get: async ({ get }) => {
      const { data: platforms } = await fetchPlatforms()
      console.log('platformsRepo', platforms)
      if (!Array.isArray(platforms)) {
        console.log('fetchPlatforms error. platforms isnot array', platforms)
        return []
      }
      return platforms
    }
  })
})

export const platformsSelector = selector({
  key: 'platformsRepoSelector',
  get: ({ get }) => get(platformsLoadedAtom),
  set: ({ get, set, reset }, newValue) => {
    console.log('set platformsRepoSelector')
    const loadedPlatforms = get(platformsLoadedAtom)
    if (newValue instanceof DefaultValue) {
      loadedPlatforms.map((platform) => reset(platformAtomFamily(platform.id)))
      reset(platformIdsAtom)
      return
    }
    set(
      platformIdsAtom,
      newValue.map((platform) => platform.id)
    )
    newValue.map((platform) => set(platformAtomFamily(platform.id), platform))
  }
})
