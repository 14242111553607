import React, { useEffect, useState } from 'react'
import { ScDropdownField, ScSwitchField } from '../../../shared/components/SnowEditableField/baseFields'
import ScEditableFieldsTree from '../../../shared/components/SnowEditableField/ScEditableFieldsTree'
import _ from 'lodash'
import { pathifyObjectFields } from '../../../utils/helpers'
import { ScPageContentPaper } from '../../../shared/components/LayoutComponents'
import { addDays, parseISO, differenceInDays } from 'date-fns'
import {
  scDateIsoFormat
} from '../../../shared/components/SnowEditableField/baseFields/ScDateField/ScDateField'

const FIRST_LEAD_DATE_PATH = 'shipping_dates.earliest_ship_date'
const SECOND_LEAD_DATE_PATH = 'shipping_dates.earliest_delivery_date'

const isItFirstLeadDatePath = (path) => {
  return path === FIRST_LEAD_DATE_PATH
}
const isItSecondLeadDatePath = (path) => {
  return path === SECOND_LEAD_DATE_PATH
}

const getDateNumberOriginPaths = (pathsProps) => {
  const dateNumbersPaths = Object.keys(pathsProps).filter(
    (path) => pathsProps[path].type === 'date-number'
  )
  return dateNumbersPaths
}
const getFirstLeadDateValue = (pathifiedDateFields) => {
  return pathifiedDateFields?.[FIRST_LEAD_DATE_PATH]?.default_value
}
const getSecondLeadDateValue = (pathifiedDateFields) => {
  return pathifiedDateFields?.[SECOND_LEAD_DATE_PATH]?.default_value
}

const getLeadedDateLabel = (
  label,
  date,
  leadDate,
  leadDateShortcut = 'ESD'
) => {
  const shiftDifference =
    !isNaN(date) && parseInt(date || '0') - parseInt(leadDate || '0')

  const datesDifference =
    isNaN(date) && differenceInDays(parseISO(date), parseISO(leadDate)) //parseInt(date || '0') - parseInt(leadDate || '0')

  console.log(
    'getLeadedDateLabel date:',
    date,
    ' leadDate:',
    leadDate,
    ' shiftDifference:',
    shiftDifference,
    ' datesDifference',
    datesDifference
  )

  const daysDifference = shiftDifference || datesDifference

  const newLabel = daysDifference
    ? `${label} ( ${leadDateShortcut} + ${daysDifference} day${
        daysDifference > 1 ? 's' : ''
      } )`
    : `${label} ( ${leadDateShortcut}} )`
  console.log('newLabel', newLabel)
  return newLabel
}

const zipPathsToObj = (pathifiedObj) => {
  return _.zipObjectDeep(Object.keys(pathifiedObj), Object.values(pathifiedObj))
}

const shippingDatesFirstLeadDateDefaultsUpdateRulesObj = {
  'shipping_dates.earliest_ship_date': (val) => {
    console.log(
      'shippingDatesFirstLeadDateDefaultsUpdateRulesObj',
      ' val=',
      val,
      ' parseISO(val)=',
      parseISO(val)
      // ' new Date(val)=',
      // new Date(val)
      // scDateIsoFormat(new Date(val)),
      // scDateFormat(new Date(val))
    )
    return val
      ? isNaN(val)
        ? scDateIsoFormat(parseISO(val))
        : parseInt(val)
      : ''
  },
  'shipping_dates.latest_ship_date': (val) =>
    val
      ? isNaN(val)
        ? scDateIsoFormat(addDays(parseISO(val), 1))
        : parseInt(val) + 1
      : '',
  'shipping_dates.earliest_delivery_date': (val) =>
    val
      ? isNaN(val)
        ? scDateIsoFormat(addDays(parseISO(val), 2))
        : parseInt(val) + 2
      : '',
  'shipping_dates.latest_delivery_date': (val) =>
    val
      ? isNaN(val)
        ? scDateIsoFormat(addDays(parseISO(val), 6))
        : parseInt(val) + 6
      : ''
}
const shippingDatesSecondLeadDateDefaultsUpdateRulesObj = {
  'shipping_dates.earliest_delivery_date': (val) =>
    val ? (isNaN(val) ? scDateIsoFormat(parseISO(val)) : parseInt(val)) : '',

  'shipping_dates.latest_delivery_date': (val) =>
    val
      ? isNaN(val)
        ? scDateIsoFormat(addDays(parseISO(val), 4))
        : parseInt(val) + 4
      : ''
}

const shippingDatesCloneDefaultssUpdateRulesObj = {
  'shipping_dates.earliest_ship_date': (val) => val,
  'shipping_dates.latest_ship_date': (val) => val,
  'shipping_dates.earliest_delivery_date': (val) => val,
  'shipping_dates.latest_delivery_date': (val) => val
}

const getWithUpdatedShipDatesLabels = (
  pathifiedFieldsObj,
  originPathifiedFieldsObj,
  shiftScope
) => {
  const dateNumberPaths = getDateNumberOriginPaths(originPathifiedFieldsObj)
  console.log(
    'getWithUpdatedShipDatesLabels pathifiedDateFields',
    pathifiedFieldsObj,
    originPathifiedFieldsObj,
    FIRST_LEAD_DATE_PATH
  )
  const firstLeadDateValue = getFirstLeadDateValue(pathifiedFieldsObj)
  const secondLeadDateValue = getSecondLeadDateValue(pathifiedFieldsObj)

  const updatedLabels = dateNumberPaths.reduce((acc, path) => {
    const { label: originLabel } = originPathifiedFieldsObj[path]
    const { default_value: dateValue } = pathifiedFieldsObj[path]
    const { label: _label, ...withoutLabel } = pathifiedFieldsObj[path]

    const leadDateValue = path.includes('delivery')
      ? secondLeadDateValue
      : firstLeadDateValue
    const leadShortCut = path.includes('delivery') ? 'EDD' : 'ESD'

    const label = getLeadedDateLabel(
      originLabel,
      dateValue,
      leadDateValue,
      leadShortCut
    )
    const labeledField = { ...withoutLabel, label }

    return { ...acc, [path]: labeledField }
  }, pathifiedFieldsObj)
  console.log('getWithUpdatedShipDatesLabels', updatedLabels)
  return updatedLabels
}

/**
 *
 * @param dateValue - number or date which will lead other date-numbers update
 * @param pathifiedFieldsObj - original fields definition obj
 * @param rulesPerformer - rules functions object to apply to fields with dateValue as val param
 * @returns {{}}
 */
const updateShipDateDefaultsByRulesPerformer = (
  dateValue,
  pathifiedFieldsObj,
  originPathsProps,
  rulesPerformer,
  additionalProps = {}
) => {
  console.log(
    'updateShipDateDefaultsByRulesPerformer',
    dateValue,
    pathifiedFieldsObj,
    additionalProps
  )
  const leadPath = Object.keys(pathifiedFieldsObj).find((path) =>
    isItFirstLeadDatePath(path)
  )

  return Object.keys(rulesPerformer).reduce((acc, dateFieldPath) => {
    // const originLabel = originPathsProps[dateFieldPath].label
    const currentDateField = pathifiedFieldsObj[dateFieldPath]
    // const currentDate = currentDateField.default_value

    const performedFieldData = {
      ...currentDateField,
      default_value: rulesPerformer[dateFieldPath](dateValue),
      ...(dateFieldPath === leadPath ? {} : additionalProps)
    }
    console.log(
      'updateShipDateDefaultsByRulesPerformer performedFieldData',
      performedFieldData
    )
    return {
      ...acc,
      [dateFieldPath]: performedFieldData
    }
  }, pathifiedFieldsObj)
}

const TreeTest = () => {
  const fieldsToEdit = {
    shipping_dates: {
      earliest_ship_date: {
        id: 'earliest_ship_date',
        label: 'earliest_ship_date',
        type: 'date-number',
        default_value: ''
      },
      latest_ship_date: {
        id: 'latest_ship_date',
        label: 'latest_ship_date',
        type: 'date-number',
        default_value: ''
      },
      earliest_delivery_date: {
        id: 'earliest_delivery_date',
        label: 'earliest_delivery_date',
        type: 'date-number',
        default_value: ''
      },
      latest_delivery_date: {
        id: 'latest_delivery_date',
        label: 'latest_delivery_date',
        type: 'date-number',
        default_value: ''
      },
      comment: {
        id: 'comment',
        label: 'comment',
        type: 'text',
        default_value: ''
      }
    }
  }
  const originPathsProps = pathifyObjectFields(fieldsToEdit)

  const [dateMode, setDateMode] = useState('date')
  const [shiftScope, setShiftScope] = useState('all')

  const [fieldsObj, setFieldsObj] = useState(fieldsToEdit)
  const [updatedItems, setUpdatedItems] = useState({})
  const pathifiedFieldsObj = pathifyObjectFields(fieldsObj)
  const dateNumbersPaths = getDateNumberOriginPaths(originPathsProps)

  const isItDateNumberPath = (path) => {
    return dateNumbersPaths.includes(path)
  }

  const autoUpdateFields = ({ code, value, ...rest }) => {
    const isDateNumberField = isItDateNumberPath(code)
    const isFirstLeadDateField = isItFirstLeadDatePath(code)
    const isSecondLeadDateField = isItSecondLeadDatePath(code)

    const updatedField = { ...pathifiedFieldsObj[code], default_value: value }

    const needsToSyncDateNumberFields =
      isDateNumberField && shiftScope === 'all'

    const needsToUpdateByFirstLeadDate =
      isFirstLeadDateField &&
      (shiftScope === 'individual' || shiftScope === 'auto')

    const needsToUpdateBySecondLeadDate =
      isSecondLeadDateField &&
      (shiftScope === 'individual' || shiftScope === 'auto')

    const rulesToApply = [
      needsToUpdateByFirstLeadDate &&
        shippingDatesFirstLeadDateDefaultsUpdateRulesObj,
      needsToUpdateBySecondLeadDate &&
        shippingDatesSecondLeadDateDefaultsUpdateRulesObj,
      needsToSyncDateNumberFields && shippingDatesCloneDefaultssUpdateRulesObj
    ].find((item) => item)

    const updatedFieldsObj = (rulesToApply &&
      updateShipDateDefaultsByRulesPerformer(
        value,
        pathifiedFieldsObj,
        originPathsProps,
        rulesToApply,
        { visible: shiftScope === 'individual' }
      )) || { ...pathifiedFieldsObj, [code]: updatedField }

    const newUpdatedValues = rulesToApply
      ? Object.keys(rulesToApply).reduce(
          (acc, path) => ({
            ...acc,
            [path]: rulesToApply[path](value)
          }),
          {}
        )
      : { [code]: value }

    const mergedUpdatedFieldValues = {
      ...updatedItems,
      ...newUpdatedValues
    }
    setUpdatedItems({ ...mergedUpdatedFieldValues })

    console.log('onUpdate updated fields', updatedFieldsObj)
    const updatedObj = updatedFieldsObj && zipPathsToObj(updatedFieldsObj)

    console.log('onUpdate updated fields2', updatedObj, updatedFieldsObj)

    const withUpdatedLabels =
      updatedObj &&
      getWithUpdatedShipDatesLabels(
        pathifyObjectFields(updatedObj),
        originPathsProps,
        shiftScope
      )
    console.log('onUpdate updated fields3', withUpdatedLabels)

    withUpdatedLabels && setFieldsObj(zipPathsToObj(withUpdatedLabels))
    return updatedObj
  }
  const onUpdate = ({ code, value, key, ...rest }) => {
    console.log('onUpdate', pathifiedFieldsObj)
    autoUpdateFields({ code, value, ...rest })
  }

  const removeDateNumberFields = (fieldsObj) => {
    const cleanedFields = Object.keys(fieldsObj).reduce((acc, path) => {
      return !isItDateNumberPath(path)
        ? {
            ...acc,
            [path]: fieldsObj[path]
          }
        : acc
    }, {})
    return cleanedFields
  }

  const refreshUpdatedIfChanged = (newObj, prevObj) => {
    if (Object.keys(newObj).length !== Object.keys(prevObj).length)
      setUpdatedItems(newObj)
  }

  const updateDatesMode = (obj, dateMode) => {
    const pathifiedObj = pathifyObjectFields(obj)
    const updatedPathifiedObj = Object.keys(pathifiedObj).reduce(
      (acc, path) => ({
        ...acc,
        [path]: {
          ...(isItDateNumberPath(path)
            ? {
                ...pathifiedObj[path],
                type: dateMode
              }
            : pathifiedObj[path]),
          default_value: originPathsProps[path].default_value,
          label: originPathsProps[path].label
        }
      }),
      {}
    )
    const updatedObj = _.zipObjectDeep(
      Object.keys(updatedPathifiedObj),
      Object.values(updatedPathifiedObj)
    )
    return updatedObj
  }

  const updateFieldsVisibilityByShiftScope = (obj, shiftScope) => {
    const pathifiedObj = pathifyObjectFields(obj)
    const reducedPathifiedObj = Object.keys(pathifiedObj).reduce(
      (acc, path) => {
        const fieldToClone =
          isItFirstLeadDatePath(path) || !isItDateNumberPath(path)
            ? pathifiedObj[path]
            : {
                ...pathifiedObj[path],
                visible: shiftScope === 'individual'
              }
        return {
          ...acc,
          [path]: fieldToClone
        }
      },
      {}
    )

    console.log('3 updateShiftScope reducedPathifiedObj', reducedPathifiedObj)

    const updatedObj = _.zipObjectDeep(
      Object.keys(reducedPathifiedObj),
      Object.values(reducedPathifiedObj)
    )
    console.log('4 updateShiftScope updatedObj', updatedObj)

    return updatedObj
  }

  const handleSwitchShiftScope = ({ value }) => {
    console.log('handleSwitchShiftScope', value)
    setShiftScope(value)
  }

  useEffect(() => {
    dateMode === 'date' && setShiftScope('individual')

    const cleanedUpdatedItems = removeDateNumberFields(updatedItems)

    refreshUpdatedIfChanged(cleanedUpdatedItems, updatedItems)
    setFieldsObj((fieldsObj) => updateDatesMode(fieldsObj, dateMode))
  }, [dateMode])

  useEffect(() => {
    if (!fieldsObj) return

    setFieldsObj((fieldsObj) =>
      updateFieldsVisibilityByShiftScope(fieldsObj, shiftScope)
    )

    updatedItems['shipping_dates.earliest_ship_date'] &&
      autoUpdateFields({
        code: 'shipping_dates.earliest_ship_date',
        value: updatedItems['shipping_dates.earliest_ship_date']
      })
  }, [shiftScope])

  console.log('updatedItems', updatedItems, fieldsObj)

  return (
    <div>
      <ScSwitchField
        labelStart={'Date Shift'}
        labelEnd={'Specify Date'}
        value={dateMode === 'date'}
        onUpdate={({ value }) => setDateMode(value ? 'date' : 'number')}
      />
      {dateMode === 'number' && (
        <ScDropdownField
          required={true}
          label={'Application type'}
          code={'shiftScope'}
          meta={{
            options: {
              all: 'The same as earliest',
              individual: 'Individual shift'
              // auto: 'Automatically shift'
            }
          }}
          value={shiftScope}
          onUpdate={handleSwitchShiftScope}
        />
      )}
      <br />
      <ScPageContentPaper isPaper={true}>
        <ScEditableFieldsTree onUpdate={onUpdate} fieldsObj={fieldsObj} />
      </ScPageContentPaper>
    </div>
  )
}

export default TreeTest
