import React, { useState, useEffect } from 'react'
import { useProduct } from '../../context/product'

import ProductImages from './ProductImages'
import ScSidebar from '../../../../shared/components/LayoutComponents/ScSidebar/ScSidebar'
import ProductEdd from './ProductEdd'
import { useProductEddCmd } from '../../infrastructure/apiV2'
const useProductEdd = (props) => {
  const {
    product: {
      values: {
        product: { id }
      }
    }
  } = useProduct()

  const [edd, setEdd] = useState(null)
  console.log('useProductEdd', id)
  const { fetchEddCmd, requestEddUpdateCmd } = useProductEddCmd(id)
  useEffect(() => {
    fetchEddCmd().then((r) => setEdd(r?.data))
  }, [])
  return [edd, requestEddUpdateCmd]
}

const ProductSidebar = () => {
  const {
    product: { values }
  } = useProduct()
  // const [edd] = useProductEdd(values?.id)
  if (!values) return null
  return (<>

      <ScSidebar>
        <ProductImages />
        <ProductEdd />
    </ScSidebar></>
  )
}

export default ProductSidebar
