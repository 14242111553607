import styled, {css} from 'styled-components'

const VendorsTitle = styled.div`
  display: flex;
  align-items: center;
  ${({ ismulti }) =>
    ismulti
      ? css`
          margin-bottom: -1em;
          margin-top: -1em;
        `
      : ''}
`

export default VendorsTitle