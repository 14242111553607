import React, { useEffect, useRef } from 'react'

import {
  atom, atomFamily, selector,
  useRecoilState, useRecoilValue,
} from 'recoil'

import {
  useFetchGroupsLevelsCmd, useFetchCountriesCmd, useFetchCountryDetailsCmd
} from '../infrastructure/areaApi'
import { useScMessage } from '../../../shared/service/Message/data/currentMessageState'


const allCountriesState = atom({
  key: 'allCountriesState',
  default: null
})
const allLevelsState = atom({
  key: 'allLevelsState',
  default: null
})

const allCountryRegionsState = atomFamily({
  key: 'allCountryRegionsState',
  default: (countryId) => null
})
const loadedLevels = selector({
  key: 'loadedLevels',
  get: ({ get }) => get(allLevelsState) ?? {}
})
export const useCountries = ({ orderby = 'desc' } = {}) => {
  const [countries, setCountries] = useRecoilState(allCountriesState)
  const { send: sendMessage } = useScMessage()
  const fetchCountriesCmd = useFetchCountriesCmd(
    { orderby },
    (r) => {
      console.log('fetchCountriesCmd onSuccess ', r?.data)
      Array.isArray(r?.data) && setCountries(r?.data)
    },
    (r) => {
      r?.messages?.map(({ text, type }) => sendMessage(text, type))
      console.log('fetchCountriesCmd onFail r', r?.messages?.text, r?.messages?.type)
    }
  )

  useEffect(() => {
    if (Array.isArray(countries)) return

    setCountries([])
    fetchCountriesCmd()
  }, [])

  // console.log('useCountries!', countries)
  return countries || []
}

export const useLevels = () => {
  const levels = useRecoilValue(loadedLevels)
  return levels ?? {}
}

export const useLoadLevels = () => {
  const [levels, setLevels] = useRecoilState(allLevelsState)
  const isLoading = useRef(false)
  const { send: sendMessage } = useScMessage()

  const fetchLevelsCmd = useFetchGroupsLevelsCmd(
    (r) => {
      console.log('useLoadLevels fetchLevelsCmd onSuccess ', r?.data)

      r?.data && setLevels(r?.data)
    },
    (r) => {
      console.log('fetchLevelsCmd onFail r', r)
      Array.isArray(r?.messages) && r.messages.map(({ text, type }) => sendMessage(text, 'error'))
    }
  )

  useEffect(() => {
    console.log('useLoadLevels!!', levels)
    if (Array.isArray(levels)) return

    setLevels([])
    isLoading.current = true
    fetchLevelsCmd().then((r) => {
      isLoading.current = false
    })

  }, [])

  console.log('useLoadLevels!', levels)
  return levels ?? {}
}

export const useCountryRegions = ({ id }) => {
  const [regions, setRegions] = useRecoilState(allCountryRegionsState(id))
  const { send: sendMessage } = useScMessage()

  const fetchCountryRegionsCmd = useFetchCountryDetailsCmd({ id },
    (r) => {
      console.log('useCountryRegions fetchCountryRegionsCmd onSuccess ', r?.data)

      Array.isArray(r?.data?.regions) && setRegions(r?.data?.regions)
    },
    (r) => {
      r?.messages?.map(({ text, type }) => sendMessage(text, type))
      console.log('fetchCountryRegionsCmd onFail r', r)
    }
  )

  useEffect(() => {
    if (!id || Array.isArray(regions)) return

    console.log('fetchCountryRegionsCmd,i', id)
    // setRegions([])
    fetchCountryRegionsCmd()

  }, [id])

  console.log('useCountryRegions!!', id, regions)
  return regions
}

