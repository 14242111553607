import React, { useState } from 'react'
import { ScActionsBar } from '../../shared/components/LayoutComponents'
import { ScPrimaryBtn, ScLinkButton } from '../../shared/components/buttons'
import { removeEmptyProps } from '../../utils/helpers'
import BulkActionModal from '../../shared/components/modals/BulkActionModal'
import useShippingDatesContent from './BulkUpdateModal/useShippingDatesContent'
import useUnpreorderContent from './BulkUpdateModal/useUnpreorderContent'

const ScBulkActionsBar = ({
  selectedIds = [],
  bulkCount = null,
  filters = {},
  clearFilters = () => {
    console.log('Clear Filters')
  }
}) => {
  const [actionType, setActionType] = useState('')
  const [modalAction, setModalAction] = useState('')
  const bulkActions = {
    shippingDates: {
      useBulkActionContent: useShippingDatesContent
    },
    unpreorder: {
      useBulkActionContent: useUnpreorderContent
    }
  }
  const resetModalAction = () => setModalAction('')

  console.log(
    'ScBulkActionsBar filters',
    {
      actionType,
      filters,
      removeEmptyProps: removeEmptyProps(filters)
    }
  )
  const appliedFiltersCount = Object.values(filters).filter(
    (filterValue) => filterValue
  ).length
  return (
    <>
      {modalAction && actionType && bulkActions[modalAction] && (
        <BulkActionModal
          onClose={() => resetModalAction()}
          selectedIds={actionType === 'selected' ? selectedIds : []}
          filters={actionType === 'filtered' ? removeEmptyProps(filters) : {}}
          useBulkActionContent={bulkActions[modalAction].useBulkActionContent}
        />
      )}

      <ScActionsBar>
        <ScPrimaryBtn
          label={
            selectedIds.length
              ? `Update ${selectedIds.length} items`
              : 'Select items to update'
          }
          onClick={() => {
            setActionType('selected')
            setModalAction('shippingDates')
            // setModalOpen(true)
          }}
          disabled={!selectedIds.length}
        />

        <ScPrimaryBtn
          label={
            appliedFiltersCount
              ? `Update all found items`
              : 'Filter items to bulk update'
          }
          onClick={() => {
            setActionType('filtered')
            setModalAction('shippingDates')
            // setModalOpen(true)
          }}
          disabled={
            (typeof bulkCount === 'number' && bulkCount > 0) ||
            !appliedFiltersCount
          }
        />

        <ScPrimaryBtn
          label={`Remove preorders`}
          onClick={() => {
            setActionType(selectedIds.length ? 'selected' : 'filtered')
            setModalAction('unpreorder')
            // setModalOpen(true)
          }}
          disabled={!selectedIds.length && !filters.sku}
        />

        {!!appliedFiltersCount && (
          <ScLinkButton label={'Clear All Filters'} onClick={clearFilters} />
        )}
      </ScActionsBar>
    </>
  )
}

export default ScBulkActionsBar
