import {
  selectedBulkItemIds,
  selectItemsCmd
} from '../data/selectedBulkItemsState'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import { BULK_SELECTION_STATE } from '../infrastructure/helpers'

export const useBulkSelection = ({
  itemIds,
  preselectedIds,
  forceSelect = false
}) => {
  console.log(
    'useBulkSelection itemIds, preselectedIds,',
    itemIds,
    preselectedIds
  )
  const [selectTrippleState, setSelectTrippleState] = useState(
    BULK_SELECTION_STATE.None
  )
  const actualSelectedIds = useRecoilValue(selectedBulkItemIds)
  const setSelectedItemsCmd = useSetRecoilState(selectItemsCmd(itemIds || []))

  const setPreSelectedItemsCmd = useSetRecoilState(
    selectItemsCmd(preselectedIds || [])
  )

  useEffect(() => {
    console.log('actualSelectedIds,itemIds', actualSelectedIds, itemIds)
    const tripleState =
      actualSelectedIds.length &&
      (itemIds.length === actualSelectedIds.length) + 1
    console.log('selectedItems', tripleState, actualSelectedIds)
    setSelectTrippleState(tripleState)
  }, [actualSelectedIds])

  useEffect(() => {
    const shouldSelectPreselected =
      (!actualSelectedIds.length && preselectedIds.length) || forceSelect
    console.log(
      'useEffect preselectedIds',
      forceSelect,
      preselectedIds,
      shouldSelectPreselected
    )

    shouldSelectPreselected && setPreSelectedItemsCmd(true)
  }, [preselectedIds, forceSelect])

  useEffect(() => {
    console.log('useEffect []')
    return () => {
      console.log(
        'BulkSelector destruction actualSelectedIds',
        actualSelectedIds,
        preselectedIds
      )
      setPreSelectedItemsCmd(false)
      setSelectedItemsCmd(false)
    }
  }, [])

  const updateBulkSelection = () => {
    const binaryValue = selectTrippleState && selectTrippleState - 1
    console.log('updateBulkSelection', binaryValue, selectTrippleState)
    setSelectedItemsCmd(!binaryValue)
  }

  return { selectTrippleState, updateBulkSelection }
}
