import React, { useState, useRef, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import {
  SnowTypography,
  SnowList,
  SnowListItem,
  SnowBadge,
  SnowFab,
  SnowInputLabel,
  SnowBox
} from '../../../../shared/components/snowComponents'
import {
  SnowCancelIcon,
  SnowAddIcon
} from '../../../../shared/components/icons'
import {
  ScIconButton,
  ScPrimaryBtn,
  ScSecondaryBtn
} from '../../../../shared/components/buttons'
import { saveBundle } from '../fetches'
import useAuthToken from '../../../auth/hooks/useAuthToken'
import { useUpdateBundleItemCmd } from '../../infrastructure/apisV2'
import { arrayFromString } from '../../../../utils/helpers'
import { currentMessageState } from '../../../../shared/service/Message/data/currentMessageState'
import ScList from '../../../../shared/components/widgets/ScList/ScList'
import styled, { css } from 'styled-components'

const InputActiveView = css`
  input {
    border: ${({ theme }) => theme.border.main};
  }
`

const InputView = css`
  input {
    background: transparent;
    border: none;
  }
`

const BundleTitleView = styled(SnowTypography).attrs({
  component: 'h2',
  fontWeight: 400
})`
  &.MuiTypography-root {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.huge};
      color: ${theme.mainPalette.grey.dark};
    `}
  }
`

const BundleListItemView = styled(SnowListItem).attrs({
  disableGutters: true
})`
  &.MuiListItem-root {
    justify-content: space-between;
  }
  input {
    flex: 0 1 70%;
  }
`

const BundleItemLabelView = styled(SnowInputLabel)`
  margin-right: ${({ theme }) => theme.spacing.secondary};
  margin-bottom: 0;
`

const BundleParentView = styled(SnowBox).attrs({
  component: 'section'
})`
  margin-bottom: ${({ theme }) => theme.spacing.secondary};
  ${InputActiveView}
`

const BundleChildrenListView = styled(SnowList)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${({ theme }) => theme.spacing.main};
`

const BundleChildView = styled(SnowListItem).attrs({})`
  flex: 0 1 30%;
  min-width: 300px;
  margin: ${({ theme }) => theme.spacing.main};
  &.MuiListItem-root {
    padding: ${({ theme }) => theme.spacing.secondary};
    width: auto;
    align-items: flex-start;
  }
  ${({ isactive, theme }) =>
    isactive
      ? css`
          box-shadow: ${theme.shadow.big};
          ${InputActiveView}
        `
      : css`
          box-shadow: ${theme.shadow.main};
          ${InputView}
        `}
`

const BundleTitleBlockView = styled(SnowBox)`
  display: flex;
  align-items: center;
  h2 {
    margin-right: ${({ theme }) => theme.spacing.secondary};
  }
`

const AddButtonView = styled(SnowFab)`
  svg {
    margin-right: 4px;
  }
  &.MuiFab-root {
    padding: 2px 6px;
    height: auto;
    box-shadow: none;
  }
`

const BundleActionsView = styled.div`
  margin: -8px 0;
  display: flex;
  button {
    margin: 8px;
  }
`

const blankChild = {
  newChild: true,
  child_sku: 'child_sku',
  name: 'Child name',
  price: 1,
  qty: 1,
  weight: 1,
  personalized: 0
}
const bundleProductFields = [
  {
    code: 'sku',
    label: 'Bundle SKU',
    type: 'text',
    editable: true
  },
  {
    code: 'marketplaces',
    label: 'Sales Channels',
    type: 'custom',
    editable: true,
    renderer: (bundle, onUpdate) => {
      return (
        <input
          type={'text'}
          name={'marketplaces'}
          defaultValue={bundle.marketplaces.map(({ id }) => id).join(', ')}
          onChange={(ev) => {
            console.log('marketplaces', ev.target.value)
            onUpdate('marketplaces', arrayFromString(ev.target.value))
          }}
        />
      )
    }
  },
  { code: 'created_at', label: 'Created at', type: 'text', editable: false },
  { code: 'updated_at', label: 'Updated at', type: 'text', editable: false }
]
const getFieldDescription = (incomeCode) =>
  bundleProductFields.find(({ code }) => code === incomeCode)

const fieldLabel = (code) => getFieldDescription(code)?.label ?? code

const BundleField = ({ code, bundle, onUpdate }) => {
  const fieldDesc = getFieldDescription(code)
  if (!fieldDesc) return <>{code}</>
  if (fieldDesc.type === 'text')
    return (
      <input
        type="text"
        disabled={!fieldDesc.editable}
        defaultValue={bundle[code]}
        onChange={(ev) => onUpdate(code, ev.target.value)}
      />
    )
  if (fieldDesc.editable && fieldDesc.renderer)
    return <>{fieldDesc.renderer(bundle, onUpdate)}</>

  return <>{JSON.stringify(bundle[code])}</>
}

const BundleFields = ({ bundle, onUpdate }) => {
  const [fieldsState, setFieldsState] = useState({})

  const handleFieldUpdate = (code, value) =>
    setFieldsState({ ...fieldsState, [code]: value })

  useEffect(() => {
    onUpdate(fieldsState)
  }, [fieldsState])

  return (
    <ScList style={{ width: '35%' }}>
      {Object.keys(bundle).map((key) => (
        <div key={key} style={{ minWidth: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <BundleItemLabelView>{fieldLabel(key)}</BundleItemLabelView>
          <BundleField
            bundle={bundle}
            code={key}
            onUpdate={handleFieldUpdate}
          />
        </div>
      ))}
    </ScList>
  )
}
const BundleBody = (props) => {
  console.log('BundleBody props', props)
  const bundle = props.product.parent
  const productId = props.id
  const reload = props.reload
  const initialChildren = [...props.product.children]
  const childForms = useRef({})
  const [updatedParentData, setUpdatedParentData] = useState({})
  const [activeForms, setActiveForms] = useState([])
  const [deleteItems, setDeleteItems] = useState([])
  const [addForms, setAddForms] = useState([])
  const [children, setChildren] = useState([...props.product.children])
  const setMessage = useSetRecoilState(currentMessageState)
  const { fetchWithToken } = useAuthToken()
  const getBundleParentDto = () => {
    const _sku = updatedParentData.sku ?? bundle.sku
    const _salesChannelsIds =
      updatedParentData.marketplaces ?? bundle.marketplaces.map(({ id }) => id)
    return (
      _salesChannelsIds &&
      _sku && {
        sku: _sku,
        salesChannelsIds: _salesChannelsIds
      }
    )
  }
  const updateBundleParentCmd = useUpdateBundleItemCmd(
    productId,
    getBundleParentDto(),
    (r) => {
      console.log('updateBundleParentCmd r', r)
      if (r.data.success === 'true') reload()
      else
        setMessage({
          message: `Something went wrong: ${r?.data?.messages && r.data.messages[0].text
            }`,
          severity: 'error'
        })
    }
  )
  const hasChildrenChanges = () => {
    return activeForms.length || deleteItems.length || addForms.length
  }
  const hasParentChanges = () => {
    return Object.keys(updatedParentData).length
  }

  const deleteHandle = (e, id) => {
    e.stopPropagation()
    if (!deleteItems.includes(id)) {
      setDeleteItems([...deleteItems, id])
    }
    return false
  }

  const cancel = () => {
    if (!hasChildrenChanges()) return false
    setActiveForms([])
    setDeleteItems([])
    setAddForms([])
    setChildren([...initialChildren])
  }

  const addChild = () => {
    const child = { ...blankChild }
    child.id = Date.now()
    setChildren([...children, child])
    setAddForms([...addForms, child.id])
  }

  const save = () => {
    if (!hasChildrenChanges() && !hasParentChanges()) return false
    if (hasChildrenChanges()) {
      const data = {
        parent_id: productId,
        marketplace_id: props.product.parent.marketplace_id
      }

      /* get active forms */
      if (activeForms.length) {
        const update = {}
        activeForms.map((childId) => {
          if (addForms.includes(childId) || deleteItems.includes(childId)) {
            return false
          }
          const form = childForms.current[childId]
          const fd = new FormData(form)
          update[childId] = Object.fromEntries(fd)
        })
        data.update = update
      }

      /* delete action */
      if (deleteItems.length) {
        const toDelete = []
        deleteItems.map((it) => {
          if (initialChildren.find((el) => el.id == it)) toDelete.push(it)
        })

        if (toDelete.length) data.delete = toDelete
      }

      /* add action */
      if (addForms.length) {
        const add = []
        addForms.map((childId) => {
          if (!childForms.current[childId]) return false
          const form = childForms.current[childId]
          const fd = new FormData(form)
          add.push(Object.fromEntries(fd))
        })
        data.create = add
      }

      fetchWithToken(saveBundle, { data }).then((r) => {
        reload()
      })
    }
    if (hasParentChanges()) {
      updateBundleParentCmd()
    }
  }

  const getControlsDisabled =
    hasChildrenChanges() || hasParentChanges() ? false : true

  return (
    <SnowBox aria-label="bundle-view">
      <BundleParentView aria-label="parent-info">
        <BundleTitleView>{bundle.sku} details</BundleTitleView>
        <BundleFields
          bundle={bundle}
          onUpdate={(bundleData) => setUpdatedParentData(bundleData)}
        />
      </BundleParentView>
      <SnowBox component="section" aria-label="children-info">
        <BundleTitleBlockView>
          <BundleTitleView>Children Info:</BundleTitleView>
          <AddButtonView onClick={addChild} variant="extended" size={'small'}>
            <SnowAddIcon />
            Add
          </AddButtonView>
        </BundleTitleBlockView>
        <BundleChildrenListView aria-label="children">
          {Object.keys(children).map((key) => {
            const isActive = activeForms.includes(children[key].id)
              ? true
              : false

            if (deleteItems.includes(children[key].id)) {
              return false
            }

            return (
              <BundleChildView
                key={key}
                isactive={isActive ? 1 : 0}
                onClick={(e) => {
                  const childVal = children[key].id
                  if (activeForms.includes(childVal)) return false
                  setActiveForms([...activeForms, childVal])
                  console.log(childVal)
                }}
                aria-label={'child'}
              >
                <form
                  style={{ width: '100%' }}
                  ref={(el) => (childForms.current[children[key].id] = el)}
                >
                  <SnowBadge
                    style={{ display: 'flex' }}
                    badgeContent={
                      isActive ? (
                        <ScIconButton
                          noPadding={true}
                          onClick={(evt) => deleteHandle(evt, children[key].id)}
                        >
                          <SnowCancelIcon />
                        </ScIconButton>
                      ) : (
                        0
                      )
                    }
                  >
                    <SnowList style={{ width: '100%' }}>
                      {!children[key].newChild ? (
                        <>
                          <BundleListItemView>
                            <BundleItemLabelView htmlFor="id">
                              id:{' '}
                            </BundleItemLabelView>
                            <input
                              disabled="disabled"
                              name="id"
                              id="id"
                              defaultValue={children[key].id}
                            />
                          </BundleListItemView>
                          <BundleListItemView>
                            <BundleItemLabelView htmlFor="child_sku">
                              child_sku:{' '}
                            </BundleItemLabelView>
                            <input
                              disabled="disabled"
                              name="child_sku"
                              id="child_sku"
                              defaultValue={children[key].child_sku}
                            />
                          </BundleListItemView>
                        </>
                      ) : (
                        ''
                      )}
                      {Object.keys(children[key]).map((childKey) => {
                        if (
                          childKey == 'id' ||
                          (childKey == 'child_sku' &&
                            !children[key].newChild) ||
                          childKey == 'newChild'
                        )
                          return false
                        return (
                          <BundleListItemView key={childKey}>
                            <BundleItemLabelView htmlFor={childKey}>
                              {childKey}:{' '}
                            </BundleItemLabelView>
                            <input
                              name={childKey}
                              id={childKey}
                              defaultValue={children[key][childKey]}
                            />
                          </BundleListItemView>
                        )
                      })}
                    </SnowList>
                  </SnowBadge>
                </form>
              </BundleChildView>
            )
          })}
        </BundleChildrenListView>
        <BundleActionsView>
          <ScSecondaryBtn
            onClick={cancel}
            label={'Cancel'}
            disabled={getControlsDisabled}
          />
          <ScPrimaryBtn
            onClick={save}
            label={'Save'}
            disabled={getControlsDisabled}
          />
        </BundleActionsView>
      </SnowBox>
    </SnowBox>
  )
}

export default BundleBody
