import { SnowTypography } from '../../../../../../../../../shared/components/snowComponents'
import styled from 'styled-components'

const PersonalisationRowValue = styled(SnowTypography).attrs({
  variant: 'inherit',
  component: 'div'
})`
  text-transform: lowercase;
`

export default PersonalisationRowValue
