import React from 'react'
import { SnowPaper } from '../../snowComponents'
import { ScActionsBar, ScItemsInRow } from '../../LayoutComponents'
import styled from 'styled-components'

const PageHeaderView = styled.header`
  background: ${({ theme }) => theme.mainPalette.grey.light};
`

const ScPageContentWithHeader = ({
  formFields,
  actionContent,
  pageContent, 
}) => {
  const _formFields = Array.isArray(formFields) ? formFields : [formFields]
  const _actionContent = Array.isArray(actionContent)
    ? actionContent
    : [actionContent]

  return (
    <SnowPaper aria-label={'sc-page-content-with-header'}>
      <PageHeaderView>
        <ScActionsBar justifyContent={'space-between'}>
          {formFields ? (
            <ScItemsInRow
              justifyContent={'flex-start'}
              itemWidth={100/formFields.length}
              width={formFields.length*300}
            >
              {_formFields}
            </ScItemsInRow>
          ) : null}
          {actionContent ? (
            <ScItemsInRow justifyContent={'flex-end'} itemWidth={'auto'}>
              {_actionContent}
            </ScItemsInRow>
          ) : null}
        </ScActionsBar>
      </PageHeaderView>
      {pageContent}
    </SnowPaper>
  )
}
export default ScPageContentWithHeader
