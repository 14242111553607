import { createContext, useContext } from 'react'
import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
  SerializableParam
} from 'recoil'
import { updatedScFieldsArray } from '../../../shared/components/SnowEditableField/data/updatedFieldData'
import { findAllByKey, removeArrayItem, setElementInCollectionByKey } from '../../../utils/helpers'
import { cqrsHash, integrationCqrsRequestModel } from './integrationDeterminant'
import _ from 'lodash'

const getFieldsList = (arrayWithFields) => {
  console.log('arrayWithFields', arrayWithFields)
  return findAllByKey(arrayWithFields, 'fields')
}

const getNestedFieldsValues = (configState, marketplace_id) => {
  const nestedFields = configState ? getFieldsList([configState]) : []
  console.log('nestedFields', nestedFields, configState?.values)
  const values = configState?.values ?? []
  const predefinedField = (field_id) =>
    values.find(
      ({ field_id: predefined_field_id }) => field_id === predefined_field_id
    )?.value

  return nestedFields.map(({ field_id, default_value }) => ({
    field_id,
    marketplace_id,
    value:
      typeof predefinedField(field_id) !== 'undefined'
        ? predefinedField(field_id)
        : default_value
  }))
}
// used to store currently loaded  integration origin full config
//** applies in files : IntegrationSettings.js
const integrationConfigsOriginState = atomFamily({
  key: 'integrationConfigsOriginState',
  default: (cqrsHash) => null
})
const integrationOriginValues = atomFamily({
  key: 'integrationOriginValues',
  default: []
})

export const currentIntegrationConfigOriginState = selector({
  key: 'currentIntegrationConfigOriginState',
  get: ({ get }) => get(integrationConfigsOriginState(get(cqrsHash))),
  set: ({ get, set, reset }, newValues) => {
    const hash = '' + get(cqrsHash)
    if (newValues instanceof DefaultValue) {
      console.log('WANNA TO RESET CONFIG', newValues)
      reset(updatedScFieldsArray) //todo: reset only integration related ScFields
      reset(integrationConfigsOriginState(hash))
      reset(integrationOriginValues(hash))
      reset(resetConfigGroupLoadableFieldsCmd)
      return
    }

    const marketplace_id = get(integrationCqrsRequestModel)?.channel ?? '0'
    const configFieldValues = getNestedFieldsValues(newValues, marketplace_id)
    console.log(
      'currentIntegrationConfigOriginState configFieldValues!!!!',
      hash,
      configFieldValues,
    )
    reset(updatedScFieldsArray) //todo: reset only integration related ScFields
    set(integrationConfigsOriginState(hash), newValues)
    set(integrationOriginValues(hash), configFieldValues ?? [])
  }
})
export const isFieldFromOriginValues = selectorFamily({
  key: 'isSetInOriginValues',
  get:
    (fieldId) =>
      ({ get }) => {
        const fieldFromOrigin = get(
          integrationOriginValues('' + get(cqrsHash))
        ).find(({ field_id }) => '' + field_id === '' + fieldId)
        console.log(
          'fieldFromOrigin',
          fieldId,
          fieldFromOrigin,
          get(integrationOriginValues('' + get(cqrsHash)))
        )
        return fieldFromOrigin
      }
})

//used in ConfigGroup.js to store loadable fields
const configGroupLoadableFields = atomFamily({
  key: 'configGroupLoadableFields',
  default: (cqrsGroupHash) => ({})
})
export const configLoadedGroupsAtom = atom({
  key: 'configLoadedGroupsAtom',
  default: []
})
export const resetConfigGroupLoadableFieldsCmd = selector({
  key: 'resetConfigGroupLoadableFieldsCmd',
  get: ({ get }) => { },
  set: ({ get, set, reset }, newValues) => {
    if (newValues instanceof DefaultValue) {
      get(configLoadedGroupsAtom).map((groupHash) =>
        reset(configGroupLoadableFields(groupHash))
      )
      reset(configLoadedGroupsAtom)
    }
  }
})
export const currentConfigGroupLoadableFieldsSelector = selectorFamily({
  key: 'currentConfigGroupLoadableFieldsSelector',
  get:
    (groupId) =>
      ({ get }) => {
        const cqrsGroupHash = '' + get(cqrsHash) + groupId
        const { fields, values } = get(configGroupLoadableFields(cqrsGroupHash))
        console.log(
          'currentConfigGroupLoadableFieldsSelector get',
          cqrsGroupHash,
          groupId,
          fields,
          values
        )
        return { fields, values }
      },
  set:
    (groupId) =>
      ({ get, set, reset }, newValues) => {
        const cqrsGroupHash = get(cqrsHash) + groupId
        if (newValues instanceof DefaultValue) {
          console.log(
            'currentConfigGroupLoadableFieldsSelector reset!!',
            cqrsGroupHash
          )
          set(
            configLoadedGroupsAtom,
            get(configLoadedGroupsAtom).filter((item) => item === groupId)
          )
          reset(configGroupLoadableFields(cqrsGroupHash))
          return
        }

        set(configLoadedGroupsAtom, [
          ...get(configLoadedGroupsAtom),
          cqrsGroupHash
        ])
        const marketplace_id = get(integrationCqrsRequestModel)?.channel ?? '0'
        const extractedValues = getNestedFieldsValues(newValues, marketplace_id)

        console.log(
          'currentConfigGroupLoadableFieldsSelector values!',
          newValues,
          extractedValues
        )
        const currentOriginFieldValues = get(
          integrationOriginValues(get(cqrsHash))
        )
        // const originFieldsKeys = currentOriginFieldValues.map(
        //   ({ field_id }) => field_id
        // )
        // const newFieldValues = extractedValues.filter(
        //   ({ field_id }) => !originFieldsKeys.includes(field_id)
        // )
        //
        // const updatedValues = currentOriginFieldValues.reduce(
        //   (acc, originValue) => {
        //     return [
        //       ...acc,
        //       extractedValues.find(
        //         ({ field_id: extractedFieldId }) =>
        //           originValue.field_id === extractedFieldId
        //       ) ?? originValue
        //     ]
        //   },
        //   newFieldValues
        // )
        const mergedOriginValues = _.unionBy(
          currentOriginFieldValues,
          extractedValues,
          'field_id'
        )
        // console.log(
        //   'updatedValues',
        //   currentOriginFieldValues,
        //   extractedValues,
        //   mergedOriginValues
        // )

        set(integrationOriginValues(get(cqrsHash)), mergedOriginValues)
        set(configGroupLoadableFields(cqrsGroupHash), newValues)
      }
})
const getUpdatedFieldsFromOrigin = (originFields, updatedFields) =>
  updatedFields.filter((inputField) =>
    originFields.find(
      ({ field_id: originFieldId }) =>
        typeof inputField[originFieldId] !== 'undefined'
    )
  )
const integrationUpdatedFieldsList = selectorFamily({
  key: 'integrationUpdatedFieldsList',
  get:
    (cqrsHash) =>
      ({ get }) => {
        // const integrationFields = get(integrationFieldsList(cqrsHash))
        const updatedFields = get(updatedScFieldsArray)
        console.log('integrationUpdatedFieldsList updatedFields', updatedFields)
        const originFields = get(integrationOriginValues(cqrsHash))
        console.log('integrationUpdatedFieldsList originFields', originFields)

        //merge the updated fields values frim origin set
        const integrationUpdatedFields = getUpdatedFieldsFromOrigin(
          originFields,
          updatedFields
        )
        //todo: keep integration updated fields by cqrsHash key

        console.log(
          'integrationUpdatedFieldsList integrationUpdatedFields',
          integrationUpdatedFields
        )
        return integrationUpdatedFields
      }
})
export const currentIntegrationUpdatedFieldsList = selector({
  key: 'currentIntegrationUpdatedFieldsList',
  get: ({ get }) => get(integrationUpdatedFieldsList(get(cqrsHash)))
})

export const currentIntegrationActualFieldsState = selector({
  key: 'currentIntegrationActualFieldsState',
  get: ({ get }) => {
    const originFields = get(integrationOriginValues(get(cqrsHash)))
    const updatedFields = get(currentIntegrationUpdatedFieldsList)
    const updatedIntegrationFields = updatedFields.map((updatedField) => {
      const [key, value] = Object.entries(updatedField)[0]
      key === '8460' &&
      console.log(
        'key, value',
        key,
        value,
        originFields.find(({ field_id }) => '' + field_id === '' + key)
      )
      return {
        ...originFields.find(({ field_id }) => '' + field_id === '' + key),
        value
      }
    })
    const actualValues = updatedIntegrationFields.reduce((acc, item) => {
      return setElementInCollectionByKey(acc, item, 'field_id')
    }, originFields)

    console.log(
      'originFields,actualValues,updatedFields',
      originFields,
      actualValues,
      updatedFields,
      updatedIntegrationFields
    )

    return actualValues
  }
})

export const loadedFields = atom({
  key: 'loadedFields',
  default: []
})

export const IntegrationContext = createContext({})

export function useIntegration () {
  return useContext(IntegrationContext)
}
