import React from 'react'
import {
  SnowDialog,
  SnowDialogActions,
  SnowPaper,
  SnowTypography
} from '../../../../shared/components/snowComponents'
import { SnowCloseIcon } from '../../../../shared/components/icons'
import {
  ScIconButton,
  ScSecondaryBtn,
  ScPrimaryBtn
} from '../../../../shared/components/buttons'
import styled, { css } from 'styled-components'

const StyledDialog = styled(SnowDialog)`
  position: relative;
  .MuiDialog-paper {
    overflow-y: visible;
    padding: 48px 16px 16px;
    width: ${({ width }) => (width ? `${width}px` : '500px')};
    background: ${({ theme }) => theme.mainPalette.grey.light};
  }
`

const StyledPaper = styled(SnowPaper)`
  ${({ theme }) => css`
    padding: ${theme.spacing.secondary};
    margin-bottom: ${theme.spacing.secondary};
  `}
`

const StyledTitle = styled(SnowTypography)`
  &.MuiTypography-root {
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.secondary};
    font-weight: ${({ theme }) => theme.fontWeight.main};
  }
`

const StyledActionsContainer = styled(SnowDialogActions)`
  &.MuiDialogActions-root {
    justify-content: center;
  }
`

const ScFormModal = ({
  open,
  onClose,
  onSubmit,
  title,
  content,
  labelToSubmitBtn,
  message,
  loading,
  width
}) => {
  return (
    <StyledDialog open={open} onClose={onClose} width={width} maxWidth={'sm'}>
      <ScIconButton
        onClick={onClose}
        style={{ position: 'absolute', right: '0', top: '0' }}
      >
        <SnowCloseIcon />
      </ScIconButton>

      <form onSubmit={onSubmit}>
        <StyledPaper>
          {title ? (
            <StyledTitle variant="h5" component="h3">
              {title}
            </StyledTitle>
          ) : null}
          {content ? <div>{content}</div> : null}
          {message ? <div>{message}</div> : null}
        </StyledPaper>
        <StyledActionsContainer
          className={loading ? 'actions loading' : 'actions'}
        >
          <ScSecondaryBtn
            id="modal-cancel"
            disabled={loading}
            type="button"
            onClick={onClose}
            label={'Cancel'}
          />
          {labelToSubmitBtn ? (
            <ScPrimaryBtn
              type="submit"
              disabled={loading}
              label={labelToSubmitBtn}
              style={{ minWidth: '130px' }}
            />
          ) : null}
        </StyledActionsContainer>
      </form>
    </StyledDialog>
  )
}

export default ScFormModal
