import React, { useEffect, useRef, useState } from 'react'
import ScBaseTextField from './ScBaseTextField'
import PropTypes from 'prop-types'

/**
 *
 * @param onUpdate - callback function {key,value}
 * @param mb - margin-bottom, in 'px', number
 * @param inputWidth - max-width for text field, in '%', number
 * @param labelPlacement - where label render, can be 'top', 'start', 'end'
 * @param onClick
 * @param value
 * @param code
 * @param label
 * @param required
 * @param meta
 * @param isInvalid
 * @param updateDelay
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const ScTextField = ({
  onUpdate = (evt) => console.log(evt),
  onClick,
  value = '',
  code = '',
  label = '',
  labelPlacement = 'top',
  isGroupField = false,
  inputWidth = 100,
  inputProps = {},
  fontSize='',
  mb = 0,
  type = 'text',
  isInvalid = false,
  helperText = '',
  updateDelay = 500,
  variant = 'outlined',
  ...props
}) => {
  console.log('isGroupField', isGroupField, inputWidth)
  // console.log('ScTextField props', props)
  const [poolValue, setPoolValue] = useState(value)
  const timeOutId = useRef(null)
  const fieldRef=useRef()

  const handleChange = (evt) => {
    const typedTxt = evt?.target?.value
    // console.log('handleChange', typedTxt, '2')
    setPoolValue(typedTxt)

    const updateValue = () => {
      // console.log('updateTextValue', updateDelay, typedTxt, code)
      onUpdate({ value: typedTxt, key: code })
    }
    timeOutId.current && clearTimeout(timeOutId.current)
    timeOutId.current = updateDelay
      ? setTimeout(() => updateValue(), updateDelay)
      : updateValue()
  }

  useEffect(() => {
    setPoolValue(value)
  }, [value])

  useEffect(() => {
    return () => timeOutId.current && clearTimeout(timeOutId.current)
  }, [])

  useEffect(() => {
    console.log('ScTextField fieldRef',fieldRef)
  },[fieldRef])

  return (
    <ScBaseTextField
      inputProps={{
        ...inputProps,
        'aria-label': props.multiline ? 'textarea' : 'text',
        value: poolValue,
        type: type,
        id: code,
        inputRef: fieldRef,
        onChange: handleChange,
        onClick: onClick,
        onBlur:()=>console.log('Blur'),
        focused: props.focused,
        disabled: props.disabled,
        required: props.required,
        multiline: props.multiline,
        rows: props.rows,
        error: isInvalid,
        placeholder: props.placeholder,
        'data-testid': 'text-input'
      }}
      rest ={{
        name: label ? label : 'text-field',
        variant,
        isGroupField,
        label,
        labelPlacement, 
        helperText,
        isInvalid,
        inputWidth,
        code,
        fontSize,
        mb,
        tooltipText: props.tooltip,
        ...props
      }}
    />
  )
}

export default ScTextField

ScTextField.propTypes = {
  value:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
  labelPlacement: PropTypes.oneOf(['top', 'start', 'end', '']),
  isGroupField: PropTypes.bool,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  focused: PropTypes.bool,
  required: PropTypes.bool,
  updateDelay: PropTypes.number,
  code: PropTypes.string,
  variant: PropTypes.oneOf(['outlined', 'standard']),
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.number,
  onUpdate: PropTypes.func
}
