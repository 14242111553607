function getSubtotal ({ qty, price, discount, tax }) {
  const lineTotal = price * qty - discount + tax;
  return lineTotal;
}

function getLineItemDiscount (discountLines) {
  const discounts = JSON.parse(discountLines);
  if (!discounts?.length) return 0;
  return Number(discounts[0].amount);
}

function hasOrderItemPersonalizationInOptions (options = []) {
  let hasPersonalizations = false;
  // if item.options is empty, just return false
  if (options?.length > 0) {
    // otherwise...for each object in item.options...
    options.forEach((option) => {
      // if the key of "value" has a value that loosely equates to true
      if (option.value) {
        // then return true / has a personaization
        hasPersonalizations = true;
      }
    });
  } else {
    return false;
  }
  return hasPersonalizations;
}

const paramToEntityValue = (value, type) => {
  switch (type) {
    case "numeric":
      return Number(value);
    case "string":
      return `${value}`;
  }
  return value;
};

const orderLineItemMap = [
  { requestParam: "id", entityProp: "id" },
  { requestParam: "attributes", entityProp: "attributes" },
  { requestParam: "vendor", entityProp: "vendor" },
  { requestParam: "name", entityProp: "name" },
  { requestParam: "meta", entityProp: "meta" },
  { requestParam: "status", entityProp: "status" },
  { requestParam: "sku", entityProp: "sku" },
  { requestParam: "options", entityProp: "options" },
  { requestParam: "errors", entityProp: "errors" },
  { requestParam: "created_at", entityProp: "createdAt" },
  { requestParam: "type", entityProp: "type" },
  { requestParam: "product_type", entityProp: "productType" },
  { requestParam: "additional_data", entityProp: "additionalData" },
  { requestParam: "snowaxis_product_id", entityProp: "saProductId" },
  { requestParam: "vendor_sku", entityProp: "vendorSku" },
  { requestParam: "snowaxis_product_name", entityProp: "saProductName" },
  { requestParam: "platform_product_id", entityProp: "platformProductId" },
  { requestParam: "discount_lines", entityProp: "discountLines" },
  { requestParam: "free_shipping", entityProp: "freeShipping" },
  { requestParam: "shipping_requires", entityProp: "shippingRequires" },
  { requestParam: "platform_item_id", entityProp: "platformItemId" },
  { requestParam: "gift_message", entityProp: "giftMessage" },
  { requestParam: "weight", entityProp: "weight" },
  { requestParam: "order_id", entityProp: "orderId" },
  { requestParam: "shipping_id", entityProp: "shippingIds" },
  { requestParam: "tax_lines", entityProp: "taxLines" },
  { requestParam: "parent_id", entityProp: "parentId" },
  { requestParam: "vendor_data", entityProp: "vendorData" },
  { requestParam: "vendor_issues", entityProp: "vendorIssues" },
  { requestParam: "vendor_item_id", entityProp: "vendorItemId" },
  { requestParam: "vendor_name", entityProp: "vendorName" },
  { requestParam: "vendor_status", entityProp: "vendorStatus" },
  { requestParam: "updated_at", entityProp: "updatedAt" },
  { requestParam: "price", entityProp: "price", type: "numeric" },
  { requestParam: "cost", entityProp: "cost", type: "numeric" },
  { requestParam: "refunded", entityProp: "refunded", type: "numeric" },
  {
    requestParam: "refund_amount",
    entityProp: "refundAmount",
    type: "numeric"
  },
  {
    requestParam: "base_discount_amount",
    entityProp: "baseDiscountAmount",
    type: "numeric"
  },
  { requestParam: "base_price", entityProp: "basePrice", type: "numeric" },
  { requestParam: "base_cost", entityProp: "baseCost", type: "numeric" },
  {
    requestParam: "base_tax_amount",
    entityProp: "baseTaxAmount",
    type: "numeric"
  },
  {
    requestParam: "discount_amount",
    entityProp: "discountAmount",
    type: "numeric"
  },
  { requestParam: "qty_ordered", entityProp: "qtyOrdered", type: "numeric" },
  { requestParam: "tax_amount", entityProp: "taxAmount", type: "numeric" },
  {
    requestParam: "customizations",
    entityProp: "personalizations"
  },
  {
    requestParam: "qty_canceled",
    entityProp: "qtyCanceled",
    type: "numeric"
  },
  {
    requestParam: "qty_refunded",
    entityProp: "qtyRefunded",
    type: "numeric"
  },
  {
    requestParam: "possible_vendors",
    entityProp: "possibleVendors"
  },
  {
    requestParam: "actions",
    entityProp: "doActions"
  },
  { requestParam: "qty_shipped", entityProp: "qtyShipped", type: "numeric" },
  {
    requestParam: "shipping_dates",
    entityProp: "shippingDates"
    /**  requestParam Type:
     changeable: false
     comment: null
     created_at: "2022-03-11T12:34:09+00:00"
     deleted_at: null
     earliest_delivery_date: "2022-03-23T07:00:00+00:00"
     earliest_ship_date: "2022-03-17T07:00:00+00:00"
     id: 1
     latest_delivery_date: "2022-03-31T06:59:59+00:00"
     latest_ship_date: "2022-03-19T06:59:59+00:00"
     user_id: null
     user_name: null
     **/
  }
];
const responseToEntity = (params) => {
  const _notMapped = Object.fromEntries(
    Object.entries(params).filter(
      ([key]) =>
        !orderLineItemMap.find(({ requestParam }) => requestParam === key)
    )
  );
  return orderLineItemMap.reduce(
    (acc, { entityProp, requestParam, type }) => {
      return {
        ...acc,
        [entityProp]: paramToEntityValue(params[requestParam], type)
      };
    },
    { _notMapped, _rawData: params }
  );
};

export const orderLineItemModel = (rawData) => {
  const orderItemEntity = responseToEntity(rawData);
  console.log("orderItemEntity", orderItemEntity);
  const lineItemTax = orderItemEntity.taxAmount;
  const discount = getLineItemDiscount(orderItemEntity.discountLines);
  const hasOrderItemPersonalizationInOptionsField =
    hasOrderItemPersonalizationInOptions(orderItemEntity.options);
  const hasCustomization = Boolean(orderItemEntity.personalizations.length);
  const hasTrackingInfo = Boolean(orderItemEntity.shippingIds.length);
  const isPersonalizable =
    hasCustomization ||
    [orderItemEntity.name, orderItemEntity.saProductName].find((name) =>
      name.toLowerCase().includes("personalized")
    );
  const subtotal = getSubtotal({
    qty: orderItemEntity.qtyOrdered,
    price: orderItemEntity.price,
    discount,
    tax: lineItemTax
  });
  const validModerationActions = ["approve", "refuse", "waiting"];

  const { moderation_options, editable_fields, ...rest } = orderItemEntity.meta;

  const meta = {
    moderationOptions: moderation_options
      .map(
        (status) =>
          validModerationActions.find((valid) => status.includes(valid)) ?? ""
      )
      .filter((status) => status),
    editable_fields,
    _notMapped: rest
  };

  console.log("orderItemEntity", hasCustomization, isPersonalizable);

  return {
    ...orderItemEntity,
    meta,
    lineItemTax,
    discount,
    hasOrderItemPersonalizationInOptionsField,
    hasCustomization,
    isPersonalizable,
    hasTrackingInfo,
    subtotal
  };
};
export default orderLineItemModel;
