import React, { useRef } from 'react'
import { SnowList, SnowListItem } from '../../../snowComponents'
import { ScTextField, ScComboField } from '../../baseFields'
import { ScClearButton } from '../../../buttons'
import { ScListLabel } from '../../../LayoutComponents'
import { ScAddListItemButton } from '../../../buttons'
import { removeArrayItem } from '../../../../../utils/helpers'
import { getAvailableOptions, transformOptions } from '../../../../../utils/transformOptions'
import styled from 'styled-components'
import PropTypes from 'prop-types'

/**
 * normalisedOptions - cast income options to options object
 * @param listType - type of list item.
 *        'flat' (default) -  means string-castable item type.
 *       'object'-  object contained label and value data
 * @param options - available options List can have
 * @param optionsDef - rules to parse option (-and item in case of listType==='object) value
 * @returns {} of type{ value:label,...}
 */

const FlatListView = styled(SnowList)`
  min-width: 100%;
  &.MuiList-padding {
    padding: 0;
  }
  .MuiListItem-root{
    padding: 0;
  }
`

const FlatListItemView = styled(SnowListItem)`
  &.MuiListItem-root{
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: 1fr 40px;
  }
  margin-bottom: 8px;
  :last-child {
    margin-bottom: 0;
  };
`

const FlatListFieldView = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
`

const FlatListButtonBoxView = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`

const ScFlatValuesListField = ({
  value = [],
  onUpdate,
  variant = 'standard',
  inputWidth = 100,
  isGroupField = false,
  updateDelay = 500,
  meta = {},
  alertOnNewValue = true,
  label = '',
  labelPlacement = 'top',
  code = '',
  ...props
}) => {
  const currentIdx = useRef(0)
  const arrayValue = Array.isArray(value) ? value : ['']
  const { options, optionsDef, listType } = meta || {}
  const hasOptions = !!options

  const normalisedOptions = transformOptions({ options: transformOptions(meta) })
  console.log('normalisedOptions', normalisedOptions)

  const newItemValue =
    optionsDef?.value && listType !== 'flat' ? { [optionsDef.value]: '' } : ''

  const replace = (array, index, ...items) => [
    ...array.slice(0, index),
    ...items,
    ...array.slice(index + 1)
  ]

  const canAddNew = !arrayValue.includes('')

  const handleAddNew = () => {
    currentIdx.current = arrayValue.length
    // console.log('handleAddNew', arrayValue)
    onUpdate({ value: [...arrayValue, newItemValue] })
  }

  const remove = (array, index) => [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ]

  const handleRemove = (idx) => {
    currentIdx.current = 0
    const newArray = removeArrayItem(arrayValue, idx)
    onUpdate({ value: newArray })
  }

  const handleUpdate = (idx, val) => {
    console.log('ScFlatValuesListField updated', arrayValue, idx, val)
    currentIdx.current = idx
    onUpdate({ value: replace(arrayValue, idx, val) })
  }
  // console.log('ScFlatValuesListField', value, options, code, arrayValue, meta)

  return (
    <ScListLabel label={label} labelPlacement={labelPlacement} isGroupField={isGroupField} inputWidth={inputWidth}>
      <FlatListView id={code} aria-label='flat-list'>
        {Array.isArray(arrayValue) &&
          arrayValue.map((strValue, idx) => (
            <FlatListItemView key={`${idx}-${JSON.stringify(strValue)}`}>
              {console.log('getAvailableOptions', getAvailableOptions(strValue, arrayValue, meta))}
              <FlatListFieldView>
                {hasOptions ? (
                  <ScComboField
                    code={`code-${idx}`}
                    value={strValue}
                    variant={variant}
                    meta={{ options: getAvailableOptions(strValue, arrayValue, meta) }}
                    updateDelay={updateDelay}
                    onUpdate={({ value }) => handleUpdate(idx, value)}
                    focused={idx === currentIdx.current}
                  />) : (
                  <ScTextField
                    code={`code-${idx}`}
                    value={strValue}
                    meta={meta}
                    updateDelay={updateDelay}
                    onUpdate={({ value }) => handleUpdate(idx, value)}
                    focused={idx === currentIdx.current}
                  />
                )}
              </FlatListFieldView>
              <ScClearButton onClear={() => handleRemove(idx)} />
            </FlatListItemView>
          ))}
        {canAddNew && (
          <FlatListButtonBoxView>
            <ScAddListItemButton
              inputProps={{ 'data-testid': 'add-buton' }}
              onClick={handleAddNew}
            />
          </FlatListButtonBoxView>
        )}
      </FlatListView>
    </ScListLabel>
  )
}

export default ScFlatValuesListField

ScFlatValuesListField.propTypes = {
  label: PropTypes.string,
  isGroupField: PropTypes.bool,
  labelPlacement: PropTypes.oneOf(['top', 'start', 'end']),
  inputWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.array,
  onUpdate: PropTypes.func,
  variant: PropTypes.oneOf(['standard', 'outlined']),
  updateDelay: PropTypes.number,
  meta: PropTypes.object,
}
