import React, { useContext } from 'react'
import { ScIconButton } from '../../buttons'
import { SnowDirectionIcon } from '../../icons'
import { ThemeContext } from 'styled-components'

function ScDirectionButton({ ...props }) {
  const theme = useContext(ThemeContext)

  return (
    <ScIconButton color={theme.mainPalette.color.secondary} {...props}>
      <SnowDirectionIcon fontSize={'medium'} />
    </ScIconButton>
  )
}
export default ScDirectionButton
