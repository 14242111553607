import React, { useEffect, useState } from 'react'
import { SnowExitIcon } from '../../../../shared/components/icons'
import { fetchuserinfo } from '../../../../infrastructure/api/fetches'
import useLogoutHook from '../../../auth/hooks/useLogoutHook'
import ScMenuButton from '../../../../shared/components/widgets/ScMenuButton'

const TopbarProfileMenu = () => {
  const [username, setUsername] = useState('')

  const { signOut } = useLogoutHook()

  const getUser = async () => {
    const { username } = await fetchuserinfo()
    setUsername(username)
  }

  useEffect(() => {
    getUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ScMenuButton
      label={username}
      options={[{ label: 'Log Out', onClick: signOut, icon: SnowExitIcon }]}
    />
  )
}

export default TopbarProfileMenu
