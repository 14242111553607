import {
  fakeFetch,
  fetchConfigurationsFake
} from '../../../infrastructure/service/tests/stubs'

export const FETCH_PERSONALIZATION_ITEMS_URL = 'personalizationInfo/grid/items'
export const FETCH_PERSONALIZATION_FIELDS_URL =
  'personalizationInfo/grid/fields'

import { portalRequestor } from '../../../infrastructure/api/v1/requestors'

export const fetchPersonalizations = async (params) => {
  const { limit, ...restParams } = params
  const url = FETCH_PERSONALIZATION_ITEMS_URL
  const per_page = limit
  const fetchParams = { ...restParams, per_page }
  console.log('params to fetch', fetchParams)

  // const resp = await fakePersonalizationsFetch({ page, limit })
  // return resp.json()

  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchPersonalizations',
    fetchParams: fetchParams
  })
}

export const fetchPersonalizationFields = async () => {
  const url = FETCH_PERSONALIZATION_FIELDS_URL
  // const per_page = limit
  // console.log('{ page, limit }', { page, per_page })

  // const resp = await fakePersonalizationsFetch({ page, limit })
  // return resp.json()

  return await portalRequestor({
    type: 'get',
    url,
    caller: 'fetchPersonalizations'
    // fetchParams: { page, per_page, pstatus: '' }
  })
}
