import React, { useContext, useEffect, useState } from 'react'
import ScSimpleTable from '../../../../shared/components/tables/ScSimpleTable'
import { BatchContext } from './BatchView'
import { batchItemFieldsDescription } from '../../infrastructure/fakeBatches'
import { useFetchBatchItemsCmd } from '../../infrastructure/bachesApi'

const PER_CURSOR_LIMIT = 10000

export const useBatchItems = ({ batchId }) => {
  const [items, setItems] = useState([])
  const fields = batchItemFieldsDescription
  const [batchItemsCursor, setBatchItemsCursor] = useState(null)
  const [perCursor, setPerCursor] = useState(PER_CURSOR_LIMIT)
  const [totals, setTotals] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const fetchBatchItemsCursorCmd = useFetchBatchItemsCmd(
    { batchId, cursor: batchItemsCursor, per_cursor: perCursor },
    ({ data, total_records }) => {
      data && setItems(data)
      typeof total_records !== 'undefined' && setTotals(total_records)
    },
    (r) => console.log('onFail', r)
  )

  useEffect(() => {
    if (!batchId) return
    try {
      setIsLoading(true)
      fetchBatchItemsCursorCmd().then(() => setIsLoading(false))
    } catch (e) {
      console.log('useBatchView Exception', e)
      setIsLoading(false)
    }
    // setItems
  }, [batchId])
  return { data: { fields, items }, totals, isLoading }
}

const BatchItemsList = ({ fields, items }) => {
  const { batchId, i } = useContext(BatchContext)
  return (
    <div>
      <ScSimpleTable
        dataRows={items}
        fieldsList={Object.keys(fields).filter(
          (fieldKey) => fields[fieldKey]?.visible !== false
        )}
        keyField={'id'}
        preselected={[]}
      />
    </div>
  )
}

export default BatchItemsList
