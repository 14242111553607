import styled from 'styled-components'

const OrderDashboardArrowsView = styled.div`
  margin-top: auto;  
  text-align: center;
  button {
    margin: 0 5px;
  }
`

export default  OrderDashboardArrowsView