import objWithParent from '../../../utils/parenter'

const PERSONALIZATION_ITEMS_APPROVE_URL = 'grid/approve'
const PERSONALIZATION_ITEM_FIELDS_URL = 'grid/fields'
const PERSONALIZATION_ITEMS_URL = 'grid/items'

import { useEntityCommand } from '../../../infrastructure/api/v2/useEntityApi'
import { useRecoilValue } from 'recoil'
import { selectedBulkItemIds } from '../../../shared/components/BulkComponents/data/selectedBulkItemsState'
import useFetchedResult from '../../../infrastructure/api/v2/useFetchedResult'

export const API_PERSONALIZATIONS = objWithParent({
  url: '/personalization',

  FETCH: {
    url: `/${PERSONALIZATION_ITEMS_URL}`,
    method: 'GET',
    caller: 'API_PERSONALIZATIONS fetch!',
    validator: () =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  },
  FETCH_FIELDS: {
    url: `${PERSONALIZATION_ITEM_FIELDS_URL}`,
    method: 'GET',
    caller: 'PERSONALIZATION_ITEM_FIELDS_URL fetch!',
    validator: () =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  },
  APPROVE_ITEMS: {
    url: `${PERSONALIZATION_ITEMS_APPROVE_URL}`,
    method: 'POST',
    caller: 'APPROVE_PERSONALIZATION_ITEMS_URL!',
    validator: () =>
      // {
      // limit = 20,
      // offset = 0,
      // search = '',
      // searchFields = ['snow_group_id', 'product_sku']
      // }
      true //limit && offset && search && searchFields
  }
})

export const usePersonalizationItemFields = () => {
  console.log('usePersonalizationItemFields start')
  const url = `${API_PERSONALIZATIONS.url}/${API_PERSONALIZATIONS.FETCH_FIELDS.url}`
  const fields = useFetchedResult(
    { ...API_PERSONALIZATIONS.FETCH_FIELDS, url },
    []
  )
  console.log('usePersonalizationItemFields end', fields)
}

export const useApprovePersonalizationsCmd = (
  { selectedItemIds, perPage = 8, orderBy = 'desc' },
  responseHandler = (resp) =>
    console.log('useApprovePersonalizationsCmd responseHandler', resp)
) => {
  const selectedItems = useRecoilValue(selectedBulkItemIds)
  const approveCustomizationCmd = useEntityCommand(
    0,
    {
      ...API_PERSONALIZATIONS.APPROVE_ITEMS,
      params: { items: selectedItems, per_page: perPage, order_by: orderBy }
    },
    responseHandler
  )
  return approveCustomizationCmd
}
