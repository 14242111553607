import { requestor } from './requestor'

/**
 * todo describe requestConfig fields
 * @param requestConfig
 * @param resultCallback
 * @param setMessage
 */
export const apiRequest = (
  requestConfig,
  resultCallback = () => {},
  setMessage = ({ severity, message }) =>
    console.log('apiRequest', severity, message)
) => {
  // console.log('ApiRequest', requestConfig)
  const processResult = ({ data, message, severity }) => {
    setMessage({ severity, message })
    resultCallback({
      data,
      error: severity === 'error' ? message : '',
      messages: message,
      isLoading: false
    })
  }
  //if no validator - then  params validation is true
  const { validator = () => true, reflect = () => this, params } = requestConfig

  if (!validator(params)) {
    processResult({
      data: null,
      message: ['incorrect params in apiRequest'],
      severity: 'error'
    })
    return
  }

  requestor(requestConfig)
    .then((response) => {
      console.log('apiRequest requestor!', response)
      if (response.status >= 200 && response.status < 300) {
        processResult({
          data: response.data,
          message: [`${requestConfig.caller} request success`],
          severity: 'success'
        })
        // resultCallback({ data: response.data, error: null, isLoading: false })
      } else {
        processResult({
          data: response.data,
          message: [response.statusText],
          severity: 'error'
        })
      }
    })
    .catch((err, ...r) => {
      console.debug('catch err', err)
      console.debug('catch err', err?.response?.data)
      const { messages, success } = err?.response?.data ?? {}
      console.log(err.response?.statusText)
      processResult({
        data: null,
        message: messages ?? [err.message],
        severity: 'error'
      })
    })
}

/**
 * function to get promised data of API request with simple response validation
 * @param apiCfg - config of API request
 * @returns {Promise<unknown>}
 */
export const apiDataRequestor = (apiCfg) => {
  const token = JSON.parse(localStorage.getItem('token'))

  return new Promise(function (resolve, reject) {
    try {
      apiRequest(
        { ...apiCfg, token },
        ({ data, error, isLoading, ...rest }) => {
          // console.log('apiRequest', data, error, rest)
          if (!error) resolve({ data })
          else reject(error)
        }
      )
    } catch (e) {
      console.log('exception in apiRequest', e)
      reject(e)
    }
  })
}
