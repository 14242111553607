import React from 'react'
import PropTypes from 'prop-types'
import { ScWhiteButton } from '../../../../../../shared/components/buttons'
import { ReactComponent as CancelImg } from '../../../../../app/images/SVGs/cancel.svg'
import { useCurrentOrder } from '../../../data/currentOrderState'

const CancelOrderButton = (props) => {
  const order = useCurrentOrder()

  const isDisabled = (order) => {
    if (order.orderhubStatus?.includes('cancelled')) {
      return true
    } else {
      return false
    }
  }

  const handleClick = (order) => {
    if (isDisabled(order)) {
      return
    }
    props.toggleShowCancelModal()
  }

  return (
    <ScWhiteButton
      disabled={isDisabled(order)}
      onClick={() => handleClick(order)}
      icon={() => <CancelImg />}
      label={'Cancel'}
    />
  )
}

CancelOrderButton.propTypes = {
  toggleShowCancelModal: PropTypes.func.isRequired
}

export default CancelOrderButton
