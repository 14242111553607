import { SnowTypography } from '../../../../../../shared/components/snowComponents'
import styled, { css } from 'styled-components'

const SidebarSubtitleView = styled(SnowTypography).attrs({
  component: 'h3',
  variant: 'body1',
  textTransform: 'uppercase'
})`
  display: inline-block;
  &.MuiTypography-root {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.small};
      font-weight: ${theme.fontWeight.bold};
    `}
  }
`

export default SidebarSubtitleView
