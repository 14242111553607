import React from 'react'
import { SnowTypography } from '../../../../../../../shared/components/snowComponents'
import {
  getShippingColor
} from '../../../../../infrastructure/helpers'
import ScOrderItemDateRange from './orderItemFields/ScOrderItemDateRange'
import styled, { css } from 'styled-components'

const OrderShippingRowView = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    font-size: ${theme.fontSize.secondary};
  `}
`

const OrderShippingLabelView = styled(SnowTypography).attrs({
  component: 'p',
})`
  flex: 0 0 20%;
  min-width: 100px;
  margin-right: ${({ theme }) => theme.spacing.secondary};
  &.MuiTypography-root {
    font-size: ${({ theme }) => theme.fontSize.secondary};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`

const OrderShippingDateView = styled.div`
  flex: 1 1 auto;
  .MuiTypography-root {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    ${({ color }) => color ? `color:${color};` : ''}
  }
`

const OrderItemShippingDates = ({ item }) => {
  const { status } = item
  const {
    id,
    earliest_ship_date,
    latest_ship_date,
    earliest_delivery_date,
    latest_delivery_date,
    user_id,
    user_name,
    comment,
    changeable,
    created_at,
    deleted_at
  } = item?.shippingDates ?? {}

  const shippingData = [
    { title: ' Ship by:', start: 'shipping_dates.earliest_ship_date', end: 'shipping_dates.latest_ship_date' },
    { title: 'Deliver by:', start: 'shipping_dates.earliest_delivery_date', end: 'shipping_dates.latest_delivery_date' }
  ]

  return (
    <div
      key={id}
      onClick={(evt) => evt.stopPropagation()}
    >
      {shippingData.map(({ title, start, end }, index) => (
        <OrderShippingRowView key={index}>
          <OrderShippingLabelView>{title}</OrderShippingLabelView>
          <OrderShippingDateView
            color={
              latest_ship_date && status !== 'shipped' && index === 0
                ? getShippingColor(latest_ship_date) : ''
            }
          >
            <ScOrderItemDateRange
              orderItem={item}
              startDatePath={start}
              endDatePath={end}
            />
          </OrderShippingDateView>
        </OrderShippingRowView>
      ))}
    </div>
  )
}

export default OrderItemShippingDates
