import React from 'react'
import { atom, selector } from 'recoil'
/**
 * function to emulate Fetching from external resource (in testing goals)
 *
 * @param data
 * @param timeout
 * @returns {Promise} with successfully resolving with {data} in {timeout} milliseconds
 */
const fakeFetch = ({ data, timeout }) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        json: () => new Promise((resolve) => resolve(data))
      })
    }, timeout)
  })
}

const fakeAxios = ({ data, timeout }) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        data
      })
    }, timeout)
  })
}

import { defaultFieldList } from '../infrastructure/fakePersonalizationFields'

const itemFieldsLoader = selector({
  key: 'itemFieldsLoader',
  get: async ({ get }) =>
    await fakeAxios({ data: defaultFieldList, timeout: 1000 })
})

export const filterablePersonalizationItemFields = atom({
  key: 'filterablePersonalizationItemFields',
  default: []
})

export const loadedPersonalizationItemsFields = atom({
  key: 'loadedPersonalizationItemsFields',
  default: itemFieldsLoader
})

export default loadedPersonalizationItemsFields
