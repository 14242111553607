import React, { useEffect, useState } from 'react'
import { ScBasicModal } from '../../../../../shared/components/modals'
import VendorShippingMethodsMapper from '../shippingMethods/shippingMethodsMapper'

const ShippingMethodsModal = ({
  open,
  onClose,
  entityId = 0,
  entityName = '',
  defaultValue = '',
  entity = {}
}) => {
  const [mapping, setMapping] = useState([])
  const [saveCmd, setSaveCmd] = useState(null)
  const [savingResult, setSavingResult] = useState(null)
  const [title, setTitle] = useState(
    `Shipping Methods Mapping for Vendor #${entityId}`
  )
  const onContentInit = ({ contentTitle }) => {
    console.log('onContentInit', contentTitle)
    setTitle(contentTitle)
  }
  const onMap = ({ shippingMap, saveCmd: save }) => {
    console.log('ShippingMethodsModal onMap', shippingMap, save)
    setSaveCmd(() => save)
    setMapping(shippingMap)
  }

  const onSave = () => {
    console.log('ShippingMethodsModal onSave', mapping, saveCmd)
    setSavingResult(typeof saveCmd === 'function' && saveCmd())
  }

  useEffect(() => {
    console.log('ShippingMethodsModal savingResult', !!savingResult)
    !!savingResult && onClose()
  }, [savingResult])

  return (
    <ScBasicModal
      open={open}
      onClose={onClose}
      title={title}
      labelForCancelBtn={'Close'}
      content={
        <VendorShippingMethodsMapper
          onMap={onMap}
          onInit={onContentInit}
          vendor={entityId}
          vendorName={entityName}
          defaultValue={defaultValue}
        />
      }
    />
  )
}

export default ShippingMethodsModal
