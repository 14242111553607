import React, { useEffect } from 'react'
import { useResetRecoilState, useSetRecoilState } from 'recoil'
import { min } from 'date-fns'
import { setScPageHeaderBgColorCmd } from '../../../../app/data/currentHeadingState'
import OrderLineItem from './OrderLineItem/OrderLineItem'
import { getShippingColor } from '../../../infrastructure/helpers'
import { OrderDetailsList } from './_styled'

const OrderItems = ({ items = [] }) => {
  const setHeadingBgColor = useSetRecoilState(setScPageHeaderBgColorCmd)
  const resetHeadingBgColor = useResetRecoilState(setScPageHeaderBgColorCmd)

  const most_latest_ship_date = min(
    items
      .filter(
        (item) =>
          item?.shippingDates?.latest_ship_date && item?.status !== 'shipped'
      )
      .map(({ shippingDates: { latest_ship_date } }) => {
        console.log(
          'latest_ship_date',
          latest_ship_date,
          new Date(latest_ship_date)
        )
        return new Date(latest_ship_date)
      })
  )
  const severityColor = getShippingColor(most_latest_ship_date)

  useEffect(() => {
    setHeadingBgColor(severityColor)

    return () => resetHeadingBgColor()
  }, [])

  console.log('most_latest_ship_date', { most_latest_ship_date, severityColor })
  if (!items.length) return null
  return (
    <OrderDetailsList>
      {/*{latest_ship_date}*/}
      {items.map((item, index) => (
        <OrderLineItem item={item} key={`${JSON.stringify(item)}-${index}`} />
      ))}
    </OrderDetailsList>
  )
}

export default OrderItems
