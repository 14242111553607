import React, {useContext} from 'react'
import { SnowEditIcon } from '../../icons'
import { ScIconButton } from '../../buttons'
import { ThemeContext } from 'styled-components'

const ScEditButton = ({ onClick, ...props }) => {
  const theme=useContext(ThemeContext)
  return (
    <ScIconButton
      aria-label="edit"
      color={theme.mainPalette.grey[200]}
      onClick={(evt) => {
        evt.stopPropagation()
        onClick()
      }}
      {...props}
    >
      <SnowEditIcon />
    </ScIconButton>
  )
}

export default ScEditButton
