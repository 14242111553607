import React from 'react'
import {
  SnowDialog,
  SnowDialogContent,
  SnowDialogActions,
  SnowTypography
} from '../../../../shared/components/snowComponents'
import { ScPrimaryBtn, ScSecondaryBtn } from '../../buttons'
import styled, { css } from 'styled-components'

const BasicModalHeadView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => css`
    padding: ${theme.spacing.secondary};
    margin-bottom: ${theme.spacing.main};
    background: ${theme.mainPalette.grey.soLight};
    box-shadow: ${theme.shadow.decorative};
    border-radius: 5px 5px 0 0;
  `}
`

const BasicModalHeadFormView = styled.div`
  min-width: 200px;
  margin-left: ${({ theme }) => theme.spacing.secondary};
  flex: 0 0 auto;
`

const BasicModalActionsView = styled(SnowDialogActions)`
  &.MuiDialogActions-root {
    justify-content: center;
    padding: ${({ theme }) => theme.spacing.secondary};
  }
`

const ScBasicModal = ({
  open,
  onClose,
  onConfirm,
  title,
  headForm,
  content,
  actions,
  labelForConfirmBtn = '',
  labelForCancelBtn = '',
  onConfirmProps = {},
  maxWidth
}) => {
  return (
    <SnowDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog"
      fullWidth
      maxWidth={maxWidth ? maxWidth : 'md'}
      sx={{ overflowY: 'visible' }}
      PaperProps={{ style: { overflowY: 'visible' } }}
    >
      {title ? (
        <BasicModalHeadView>
          <SnowTypography variant="h6" component="h3" fontWeight={500}>
            {title}
          </SnowTypography>
          {headForm ? (
            <BasicModalHeadFormView>{headForm}</BasicModalHeadFormView>
          ) : null}
        </BasicModalHeadView>
      ) : null}
      {content ? (
        <SnowDialogContent style={{ overflowY: 'scroll' }}>
          {content}
        </SnowDialogContent>
      ) : null}
      {
        <BasicModalActionsView>
          {actions ? (
            actions
          ) : (
            <>
              <ScSecondaryBtn
                label={labelForCancelBtn ? labelForCancelBtn : 'Cancel'}
                onClick={onClose}
                style={{ padding: '9px 11px', minWidth: '100px' }}
              />
              {onConfirm ? (
                <ScPrimaryBtn
                  label={labelForConfirmBtn ? labelForConfirmBtn : 'OK'}
                  onClick={onConfirm}
                  disabled={onConfirmProps.disabled}
                  style={{ minWidth: '100px' }}
                />
              ) : null}
            </>
          )}
        </BasicModalActionsView>
      }
    </SnowDialog>
  )
}
export default ScBasicModal
