import React from 'react'
import { SnowCloseIcon } from '../../../icons'
import styled from 'styled-components'

const StyledTag = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background: ${({theme})=>theme.mainPalette.grey.light};
  border-radius: 12px;
  box-sizing: content-box;
  padding: 0 6px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }
`

const StyledLabel = styled.span`
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
`

const StyledCloseIcon = styled(SnowCloseIcon)`
  border-radius: 50%;
  background:${({theme})=>theme.mainPalette.grey.middle};
  &.MuiSvgIcon-root {
    font-size: 10px;
    cursor: pointer;
    fill:${({theme})=>theme.mainPalette.grey.light};
    padding: 2px;
  }
`

function SnowTag({ label, onDelete, ...props }) {
  return (
    <StyledTag  {...props}>
      <StyledLabel>{label}</StyledLabel>
      <StyledCloseIcon  onClick={onDelete} />
    </StyledTag>
  )
}

export default SnowTag
