import React, { useContext } from 'react'
import { SnowTypography } from '../../../../shared/components/snowComponents'
import { ScConfirmationAlert } from '../../../../shared/components/modals'
import { ThemeContext } from 'styled-components'
import {
  holdOrder,
  unHoldOrder,
  fetchSingleOrder
} from '../../infrastructure/fetches'
import { useNavigate } from 'react-router-dom'

function getAction(status, part) {
  if (status?.includes('hold')) {
    if (part === 1) {
      return 'un-hold'
    } else if (part === 2) {
      return "off of 'Hold'?"
    }
  } else {
    if (part === 1) {
      return 'hold'
    } else if (part === 2) {
      return "on 'Hold'?"
    }
  }
}

function getButtonText(status) {
  if (status?.includes('hold')) {
    return 'Un-Hold'
  } else {
    return 'Hold'
  }
}

const OnHoldModal = (props) => {
  const theme = useContext(ThemeContext)
  const status = props?.order?.orderhubStatus
  const navigate = useNavigate()
  const fetchUpdatedOrder = ({ orderID }) => {
    fetchSingleOrder({ orderID }).then((response) => {
      // push new order to OrderDetail state
      props.updateOrderInDetailState(response.data)
      // push new order to OrderGrid setState
      // props.updateOrderInGridState(response.data)
      // close modal
      props.toggleShowModal()
      props.toggleShowConfirmationModal({
        success: true,
        message: 'hold-message-success'
      })
    })
  }

  const handleError = (data) => {
    props.toggleShowModal()
    props.toggleShowConfirmationModal({
      success: false,
      message: data.error
    })
    if (data.status === 'Token is Invalid') {
      setTimeout(() => {
        navigate('/')
      }, 3000)
    }
  }

  const handleConfirmation = (order) => {
    const orderID = order.id
    if (order?.orderhubStatus.includes('hold')) {
      unHoldOrder({ orderID }).then((data) => {
        if (data.success) {
          fetchUpdatedOrder({ orderID })
        } else {
          handleError(data)
        }
      })
    } else {
      holdOrder({ orderID }).then((data) => {
        if (data.success) {
          fetchUpdatedOrder({ orderID })
        } else {
          handleError(data)
        }
      })
    }
  }

  return (
    <ScConfirmationAlert
      isShowAttention={props.showModal}
      onClose={props.toggleShowModal}
      onConfirm={() => handleConfirmation(props.order)}
      labelForConfirmBtn={`Confirm ${getButtonText(status)}`}
      labelForCancelBtn="Go back"
      message={{
        content: (
          <>
            <SnowTypography variant={'body1'} component={'p'} align={'center'}>
              {`Are you sure you would like to ${getAction(
                status,
                1
              )} order number`}
            </SnowTypography>
            <SnowTypography
              variant={'h6'}
              component={'p'}
              align={'center'}
              fontWeight={theme.fontWeight.bold}
            >
              {props.order.orderNumber}
            </SnowTypography>
          </>
        )
      }}
    />
  )
}

OnHoldModal.propTypes = {
  // order: PropTypes.shape().isRequired,
  // showModal: PropTypes.bool.isRequired,
  // toggleShowModal: PropTypes.func.isRequired,
  // updateOrderInDetailState: PropTypes.func.isRequired,
  // updateOrderInGridState: PropTypes.func.isRequired,
  // toggleShowConfirmationModal: PropTypes.func.isRequired
}

export default OnHoldModal
