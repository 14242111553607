import React from 'react'
import { atom, atomFamily, selector } from 'recoil'

export const searcherState = atom({
  key: 'searcherState',
  default: '' //search string
})
export const searcherFamily = atomFamily({
  key: 'searcherFamily',
  default: '' //search string
})
