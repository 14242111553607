import React, { Fragment, useEffect } from 'react'
import { SnowList, SnowListItem, SnowPaper, SnowTypography } from '../snowComponents'
import styled from 'styled-components'

const ProductTitleView = styled(SnowTypography)`
  display: flex;
  align-items: flex-start;
  svg {
    ${({ theme }) => `
      margin-right: ${theme.spacing.main};
      margin-left: ${theme.spacing.secondary};
    `}
    height: 38px;
    width: 38px;
  }
  &.MuiTypography-root {
    margin-bottom:  ${({ theme }) => theme.spacing.secondary};
  }
  .MuiTypography-root {
    ${({ theme }) => `
      font-weight: ${theme.fontWeight.bold};
      color: ${theme.mainPalette.typography.soDark};
    `}
    line-height: 1;
  }
`

const ProductPaperView = styled(SnowPaper)`
  ${({ theme }) => `
    padding: ${theme.spacing.secondary};
    margin-bottom:  ${theme.spacing.main};
  `}
  width: 100%;
  min-width: 290px; 
`

const ScGroupContent = ({
  groupIcon,
  groupCode,
  groupPath,
  level,
  items: groupItems,
  label,
  getGroupContent,
  editable
}) => {
  const GroupIcon = () => (groupIcon)
  const hasItems = Boolean(groupItems.length)

  useEffect(() => {
    console.log('ScGroupContent constructor')
    return () => console.log('ScGroupContent destructor')
  }, [])

  return (
    <Fragment>
      {hasItems ? (
        <ProductPaperView
          elevation={(level == 1) * 1}
        >
          <ProductTitleView
            variant={`h${4 + level}`}
          >
            {groupIcon ? <GroupIcon /> : null}
            <SnowTypography component={'span'} variant={'inherit'}>{label}</SnowTypography>
          </ProductTitleView>
          <SnowList>
            {groupItems.map((item) => {
              const itemLinePath = groupPath.replace(
                '%key%',
                `${groupCode}.%key%`
              )
              return (
                <SnowListItem dense={true} key={JSON.stringify(item)}>
                  {getGroupContent({
                    meta: item.meta,
                    values: item.values,
                    level,
                    linePath: `${itemLinePath}`
                  })}
                </SnowListItem>
              )
            })}
          </SnowList>
        </ProductPaperView>
      ) : (
        <ProductTitleView
          variant={`h6`}
        >
          <SnowTypography component={'span'} variant={'inherit'}>No {label}</SnowTypography>
        </ProductTitleView>
      )}
    </Fragment>
  )
}

export default ScGroupContent
