import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ScGoBackButton } from '../../../../shared/components/buttons'

function getURL() {
  let url = '/home/order_hub/grid'
  if (localStorage.getItem('orderDetailURL')) {
    url = localStorage.getItem('orderDetailURL')
  }
  return url
}

const HeaderLinks = () => {
  const navigate = useNavigate()
  console.log('header buttons')
  console.log(navigate)
  return <ScGoBackButton onBack={() => navigate(-1)} />
}

export default HeaderLinks
