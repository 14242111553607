import React from 'react'
import PropTypes from 'prop-types'

import ScAddress from './ScAddress'

const ShippingAddress = ({ onUpdate, orderId, values }) => {
  const shippingSpecificFields = ['vat_number']
  return (
    <ScAddress
      onUpdate={onUpdate}
      type={'shipping'}
      orderId={orderId}
      orderAddress={values}
    />
  )
}

ShippingAddress.propTypes = {
  values: PropTypes.shape().isRequired
}

export default ShippingAddress
