import {
  atom,
  useRecoilState,
  selectorFamily,
  atomFamily,
  useRecoilValue,
} from 'recoil'
import { fetchProjectVendors, fetchVendors } from '../infrastructure/v1/apis'
import { replaceArrayItem } from '../../../utils/helpers'
import { searcherState } from '../../../shared/components/searcher/data/searcherState'

const loadedVendorsAtom = atomFamily({
  key: 'loadedVendorsAtom',
  default: selectorFamily({
    key: 'vendorsLoader',
    get:
      (projectId) =>
      async ({ get }) => {
        const { data: vendors, ...serviceInfo } = await fetchProjectVendors({
          projectId
        })
        return vendors ?? []
      }
  })
})

const loadingVendorsState = atom({
  key: 'vendorsLoadingState',
  default: { isLoading: false, loadingError: false }
})

export const vendorWithId = selectorFamily({
  key: 'vendorWithId',
  get:
    (vendorId) =>
    ({ get }) =>
      get(loadedVendorsAtom('0')).find(({ id }) => +id === +vendorId),
  set:
    (vendorId) =>
    ({ set, get }, newValue) => {
      const vendors = get(loadedVendorsAtom('0'))
      const index = vendors.findIndex(({ id }) => +id === +vendorId)
      set(loadedVendorsAtom('0'), [
        ...replaceArrayItem(vendors, index, newValue)
      ])
    }
})

const vendorsBySearchStr = selectorFamily({
  key: 'vendorWithId',
  get:
    ({ searchStr = '', projectId = '0' }) =>
    ({ get }) => {
      const vendors = get(loadedVendorsAtom(projectId + ''))
      console.log('searchStr', searchStr, typeof searchStr, vendors)
      return (
        vendors.filter(({ name }) =>
          name?.toLowerCase().includes(searchStr?.toLowerCase())
        ) ?? []
      )
    }
})

const useVendors = (projectId = '0') => {
  const searchStr = useRecoilValue(searcherState)
  const filteredVendors = useRecoilValue(
    vendorsBySearchStr({ searchStr: searchStr, projectId: projectId + '' })
  )
  const [loadingState, setLoadingState] = useRecoilState(loadingVendorsState)


  return { data: filteredVendors, ...loadingState }
}

export default useVendors
