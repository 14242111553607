import axios from 'axios'

const PORTAL_API_URL = process.env.REACT_APP_URL + '/portal'
const AUTH_API_URL = process.env.REACT_APP_URL

/** function responsible for doing request from SA to external APIs
 * adapter between Snow Axis and HTTP request library (axios originally)
 *
 * @param token
 * @param method
 * @param apiUrl
 * @param urlType
 * @param params
 * @param caller
 * @param version
 * @returns {Promise<*>}
 */
export const requestor = async ({
  token = '',
  method,
  url: apiUrl,
  urlType = 'portal',
  params = {},
  caller = 'anonymous',
  version = 'v1',
  headers = {}
}) => {
  const url =
    urlType === 'root'
      ? `${AUTH_API_URL}${apiUrl}`
      : `${PORTAL_API_URL}/${version}${apiUrl}`
  const getParams = method === 'GET' ? params : {}
  const config = {
    method,
    url,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...headers
    },
    params: params,
    data: params
  }

  console.log('requestor: ', config)

  return axios(config)
}
