import React from 'react'
import { ConfigField } from './ConfigField'
import { ScFieldsGroup } from '../../../../shared/components/LayoutComponents'

const FlatFieldList = ({
  fieldList,
  values,
  updateValue,
  onError = () => { }
}) => {
  console.log('FlatFieldList', fieldList)

  if (!Array.isArray(fieldList)) {
    console.error('FlatFieldList is not an Array:', fieldList)
    return null
  }
  return (
    <>
      <ScFieldsGroup
        inputWidth={70}
        labelPlacement="start"
        aria-label={'FlatFieldList'}
      >
        {fieldList.map((field) => (
          <ConfigField
            key={field.field_id}
            field={field}
            updateValue={updateValue}
            onError={onError}
          />
        ))}
      </ScFieldsGroup>
    </>
  )
}

export default FlatFieldList
