import React, { Fragment, useEffect } from 'react'
import { SnowBox } from '../snowComponents'
import { ScDateField, ScTextField } from './baseFields'
import clsx from 'clsx'
import { v4 } from 'uuid'

const EditableFieldProxy = (props) => {
  // console.log('EditableFieldProxy', props.type)
  const { type,  ...rest } = props
  switch (type) {
    case 'date':
      return <ScDateField {...rest} />
  }
  return <ScTextField {...rest} />
}
const getPositionClass = (fieldPath) => {
  const position = {
    col1: [
      'shipping_dates.earliest_ship_date',
      'shipping_dates.earliest_delivery_date'
    ].includes(fieldPath),
    col2: [
      'shipping_dates.latest_ship_date',
      'shipping_dates.latest_delivery_date'
    ].includes(fieldPath),
    wide: ['shipping_dates.comment'].includes(fieldPath)
  }
  return position
}
const ScEditableFieldsTree = ({ fieldsObj, path = '', onUpdate }) => {
  // console.debug('EditableFieldsTree', Object.entries(fieldsObj))

  useEffect(() => {
    console.log('ScEditableFieldsTree created', path, fieldsObj)
    return () => console.log('ScEditableFieldsTree destroyed', path, fieldsObj)
  }, [])
  return (
    <SnowBox p={1} className={`box-${path.replace('.', '_')}`}>
      {Object.entries(fieldsObj).reduce((acc, [parent, child]) => {
        const { id, label, type, default_value, visible = true } = child
        const isFieldChild =
          typeof id === 'string' &&
          typeof label === 'string' &&
          typeof type === 'string'
        const isVisible = visible !== false
        const childKey = id ?? parent ?? v4()
        const newPath = path ? path + '.' + parent : parent
        const code = path + '.' + id
        console.log('Child default_value', default_value)
        return [
          ...acc,
          <Fragment key={childKey}>
            {isFieldChild ? (
              <SnowBox
                className={clsx([
                  `field-${newPath.replace('.', '_')}`,
                  getPositionClass(newPath)
                ])}
              >
                <EditableFieldProxy
                  type={type}
                  edit={true}
                  code={code}
                  label={label.replaceAll('_', ' ')}
                  meta={{ editType: type }}
                  value={child.default_value}
                  autoFocus={false}
                  labelPlacement={'top'}
                  uid={id}
                  mb={8}
                  onUpdate={({ value }) => onUpdate({ value, code })}
                  disabled={!isVisible}
                />
              </SnowBox>
            ) : (
              <>
                {parent}:
                <ScEditableFieldsTree
                  fieldsObj={child}
                  path={newPath}
                  onUpdate={onUpdate}
                />
              </>
            )}
          </Fragment>
        ]
      }, [])}
    </SnowBox>
  )
}
export default ScEditableFieldsTree
