import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { SnowTypography, SnowBox } from '../../../../shared/components/snowComponents'
import { ScDropdownField, ScTextField, ScDateField } from '../../../../shared/components/SnowEditableField/baseFields'
import { ScItemsInRow } from '../../../../shared/components/LayoutComponents'
import { currentOrderState } from '../data/currentOrderState'
import {
  fetchOrderVendorCarriers,
  fetchVendorOrderCarriers
} from '../../infrastructure/fetches'

const EnterTrackingBody = ({
  vendor,
  vendorOrder,
  onTrackingReady,
  showShippingDate = false
}) => {
  const [carriers, setCarriers] = useState([])
  const [carrier, setCarrier] = useState('')
  const [shippingDate, setShippingDate] = useState(null)
  const [tracking, setTracking] = useState('')
  const currentOrder = useRecoilValue(currentOrderState)
  const hasVendorOrder = !!vendorOrder

  console.log('hasVendorOrder', hasVendorOrder)
  useEffect(() => {
    if (!carrier || !tracking) return onTrackingReady(null)

    const params = {
      carrier: carrier,
      tracking: tracking
    }
    const requestedVendorKey = hasVendorOrder
      ? 'vendor_order_id'
      : 'order_vendor_id'
    const requestedVendorValue = hasVendorOrder
      ? vendorOrder.id
      : vendor.orderVendorId ?? vendor.id
    const scopedParams = {
      ...params,
      [requestedVendorKey]: requestedVendorValue,
      shipping_at: shippingDate
    }
    onTrackingReady(scopedParams)
  }, [carrier, tracking, shippingDate])

  useEffect(() => {
    hasVendorOrder
      ? fetchVendorOrderCarriers({
        vendorOrderId: vendorOrder.id
      }).then((res) => {
        console.log('fetched VendorOrderCarriers', res.data)
        if (res.data && res.data.length) setCarriers(res.data)
      })
      : fetchOrderVendorCarriers({
        orderVendorId: vendor.orderVendorId ?? vendor.id
      }).then((res) => {
        console.log('fetched OrderVendorIdCarriers', res.data)
        if (res.data && res.data.length) setCarriers(res.data)
      })

  }, [])

  return (
    <>
      {' '}
      <SnowBox pb={1}>
        <SnowTypography variant={'caption'} fontWeight={400} component="p">
          order: {currentOrder.id}, vendor:{vendor.vendorName ?? vendor.name}
        </SnowTypography>
      </SnowBox>
      <ScItemsInRow justifyContent="space-between" alignItems="flex-end" itemWidth={carriers?.length ? 33 : 50}>
        {carriers?.length &&
          <ScDropdownField
            label="Carrier"
            value={carrier}
            inputWidth={100}
            code="CarrierCode"
            variant="outlined"
            onUpdate={({ value }) => setCarrier(value)}
            meta={{ options: carriers, optionsDef: { value: 'title', label: 'vendorCarrierCode' } }}
          />}
        <ScTextField
          label="Tracking code"
          value={tracking}
          inputWidth={100}
          code="TrackingCode"
          onUpdate={({ value }) => setTracking(value)}
        />
        <ScDateField
          code={'shippingDate'}
          label="Shipping Date"
          name="shippingDate"
          value={shippingDate}
          inputWidth={100}
          onUpdate={({ value }) => setShippingDate(value)}
          labelPlacement="top"
          popperPlacement={'top'}
          isClearable
        />
      </ScItemsInRow>
    </>
  )
}

export default EnterTrackingBody
