import React, { useState } from "react";
import { ScTextField, ScComboField } from "../../../shared/components/SnowEditableField/baseFields";
import {
  useOrderItemsBulkUpdateActionCmd
} from "../infrastructure/orderItemsApi";
import { useScMessage } from "../../../shared/service/Message/data/currentMessageState";
import { removeEmptyProps } from "../../../utils/helpers";
import { useNavigate } from "react-router-dom";
import useVendors from "../../integrations/hooks/useVendors";
import useUnpreorderFields from "../actions/unpreorder/useUnpreorderFields";
import _ from "lodash";
import styled from 'styled-components'

const UnpreorderContentView = styled.div`
  display: flex;
  >div {
    padding: 8px;
  }
`


const prepareDateRange = (dateRange) => {
  const [start, end] = dateRange;
  console.log("prepareDateRange", start, end, typeof end);
  return { start, end: end || new Date() };
};

const useUnpreorderContent = ({ onClose, selectedIds, filters }) => {
  const {
    fields: fieldsToEdit
  } = useUnpreorderFields({
    itemIds: selectedIds,
    filters
  });
  const { data } = useVendors();

  const vendors = data.reduce((acc, item) => ({
    ...acc, [item.code]: item.name
  }), {});

  const prepareFiltersToLoad = (filters) => {
    return removeEmptyProps(
      Object.keys(filters).reduce((acc, filterKey) => {
        const fValue = filters[filterKey];
        const castedFValue = Array.isArray(fValue)
          ? prepareDateRange(fValue)
          : fValue;
        return { ...acc, [filterKey]: castedFValue };
      }, {})
    );
  };
  const [vendor, setVendor] = useState("");
  const [qty, setQty] = useState(1);

  const navigate = useNavigate();

  const messenger = useScMessage();
  const [isLoading, setIsLoading] = useState(false);


  const bulkUnpreorderCmd = useOrderItemsBulkUpdateActionCmd(
    {
      action: "unpreorder",
      itemIds: selectedIds,
      filters: prepareFiltersToLoad(filters),
      updatedFields: {
        qty, vendor
      }
    },
    (r) => {
      const { messages, data, success } = r;
      const { bulk_update_batch_id } = data ?? {};
      console.log("bulkUnpreorderCmd success", r, messages);

      Array.isArray(messages) &&
        messages.forEach((message) =>
          messenger.send(message.text, message.type)
        );
      bulk_update_batch_id && navigate(`/home/batches/${bulk_update_batch_id}`);
      onClose();
    },
    (r) => {
      console.log("bulkUnpreorderCmd fail", r);
      messenger.send("Something went wrong", "error");
    }
  );

  const doBulkUnpreorder = () => {
    setIsLoading(true);
    bulkUnpreorderCmd().then((r) => {
      console.log("after bulk unpreorder", r);
      setIsLoading(false);
    });
  };
  console.log("useUnpreorderContent vendors", vendors);

  return {
    content: <UnpreorderContentView>

      <ScComboField
        required={true}
        label={"Vendor"}
        code={"vendor"}
        meta={{
          options: vendors
        }}
        value={vendor}
        onUpdate={({ value }) => setVendor(value)}
      />

      <ScTextField
        inputWidth={'auto'}
        label={"Qty"} 
        value={"1"} 
        onUpdate={({ value }) => setQty(value)} 
      />
    </UnpreorderContentView>,
    confirmBtnProps:
    {
      label: "Release",
      onClick: doBulkUnpreorder,
      disabled: !vendor || !qty
    },
    title: "Quantity to release ",
    isLoading
  };

};

useUnpreorderContent.propTypes = {};

export default useUnpreorderContent;
